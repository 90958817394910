import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import QueryString from 'qs';
import axiosApi from '../../../../functions/axiosApi';
import { readClipboard } from '../../../../functions/basicFunctions';
import { User, basicProps } from '../../../../utils/interfaces';

export default function AddPartner(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [open, setOpen] = useState(false);
    const [userID, setUserID] = useState('');
    const [countries, setCountries] = useState<any>([]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        
        axiosApi.get('https://restcountries.com/v2/region/Africa/?fields=translations,flag')
        .then(res => {
            setCountries(res.data);
        })

    }, [])

    const readUserIDClipboard = () => {
        readClipboard((clipboardContent) => {
            if(clipboardContent && typeof clipboardContent === 'string') setUserID(clipboardContent)
        })
    }


    const addPartner = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);

        axiosApi.post("wallet/transfer/verify-receiver-account.php", QueryString.stringify({receiverID:userID}))
        .then(response => {
            if(response.data.status === 'success'){

                const receiverInfo: User = response.data.receiverAccount;

                basicDatas.dialogManager({
                    dialogTitle: 'Destinataire',
                    dialogText: (
                        <Box sx={{
                            textAlign: 'center'
                        }}>
                            <Avatar src={receiverInfo.avatarUrl}
                                    sx={{
                                        margin: 'auto',
                                        width: '60px',
                                        height: '60px',
                                        boxShadow: 1,
                                        mb: 2
                                    }} />

                            <Typography sx={{mb: 3}}>
                                
                                { receiverInfo.lastName + ' ' + receiverInfo.firstName }

                                <br /> <br />

                                <b style={{fontSize: 'small'}}>{ "ID : " + receiverInfo.user_id}</b>

                            </Typography>
                            

                            <Typography variant='caption'>
                                Est-ce bien le compte à ajouter ?
                            </Typography>

                        </Box>
                    ),
                    dialogAgreeBtnColor: 'info',
                    dalogCloseBtnText: 'Non',
                    dialogAgreeBtnText: 'Confirmer'
                  }, (response) => {
                    
                    if(response === 'ok') {
                        axiosApi.post("ctr/team-manager/cft-partners/add-partner.php", data)
                        .then(response => {

                            if(response.data.status === 'success') {
                                setOpen(false);
                            }
                        })
                    }
              
                })
                
            }
        })
    }

    return (
        <Box>

            <Typography 
                color='primary'
                sx={{mb: 2, cursor: 'pointer'}}
                onClick={() => setOpen(true)}
            >
                Ajouter un partenaire
            </Typography>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{maxWidth: '550px', margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Ajouter un partenaire</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Box
                        component='form'
                        onSubmit={addPartner}
                    >

                        <Typography sx={{textAlign: 'right'}}>
                            <Button color='info'
                                    onClick={readUserIDClipboard}
                                    >
                                Coller
                            </Button>
                        </Typography>

                        <TextField 
                            name='userID'
                            label="ID utilisateur"
                            placeholder='User ID'
                            fullWidth
                            value={userID}
                            required
                            onChange={(e) => setUserID(e.target.value)}
                            sx={{
                                mb: 3
                            }}
                        />

                        <FormControl 
                            fullWidth 
                            sx={{
                                mb: 3
                            }}
                        >

                            <InputLabel id="select_country_label">Pays</InputLabel>
                            
                            <Select
                                labelId="select_country_label"
                                name='country'
                                label="Choisir le pays"
                                fullWidth
                                margin="dense"
                                required
                                >

                                {
                                    countries.map((country, index) => {

                                        return (
                                            <MenuItem key={index} value={ country.translations.fr}>
                                                <Avatar 
                                                    src={country.flag} 
                                                    sx={{
                                                        width:'30px', 
                                                        height:'20px', 
                                                        display:'inline-block', 
                                                        mr: 2, 
                                                        verticalAlign:'middle',
                                                        borderRadius: 0,
                                                        boxShadow: 1
                                                    }} />
                                                <span>{ country.translations .fr}</span>
                                            </MenuItem>
                                        )

                                    })
                                }
                
                            </Select>        

                        </FormControl>

                        <TextField 
                            type='password'
                            name='sw'
                            label="sw"
                            fullWidth
                            sx={{
                                mb: 3
                            }}
                        />

                        <Button
                            variant='contained'
                            fullWidth
                            type='submit'
                        >
                            Valider
                        </Button>

                    </Box>


                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}
