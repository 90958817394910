import { User, internalTransferType } from "../../utils/interfaces";
import { Chip } from '@mui/material';

interface myProps {
    currentUser: User,
    transaction: internalTransferType
}

export default function TransferTypeBadge(props: myProps) {

    const transaction = props.transaction;
    const currentUser = props.currentUser;
    const iamSender = transaction.sender.user_id === currentUser.user_id;

    let badgeLabel = '';

    switch (transaction.type) {
        case 'transfer':
            badgeLabel = iamSender ? 'Transfer OUT' : 'Transfer IN'
            break;

        case 'deposit':
            badgeLabel = "Deposit"
            break;

        case 'withdrawal':
            badgeLabel = "Withdrawal"
            break;

        case 'payment':
            badgeLabel = "Payment"
            break;

        case 'commissions':
            badgeLabel = "Commissions"
            break;

        case 'bonus':
            badgeLabel = "Bonus"
            break;
        case 'rewards':
            badgeLabel = "Rewards"
            break;
        case 'adjustment':
            badgeLabel = "Adjustment"
            break;
    
        default:
            badgeLabel = 'Unknown'
            break;
    }

    if(iamSender) return (
        <Chip 
            label={ badgeLabel } 
            sx={{
                backgroundColor: "#FDEDED",
                color: "#E5593C",
                ml: 1,
                borderRadius: 1,
                fontSize: 'small'
            }} 
            variant="filled" 
            size="small" />
    )

    return (
        <Chip 
            label={ badgeLabel } 
            sx={{
                backgroundColor: "#E8F3EE",
                color: "#338F5C",
                ml: 1,
                borderRadius: 1,
                fontSize: 'small'
            }} 
            variant="filled" 
            size="small" />
    )
}
