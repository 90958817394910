import React from 'react'
import { basicProps, prizePoolCampaignType } from '../../../../../utils/interfaces'
import { Box, Button, Chip, Typography } from '@mui/material'
import KycTask from './KycTask'
import InviteFriendsTask from './InviteFriendsTask'
import MakeTransactionTask from './MakeTransactionTask'
import TelegramSubscriptionTask from './TelegramSubscriptionTask'
import { toast } from 'react-toastify'
import axiosApi from '../../../../../functions/axiosApi'
import QueryString from 'qs'
import { isPC } from '../../../../../functions/basicFunctions'

interface myProps extends basicProps {
    campaignData: prizePoolCampaignType
}

const taskList = [
    {
        name: 'KYC',
        label: "Passer le KYC"
    },

    {
        name: 'TELEGRAM_SUBSCRIPTION',
        label: "S'abonner au canal Telegram"
    },

    {
        name: 'INVITE_FRIENDS',
        label: "Inviter des amis"
    },

    {
        name: 'MAKE_TRANSACTION',
        label: "Effectuer des transactions"
    }

]

export default function TaskViews(props: myProps) {

    const campaignData = props.campaignData;
    const tasks = campaignData.tasks;

    const checkTasksStatus = () => {

        const data = {
            campaignID: campaignData.id,
            loaderText: "Vérification en cours..."
        }
        axiosApi.post("airdrops/prize-pools/check-all-task.php", QueryString.stringify(data));
    }

    return (
        <Box>
            
            <Typography
                fontWeight='bold'
                className='Montserrat'
                sx={{
                    mb: 3,
                    mt: 5,
                    fontSize: isPC ? 'inherit' : 18
                }}
            >
                Pour être éligible à cette cagnotte, veuillez effectuer les tâches suivantes :
            </Typography>

            {
                tasks.includes('KYC') &&
                <KycTask {...props} />
            }

            {
                tasks.includes('MAKE_TRANSACTION') &&
                <MakeTransactionTask {...props} />
            }

            {
                tasks.includes('INVITE_FRIENDS') &&
                <InviteFriendsTask {...props} />
            }

            {
                tasks.includes('TELEGRAM_SUBSCRIPTION') &&
                <TelegramSubscriptionTask {...props} />
            }

            <Typography
                fontWeight='bold'
                className='Montserrat'
                variant='h6'
                sx={{
                    mb: 5,
                    mt: 7,
                    fontSize: isPC ? 'inherit' : 16
                }}
            >
                Pour être éligible à cette cagnotte, vous devez compléter toutes les tâches ci-dessus. 
                <br />
                Cliquez sur le bouton ci-dessous pour vérifier l'état de vos tâches.
            </Typography>

            <Button
                fullWidth
                variant='contained'
                className='Montserrat'
                onClick={checkTasksStatus}
                sx={{p: 1.5, fontWeight: 'bold', letterSpacing: 2}}
            >
                Vérifier les tâches
            </Button>

        </Box>
    )
}

