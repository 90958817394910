import { Box, Button, TextareaAutosize, TextField, Typography } from '@mui/material'
import QueryString from 'qs'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axiosApi from '../../../../../functions/axiosApi'
import { basicProps, newsLetterType } from '../../../../../utils/interfaces'

export default function UpdateNews(props: basicProps) {

    const basicDatas = props.basicDatas;
    const { postID } = useParams();

    const [postInfo, setpostInfo] = useState<newsLetterType>();
    const [sw, setSw] = useState('')

    useEffect(() => {
        getNewsInfos();
    }, [])
    

    const getNewsInfos = () => {
        axiosApi.post("ctr/communication/news-letter/web-news/get-post-info.php", QueryString.stringify({ postID }))
        .then(response => {
            if(response.data.status === 'success'){
                setpostInfo(response.data.postInfo)
            }
        })
    }

    const updatePost = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const form = new FormData(e.currentTarget)
        form.append('postID', postID as string);

        axiosApi.post("ctr/communication/news-letter/web-news/update-post.php", form);
    }

    const deletePost = () => {

        basicDatas.dialogManager({
            dialogTitle: "Supprimer une annonce",
            dialogText: "Voulez-vous vraiment supprimer cette annonce ?",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Oui supprimer"
          }, (response: string) => {
            
            if(response === 'ok') {            
                axiosApi.post("ctr/communication/news-letter/web-news/delete-post.php", QueryString.stringify({postID, sw}));
            }
      
        })
        
    }

    if(!postInfo) return (
        <Box sx={{textAlign: 'center', mt: 4}}>
            Chargement...
        </Box>
    )

    return (
        <div style={{maxWidth: '500px', margin: 'auto'}}>

            <Typography variant='h5' sx={{textAlign: 'center', mb: 4, mt: 4}}>
                Modifier l'annonce
            </Typography>

            <Box component='form'
                noValidate
                onSubmit={updatePost}>

                <TextField
                        type='text'
                        name='title'
                        placeholder="Titre"
                        defaultValue={postInfo.title}
                        fullWidth
                        required
                        label="Titre de l'annonce"
                        sx={{
                            mb: 2
                        }} />

                
                <TextareaAutosize
                    aria-label="Adresse blockchain"
                    name='message'
                    id="address"
                    defaultValue={postInfo.message}
                    minRows={3}
                    required
                    placeholder="Entrez le message ici"
                    style={{ 
                        width:'100%', 
                        padding:'10px', 
                        borderRadius:'5px', 
                        border: "1px solid #bbb"
                    }}
                    />

                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    fullWidth
                    onChange={(e) => setSw(e.target.value)}
                    sx={{mt: 2, mb: 3}}
                />

                <Button  variant='contained'
                        type='submit'
                        color='info'
                        fullWidth
                        sx={{mt: 2}}>
                    Mettre à jour
                </Button>

                <Button  variant='contained'
                        type='button'
                        color='error'
                        onClick={deletePost}
                        sx={{margin: 'auto', mt: 10, display: 'block'}}>
                    Supprimer
                </Button>

            </Box>
            
        </div>
    )
}
