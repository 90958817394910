import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import QueryString from 'qs';
import { Box, Table, TableBody, TableContainer, Typography, TableRow, Paper, Stack, Button, Avatar } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { basicProps, investPlanType } from '../../utils/interfaces';
import axiosApi from '../../functions/axiosApi';
import InvestPlanStatusBadge from '../../components/InvestPlanStatusBadge';
import { cf_pay_info, copyClipboard, readabilityNumber } from '../../functions/basicFunctions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function ItemInvestPlan(props: basicProps) {

    const { investPlanID } = useParams();
    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const [investPlanData, setInvestPlanData] = useState<investPlanType>();
    
    useEffect(() => {
        
        axiosApi.post('liquidity/get_plan_info.php', QueryString.stringify({investPlanID}))
        .then(response => {

            if(response.data.status === 'success') {
                setInvestPlanData(response.data.investPlanData)
            }
        })

    }, [investPlanID])


    if(!investPlanData) return (
        <Box>
            <Typography textAlign='center' sx={{mt: 3}}>
                Chargement...
            </Typography>
        </Box>
    )

    const user = investPlanData.user;
    
    return (
        <Box
            sx={{
                pt: 3,
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <Typography 
                variant='h5'
                textAlign='center'
                sx={{
                    mt: 2,
                    mb: 5
                }}
            >
                Plan d'investissement <br />
                { investPlanID }
            </Typography>
            
            <TableContainer component={Paper}  sx={{mt:4}}>

                <Table aria-label="customized table">

                    <TableBody>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                ID
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold', cursor:'pointer'}} onClick={() => copyClipboard(investPlanData.planID, 'Copie !')}>
                                { investPlanData.planID }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Statut
                            </StyledTableCell>
                            <StyledTableCell align="left" >
                                <InvestPlanStatusBadge status={investPlanData.status} />
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Montant fourni
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                <b>{ readabilityNumber(investPlanData.amount) } XAF</b>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Total à recevoir
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                <b>{ readabilityNumber(investPlanData.totalAmountToReceive) } XAF</b>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Total reçu
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                <b>{ readabilityNumber(investPlanData.totalPayments) } XAF</b>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Nomnre de mois
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                { investPlanData.num_months }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Taux intérêt
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                { investPlanData.interest_rate + '%' }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Debut validité
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                { investPlanData.month + ' / ' + investPlanData.year }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Moyen de paiement
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>

                                {
                                    investPlanData.paymentAddress 

                                    ?

                                    <table>
                                        <td>
                                            <Avatar 
                                                src={ investPlanData.paymentAddress.paymentMethod.logo } 
                                                sx={{
                                                    width: 25,
                                                    height: 25,
                                                    mr: 1
                                                }}
                                            />
                                        </td>
                                        <td style={{verticalAlign: 'middle'}}>
                                            { investPlanData.paymentAddress.paymentMethod.symbol + " (" + investPlanData.paymentAddress.paymentMethod.name + ")" }
                                        </td>
                                    </table>
                                    :

                                    <Typography variant='caption'>Aucun moyen de paiement</Typography>
                                }

                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Adresse de réception
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                { 
                                    investPlanData.paymentAddress 
                                    ?

                                    <>
                                        {
                                            (investPlanData.paymentAddress.paymentMethod.symbol === cf_pay_info.symbol)
                                            ?

                                            authUser.user_id

                                            :

                                            investPlanData.paymentAddress.paymentMethodNumber
                                        }
                                    </>

                                    :

                                    <span>...</span>
                                }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Nom du compte
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                { 
                                    investPlanData.paymentAddress

                                    ?

                                    <>
                                        {
                                            (investPlanData.paymentAddress.paymentMethod.symbol === cf_pay_info.symbol)
                                            ?

                                            authUser.lastName + ' ' + authUser.firstName

                                            :

                                            investPlanData.paymentAddress.accountOwnerName
                                        }
                                    </>

                                    :

                                    <span>...</span>
                                }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Actions
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                >
                                    
                                    <Link to={'/liquidity-provider/invest-plans/' + investPlanData.planID + '/payments/'} className='link'>
                                        <Button
                                            sx={{textTransform: 'none'}}
                                        >
                                            Paiements
                                        </Button>
                                    </Link>

                                </Stack>

                            </StyledTableCell>
                        </StyledTableRow>
                    
                    </TableBody>
                </Table>

            </TableContainer>

        </Box>
    )
}
