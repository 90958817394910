import { Box, Button, TextField } from '@mui/material'
import { useState } from 'react'
import axiosApi from '../../../../functions/axiosApi';

interface myProps {
    getFundsLocations: () => void
}

export default function AddFundsLocationForm(props: myProps) {

    const [open, setOpen] = useState(false);

    const add = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loaderText', 'Ajout en cours...');

        axiosApi.post('ctr/stats/compta/funds-location/add.php', data)
        .then(response => {

            if(response.data.status === 'success') {
                props.getFundsLocations();
            }
        })
    }

    return (
        <div>

            <Button onClick={() => setOpen(!open)}>
                { open ? 'Fermer' : 'Ajouter' }
            </Button>

            <br /><br />

            {
                open &&
            
                <Box
                    component='form'
                    onSubmit={add}
                    sx={{mb: 4}}
                >

                    <TextField
                        name='name' 
                        placeholder='Nom'
                        label='Nom'
                        fullWidth
                        sx={{
                            mb: 2
                        }}
                    />

                    <TextField
                        type='password'
                        name='sw' 
                        label='sw'
                        fullWidth
                        sx={{
                            mb: 3
                        }}
                    />

                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                    >
                        Ajouter
                    </Button>
                </Box>
            }

        </div>
    )
}
