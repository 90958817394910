import { useState, useEffect } from 'react';
import { 
    Alert, Box, Button, Typography, TextField, Stack, Dialog, DialogTitle, DialogContent, DialogActions, Skeleton, Chip, Checkbox
} from "@mui/material";
import { basicProps, currencyType, networkType, saveAddressType } from "../../utils/interfaces";
import { isPC, maxTextLength } from "../../functions/basicFunctions";
import { HashLink } from 'react-router-hash-link';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';
import { Link } from 'react-router-dom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface myProps extends basicProps {
    action: string,
    network: string,
    selectedCrypto: currencyType | undefined | null,
    blockchainAddress: string,
    memo: string,
    addessIsValid: boolean;
    selectedNetwork: networkType | undefined;
    handleBlockchainAddressChange: (value: string) => void;
    setBlockchainAddress: (value: string) => void;
    setMemo: (value: string) => void;
}

export default function WalletAddress(props: myProps) {

    const selectedCrypto = props.selectedCrypto;
    const selectedNetwork = props.selectedNetwork;
    const action = props.action;
    const addessIsValid = props.addessIsValid;

    const [open, setOpen] = useState(false);
    const [savedAddresses, setSavedAddresses] = useState<saveAddressType[]>([])
    const [selectedAddress, setSelectedAddress] = useState<saveAddressType>()
    const [addressInView, setAddressInView] = useState<saveAddressType>()
    const [addressesLoaded, setAddressesLoaded] = useState(false);

    useEffect(() => {
        getSavedAddresses();
    }, [])
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getSavedAddresses = () => {

        axiosApi.post("settings/saved_addresses/get-blockchain-addresses.php", QueryString.stringify({network: selectedNetwork?.symbol}))
        .then(response => {
            if(response.data.status === 'success') {
                setSavedAddresses(response.data.addresses)
                setAddressesLoaded(true)

                if((response.data.addresses.length !== 0) && !selectedAddress) {
                    setAddressInView(response.data.addresses[0])
                }

                response.data.addresses.forEach((item, i) => {
                    if(item.blockchainAddress === props.blockchainAddress) {
                        setSelectedAddress(item);
                        setAddressInView(item)
                    }
                })
            }
        })
    }


    const selectSavedAddress = (address: saveAddressType | undefined) => {

        if(address) {
            setAddressInView(address)
            props.setBlockchainAddress(address.blockchainAddress as string);
            address.memo && props.setMemo(address.memo)
            handleClose();
        }

        else {
            props.setBlockchainAddress('');
        }
        
    }

    const ItemAddressBox = (props: {itemAddress: saveAddressType}) => {

        const itemAddress = props.itemAddress;

        return (
            <Box
                key={itemAddress.id}
                onClick={() => {

                    if(itemAddress.status !== 'basic'){

                        if(selectedAddress?.blockchainAddress === itemAddress.blockchainAddress) {
                            selectSavedAddress(undefined);
                            setSelectedAddress(undefined);

                        }

                        else {
                            selectSavedAddress(itemAddress);
                            setSelectedAddress(itemAddress)
                        }
                        
                    }
                }
                }
                sx={{
                    pb: 3,
                    mb: 3,
                    boxShadow: 2,
                    p: 2,
                    borderRadius: 1,
                    cursor: itemAddress.status === 'basic' ? 'inherit' : 'pointer',
                    opacity: itemAddress.status === 'basic' ? 0.5 : 1
                }}
            >

                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography sx={{fontWeight: 'bold'}}>
                        {itemAddress.walletName}
                    </Typography>

                    <Checkbox checked={selectedAddress?.blockchainAddress === itemAddress.blockchainAddress} />
                </Stack>

                <Typography
                    color='GrayText'>
                    { itemAddress.network }
                </Typography>
            
                <Typography sx={{wordWrap: 'break-word'}}>
                
                    { 
                        itemAddress._system === 'blockchain' &&  
                        <>
                            { maxTextLength(isPC ? 35 : 24, itemAddress.blockchainAddress) + " " }
                            { itemAddress.memo &&  <Typography variant='caption'> ( { itemAddress.memo } ) </Typography> }
                        </>
                    }

                </Typography>

                { 
                    (itemAddress.status === 'basic') &&  
                    <>
                        <Chip 
                            label="En cours de vérification..." 
                            sx={{
                                backgroundColor: "#FDEDED",
                                color: "#E5593C",
                                borderRadius: 1,
                                mr: 2
                            }} 
                            variant="filled" 
                            size="small" 
                            icon={<AccessTimeIcon fontSize='small' sx={{mr:0.5}} />}
                        />
                    </>
                }

            </Box>
        )
    }

    return (
        <Box>

            {
                (selectedCrypto 
                    && selectedNetwork 
                    && (action === 'buy')
                    && selectedCrypto.allowed_networks?.filter(allowed_network => selectedNetwork.symbol === allowed_network.network)[0].buyAlertMessage) &&

                <Alert severity="info" 
                        sx={{mb: 1, textAlign:'left', fontSize:'small'}}>
                        
                    { selectedCrypto.allowed_networks?.filter(allowed_network => selectedNetwork.symbol === allowed_network.network)[0].buyAlertMessage }

                </Alert>
            }

            {
                (selectedCrypto 
                    && selectedNetwork 
                    && (action === 'sell')
                    && selectedCrypto.allowed_networks?.filter(allowed_network => selectedNetwork.symbol === allowed_network.network)[0].sellAlertMessage) &&

                <Alert severity="info" 
                        sx={{mb: 1, textAlign:'left', fontSize:'small'}}>
                        
                    { selectedCrypto.allowed_networks?.filter(allowed_network => selectedNetwork.symbol === allowed_network.network)[0].sellAlertMessage }

                </Alert>
            }
            

            <div style={{opacity: (selectedNetwork && selectedNetwork.id) ? 1 : 0.4}}> 

                <Typography sx={{mb: 2}}>
                    <label htmlFor="address" className="small">
                        Adresse portefeuille {selectedNetwork?.symbol} 
                    </label> 
                </Typography>

                {
                    !addressesLoaded && 
                    <Skeleton
                        animation='wave'
                        variant='rectangular'
                        sx={{height: 100, borderRadius: 1, mb: 2}}
                    />
                }

                {
                    addressInView &&
                    <ItemAddressBox itemAddress={addressInView} />
                }

                {
                    (addressesLoaded && (savedAddresses.length === 0))

                    ?

                    <>
                        <Box
                            sx={{mb: 3, mt: 2, textAlign: 'center'}}>
                            Vous n'avez aucune adresse <b>{ selectedNetwork?.symbol }</b> enregistrée !
                        </Box>

                        <Typography textAlign='center' sx={{mb: 3}}>
                            <Link to='/settings/addresses/'>
                                <Button variant="outlined" startIcon={<NoteAddIcon />}>
                                    Enregistrer une adresse
                                </Button>
                            </Link>
                        </Typography>

                    </>

                    :

                    <Button startIcon={<StickyNote2Icon />} onClick={() => handleClickOpen()} sx={{textTransform: 'none', fontWeight: 'bold'}}>
                        Choisir une autre adresse
                    </Button>
                }
                

                <Dialog open={open} onClose={handleClose}>

                    <DialogTitle>Carnet d'adresses</DialogTitle>

                    <DialogContent sx={{minWidth: 300}}>

                        <Typography sx={{mb: 3}}>
                            <Typography variant='caption'>
                                Choisir parmi vos adresses <b>{ selectedNetwork?.symbol }</b> enregistrées
                            </Typography>
                        </Typography>

                        {
                            !addressesLoaded && 
                            [1,2].map((i) => {
                                return (
                                    <Skeleton
                                        key={i}
                                        animation='wave'
                                        variant='rectangular'
                                        sx={{height: 100, borderRadius: 1, mb: 2}}
                                    />
                                )
                            })
                        }

                        {
                            (addressesLoaded && (savedAddresses.length === 0)) &&
                            <Box
                                sx={{textAlign: 'center', fontWeight: 'bold'}}>
                                Vous n'avez aucune adresse { selectedNetwork?.symbol } enregistrée !
                            </Box>
                        }
                        
                        {
                            savedAddresses.map((itemAddress, i) => {
                                return (
                                    <ItemAddressBox itemAddress={itemAddress} key={i} />
                                )
                            })
                        }

                        <br />

                        <Typography textAlign='center'>
                            <Link to='/settings/addresses/'>
                                <Button variant="outlined" startIcon={<NoteAddIcon />}>
                                    Enregistrer une adresse
                                </Button>
                            </Link>
                        </Typography>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>

                </Dialog>
                
                {
                    !addessIsValid &&
                    <Alert severity='error' sx={{fontSize: '12px', float: 'left', mb: 2, mt: 2}}>
                        L'adresse semble non compatible avec le réseau <b>{ selectedNetwork?.symbol }</b>. {isPC && " Votre ordre risque d'être annulé par les validateurs."} <br />
                        <HashLink to="/about#contact-us">
                            Contactez
                        </HashLink> le support pour vous assurer de la compatibilité.
                    </Alert>
                }

                {
                    (selectedNetwork && (selectedNetwork.memoRequiredForBuy == 1) && (action === 'buy')) &&
                    <>         

                        <TextField
                            label='Memo (Facultatif)'
                            placeholder={'Ex : ' + selectedNetwork?.memo }
                            fullWidth
                            value={props.memo}
                            onChange={(e) => props.setMemo(e.target.value)}
                            sx={{
                                mt: 2
                            }}
                        />

                        <Alert severity="warning" 
                                sx={{textAlign:'left', fontSize:'small'}}>
                            La plupart des exchanges exigent un MEMO pour que votre { selectedCrypto?.symbol } soit correctement crédité. Si le MEMO est exigé, veuillez vous assurer que vous l'avez saisi correctement sinon vous perdrez vos { selectedCrypto?.symbol }.
                        </Alert>

                    </>
                }

                <Alert severity='info' sx={{fontSize: '12px', float: 'left', mb: 2, mt: 2, width: '100%'}}>
                    L'enregistrement d'adresse est désormais obligatoire avant toute transaction. 
                    <br />
                    <a 
                        href="https://medium.com/@cryptofiat1022/comment-enregistrer-une-nouvelle-adresse-de-portefeuille-sur-cryptofiat-73fbdb80a124" 
                        target='_blank'
                        style={{fontSize: 15}}
                    >
                        Comment enregistrer une adresse?
                    </a>
                </Alert>

            </div>
            
        </Box>
    )
}
