import { Box, Typography } from '@mui/material'
import { MetaTags } from 'react-meta-tags';
import { basicProps } from '../../utils/interfaces'
import { Link } from 'react-router-dom';

export default function LegalNotices(props: basicProps) {

    const basicDatas = props.basicDatas;
    
    return (
        <Box sx={{
            maxWidth: '550px',
            margin: 'auto',
            pt: 5
        }}>

            <MetaTags id="egal-notices">
                <title>Mentions légales</title>
                <meta name="description" content={basicDatas.appName + " - Mentions légales"} />
                <meta property="og:title" content={basicDatas.appName + " - Mentions légales"} />
            </MetaTags>
            
            <Typography variant='h4' fontWeight='bold' sx={{textAlign: 'center', mb: 4}}>
                Informations légales
            </Typography>

            <Box sx={{
                boxShadow: 2,
                p: 2,
                mb: 3,
                borderRadius: 1
            }}>
                <Typography variant='h6' 
                            sx={{
                                fontWeight: 'bold'
                            }}>
                    { basicDatas.appName + " Exchange" }
                </Typography>

                <Typography sx={{opacity: 0.8}}>
                    Service d'échanges d'actifs numériques contre devises fiduciaires
                </Typography>

                <Typography variant='caption'>
                    La plateforme <b>Cryptofiat-ex.com</b> et ses sous-domaines sont la propriété de 
                </Typography> 
                <br />
                <b>{"Ets " + basicDatas.appName }</b>
            </Box>

            <Box sx={{
                boxShadow: 2,
                p: 2,
                mb: 3,
                borderRadius: 1
            }}>
                <Typography variant='h6' 
                            sx={{
                                fontWeight: 'bold'
                            }}>
                    Registre du Commerce et du Crédit Mobilier
                </Typography>

                <Typography sx={{opacity: 0.8}}>

                    <Typography variant='caption'>
                        <b>{"Ets " + basicDatas.appName }</b> est une entreprise enregistrée au Cameroun, au Registre du Commerce et du crédit mobilier sous le numéro
                    </Typography>
                    {' '} RC/YAO/2023/A/1144
                </Typography>
            </Box>

            <Box sx={{
                boxShadow: 2,
                p: 2,
                mb: 3,
                borderRadius: 1
            }}>
                <Typography variant='h6' 
                            sx={{
                                fontWeight: 'bold'
                            }}>
                    Contribuable
                </Typography>

                <Typography sx={{opacity: 0.8}}>

                    <Typography variant='caption'>
                        <b>{"Ets " + basicDatas.appName }</b> est une entreprise enregistrée au Cameroun, à le Direction Générale des Impôts sous le numéro contribuable
                    </Typography>
                    {' '} P079718069698Q
                </Typography>
            </Box>

            <Box sx={{
                boxShadow: 2,
                p: 2,
                mb: 3,
                borderRadius: 1
            }}>
                <Typography variant='h6' 
                            sx={{
                                fontWeight: 'bold'
                            }}>
                    Conception / Promotion
                </Typography>

                <Typography sx={{opacity: 0.8}}>
                    <a href="https://twitter.com/idriswagg" target='_blank'>IDRISS WAGOUM TAKUÉTÉ</a>
                </Typography>
            </Box>

            <Box sx={{
                boxShadow: 2,
                p: 2,
                mb: 3,
                borderRadius: 1
            }}>
                <Typography variant='h6' 
                            sx={{
                                fontWeight: 'bold'
                            }}>
                    Contact
                </Typography>

                <Typography variant='caption'>
                    Pour tout problème ou renseignement, contactez-nous via WhatsApp au numéro suivant
                </Typography>
                <br />

                <Typography sx={{opacity: 0.8}}>
                    WhatsApp: <b>+237 650 83 94 31</b>
                </Typography>
                <Typography sx={{opacity: 0.8}}>
                    E-mail: <b>contact@cryptofiat-ex.com</b>
                </Typography>
            </Box>


            <Box sx={{
                boxShadow: 2,
                p: 2,
                mb: 3,
                borderRadius: 1
            }}>
                <Typography variant='h6' 
                            sx={{
                                fontWeight: 'bold'
                            }}>
                    Conditions Générales d'Utilisation (CGU)
                </Typography>

                <Typography variant='caption'>
                    En utilisant nos services, vous acceptez nos conditions accéssibles via le lien ci-dessous.
                </Typography>
                <br />

                <Link to='/terms/cgu/' target='_blak'>
                    Conditions Générales d'Utilisation
                </Link>
            </Box>

            <Box sx={{
                boxShadow: 2,
                p: 2,
                mb: 3,
                borderRadius: 1
            }}>
                <Typography variant='h6' 
                            sx={{
                                fontWeight: 'bold'
                            }}>
                    Politique de Confidentialité
                </Typography>

                <Typography variant='caption'>
                    En utilisant nos services, vous déclarez avoir lu et êtes en accord avec notre Politique de Confidentialité accéssibles via le lien ci-dessous.
                </Typography>
                <br />

                <Link to='/terms/privacy/' target='_blak'>
                    Politique de Confidentialité
                </Link>
            </Box>

        </Box>
    )
}
