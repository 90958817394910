import { useEffect, useState } from 'react'
import { basicProps } from '../../../../utils/interfaces'
import { Box, Button, TextField, Typography } from '@mui/material'
import { cf_pay_info } from '../../../../functions/basicFunctions'
import axiosApi from '../../../../functions/axiosApi'

interface contentType {
    senderFees?: number,
    receiverFees?: number,
    senderCommissions?: number,
    receiverCommissions?: number
}

interface dataType {
    transferSimpleToSimpleAccount: contentType,
    transferSimpleToMerchandAccount: contentType,
    transferMerchandToSimpleAccount: contentType,
    transferMerchandToMerchandAccount: contentType,
    paymentBySimpleAccount: contentType,
    paymentByMerchandAccount: contentType
}

export default function CfpayFeesManager(props: basicProps) {

    const [data, setData] = useState<dataType>()
    const [sw, setSw] = useState('');

    useEffect(() => {  
        axiosApi.post('ctr/finances/get-cfpay-fees-commissions.php')
        .then(response => {

            if(response.data.status === 'success') {
                const responseData = response.data.data;
                setData(responseData)
            }
        })
        
    }, [])
    

    const updateOnServer = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('sw', sw);
        data.append('loaderText', "Mise à jour...");

        axiosApi.post("ctr/finances/cfpay-fees-commissions-mananager.php", data);
    }

    if(!data) return (
        <Box sx={{
            textAlign: 'center',
            mt: 5
        }}>
            <Typography>
                Chargement...
            </Typography>
        </Box>
    )
    
    return (
        <Box
            sx={{
                pt: 4,
                maxWidth: 500,
                margin: 'auto'
            }}>
            
            <Typography variant='h5' sx={{textAlign: 'center', mb: 4}}>
                Gestionnaire frais { cf_pay_info.symbol }
            </Typography>

            <Box
                component='form'
                onSubmit={updateOnServer}>

                <Box
                    sx={{
                        p: 2,
                        boxShadow: 2,
                        mb: 4
                    }}>

                    <Typography variant='h6' sx={{mb: 2}}>
                        Transfer Simple To Simple Account
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferSimpleToSimpleAccountSenderFees'
                            defaultValue={data.transferSimpleToSimpleAccount?.senderFees}
                            label="Sender Fees"
                            placeholder='En %'
                            fullWidth
                        />

                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferSimpleToSimpleAccountReceiverFees'
                            defaultValue={data.transferSimpleToSimpleAccount?.receiverFees}
                            label="Receiver Fees"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferSimpleToSimpleAccountSenderCommissions'
                            defaultValue={data.transferSimpleToSimpleAccount?.senderCommissions}
                            label="Sender Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferSimpleToSimpleAccountReceiverCommissions'
                            defaultValue={data.transferSimpleToSimpleAccount?.receiverCommissions}
                            label="Receiver Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                </Box>

                <Box
                    sx={{
                        p: 2,
                        boxShadow: 2,
                        mb: 4
                    }}>

                    <Typography variant='h6' sx={{mb: 2}}>
                        Transfer Simple To Merchand Account
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferSimpleToMerchandAccountSenderFees'
                            defaultValue={data.transferSimpleToMerchandAccount?.senderFees}
                            label="Sender Fees"
                            placeholder='En %'
                            fullWidth
                        />

                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferSimpleToMerchandAccountReceiverFees'
                            defaultValue={data.transferSimpleToMerchandAccount?.receiverFees}
                            label="Receiver Fees"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferSimpleToMerchandAccountSenderCommissions'
                            defaultValue={data.transferSimpleToMerchandAccount?.senderCommissions}
                            label="Sender Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferSimpleToMerchandAccountReceiverCommissions'
                            defaultValue={data.transferSimpleToMerchandAccount?.receiverCommissions}
                            label="Receiver Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                </Box>


                <Box
                    sx={{
                        p: 2,
                        boxShadow: 2,
                        mb: 4
                    }}>

                    <Typography variant='h6' sx={{mb: 2}}>
                        Transfer Merchand To Simple Account
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferMerchandToSimpleAccountSenderFees'
                            defaultValue={data.transferMerchandToSimpleAccount?.senderFees}
                            label="Sender Fees"
                            placeholder='En %'
                            fullWidth
                        />

                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferMerchandToSimpleAccountReceiverFees'
                            defaultValue={data.transferMerchandToSimpleAccount?.receiverFees}
                            label="Receiver Fees"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferMerchandToSimpleAccountSenderCommissions'
                            defaultValue={data.transferMerchandToSimpleAccount?.senderCommissions}
                            label="Sender Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferMerchandToSimpleAccountReceiverCommissions'
                            defaultValue={data.transferMerchandToSimpleAccount?.receiverCommissions}
                            label="Receiver Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                </Box>


                <Box
                    sx={{
                        p: 2,
                        boxShadow: 2,
                        mb: 4
                    }}>

                    <Typography variant='h6' sx={{mb: 2}}>
                        Transfer Merchand To Merchand Account
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferMerchandToMerchandAccountSenderFees'
                            defaultValue={data.transferMerchandToMerchandAccount?.senderFees}
                            label="Sender Fees"
                            placeholder='En %'
                            fullWidth
                        />

                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferMerchandToMerchandAccountReceiverFees'
                            defaultValue={data.transferMerchandToMerchandAccount?.receiverFees}
                            label="Receiver Fees"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferMerchandToMerchandAccountSenderCommissions'
                            defaultValue={data.transferMerchandToMerchandAccount?.senderCommissions}
                            label="Sender Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='transferMerchandToMerchandAccountReceiverCommissions'
                            defaultValue={data.transferMerchandToMerchandAccount?.receiverCommissions}
                            label="Receiver Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                </Box>

                <Box
                    sx={{
                        p: 2,
                        boxShadow: 2,
                        mb: 4
                    }}>

                    <Typography variant='h6' sx={{mb: 2}}>
                        Payment By Simple Account
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='paymentBySimpleAccountSenderFees'
                            defaultValue={data.paymentBySimpleAccount?.senderFees}
                            label="Sender Fees"
                            placeholder='En %'
                            fullWidth
                        />

                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='paymentBySimpleAccountReceiverFees'
                            defaultValue={data.paymentBySimpleAccount?.receiverFees}
                            label="Receiver Fees"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='paymentBySimpleAccountSenderCommissions'
                            defaultValue={data.paymentBySimpleAccount?.senderCommissions}
                            label="Sender Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='paymentBySimpleAccountReceiverCommissions'
                            defaultValue={data.paymentBySimpleAccount?.receiverCommissions}
                            label="Receiver Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                </Box>


                <Box
                    sx={{
                        p: 2,
                        boxShadow: 2,
                        mb: 4
                    }}>

                    <Typography variant='h6' sx={{mb: 2}}>
                        Payment By Merchand Account
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='paymentByMerchandAccountSenderFees'
                            defaultValue={data.paymentByMerchandAccount?.senderFees}
                            label="Sender Fees"
                            placeholder='En %'
                            fullWidth
                        />

                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='paymentByMerchandAccountReceiverFees'
                            defaultValue={data.paymentByMerchandAccount?.receiverFees}
                            label="Receiver Fees"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='paymentByMerchandAccountSenderCommissions'
                            defaultValue={data.paymentByMerchandAccount?.senderCommissions}
                            label="Sender Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                    <Typography sx={{mb: 2}}>

                        <TextField
                            type='number'
                            inputProps={{step: 'any'}}
                            name='paymentByMerchandAccountReceiverCommissions'
                            defaultValue={data.paymentByMerchandAccount?.receiverCommissions}
                            label="Receiver Commissions"
                            placeholder='En %'
                            fullWidth
                        />
                    </Typography>

                </Box>

                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    fullWidth
                    value={sw}
                    onChange={(e) => setSw(e.target.value)}
                    sx={{mb: 3}}
                />

                <Button
                    type='submit'
                    variant='contained'
                    fullWidth
                    sx={{mt: 4}}>
                    Mettre à jour
                </Button>

            </Box>

        </Box>
    )
}
