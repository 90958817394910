import React from 'react'
import { basicProps } from '../../../utils/interfaces'
import { Box } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import WaitingDemands from './WaitingDemands'
import ItemDemand from './ItemDemand'

export default function AccountVerification(props: basicProps) {
    return (
        <Box>

            <Routes>
                <Route path='/' element={<WaitingDemands {...props} />} />
                <Route path='/:user_id' element={<ItemDemand {...props} />} />
            </Routes>

        </Box>
    )
}
