import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { basicProps, investPlanType, paymentMethodType } from '../../../utils/interfaces';
import QueryString from 'qs';
import { cf_pay_info, cftTokenInfo } from '../../../functions/basicFunctions';
import { MuiTelInput } from 'mui-tel-input';

interface myProps extends basicProps {
    investPlanData: investPlanType
}

export default function PaymentMethodManager(props: myProps) {

    const basicDatas = props.basicDatas;
    const investPlanData = props.investPlanData;
    const user = investPlanData.user;
    const paymentAddress = investPlanData.paymentAddress;
 

    const [open, setOpen] = useState(false);
    const [paymentMethods, setpaymentMethods] = useState<paymentMethodType[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<paymentMethodType>();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [accountOwnerName, setPaymentMethodOwnerName] = useState(user.lastName || user.firstName ? user.lastName + " " + user.firstName : "")

    useEffect(() => {

        if(paymentAddress) {
            setSelectedPaymentMethod(paymentAddress.paymentMethod)
            setPaymentMethod(paymentAddress.paymentMethod.symbol)
        }

        if(paymentAddress && (paymentAddress.paymentMethod.symbol !== cftTokenInfo.symbol) && (paymentAddress.paymentMethod.symbol !== cf_pay_info.symbol)) {
            setPhoneNumber(paymentAddress.paymentMethodNumber)
            setPaymentMethodOwnerName(paymentAddress.accountOwnerName)
        } 
       
    }, [open])

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        getPaymentMethods();

    }, []) 

    useEffect(() => {
        
        const cftMethod = paymentMethods.filter((method) => method.symbol === cf_pay_info.symbol);
        if(cftMethod[0]){
            setSelectedPaymentMethod(cftMethod[0]);
            setPaymentMethod(cftMethod[0].symbol);
        }
    }, [paymentMethods])
    

    const getPaymentMethods = () => {
        axiosApi.post('ctr/payment-methods/get-payment-methods.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setpaymentMethods(response.data.paymentMethods);
            }
        })
    }

    const handlePaymentMethodChange = (e: SelectChangeEvent) => {

        const matchedPaymentMethod = paymentMethods.filter((item) => item.symbol === e.target.value);
        if(matchedPaymentMethod.length !== 0){
            setSelectedPaymentMethod(matchedPaymentMethod[0]);
        }

        setPaymentMethod(e.target.value as string);

    }

    const updatePaymentMethod = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Vérification en cours...");

        basicDatas.dialogManager({
            dialogTitle: 'Destinataire',
            dialogText: "Souhaitez-vous vraiment modifier les informations de paiement pour ce plan d'investissement ?",
            dialogAgreeBtnColor: 'info',
            dalogCloseBtnText: 'Non',
            dialogAgreeBtnText: 'Confirmer'
            }, (response) => {
            
            if(response === 'ok') {
                
                data.set('loaderText', "Mise à jour en cours...");
                data.append('investPlanID', investPlanData.planID)

                axiosApi.post("ctr/liquidity/set-payment-method.php", data)
                .then(response => {

                    if(response.data.status === 'success') {
                        setOpen(false);
                    }
                })
            }
        
        })
        
    }

    return (
        <Box>
            
            <Button 
                variant='contained' 
                color='inherit' 
                onClick={() => setOpen(true)}
                sx={{
                    mt: 5,
                    mb: 5
                }}
            >
                Moyen de paiement
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Moyen de paiemnt</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Box    
                        component='form'
                        onSubmit={updatePaymentMethod}
                        sx={{
                            width: '500px'
                        }}
                    >

                        <FormControl fullWidth sx={{mb:2, mt:2}}>

                            <InputLabel id="select_payment_method_label">Moyen de paiement</InputLabel>

                            <Select
                                labelId="select_payment_method_label"
                                id="demo-simple-select"
                                name='paymentMethod'
                                label="Moyen de paiement"
                                value={paymentMethod}
                                onChange={handlePaymentMethodChange}
                                fullWidth
                                required
                            >

                                {
                                    paymentMethods.map((method: paymentMethodType, index) => {
                                        return (
                                            <MenuItem 
                                                key={index} 
                                                value={method.symbol}
                                            > 
                                                <Avatar src={method.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                                <span>{method.symbol + " (" + method.name + ")"}</span>
                                            </MenuItem>
                                        )
                                    })
                                }

                            </Select>

                        </FormControl>

                        {
                            (selectedPaymentMethod && (selectedPaymentMethod.symbol !== cftTokenInfo.symbol) && (selectedPaymentMethod.symbol !== cf_pay_info.symbol)) &&

                            <>
                                <MuiTelInput 
                                    label={"Numéro " + selectedPaymentMethod.name}
                                    margin="normal"
                                    id="paymentMethodNumber"
                                    value={phoneNumber} 
                                    onChange={(value) => setPhoneNumber(value)}
                                    name="paymentMethodNumber"
                                    autoComplete="paymentMethodNumber"
                                    placeholder={"Exemple : +237 " + selectedPaymentMethod.address}
                                    fullWidth
                                    required
                                />

                                <TextField
                                    type="text"
                                    margin="normal"
                                    value={accountOwnerName}
                                    onChange={(e) => setPaymentMethodOwnerName(e.target.value)}
                                    id="accountOwnerName"
                                    label={"Nom associé à votre compte " + selectedPaymentMethod.name}
                                    name="accountOwnerName"
                                    autoComplete="accountOwnerName"
                                    placeholder={"Exemple : " + selectedPaymentMethod.accountOwnerName}
                                    fullWidth
                                    required
                                    sx={{
                                        mb: 2
                                    }}
                                />

                            </>
                        }

                        <TextField
                            type='password'
                            name='sw'
                            label="sw"
                            required 
                            fullWidth
                        />

                        <Button 
                            variant='contained'
                            type='submit'
                            color='info'
                            fullWidth
                            disabled={(investPlanData.status === 'canceled') || (investPlanData.status === 'completed')}
                            sx={{grid: 1, mt: 3}}
                        >
                            Mettre à jour
                        </Button>

                    </Box>
                        
                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}

