import { Link, Route, Routes } from "react-router-dom";
import { basicProps } from "../../../utils/interfaces";
import General from "./general/General";
import UsersStats from "./users-stats/UsersStats";
import Compta from "./compta/Compta";

interface statsProps extends basicProps {

}
export default function Stats(props: statsProps) {
    return (
        <div>

            <Routes>

                <Route path="/" element={
                    <div style={{textAlign:'center'}}>
            
                        <br /><br />
            
                        <Link to="/ctr/stats/general/" className='link' style={{fontSize:'18px'}}>Stats : Généraux</Link>
                        <br /><br />

                        <Link to="/ctr/stats/users-stats/" className='link' style={{fontSize:'18px'}}>Stats : Utilisateurs</Link>
                        <br /><br />

                        <Link to="/ctr/stats/compta/" className='link' style={{fontSize:'18px'}}>Comptabilité</Link>
                        <br /><br />
            
                    </div>
                } />

                <Route path="/general" element={<General />} />

                <Route path="/users-stats" element={<UsersStats />} />

                <Route path="/compta/*" element={<Compta {...props} />} />

            </Routes>

        </div>
    )
}
