import React, { useEffect } from 'react'
import { basicProps } from '../../utils/interfaces'
import axiosApi from '../../functions/axiosApi'
import QueryString from 'qs'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material'

export default function Redirection(props: basicProps) {

    const basicDatas = props.basicDatas;
    const { linkID } = useParams();

    useEffect(() => {
        axiosApi.post("ctr/communication/links/get-link.php", QueryString.stringify({linkID}))
        .then(response => {

            if(response.data.status === 'success') {
                const link = response.data.link;
                window.location = link.link
            }
        })
    }, [])
    
    return (
        <Box sx={{
            pt: 3,
            textAlign: 'center'
        }}>
            
            <Typography variant='h5' sx={{textAlign: 'center', mb: 5}}>
                {basicDatas.appName + " Redirection"}
            </Typography>

            <CircularProgress thickness={1}  />

        </Box>
    )
}
