import { Alert, AlertTitle } from '@mui/material';
import { orderType } from '../../../utils/interfaces';
import MessageIcon from '@mui/icons-material/Message';
const nl2br = require('react-nl2br');

interface adminMessageProps {
    order: orderType,
    style?: any
}

export default function AdminMessage(props: adminMessageProps) {

    const orderData = props.order;

    if ((orderData.basic.status === 'completed') || (orderData.basic.status === 'canceled_by_admin') || (orderData.basic.status === 'deleted')) return (

        <Alert icon={ <MessageIcon /> } severity="info" sx={{background:'#eee', textAlign:'left', mt:2}} {...props}>
            <AlertTitle sx={{fontWeight: 'bold', mb: 2}}>Message du validateur </AlertTitle>
            { orderData.basic.message ? nl2br(<p dangerouslySetInnerHTML={{__html: orderData.basic.message}}></p> ) : "Aucun message" }
        </Alert>
    )

    return <></>
}
