import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';
import { basicProps, currencyType, internalTransferType } from '../../utils/interfaces';
import { Box, Avatar, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Chip, Stack } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import PreviewTransfer from '../../components/internal-transfers/PreviewTransfer';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { copyClipboard, isPC, readabilityNumber } from '../../functions/basicFunctions';
//import AgricultureIcon from '@mui/icons-material/Agriculture';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { MetaTags } from 'react-meta-tags';

interface assetType extends currencyType {
    userBalance: number
}

export default function Asset(props: basicProps) {

    const { assetSymbol } = useParams();
    const basicDatas = props.basicDatas;

    const [asset, setAsset] = useState<assetType>();
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    const [transactions, setTransactions] = useState([]);
    const [open, setOpen] = useState(false);
    const [idCopied, setIdCopied] = useState(false);
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getTransactions();
    }, [queryValue])
    

    useEffect(() => {

        axiosApi.post("wallet/get-asset-info.php", QueryString.stringify({ symbol: assetSymbol}))
        .then(response => {

            if(response.data.status === 'success'){
                setAsset(response.data.asset);
            }

        })

    }, [])

    const getTransactions = () => {

        setIsLoading(true);

        axiosApi.post("wallet/transfer/get-transactions.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newtransactionsArray = transactions.concat(response.data.transactions);
                setTransactions(newtransactionsArray);
                setOffset(offset + 5);

                if(response.data.transactions.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }

    if(!asset) return (
        <Box sx={{
            textAlign: 'center',
            mt: 5
        }}>
            Chargement...
        </Box>
    )
    
    return (
        <Box sx={{
            maxWidth: '500px',
            margin: 'auto',
            pt: 3
        }}>

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Historique transactions</title>
                <meta name="description" content="CRYPTO-FIAT | Historique transactions" />
                <meta property="og:title" content="CRYPTO-FIAT | Historique transactions" />
            </MetaTags>

            <Box sx={{textAlign: 'center'}}>
                <Avatar src={ asset.logo } 
                        sx={{
                            margin: 'auto',
                            width: '80px',
                            height: '80px',
                            boxShadow: 1,
                            mb: 2
                        }} />
                
                <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                    { asset.symbol}
                </Typography>

                <Typography sx={{fontSize: 'small'}}>
                    { asset.name}
                </Typography>

                <Typography variant='h5' sx={{mt: 2}}>
                    { readabilityNumber(parseFloat((asset.userBalance).toFixed(5))) }
                </Typography>


                <Typography> 

                    <Link to={"/cft-token/sellers/"} className='link'>

                        <Typography sx={{
                            margin: 'auto',
                            fontWeight: 'bold',
                            mt: 3,
                            mr: 2,
                            p: 1,
                            pl: 2,
                            pr: 2,
                            boxShadow: 1,
                            borderRadius: 2,
                            maxWidth: 250,
                            display: 'inline-block'
                        }}>

                            <AddIcon sx={{mr: 1}} />

                            Recharger

                        </Typography>

                    </Link>  


                    <Typography 
                        onClick={() => setOpen(true)}
                        sx={{
                            margin: 'auto',
                            fontWeight: 'bold',
                            mt: 3,
                            mr: isPC ? 2 : 0,
                            p: 1,
                            pl: 2,
                            pr: 2,
                            boxShadow: 1,
                            borderRadius: 2,
                            maxWidth: 250,
                            display: 'inline-block',
                            cursor: 'pointer'
                        }}
                    >

                        <SystemUpdateAltIcon sx={{mr: 1}} />
                        Recevoir
                    </Typography>

                    <Link to={"/wallet/" + asset.symbol + "/transfer/"} className='link'>

                        <Typography sx={{
                            margin: 'auto',
                            fontWeight: 'bold',
                            mt: 3,
                            p: 1,
                            pl: 2,
                            pr: 2,
                            boxShadow: 1,
                            borderRadius: 2,
                            maxWidth: 250,
                            display: 'inline-block'
                        }}>

                            <NearMeIcon sx={{mr: 1}} />
                            Transférer
                        </Typography>
                    </Link>

                    {/* <Link to={"/wallet/farming/"} className='link'>

                        <Typography sx={{
                            margin: 'auto',
                            fontWeight: 'bold',
                            mt: 3,
                            p: 1,
                            pl: 2,
                            pr: 2,
                            boxShadow: 1,
                            borderRadius: 2,
                            maxWidth: 250,
                            display: 'inline-block'
                        }}>

                            <AgricultureIcon sx={{mr: 1}} />
                            Farming
                        </Typography>
                    </Link> */}

                </Typography>
            
            </Box>


            <Dialog open={open} onClose={handleClose}>

                <DialogTitle sx={{bgcolor: '#F7F7F7'}}>Recevoir { asset.symbol }</DialogTitle>

                <DialogContent sx={{minWidth: 300, textAlign: 'center', bgcolor: '#F7F7F7'}}>
                    
                    <Typography 
                        sx={{
                            mb: 2, 
                            bgcolor: '#fff', 
                            p:2,
                            textAlign: 'left',
                            borderRadius: 1
                        }}>

                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >

                            <Typography fontWeight='bold' sx={{fontSize: 13}}>
                                { basicDatas.authUser.user_id }
                            </Typography>

                            <Typography>

                                <Tooltip title="Cliquez pour copier l'ID du compte">

                                    {
                                        !idCopied 
                                        ?   <ContentCopyIcon 
                                                sx={{cursor: 'pointer'}} 
                                                onClick={() => { copyClipboard(basicDatas.authUser.user_id, "L'ID du compte a été copié !"); setIdCopied(true)}}
                                            />
                                        :   <CheckCircleIcon 
                                                className="text-success" 
                                                sx={{cursor: 'pointer'}} 
                                                onClick={() => { copyClipboard(basicDatas.authUser.user_id, "L'ID du compte a été copié !"); setIdCopied(true)}}
                                            />
                                    }

                                </Tooltip>

                                <Tooltip title="Partager via WhatsApp">
                                    <a 
                                        href={"https://wa.me/?text=" + basicDatas.authUser.user_id + " : " + basicDatas.authUser.lastName + " " + basicDatas.authUser.firstName }
                                        target='_blank'
                                    >
                                        <WhatsAppIcon sx={{color:'#128C7E', ml: 3, cursor: 'pointer'}} />
                                    </a>
                                </Tooltip>

                            </Typography>

                        </Stack>

                    </Typography>

                    <Typography variant='caption'>
                        Partagez votre ID pour recevoir des { asset.symbol } dans votre compte.
                    </Typography>

                </DialogContent>

                <DialogActions sx={{bgcolor: '#F7F7F7'}}>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>

            <Box sx={{mt: 5}}>

                <Typography 
                        variant='h6'
                        sx={{
                            mb: 3,
                            textAlign: 'center'
                        }}>
                    Historique transactions
                </Typography>
                
                <Typography
                        sx={{
                            textAlign: 'center',
                            mb: 5
                        }}>
                    <TextField 
                        id="outlined-basic"
                        type='search' 
                        label="Rechercher une transaction : ID" 
                        variant="outlined"
                        placeholder="Entrez ID transaction"
                        value={queryValue}
                        fullWidth
                        sx={{maxWidth: 250}}
                        onChange={
                            (e) => {
                                setQueryValue(e.target.value);
                                setTransactions([]);
                                setOffset(0);
                                setEnded(false)
                            }
                        }
                        />
                </Typography>

                {
                    transactions.length === 0 &&
                    <Typography sx={{textAlign: 'center', mb: 3}}>
                        <Typography variant='caption'>
                            Aucune transaction
                        </Typography> 
                    </Typography>
                }

                {
                    transactions.map((transaction: internalTransferType) => {
                        return (
                            <PreviewTransfer 
                                        basicDatas={basicDatas}
                                        transaction={transaction} 
                                        currentUser={basicDatas.authUser}
                                        key={transaction.transactionID} />
                        )
                    })
                }

                <div className="text-center">

                    {
                        isloading

                        ? 
                            <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                            >
                                Chrargement
                            </LoadingButton>
                        :
                            !ended &&
                            <Button variant='contained' color='inherit' onClick={() => getTransactions()}>
                                Afficher plus
                            </Button>
                    }

                </div>

            </Box>
             
        </Box>
    )
}
