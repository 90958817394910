import { Alert, AlertTitle, Box, Button, Stack, TextField, Typography } from '@mui/material'
import { useState, useEffect } from 'react';
import QueryString from 'qs';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { Link, Route, Routes } from 'react-router-dom';
import { basicProps, investPlanType } from '../../utils/interfaces';
import axiosApi from '../../functions/axiosApi';
import { copyClipboard, readabilityNumber } from '../../functions/basicFunctions';
import InvestPlanStatusBadge from '../../components/InvestPlanStatusBadge';
import Payments from './Payments';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function InvestPlans(props: basicProps) {

    const [investPlans, setInvestPlans] = useState<investPlanType[]>([]);
    const [numInvestPlans, setNumInvestPlans] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');


    useEffect(() => {
        getInvestPlans();
    }, [queryValue])

    const getInvestPlans = () => {

        setIsLoading(true);

        axiosApi.post("liquidity/get-invest-plans.php", QueryString.stringify({
            queryValue,
            offset, 
            limit: 3
        }))
        .then(response => {

            if(response.data.status === 'success'){
                const newDemandsArray = investPlans.concat(response.data.investPlans);
                setInvestPlans(newDemandsArray);
                setNumInvestPlans(response.data.numInvestPlans);
                setIsLoading(false);
                setOffset(offset + 3);

                if(response.data.investPlans.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    if(numInvestPlans === 0) return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto',
                pt: 5
            }}
        >
            <Typography
                variant='h5'
                textAlign='center'
                className='Montserrat'
                fontWeight={800}
                sx={{mb: 5}}
            >
                Plans d'investissement 
                <br />
                { numInvestPlans }
            </Typography>

            <Alert severity="info" sx={{textAlign:'left'}} {...props}>
                <AlertTitle sx={{fontWeight: 'bold'}}>Aucune liquidité fournie</AlertTitle>
                Vous n'avez pas encore fourni de liquidité. 
                <br /><br />
                Vous souhaitez devenir fournisseur de liquidité, veuillez contacter le support pour plus d'informations.
                <br />

                <a 
                    href={"https://wa.me/+237650839431/?text=Bonjour, je souhaites devenir fournisseur de liquidité à CRYPTO-FIAT, comment débuter?."} 
                    target='_blank'
                    className='link'
                >
                    <Button
                        variant='contained'
                        startIcon={<WhatsAppIcon />}
                        sx={{
                            bgcolor: '#128C7E',
                            mt: 2
                        }}
                    >
                        +237 650 839 431
                    </Button>
                </a>
            </Alert>
        </Box>
    )

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto',
                pt: 5
            }}
        >

            <Routes>
                <Route 
                    path='/'
                    element={
                        <>

                            <Typography
                                variant='h5'
                                textAlign='center'
                                className='Montserrat'
                                fontWeight={800}
                                sx={{mb: 5}}
                            >
                                Plans d'investissement 
                                <br />
                                { numInvestPlans }
                            </Typography>

                            <div className="text-center" style={{
                                marginBottom: '40px'
                            }}>
        
                                <TextField 
                                    id="outlined-basic"
                                    type='search' 
                                    label="Rechercher un plan" 
                                    variant="outlined"
                                    placeholder="Entrez l'ID du plan"
                                    value={queryValue}
                                    sx={{mt:3}}
                                    onChange={
                                        (e) => {
                                            setQueryValue(e.target.value);
                                            setInvestPlans([]);
                                            setOffset(0);
                                            setEnded(false)
                                        }
                                    }
                                    />
        
                            </div>

                            {
                                investPlans.length === 0 &&
                                <Typography textAlign='center'>
                                    <Typography variant='caption'>
                                        Aun plan d'investissement trouvé
                                    </Typography>
                                </Typography>
                            }


                            {
                                investPlans.map((itemPlan) => {

                                    const user = itemPlan.user;
                                    
                                    return (
                                        <Box 
                                            key={ itemPlan.planID }
                                            sx={{
                                                mb: 5,
                                                p: 2,
                                                boxShadow: 2,
                                                borderRadius: 2
                                            }}
                                        >

                                            <Typography variant='h5' color='primary' sx={{mb: 2}}>
                                                { readabilityNumber(itemPlan.amount) } <Typography variant='caption'>XAF </Typography> <InvestPlanStatusBadge status={itemPlan.status} />
                                            </Typography>

                                            <Typography 
                                                fontWeight='bold'
                                                onClick={() => copyClipboard(itemPlan.planID, "Copie !")}
                                                sx={{cursor: 'pointer', mb: 2}}
                                            >
                                                { 'ID : ' + itemPlan.planID } 
                                            </Typography>
                                                
                                            <Link to={'/liquidity-provider/invest-plans/' + itemPlan.planID + '/'} className='link'>
                                                <Button
                                                    sx={{textTransform: 'none'}}
                                                >
                                                    Accéder au plan
                                                </Button>
                                            </Link>
                                        </Box>
                                    )
                                })
                            }

                            <div className="text-center">

                                {
                                    isloading

                                    ? 
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="outlined"
                                        >
                                            Chrargement
                                        </LoadingButton>
                                    :
                                        !ended &&
                                        <Button variant='contained' color='inherit' onClick={() => getInvestPlans()}>
                                            Afficher plus
                                        </Button>
                                }

                            </div>

                        </>
                    }
                />

                <Route 
                    path='/:investPlanID/payments'
                    element={<Payments {...props} />}
                />
            </Routes>

        </Box>
    )
}
