import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { User, basicProps } from '../../../utils/interfaces';
import QueryString from 'qs';

export default function AddInvestmentPlan(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [open, setOpen] = useState(false);

    useEffect(() => {
    }, [open])

    const handleClose = () => {
        setOpen(false);
    };

    const addPlan = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Vérification en cours...");

        axiosApi.post("wallet/transfer/verify-receiver-account.php", QueryString.stringify({receiverID: data.get('user_id')}))
        .then(response => {
            if(response.data.status === 'success'){

                const receiverInfo: User = response.data.receiverAccount;

                basicDatas.dialogManager({
                    dialogTitle: 'Destinataire',
                    dialogText: (
                        <Box sx={{
                            textAlign: 'center'
                        }}>
                            <Avatar src={receiverInfo.avatarUrl}
                                    sx={{
                                        margin: 'auto',
                                        width: '60px',
                                        height: '60px',
                                        boxShadow: 1,
                                        mb: 2
                                    }} />

                            <Typography sx={{mb: 3}}>
                                
                                { receiverInfo.firstName + ' ' + receiverInfo.lastName }

                                <br /> <br />

                                <b style={{fontSize: 'small'}}>{ "ID : " + receiverInfo.user_id}</b>

                            </Typography>

                            

                            <Typography variant='caption'>
                                Est-ce bien le compte pour lequel vous souhaitez ajouter un plan d'investissement ?
                            </Typography>
                        </Box>
                    ),
                    dialogAgreeBtnColor: 'info',
                    dalogCloseBtnText: 'Non',
                    dialogAgreeBtnText: 'Confirmer'
                  }, (response) => {
                    
                    if(response === 'ok') {
                        
                        data.set('loaderText', "Traitement en cours...");

                        axiosApi.post("ctr/liquidity/add-investment-plan.php", data)
                        .then(response => {

                            if(response.data.status === 'success') {
                                setOpen(false);
                            }
                        })
                    }
              
                })
                
            }
        })
    }

    return (
        <Box>
            
            <Button 
                variant='contained' 
                color='inherit' 
                onClick={() => setOpen(true)}
                sx={{
                    mt: 5,
                    mb: 5
                }}
            >
                Ajouter un plan
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Ajouter un plan d'investissement</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Box    
                        component='form'
                        onSubmit={addPlan}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: 2,
                            width: '500px'
                        }}
                    >

                        <TextField
                            type='text'
                            name='user_id'
                            label="User ID"
                            placeholder='User ID'
                            required 
                        />

                        <TextField
                            type='number'
                            name='amount'
                            label="Montant"
                            placeholder='Montant'
                            required 
                            inputProps={{step: 'any'}}
                        />

                        <TextField
                            type='number'
                            name='interest_rate'
                            label="Pourcentage intérêt"
                            placeholder='Pourcentage intérêt'
                            required 
                            defaultValue={10}
                            inputProps={{step: 'any'}}
                        />

                        <TextField
                            type='number'
                            name='num_months'
                            label="Nombre de mois"
                            placeholder='Nombre de mois'
                            defaultValue={10}
                            required 
                            inputProps={{step: 'any'}}
                        />

                        <FormControl fullWidth required>

                            <InputLabel id="demo-simple-select-label">Mois</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                name='month'
                                id="demo-simple-select"
                                label="Mois"
                                required
                            >
                                {
                                    [
                                        { label: 'Janvier', value: 1 },
                                        { label: 'Février', value: 2 },
                                        { label: 'Mars', value: 3 },
                                        { label: 'Avril', value: 4 },
                                        { label: 'Mai', value: 5 },
                                        { label: 'Juin', value: 6 },
                                        { label: 'Juillet', value: 7 },
                                        { label: 'Août', value: 8 },
                                        { label: 'Septembre', value: 9 },
                                        { label: 'Octobre', value: 10 },
                                        { label: 'Novembre', value: 11 },
                                        { label: 'Décembre', value: 12 },
                                    ].map((month) => {
                                        return (
                                            <MenuItem key={month.value} value={month.value}>{ month.label }</MenuItem>
                                        )
                                    })
                                }
                                
                            </Select>

                            </FormControl>

                            <FormControl fullWidth required>

                            <InputLabel id="demo-simple-select-label">Année</InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                name='year'
                                id="demo-simple-select"
                                label="Année"
                                required
                            >
                                {
                                    [
                                        { label: '2024', value: 2024 },
                                        { label: '2025', value: 2025 },
                                        { label: '2026', value: 2026 },
                                        { label: '2027', value: 2027 },
                                        { label: '2028', value: 2028 },
                                        { label: '2029', value: 2029 },
                                        { label: '2030', value: 2030 },
                                    ].map((year) => {
                                        return (
                                            <MenuItem key={year.value} value={year.value}>{ year.label }</MenuItem>
                                        )
                                    })
                                }
                            </Select>

                        </FormControl>

                        <TextField
                            type='password'
                            name='sw'
                            label="sw"
                            required 
                        />

                        <Button variant='contained'
                                type='submit'
                                color='info'
                                fullWidth
                                sx={{grid: 1}}
                                >
                            Ajouter
                        </Button>

                    </Box>
                        
                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}
