import React from 'react'
import { internalTransferType } from '../../../../utils/interfaces';
import { 
    Box, Typography, Tooltip, Avatar, Stack
} from '@mui/material';
import { copyClipboard, readabilityNumber } from '../../../../functions/basicFunctions';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

interface myProps {
    transaction: internalTransferType,
}

export default function ViewTransaction(props: myProps) {

    const transaction = props.transaction;
    const sender = transaction.sender;
    const receiver = transaction.receiver;

    return (
        <Box
            sx={{
                mb: 7,
                p: 2,
                borderRadius: 1,
                boxShadow: 2
            }}>
            
            <Typography>
                <Tooltip title="Cliquez pour copier ID transaction">
                    <span style={{cursor:'pointer', fontWeight: 'bold'}} onClick={() => copyClipboard(transaction.transactionID, "L'ID transaction a été copié !")}>
                        {"ID : " + transaction.transactionID}
                    </span>
                </Tooltip>
            </Typography>

            <Typography>
                Type : <b>{ transaction.type }</b>
            </Typography>

            <Typography
                sx={{
                    mt: 1
                }}>
                Montant : <b>{ readabilityNumber(parseFloat((transaction.amount).toFixed(5))) + " " + transaction.asset.symbol }</b>
            </Typography>

            <Typography
                sx={{
                    mt: 1
                }}>
                Sender Fees : <b>{ readabilityNumber(parseFloat((transaction.senderFees).toFixed(5))) + " " + transaction.asset.symbol }</b>
            </Typography>

            <Typography
                sx={{
                    mt: 1
                }}>
                Receiver Fees : <b>{ readabilityNumber(parseFloat((transaction.receiverFees).toFixed(5))) + " " + transaction.asset.symbol }</b>
            </Typography>

            <Typography sx={{mt: 1}}>
                Référence : <b>{ transaction.message }</b>
            </Typography>

            <Typography
                variant='caption'
                sx={{
                    mt: 1,
                    mb: 2
                }}>
                Date : <b>{ transaction.date }</b>
            </Typography>

            <Stack 
                direction='row' 
                spacing={3}
                alignItems='center'
                justifyContent='space-between'
                >

                <Box
                    sx={{
                        boxShadow: 2,
                        p: 2,
                        mb: 3,
                        mt: 3,
                        borderRadius: 1,
                        textAlign: 'center'
                    }}>

                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 2
                        }}>
                        <Avatar 
                            src={sender.avatarUrl} 
                            alt={sender.firstName + ' ' + sender.lastName}
                            onClick={() => window.open('/ctr/users/' + sender.user_id + '/')}
                            sx={{
                                boxShadow: 1,
                                cursor: 'pointer'
                            }} />
                    </Typography>

                    <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: 'var(--site-color)',
                                mb: 1
                            }}>
                        Compte Émetteur
                    </Typography>

                    <Box>

                        <Typography sx={{mb: 2}}>
                            { sender.firstName + ' ' + sender.lastName }
                        </Typography>

                        <Typography>
                            <Tooltip title="ID utilisateur copié !">
                                <b style={{cursor:'pointer'}} onClick={() => copyClipboard(sender.user_id, "L'ID utilisateur a été copié !")}>
                                    {" " + sender.user_id }
                                </b>
                            </Tooltip>
                        </Typography>

                    </Box>
                </Box>

                <ArrowRightAltIcon color='primary' sx={{fontSize: 50, opacity: 0.7}} />

                <Box
                    sx={{
                        boxShadow: 2,
                        p: 2,
                        borderRadius: 1,
                        textAlign: 'center'
                    }}>

                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center' ,
                            mb: 2                       
                            }}>
                        <Avatar 
                            src={receiver.avatarUrl} 
                            alt={receiver.firstName + ' ' + receiver.lastName}
                            onClick={() => window.open('/ctr/users/' + receiver.user_id + '/')}
                            sx={{
                                boxShadow: 1,
                                cursor: 'pointer'
                            }} />
                    </Typography>

                    <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: 'var(--site-color)',
                                mb: 1
                            }}>
                        Compte Bénéficiaire
                    </Typography>

                    <Box>

                        <Typography sx={{mb: 2}}>
                            { receiver.firstName + ' ' + receiver.lastName }
                        </Typography>

                        <Typography>
                            <Tooltip title="ID utilisateur copié !">
                                <b style={{cursor:'pointer'}} onClick={() => copyClipboard(receiver.user_id, "L'ID utilisateur a été copié !")}>
                                    { ' ' + receiver.user_id }
                                </b>
                            </Tooltip>
                        </Typography>
                    </Box>
                </Box>

            </Stack>

        </Box>
    )
}
