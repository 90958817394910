import React from 'react'
import { basicProps } from '../../utils/interfaces'
import { Box, Typography } from '@mui/material'
import { isPC, publicPath } from '../../functions/basicFunctions';

export default function Cgu(props: basicProps) {

    const basicDatas = props.basicDatas;

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <div className="text-center"
                    style={{
                        marginTop: '0px',
                        marginBottom: '50px'
                    }}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>

                    <img 
                        src={publicPath + "images/cgu_banner.jpg"} 
                        alt="CRYPTO-FIAT"
                        style={{
                            width: '100%',
                            borderBottomLeftRadius: isPC ? '0px' : '5px',
                            borderBottomRightRadius: isPC ? '0px' : '5px'
                        }} />

                </div>

            </div>
            
            <Typography
                variant='h4'
                fontWeight='bold'
                sx={{mb:5}}
            >
                Conditions Générales d'Utilisation de CryptoFiat
            </Typography>

            <h5>Introduction</h5>
            <p>Bienvenue chez CryptoFiat, votre plateforme sécurisée d'achat et de vente de cryptomonnaies. En utilisant nos services, vous acceptez ces Conditions Générales d'Utilisation ainsi que notre Politique de Confidentialité.</p>
            
            <h5>Services Offerts</h5>
            <p>CryptoFiat permet aux utilisateurs d'acheter et de vendre des cryptomonnaies via une plateforme sécurisée. Notre engagement ? Sécurité et accessibilité.</p>
            
            <h5>Compte Utilisateur</h5>
            <p>Créez votre compte avec des informations véridiques pour accéder à nos services. Vous êtes le seul responsable de sa sécurité.</p>
            
            <h5>Transactions</h5>
            <p>Chaque transaction sur CryptoFiat est définitive. Nous veillons à l'intégrité de nos opérations en refusant toute activité suspecte.</p>
            
            <h5>Sécurité</h5>
            <p>Votre sécurité est notre priorité. Nous protégeons vos données et actifs numériques, tout en vous rappelant l'importance de sécuriser vos identifiants.</p>
            
            <h5>Confidentialité</h5>
            <p>Nous valorisons votre vie privée. Vos informations personnelles sont traitées avec la plus grande confidentialité, sans partage non consenti.</p>
            
            <h5>Responsabilité</h5>
            <p>CryptoFiat s'efforce de protéger vos actifs, mais n'est pas responsable des pertes liées à des accès non autorisés ou des erreurs de réseau.</p>
            
            <h5>Paiement de Commande</h5>
            <p>Le paiement doit être personnel. Les paiements tiers sont interdits et peuvent entraîner la fermeture de votre compte.</p>
            
            <h5>Adresse de Portefeuille</h5>
            <p>Utilisez uniquement l'adresse de votre portefeuille personnel pour les achats, garantissant ainsi la réception directe de vos cryptos.</p>
            
            <h5>Confidentialité des Informations de Compte</h5>
            <p>Ne divulguez jamais vos informations de compte. Le partage avec des tiers compromet la sécurité de votre compte.</p>
            
            <h5>Modifications des CGU</h5>
            <p>Nous pouvons actualiser ces CGU à tout moment, avec effet immédiat dès leur publication.</p>
            
            <h5>Droit Applicable et Juridiction</h5>
            <p>Ces CGU sont régies par le droit Camerounais. Les litiges seront résolus exclusivement par les tribunaux camerounais.</p>
            
            <h5>Contact</h5>
            <p>Pour questions ou réclamations : <a href="mailto:info@cryptofiat-ex.com" style={{fontWeight: 'bold'}} className='link'>info@cryptofiat-ex.com</a>.</p>
            
            <p>En utilisant CryptoFiat, vous reconnaissez avoir lu, compris, et accepté ces CGU.</p>
        </Box>
    )
}
