import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, TextareaAutosize, Typography } from '@mui/material'
import React, { useState, useEffect} from 'react'
import EditPermissionForm from './EditPermissionForm';
import axiosApi from '../../../../functions/axiosApi';
import { User, basicProps, permissionType } from '../../../../utils/interfaces';
import QueryString from 'qs';
import ItemPermission from './ItemPermission';


interface myProps extends basicProps {
    userInfos: User
}

export default function ManageAccount(props: myProps) {

    const basicDatas = props.basicDatas;

    const [userPermissions, setUserPermissions] = useState<permissionType[]>([]);
    const userInfos = props.userInfos;

    useEffect(() => {
        getPermissions();
    }, [])
    

    const getPermissions = () => {
        axiosApi.post("ctr/users/permissions/get-permissions.php", QueryString.stringify({userID: userInfos.user_id}))
        .then(response => {

            if(response.data.status === 'success') {
                const res = response.data.userPermissions;
                setUserPermissions(res);
            }
        })
    }

    const changeMail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const form = e.currentTarget;

        const data = new FormData(form);
        data.append('user_id', userInfos.user_id);
        data.append('loaderText', "Traitement en cours...");

        basicDatas.dialogManager({
            dialogTitle: "Changer l'adresse mail du compte",
            dialogText: "Souhaitez-vous confirmer la mise à jour de l'adresse mail de ce compte ?",
            dialogAgreeBtnColor: 'primary',
            dialogAgreeBtnText: "Oui valider"
          }, (response: string) => {
            
            if(response === 'ok') {
                axiosApi.post("ctr/users/set-mail-address.php", data)
                .then(response => {

                    if(response.data.status === 'success') {
                        form.reset();
                    }
                })
            }
        })
    }


    return (
        <Box>

            {
                userPermissions.map((permission, i) => {
                    return (
                        <ItemPermission 
                            user={userInfos} 
                            permission={permission} 
                            getPermissions={getPermissions}
                            key={i} />
                    )
                })
            }

            <Box
                component='form'
                onSubmit={changeMail}
                sx={{
                    mt: 4,
                    p: 2,
                    boxShadow: 2,
                    borderRadius: 2
                }}
            >

                <Typography variant='h6' sx={{mb: 3}}>
                    Adresse mail
                </Typography>

                <Stack
                    direction='row'
                    justifyContent='space-between'
                    gap={2}
                >

                    <TextField 
                        type='email'
                        name='newMailAddress'
                        label='Nouvelle adresse mail'
                        placeholder='Nouvelle adresse mail'
                        fullWidth
                        required
                    />

                    <TextField 
                        type='password'
                        name='sw'
                        label='sw'
                        required
                    />

                    <Button
                        variant='contained'
                        type='submit'
                    >
                        Mettre à jour
                    </Button>

                </Stack>
                
            </Box>
            
        </Box>
    )
}
