import { Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface myProps {
    status: 'not_completed' | 'completed', 
    sx?: any
}

export default function TaskStatusBadge(props: myProps) {

    const status = props.status;
    const bgColor = status === 'not_completed' ? 'rgba(150,150,150, 0.3)' : '#E8F3EE';
    const color = status === 'not_completed' ? '#222' : '#338F5C';
    
    return (
        <Chip label={ status === 'not_completed' ? <>Tâche non effectuée <AccessTimeIcon fontSize='small' sx={{color}}  /></> : <>Tâche complétée <CheckCircleIcon fontSize='small' sx={{color}}  /></>  } 
            sx={{
                backgroundColor: bgColor,
                color: color,
                borderRadius: 1,
                ...props.sx
            }} 
            variant="filled" 
            size="small" 
        />
    )
}
