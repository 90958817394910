import { 
    Avatar,
    Box, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography
} from '@mui/material';
import { basicProps} from '../../utils/interfaces';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { cf_pay_info, cftTokenInfo, publicPath } from '../../functions/basicFunctions';


export default function BecomePartner(props: basicProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    return (
        <Box
            sx={{
                maxWidth: 700,
                margin: 'auto',
                bgcolor: '#F7F7F7',
                mt: -5,
                pt: 4,
                borderRadius: 3
            }}>

            <img 
                src={publicPath + "images/cf_pay_homme_banner_phone.png"} 
                alt="CRYPTO-FIAT PAY" 
                width='100%'
            />

            <Box sx={{p: 1}}>

                <br /> <br />
                
                <Typography
                        variant='h5'
                        sx={{
                            textAlign: 'center',
                            mb: 5,
                            fontWeight: 'bold'
                        }}>
                    Devenir partenaire { cf_pay_info.name }
                </Typography> 

                <Box
                    sx={{
                        bgcolor: 'white',
                        p: 2,
                        mb: 5,
                        borderRadius: 2
                    }}
                >

                    <Typography fontWeight='bold' fontSize={20} sx={{mb: 2}}>
                        Contactez-nous
                    </Typography>

                    <Typography>
                        Vous souhaitez devenir partenaire et représenter { basicDatas.appName } dans votre pays? contactez-nous.  
                    </Typography>
                    
                    <a 
                        href={"https://wa.me/+237650839431/?text=Bonjour, je souhaites devenir partenaire et représenter " + basicDatas.appName + " dans mon pays de résidence."} 
                        target='_blank'
                        className='link'
                    >
                        <Button
                            variant='contained'
                            startIcon={<WhatsAppIcon />}
                            sx={{
                                bgcolor: '#128C7E',
                                mt: 2
                            }}
                        >
                            +237 650 839 431
                        </Button>
                    </a>

                </Box>


                <Box
                    sx={{
                        bgcolor: 'white',
                        p: 2,
                        mb: 5,
                        borderRadius: 2
                    }}
                >

                    <Typography fontWeight='bold' fontSize={20} sx={{mb: 2}}>
                        Votre rôle
                    </Typography>

                    <Typography fontWeight='bold' sx={{mb: 2}}>
                        Que font les partenaires { basicDatas.appName } ?  
                    </Typography>
                    
                    <Typography>
                        En tant que partenaire, vous aidez les utilisateurs de votre pays à recharger et faire les retraits { cftTokenInfo.symbol } dans leurs comptes. 
                    </Typography>

                </Box>

                <Box
                    sx={{
                        bgcolor: 'white',
                        p: 2,
                        mb: 5,
                        borderRadius: 2
                    }}
                >

                    <Typography fontWeight='bold' fontSize={20} sx={{mb: 2}}>
                        Avantages à travailler avec nous
                    </Typography>

                    <Typography fontWeight='bold' sx={{mb: 2}}>
                        En tant que partenaire { basicDatas.appName }, vous bénéficiez des avantages suivants : 
                    </Typography>
                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        
                        <ListItem alignItems="flex-start">
                            
                            <ListItemAvatar>
                                <Avatar 
                                    alt='1' 
                                    src="/static/images/avatar/1.jpg" 
                                    sx={{
                                        bgcolor: 'var(--site-color)',
                                        width: 30,
                                        height: 30
                                    }}
                                />
                            </ListItemAvatar>

                            <ListItemText
                            primary="Commissions sur chaque transaction"
                            secondary={
                                <span>
                                    Lorsque vous effectuez une recharge ou un retrait à un utilisateur, vous recevez automatiquement une commission en fonction du montant de la transaction.
                                </span>
                            }
                            />
                        </ListItem>

                        <Divider variant="inset" component="li" />

                        <ListItem alignItems="flex-start">
                            
                            <ListItemAvatar>
                                <Avatar 
                                    alt='2' 
                                    src="/static/images/avatar/2.jpg" 
                                    sx={{
                                        bgcolor: 'var(--site-color)',
                                        width: 30,
                                        height: 30
                                    }}
                                />
                            </ListItemAvatar>

                            <ListItemText
                            primary="Remises lors des achats"
                            secondary={
                                <span>
                                    Nous appliquons des remises lorsques les partenaires effectuent des achats sur la plateforme.
                                    <br />
                                    Par exemple si le prix USDT est de 680 XAF sur le marché, vous pouvez l'obtenir à un prix bien inférieur.
                                </span>
                            }
                            />
                        </ListItem>

                        <Divider variant="inset" component="li" />

                        <ListItem alignItems="flex-start">
                            
                            <ListItemAvatar>
                                <Avatar 
                                    alt='3' 
                                    src="/static/images/avatar/2.jpg" 
                                    sx={{
                                        bgcolor: 'var(--site-color)',
                                        width: 30,
                                        height: 30
                                    }}
                                />
                            </ListItemAvatar>

                            <ListItemText
                            primary="Des cadeaux"
                            secondary={
                                <span>
                                    Lorsque votre volume d'échange est assez important pour une certaine période, vous pouvez recevoir des petites surprises de { basicDatas.appName }.
                                </span>
                            }
                            />
                        </ListItem>

                    </List>

                </Box>

                <Box
                    sx={{
                        bgcolor: 'white',
                        p: 2,
                        borderRadius: 2
                    }}
                >

                    <Typography fontWeight='bold' fontSize={20} sx={{mb: 2}}>
                        Conditions
                    </Typography>

                    <Typography fontWeight='bold' sx={{mb: 2}}>
                        Avant de devenir ou étant partenaire { basicDatas.appName }, vous devez remplir/respecter les conditions ci-dessous : 
                    </Typography>
                    
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>                 

                        <ListItem alignItems="flex-start">
                            
                            <ListItemAvatar>
                                <Avatar 
                                    alt='1' 
                                    src="/static/images/avatar/1.jpg" 
                                    sx={{
                                        bgcolor: 'var(--site-color)',
                                        width: 30,
                                        height: 30
                                    }}
                                />
                            </ListItemAvatar>

                            <ListItemText
                            primary="Vous ne devez pas échanger à un taux différent du taux officiel"
                            secondary={
                                <span>
                                    Le { cftTokenInfo.symbol + " (" + cftTokenInfo.name + ")"} est le token de { basicDatas.appName } qui permet aux utilisateurs d'effectuer leurs transactions sur la plateforme. Ce token a la même valeur que le FCFA et donc 1 { cftTokenInfo.symbol } = 1 FCFA.
                                    <br />
                                    Vous devez toujours respecter ce taux lorsque vous effectuez les échanges.
                                </span>
                            }
                            />
                        </ListItem>

                        <Divider variant="inset" component="li" />

                        <ListItem alignItems="flex-start">
                            
                            <ListItemAvatar>
                                <Avatar 
                                    alt='2' 
                                    src="/static/images/avatar/2.jpg" 
                                    sx={{
                                        bgcolor: 'var(--site-color)',
                                        width: 30,
                                        height: 30
                                    }}
                                />
                            </ListItemAvatar>

                            <ListItemText
                            primary="Vous devez posséder capital propre"
                            secondary={
                                <span>
                                    Afin de pouvoir servir les utilisateurs (vos clients), vous devez posséder suffisamment de fonds pour que les échanges soient rapides afin de garantir une meilleure expérience pour les utilisateurs.
                                    <br />
                                    Nous exigeons à ce qu'un partenaire possède minimum 500,000 FCFA comme capital personnel.
                                </span>
                            }
                            />
                        </ListItem>

                        <Divider variant="inset" component="li" />

                        <ListItem alignItems="flex-start">
                            
                            <ListItemAvatar>
                                <Avatar 
                                    alt='3' 
                                    src="/static/images/avatar/2.jpg" 
                                    sx={{
                                        bgcolor: 'var(--site-color)',
                                        width: 30,
                                        height: 30
                                    }}
                                />
                            </ListItemAvatar>

                            <ListItemText
                            primary="Vous avez du temps libre"
                            secondary={
                                <span>
                                    Avant de commencer la procédure, assurez-vous d'avoir du temps libre de façon à pouvoir répondre et fournir un meilleur service pour vos clients. Nous bloquons le compte si vous êtes trop souvent indisponible.
                                </span>
                            }
                            />
                        </ListItem>

                    </List>

                </Box>

            </Box>

        </Box>
    )
}
