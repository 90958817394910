import React from 'react'
import { User, basicProps, internalTransferType } from '../../utils/interfaces'
import { Box, Tooltip, Typography } from '@mui/material';
import { copyClipboard, readabilityNumber } from '../../functions/basicFunctions';
import TransferTypeBadge from './TransferTypeBadge';
import ViewTransfer from './ViewTransfer';

interface myProps extends basicProps {
    transaction: internalTransferType,
    currentUser: User
}

export default function PreviewTransfer(props: myProps) {

    const currentUser = props.currentUser;

    const transaction = props.transaction;
    const sender = transaction.sender;
    const receiver = transaction.receiver;
    const iamSender = sender.user_id === currentUser.user_id;

    return (
        <Box
            sx={{
                mb: 3,
                pb: 3,
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid #eee'
            }}>

            <Typography>

                <Typography>
                    <Tooltip title="Cliquez pour copier ID transaction">
                        <span style={{cursor:'pointer', fontWeight: 'bold'}} onClick={() => copyClipboard(transaction.transactionID, "L'ID transaction a été copié !")}>
                            {transaction.transactionID}
                        </span>
                    </Tooltip>

                    <TransferTypeBadge {...props} />
                </Typography>

                <Typography>
                    <Typography 
                            variant='caption'
                            onClick={() => copyClipboard(iamSender ? receiver.user_id : sender.user_id, "L'ID du compte a été copié !")}
                            sx={{
                                opacity: 0.7,
                                cursor: 'pointer'
                            }}>
                        Avec <b>{ iamSender ? receiver.firstName + ' ' + receiver.lastName : sender.firstName + ' ' + sender.lastName }</b>
                    </Typography>
                </Typography>
                
                <Typography sx={{mb: 1}}>
                    <Typography 
                            variant='caption'
                            sx={{
                                opacity: 0.7
                            }}>
                        { transaction.date }
                        
                    </Typography>
                </Typography>

            </Typography>

            <Typography
                    sx={{
                        ml: 2
                    }}>

                <Typography
                    sx={{
                        fontSize: 'small',
                        fontWeight: 'bold',
                        color: iamSender ? 'red' : 'green',
                        mb: 2,
                        textAlign: 'right'
                    }}>
                    {iamSender ? '-' : '+'}{ readabilityNumber(parseFloat((transaction.amount).toFixed(5))) }
                </Typography>

                <ViewTransfer {...props} />

            </Typography>

        </Box>
    )
}
