import { useState, useEffect } from 'react'
import { basicProps } from '../../../../utils/interfaces'
import { Typography } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axiosApi from '../../../../functions/axiosApi';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Graphique',
        },
        },
};
  

interface bilan {
    labels: string[],
    datasets: {
        fill: boolean,
        label: string,
        data: number[],
        borderColor: string,
        backgroundColor: string
    }[]
}

export default function Graphic(props: basicProps) {

    const [chartInfo, setChartInfo] = useState<bilan>({
        labels: [],
        datasets: [
            {
                fill: true,
                label: 'Evolution de capitalisation',
                data: [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    })

    useEffect(() => {
        
        axiosApi.post('ctr/stats/compta/bilans/get-chart-info.php')
        .then(response => {

            if(response.data.status === 'success') {

                const responseData = response.data.chartInfo;

                setChartInfo({
                    labels: responseData.labels,
                    datasets: [
                        {
                            fill: true,
                            label: 'Evolution de capitalisation',
                            data: responseData.data,
                            borderColor: 'rgb(53, 162, 235)',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                })
            }
        })
    }, [])
    

    return (
        <div>

            <br />

            <Line options={options} data={chartInfo} />

        </div>
    )
}
