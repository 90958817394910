import { useState, useEffect } from 'react'
import { Typography, Checkbox, TextField, Stack, Button } from '@mui/material';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { basicProps } from '../../../utils/interfaces';

interface myProps extends basicProps {
    sw: string
}

export default function TeamAvailability(props: myProps) {

    const basicDatas = props.basicDatas;

    const [isAvailable, setIsAvailable] = useState(true);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [notificationAudienceNumber, setNotificationAudienceNumber] = useState(0);

    useEffect(() => {
        axiosApi.post("ctr/team-manager/get-team-availability.php")
        .then(response => {
            if(response.data.status === 'success'){
                const teamAvailability = response.data.teamAvailability;
                setIsAvailable(parseInt(teamAvailability.isAvailable) === 1 ? true : false);
                setDate(teamAvailability.date);
                setTime(teamAvailability.time);
            }
        })
    }, [])


    useEffect(() => {
        axiosApi.post("ctr/team-manager/get-team-availability-callback-subscription-number.php")
        .then(response => {
            if(response.data.status === 'success'){
                setNotificationAudienceNumber(response.data.count)
            }
        })
    }, [])
    

    const setTeamAvailabilityOnServer = () => {

        axiosApi.post("ctr/team-manager/set-team-availability.php", QueryString.stringify({
            isAvailable,
            date,
            time,
            sw: props.sw,
            loaderText: "Mise à jours en cours..."
        }))
    }

    const sendNotification = () => {
        
        basicDatas.dialogManager({
            dialogTitle: "Envoyer les otifications",
            dialogText: "Les utilisateurs ayant défini des rappel recevront des notifications Push et par mail pour les rappeler de la disponibilité des validateurs.",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: 'Confirmer',
            dalogCloseBtnText: 'Annuler'
        }, (response => {

            if(response === 'ok') {

                const data = {
                    sw: props.sw,
                    loaderText: "Envoi de notifications en cours..."
                }
                axiosApi.post("ctr/team-manager/inform_users_for_team_availability.php", QueryString.stringify(data))
            }
        }))
    }
    
    return (
        <div style={{maxWidth:'400px', margin:'auto', marginTop:'40px', textAlign:'center'}}>
            
            <Typography variant='h5' className='text-center' sx={{mb:4}}>
                Disponibilité de la team
            </Typography>

            <Typography variant='body1' 
                        onClick={() => setIsAvailable(!isAvailable)}
                        sx={{cursor:'pointer', mb:4}}
                        color={isAvailable ? 'info' : 'error'}>
                <Checkbox checked={isAvailable} />
                {isAvailable ? "L'équipe est disponible" : "L'équipe est indisponible"}
            </Typography>

            {
                !isAvailable &&
            
                <>

                    <Typography>
                        Entrez la late de disponibilité
                    </Typography>

                    <Stack direction='row' spacing={3} sx={{mt:3}}>

                        <TextField  type='date'
                                    fullWidth
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)} />

                        <TextField  type='time'
                                    fullWidth
                                    value={time}
                                    onChange={(e) => setTime(e.target.value)} />
                    </Stack>

                </>
            }


            <Button variant='contained' 
                    color='info'
                    fullWidth
                    onClick={setTeamAvailabilityOnServer}
                    sx={{mt:2}}>
                Mettre à jour
            </Button>


            {
                isAvailable &&

                <Typography 
                    color='primary'
                    fontWeight='bold'
                    onClick={sendNotification}
                    sx={{mt:3, cursor: 'pointer'}}
                >
                    Informer les utilisateurs <br />
                    <b>{notificationAudienceNumber + " "}</b> 
                    <Typography variant='caption'>personnes se sont abonnées pour le rappel</Typography>
                    
                </Typography>
            }

        </div>
    )
}
