import { useEffect } from 'react';

import { Avatar, Box, Button, Checkbox, Chip, FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextField, Typography } from '@mui/material';
import QueryString from 'qs';
import { useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { basicProps, paymentMethodType } from '../../../utils/interfaces';
import { useParams } from 'react-router-dom';

interface paymentMethodProps extends basicProps {
    paymentMethods: paymentMethodType[]
}

export default function ItemPaymentMethod(props: paymentMethodProps) {

    const { paymentMethodID } = useParams();

    const paymentMethods = props.paymentMethods;
    const [paymentMethod, setPaymentMethod] = useState<paymentMethodType>();
    const [sw, setSw] = useState('');

    useEffect(() => {
        
        axiosApi.post("ctr/payment-methods/get-payment-method-infos.php", QueryString.stringify({paymentMethodID}))
        .then(response => {

            if(response.data.status === 'success') {
                setPaymentMethod(response.data.paymentMethod);
            }
        })
    }, [])

    const setPaymentMethodOnServer = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if(!paymentMethod) return;
    
        let data = new FormData(e.currentTarget);
        
        data.append('id', '' + paymentMethod.id);
        data.append('sw', sw)
        data.append('loaderText', 'Mise à jour de la devise en cours...');
    
        axiosApi.post('ctr/payment-methods/set-payment-method.php', data)

    }

    const setAlertsOnServer = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if(!paymentMethod) return;
    
        let data = new FormData(e.currentTarget);
        
        data.append('id', '' + paymentMethod.id);
        data.append('sw', sw)
        data.append('loaderText', 'Mise à jour de la devise en cours...');
    
        axiosApi.post('ctr/payment-methods/set-payment-method-alerts.php', data)

    }
    
    
    const handleManageBuyAndSellAction = (actionType:string) => {
        if(!paymentMethod) return;
        axiosApi.post('ctr/payment-methods/buy-sell-actions-manager.php', QueryString.stringify({actionType, paymentMethodID: paymentMethod.id, sw}))
    }
    
    const handleAvailableInCameroon = () => {
        if(!paymentMethod) return;
        axiosApi.post('ctr/payment-methods/set-availability-in-cameroon.php', QueryString.stringify({paymentMethodID: paymentMethod.id, sw}))
    }

    const handleAllowAutomaticTransaction = () => {
        if(!paymentMethod) return;
        axiosApi.post('ctr/payment-methods/set-allow-automatic-transaction.php', QueryString.stringify({paymentMethodID: paymentMethod.id, sw}))
    }

    const handleAcceptExternalMobileMoney = () => {
        if(!paymentMethod) return;
        axiosApi.post('ctr/payment-methods/set-accept-external-mobile-money.php', QueryString.stringify({paymentMethodID: paymentMethod.id, sw}))
    }
    

    if(!paymentMethod) return (
        <Typography sx={{
            textAlign: 'center',
            mt: 5
        }}>
            Chargement...
        </Typography>
    )
    

    return (
            
        <div style={{maxWidth: '98vw', margin: 'auto', marginTop: '30px'}}>
            
            <Typography sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Avatar src={ paymentMethod.logo } sx={{width: 60, height: 60}} />

            </Typography>

            <Typography variant='h5' sx={{mt: 2, textAlign: 'center'}}>
                { paymentMethod.name }
            </Typography>

            <Box
                sx={{
                    mt: 7,
                    p: 2,
                    boxShadow: 1,
                    borderRadius: 2
                }}>

                <Box
                    component="form"   
                    id="form_add_paymentMethod"
                    sx={{
                        mb: 2,
                        display: 'grid', 
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gap: 3
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={setPaymentMethodOnServer}
                    >

                        <TextField
                            required
                            name='name'
                            id="outlined-required"
                            label="Nom devise"
                            defaultValue={paymentMethod.name}
                        />

                        <TextField
                            required
                            name="symbol"
                            id="outlined-disabled"
                            label="Symbole devise"
                            defaultValue={paymentMethod.symbol}
                        />

                        <TextField
                            required
                            name='address'
                            id="outlined-required"
                            label="Numéro du compte"
                            defaultValue={paymentMethod.address}
                        />

                        <TextField
                            required
                            name='accountOwnerName'
                            id="outlined-required"
                            label="Nom attaché au compte"
                            defaultValue={paymentMethod.accountOwnerName}
                        />
                        
                        <TextField
                            required
                            name='logo'
                            id="outlined-required"
                            label="Lien du logo"
                            defaultValue={paymentMethod.logo}
                        />

                        <TextField
                            required
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='dollarPurchasePrice'
                            label="Prix achat dollar"
                            defaultValue={paymentMethod.dollarPurchasePrice}
                        />

                        <TextField
                            required
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='dollarSalePrice'
                            label="Prix vente dollar"
                            defaultValue={paymentMethod.dollarSalePrice}
                        />

                        <div></div>

                        <TextField
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='maxBuyAmount'
                            label="Max achat"
                            defaultValue={paymentMethod.maxBuyAmount}
                        />

                        <TextField
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='maxSellAmount'
                            label="Max vente"
                            defaultValue={paymentMethod.maxSellAmount}
                        />

                        <TextField
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='maxTimePaymentLimit'
                            label="Temps d'attente de paiement max (minutes)"
                            defaultValue={paymentMethod.maxTimePaymentLimit}
                        />

                        <TextField
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='percentagePurchaseTax'
                            label="% Taxe à l'achat (en FCFA)"
                            defaultValue={paymentMethod.percentagePurchaseTax}
                        />

                        <TextareaAutosize
                            aria-label="Raison taxe à l'achat"
                            name='purchaseTaxReason'
                            id="purchaseTaxReason"
                            minRows={2}
                            defaultValue={paymentMethod.purchaseTaxReason}
                            placeholder="Raison taxe à l'achat"
                            style={{ padding:'10px', borderRadius:'5px', border: "1px solid #bbb" }}
                        />

                        <TextField
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='percentageSaleTax'
                            label="% Taxe à la vente (en FCFA)"
                            defaultValue={paymentMethod.percentageSaleTax}
                        /> 

                        <TextareaAutosize
                            aria-label="Raison taxe à la vente"
                            name='saleTaxReason'
                            id="saleTaxReason"
                            minRows={2}
                            defaultValue={paymentMethod.saleTaxReason}
                            placeholder="Raison taxe à la vente"
                            style={{ padding:'10px', borderRadius:'5px', border: "1px solid #bbb" }}
                        />

                        <Button 
                            type='submit' 
                            variant='contained'
                            fullWidth 
                            sx={{p:1.9, mr:2, maxHeight: 4}}
                            >
                            Mettre à jour
                        </Button>

                </Box>


                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    value={sw}
                    onChange={(e) => setSw(e.target.value)}
                    sx={{mt: 2}}
                />




                <Typography sx={{mt:7, mb:2, fontWeight: 'bold'}}>
                    Alertes
                </Typography>

                <Box
                    component='form'
                    onSubmit={setAlertsOnServer}
                    sx={{
                        mb: 2,
                        display: 'grid', 
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gap: 3
                    }}
                >

                    <TextareaAutosize
                                aria-label="Alerte achat"
                                name='alertMessageForBuy'
                                id="alertMessageForBuy"
                                minRows={2}
                                defaultValue={paymentMethod.alertMessageForBuy}
                                placeholder="Alerte achat"
                                style={{ padding:'10px', borderRadius:'5px', border: "1px solid #bbb" }}
                                />

                    <FormControl fullWidth>

                        <InputLabel id="select_payment_method_label1">Moyen alternatif achat</InputLabel>

                        <Select
                            labelId="select_payment_method_label1"
                            id="demo-simple-select"
                            name='alternativeForBuy'
                            label="Moyen alternatif achat"
                            defaultValue={paymentMethod.alternativeForBuy}
                            fullWidth
                            >

                            {
                                paymentMethods.map((method: paymentMethodType, index) => {

                                    return (
                                        <MenuItem 
                                            key={index} 
                                            value={method.symbol} 
                                        > 
                                            <Avatar src={method.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                            <span>{method.symbol + " (" + method.name + ")"}</span>
                                        </MenuItem>
                                    )
                                })
                            }

                        </Select>

                    </FormControl>

                    <TextareaAutosize
                                aria-label="Alerte Vente"
                                name='alertMessageForSell'
                                id="alertMessageForSell"
                                minRows={2}
                                defaultValue={paymentMethod.alertMessageForSell}
                                placeholder="Alerte Vente"
                                style={{ padding:'10px', borderRadius:'5px', border: "1px solid #bbb" }}
                                />

                    <FormControl fullWidth>

                        <InputLabel id="select_payment_method_label2">Moyen alternatif Vente</InputLabel>

                        <Select
                            labelId="select_payment_method_label2"
                            id="demo-simple-select"
                            name='alternativeForSell'
                            label="Moyen alternatif Vente"
                            defaultValue={paymentMethod.alternativeForSell}
                            fullWidth
                            >

                            {
                                paymentMethods.map((method: paymentMethodType, index) => {

                                    return (
                                        <MenuItem 
                                            key={index} 
                                            value={method.symbol} 
                                        > 
                                            <Avatar src={method.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                            <span>{method.symbol + " (" + method.name + ")"}</span>
                                        </MenuItem>
                                    )
                                })
                            }

                        </Select>

                    </FormControl>

                    <Button
                        fullWidth
                        variant='contained'
                        type='submit'
                        sx={{p:1.9, mr:2, maxHeight: 4}}
                    >
                        Enregistrer
                    </Button>

                </Box>

                <Typography sx={{mt:7, mb:2, fontWeight: 'bold'}}>
                    Accepte les transferts internationaux Mobile Money :
                </Typography>

                <Chip
                    label="Transferts internationaux Mobile Money vers Cameroun"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={
                                    () => handleAcceptExternalMobileMoney()
                                }
                                defaultChecked={paymentMethod.acceptExternalMobileMoney && paymentMethod.acceptExternalMobileMoney == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                    />

                <Typography sx={{mt:7, mb:2, fontWeight: 'bold'}}>
                    Disponibilité au Cameroun :
                </Typography>

                <Chip
                    label="Disponible au Cameroun"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={
                                    () => handleAvailableInCameroon()
                                }
                                defaultChecked={paymentMethod.availableInCameroon && paymentMethod.availableInCameroon == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />

                <Typography sx={{mt:7, mb:2, fontWeight: 'bold'}}>
                    Activer les transactions automatiques :
                </Typography>

                <Chip
                    label="Transactions automatiques"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={
                                    () => handleAllowAutomaticTransaction()
                                }
                                defaultChecked={paymentMethod.allowAutomaticTransaction && paymentMethod.allowAutomaticTransaction == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />

                <Typography sx={{mt:7, mb:2, fontWeight: 'bold'}}>
                    Action d'achat et de vente :
                </Typography>

                <Chip
                    label="Autoriser l'achat"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={
                                    () => handleManageBuyAndSellAction('buy')
                                }
                                defaultChecked={paymentMethod.allowForBuy && paymentMethod.allowForBuy == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                    />

                <Chip
                    label="Autoriser la vente"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={
                                    () => handleManageBuyAndSellAction('sell')
                                }
                                defaultChecked={paymentMethod.allowForSell && paymentMethod.allowForSell == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                    />

            </Box>

        </div>
    )
}
