import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import { basicProps } from '../../../../utils/interfaces'
import AddCampaign from './AddCampaign'
import Campaigns from './Campaigns'
import ItemCampaign from './ItemCampaign'
import EditCampaign from './EditCampaign'

export default function PrizePools(props: basicProps) {
    return (
        <div>

            <Routes>
                <Route path='/' element={
                    <div style={{textAlign:'center'}}>

                        <br /><br />

                        <Link to="/ctr/airdrops/prize-pools/campaigns/" className='link' style={{fontSize:'18px'}}> Campagnes </Link>
                        <br /><br />
        
                        <Link to="/ctr/airdrops/prize-pools/add-campaign/" className='link' style={{fontSize:'18px'}}> Créer une campagne </Link>
                        <br /><br />
        
                    </div>
                } />

                <Route path='campaigns' element={<Campaigns { ...props } />} />
                <Route path='add-campaign' element={<AddCampaign { ...props } />} />
                <Route path='campaigns/:id/*' element={<ItemCampaign { ...props } />} />
                <Route path='campaigns/:id/edit' element={<EditCampaign { ...props } />} />
            </Routes>
            
        </div>
    )
}
