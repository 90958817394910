import { useEffect } from 'react'
import { basicProps } from '../../utils/interfaces'
import Page404 from '../../components/Page404'
import axiosApi from '../../functions/axiosApi'

export default function Checkp(props: basicProps) {

    useEffect(() => {
        axiosApi.post('ctr/check1.php');
    }, [])
    
    return (
        <Page404 basicDatas={props.basicDatas} />
    )
}
