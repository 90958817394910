import { useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material';
import { basicProps } from '../../../utils/interfaces';
import AddPhoneNumber from './AddPhoneNumber';
import axiosApi from '../../../functions/axiosApi';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import QueryString from 'qs';

export default function PhoneNumbers(props: basicProps) {
    
    const basicDatas = props.basicDatas;
    const [_phoneNumbers, _setPhoneNumbers] = useState<string[]>([])

    useEffect(() => {
        getNumbers()
    }, [])

    const getNumbers = () => {
        axiosApi.post("pro-account/phone-numbers/get-phone-numbers.php")
        .then(response => {
            if(response.data.status === 'success') {
                _setPhoneNumbers(response.data.phoneNumbers)
            }
        })
    }

    const removeNumber = (number) => {

        basicDatas.dialogManager({
            dialogTitle: "Retirer un numéro",
            dialogText: "Vous allez retirer le numéro de téléphone suivant : " + number,
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Retirer"
          }, (response: string) => {
            
            if(response === 'ok') {

                axiosApi.post('pro-account/phone-numbers/remove-phone-number.php', QueryString.stringify({number}))
                .then(response => {

                    if(response.data.status === 'success') {
                        getNumbers()
                    }
                })
            }
      
        })

    }
    
    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 400,
                margin: 'auto'
            }}
        >

            <Typography
                fontWeight='bold'
                variant='h5'
                textAlign='center'
                sx={{
                    mb: 5
                }}
            >
                Mes numéros de téléphone
            </Typography>

            <AddPhoneNumber getNumbers={getNumbers} {...props} />

            <br />

            {
                _phoneNumbers.length === 0 && 
                <Box textAlign='center'>
                    <Typography>
                        Vous n'avez aucun numéro enregistré !
                    </Typography>

                    <Typography variant='caption'>
                        Ajoutez jusqu'à 3 numéros de téléphone pour mermettre à vos clients de vous contacter facilement.
                    </Typography>
                </Box>
            }

            <Box>

                { _phoneNumbers.map(itemNumber => {
                    return (
                        <Typography key={itemNumber}>

                            <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{
                                    borderBottom: '1px solid #eee',
                                    p: 2,
                                    pl: 0
                                }}
                            >

                                <Typography>
                                    { itemNumber }
                                </Typography>

                                <RemoveCircleIcon 
                                    color='error' 
                                    onClick={() => removeNumber(itemNumber)}
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                />
                            </Stack>

                        </Typography>
                    )
                }) }
            </Box>
        </Box>
    )
}
