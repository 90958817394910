import { useState } from 'react'
import { basicProps } from '../../utils/interfaces';
import { Typography, Stack, Avatar, Alert, AlertTitle, Tooltip } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { copyClipboard } from '../../functions/basicFunctions';
import { MetaTags } from 'react-meta-tags';

export default function CommissionsRecap(props: basicProps) {

    const basicDatas = props.basicDatas;
    const [linkCopied, setLinkCopied] = useState(false);

    return (
        <div style={{maxWidth: '600px', margin:'auto', marginTop:'50px'}}>

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Comment sont calculées vos commissions de parrainage chez CRYPTO-FIAT</title>
                <meta name="description" content="CRYPTO-FIAT | Comment sont calculées vos commissions de parrainage chez CRYPTO-FIAT" />
                <meta property="og:title" content="CRYPTO-FIAT | Comment sont calculées vos commissions de parrainage chez CRYPTO-FIAT" />
            </MetaTags>

            <Stack direction='row' justifyContent='center' sx={{mb:2}}>
                <Avatar className='text-success' sx={{backgroundColor:'#E8F3EE'}}>
                    <AttachMoneyIcon />
                </Avatar>
            </Stack>
            
            <Typography variant='h5' className='text-center' sx={{mb:3}}>
                Comment sont calculées vos commissions de parrainage chez { basicDatas.appName } ?
            </Typography>

            <Alert severity="info" sx={{marginTop:'40px', marginBottom:'30px', textAlign:'left', bgcolor:'rgba(245,245,245, 0.8)'}}>
                <AlertTitle style={{fontWeight:'bold', fontSize: 20}}>Fonctionnement</AlertTitle>

                Lorsque vous partagez votre lien d'invitation et que vos amis s'inscrivent sur { basicDatas.appName }, vous recevez des commissions à chaque transaction effectuée par vos filleuls. 
                <br /><br />
                Pour chaque transaction effectuée par votre filleul, vous pouvez recevoir une commission à partir de 25 FCFA.

                <br /><br />

                Le montant de la commission dépend de plusieurs éléments, notamment :
                <br />

                👉 Le montant échangé dans la transaction ; <br />
                👉 Le type de transaction (Achat ou Vente) ; <br />
                👉 Le réseau blockchain utilisé ; <br />
                👉 L'actif acheté ou vendu (ex : BTC, USDT...) <br />

                <br />

                <b>Exemples de calcul de commission : </b> <br /> <br />

                Votre filleul effectue un achat de 100 USDT. Vous toucherez environ 100 FCFA de commission.
                <br />
                Votre filleul effectue une vente de 100 USDT. Vous toucherez environ 200 FCFA de commission.
                
                <br /> <br />
                <b>Rejoignez notre programme de parrainage dès maintenant et commencez à gagner des commissions sur les transactions de vos amis !</b>

                <Typography 
                    sx={{
                        mb: 2, 
                        mt: 3,
                        bgcolor: '#fff', 
                        p:2,
                        textAlign: 'left',
                        borderRadius: 1
                    }}
                >
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >

                        <Typography fontWeight='bold' sx={{fontSize: 13}}>
                            { "https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id }
                        </Typography>

                        <Typography>

                            <Tooltip title="Cliquez pour copier le lien">

                                {
                                    !linkCopied 
                                    ?   <ContentCopyIcon 
                                            sx={{cursor: 'pointer'}} 
                                            onClick={() => { copyClipboard("https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id, "Le lien a été copié !"); setLinkCopied(true)}}
                                        />
                                    :   <CheckCircleIcon 
                                            className="text-success" 
                                            sx={{cursor: 'pointer'}} 
                                            onClick={() => { copyClipboard("https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id, "Le lien a été copié !"); setLinkCopied(true)}}
                                        />
                                }

                            </Tooltip>

                            <Tooltip title="Partager via WhatsApp">
                                <a 
                                    href={"https://wa.me/?text=Bonjour! Utilisez mon lien pour rejoindre CRYPTO-FIAT et commencez à acheter et vendre vos cryptos en toute simplicité : https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id }
                                    target='_blank'
                                >
                                    <WhatsAppIcon sx={{color:'#128C7E', ml: 3, cursor: 'pointer'}} />
                                </a>
                            </Tooltip>

                        </Typography>

                    </Stack>
                </Typography>

            </Alert>

        </div>
    )
}
