import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomeHelp from '../HomeHelp'
import OtpHelp from './OtpHelp'

export default function AuthHelp() {
    return (
        <div>
            <Routes>
                <Route path='/' element={<HomeHelp/>} />
                <Route path='/otp' element={<OtpHelp />} />
            </Routes>
        </div>
    )
}
