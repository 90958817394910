import { 
    Alert, AlertTitle, Avatar, Button, Chip, Tooltip,
    TableContainer, Table, Paper, TableBody, TableRow, Typography
} from '@mui/material';
import { useState } from 'react'
import { basicProps, orderType } from '../../../utils/interfaces';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ShowOrderStaus from './ShowOrderStaus';
import { Link, useLocation } from 'react-router-dom';
import { copyClipboard, isPC, readabilityNumber } from '../../../functions/basicFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import InfoPopup from '../../../components/InfoPopup';
import AdminMessage from './AdminMessage';
import ListInstructions from './ListInstructions';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface buyInstructionsProps extends basicProps {
    orderData: orderType;
    confirmPayment: () => void,
    cancelOrder: () => void
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function BuyInstructions(props: buyInstructionsProps) {

    const basicDatas = props.basicDatas;
    const location = useLocation();
    const [addressCopied, setAddressCopied] = useState(false);

    const orderData = props.orderData;
    const orderBasicInfos = orderData.basic;
    const paymentMethod = orderData.paymentMethod;
    const cryptoCurrency = orderData.cryptoCurrency;
    const network = orderData.network;
    const mobileMoneyProAccount = paymentMethod?.mobileMoneyProAccount;
    const useProAccount = mobileMoneyProAccount && (parseInt(mobileMoneyProAccount.activated + '') === 1)

    let amountReceived = orderBasicInfos.networkFee ? (parseFloat(orderBasicInfos.cryptoQte as string) - parseFloat(orderBasicInfos.networkFee + '')) : orderBasicInfos.cryptoQte;
    amountReceived = readabilityNumber(amountReceived);

    if((orderBasicInfos.status !== 'waiting_payment') && (orderBasicInfos.status !== 'marked_paid'))
    {
        return (
            <>
                <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />
                <AdminMessage order={orderData}/>
            </>
        )
    }

    return (
        <div>

            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'20px'}}>
                <Avatar src={paymentMethod.logo} sx={{width:'100px', height:'100px'}} />
            </div>
            
            <b>{ paymentMethod.name }</b>

            <br /> <br />

            {
                useProAccount 

                ?

                <Tooltip title="Composer le code">

                    <Typography>

                        <Alert 
                            severity="info" 
                            sx={{
                                textAlign:'left',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0
                            }}
                        >
                            <Typography variant='caption'>
                                Cliquez sur le code pour composer directement
                            </Typography>
                        </Alert>

                        <a href={ "tel:" + mobileMoneyProAccount.cashOutCode.replace('amount', orderData.basic.fiatQte + '').replaceAll('#', ' %23') }>

                            <Button
                                fullWidth
                                sx={{
                                    boxShadow: 2,
                                    p: 2,
                                    mb: 2,
                                    display: 'inline-block',
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0
                                }}
                            >
                                <Typography fontWeight='bold' fontSize={isPC ? '25px' : '20px'}>
                                    { mobileMoneyProAccount.cashOutCode.replace('amount', orderData.basic.fiatQte + '') }
                                </Typography>
                                <Typography variant='caption'>
                                    { mobileMoneyProAccount.accountOwnerName }
                                </Typography>
                            </Button>
                        </a>

                    </Typography>
                    
                </Tooltip>

                :

                <Tooltip title="Cliquez pour copier le numéro">
                    <Chip
                            label={<>{ paymentMethod.address } <span className='text-muted' style={{fontSize:'small'}}>{ paymentMethod.accountOwnerName }</span></>}
                            avatar={
                                !addressCopied 
                                ? <ContentCopyIcon sx={{fontSize:'small'}} />
                                : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                            }
                            variant="outlined"
                            sx={{
                                fontWeight:'bold', 
                                fontSize:'20px', 
                            }}
                            onClick={() => {copyClipboard(paymentMethod.address, "Numéro copié !"); setAddressCopied(true)}}
                        />
                </Tooltip>
            }

            <div style={{fontWeight:'bold', marginTop:'15px', fontSize:'25px'}}>
                { readabilityNumber(orderData.basic.fiatQte) + " FCFA"}
            </div>

            {
                orderBasicInfos.networkFee &&

                <TableContainer component={Paper}  sx={{mt:4}}>
                    <Table aria-label="customized table">

                        <TableBody>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Quantité crypto
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => copyClipboard(orderBasicInfos.cryptoQte + '', "Montant copié !")}
                                    >
                                    <b>{ readabilityNumber(orderBasicInfos.cryptoQte) + " " + cryptoCurrency.symbol}</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Frais réseau

                                    <InfoPopup basicDatas={basicDatas}
                                                title="Pourquoi les frais de réseau ?"
                                                text={
                                                    <div>
                                                        Ces frais sont dus au fait que les frais de gas sur le réseau { network.symbol + " (" + network.name + ")" } sont élévés. Alors l'utilisateur paye une partie et CRYPTO-FIAT prend en charge le reste.

                                                        <br /> <br />

                                                        <div style={{textAlign: 'right'}} className='small'>
                                                            <a href="https://academy.binance.com/fr/articles/what-are-blockchain-transaction-fees" target={'_blank'}>
                                                                C'est quoi les frais de gas ?
                                                            </a>
                                                        </div>
                                                    </div>
                                                }>
                                        <span   className='small text-primary'
                                                style={{
                                                    marginLeft: '20px',
                                                    fontStyle: 'oblique',
                                                    cursor: 'pointer',
                                                    float: 'right'
                                                }}> 
                                                    Pourquoi ces frais ?
                                        </span>
                                    </InfoPopup>

                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <b>{ readabilityNumber(orderBasicInfos?.networkFee) + " " + cryptoCurrency.symbol }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Montant net à recevoir
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <b>{amountReceived +  " " + cryptoCurrency.symbol}</b>
                                </StyledTableCell>
                            </StyledTableRow>
                        
                        </TableBody>
                    </Table>
                </TableContainer>
            }


            {
                ((orderBasicInfos.status === 'waiting_payment') && (!location.search.match('recap'))) &&

                <Alert severity="warning" sx={{marginTop:'30px', marginBottom:'20px', textAlign:'left'}}>
                    
                    <AlertTitle style={{fontWeight:'bold'}}>Geste important</AlertTitle>

                    Votre ordre a été créé avec succès. Mais avant de procéder au paiement, nous vous invitons à jetter un dernier coup d'oeil sur le 
                    <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link text-primary"> récapitulatif</Link>.

                    <br />

                    <div style={{textAlign:'right', marginTop:'10px'}}>
                        <Link to={"/orders/" + orderBasicInfos.orderID + "/"} className="link">
                            <Button variant='contained' color='inherit'>
                                Voir le récapitulatif 
                            </Button> 
                        </Link>
                    </div>

                </Alert>
            }

            <ShowOrderStaus order={orderData} {...props} style={{marginTop:'30px'}} />

            <ListInstructions
                instructions={[
                    {
                        step: 1,
                        title: "Effectuez le paiement",
                        content: (
                            <>

                                {
                                    useProAccount
                                    ?
                                        <>
                                            Composez le code ci-dessous pour effectuer le paiement via <b>{ paymentMethod.name }</b>.

                                            <Typography sx={{mt:2}}>
                                                <a 
                                                    href={'tel:' + mobileMoneyProAccount.cashOutCode.replace('amount', orderData.basic.fiatQte + '').replaceAll('#', ' %23') }
                                                    className='link text-primary'
                                                    style={{
                                                        fontSize: isPC ? 'large' : 'normal',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    { mobileMoneyProAccount.cashOutCode.replace('amount', orderData.basic.fiatQte + '') }
                                                </a>
                                            </Typography>

                                            <Typography>
                                                <Typography variant='caption'>
                                                    Vérifiez bien que le nom qui s'affiche est <b>{ mobileMoneyProAccount.accountOwnerName }</b>
                                                </Typography>
                                            </Typography>

                                            <Alert 
                                                severity="info" 
                                                sx={{
                                                    mt: 2,
                                                    textAlign:'left',
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0
                                                }}
                                            >
                                                <Typography variant='caption'>
                                                    Besoin d'aide ? <a target='_blank' href={"https://wa.me/+237650839431/?text=Bonjour, je ne sais pas comment composer le code pour effectuer le paiement via " + paymentMethod.name }><WhatsAppIcon fontSize='small' sx={{ml:2}} /> Contactez-nous</a>
                                                </Typography>
                                            </Alert>
                                        </>
                                    :

                                    <>
                                        Envoyez <b>{ readabilityNumber(orderData.basic.fiatQte) + " FCFA"}</b> par <b>{paymentMethod.name + " "}</b>
                                        au numéro <b>{ paymentMethod.address }</b> <b style={{fontStyle:'oblique', fontSize:'small'}}>({ paymentMethod.accountOwnerName})</b>.
                                    </>
                                }
                                
                            </>
                        )
                    },

                    {
                        step: 2,
                        title: "Marquez l'ordre comme étant payé",
                        content: (
                            <>
                                Cliquez sur <b>MARQUER PAYÉ</b> en bas de page. <br />
                                {   orderBasicInfos.status === 'waiting_payment' && 
                                    <Typography variant='caption' color='error'>Si vous ne marquez pas comme étant payé, l'ordre ne sera pas traité et finira par être annulé pour delai dépassé.</Typography>
                                }
                            </>
                        )
                    }
                ]}

                receptionAddressNote={
                    <>
                        Lorsque nous recevons votre paiement, vous recevrez <b className='cursor-pointer' onClick={() => copyClipboard(amountReceived as string, "La quantité a été copiée !")}>{ amountReceived + " " + cryptoCurrency.symbol + " " }</b>
                        dans votre portefeuille crypto à l'adresse <b className='cursor-pointer' onClick={() => copyClipboard(orderData.basic.blockchainAddress, "L'adresse a été copié dans le presse-paier.")}>{orderData.basic.blockchainAddress}</b>
                        {orderBasicInfos.memo && <span> memo :  <b className='cursor-pointer' onClick={() => copyClipboard(orderBasicInfos.memo ? orderBasicInfos.memo : '', "Memo copié !")}>{ orderBasicInfos.memo }</b></span>} sur le réseau <b>{network.symbol}</b>.
                    </>
                }
                note={
                    <>
                        <b>Note :</b> Veuillez noter que si nous ne recevons pas votre paiement dans un delai de <b>{ paymentMethod.maxTimePaymentLimit + ' minutes' }</b> à partir du moment où l'ordre a été créé, il sera automatiquent annulé.
                    </>
                }
            />
            
                

            {
                (orderBasicInfos.status === 'waiting_payment') &&

                <>
                    <br />
                    <Button type='button' variant='contained' startIcon={<DoneAllIcon />} onClick={props.confirmPayment}>
                        MARQUER PAYÉ
                    </Button>

                </>
            }

            {
                (orderBasicInfos.status === 'waiting_payment') &&

                <>
                    <br /> <br /> <br />

                    <Button type='button' variant='contained' color='inherit' startIcon={<CancelIcon fontSize='small' color='error'/>} onClick={props.cancelOrder}>
                        Annuler l'ordre
                    </Button>
                </>
            }

            
        </div>
    )
}
