import { useEffect } from 'react'
import { basicProps } from '../utils/interfaces';
import { Avatar, Box, Typography } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from 'react-router-dom';
import { isPC, publicPath } from '../functions/basicFunctions';

interface myProps extends basicProps {
    openKycInvitDialog?: boolean,
    handleCloseKycInvitDialog?: () => void
}

export default function KycInvitationPopup(props: myProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;
    const navigate = useNavigate();

    useEffect(() => {

        handleDialog();

    }, [basicDatas.isLoggedIn, props.openKycInvitDialog])


    const handleDialog = () => {

        if(basicDatas.isLoggedIn && authUser.vip_level === 'vip0') {

            const date = new Date();
    
            basicDatas.dialogManager({
                dialogTitle: '',
                dialogText: (
                    <Box sx={{
                        textAlign: 'center'
                    }}>
                        <VerifiedUserIcon
                                color='primary'
                                sx={{
                                    margin: 'auto',
                                    fontSize: 70,
                                    mb: 2
                                }} />

                        <Typography variant='h5' sx={{mb: 3, fontWeight: 'bold'}}>
                            Programme KYC
                        </Typography>

                        <Avatar 
                            src={ publicPath + "images/kyc_airdrop_page_banner.png" }
                            sx={{
                                width: '100%',
                                height: isPC ? '150px' : '85px',
                                borderRadius: '3px',
                                mb: 3
                            }} 
                        />

                        <Typography variant='h6' sx={{mb: 2}}>
                            {(date.getHours() < 13) ? 'Bonjour' : 'Bonsoir'} { (authUser.firstName && authUser.firstName !== '') ? authUser.firstName : authUser.lastName },
                        </Typography>

                        <Typography>
                            Nous avons mis en place le programme KYC pour renforcer la sécurité sur { basicDatas.appName }.
                            <br /><br />
                            <Typography variant='caption'>
                                Nous vous encourageons à vérifier votre identité pour contribuer à faire de { basicDatas.appName } un endroit encore plus sûr où chacun se sent en confiance.
                            </Typography>
                        </Typography>

                    </Box>
                ),
                dialogAgreeBtnColor: 'info',
                dalogCloseBtnText: <span style={{color: 'red'}} onClick={props?.handleCloseKycInvitDialog}>Plus tard</span>,
                dialogAgreeBtnText: 'Vérifier maintenant'
            }, (response) => {

                if(response === 'ok') {
                    navigate('/kyc/')
                }
            })
        }

    }

    return <></>
}
