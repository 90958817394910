import React from 'react'
import { Box, Stack, Avatar, Typography, Chip, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { basicProps } from '../../utils/interfaces';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { isPC } from '../../functions/basicFunctions';

interface myProps extends basicProps {
    vip_level: 'vip0' | 'vip00' | 'vip1'
}

export default function KycStatusBox(props: myProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;
    const vip_level = props.vip_level;
    let title = '';
    let titleColor = 'grayText';
    let description = '';

    if(vip_level === 'vip0') {
        title = "Compte non vérifié"
        titleColor = 'error';
        description = "Faites vérifier votre compte pour commencer à bénéficier de tous les services et fonctionnalités de " + basicDatas.appName + ".";
    }

    else if(vip_level === 'vip00') {
        title = "Documents en cours de vérification";
        titleColor = 'primary';
        description = "Vos documents ont bien été envoyés et sont en cours de vérification. Vous recevrez une notification du résultat de vérification par mail. La vérification de compte peut durer maximum 24h à partir de la date de soumission de documents. Si après 24h vous n'avez pas eu de réponse, veuillez contacter le service client via WhatsApp en cliquant sur le bouton WhatsApp en bas de page.";
    }

    else if(vip_level === 'vip1') {
        title = "Compte vérifié";
        titleColor = 'green';
        description = "Votre compte a été vérifié. Vous pouvez utiliser tous les services proposés et disponibles de " + basicDatas.appName + '.';
    }

    const MyBadge = () => {

        if(vip_level === 'vip0') return (
            <Chip label="VIP0" color='error' size='small' icon={<HighlightOffIcon />} />
        )

        if(vip_level === 'vip00') return (
            <Chip label="VIP00" color='primary' size='small' icon={<ScheduleIcon />} />
        )

        return (
            <Chip label="VIP1" color='success' size='small' icon={<CheckCircleOutlineIcon/>} />
        )
    }

    return (
        <Box 
            sx={{
                p: 2,
                boxShadow: 1,
                borderRadius: 2
            }}>

            <Stack spacing={2} direction="row" alignItems="center" sx={{mb: 3}}>
                <Stack>
                    <Avatar 
                        src={ authUser.avatarUrl }
                        sx={{
                            width: isPC ? 75 : 50,
                            height: isPC ? 75 : 50
                        }}  />
                </Stack>
                <Stack sx={{ minWidth: 0 }}>

                    <Typography variant={isPC ? 'h5' : 'h6'} color='GrayText' noWrap sx={{fontWeight: 'bold'}}>
                        { authUser.lastName + ' ' + authUser.firstName }
                    </Typography>

                    <Typography noWrap sx={{fontWeight: 'bold'}}>
                        <MyBadge />
                    </Typography>
                </Stack>
            </Stack>

            <Typography variant='h6' color={ titleColor } sx={{mb: 2}}>
                { title }
            </Typography>

            <Typography sx={{mb: 2}}>
                <Typography variant='caption' color='GrayText' sx={{mb: 2}}>
                    { description }
                </Typography>
            </Typography>

            {
                vip_level === 'vip0' &&
            
                <Typography sx={{textAlign: 'right'}}>
                    <Link to="/kyc/send-documents/" className='link'>
                        <Button 
                            variant='contained'
                            sx={{backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'}}
                            >
                            Vérifier maintenant
                        </Button>
                    </Link>
                </Typography>
            }

        </Box>
    )
}
