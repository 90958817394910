import { useState, useEffect } from 'react'
import { User, basicProps, currencyType } from '../../../../utils/interfaces'
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import { 
    Box, FormControl, InputLabel, Select, MenuItem, 
    Avatar, SelectChangeEvent, Typography, TextField, Button, TextareaAutosize 
} from '@mui/material';
import { cftTokenInfo, readClipboard } from '../../../../functions/basicFunctions';

export default function DepositFunds(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [currencies, setCurrencies] = useState<currencyType[]>([]);
    const [cryptoCurrency, setCryptoCurrency] = useState<string>('');
    const [receiverID, setReceiverID] = useState('');

    useEffect(() => {
        getCurrencies();
    }, [])
    

    const getCurrencies = () => {
        axiosApi.post('ctr/currencies/get-currencies.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){

                setCurrencies(response.data.currencies);
                var usdtArray = response.data.currencies.filter((coin: currencyType) => coin.symbol === cftTokenInfo.symbol);

                if(usdtArray.length !== 0){
                    setCryptoCurrency(usdtArray[0].symbol);
                }
               
            }
        })
    }

    const handleChangeCryptoCurrency = (e: SelectChangeEvent) => {

        setCryptoCurrency(e.target.value as string);

    }

    const readReceiverIDClipboard = () => {
        readClipboard((clipboardContent) => {
            if(clipboardContent && typeof clipboardContent === 'string') setReceiverID(clipboardContent)
        })
    }

    const validDeposit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Vérification en cours...");

        axiosApi.post("wallet/transfer/verify-receiver-account.php", data)
        .then(response => {
            if(response.data.status === 'success'){

                const receiverInfo: User = response.data.receiverAccount;

                basicDatas.dialogManager({
                    dialogTitle: 'Destinataire',
                    dialogText: (
                        <Box sx={{
                            textAlign: 'center'
                        }}>
                            <Avatar src={receiverInfo.avatarUrl}
                                    sx={{
                                        margin: 'auto',
                                        width: '60px',
                                        height: '60px',
                                        boxShadow: 1,
                                        mb: 2
                                    }} />

                            <Typography sx={{mb: 3}}>
                                
                                { receiverInfo.firstName + ' ' + receiverInfo.lastName }

                                <br /> <br />

                                <b style={{fontSize: 'small'}}>{ "ID : " + receiverInfo.user_id}</b>

                            </Typography>

                            

                            <Typography variant='caption'>
                                Est-ce bien le compte auquel vous souhaitez transférer ?
                            </Typography>
                        </Box>
                    ),
                    dialogAgreeBtnColor: 'info',
                    dalogCloseBtnText: 'Non',
                    dialogAgreeBtnText: 'Confirmer'
                  }, (response) => {
                    
                    if(response === 'ok') {
                        
                        data.set('loaderText', "Envoi en cours...");

                        axiosApi.post("ctr/finances/deposit-funds.php", data)
                        .then(response => {

                            if(response.data.status === 'success') {
                                setReceiverID('');
                            }
                        })
                    }
              
                })
                
            }
        })
    }

    return (
        <Box sx={{
            maxWidth: 400,
            margin: 'auto'
        }}>

            <Typography variant='h5' sx={{mt:3, mb:5, textAlign: 'center'}}>
                Déposer des fonds
            </Typography>

            <Box>

                <FormControl 
                        component='form'
                        fullWidth 
                        onSubmit={ validDeposit }
                        sx={{
                            mb: 3
                        }}>

                    <InputLabel id="select_crypto_currency_label">Choisir un actif</InputLabel>
                    
                    <Select
                        name='asset'
                        label="Choisir un actif"
                        value={cryptoCurrency}
                        onChange={handleChangeCryptoCurrency}
                        fullWidth
                        margin="dense"
                        required
                        sx={{
                            mb: 2
                        }}
                        >

                        {
                            currencies.map((coin: currencyType, index) => {

                                return (
                                    <MenuItem key={index} value={coin.symbol}>
                                        <Avatar src={coin.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                        <span>{coin.symbol + " (" + coin.name + ")"}</span>
                                    </MenuItem>
                                )

                            })
                        }
         
                    </Select> 

                    <Typography sx={{textAlign: 'right'}}>
                        <Button color='info'
                                onClick={readReceiverIDClipboard}
                                >
                            Coller
                        </Button>
                    </Typography> 

                    <TextField
                        name='receiverID'
                        value={receiverID}
                        onChange={(e) => setReceiverID(e.target.value)}
                        label="ID du compte client"
                        placeholder='ID client'
                        fullWidth
                        required
                        sx={{
                            mb: 2
                        }} />

                    <FormControl 
                            fullWidth 
                            sx={{
                                mb: 2
                            }}>

                        <InputLabel id="select_transaction_type_label">Type de transaction</InputLabel>
                        
                        <Select
                            labelId="select_transaction_type_label"
                            name='type'
                            defaultValue='deposit'
                            label="Type de transaction"
                            fullWidth
                            margin="dense"
                            required
                            >

                            {
                                ['deposit', 'bonus', 'rewards'].map((type, index) => {

                                    return (
                                        <MenuItem key={ index } value={ type }>
                                            { type }
                                        </MenuItem>
                                    )

                                })
                            }
            
                        </Select>        

                    </FormControl>

                    <TextField
                        type='number'
                        name='amount'
                        label="Montant à créditer"
                        placeholder='Montant'
                        fullWidth
                        required
                        inputProps={{
                            step: 'any'
                        }}
                        sx={{
                            mb: 2
                        }} />

                    
                    <TextareaAutosize
                        aria-label="Message"
                        name='message'
                        minRows={2}
                        placeholder='Ajouter un message'
                        style={{ 
                            width:'100%', 
                            padding:'10px', 
                            borderRadius:'5px', 
                            border: "1px solid #bbb"
                        }}
                        />

                    <TextField
                        type='password'
                        name='pinCode'
                        label="Code PIN"
                        placeholder='PIN'
                        fullWidth
                        required
                        sx={{
                            mb: 3,
                            mt: 2
                        }} /> 

                    <TextField
                        type='password'
                        label="sw"
                        name='sw'
                        fullWidth
                        sx={{mb: 3}}
                    />

                    <Button
                        type='submit'
                        variant='contained'
                        fullWidth
                        sx={{

                        }}>
                        Valider
                    </Button>   

                </FormControl>

            </Box>

        </Box>
    )
}
