import { Chip } from '@mui/material'
import { orderActionType } from '../utils/interfaces'

export default function OrderTypeBadge(props: {type: orderActionType}) {

    let label = 'Achat';
    let color = '#338F5C';
    let bgColor = '#E8F3EE';

    switch (props.type) {
        case 'buy':
            label = 'Achat';
            color = '#338F5C';
            bgColor = '#E8F3EE';
        break;

        case 'sell':
            label = 'Vente';
            color = '#E5593C';
            bgColor = '#FDEDED';
        break;

        case 'swap':
            label = 'Swap';
            color = 'var(--site-color)';
            bgColor = 'rgba(167, 202, 237, 0.2)';
        break;
    
        default:
            label = 'Achat';
            color = '#338F5C';
            bgColor = '#E8F3EE';
        break;
    }

    return (
        <Chip label={ label } 
            sx={{
                backgroundColor: bgColor,
                color: color,
                borderRadius: 1
            }} 
            variant="filled" 
            size="small" 
        />
    )
}
