import { useState, useEffect } from 'react'
import { cftTokenInfo, copyClipboard } from '../../../../functions/basicFunctions';
import { 
    Typography, ToggleButtonGroup, ToggleButton, Badge, TextField,
    List, ListItem, ListItemAvatar, Avatar, ListItemText, Tooltip,
    Divider, Chip, Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import moment from 'moment';
import OrderStatusLabel from '../../../../components/OrderStatusLabel';
import { basicProps, User } from '../../../../utils/interfaces';
import ConfirmOrderForm from './ConfirmOrderForm';
import CancelOrderForm from './CancelOrderForm';

interface claimOrderStatusCountType {
    all_count: number,
    waiting_payment: number,
    canceled_by_admin: number,
    completed: number
}

export default function CftAirdrop(props: basicProps) {

    const [claimOrders, setClaimOrders] = useState([]);
    const [claimOrderStatusCount, setClaimOrderStatusCount] = useState<claimOrderStatusCountType>();
    const [status, setStatus] = useState('all');
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getClaimOrders();
    }, [status, queryValue])

    const getClaimOrders = () => {

        setIsLoading(true);

        axiosApi.post("ctr/airdrops/cft-token/claim-orders.php", 
        QueryString.stringify({status, offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newOrdersArray = claimOrders.concat(response.data.orders);
                setClaimOrders(newOrdersArray);
                setOffset(offset + 5);
                setClaimOrderStatusCount(response.data.claimOrderStatusCount);

                if(response.data.orders.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }


    return (
        <div style={{maxWidth:'600px', margin: 'auto'}}>
            
            <Typography variant='h5' sx={{mt:4, mb:5, textAlign:'center'}}>
                { cftTokenInfo.name + " airdrop"}
            </Typography>

            <div className="text-center">
                <ToggleButtonGroup
                    color="primary"
                    value={status}
                    exclusive
                    onChange={
                        (e, newStatus) => {
                            setStatus(newStatus);
                            setClaimOrders([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    sx={{
                        maxWidth: '100%',
                        overflow: 'auto'
                    }}
                    aria-label="order-status"
                    >
                    
                    <ToggleButton value="all" sx={{minWidth: '150px'}}>
                        Tout ({claimOrderStatusCount ? claimOrderStatusCount.all_count : 0})
                    </ToggleButton>

                    <ToggleButton value="waiting_payment" sx={{minWidth: '210px'}}>
                        <Badge max={9999999999999} badgeContent={claimOrderStatusCount ? claimOrderStatusCount.waiting_payment : 0} color="primary">
                            En attente paiement
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="completed" sx={{minWidth: '100px'}}>
                        <Badge max={9999999999999} badgeContent={claimOrderStatusCount ? claimOrderStatusCount.completed : 0} color="success">
                            Payé
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="canceled_by_admin" sx={{minWidth: '200px'}}>
                        <Badge max={9999999999999} badgeContent={claimOrderStatusCount ? claimOrderStatusCount.canceled_by_admin : 0} color="error">
                            Annulé par admin
                        </Badge>
                    </ToggleButton>

                </ToggleButtonGroup>

                <br /> <br />

                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher un ordre : ID" 
                    variant="outlined"
                    placeholder="Entrez l'ID de l'ordre"
                    value={queryValue}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setClaimOrders([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    />

            </div>

            <List sx={{ bgcolor: 'background.paper', margin:'auto', mt:5 }}>
                
                {
                    claimOrders.map((order: any, orderIndex) => {

                        const basicInfos = order.basic;
                        const user: User = order.user;

                        return (

                            <div key={orderIndex}>

                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={user.lastName} src={user.avatarUrl} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>

                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{fontWeight:'bold'}}
                                                > 
                                                    <Tooltip title="Cliquez pour copier l'adresse">
                                                        <span style={{cursor:'pointer'}} onClick={() => copyClipboard(basicInfos.bscAddress, "L'ID de l'adresse a été copiée !")}>{"Adresse : " + basicInfos.bscAddress}</span>
                                                    </Tooltip>
                                                </Typography>


                                                <div>{user.lastName + " " + user.firstName}</div>

                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Telegram : { basicInfos.telegramName }
                                                </Typography>
                                                
                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Statut : <OrderStatusLabel orderStatus={basicInfos.status} />
                                                </Typography>
                                            </>
                                        }
                                        secondary={
                                            <>
                                                <div>{moment(basicInfos.date).fromNow()}</div>

                                                <br />

                                                {
                                                    ((basicInfos.status === 'waiting_payment') || (basicInfos.status === 'marked_paid')) &&
                                                    <ConfirmOrderForm orderData={order} {...props} />
                                                }

                                                {
                                                    ((basicInfos.status === 'waiting_payment') || (basicInfos.status === 'marked_paid')) &&
                                                    <CancelOrderForm orderData={order} {...props} />
                                                } 

                                            </>
                                        }
                                    />
                                </ListItem>
                                
                                <Divider variant="inset" component="li" sx={{mt:4, mb:4}} />

                            </div>
                        )
                    })
                }
            </List>

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getClaimOrders()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </div>
    )
}
