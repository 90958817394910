import { useState } from 'react'
import { basicProps } from '../../utils/interfaces'
import { Alert, AlertTitle, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Typography } from '@mui/material'
import MessageIcon from '@mui/icons-material/Message';

interface myProps extends basicProps{
    adminMessage: string
}

export default function LastMotifMessage(props: myProps) {

    const [open, setOpen] = useState(true);
    const [read, setRead] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog 
            open={open} 
        >

            <DialogTitle>Conseils KYC</DialogTitle>

            <DialogContent sx={{minWidth: 300}}>

                <DialogContentText>

                    <Typography
                        sx={{
                            mb: 2,
                            fontSize: 'small'
                        }}
                    >
                        Votre dernière demande a été refusée. Le validateur a expliqué ce refus en fournissant le motif suivant :
                    </Typography>

                    <Alert icon={ <MessageIcon /> } severity="error" sx={{mb: 2, textAlign:'left'}} {...props}>
                        <AlertTitle sx={{fontWeight: 'bold', mb: 2}}>Message du validateur</AlertTitle>
                        <div dangerouslySetInnerHTML={{__html: props.adminMessage}} />
                    </Alert>

                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={read} 
                                    onChange={() => setRead(!read)}
                                />
                            } 
                            label={<Typography variant='caption' color='primary'>J'ai compris et je vais corriger l'erreur</Typography>}
                        />
                    </FormGroup>
                </DialogContentText>

            </DialogContent>

            <DialogActions>
                <Button 
                    onClick={handleClose} 
                    variant='contained'
                    className='Montserrat' 
                    disabled={!read}
                    sx={{
                        textTransform: 'none'
                    }}
                >Envoyer une nouvelle demande</Button>
            </DialogActions>

        </Dialog>
    )
}
