import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { User, permissionType } from '../../../../utils/interfaces'
import { permissionDescription } from '../../../../functions/basicFunctions'
import EditPermissionForm from './EditPermissionForm';

interface myProps {
    user: User,
    permission: permissionType,
    getPermissions: () => void
}

export default function ItemPermission(props: myProps) {

    const user = props.user;
    const permission = props.permission;

    const [open, setOpen] = useState(false);
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box 
            sx={{
                boxShadow: 2,
                borderRadius: 1,
                p: 2,
                mb: 5
            }}>

            <Typography variant='h6'>
                { permissionDescription(permission.name).label }
            </Typography>

            <Typography variant='caption'>
                { permissionDescription(permission.name).description }
            </Typography>

            <Typography>
                Statut : <Typography variant='caption' color={permission.status === 'allow' ? 'green' : 'error'} sx={{fontWeight: 'bold'}}> { permission.status } </Typography>
            </Typography>

            <Typography>
                Message : <Typography variant='caption' sx={{fontWeight: 'bold'}}> { permission.motif } </Typography>
            </Typography>

            <Typography>
                Last Update : <Typography variant='caption' sx={{fontWeight: 'bold'}}> { permission.lastUpdate } </Typography>
            </Typography>

            <Typography sx={{textAlign: 'right'}}>
                <Button 
                    variant='contained' 
                    color='inherit'
                    onClick={handleClickOpen} 
                    sx={{mt: 2}}>
                    Modifier
                </Button>
            </Typography>

            <Dialog open={open} onClose={handleClose} >

                <DialogTitle sx={{fontWeight: 'bold'}}>
                    { permission.name }
                </DialogTitle>

                <DialogContent>
                    <EditPermissionForm handleClose={handleClose} {...props} />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>

        </Box>
    )
}
