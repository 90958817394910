import { Typography } from "@mui/material";
import { propTypes } from "react-bootstrap/esm/Image";
import { Link } from "react-router-dom";
import { basicProps } from "../utils/interfaces";

export default function Copyright(props: basicProps) {

  const basicDatas = props.basicDatas;

  return (

    <Typography variant="body2" 
                color="text.secondary" 
                align="center" 
                sx={{pb:2}} >

      <Link to='/' color="inherit" className="goldman link">
        {basicDatas.appName} 
      </Link>
      
      {' '}

      {' © ' + new Date().getFullYear() + '.'}

    </Typography>
  );
}