import { useRef, useState } from 'react'
import { basicProps } from '../../../../../utils/interfaces';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';
import { copyClipboard } from '../../../../../functions/basicFunctions';


export default function WriteMail(props: basicProps) {

    const emailEditorRef = useRef<EditorRef>(null);
    const [openDialog, setOpenDialog] = useState(false)
    const [htmlContent, setHtmlContent] = useState('')
    

    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {

            const { design, html } = data;

            setHtmlContent(html)
            setOpenDialog(true)
        });
    };

    const onReady: EmailEditorProps['onReady'] = (unlayer) => {
        toast.info("Chargement de l'éditeur terminé !")
    };
    

    return (
        <Box
            sx={{mt: 5}}
        >
            
            <EmailEditor ref={emailEditorRef} onReady={onReady} />

            <Box textAlign='center'>

                <Button 
                    type='submit'
                    variant='contained' 
                    onClick={exportHtml}
                    sx={{
                        mt: 5,
                        p: 1.5
                    }}
                >
                    Générer le code HTML
                </Button>

            </Box>

            <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{margin:'auto'}}
            >

                <DialogTitle id="alert-dialog-title">
                    Contenu mail en HTML
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{width: 500}}>
                        <textarea 
                            name="" 
                            id=""
                            value={htmlContent}
                            style={{
                                width: '100%'
                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='inherit' id='dialogDeclineBtn' onClick={() => setOpenDialog(false)}>
                        Annuler
                    </Button>
                    <Button color='info' 
                            id="dialogAgreeBtn"
                            onClick={() => copyClipboard(htmlContent, "Contenu copié !")} 
                            autoFocus >
                        Copier
                    </Button>
                </DialogActions>
                </Dialog>

        </Box>
    )
}
