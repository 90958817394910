import { 
    Box, Button, Dialog, DialogActions, TextField, DialogContent, DialogTitle, Typography 
} from '@mui/material'
import { useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { basicProps } from '../../../../utils/interfaces';
import axiosApi from '../../../../functions/axiosApi';

export default function PartnerPhoneNumbers(props: basicProps) {

    const [open, setOpen] = useState(false);
    const [userID, setUserID] = useState('')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addNumber = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);

        axiosApi.post("ctr/airdrops/discounts/add-user.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                setOpen(false);
                setUserID('')
            }
        })
    }

    return (
        <Box>

            <Typography textAlign='center'>

                <Button
                    variant='contained'
                    onClick={() => setOpen(true)}
                    startIcon={<AddCircleIcon/>}
                    sx={{
                        mb: 5
                    }}
                >
                    Ajouter un compte
                </Button>
            </Typography>

            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Ajouter un compte</DialogTitle>

                <DialogContent sx={{minWidth: 200, maxWidth: 400}}>

                    <Box
                        component='form'
                        onSubmit={addNumber}
                    >
                    
                        <TextField
                            margin="normal"
                            fullWidth
                            value={userID}
                            onChange={(e) => setUserID(e.target.value)}
                            id="userID"
                            label="ID User"
                            name="userID"
                            autoComplete="userID"
                        />

                        <TextField
                            margin="normal"
                            fullWidth
                            label="Réduction pour achat"
                            name="purchaseDiscount"
                            autoComplete="purchaseDiscount"
                        />

                        <TextField
                            margin="normal"
                            fullWidth
                            label="Réduction pour achat"
                            name="salesDiscount"
                            autoComplete="salesDiscount"
                            sx={{mb: 2}}
                        />

                        <TextField
                            margin="normal"
                            type='password'
                            fullWidth
                            label="sw"
                            name="sw"
                            sx={{mb: 2}}
                        />

                        <Typography sx={{textAlign: 'right'}}>
                            <Button
                                variant='contained'
                                type='submit'
                            >
                                Ajouter
                            </Button>
                        </Typography>

                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>
            
        </Box>
    )
}
