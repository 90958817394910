import { useState } from 'react'
import { basicProps } from '../../utils/interfaces'
import { Link, Route, Routes } from 'react-router-dom'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from '@mui/material'
import { cftTokenInfo } from '../../functions/basicFunctions';
import Packs from './invest-now/InvestNow';

export default function Invest(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            
            <Routes>
                <Route path='/' element={
                    <Box sx={{
                        maxWidth: '500px',
                        margin: 'auto',
                        mt: 3
                    }}>
                        
                        <Typography variant='h5' sx={{textAlign: 'center', mb: 3}}>
                            Investir sur { basicDatas.appName }
                        </Typography>

                        <Typography 
                                onClick={() => setOpen(true)}
                                sx={{
                                    boxShadow: 1,
                                    p: 2,
                                    mb: 2,
                                    borderRadius: 2,
                                    cursor: 'pointer'
                                }}>
                            Comment ça fonctionne ?
                        </Typography>

                        <Link to='/invest/invest-now/' className='link'>

                            <Typography sx={{
                                boxShadow: 1,
                                p: 2,
                                mb: 2,
                                borderRadius: 2
                            }}>
                                Investir maintenant
                            </Typography>

                        </Link>

                        <Typography sx={{
                            boxShadow: 1,
                            p: 2,
                            borderRadius: 2
                        }}>
                            Mes commissions
                        </Typography>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            scroll="paper"
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            sx={{margin: 'auto'}}
                        >
                            <DialogTitle id="scroll-dialog-title" sx={{fontWeight: 'bold', color: 'var(--site-color)'}}>
                                Fonctionnement de notre programme d'investissement
                            </DialogTitle>
                            
                            <DialogContent dividers={true}>

                                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                    Pourquoi ce programme ?
                                </Typography>

                                Depuis son lancement, la plateforme d'échanges { basicDatas.appName } ne cesse d'enregistrer de nouveaux utilisateurs et le trafic augmente sur la plateforme. 
                                <br />
                                Mais, étant donné que les méthodes de paiement actuels sont principalement par mobile money, certains utilisateurs ont souvent du mal à effectuer des transactions par ce qu'ils préfèrent du MAIN A MAIN (c'est à dire payer en cash).
                                <br />
                                Nous avons donc pensé non seulement à intégrer ce moyen de paiement, mais aussi ajouter d'autres services dans notre pack comme les Dépots et retraits mobile money, envois et réceptions d'argent (Western Union, Moneygram, ...), des transferts d'argent quasiment sans frais entre les pays de la sous-region.
                                <br />
                                Nous avons pour objectif d'ouvrir des points de ventes d'abord au Cameroun, puis dans d'autres pays de l'Afrique centrale et ensuite dans l'Afrique de l'Ouest. Cela nous permettra d'être présents même dans les rues et plus tard de permettre aux utilisateurs hors du Cameroun de pouvoir effectuer des transactions en dépensant très peu en frais puisque plus besoin de transférer des fonds au Cameroun.
                                <br />
                                Pour que les objectifs cités ci-dessus soient réalisés, nous avons besoin de fonds et c'est pour cela que nous lançons un appel à tous ceux qui souhaitent nous accompagner dans cette aventure.

                                <Typography variant='h6' sx={{ fontWeight: 'bold', mt:3 }}>
                                    Comment rejoindre ?
                                </Typography>

                                Pour nous rejoindre dans ce voyage, il vous suffit de choisir un parmis nos packs d'investissement. 
                                
                                <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 3 }}>
                                    Comment les bénéfices sont partagés ?
                                </Typography>
                                
                                D'abord, nous tenons à informer à nos investisseurs que nous ne promettons pas des gains extraordinaires.
                                Votre commission est calculée comme suit : <br />
                                Supposons que : <br /> 

                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="- Tous les investisseurs ont injecté 1,000,000 FCFA dans la société."
                                        />
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText
                                            primary={<span>- Votre participation est de 100,000 FCFA. Dans ce cas, vous avez injecté 100,000 x 100 / 1,000,000 = <b>10%</b> dans le pool d'investissement</span>}
                                        />
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText
                                            primary="- L'entreprise réalise un bénéfice de 300,000 FCFA et décide de partager 100,000 FCFA aux investisseurs."
                                        />
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText
                                            primary={ <span>- Vous obtiendrez donc 10 x 100,000 / 100 = <b>10,000 FCFA</b></span>}
                                        />
                                    </ListItem>
                                </List>

                                Lorsque vous investissez, vos fonds sont bloqués pendant une certaine période et pour être éligible au partage, l'investissement de l'utilisateur doit avoir fait une certaine durée.

                                <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 3 }}>
                                    Retrait de commissions
                                </Typography>

                                Toutes les commions sont versées en { cftTokenInfo.symbol + " (" + cftTokenInfo.name + ") le token natif de la plateforme."}

                            </DialogContent>


                            <DialogActions>
                                <Button onClick={handleClose}>Je comprends</Button>
                            </DialogActions>
                        </Dialog>

                    </Box>
                } />

                <Route path='/invest-now/*' element={<Packs basicDatas={basicDatas} />} />
                
            </Routes>
        </div>
    )
}
