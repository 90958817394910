import { useEffect, useState } from 'react'
import { basicProps, prizePoolCampaignType } from '../../../../utils/interfaces'
import { useParams } from 'react-router-dom'
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, Select, TextareaAutosize, TextField, Typography } from '@mui/material';
import QueryString from 'qs';
import axiosApi from '../../../../functions/axiosApi';
import ImagesSelector from '../../../../components/ImageSelector';

const taskList = [
    {
        name: 'KYC',
        label: "Passer le KYC"
    },

    {
        name: 'TELEGRAM_SUBSCRIPTION',
        label: "S'abonner au canal Telegram"
    },

    {
        name: 'INVITE_FRIENDS',
        label: "Inviter des amis"
    },

    {
        name: 'MAKE_TRANSACTION',
        label: "Effectuer des transactions"
    }

]

export default function EditCampaign(props: basicProps) {

    const {id} = useParams();
    const basicDatas = props.basicDatas;

    const [campaignData, setCampaignData] = useState<prizePoolCampaignType>();
    const [images, setImages] = useState([]);
    const [checkedTasks, setCheckedTasks] = useState<string[]>(campaignData ? campaignData.tasks : []);

    useEffect(() => {
        
        if(campaignData && campaignData.tasks) {
            setCheckedTasks(campaignData.tasks)
        }

    }, [campaignData])
    

    useEffect(() => {
        
        axiosApi.post("ctr/airdrops/prize-pools/get-campaign-data.php", QueryString.stringify({id}))
        .then(response => {

            if(response.data.status === 'success') {
                setCampaignData(response.data.campaignData)
            }
        })

    }, [id])

    const editCampaign = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('images', JSON.stringify(images))
        data.append("loaderText", "Mise à jours en cours...")
        data.append('campaignID', id + '')
        data.append('tasks', JSON.stringify(checkedTasks))

        basicDatas.dialogManager({
            dialogTitle: "Modifier cette campagne",
            dialogText: "Vous allez modifier cette campagne. Faut-il vraiment exécuter cette action ?",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui mettre à jours"
          }, (response: string) => {
            
            if(response === 'ok') {
                axiosApi.post('ctr/airdrops/prize-pools/edit-campaign.php', data)
                .then(() => {})
            }
      
        })

    }

    const taskCheckedManager = (taskName: string) => {
        
        if(!checkedTasks.includes(taskName)) {
            setCheckedTasks([...checkedTasks, taskName])
        }

        else {
            const _checkedTasks = checkedTasks.filter((_task) => _task !== taskName)
            setCheckedTasks(_checkedTasks)
        }
    }


    if(!campaignData) return (
        <Box sx={{pt: 5, textAlign: 'center'}}>
            Chargement...
        </Box>
    )
    

    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <Typography
                textAlign='center'
                className='Montserrat'
                fontWeight='bold'
                variant='h5'
                sx={{
                    mb: 5
                }}
            >
                Modifier la campagne 
                <br />
                <Typography variant='caption'>
                    { campaignData.name }
                </Typography>
            </Typography>


            <Box
                component='form'
                onSubmit={editCampaign}
            >

                <TextField
                    type='text'
                    name='name'
                    defaultValue={campaignData.name}
                    label='Nom de la campagne'
                    placeholder='Attribuer un nom'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <FormControl 
                    fullWidth
                    sx={{
                        mb: 2
                    }}
                >
                    <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            name='status'
                            defaultValue={campaignData.status}
                        >
                        <MenuItem value={'being_created'}>Création en cours</MenuItem>
                        <MenuItem value={'progress'}>En cours</MenuItem>
                        <MenuItem value={'completed'}>Terminé</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    type='text'
                    name='title'
                    defaultValue={campaignData.title}
                    label='Titre de campagne'
                    placeholder='Titre de campagne'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <TextField
                    type='number'
                    name='amount'
                    defaultValue={campaignData.amount}
                    label='Montant de la cagnotte'
                    placeholder='Montant de la cagnotte'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <TextField
                    type='number'
                    name='amountPerWinner'
                    defaultValue={campaignData.amountPerWinner}
                    label='Montant pour chaque gagnant'
                    placeholder='Montant pour chaque gagnant'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <Typography sx={{mb: 1}}>
                    Description
                </Typography>

                <TextareaAutosize 
                    minRows={3} 
                    maxRows={10}
                    name='description'
                    id='description'
                    defaultValue={campaignData.description}
                    placeholder="Description de la campagne" 
                    style={{
                        width: '100%',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        padding: '15px',
                        marginBottom: '15px'
                    }}
                />

                <TextField
                    type='number'
                    name='num_winners'
                    defaultValue={campaignData.num_winners}
                    label='Nombre de gagnants'
                    placeholder='Nombre de gagnants'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <TextField
                    type='date'
                    name='start_date'
                    defaultValue={campaignData.start_date}
                    label='Date de debut'
                    placeholder='Date de debut'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <TextField
                    type='date'
                    name='end_date'
                    defaultValue={campaignData.end_date}
                    label='Date de fin'
                    placeholder='Date de fin'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />


                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">

                    <FormLabel component="legend">Assigner les tâches</FormLabel>
                    <FormHelperText>Choisir les tâches requises pour participer à cette cagnotte</FormHelperText>

                    <FormGroup>

                        {
                            taskList.map((itemTask, i) => {
                                return (
                                    <FormControlLabel
                                        key={i}
                                        control={
                                            <Checkbox checked={checkedTasks.includes(itemTask.name)} onChange={() => taskCheckedManager(itemTask.name)} name={itemTask.name} />
                                        }
                                        label={itemTask.label}
                                    />
                                )
                            })
                        }
             
                    </FormGroup>
                
                </FormControl>

                <TextField
                    type='password'
                    name='sw'
                    label='sw'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 3
                    }} 
                />
                
                <Typography sx={{mb: 1.5}}>
                    Ajouter une image de mise en avant <br />
                    <Typography variant='caption'>
                        Si vous ajoutez une image, elle remplacera celle existante
                    </Typography>
                </Typography>

                <div className='kyc-cni-images'>
                    <ImagesSelector images={images} max={1} setImages={(items: any) => {setImages(items)}} />
                </div>
                
                <br /><br />

                <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                >
                    Modifier la campagne
                </Button>

            </Box>

        </Box>
    )
}
