import { 
    Alert, AlertTitle, Box, Button, Dialog, FormGroup,
    DialogActions, DialogContent, DialogTitle, Typography,
    FormControlLabel, Checkbox
} from '@mui/material'
import { useState, useEffect } from 'react'
import axiosApi from '../functions/axiosApi';
import { basicProps } from '../utils/interfaces';
import QueryString from 'qs';
import { toast } from 'react-toastify';

export default function TeamAvailabilityAlert(props: basicProps) {

    const [teamAvailability, setTeamAvailability] = useState<any>({});
    const [requestEnded, setRequestEnded] = useState(false);

    useEffect(() => {
        axiosApi.post("ctr/team-manager/get-team-availability.php")
        .then(response => {
            if(response.data.status === 'success'){
                setTeamAvailability(response.data.teamAvailability);
                setRequestEnded(true);
            }
        })
    }, [])

    if(!requestEnded || (teamAvailability.isAvailable == 1)) return (
        <></>
    )

    return (
        <>
        
            <Alert severity="warning" 
                    sx={{mt: 3, mb: 5, textAlign:'left'}}>
                    
                <AlertTitle style={{fontWeight:'bold'}}>Information importante</AlertTitle>
                Les transactions sont temporairement suspendues. 
                <br /> <br />
                Heure de reprise : <b>{ teamAvailability.date }</b>  à <b>{teamAvailability.time}</b>

                <Alert 
                    icon={false}
                    sx={{textAlign:'left', mt: 2, bgcolor: '#fff'}}>
                    
                    <AlertTitle style={{fontWeight:'bold'}}>Me tenir informé.e</AlertTitle>
                    <Typography variant='caption'>
                        Vous recevrez une notification dès que les transactions reprendrons.
                    </Typography>

                    <Typography textAlign='right' sx={{mt: 1}}>
                        <DefineCallback { ...props } />
                    </Typography>

                </Alert>

            </Alert>
        </>
    )
}



const DefineCallback = (props: basicProps) => {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;
    let maidAddress = authUser.email.split('@');
    let _maidAddress = maidAddress[0].substring(0,3) + '***@' + maidAddress[1];

    const [open, setOpen] = useState(false);
    const [allowMail, setAllowMail] = useState(true);
    const [allowPush, setAllowPush] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        axiosApi.post("communication/notifications/get-user-preferences.php")
        .then(response => {

            if(response.data.status === 'success') {

                const _userPreferences = response.data.userPreferences;

                if(_userPreferences.teamAvailability && _userPreferences.teamAvailability.chanels) {
                    setAllowMail(_userPreferences.teamAvailability.chanels.allowMail);
                    setAllowPush(_userPreferences.teamAvailability.chanels.allowPush)
                }

            }
        })

    }, [])
    

    const savePreferences = () => {

        if(!allowMail && !allowPush) {
            toast.error("Pour définir un rappel, veuillez choisir au moins un canal pour être notifié.");
            return;
        }

        axiosApi.post("communication/notifications/define-callback.php", QueryString.stringify({
            allowMail,
            allowPush
        })).then(response => {

            if(response.data.status === 'success') {
                setOpen(false);
            }
        })
    }

    return (
        <Box>

            <Typography 
                color='primary'
                sx={{cursor: 'pointer'}}
                onClick={() => setOpen(true)}
                fontWeight='bold'
            >
                Définir un rappel
            </Typography>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{maxWidth: '550px', margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Définir un rappel</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Typography sx={{mb: 2}}>
                        Vous vous abonnez pour recevoir une notification lorsque les transactions reprendrons. 
                        <br />
                        Spécifiez par quels canaux vous souhaitez être notifié.e
                    </Typography>

                    <FormGroup>
                        <FormControlLabel 
                            control={<Checkbox checked={allowMail} onChange={() => setAllowMail(!allowMail)} />} 
                            label={<>E-mail à <b style={{fontSize:'small'}}>{ _maidAddress }</b></>} 
                        />
                        <FormControlLabel 
                            control={<Checkbox checked={allowPush} onChange={() => setAllowPush(!allowPush)} />} 
                            label="Notification Push" 
                        />
                    </FormGroup>

                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose} sx={{mr:3}}>Fermer</Button>
                    <Button 
                        variant='contained' 
                        disabled={!allowMail && !allowPush}
                        onClick={savePreferences}
                    >
                        Définir le rappel
                    </Button>
                </DialogActions>
                
            </Dialog>

        </Box>
    )
}
