import QueryString from 'qs';
import { useState, useEffect } from 'react'
import { useParams, Link, Routes, Route } from 'react-router-dom';
import axiosApi from '../../../functions/axiosApi';
import { 
    Avatar, Typography, TableContainer, Table, TableHead, 
    TableRow, TableBody, Paper, ToggleButtonGroup,
    ToggleButton, Badge, TextField, List, ListItem, ListItemAvatar,
    Tooltip, Divider, Button, ListItemText, Chip, Box, Stack
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { basicProps, orderType, User } from '../../../utils/interfaces'
import moment from 'moment';
import { cftTokenInfo, copyClipboard, readabilityNumber } from '../../../functions/basicFunctions';
import OrderStatusLabel from '../../../components/OrderStatusLabel';
import UserReferralList from './UserReferralList';
import ManageAccount from './manage-account/ManageAccount';
import OrderTypeBadge from '../../../components/OrderTypeBadge';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

interface orderStatusCountType {
    all_count: number,
    waiting_payment: number,
    marked_paid: number,
    canceled_by_user: number,
    canceled_by_admin: number,
    completed: number
}

interface UserDatas extends User {
    sponsorshipCommissions: {
        totalCommissions: number,
        balance: number
    }
}

export default function UserInfos(props: basicProps) {

    const {userID} = useParams();
    const [userInfos, setUserInfos] = useState<UserDatas>();

    const [orders, setOrders] = useState([]);
    const [orderStatusCount, setOrderStatusCount] = useState<orderStatusCountType>();
    const [status, setStatus] = useState('all');
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    

    useEffect(() => {
        getUserInfos();
    }, [userID])

    useEffect(() => {
        getUserOrders();
    }, [status, queryValue])

    const getUserOrders = () => {

        setIsLoading(true);

        axiosApi.post("ctr/users/get-user-orders.php", 
        QueryString.stringify({userID, status, offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newOrdersArray = orders.concat(response.data.orders);
                setOrders(newOrdersArray);
                setOffset(offset + 5);
                setOrderStatusCount(response.data.orderStatusCount);

                if(response.data.orders.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }
    

    const getUserInfos = () => {

        axiosApi.post("ctr/users/get-user-infos.php", QueryString.stringify({userID}))
        .then(response => {

            if(response.data.status === 'success'){
                setUserInfos(response.data.userInfos);
            }
        })
    }

    if(!userInfos) return (
        <div className='text-center mt-4'>Chargement...</div>
    )

    return (
        <div style={{maxWidth:'700px', margin:'auto', marginTop: '50px'}}>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Avatar src={userInfos.avatarUrl} sx={{width:'150px', height:'150px'}} />
            </div>
            
            <Typography variant='h5' sx={{textAlign:'center', mt:3, mb:4}}>
                {userInfos.firstName + " " + userInfos.lastName}
            </Typography>

            <Routes>

                <Route path='/' element={

                    <>

                        <Typography sx={{mb: 3}}>
                            <Link to={'/ctr/users/' + userInfos.user_id + '/manage-account/'}>
                                Manage account
                            </Link>

                            <Link to={'/ctr/kyc/customers/' + userInfos.user_id + "/"} target='_blank' style={{marginLeft: 20}}>
                                KYC
                            </Link>
                        </Typography>

                        <TableContainer component={Paper} >
                            <Table aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell>Element</StyledTableCell>
                                    <StyledTableCell align="left">Valeur</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Identifiant
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            <Tooltip title="Cliquez pour copier ID utilisateur">
                                                <span style={{cursor:'pointer', fontWeight: 'bold'}} onClick={() => copyClipboard(userInfos.user_id, "L'ID de l'utilisateur a été copié !")}>
                                                    {userInfos.user_id}
                                                </span>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Type de compte
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{color:userInfos.accountType === 'admin' ? 'red' : 'inherit'}}>
                                            {userInfos.accountType}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Vip Level
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{color: userInfos.vip_level === 'vip1' ? 'green' : 'red'}}>
                                            {userInfos.vip_level}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Nom
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {userInfos.lastName}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Prénom
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {userInfos.firstName}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Email
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {userInfos.email}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Téléphone
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {userInfos.phone}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Google ID
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            { userInfos.googleID ? userInfos.googleID : '...'}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Ville
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {userInfos.location.city ? userInfos.location.city : '...'}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Nombre d'ordres
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {"Total : " +  userInfos.totalOrders + " | Terminés : " + userInfos.numCompletedOrders}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Volume total transactions
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                            { userInfos?.totalTransactionVolume ? readabilityNumber(userInfos?.totalTransactionVolume) : '...'}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Invité.e par
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Link to={"/ctr/users/" + userInfos.sponsor?.user_id} target='_blank' className='small link' style={{color: 'var(--site-color)'}}>
                                                {userInfos.sponsor ? userInfos.sponsor.firstName + " " + userInfos.sponsor.lastName : '...'}
                                            </Link>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Parrainages
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{color:'var(--site-color)'}}>
                                            <UserReferralList userInfos={userInfos} />
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Commissions parrainage
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Total : <b>{ readabilityNumber(userInfos.sponsorshipCommissions.totalCommissions) + " XAF" }</b> | Solde : <b>{ readabilityNumber(userInfos.sponsorshipCommissions.balance) + " XAF"}</b>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Solde { cftTokenInfo.symbol }
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Volume Trans : <b>{ readabilityNumber(userInfos.walletInfos.totalTransactionVolume) + " XAF" }</b> | Solde : <b> {readabilityNumber(userInfos.walletInfos.cftBalance) + " XAF"}</b>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Date inscription
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {userInfos.inscriptionDate}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Divider component="div" sx={{mt:6, mb:4}} />

                        <Typography variant="h5" className='text-center' sx={{mt:6}}>
                            Ordres
                        </Typography>

                        <br /> <br />

                        <div className="text-center">
                            <ToggleButtonGroup
                                color="primary"
                                value={status}
                                exclusive
                                onChange={
                                    (e, newStatus) => {
                                        setStatus(newStatus);
                                        setOrders([]);
                                        setOffset(0);
                                        setEnded(false)
                                    }
                                }
                                sx={{
                                    maxWidth: '100%',
                                    overflow: 'auto'
                                }}
                                aria-label="order-status"
                                >
                                
                                <ToggleButton value="all" sx={{minWidth: '150px'}}>
                                    Tout ({orderStatusCount ? orderStatusCount.all_count : 0})
                                </ToggleButton>

                                <ToggleButton value="waiting_payment" sx={{minWidth: '210px'}}>
                                    <Badge max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.waiting_payment : 0} color="primary">
                                        En attente paiement
                                    </Badge>
                                </ToggleButton>

                                <ToggleButton value="marked_paid" sx={{minWidth: '150px'}}>
                                    <Badge max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.marked_paid : 0} color="primary">
                                        Marqué payé
                                    </Badge>
                                </ToggleButton>

                                <ToggleButton value="completed" sx={{minWidth: '100px'}}>
                                    <Badge max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.completed : 0} color="success">
                                        Terminé
                                    </Badge>
                                </ToggleButton>

                                <ToggleButton value="canceled_by_user" sx={{minWidth: '200px'}}>
                                    <Badge max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.canceled_by_user : 0} color="error">
                                        Annulé par user
                                    </Badge>
                                </ToggleButton>

                                <ToggleButton value="canceled_by_admin" sx={{minWidth: '200px'}}>
                                    <Badge max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.canceled_by_admin : 0} color="error">
                                        Annulé par admin
                                    </Badge>
                                </ToggleButton>

                            </ToggleButtonGroup>

                            <br /> <br />

                            <TextField 
                                id="outlined-basic"
                                type='search' 
                                label="Rechercher un ordre : ID" 
                                variant="outlined"
                                placeholder="Entrez l'ID de l'ordre"
                                value={queryValue}
                                onChange={
                                    (e) => {
                                        setQueryValue(e.target.value);
                                        setOrders([]);
                                        setOffset(0);
                                        setEnded(false)
                                    }
                                }
                                />

                        </div>

                        {
                            ((queryValue.length !== 0) && (orders.length === 0)) &&
                            <Typography className='text-center mt-4'>
                                Aucun ID d'ordre correspondant à <b>{queryValue}</b>
                            </Typography>
                        }

                        {
                            orders.length === 0 &&
                            <Typography className='text-center mt-5 small text-muted'>
                                Aucun ordre
                            </Typography>
                        }

                        <br /><br />

                        <List sx={{ bgcolor: 'background.paper', margin:'auto' }}>
                
                            {
                                orders.map((order: orderType, orderIndex) => {

                                    const basicInfos = order.basic;
                                    const action = basicInfos.action;
                                    const cryptoCurrency = order.cryptoCurrency;
                                    const paymentMethod = order.paymentMethod;
                                    const network = order.network;

                                    const sendInfos = order.sendInfos;
                                    const sendCurrency = sendInfos.sendCurrency;
                                    const sendNetwork = sendInfos.sendNetwork;
                                    const sendQte = sendInfos.sendQte;

                                    if (action === 'swap') return (
                                        <Box key={orderIndex}>

                                            <Typography
                                                component="div"
                                                variant="body2"
                                                color="text.primary"
                                                sx={{fontWeight:'bold', mb: 1}}
                                            >
                                                <Tooltip title="Cliquez pour copier l'ID de l'ordre">
                                                    <span style={{cursor:'pointer'}} onClick={() => copyClipboard(basicInfos.orderID, "L'ID de l'ordre a été copié !")}>{"ID : " + basicInfos.orderID}</span>
                                                </Tooltip>
                                            </Typography>

                                            <Typography sx={{mb: 1}}>
                                                <Link to={"/orders/" + basicInfos.orderID + "/"} className="link">
                                                    <OrderTypeBadge type={basicInfos?.action} /> 
                                                </Link>
                                            </Typography>

                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                Statut : <OrderStatusLabel orderStatus={basicInfos.status} />
                                                <br />
                                                Créé par : <a href={'/ctr/users/' + order.user.user_id + '/' } target='_blank' className='text-muted'>{order.user?.firstName + " " + order.user?.lastName}</a>
                                            </Typography>

                                            <br />

                                            {
                                                basicInfos.status === 'waiting_payment' &&
                                            
                                                <Typography>

                                                    Delai paiement :

                                                    <Typography variant='caption' sx={{fontWeight: 'bold', color: '#D32F2F'}}>
                                                        {
                                                            basicInfos.action === 'buy' 
                                                            ?   ' ' + paymentMethod.maxTimePaymentLimit + ' minutes' 
                                                            :   ' ' + network.maxTimePaymentLimit + ' minutes'
                                                        }
                                                    </Typography>
                                                    
                                                </Typography>
                                            }

                                            <Typography color='GrayText'>
                                                {moment(basicInfos.date).fromNow()}
                                            </Typography>
                            
                                            <Stack
                                                direction={'row'}
                                                spacing={2}
                                                alignItems='center'
                                                justifyContent='flex-start'
                                            >

                                                <List>

                                                    <ListItem alignItems="flex-start" sx={{p: 0}}>

                                                        <ListItemAvatar>
                                                            <Avatar 
                                                                alt={sendCurrency.symbol} 
                                                                src={sendCurrency.logo}
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25
                                                                }}
                                                            />
                                                        </ListItemAvatar>

                                                        <ListItemText
                                                            primary={<b>{sendCurrency.symbol}</b>}
                                                            secondary={
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="caption"
                                                                        color="text.primary"
                                                                    >
                                                                        { sendNetwork.symbol }
                                                                    </Typography>

                                                                    <Typography>
                                                                        { sendQte }
                                                                    </Typography>
                                                                </>
                                                            }
                                                        />
                                                    </ListItem>

                                                </List>

                                                <ArrowRightAltIcon color='primary' sx={{fontSize: 40, opacity: 0.7}} />

                                                <List>

                                                    <ListItem alignItems="flex-start" sx={{p: 0}}>

                                                        <ListItemAvatar>
                                                            <Avatar 
                                                                alt={cryptoCurrency.symbol} 
                                                                src={cryptoCurrency.logo} 
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25
                                                                }}
                                                            />
                                                        </ListItemAvatar>

                                                        <ListItemText
                                                            primary={<b>{cryptoCurrency.symbol}</b>}
                                                            secondary={
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="caption"
                                                                        color="text.primary"
                                                                    >
                                                                        { network.symbol }
                                                                    </Typography>

                                                                    <Typography>
                                                                        { basicInfos.cryptoQte }
                                                                    </Typography>
                                                                </>
                                                            }
                                                        />
                                                    </ListItem>

                                                </List>
                                                
                                            </Stack>

                                            <br />

                                            <div className='text-right' style={{marginTop:'15px', textAlign:'right'}}>
                                        
                                                <Link to={"/orders/" + basicInfos.orderID + "/"} target='_blank' className="link text-primary" style={{display:'inline-block', marginRight:'20px'}}>
                                                    <Button>
                                                        Récap
                                                    </Button>
                                                </Link>

                                                <Link to={"/orders/" + basicInfos.orderID + "/reglement/"} target='_blank' className="link text-primary" style={{display:'inline-block'}}>
                                                    <Button>
                                                        Règlement
                                                    </Button>
                                                </Link>

                                            </div>
                                            
                                            <Divider variant="inset" component="li" sx={{mt:4, mb:4}} />

                                        </Box>
                                    ) 




                                    return (

                                        <div key={orderIndex}>

                                            <ListItem alignItems="flex-start" sx={{pl: 0}}>
                                                <ListItemAvatar>
                                                    <Avatar alt={cryptoCurrency.symbol} src={cryptoCurrency.logo} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <>

                                                            <Typography
                                                                component="div"
                                                                variant="body2"
                                                                color="text.primary"
                                                                sx={{fontWeight:'bold'}}
                                                            > 
                                                                <Tooltip title="Cliquez pour copier l'ID de l'ordre">
                                                                    <span style={{cursor:'pointer'}} onClick={() => copyClipboard(basicInfos.orderID, "L'ID de l'ordre a été copié !")}>{"ID : " + basicInfos.orderID}</span>
                                                                </Tooltip>
                                                            </Typography>

                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                                sx={{fontWeight:'bold'}}
                                                            >
                                                                { readabilityNumber(basicInfos.cryptoQte) + " " + cryptoCurrency.symbol + "_" + network.symbol}
                                                            </Typography>

                                                            {" — "} 

                                                            <Chip label={basicInfos.action === 'buy' ? "Achat" : "Vente"} 
                                                                    sx={{
                                                                        backgroundColor: basicInfos.action === 'buy' ? "#E8F3EE" : "#FDEDED",
                                                                        color: basicInfos.action === 'buy' ? "#338F5C" : "#E5593C",
                                                                        borderRadius: 1
                                                                    }} 
                                                                    variant="filled" 
                                                                    size="small" />

                                                            <div>{cryptoCurrency.name}</div>
                                                            
                                                            <Typography
                                                                component="div"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Pour : { readabilityNumber(basicInfos.fiatQte) + ' ' + paymentMethod.symbol } 
                                                            </Typography>

                                                            <Typography
                                                                component="div"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                { basicInfos.paymentMethodNumber } 
                                                            </Typography>

                                                            {
                                                                (basicInfos.action === 'buy' && basicInfos.momoPaymentType) &&

                                                                <Typography
                                                                    component="div"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    Retrait/Float : <b>{ basicInfos.momoPaymentType === 'withdrawal' ? "Retrait" : "Float" }</b> 
                                                                </Typography>

                                                            }

                                                            <Typography
                                                                component="div"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Statut : <OrderStatusLabel orderStatus={basicInfos.status} />
                                                                <br />
                                                                Créé par : <a href={'/ctr/users/' + order.user.user_id + '/' } target='_blank' className='text-muted'>{order.user?.firstName + " " + order.user?.lastName}</a>
                                                            </Typography>
                                                        </>
                                                    }
                                                    secondary={
                                                        <>

                                                            {
                                                                basicInfos.status === 'waiting_payment' &&
                                                            
                                                                <Typography>

                                                                    Delai :

                                                                    <Typography variant='caption' sx={{fontWeight: 'bold'}}>
                                                                        {
                                                                            basicInfos.action === 'buy' 
                                                                            ?   ' ' + paymentMethod.maxTimePaymentLimit + ' minutes' 
                                                                            :   ' ' + network.maxTimePaymentLimit + ' minutes'
                                                                        }
                                                                    </Typography>
                                                                    
                                                                </Typography>
                                                            }

                                                            <div>
                                                                {moment(basicInfos.date).fromNow()}
                                                            </div>

                                                            <br />

                                                            <div className='text-right' style={{marginTop:'15px', textAlign:'right'}}>
                                                        
                                                                <Link to={"/orders/" + basicInfos.orderID + "/"} target='_blank' className="link text-primary" style={{display:'inline-block', marginRight:'20px'}}>
                                                                    <Button>
                                                                        Récap
                                                                    </Button>
                                                                </Link>

                                                                <Link to={"/orders/" + basicInfos.orderID + "/reglement/"} target='_blank' className="link text-primary" style={{display:'inline-block'}}>
                                                                    <Button>
                                                                        Règlement
                                                                    </Button>
                                                                </Link>

                                                            </div>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                            
                                            <Divider variant="inset" component="li" sx={{mt:4, mb:4}} />

                                        </div>
                                    )
                                })
                            }
                        </List>

                        <div className="text-center">

                            {
                                isloading

                                ? 
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="outlined"
                                    >
                                        Chrargement
                                    </LoadingButton>
                                :
                                    !ended &&
                                    <Button variant='contained' color='inherit' onClick={() => getUserOrders()}>
                                        Afficher plus
                                    </Button>
                            }

                        </div>

                    </>

                } />

                <Route path='/manage-account/' element={
                    <ManageAccount userInfos={userInfos} {...props} />
                } />
            </Routes>

        </div>
    )
}
