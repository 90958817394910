import { basicProps } from '../../utils/interfaces'
import WithdrawForm from './WidthdrawForm';
import { useEffect, useState } from 'react';
import axiosApi from '../../functions/axiosApi';
import { 
    Divider, List,  
    Typography, Box, 
    Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { readabilityNumber } from '../../functions/basicFunctions';
import QueryString from 'qs';
import SponsorshipTransactionTypeBadge from './SponsorshipTransactionTypeBadge';
import { MetaTags } from 'react-meta-tags';

export default function Withdraw(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [withdrawalHistory, setWithdrawalHistory] = useState([]);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        getWithdrawalHistory();
    }, [])

    const getWithdrawalHistory = () => {

        setIsLoading(true);

        axiosApi.post("sponsorships/get-withdrawal-history.php", QueryString.stringify({offset, limit:3}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newOrdersArray = withdrawalHistory.concat(response.data.withdrawalHistory);
                setWithdrawalHistory(newOrdersArray);
                setOffset(offset + 3);

                if(response.data.withdrawalHistory.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <div style={{maxWidth: '450px', margin:'auto', paddingTop:'30px'}}>

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Retrait commissions de parrainage</title>
                <meta name="description" content="CRYPTO-FIAT | Retrait commissions de parrainage" />
                <meta property="og:title" content="CRYPTO-FIAT | Retrait commissions de parrainage" />
            </MetaTags>
            
            <WithdrawForm basicDatas={basicDatas} getWithdrawalHistory={getWithdrawalHistory} />

            <Typography variant='h5' className='text-center' sx={{mt:7, mb:3}}>
                Historique
            </Typography>

            <List sx={{ bgcolor: 'background.paper', margin:'auto' }}>

                {
                    withdrawalHistory.map((withdraw: any) => {

                        return (
                            <Box key={withdraw.id}>

                                <Typography
                                    component="div"
                                    variant="h6"
                                    color={withdraw.type === 'withdrawal' ? 'error' : 'primary'}
                                    sx={{fontWeight:'bold'}}
                                >
                                    {withdraw.type === 'withdrawal' ? '-' : '+'} {readabilityNumber(withdraw.amount) + " CFT"}
                                </Typography>

                                <SponsorshipTransactionTypeBadge transactionType={withdraw.type} date={withdraw.date} />
                
                                <Divider variant="inset" component="li" sx={{mt:4, mb:4}} />

                            </Box>
                        )
                    })
                }

            </List>

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getWithdrawalHistory()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </div>
    )
}
