import { Box, TextField, Button, AvatarGroup, Avatar, Stack, Typography } from '@mui/material';
import axiosApi from '../../../functions/axiosApi';
import { basicProps } from '../../../utils/interfaces';
import { useState } from 'react';
import { cftTokenInfo } from '../../../functions/basicFunctions';

export default function RechargeForm(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [amount, setAmount] = useState();

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let data = new FormData(e.currentTarget);
        data.append("loaderText", "Initialisation en cours...");

        basicDatas.dialogManager({
            dialogTitle: 'Confirmer',
            dialogText: (
                <Typography> 
                    
                    <Typography>
                        Montant : <b>{ amount + ' XAF' }</b>
                    </Typography>

                    <Typography>
                        Frais partenaire : <b>{ amount && amount * 2.5 / 100 + ' XAF'}</b>
                    </Typography>

                    <Typography>
                        A recevoir : <b>{ amount &&amount - (amount * 2.5 / 100) + ' ' + cftTokenInfo.symbol }</b>
                    </Typography>

                    <br />

                    <Typography variant='caption'>
                        <b>Frais partenaire :</b> Les recharges automatiques sont gérées par un partenaire de service externe. Le partenaire prélève les frais lorsque vous effectuez des paiements.
                    </Typography>

                </Typography>
            ),
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Payer"
          }, (response: string) => {
            
            if(response === 'ok') {

                axiosApi.post('auto-payment/intouch/init-payment.php', data)
                //axiosApi.get('auto-payment/intouch/confirm.php?payment_status=200&paid_amount=100&command_number=647a41be834e9.67df3dfbffe84121c44b')
                .then(response => {

                    if(response.data.status === 'success') {

                        const responseData = response.data.data;

                        const myWindow = window as any;

                        if(myWindow.calltouchpay) {
                            myWindow.calltouchpay({
                                order_number: responseData.order_number,
                                name: responseData.name,
                                email: responseData.email,
                                amount: responseData.amount,
                                recipient: responseData.recipient
                            })
                        }
                    
                    }
                })
            }
        })
    }

    return (
        <Box
            component='form'
            onSubmit={submit}
            sx={{
                boxShadow: 2,
                padding: 2,
                borderRadius: 2,
                margin: 'auto',
                mt: 3
            }}>

            <Stack direction="row" 
                    spacing={2} justifyContent='center' 
                    alignItems='center'
                    sx={{
                        mb: 3
                    }}>
                <AvatarGroup total={2}>
                    <Avatar src='https://lemobileaukamer.files.wordpress.com/2014/11/mobile-money.jpg'
                            alt='MTN_CM'
                            />
                    <Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3ChNSs5I9zr3fNgvBzRbWWYlwbGpiExcx9A&usqp=CAU" 
                            alt='OM_CM'
                            />
                </AvatarGroup>
            </Stack>

            <TextField
                name='amount'
                type='number'
                inputProps={{step: 0}}
                label="Montant"
                placeholder='Entrez le montant'
                fullWidth
                onChange={(e) => setAmount(e.target.value as any)}
                sx={{
                    mb: 2
                }} />

            <TextField
                name='recipient'
                label="Numéro téléphone (sans indicatif)"
                placeholder='Numéro mobile money'
                fullWidth
                sx={{
                    mb: 2
                }} />

            <Button
                type='submit'
                variant='contained'
                fullWidth>
                Continuer
            </Button>
            
        </Box>
    )
}
