import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { User } from '../../../utils/interfaces';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { 
    Typography, List, ListItem, ListItemText, ListItemAvatar, 
    Avatar, Divider, Button, TextField
} from '@mui/material';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { Link } from 'react-router-dom';

interface thisProps {
    userInfos: User
}

export default function UserReferralList(props: thisProps) {

    const userInfos = props.userInfos;

    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getUserReferrals()
    }, [queryValue])

    const getUserReferrals = () => {

        setIsLoading(true);

        axiosApi.post("ctr/users/get-user-referrals.php", QueryString.stringify({offset, limit: 5, user_id: userInfos.user_id, queryValue}))
        .then(response => {
            if(response.data.status === 'success'){
                setUsers(users.concat(response.data.users))
            }

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newusersArray = users.concat(response.data.users);
                setUsers(newusersArray);
                setOffset(offset + 5);

                if(response.data.users.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
        }
    }, [open]);

    return (

        <div>
            <div onClick={() => setOpen(true)} className='cursor-pointer'>
                {userInfos.numRferrals + " personne.s"}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{maxWidth: '550px', margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Filleuls : { userInfos.numRferrals }</DialogTitle>
                
                <DialogContent dividers={true}>

                    <TextField 
                            type='search' 
                            label="Rechercher un utilisateur" 
                            variant="outlined"
                            placeholder="nom de l'utilisateur"
                            value={queryValue}
                            sx={{mt:3}}
                            fullWidth
                            onChange={
                                (e) => {
                                    setQueryValue(e.target.value);
                                    setUsers([]);
                                    setOffset(0);
                                    setEnded(false)
                                }
                            }
                            />

                    <br /><br />

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', margin:'auto' }}>
                            
                            {
                                users.map((user: User) => {
                                    return (
    
                                        <div key={user.user_id}>
    
                                            <ListItem alignItems="flex-start" >
    
                                                <ListItemAvatar sx={{cursor:'pointer'}}>
                                                    <Link to={"/ctr/users/" + user.user_id + "/"} target='_blank' className='link'>
                                                        <Avatar alt={user.lastName + " " + user.firstName} src={user.avatarUrl} />
                                                    </Link>
                                                </ListItemAvatar>
    
                                                <ListItemText
                                                    primary={ user.firstName + " " + user.lastName }
                                                    secondary={
                                                        <>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {user.phone}
                                                            </Typography>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Total ordres : {user.totalOrders}
                                                            </Typography>

                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Ordres validés : {user.numCompletedOrders}
                                                            </Typography>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {user.email}
                                                            </Typography>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                GoogleID : {user.googleID}
                                                            </Typography>

                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Invitations : {user.numRferrals}
                                                            </Typography>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {user.location.city}
                                                            </Typography>
                                                        
                                                            <Typography
                                                                sx={{ display: 'block', fontSize: 'small' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.secondary"
                                                            >
                                                                {user.inscriptionDate}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
    
                                            <Divider variant="inset" component="li" />
    
                                        </div>
                                    )
                                })
                            }
                        </List>
                        
                        <br />

                        <div className="text-center">
            
                            {
                                isloading

                                ? 
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="outlined"
                                    >
                                        Chrargement
                                    </LoadingButton>
                                :
                                    !ended &&
                                    <Button variant='contained' color='inherit' onClick={() => getUserReferrals()}>
                                        Afficher plus
                                    </Button>
                            }

                        </div>

                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
