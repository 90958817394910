import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import axiosApi from '../../../../../functions/axiosApi';
import { basicProps } from '../../../../../utils/interfaces';

export default function AddPeriodicBalance(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [fundsLocations, setfundsLocations] = useState<{name: string, id: number}[]>([]);

    useEffect(() => {

        getFundsLocations()
      
    }, [])

    const getFundsLocations = () => {

        axiosApi.post('ctr/stats/compta/funds-location/get.php')
        .then(response => {

            if(response.data.status === 'success'){
                setfundsLocations(response.data.fundsLocations)
            }
        })
    }

    const save = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        const form = e.currentTarget;

        const data = new FormData(form);
        data.append('loaderText', "Traitement en cours...");

        basicDatas.dialogManager({
            dialogTitle: "Confirmer la sauvegarde",
            dialogText: "Souhaitez-vous confirmer l'ajout des informations ?",
            dialogAgreeBtnColor: 'primary',
            dialogAgreeBtnText: "Oui Ajouter"
          }, (response: string) => {
            
            if(response === 'ok') {
                axiosApi.post("ctr/stats/compta/bilans/add.php", data)
                .then(response => {

                    if(response.data.status === 'success') {
                        form.reset();
                    }
                })
            }
        })
    }

    return (
        <div
            style={{
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <br />
            
            <Typography variant='h5' textAlign='center'>
                Ajouter un bilan périodique
            </Typography>

            <br /><br />

            <Box
                component='form'
                onSubmit={save}
            >

                <Stack 
                    direction='row'
                    justifyContent='space-between'
                    gap={3}
                    sx={{
                        mb: 5,
                        p: 2,
                        border: '1px solid #ddd',
                        borderRadius: 1
                    }}
                >

                    <FormControl fullWidth required>

                        <InputLabel id="demo-simple-select-label">Mois</InputLabel>
                        
                        <Select
                            labelId="demo-simple-select-label"
                            name='month'
                            id="demo-simple-select"
                            label="Mois"
                            required
                        >
                            {
                                [
                                    { label: 'Janvier', value: 1 },
                                    { label: 'Février', value: 2 },
                                    { label: 'Mars', value: 3 },
                                    { label: 'Avril', value: 4 },
                                    { label: 'Mai', value: 5 },
                                    { label: 'Juin', value: 6 },
                                    { label: 'Juillet', value: 7 },
                                    { label: 'Août', value: 8 },
                                    { label: 'Septembre', value: 9 },
                                    { label: 'Octobre', value: 10 },
                                    { label: 'Novembre', value: 11 },
                                    { label: 'Décembre', value: 12 },
                                ].map((month) => {
                                    return (
                                        <MenuItem key={month.value} value={month.value}>{ month.label }</MenuItem>
                                    )
                                })
                            }
                            
                        </Select>

                    </FormControl>

                    <FormControl fullWidth required>

                        <InputLabel id="demo-simple-select-label">Année</InputLabel>
                        
                        <Select
                            labelId="demo-simple-select-label"
                            name='year'
                            id="demo-simple-select"
                            label="Année"
                            required
                        >
                            {
                                [
                                    { label: '2023', value: 23 },
                                    { label: '2024', value: 24 },
                                    { label: '2025', value: 25 },
                                    { label: '2026', value: 26 },
                                    { label: '2027', value: 27 },
                                    { label: '2028', value: 28 },
                                    { label: '2029', value: 29 },
                                    { label: '2030', value: 30 },
                                ].map((year) => {
                                    return (
                                        <MenuItem key={year.value} value={year.value}>{ year.label }</MenuItem>
                                    )
                                })
                            }
                        </Select>

                    </FormControl>

                </Stack>
                

                {
                    fundsLocations.map(itemFundLocation => {
                        return (
                            <Stack 
                                key={itemFundLocation.id}
                                direction='row'
                                justifyContent='space-between'
                                sx={{
                                    mb: 5,
                                    p: 2,
                                    border: '1px solid #ddd',
                                    borderRadius: 1
                                }}
                            >

                                <Typography variant='h6'>
                                    { itemFundLocation.name }
                                </Typography>

                                <TextField 
                                    name={itemFundLocation.id + ''}
                                    label='Valeur estimée XAF'
                                    placeholder='Valeur estimée XAF'
                                    defaultValue={0}
                                    required
                                />
                            </Stack>
                        )
                    })
                }

                <TextField 
                    label='sw'
                    fullWidth
                    type='password'
                    name='sw'
                    sx={{
                        mb: 4
                    }}
                />

                <Button
                    type='submit'
                    variant='contained'
                >
                    Enregistrer le bilan
                </Button>

            </Box>

        </div>
    )
}
