import { 
    Accordion, AccordionDetails, AccordionSummary, Avatar, 
    Box, Button, Checkbox, Chip, FormControl, 
    InputLabel, MenuItem, Select, TextField, Typography 
} from '@mui/material';
import QueryString from 'qs';
import { useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { currencyType, networkType } from '../../../utils/interfaces';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ItemNetwork from './ItemNetwork';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { binacePriceApiPath } from '../../../functions/basicFunctions';
import { toast } from 'react-toastify';
import axios from 'axios';

interface currencyProps {
    networks: networkType[],
    basicDatas: any
}

export default function ItemCurrency(props: currencyProps) {

    //const basicDatas = props.basicDatas;
    const { currencySymbol } = useParams();

    const [currency, setCurrency] = useState<currencyType>();
    const [networks, setNetworks] = useState<networkType[]>([])
    const [purchasePrice, setPurchasePrice] = useState(currency?.purchasePrice);
    const [salePrice, setSalePrice] = useState(currency?.salePrice);
    const [estimatedPurchasePrice, setEstimatedPurchasePrice] = useState(0);
    const [estimatedSalePrice, setEstimatedSalePrice] = useState(0);
    const [binancePrice, setBinancePrice] = useState(0);
    const [usdtInfos, setUsdtInfos] = useState<currencyType>();
    const [gap, setGap] = useState(1);
    const [sw, setSw] = useState('');

    useEffect(() => {
        getCurrencyInfos();
        getNetworks();

        axiosApi.post('ctr/currencies/get-usdtxaf-price.php')
        .then(response => {
            if(response.data.status === 'success'){
                setUsdtInfos(response.data.usdtInfos)
            }
        })

    }, [])

    useEffect(() => {
        setPurchasePrice(currency?.purchasePrice);
        setSalePrice(currency?.salePrice);
    }, [currency]);
    

    const getPriceOnBinance = () => {

        if(currency) {
            axios.get(currency.pairForPrice ? binacePriceApiPath + currency.pairForPrice : binacePriceApiPath + currency.symbol + 'USDT')
            .then(res => {
                if(res.data.price){
                    if(usdtInfos?.purchasePrice){

                        let usdtPurchasePrice = parseFloat(usdtInfos.purchasePrice + '');
                        let usdtSalePrice = parseFloat(usdtInfos.salePrice + '');

                        setBinancePrice(res.data.price);
                        setEstimatedPurchasePrice(res.data.price * usdtPurchasePrice);
                        setEstimatedSalePrice(res.data.price * usdtSalePrice);
                    }
                    
                }

                else {
                    toast.warn("Impossible de trouver le prix sur Binance");
                }
            }).catch((e) => {
                toast.warn("Impossible de trouver le prix sur Binance");
            })
        }
    }

    const applyBinancePrice = () => {

        if(!estimatedPurchasePrice || !usdtInfos?.purchasePrice) return;

        let newPurchasePrice = parseFloat((estimatedPurchasePrice - estimatedPurchasePrice * gap / 100).toFixed(2));
        let newSalePrice = parseFloat((estimatedSalePrice + estimatedSalePrice * gap / 100).toFixed(2));
        setPurchasePrice(newPurchasePrice);
        setSalePrice(newSalePrice)

    }

    const getCurrencyInfos = () => {
        axiosApi.post('ctr/currencies/get-currency-infos.php', QueryString.stringify({symbol: currencySymbol}))
        .then(response => {
            if(response.data.status === 'success'){
                setCurrency(response.data.currencyData);
            }
        })
    }

    const getNetworks = () => {
        axiosApi.post('ctr/networks/get-networks.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setNetworks(response.data.networks);
            }
        })
    }

    const setCurrencyOnServer = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
    
        let data = new FormData(e.currentTarget);
        
        data.append('id', '' + currency?.id);
        data.append('sw', sw)
        data.append('loaderText', 'Mise à jour de la devise en cours...');
    
        axiosApi.post('ctr/currencies/set-currency.php', data);
    }
    
    const handleManageBuyAndSaleAction = (actionType:string, network?: string) => {
        axiosApi.post('ctr/currencies/buy-sell-actions-manager.php', QueryString.stringify({
            actionType, 
            currencyID: currency?.id, 
            network: network ? network : 'all',
            sw
        })).then(response => {
            if(response.data.status === 'success'){
                getCurrencyInfos();
            }
        })
    }

    const setFeeSystem = () => {
        axiosApi.post('ctr/currencies/set-fee-system.php', QueryString.stringify({id: currency?.id, sw}))
        .then(response => {
            if(response.data.status === 'success'){
                getCurrencyInfos();
            }
        })
    }

    const setIsDigitalCurrency = () => {
        axiosApi.post('ctr/currencies/set-is-digital-currency.php', QueryString.stringify({id: currency?.id, sw}))
        .then(response => {
            if(response.data.status === 'success'){
                getCurrencyInfos();
            }
        })
    }

    const setAllowForSwap = () => {
        axiosApi.post('ctr/currencies/set-allow-for-swap.php', QueryString.stringify({id: currency?.id, sw}))
        .then(response => {
            if(response.data.status === 'success'){
                getCurrencyInfos();
            }
        })
    }

    const setAllowAutomaticTrancation = () => {
        axiosApi.post('ctr/currencies/set-allow-automatic-transaction.php', QueryString.stringify({id: currency?.id, sw}))
        .then(response => {
            if(response.data.status === 'success'){
                getCurrencyInfos();
            }
        })
    }

    const setTrendindCurrencies = () => {
        axiosApi.post('ctr/currencies/set-trending-currencies.php', QueryString.stringify({id: currency?.id, sw}))
        .then(response => {
            if(response.data.status === 'success'){
                getCurrencyInfos();
            }
        })
    }

    if(!currency) return (
        <Typography sx={{
            textAlign: 'center',
            mt: 5
        }}>
            Chargement...
        </Typography>
    )

    const allowed_networks = currency?.allowed_networks;

    return (
            
        <div style={{maxWidth: '98vw', margin: 'auto', marginTop: '30px'}}>
            
            <Typography sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Avatar src={ currency.logo } sx={{width: 60, height: 60}} />

            </Typography>

            <Typography variant='h5' sx={{mt: 2, textAlign: 'center'}}>
                {currency && currency.symbol + " (" + currency.name + ")"}
            </Typography>


            <Box sx={{
                bgcolor: "#eee",
                p: 2,
                pt: 4,
                mt: 5
            }}>

                <Box    sx={{
                    mb: 3
                }}>
                    <Button variant='outlined' color='info' onClick={getPriceOnBinance}>
                        Get price on Binance
                    </Button>

                    {
                        (binancePrice != 0) && 

                        <>
                            <Button variant='outlined' 
                                    color='info' sx={{ml: 2}} 
                                    onClick={applyBinancePrice}>
                                Apply
                            </Button>

                            <TextField 
                                type='number'
                                label="% écart"
                                placeholder='Valeur'
                                value={gap}
                                onChange={(e) => setGap(parseFloat(e.target.value + ''))}
                                sx={{ml: 2}} />
                        </>
                    }

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: 5,
                        mt: 2
                    }}>
                        <Chip label={"Binance Price : " + binancePrice}/>
                        <Chip label={"Achat : " + estimatedPurchasePrice} color='info' />
                        <Chip label={"Vente : " + estimatedSalePrice} color='error' />
                    </Box>

                </Box>
 
                
                <Box
                    component="form"   
                    id="form_add_currency"
                    noValidate
                    autoComplete="off"
                    onSubmit={setCurrencyOnServer}
                    sx={{mb: 8}}
                    >

                    <Box component="div"   
                            sx={{
                                bgcolor: 'ButtonFace',
                                display: 'grid', 
                                gridTemplateColumns: "repeat(4, 1fr)",
                                gap: 3
                            }}
                            >

                        <TextField
                            required
                            name='name'
                            label="Nom devise"
                            defaultValue={currency.name}
                            />

                        <TextField
                            required
                            name="symbol"
                            id="outlined-disabled"
                            label="Symbole devise"
                            defaultValue={currency.symbol}
                            />

                        <TextField
                            required
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='purchasePrice'
                            label="Prix achat"
                            value={purchasePrice}
                            onChange={(e => setPurchasePrice(parseFloat(e.target.value + '')))}
                        />

                        <TextField
                            required
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='salePrice'
                            label="Prix vente"
                            value={salePrice}
                            onChange={(e => setSalePrice(parseFloat(e.target.value + '')))}
                        />

                        <TextField
                            required
                            name='logo'
                            label="Lien logo (CoinMarketCap)"
                            defaultValue={currency.logo}
                            />

                        <FormControl 
                            fullWidth >

                            <InputLabel id="select_transaction_type_label">Fournisseur prix</InputLabel>
                        
                            <Select
                                labelId="select_transaction_type_label"
                                name='priceProvider'
                                defaultValue={ currency.priceProvider }
                                label="Fournisseur prix"
                                fullWidth
                                margin="dense"
                                required
                                >

                                {
                                    ['crypto-fiat', 'binance'].map((type, index) => {

                                        return (
                                            <MenuItem key={ index } value={ type }>
                                                { type }
                                            </MenuItem>
                                        )

                                    })
                                }
                
                            </Select>        

                        </FormControl>

                        <TextField
                            required
                            type='text'
                            name='priceProviderLink'
                            label="Lien fourniture prix"
                            defaultValue={ currency.priceProviderLink ? currency.priceProviderLink : binacePriceApiPath + currency.symbol + 'USDT' }
                        />

                        <TextField
                            required
                            type='text'
                            name='pairForPrice'
                            label="Paire"
                            defaultValue={ currency.pairForPrice ? currency.pairForPrice : currency.symbol + 'USDT' }
                        />

                        <TextField
                            required
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='priceSpread'
                            label="Écart de prix (%)"
                            defaultValue={ currency.priceSpread }
                            />

                        <TextField
                            required
                            type='number'
                            inputProps={{ step: 'any'}}
                            name='numberDecimals'
                            label="Nombre chiffres après virgule"
                            defaultValue={ currency.numberDecimals }
                            />

                        <FormControl 
                            fullWidth >

                            <InputLabel id="select_activation">Activé / Désactivé</InputLabel>
                            
                            <Select
                                labelId="select_activation"
                                name='activated'
                                defaultValue={ currency.activated }
                                label="Activé / Désactivé"
                                fullWidth
                                margin="dense"
                                required
                                >

                                {
                                    [1, 0].map((activated, index) => {

                                        return (
                                            <MenuItem key={ index } value={ activated }>
                                                { activated === 1 ? "Activée" : "Désactivée" }
                                            </MenuItem>
                                        )

                                    })
                                }
                
                            </Select>        

                        </FormControl>

                        <Button type='submit' variant='contained' fullWidth>
                            Modifier
                        </Button>

                    </Box>


                </Box>

                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    sx={{mb: 3, mt: 2}}
                    onChange={(e) => setSw(e.target.value)}
                />

                <Typography sx={{mt:5, mb:2}}>
                    Afficher sur les tendances :
                </Typography>

                <Chip
                    label="Devise en tendance"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={setTrendindCurrencies}
                                checked={currency.trending == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />

                <Typography sx={{mt:5, mb:2}}>
                    Frais fixe ou en pourcentage (%) :
                </Typography>

                <Chip
                    label="Frais en pourcentage (%)"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={setFeeSystem}
                                checked={currency.feeInPercentage == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />

                <Typography sx={{mt:5, mb:2}}>
                    Devise numérique  de plateforme en ligne :
                </Typography>

                <Chip
                    label="Devise numérique"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={setIsDigitalCurrency}
                                checked={currency.isDigitalCurrency == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />

                <Typography sx={{mt:5, mb:2}}>
                    Autoriser le SWAP :
                </Typography>

                <Chip
                    label="Disponible pour SWAP"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={setAllowForSwap}
                                checked={currency.allowedForSwap == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />


                <Typography sx={{mt:5, mb:2}}>
                    Autoriser les transactions automatiques :
                </Typography>

                <Chip
                    label="Transactions automatiques"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={setAllowAutomaticTrancation}
                                checked={currency.allowAutomaticTrancation == 1 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />
                

                <Typography sx={{mt:5, mb:2}}>
                    Action d'achat et de vente :
                </Typography>

                <Chip
                    label="Autoriser l'achat tous réseaux"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={
                                    () => handleManageBuyAndSaleAction('buy')
                                }
                                checked={allowed_networks && allowed_networks.filter((item_network) => item_network?.allowForBuy).length > 0 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />

                <Chip
                    label="Autoriser la vente tous réseaux"
                    onClick={() => {}}
                    avatar={<Checkbox onChange={
                                    () => handleManageBuyAndSaleAction('sell')
                                }
                                checked={allowed_networks && allowed_networks.filter((item_network) => item_network?.allowForSell).length > 0 ? true : false}
                                />
                            }
                    variant="outlined"
                    sx={{m:1}}
                />


                <Typography sx={{mt:3, mb:2}}>
                    Réseaux blockchains :
                </Typography>

                {
                    currency && networks.map((network, index) => {

                        let networkIsAllowed = allowed_networks?.filter((item) => item.network === network.symbol);
                        let _networkIsAllowed = networkIsAllowed?.length === 0 ? false : true;
                        
                        return (

                            <Accordion sx={{bgcolor: 'white'}} key={index}>
                                <AccordionSummary   expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    >
                                    
                                    { network.symbol + " (" + network.name + ")"}

                                    {
                                        _networkIsAllowed &&
                                    
                                        <Chip label="Activé" 
                                                            sx={{
                                                                backgroundColor: "#EDF4FB",
                                                                color: "#1976D2",
                                                                borderRadius: 1,
                                                                ml: 2
                                                            }} 
                                                            variant="filled" 
                                                            size="small" />
                                    }
                                </AccordionSummary>

                                <AccordionDetails>
                                    <ItemNetwork network={network} 
                                                    currency={currency} 
                                                    handleManageBuyAndSellAction={handleManageBuyAndSaleAction} />
                                </AccordionDetails>

                            </Accordion>
                        )
                    })
                }

            </Box>

        </div>
    )
}
