import { useEffect, useState, ReactNode } from 'react'
import { 
    Typography, List, Button, ListItem, Avatar, ListItemAvatar, 
    ListItemText, Chip, Divider, ToggleButtonGroup, ToggleButton, 
    TextField, Tooltip, Badge, Stack, Box 
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import axiosApi from '../../../functions/axiosApi';
import { basicProps, orderStatusType, orderType } from '../../../utils/interfaces';
import ConfirmOrderForm from './ConfirmOrderForm';
import CancelOrderForm from './CancelOrderForm';
import SetPaimentProofForm from './SetPaimentProofForm'
import QueryString from 'qs';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { copyClipboard, publicPath, readabilityNumber } from '../../../functions/basicFunctions';
import OrderStatusLabel from '../../../components/OrderStatusLabel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OrderTypeBadge from '../../../components/OrderTypeBadge';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckHash from './CheckHash';

interface orderStatusCountType {
    all_count: number,
    waiting_payment: number,
    marked_paid: number,
    canceled_by_user: number,
    canceled_by_admin: number,
    completed: number,
    pendingAddressesCount: number
}

export interface orderAttachedMessagesType {
    status: orderStatusType,
    content: {
        title: string,
        message: string,
        allowedFor: 'cancel' | 'validate',
        icon: ReactNode
    }[]
}

const waNumber = '+237 650 83 94 31';

export const orderAttachedMessages: orderAttachedMessagesType[] = [
    {
        status: 'waiting_payment',
        content: [
            {
                title: "Délai dépassé",
                message: `
                    <b>Le délai de paiement est dépassé !</b> 
                    <br/><br/>
                    Vous n'avez pas effectué le paiement dans les délais requis.
                    <br/>
                    Si vous avez déjà effectué le paiement mais n'avez pas marqué la commande comme payée, veuillez nous contacter immédiatement sur WhatsApp au <b>${waNumber}</b> pour vérification.
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "A la demande de l'utilisateur",
                message: `
                    <b>Annulé à la demande de l'utilisateur.</b>
                    <br/><br/>
                    Cet ordre a été annulé car vous avez contacté CRYPTO-FIAT et demandé son annulation.
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            }
        ]
    },

    {
        status: 'marked_paid',
        content: [
            {
                title: "Ordre validé",
                message: `
                    <b>Félicitations !</b> 
                    <br/>
                    Votre commande a été validée avec succès. 
                    <br/><br/>
                    Nous vous remercions pour votre confiance. 
                    <br/>
                    Nous sommes impatients de continuer à travailler avec vous. Si vous avez des questions ou besoin d'assistance, n'hésitez pas à nous contacter.
                `,
                allowedFor: 'validate',
                icon: <CheckCircleIcon color='success' />
            },

            {
                title: "Paiement non reçu",
                message: `
                    <b>Nous n'avons pas reçu le paiement pour cette commande.</b>
                    <br/><br/>
                    Si vous avez effectué le paiement, veuillez nous contacter sur WhatsApp au <b>${waNumber}</b> pour vérification.
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Montant reçu incorrect",
                message: `
                    <b>Le montant reçu ne correspond pas.</b>
                    <br/><br/>
                    Nous avons reçu <b>[montant reçu]</b>. 
                    <br/><br/>
                    Veuillez créer un nouvel ordre avec le montant correct et marquez-le comme payé directement. 
                    <br/>
                    Si vous avez besoin d'assistance, veuillez contacter notre service client sur WhatsApp au <b>${waNumber}</b>
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "A la demande de l'utilisateur",
                message: `
                    <b>Annulé à la demande de l'utilisateur.</b> 
                    <br/><br/>
                    Cet ordre a été annulé car vous avez contacté CRYPTO-FIAT et demandé son annulation.
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Adresse incorrecte",
                message: `
                    <b>L'adresse/numéro de réception n'est pas valide.</b>
                    <br/><br/> 
                    Veuillez créer un nouvel ordre avec une adresse correcte et marquez-le comme payé directement. 
                    <br/>
                    Si vous avez besoin d'assistance, veuillez contacter notre service client sur WhatsApp au <b>${waNumber}</b>
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Mauvais réseau",
                message: `
                    <b>L'adresse de réception n'est pas compatible avec le réseau choisi.</b> 
                    <br/><br/>
                    Veuillez créer un nouvel ordre en choisissant le bon réseau et marquez-le comme payé directement. 
                    <br/>
                    Si vous ne savez pas quel réseau choisir, veuillez nous contacter sur WhatsApp en fournissant votre adresse de réception afin que nous puissions déterminer le réseau pour vous. <b>${waNumber}</b>
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Soupçon de SCAM",
                message: `
                    <b>Cette transaction nécessite une vérification supplémentaire.</b> 
                    <br/><br/>
                    Veuillez nous contacter sur WhatsApp pour terminer la vérification. <b>${waNumber}</b>
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Nom du compte incorrect",
                message: `
                    <b>Le nom ne correspond pas au numéro/adresse.</b>
                    <br/><br/>
                    Veuillez créer un nouvel ordre avec les informations correctes et le marquer comme payé directement. 
                    <br/>
                    Si vous avez besoin d'assistance, veuillez contacter notre service client sur WhatsApp au <b>${waNumber}</b>
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Adresse SCAM",
                message: `
                    <b>Nous sommes incapables de transférer des fonds vers votre adresse de réception actuelle.</b> 
                    <br/><br/>
                    Veuillez créer un nouvel ordre avec une adresse différente et le marquer comme payé directement. 
                    <br/>
                    Si vous avez besoin d'assistance, veuillez contacter notre service client sur WhatsApp au <b>${waNumber}</b>
                `,
                allowedFor: 'cancel',
                icon: <HighlightOffIcon color='error' />
            }
        ]
    }
]

export default function Orders(props: basicProps) {

    const basicDatas = props.basicDatas;
    const [orders, setOrders] = useState([]);
    const [orderStatusCount, setOrderStatusCount] = useState<orderStatusCountType>({
        all_count: 0,
        waiting_payment: 0,
        marked_paid: 0,
        canceled_by_user: 0,
        canceled_by_admin: 0,
        completed: 0,
        pendingAddressesCount: 0
    });
    const [status, setStatus] = useState('all');
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [sw, setSw] = useState('');

    let _orderStatusCount = {...orderStatusCount};

    useEffect(() => {
        getOrders();
        getOrdersCount();
    }, [status, queryValue])

    useEffect(() => {

        const intervalGetOrdersStatusCount = setInterval(() => {
            getOrdersCount(); 
        }, 30000)

        return () => {
            clearInterval(intervalGetOrdersStatusCount)
        } 

    }, [])
    

    const getOrdersCount = () => {

        axiosApi.post("ctr/orders/get-orders-count.php", QueryString.stringify({status, offset, limit:5, queryValue}))
        .then(response => {
            
            if(response.data.status === 'success'){

                const newOrderNotificationSon = document.getElementById('new-order-notification') as HTMLAudioElement;
                const pendingAddressNotification = document.getElementById('pending-address-notification') as HTMLAudioElement;
                
                if(newOrderNotificationSon && (_orderStatusCount.waiting_payment !== response.data.orderStatusCount.waiting_payment)){
                    newOrderNotificationSon.play();
                }

                if(pendingAddressNotification && (_orderStatusCount.pendingAddressesCount < response.data.orderStatusCount.pendingAddressesCount)){
                    pendingAddressNotification.play();
                }

                _orderStatusCount = response.data.orderStatusCount

                setOrderStatusCount(response.data.orderStatusCount);
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }

    const getOrders = () => {

        setIsLoading(true);

        axiosApi.post("ctr/orders/get-orders.php", QueryString.stringify({status, offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newOrdersArray = orders.concat(response.data.orders);
                setOrders(newOrdersArray);
                setOffset(offset + 5);
                
                if(response.data.orders.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }

    const reactiveOrder = (order: orderType) => {
        basicDatas.dialogManager({
            dialogTitle: "Réactivtion de l'ordre (" + order.basic.orderID + ")",
            dialogText: "Vous allez changer le statut de cet ordre en ATTENTE DE PAIEMENT. Faut-il vraiment exécuter cette action ?",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui exécuter"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    orderID:order.basic.orderID,
                    loaderText: "Annulation en cours..."
                }
                axiosApi.post('ctr/orders/reactive-order.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                })
            }
      
        })
    }

    const binanceWithdraw = (order: orderType) => {
        basicDatas.dialogManager({
            dialogTitle: "Payer automatiquement (" + order.basic.orderID + ")",
            dialogText: "Cette action va effectuer un paiement automatiquement via Binance.",
            dialogAgreeBtnColor: 'warning',
            dialogAgreeBtnText: "Oui valider"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    orderID:order.basic.orderID,
                    sw,
                    loaderText: "Traitement en cours..."
                }
                axiosApi.post('ctr/orders/pay-by-binance-withdraw.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                })
            }
      
        })
    }
    
    return (
        <div style={{maxWidth:'700px', margin:'auto'}}>
            
            <br />

            <Typography variant="h4" className='text-center Montserrat' component="h5">
                Ordres
            </Typography>

            <br /> <br />

            <div className="text-center">
                <ToggleButtonGroup
                    color="primary"
                    value={status}
                    exclusive
                    onChange={
                        (e, newStatus) => {
                            setStatus(newStatus);
                            setOrders([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    sx={{
                        maxWidth: '100%',
                        overflow: 'auto'
                    }}
                    aria-label="order-status"
                    >
                    
                    <ToggleButton value="all" sx={{minWidth: '150px', textTransform: 'none'}}>
                        Tout ({orderStatusCount ? orderStatusCount.all_count : 0})
                    </ToggleButton>

                    <ToggleButton value="waiting_payment" sx={{minWidth: '210px'}}>
                        <Badge 
                            max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.waiting_payment : 0} 
                            color="primary"
                            sx={{textTransform: 'none'}}
                        >
                            En attente paiement
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="marked_paid" sx={{minWidth: '150px'}}>
                        <Badge 
                            max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.marked_paid : 0} 
                            color="primary"
                            sx={{textTransform: 'none'}}
                        >
                            Marqué payé
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="completed" sx={{minWidth: '100px'}}>
                        <Badge 
                            max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.completed : 0} 
                            color="success"
                            sx={{textTransform: 'none'}}
                        >
                            Terminé
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="canceled_by_user" sx={{minWidth: '200px'}}>
                        <Badge 
                            max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.canceled_by_user : 0} 
                            color="error"
                            sx={{textTransform: 'none'}}
                        >
                            Annulé par user
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="canceled_by_admin" sx={{minWidth: '200px'}}>
                        <Badge 
                            max={9999999999999} badgeContent={orderStatusCount ? orderStatusCount.canceled_by_admin : 0} 
                            color="error"
                            sx={{textTransform: 'none'}}
                        >
                            Annulé par admin
                        </Badge>
                    </ToggleButton>

                </ToggleButtonGroup>

                <br /> <br />

                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher un ordre : ID" 
                    variant="outlined"
                    placeholder="Entrez l'ID de l'ordre"
                    value={queryValue}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setOrders([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                />

                <br /><br />

                <input type="password" value={sw} onChange={(e) => setSw(e.target.value)} />

            </div>

            {
                ((queryValue.length !== 0) && (orders.length === 0)) &&
                <Typography className='text-center mt-4'>
                    Aucun ID d'ordre correspondant à <b>{queryValue}</b>
                </Typography>
            }

            {
                orders.length === 0 &&
                <Typography className='text-center mt-5 small text-muted'>
                    Aucun ordre
                </Typography>
            }

            <br /><br />

            <List sx={{ bgcolor: 'background.paper', margin:'auto' }}>
                
                {
                    orders.map((order: orderType, orderIndex) => {

                        const basicInfos = order.basic;
                        const action = basicInfos.action;
                        const cryptoCurrency = order.cryptoCurrency;
                        const paymentMethod = order.paymentMethod;
                        const network = order.network;

                        const sendInfos = order.sendInfos;
                        const sendCurrency = sendInfos.sendCurrency;
                        const sendNetwork = sendInfos.sendNetwork;
                        const sendQte = sendInfos.sendQte;

                        if (action === 'swap') return (
                            <Box key={orderIndex}>

                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="text.primary"
                                    sx={{fontWeight:'bold', mb: 1}}
                                >
                                    <Tooltip title="Cliquez pour copier l'ID de l'ordre">
                                        <span style={{cursor:'pointer'}} onClick={() => copyClipboard(basicInfos.orderID, "L'ID de l'ordre a été copié !")}>{"ID : " + basicInfos.orderID}</span>
                                    </Tooltip>
                                </Typography>

                                <Typography sx={{mb: 1}}>
                                    <Link to={"/orders/" + basicInfos.orderID + "/"} className="link">
                                        <OrderTypeBadge type={basicInfos?.action} /> 
                                    </Link>
                                </Typography>

                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Statut : <OrderStatusLabel orderStatus={basicInfos.status} />
                                    <br />
                                    Créé par : <a href={'/ctr/users/' + order.user.user_id + '/' } target='_blank' className='text-muted'>{order.user?.firstName + " " + order.user?.lastName}</a>
                                    <br />
                                    Depuis : { basicInfos.fromCountry ?  basicInfos.fromCountry : '...'}
                                </Typography>

                                <br />

                                {
                                    basicInfos.status === 'waiting_payment' &&
                                
                                    <Typography>

                                        Delai paiement :

                                        <Typography variant='caption' sx={{fontWeight: 'bold', color: '#D32F2F'}}>
                                            { sendNetwork.maxTimePaymentLimit + ' minutes' }
                                        </Typography>
                                        
                                    </Typography>
                                }

                                <Typography color='GrayText'>
                                    {moment(basicInfos.date).fromNow()}
                                </Typography>
                
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                >

                                    <List>

                                        <ListItem alignItems="flex-start" sx={{p: 0}}>

                                            <ListItemAvatar>
                                                <Avatar 
                                                    alt={sendCurrency.symbol} 
                                                    src={sendCurrency.logo}
                                                    sx={{
                                                        width: 25,
                                                        height: 25
                                                    }}
                                                />
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={<b>{sendCurrency.symbol}</b>}
                                                secondary={
                                                    <>
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                            color="text.primary"
                                                        >
                                                            { sendNetwork.symbol }
                                                        </Typography>

                                                        <Typography>
                                                            { sendQte }
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>

                                    </List>

                                    <ArrowRightAltIcon color='primary' sx={{fontSize: 40, opacity: 0.7}} />

                                    <List>

                                        <ListItem alignItems="flex-start" sx={{p: 0}}>

                                            <ListItemAvatar>
                                                <Avatar 
                                                    alt={cryptoCurrency.symbol} 
                                                    src={cryptoCurrency.logo} 
                                                    sx={{
                                                        width: 25,
                                                        height: 25
                                                    }}
                                                />
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={<b>{cryptoCurrency.symbol}</b>}
                                                secondary={
                                                    <>
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                            color="text.primary"
                                                        >
                                                            { network.symbol }
                                                        </Typography>

                                                        <Typography>
                                                            { basicInfos.cryptoQte }
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>

                                    </List>
                                    
                                </Stack>

                                <br />

                                {
                                    (basicInfos.status === 'marked_paid') &&
                                    <>
                                        <ConfirmOrderForm orderData={order} orderAttachedMessages={orderAttachedMessages} {...props} />
                                        <CheckHash orderData={order} orderAttachedMessages={orderAttachedMessages} {...props} />
                                    </>
                                }

                                {
                                    ((basicInfos.status === 'waiting_payment') || (basicInfos.status === 'marked_paid')) &&
                                    <CancelOrderForm orderData={order} orderAttachedMessages={orderAttachedMessages} {...props} />
                                }

                                {
                                    basicInfos.status === 'canceled_by_admin' &&
                                    <Button variant='contained' color='inherit' onClick={() => reactiveOrder(order)}>
                                        Activer l'ordre
                                    </Button>
                                }

                                {
                                    (
                                        (basicInfos.status === 'marked_paid') 
                                        && (network.allowAutomaticTransaction == 1)
                                        && (cryptoCurrency.allowAutomaticTrancation == 1)
                                    ) 
                                        &&
                                    <Button color='inherit' onClick={() => binanceWithdraw(order)}>
                                        Retrait Binance
                                    </Button>
                                }

                                {
                                    basicInfos.status === 'completed' &&
                                    <SetPaimentProofForm orderData={order} orderAttachedMessages={orderAttachedMessages} {...props} />
                                }

                                <div className='text-right' style={{marginTop:'15px', textAlign:'right'}}>
                            
                                    <Link to={"/orders/" + basicInfos.orderID + "/"} target='_blank' className="link text-primary" style={{display:'inline-block', marginRight:'20px'}}>
                                        <Button>
                                            Récap
                                        </Button>
                                    </Link>

                                    <Link to={"/orders/" + basicInfos.orderID + "/reglement/"} target='_blank' className="link text-primary" style={{display:'inline-block'}}>
                                        <Button>
                                            Règlement
                                        </Button>
                                    </Link>

                                </div>
                                
                                <Divider variant="inset" component="li" sx={{mt:4, mb:4}} />

                            </Box>
                        ) 




                        return (

                            <div key={orderIndex}>

                                <ListItem alignItems="flex-start" sx={{pl: 0}}>
                                    <ListItemAvatar>
                                        <Avatar alt={cryptoCurrency.symbol} src={cryptoCurrency.logo} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <>

                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{fontWeight:'bold'}}
                                                > 
                                                    <Tooltip title="Cliquez pour copier l'ID de l'ordre">
                                                        <span style={{cursor:'pointer'}} onClick={() => copyClipboard(basicInfos.orderID, "L'ID de l'ordre a été copié !")}>{"ID : " + basicInfos.orderID}</span>
                                                    </Tooltip>
                                                </Typography>

                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{fontWeight:'bold'}}
                                                >
                                                    { readabilityNumber(basicInfos.cryptoQte) + " " + cryptoCurrency.symbol + "_" + network.symbol}
                                                </Typography>

                                                {" — "} 

                                                <Chip label={basicInfos.action === 'buy' ? "Achat" : "Vente"} 
                                                        sx={{
                                                            backgroundColor: basicInfos.action === 'buy' ? "#E8F3EE" : "#FDEDED",
                                                            color: basicInfos.action === 'buy' ? "#338F5C" : "#E5593C",
                                                            borderRadius: 1
                                                        }} 
                                                        variant="filled" 
                                                        size="small" />

                                                <div>{cryptoCurrency.name}</div>
                                                
                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Pour : { readabilityNumber(basicInfos.fiatQte) + ' ' + paymentMethod.symbol } 
                                                </Typography>

                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    { basicInfos.paymentMethodNumber } 
                                                </Typography>

                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Statut : <OrderStatusLabel orderStatus={basicInfos.status} />
                                                    <br />
                                                    Créé par : <a href={'/ctr/users/' + order.user.user_id + '/' } target='_blank' className='text-muted'>{order.user?.firstName + " " + order.user?.lastName}</a>
                                                    <br />
                                                    Depuis : { basicInfos.fromCountry ?  basicInfos.fromCountry : '...'}
                                                </Typography>

                                            </>
                                        }
                                        secondary={
                                            <>

                                                {
                                                    basicInfos.status === 'waiting_payment' &&
                                                
                                                    <Typography>

                                                        Delai :

                                                        <Typography variant='caption' sx={{fontWeight: 'bold'}}>
                                                            {
                                                                basicInfos.action === 'buy' 
                                                                ?   ' ' + paymentMethod.maxTimePaymentLimit + ' minutes' 
                                                                :   ' ' + network.maxTimePaymentLimit + ' minutes'
                                                            }
                                                        </Typography>
                                                        
                                                    </Typography>
                                                }

                                                <div>
                                                    {moment(basicInfos.date).fromNow()}
                                                </div>

                                                <br />

                                                {
                                                    (basicInfos.status === 'marked_paid') &&
                                                    <>
                                                        <ConfirmOrderForm orderData={order} orderAttachedMessages={orderAttachedMessages} {...props} />
                                                        {action === 'sell' && <CheckHash orderData={order} orderAttachedMessages={orderAttachedMessages} {...props} /> }
                                                    </>
                                                }

                                                {
                                                    ((basicInfos.status === 'waiting_payment') || (basicInfos.status === 'marked_paid')) &&
                                                    <CancelOrderForm orderData={order} orderAttachedMessages={orderAttachedMessages} {...props} />
                                                }

                                                {
                                                    basicInfos.status === 'canceled_by_admin' &&
                                                    <Button variant='contained' color='inherit' onClick={() => reactiveOrder(order)}>
                                                        Activer l'ordre
                                                    </Button>
                                                }

                                                {
                                                    (
                                                        (basicInfos.status === 'marked_paid') 
                                                        && (basicInfos.action === 'buy')
                                                        && (network.allowAutomaticTransaction == 1)
                                                        && (cryptoCurrency.allowAutomaticTrancation == 1)
                                                    ) 
                                                        &&
                                                    <Button color='inherit' onClick={() => binanceWithdraw(order)}>
                                                        Retrait Binance
                                                    </Button>
                                                }

                                                {
                                                    basicInfos.status === 'completed' &&
                                                    <SetPaimentProofForm orderData={order} orderAttachedMessages={orderAttachedMessages} {...props} />
                                                }

                                                <div className='text-right' style={{marginTop:'15px', textAlign:'right'}}>
                                            
                                                    <Link to={"/orders/" + basicInfos.orderID + "/"} target='_blank' className="link text-primary" style={{display:'inline-block', marginRight:'20px'}}>
                                                        <Button>
                                                            Récap
                                                        </Button>
                                                    </Link>

                                                    <Link to={"/orders/" + basicInfos.orderID + "/reglement/"} target='_blank' className="link text-primary" style={{display:'inline-block'}}>
                                                        <Button>
                                                            Règlement
                                                        </Button>
                                                    </Link>

                                                </div>
                                            </>
                                        }
                                    />
                                </ListItem>
                                
                                <Divider variant="inset" component="li" sx={{mt:4, mb:4}} />

                            </div>
                        )
                    })
                }
            </List>

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getOrders()}>
                            Afficher plus
                        </Button>
                }

            </div>

            <audio src={publicPath + "/sons/new-order-notification.mp3"}
                    id="new-order-notification"
                    autoPlay={false} />
            <audio src={publicPath + "/sons/pendingAddress.mp3"}
                    id="pending-address-notification"
                    autoPlay={false} />


        </div>
    )
}
