import { useState } from 'react'
import { Box, TextField, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axiosApi from '../../../../functions/axiosApi';

interface myProps {
    userID: string
}

export default function Edit(props: myProps) {

    const [open, setOpen] = useState(false);

    const update = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('userID', props.userID)

        axiosApi.post('ctr/airdrops/discounts/set-discount.php', data)
        .then(response => {

            if(response.data.status === 'success') {
                setOpen(false);
            }
        })
    }

    return (
        <Box>
            
            {
                !open && 
                <EditIcon 
                    color='info'
                    onClick={() => setOpen(true)}
                    sx={{
                        cursor: 'pointer'
                    }}
                />
            }

            {
                open &&

                <Box
                    component='form'
                    onSubmit={update}
                    sx={{
                        mb: 2
                    }}
                >

                    <TextField 
                        name='purchaseDiscount'
                        label="Remise Achat"
                        placeholder="Remise en CFT"
                        margin='normal'
                        fullWidth
                        required
                    />

                    <TextField 
                        name='salesDiscount'
                        label="Remise vente"
                        placeholder="Remise en CFT"
                        margin='normal'
                        fullWidth
                        required
                    />

                    <TextField 
                        name='sw'
                        type='password'
                        label="sw"
                        margin='normal'
                        fullWidth
                        required
                        sx={{
                            mb: 2
                        }}
                    />

                    <Button
                        type='button'
                        onClick={() => setOpen(false)}
                        sx={{
                            mr: 3
                        }}
                    >
                        Annuler
                    </Button>

                    <Button
                        variant='contained'
                        type='submit'
                    >
                        Modifier
                    </Button>

                </Box>
            }
        </Box>
    )
}
