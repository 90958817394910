import { useState, useEffect } from 'react'
import { User, basicProps } from '../../../../utils/interfaces'
import { Avatar, Box, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, TextField, Tooltip, Typography } from '@mui/material'
import AddUser from './AddUser'
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import { copyClipboard } from '../../../../functions/basicFunctions';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Edit from './Edit';

interface myUser extends User {
    discount: {
        purchaseDiscount: number,
        salesDiscount: number
    }
}

export default function Discounts(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [users, setUsers] = useState<myUser[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [sw, setSw] = useState('')

    useEffect(() => {
        getUsers()
    }, [queryValue])

    const getUsers = () => {

        setIsLoading(true);

        axiosApi.post("ctr/airdrops/discounts/get-discounts.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newUsersArray = users.concat(response.data.discounts);
                setUsers(newUsersArray);
                setOffset(offset + 5);

                if(response.data.users.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    const removeUser = (userID) => {
        
        basicDatas.dialogManager({
            dialogTitle: "Retirer un compte",
            dialogText: "Vous allez retirer les remises pour un compte.",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Retirer",
            dalogCloseBtnText: 'Annuler'
        }, response => {
            if(response === 'ok') {
                axiosApi.post('ctr/airdrops/discounts/remove-user.php', QueryString.stringify({userID, sw}))
            }
        })
    }

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto',
                pt: 5
            }}
        >
            
            <Typography textAlign='center' fontWeight='bold' variant='h5'>
                Remises
            </Typography>

            <br /><br />

            <AddUser {...props} />

            <div 
                className="text-center" 
                style={{
                    marginBottom: '40px'
                }}
            >

                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher un utilisateur" 
                    variant="outlined"
                    placeholder="Entrez le nom de l'utilisateur"
                    value={queryValue}
                    sx={{mt:3}}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setUsers([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    />

            </div>

            <List 
                sx={{ 
                    width: '100%', 
                    maxWidth: 400, 
                    bgcolor: 'background.paper', 
                    margin:'auto' 
                }}
            >
                            
                {
                    users.map((user: myUser) => {
                        return (

                            <div key={user.user_id}>

                                <ListItem alignItems="flex-start" >

                                    <ListItemAvatar sx={{cursor:'pointer'}}>
                                        <Avatar alt={user.lastName + " " + user.firstName} src={user.avatarUrl} />
                                    </ListItemAvatar>

                                    <ListItemText
                                        primary={

                                                <>
                                                    <Typography>
                                                        <Tooltip title="Cliquez pour copier ID utilisateur">
                                                            <span style={{cursor:'pointer', fontWeight: 'bold'}} onClick={() => copyClipboard(user.user_id, "L'ID de l'utilisateur a été copié !")}>{"ID : " + user.user_id}</span>
                                                        </Tooltip>
                                                    </Typography>

                                                    <Typography 
                                                            sx={{
                                                                fontWeight: 'bold'
                                                            }}>
                                                        {user.firstName + " " + user.lastName}
                                                    </Typography>
                                                </>
                                            }
                                        secondary={
                                            <>

                                                <Typography
                                                    sx={{ display: 'block' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Remise achat : <b>{user.discount.purchaseDiscount }</b>%
                                                </Typography>

                                                <Typography
                                                    sx={{ display: 'block' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Remise vente : <b>{user.discount.salesDiscount }</b>%
                                                </Typography>

                                                <br />

                                                <Edit userID={user.user_id} />

                                                <br />

                                                <DeleteForeverIcon 
                                                    color='error'
                                                    onClick={() => removeUser(user.user_id)}
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                />

                                            </>
                                        }
                                    />
                                </ListItem>

                                <Divider variant="inset" component="li" sx={{mb: 3}} />

                            </div>
                        )
                    })
                }
            </List>

            <br />

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getUsers()}>
                            Afficher plus
                        </Button>
                }

            </div>

            <br />


            <TextField 
                name='sw'
                type='password'
                label="sw"
                value={sw}
                onChange={(e) => setSw(e.target.value)}
            />


        </Box>
    )
}
