import { useState } from 'react';
import { 
    Alert, Box, Button, Typography, TextareaAutosize, TextField, Stack, Dialog, DialogTitle, DialogContent, DialogActions, Chip, Skeleton
} from "@mui/material";
import { basicProps, currencyType, networkType, saveAddressType } from "../../utils/interfaces";
import InfoPopup from "../../components/InfoPopup";
import { isPC } from "../../functions/basicFunctions";
import { toast } from "react-toastify";
import InfoIcon from '@mui/icons-material/Info';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { HashLink } from 'react-router-hash-link';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';
import { Link } from 'react-router-dom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface myProps extends basicProps {
    selectedCrypto: currencyType | undefined | null,
    blockchainAddress: string,
    memo: string,
    addessIsValid: boolean;
    selectedNetwork: networkType | undefined;
    handleBlockchainAddressChange: (value: string) => void;
    setBlockchainAddress: (value: string) => void;
    setMemo: (value: string) => void;
}

export default function WalletAddress(props: myProps) {

    const basicDatas = props.basicDatas;
    const selectedCrypto = props.selectedCrypto;
    const selectedNetwork = props.selectedNetwork;
    const blockchainAddress = props.blockchainAddress;
    const addessIsValid = props.addessIsValid;

    const [open, setOpen] = useState(false);
    const [savedAddresses, setSavedAddresses] = useState<saveAddressType[]>([]);
    const [addressesLoaded, setAddressesLoaded] = useState(false);

    let networkIsAllowed = false;

    if(selectedCrypto && selectedNetwork && selectedCrypto.allowed_networks) {
        let matchNetwork = selectedCrypto?.allowed_networks.filter((itemNetwork) => itemNetwork.network === selectedNetwork.symbol);
        if(matchNetwork.length !== 0) {
            networkIsAllowed = true;
        }
    }
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const showSavedAddresses = () => {
        handleClickOpen();

        axiosApi.post("settings/saved_addresses/get-blockchain-addresses.php", QueryString.stringify({network: selectedNetwork?.symbol}))
        .then(response => {
            if(response.data.status === 'success') {
                setSavedAddresses(response.data.addresses)
                setAddressesLoaded(true);
            }
        })
    }

    // const readAdressClipboard = () => {

    //     if(!selectedNetwork) {
    //         toast.info("Veuillez d'abord sélectionner le réseau.");
    //         return;
    //     }

    //     readClipboard((clipboardContent) => {
    //         if(clipboardContent && typeof clipboardContent === 'string') {
    //             props.setBlockchainAddress(clipboardContent)
    //             alertPasteContent()
    //         }
    //     })
    // }

    const alertPasteContent = () => {
        basicDatas.dialogManager({
            dialogTitle: <Typography fontWeight='bold' fontSize={25}>Soyez prudent(e).</Typography>,
            dialogText: <Typography>
                <Typography fontWeight='bold' color='error'>
                    Assurez-vous que l'adresse collée correspond bien à celle de votre portefeuille que vous avez copiée précédemment.
                </Typography>

                <br />

                <Typography variant='caption'>
                    En effet, certains malwares (cheval de Troie) peuvent modifier votre adresse lorsque vous faites un copier-coller, la remplaçant par celle d'un pirate informatique. Veuillez toujours vérifier que l'adresse est correcte avant de procéder.
                </Typography>
            </Typography>,
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Je vais vérifier",
            dalogCloseBtnText: ' '
        }, () =>{})
    }

    const selectSavedAddress = (address: saveAddressType) => {
        props.setBlockchainAddress(address.blockchainAddress as string);
        address.memo && props.setMemo(address.memo)
        handleClose();
    }

    if(!selectedCrypto || !selectedNetwork || !networkIsAllowed) return <></>

    return (
        <Box>

            {
                (selectedCrypto 
                    && selectedNetwork 
                    && selectedCrypto.allowed_networks?.filter(allowed_network => selectedNetwork.symbol === allowed_network.network)[0].buyAlertMessage
                ) &&

                <Alert severity="info" 
                        sx={{mb: 1, textAlign:'left', fontSize:'small'}}>
                        
                    { selectedCrypto.allowed_networks?.filter(allowed_network => selectedNetwork.symbol === allowed_network.network)[0].buyAlertMessage }

                </Alert>
            }
            

            <div style={{opacity: (selectedNetwork && selectedNetwork.id) ? 1 : 0.4}}> 

                <Typography sx={{mb: 1}}>
                    <label htmlFor="address" className="small">
                        Adresse portefeuille {selectedNetwork?.symbol} 
                    </label> 

                    {/* {
                        blockchainAddress.length === 0 &&

                        <Button color='info' 
                                style={{float: 'right'}}
                                onClick={readAdressClipboard} >
                            Coller
                        </Button>
                    } */}

                </Typography>

                <TextareaAutosize
                    aria-label="Adresse blockchain"
                    name='blockchainAddress'
                    id="address"
                    minRows={3}
                    required
                    value={blockchainAddress}
                    onChange={(e) => props.handleBlockchainAddressChange(e.target.value)}
                    //disabled={!(selectedNetwork && selectedNetwork.id)}
                    disabled
                    placeholder={selectedNetwork ? "Exemple : " + selectedNetwork.address : 'Adresse publique de votre portefeuille crypto'}
                    onPaste={(event) => {
                        event.preventDefault();

                        if(event.clipboardData) {
                            let paste = event.clipboardData.getData("text");
                            //props.handleBlockchainAddressChange(paste)
                            alertPasteContent()
                        }
                    }}
                    style={{ 
                        width:'100%', 
                        padding:'10px', 
                        borderRadius:'5px', 
                        border: "1px solid #bbb"
                    }}
                />

                <Typography variant='caption'>
                    Cliquez sur CARNET pour choisir une adresse
                </Typography>
                
                <Stack
                    direction={'row'}
                    alignContent='space-between'
                    spacing={2}
                    >

                    <Button startIcon={<StickyNote2Icon />} onClick={showSavedAddresses}>
                        Carnet
                    </Button>

                    <Dialog open={open} onClose={handleClose}>

                        <DialogTitle>Carnet d'adresses</DialogTitle>

                        <DialogContent sx={{minWidth: 300}}>

                            <Typography sx={{mb: 3}}>
                                <Typography variant='caption'>
                                    Choisir parmi vos adresses <b>{ selectedNetwork?.symbol }</b> enregistrées
                                </Typography>
                            </Typography>

                            {
                                !addressesLoaded && 
                                [1,2].map((i) => {
                                    return (
                                        <Skeleton
                                            key={i}
                                            animation='wave'
                                            variant='rectangular'
                                            sx={{height: 100, borderRadius: 1, mb: 2}}
                                        />
                                    )
                                })
                            }

                            {
                                savedAddresses.length === 0 &&
                                <Box
                                    sx={{textAlign: 'center'}}>
                                    Vous n'avez aucune adresse { selectedNetwork?.symbol } enregistrée !
                                </Box>
                            }
                            
                            {
                                savedAddresses.map(itemAddress => {
                                    return (
                                        <Box
                                            key={itemAddress.id}
                                            onClick={() => {

                                                if(itemAddress.status !== 'basic'){
                                                    selectSavedAddress(itemAddress)
                                                }
                                            }
                                            }
                                            sx={{
                                                pb: 3,
                                                mb: 3,
                                                boxShadow: 2,
                                                p: 2,
                                                borderRadius: 1,
                                                cursor: itemAddress.status === 'basic' ? 'inherit' : 'pointer',
                                                opacity: itemAddress.status === 'basic' ? 0.5 : 1
                                            }}
                                        >
                                            <Typography sx={{fontWeight: 'bold'}}>
                                                {itemAddress.walletName}
                                            </Typography>

                                            <Typography
                                                color='GrayText'>
                                                { itemAddress.network }
                                            </Typography>
                                        
                                            <Typography sx={{wordWrap: 'break-word'}}>
                                            
                                                { 
                                                    itemAddress._system === 'blockchain' &&  
                                                    <>
                                                        { itemAddress.blockchainAddress } { itemAddress.memo &&  <Typography variant='caption'> ( { itemAddress.memo } ) </Typography> }
                                                    </>
                                                }

                                            </Typography>

                                            { 
                                                (itemAddress.status === 'basic') &&  
                                                <>
                                                    <Chip 
                                                        label="En cours de vérification..." 
                                                        sx={{
                                                            backgroundColor: "#FDEDED",
                                                            color: "#E5593C",
                                                            borderRadius: 1,
                                                            mr: 2
                                                        }} 
                                                        variant="filled" 
                                                        size="small" 
                                                        icon={<AccessTimeIcon fontSize='small' sx={{mr:0.5}} />}
                                                    />
                                                </>
                                            }

                                        </Box>
                                    )
                                })
                            }

                            <br />

                            <Typography textAlign='center'>
                                <Link to='/settings/addresses/'>
                                    <Button variant="outlined" startIcon={<NoteAddIcon />}>
                                        Enregistrer une adresse
                                    </Button>
                                </Link>
                            </Typography>

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleClose}>Fermer</Button>
                        </DialogActions>

                    </Dialog>
                
                    <InfoPopup basicDatas={basicDatas}
                                title="C'est quoi une adresse publique de portefeuille crypto?"
                                text={
                                    <div>
                                        Il s'agit de l'adresse que vous fournissez lorsque vous devez recevoir des fonds dans votre portefeuille. 
                                        <br /> <br />
                                        Pour créer un portefeuille afin d'obtenir votre adresse, vous pouvez utiliser une application fiable comme 
                                        <a href="https://trustwallet.com/" target='_blank' rel="noreferrer" style={{marginLeft:'5px'}}>Trust Wallet</a> ou <a href="https://metamask.io/" target='_blank' rel="noreferrer">Metamask</a>.
                                        
                                        <br />

                                        <Typography className="small" sx={{fontStyle:"oblique", mt: 2, fontSize:'small'}}>
                                            Veuillez vous assurer que votre adresse correspond bien au réseau choisi.
                                        </Typography>

                                        <div style={{fontSize: '13px', marginTop:'20px', textAlign:'right'}}>
                                            <a href="https://wa.me/+237650839431/?text=Bonjour, j'ai besoin d'aide pour créer mon portefeuille crypto." target='_blank'>
                                                <WhatsAppIcon fontSize='small' /> J'ai besoin d'aide
                                            </a>
                                        </div>
                                        
                                        <div className='text-danger' style={{fontSize: '13px', marginTop:'20px'}}>
                                            <b>Important : </b> N'envoyez jamais votre clé privée à qui que ce soit sous aucun prétexte. 
                                        </div>
                                    </div>
                                }>
                        <span   className='small text-muted'
                                style={{
                                    fontStyle: 'oblique',
                                    cursor: 'pointer'
                                }}> 
                                    <InfoIcon sx={{mr:0.5, fontSize:'16px'}} color='info' />
                                    Je ne connais pas
                        </span>
                    </InfoPopup>

                </Stack>
                
                {
                    !addessIsValid &&
                    <Alert severity='error' sx={{fontSize: '12px', float: 'left', mb: 2, mt: 2}}>
                        L'adresse semble non compatible avec le réseau <b>{ selectedNetwork?.symbol }</b>. {isPC && " Votre ordre risque d'être annulé par les validateurs."} <br />
                        <HashLink to="/about#contact-us">
                            Contactez
                        </HashLink> le support pour vous assurer de la compatibilité.
                    </Alert>
                }

                {
                    (selectedNetwork && (selectedNetwork.memoRequiredForBuy == 1)) &&
                    <>         

                        <TextField
                            label='Memo (Facultatif)'
                            placeholder={'Ex : ' + selectedNetwork?.memo }
                            fullWidth
                            value={props.memo}
                            onChange={(e) => props.setMemo(e.target.value)}
                            sx={{
                                mt: 2
                            }}
                        />

                        <Alert severity="warning" 
                                sx={{textAlign:'left', fontSize:'small'}}>
                            La plupart des exchanges exigent un MEMO pour que votre { selectedCrypto?.symbol } soit correctement crédité. Veuillez vous assurer que vous avez saisi le bon MEMO pour effectuer votre transaction.
                        </Alert>

                    </>
                }

                <Alert severity='info' sx={{fontSize: '12px', float: 'left', mb: 2, mt: 2, width: '100%'}}>
                    L'enregistrement d'adresse est désormais obligatoire avant toute transaction. 
                    <br />
                    <a 
                        href="https://medium.com/@cryptofiat1022/comment-enregistrer-une-nouvelle-adresse-de-portefeuille-sur-cryptofiat-73fbdb80a124" 
                        target='_blank'
                        style={{fontSize: 15}}
                    >
                        Comment enregistrer une adresse?
                    </a>
                </Alert>

            </div>
            
        </Box>
    )
}
