import React, { useEffect, useState} from 'react'
import { Avatar, Box, Button, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { basicProps, networkType } from '../../../utils/interfaces';
import ItemNetwork from './ItemNetwork';
import { isPC } from '../../../functions/basicFunctions';
import { Link, Route, Routes } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

export default function Networks(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [networks, setNetworks] = useState([]);

    useEffect(() => {
        getNetworks();
    }, [])

    const getNetworks = () => {
        axiosApi.post('ctr/networks/get-networks.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setNetworks(response.data.networks);
            }
        })
    }
    

    const addNetwork = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        let data = new FormData(e.currentTarget);
        
        data.append('loaderText', 'Ajout de la devise en cours...');

        axiosApi.post('ctr/networks/add-network.php', data)
        .then(response => {
            if(response.data.status === 'success'){
                const form_network_network = document.getElementById('form_network_network') as HTMLFormElement
                if(form_network_network) form_network_network.reset();
                getNetworks();
            }
        })

    }

    return (

        <div>

            <Routes>
                <Route path='/' element={
                    <div style={{
                                paddingTop: '30px'
                            }}>
                        
                        <Typography component="h1" variant="h5" className='text-center'>
                            RÉSEAUX BLOCKCHAINS
                        </Typography>
            
                        <Box
                            component="form"
                            id="form_network_network"
                            sx={{
                                mt: 4,
                                mb: 6,
                                display: 'grid',
                                gridTemplateColumns: isPC ? 'repeat(4, 1fr)' : '',
                                gap: 5
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={addNetwork}
                            >
            
                            <TextField
                                required
                                name='name'
                                id="outlined-required"
                                label="Nom réseau"
                                />
            
                            <TextField
                                required
                                name="symbol"
                                id="outlined-disabled"
                                label="Symbole réseau"
                                />
            
                            <TextField
                                required
                                name='address'
                                id="outlined-required"
                                label="Adresse"
                                />
            
                            <TextField
                                type='text'
                                name='networkCompatibility'
                                label="Compatibilité réseau"
                                placeholder='EX: eth'
                                autoFocus
                                />
            
                            <TextField
                                type='file'
                                name='qrcode'
                                id="outlined-required"
                                label="QRcode"
                                autoFocus
                                />

                            <TextField
                                type='password'
                                label="sw"
                                name='sw'
                            />
            
                            <Button type='submit' variant='contained' sx={{p:1.9}}>
                                Ajouter
                            </Button>
            
                        </Box>
            
                        <List sx={{ width: '100%', mt:3 }}>
            
                            {
                                networks.map((network: networkType) => {
                                    return (
                                        <ListItem   secondaryAction={

                                                        <>
                                                            <Link to={'/ctr/networks/' + network.symbol + '/' }>
                                                                <Button>
                                                                    <EditIcon />
                                                                </Button>
                                                            </Link>
                                                        </>
                                                    }
                                                    sx={{bgcolor: 'ButtonFace', mb:2}} >
                                
                                            <ListItemText primary={network.symbol} secondary={network.name} />

                                            <ListItemText primary={network.address} secondary="Adresse" />

                                        </ListItem>
                                    )
                                })
                            } 
            
                        </List>
            
                    </div>
                } />


                <Route path='/:networkID' element={ <ItemNetwork basicDatas={basicDatas} />} />
            </Routes>

        </div>
    )

}
