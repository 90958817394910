import React from 'react'
import { basicProps } from '../../utils/interfaces'
import { Box, Typography } from '@mui/material'
import { isPC, publicPath } from '../../functions/basicFunctions';

export default function Privacy(props: basicProps) {

    const basicDatas = props.basicDatas;

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <div className="text-center"
                    style={{
                        marginTop: '0px',
                        marginBottom: '50px'
                    }}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>

                    <img 
                        src={publicPath + "images/cgu_banner.jpg"} 
                        alt="CRYPTO-FIAT"
                        style={{
                            width: '100%',
                            borderBottomLeftRadius: isPC ? '0px' : '5px',
                            borderBottomRightRadius: isPC ? '0px' : '5px'
                        }} />

                </div>

            </div>
            
            <Typography
                variant='h4'
                fontWeight='bold'
                sx={{mb:5}}
            >
                Politique de Confidentialité de CryptoFiat
            </Typography>

            <h5>Introduction</h5>
            <p>CryptoFiat, opérant depuis le site <a href="https://cryptofiat-ex.com" target='_blank'>https://cryptofiat-ex.com</a>, est engagé à protéger la vie privée et les données personnelles de ses utilisateurs. Basés au Cameroun, nous adhérons aux réglementations locales en matière de protection des données.</p>
            
            <h5>Collecte d'Informations</h5>
            <p>Nous recueillons des informations qui peuvent inclure des données personnelles comme votre nom, adresse email, numéro de téléphone, ainsi que des informations relatives à vos transactions et activités sur notre plateforme.</p>
            
            <h5>Utilisation des Informations</h5>
            <p>Vos données sont utilisées pour fournir nos services de cryptomonnaie, améliorer l'expérience utilisateur, communiquer avec vous concernant votre compte ou nos services, et assurer la sécurité et la conformité réglementaire.</p>
            
            <h5>Sécurité</h5>
            <p>La sécurité de vos informations est primordiale. Nous appliquons des mesures techniques et organisationnelles pour protéger vos données contre l'accès non autorisé, la modification, la divulgation ou la destruction.</p>
            
            <h5>Partage des Informations</h5>
            <p>Vos informations personnelles ne sont pas vendues, échangées, ni louées à des tiers. Elles peuvent être partagées avec des autorités réglementaires ou judiciaires au Cameroun si requis par la loi.</p>
            
            <h5>Droits des Utilisateurs</h5>
            <p>Conformément à la législation camerounaise, vous avez le droit d'accéder et de rectifier vos données personnelles. Pour exercer ces droits, contactez-nous à l'adresse fournie.</p>
            
            <h5>Modifications de la Politique</h5>
            <p>Cette politique peut être mise à jour. Nous vous informerons de tout changement significatif par une annonce sur notre plateforme ou par email.</p>
            
            <h5>Contact</h5>
            <p>Pour toute question relative à cette politique, contactez-nous à <b><a href="mailto:info@cryptofiat-ex.com" className='link'>info@cryptofiat-ex.com</a></b>.</p>
            
            <p>En utilisant CryptoFiat, vous reconnaissez avoir lu et accepté cette politique de confidentialité.</p>
        </Box>
    )
}
