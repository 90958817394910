import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, TextareaAutosize, Typography } from '@mui/material'
import { useEffect, useState, ReactNode } from 'react'
import { kycDemandType } from './WaitingDemands'
import axiosApi from '../../../functions/axiosApi';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { basicProps, genderType, kycLevelType } from '../../../utils/interfaces';

interface myProps extends basicProps {
    demand: kycDemandType
}

export interface attachedMessagesType {
    vip_level: kycLevelType,
    content: {
        title: string,
        message: string,
        icon: ReactNode
    }[]
}

export const attachedMessages: attachedMessagesType[] = [
    {
        vip_level: 'vip0',
        content: [
            {
                title: "Nom incorrect",
                message: `
                    <b style="color:red">Le nom ne correspond pas.</b> 
                    <br/><br/>
                    Veuillez modifier votre nom sur CRYPTO-FIAT pour qu'il corresponde exactement à celui figurant sur votre pièce d'identité.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Selfie invalide",
                message: `
                    <b style="color:red">Le selfie que vous avez soumis n'est pas valide.</b> 
                    <br/><br/>

                    Veuillez prendre un nouveau selfie qui respecte les consignes ci-dessous :
                    <br/><br/>

                    <ul>
                        <li style='margin-bottom: 10px'>
                            Écrivez <b>"CRYPTO-FIAT"</b> et la <b>"DATE"</b> du jour sur une feuille.
                        </li>

                        <li style='margin-bottom: 10px'>
                            Tenez la feuille et la carte en main et prenez un selfie.
                        </li>

                        <li style='margin-bottom: 10px'>
                            Assurez-vous d'être dans un endroit bien éclairé.
                        </li>

                        <li style='margin-bottom: 10px'>
                            Votre visage doit être entièrement et clairement visible.
                        </li>

                        <li style='margin-bottom: 10px'>
                            Assurez-vous que le texte sur la feuille est clairement visible et que la carte aussi.
                        </li>

                        <li style='margin-bottom: 10px'>
                            Évitez les reflets ou les ombres qui pourraient rendre le texte illisible.
                        </li>

                        <li>
                            Assurez-vous que la photo est bien cadrée et que rien n'obstrue le texte sur la feuille ou la carte.
                        </li>
                    </ul>
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Selfie zone sombre",
                message: `
                    <b style="color:red">Le selfie est trop sombre.</b> 
                    <br/><br/>
                    Veuillez prendre votre selfie dans un endroit bien éclairé. Merci de vous assurer que votre visage et la pièce d'identité sont clairement visibles.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Date d'expiration incorrecte",
                message: `
                    <b style="color:red">La date d'expiration de la pièce d'identité que vous avez fournie est incorrecte.</b> 
                    <br/><br/>
                    Veuillez entrer les informations telles qu'elles apparaissent sur votre pièce d'identité.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Date de naissance incorrecte",
                message: `
                    <b style="color:red">La date de naissance est incorrecte.</b> 
                    <br/><br/>
                    Veuillez entrer les informations telles qu'elles apparaissent sur votre pièce d'identité.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Numéro document incorrect",
                message: `
                    <b style="color:red">Le numéro de pièce d'identité est incorrect.</b> 
                    <br/><br/> 
                    Veuillez entrer les informations telles qu'elles apparaissent sur votre pièce d'identité.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Numéro carte Congo Brazza incorrect",
                message: `
                    <b style="color:red">Le numéro de pièce d'identité est incorrect.</b> 
                    <br/><br/> 
                    Veuillez entrer uniquement la partie de gauche contenant des chiffres.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Numéro carte Congo Brazza correction",
                message: `
                    <b style="color:red">Le numéro de pièce d'identité est incorrect.</b> 
                    <br/><br/> 
                    Le numéro de votre carte est : <b>[numéro carte]</b>.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Document non lisible",
                message: `
                    <b style="color:red">Nous n'avons pas pu lire les informations sur votre document.</b> 
                    <br/><br/> 
                    Veuillez vous assurer de prendre une photo de haute qualité en vous assurant que les informations de la pièce d'identité soient bien lisibles.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Photos non cadrées",
                message: `
                    <b style="color:red">Les photos ne sont pas cadrées.</b> 
                    <br/><br/> 
                    Veuillez filmer votre pièce d'identité dans le bon sens de lecture et assurez-vous que toutes les informations sont bien visibles et lisibles. 
                    <br/>
                    Assurez-vous également que la pièce d'identité est entièrement visible.
                    <br/>
                    Filmez dans un endroit bien éclairé en évitant les reflets de lumière.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Document scanné",
                message: `
                    <b style="color:red">Nous n'acceptons pas les documents scannés ni les captures d'écran.</b> 
                    <br/><br/> 
                    Veuillez filmer votre pièce d'identité et téléchargez directement les photos sans les retoucher.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Date expiration proche",
                message: `
                    <b style="color:red">Votre pièce d'identité n'est plus valide pour longtemps.</b> 
                    <br/><br/> 
                    Veuillez utiliser une pièce d'identité qui est valide pour au moins trois (3) mois
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Document non valide",
                message: `
                    <b style="color:red">Le document que vous avez fourni n'est pas accepté pour la vérification.</b> 
                    <br/><br/> 
                    Veuillez utiliser votre Carte Nationale d'Identité, votre Passeport ou votre Permis de conduire.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Document expiré",
                message: `
                    <b style="color:red">Votre document d'identité a expiré.</b> 
                    <br/><br/> 
                    Veuillez charger un nouveau document en cours de validité.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Compte double",
                message: `
                    <b style="color:red">Il semble que vous possédiez déjà un compte CRYPTO-FIAT.</b> 
                    <br/><br/> 
                    Veuillez vous connecter à ce compte pour effectuer vos opérations.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Pays non pris en charge",
                message: `
                    <b style="color:red">Le pays d'émission de votre document d'identité n'est pas encore pris en charge.</b> 
                    <br/><br/> 
                    Nous travaillons à étendre nos services à davantage de pays. Veuillez vous abonner à notre canal Telegram pour rester informé : <a href="https://t.me/cryptofiatex" target="_blank">https://t.me/cryptofiatex</a>
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "Document inacceptable",
                message: `
                    <b style="color:red">Nous n'avons pas pu vérifier votre identité avec les informations fournies.</b> 
                    <br/><br/> 
                    Veuillez soumettre votre demande à nouveau en vous assurant que les informations de votre compte CRYPTO-FIAT correspondent exactement aux informations de votre document d'identité.
                `,
                icon: <HighlightOffIcon color='error' />
            },

            {
                title: "L'utilisateur doit contacter le support",
                message: `
                    <b style="color:red">Votre demande de vérification KYC n'a pas pu être validée.</b> 
                    <br/><br/> 
                    Pour plus d'assistance, veuillez contacter notre support afin de résoudre ce problème rapidement.
                `,
                icon: <HighlightOffIcon color='error' />
            }

        ]
    },

    {
        vip_level: 'vip1',
        content: [
            {
                title: "Demande approuvée",
                message: `
                    <b style="color:green">Félicitations ! Votre compte CRYPTO-FIAT a été vérifié avec succès.</b> 
                    <br/><br/>
                    Vous pouvez maintenant profiter pleinement de nos services. 
                    <br/>
                    N'hésitez pas à nous contacter si vous avez des questions ou besoin d'assistance.
                `,
                icon: <CheckCircleIcon color='success' />
            }
        ]
    }
]

export default function KycCompletionForm(props: myProps) {

    const basicDatas = props.basicDatas;

    const demand = props.demand;
    const userInfos = demand.userInfos;
    const [countries, setCountries] = useState<any>([]);
    const [gender, setGender] = useState(demand.gender)
    const [message, setMessage] = useState('')

    const [open, setOpen] = useState(false);
    const [vipLevel, setVipLevel] = useState(userInfos.vip_level)
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        
        axiosApi.get('https://restcountries.com/v2/region/Africa/?fields=translations,flag')
        .then(res => {
            setCountries(res.data);
        })

    }, [userInfos.user_id])

    const handleVipLevelChange = (newLevel: kycLevelType) => {

        basicDatas.dialogManager({
            dialogTitle: newLevel,
            dialogText: newLevel === 'vip0' ? "Vous avez choisi " + newLevel + " : Compte non vérifié." : "Vous avez choisi " + newLevel + " : Compte vérifié.",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui je comprends"
          }, (response: string) => {
            
            if(response === 'ok') {
                setVipLevel(newLevel)
            }
      
        })
    }

    const updateKycInfos = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append("loaderText", "Traitement en cours...");
        data.append('user_id', userInfos.user_id);

        basicDatas.dialogManager({
            dialogTitle: "Mettre à jour",
            dialogText: "Vous allez changer les informations KYC de ce compte.",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui valider"
          }, (response: string) => {
            
            if(response === 'ok') {
                axiosApi.post("ctr/kyc/complete-kyc-info-by-admin.php", data)
            }
      
        })

    }

    return (
        <Box
            component='form'
            onSubmit={updateKycInfos}
        >

            <FormControl 
                    fullWidth 
                    sx={{
                        mb: 2
                    }}>

                <InputLabel id="select_country_label">Pays de l'utilisateur</InputLabel>
                
                <Select
                    labelId="select_country_label"
                    name='country'
                    defaultValue={demand.country}
                    label="Pays de l'utilisateur"
                    fullWidth
                    margin="dense"
                    required
                    >

                    {
                        countries.map((country, index) => {

                            return (
                                <MenuItem key={index} value={ country.translations.fr}>
                                    <Avatar 
                                        src={country.flag} 
                                        sx={{
                                            width:'30px', 
                                            height:'20px', 
                                            display:'inline-block', 
                                            mr: 2, 
                                            verticalAlign:'middle',
                                            borderRadius: 0,
                                            boxShadow: 1
                                        }} />
                                    <span>{ country.translations .fr}</span>
                                </MenuItem>
                            )

                        })
                    }
    
                </Select>        

            </FormControl>

            <FormControl 
                    fullWidth 
                    sx={{
                        mb: 2
                    }}>

                <InputLabel id="select_gender_label">Genre</InputLabel>
                
                <Select
                    labelId="select_gender_label"
                    name='gender'
                    value={gender}
                    onChange={(e) => setGender(e.target.value as genderType)}
                    label="Statut"
                    fullWidth
                    margin="dense"
                    required
                    >

                    {
                        ['male', 'female'].map((gender, index) => {

                            return (
                                <MenuItem key={ index } value={ gender }>
                                    { gender }
                                </MenuItem>
                            )

                        })
                    }
    
                </Select>        

            </FormControl>

            <FormControl 
                    fullWidth 
                    sx={{
                        mb: 2
                    }}>

                <InputLabel id="select_vip_level">Statut</InputLabel>
                
                <Select
                    labelId="select_vip_level"
                    name='vip_level'
                    value={vipLevel}
                    onChange={(e) => handleVipLevelChange(e.target.value as kycLevelType)}
                    label="Statut"
                    fullWidth
                    margin="dense"
                    required
                    >

                    {
                        ['vip0', 'vip1'].map((type, index) => {

                            return (
                                <MenuItem key={ index } value={ type }>
                                    { type }
                                </MenuItem>
                            )

                        })
                    }
    
                </Select>        

            </FormControl>

            <br /> <br />

            <Button
                startIcon={<StickyNote2Icon/>}
                onClick={handleClickOpen}
                sx={{
                    float: 'right'
                }}>
                Auto message
            </Button>

            <label htmlFor={"textarea-message"} style={{display:'block', marginBottom:'7px'}}>Ajouter un message</label>
            
            <TextareaAutosize 
                name='message'
                minRows={3} 
                style={{width:'100%', borderRadius: '3px'}}
                id={"textarea-message"}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                placeholder="Message associé" 
            />

            <TextField
                type='password'
                label="sw"
                name='sw'
                fullWidth
                sx={{mb: 3, mt: 2}}
            />

            <Button
                type='submit'
                fullWidth
                variant='contained'
            >
                Mettre à jour
            </Button>







            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Messages automatiques</DialogTitle>

                <DialogContent sx={{minWidth: 300}}>
                    
                    {
                        attachedMessages.map((itemMessage, i) => {

                            if(vipLevel === itemMessage.vip_level) 

                            return (
                                <Box
                                    key={i}
                                    >
                                    <Typography sx={{fontWeight: 'bold', fontSize: 'large', mb: 2}}>
                                        { vipLevel }
                                    </Typography>


                                    {
                                        itemMessage.content.map((itemContent, j) => {

                                            return (
                                                <Box
                                                    key={j}
                                                    onClick={() => {setMessage(itemContent.message); handleClose()}}
                                                    sx={{
                                                        pb: 3,
                                                        mb: 3,
                                                        boxShadow: 2,
                                                        p: 2,
                                                        borderRadius: 1,
                                                        cursor: 'pointer'
                                                    }}>
                                                    
                                                    <Typography>
                                                        { itemContent.icon } { itemContent.title}
                                                    </Typography>

                                                    <Typography color="GrayText" fontSize='small'>
                                                        { itemContent.message }
                                                    </Typography>

                                                </Box>
                                            )

                                        })
                                    }
                                    
                                </Box>
                            )

                            return <></>
                        })
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>

        </Box>
    )
}
