import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { User, basicProps, cftPartnerAccountStatus, currencyType, internalTransferType } from '../../../utils/interfaces';
import { Box, Avatar, Typography, TextField, Button, Stepper, Step, StepLabel, StepContent, Alert, Stack, Skeleton, Chip } from '@mui/material';
import { cf_pay_info, readClipboard, readabilityNumber } from '../../../functions/basicFunctions';
import { toast } from 'react-toastify';
import InfoPopup from '../../../components/InfoPopup';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MetaTags } from 'react-meta-tags';
import KycInvitationPopup from '../../../components/KycInvitationPopup';

interface assetType extends currencyType {
    userBalance: number,
    availableBalance: number
}

export default function Transfer(props: basicProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const { assetSymbol } = useParams();
    const [asset, setAsset] = useState<assetType>();
    const [receiverID, setReceiverID] = useState('');
    const [receiverAccount, setReceiverAccount] = useState<User>();
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [feesAndCommissionsInfo, setFeesAndCommissionsInfo] = useState<{
        senderFeesPercentage: number,
        transactionType: string,
        senderIsCftPartner: {status: cftPartnerAccountStatus},
        receiverIsCftPartner: {status: cftPartnerAccountStatus}
    }>();
    const [latestTranfers, setLatestTranfers] = useState<internalTransferType[]>([]);

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    

    useEffect(() => {

        axiosApi.post("wallet/get-asset-info.php", QueryString.stringify({ symbol: assetSymbol}))
        .then(response => {

            if(response.data.status === 'success'){
                setAsset(response.data.asset);
            }

        })

        axiosApi.post("wallet/transfer/get-latest-transactions.php")
        .then(response => {

            if(response.data.status === 'success'){
                setLatestTranfers(response.data.transactions)
            }

        })

    }, [])

    const steps = [
        {
          label: 'Identifiant du destinataire',
          description: `Veuillez entrer l'identifiant du compte destinataire.`,
        },

        {
          label: 'Montant à transférer',
          description:
            'Veuillez entrer le montant ' + asset?.symbol + ' que vous souhaitez transférer.',
        },

        {
            label: 'Ajouter un message',
            description:
              'Souhaitez-vous associer un message à cette transaction ?',
          },

        {
            label: 'Vérifier le PIN',
            description:
              "Vous ne possédez pas de PIN ?",
        },

        {
          label: 'Vérifier les informations',
          description: `Veuillez vérifier que toutes les informations sont correctes avant de valider.`,
        },
        {
            label: 'Résultat',
            description:
              "Statut de la transaction",
        },
    ];

    const readReceiverIDClipboard = () => {
        readClipboard((clipboardContent) => {
            if(clipboardContent && typeof clipboardContent === 'string') setReceiverID(clipboardContent)
        })
    }

    const verifyReceiverAccount = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if(receiverID === '') {
            toast.error("Veuillez entrer l'identifiant du destinataire.");
            return;
        }

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Vérification en cours...");

        axiosApi.post("wallet/transfer/verify-receiver-account.php", data)
        .then(response => {
            if(response.data.status === 'success'){

                const receiverInfo: User = response.data.receiverAccount;
                setFeesAndCommissionsInfo(response.data.feesAndCommissionsInfo);
                const _feesAndCommissionsInfo = response.data.feesAndCommissionsInfo;

                basicDatas.dialogManager({
                    dialogTitle: 'Bénéficiaire',
                    dialogText: (
                        <Box sx={{
                            textAlign: 'center'
                        }}>
                            <Avatar src={receiverInfo.avatarUrl}
                                    sx={{
                                        margin: 'auto',
                                        width: '60px',
                                        height: '60px',
                                        boxShadow: 1,
                                        mb: 2
                                    }} />

                            <Typography sx={{mb: 3}}>
                                
                                { receiverInfo.lastName + ' ' + receiverInfo.firstName }

                                <br /> <br />

                                <b style={{fontSize: 'small'}}>{ "ID : " + receiverInfo.user_id}</b>

                            </Typography>
                            
                            {
                                (_feesAndCommissionsInfo && _feesAndCommissionsInfo.receiverIsCftPartner && (_feesAndCommissionsInfo.receiverIsCftPartner.status === 'certified')) &&
                            
                                <Alert icon={<CheckCircleIcon fontSize="inherit" />} severity="info" sx={{mb: 2}}>
                                    <Typography variant='caption'>
                                        Cet utilisateur est reconnu par { basicDatas.appName } en tant que marchand.
                                    </Typography>
                                </Alert>
                            }

                            <Typography variant='caption'>
                                Est-ce bien le compte auquel vous souhaitez transférer ?
                            </Typography>

                        </Box>
                    ),
                    dialogAgreeBtnColor: 'info',
                    dalogCloseBtnText: 'Non',
                    dialogAgreeBtnText: 'Confirmer'
                  }, (response) => {
                    
                    if(response === 'ok') {
                        setReceiverAccount(receiverInfo);
                        handleNext();
                    }
              
                })
                
            }
        })
    }


    const verifyUserBalance = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if(amount === '') {
            toast.error("Veuillez entrer le montant");
            return;
        }

        if(parseFloat(amount) < 500) {
            toast.error("Le montant minimum est de 500 CFT");
            return;
        }

        if(asset) {

            if(asset.userBalance < (parseFloat(amount) + cf_pay_info.calcFees(amount, feesAndCommissionsInfo?.senderFeesPercentage))) {
                toast.error("Votre solde est insuffisant !");
            }

            else handleNext();
        }
    }

    const verifyPinCode = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if(pinCode === '') {
            toast.error("Veuillez entrer votre code PIN");
            return;
        }

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Vérification du PIN");

        axiosApi.post("wallet/transfer/verify-pin-code.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                handleNext();
            }
        })
    }

    const initTransaction = () => {

        basicDatas.dialogManager({
            dialogTitle: 'Valider la transaction',
            dialogText: "Veuillez cliquer sur CONFIRMER pour valider cette transaction. Une fois validée, elle ne pourra être annulée.",
            dialogAgreeBtnColor: 'info',
            dalogCloseBtnText: 'Annuler',
            dialogAgreeBtnText: 'Confirmer'
          }, (response) => {
            
            if(response === 'ok') {

                axiosApi.post("wallet/transfer/transfer.php", QueryString.stringify({
                    asset: asset?.symbol,
                    receiverID,
                    amount,
                    message,
                    pinCode,
                    loaderText: "Transfert en cours..."
                }))
                .then(response => {
        
                    if(response.data.status === 'success') {
                        handleNext();
                        setPinCode('');
                        setReceiverID('')
                    }
                })
            }
      
        })

    }


    if(!asset) return (
        <Box
            sx={{
                pt: 4,
                maxWidth: 400,
                margin: 'auto'
            }}
        >

            <MetaTags id="transfer">
                <title>{'CRYPTO-FIAT | Transfert CFT'}</title>
                <meta name="description" content="CRYPTO-FIAT | Transfert CFT" />
                <meta property="og:title" content="CRYPTO-FIAT | Transfert CFT" />
            </MetaTags>

            <Typography
                variant='h5'
                fontWeight={800}
                className='Montserrat'
                sx={{
                    textAlign: 'center',
                    mb: 5
                }}>
                Transfert
            </Typography>

            <Stack spacing={5}>
                <Skeleton variant='rectangular' width='100%' height={600} />
            </Stack>

        </Box>
    )
    
    return (
        <Box sx={{
            maxWidth: 400,
            margin: 'auto',
            pt: 3
        }}>

            <MetaTags id="transfer">
                <title>{'CRYPTO-FIAT | Transfert CFT'}</title>
                <meta name="description" content="CRYPTO-FIAT | Transfert CFT" />
                <meta property="og:title" content="CRYPTO-FIAT | Transfert CFT" />
            </MetaTags>

            <KycInvitationPopup basicDatas={basicDatas} />

            <Box>
                <Avatar 
                    src={ asset.logo } 
                    sx={{
                        margin: 'auto',
                        width: '80px',
                        height: '80px',
                        boxShadow: 1,
                        mb: 2
                    }} 
                />

            </Box>

            <Box sx={{ 
                margin: 'auto',
                boxShadow: 1,
                p: 2,
                mt: 5,
                borderRadius: 2
            }}>

                <Typography 
                    variant='h5' 
                    fontWeight={800}
                    className='Montserrat'
                    sx={{mb: 3, 
                        textAlign: 'center', 
                    }}
                >
                    Transférer { asset.symbol }
                </Typography>

                <Stepper activeStep={activeStep} orientation="vertical">
                    
                    { steps.map((step, index) => (

                        <Step key={step.label}>

                            <StepLabel>
                                
                                <span style={{fontWeight: 'bold'}}>
                                    {step.label}
                                </span>
                            
                            </StepLabel>

                            <StepContent>

                                <Typography 
                                        variant='caption'
                                        sx={{
                                            fontSize: 'small',
                                            opacity: 0.7
                                        }}>
                                    {step.description}
                                </Typography>

                                {
                                    index === 0 &&

                                    <Box 
                                        component='form'
                                        onSubmit={verifyReceiverAccount}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <Typography sx={{textAlign: 'right'}}>
                                            <Button color='info'
                                                    onClick={readReceiverIDClipboard}
                                                    >
                                                Coller
                                            </Button>
                                        </Typography>

                                        <TextField
                                            type='text'
                                            name='receiverID'
                                            value={receiverID}
                                            onChange={(e) => setReceiverID(e.target.value)}
                                            fullWidth
                                            label="ID destinataire"
                                            placeholder={"EX : " + authUser.user_id}
                                            required
                                            sx={{
                                                mb: receiverID.length !== 0 ? 2 : 0
                                            }}
                                            />

                                        {
                                            (receiverID.length === 0) &&

                                            <Box
                                                sx={{
                                                    mb: 2,
                                                    mt: 1
                                                }}>

                                                <Stack
                                                    spacing={2}
                                                    direction='row'>

                                                    {
                                                        latestTranfers.map((itemTransfer, i) => {

                                                            const iamSender = itemTransfer.sender.user_id === authUser.user_id;

                                                            return (
                                                                <Chip
                                                                    label={
                                                                        <span className='text-muted'>
                                                                            { 
                                                                                iamSender 
                                                                                ? itemTransfer.receiver.firstName ? itemTransfer.receiver.firstName : itemTransfer.receiver.lastName 
                                                                                : itemTransfer.sender.firstName ? itemTransfer.sender.firstName : itemTransfer.sender.lastName
                                                                            }
                                                                        </span>
                                                                    }
                                                                    icon={
                                                                        <Avatar 
                                                                            src={iamSender ? itemTransfer.receiver.avatarUrl : itemTransfer.sender.avatarUrl} 
                                                                            sx={{
                                                                                width: 20,
                                                                                height: 20
                                                                            }}
                                                                        />
                                                                    }
                                                                    variant="outlined"
                                                                    key={i}
                                                                    size='small'
                                                                    sx={{ cursor:'pointer' }}
                                                                    onClick={() => setReceiverID(iamSender ? itemTransfer.receiver.user_id : itemTransfer.sender.user_id)}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </Box>
                                        }

                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                        <Button
                                            disabled
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    index === 1 &&

                                    <Box 
                                        component='form'
                                        onSubmit={verifyUserBalance}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <Typography textAlign='right'>
                                            <Typography variant='caption'>
                                                Disponible <Typography variant='caption' color='primary' fontWeight='bold'>{ readabilityNumber(parseFloat((asset.availableBalance).toFixed(5))) + ' ' + asset.symbol }</Typography>
                                            </Typography>
                                        </Typography>

                                        <TextField
                                            type='number'
                                            name='amount'
                                            fullWidth
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            label="Montant à transférer"
                                            placeholder="Montant"
                                            required
                                            sx={{
                                                mb: 1
                                            }} />

                                        {
                                            (feesAndCommissionsInfo && !feesAndCommissionsInfo.senderIsCftPartner) && 

                                            <Typography
                                                    sx={{
                                                        textAlign: 'right'
                                                    }}>

                                                <InfoPopup basicDatas={basicDatas}
                                                            title={"Frais de transfert " + cf_pay_info.name}
                                                            text={
                                                                <div>
                                                                    { cf_pay_info.feeReason }
                                                                </div>
                                                            }>
                                                    <Typography   
                                                            variant='caption'
                                                            sx={{
                                                                marginLeft: '20px',
                                                                fontStyle: 'oblique',
                                                                cursor: 'pointer',
                                                                mb: 2
                                                            }}> 

                                                                <span>
                                                                    Frais: { cf_pay_info.calcFees(amount, feesAndCommissionsInfo?.senderFeesPercentage) + ' ' + asset.symbol }
                                                                </span>
                                                                <InfoIcon sx={{mr: 0.5, fontSize:'16px', ml: 2}} color='info' />
                                                                Pourquoi ces frais ?
                                                    </Typography>
                                                </InfoPopup>

                                            </Typography>
                                        }

                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    index === 2 &&

                                    <Box 
                                        component='form'
                                        onSubmit={verifyUserBalance}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <TextField
                                            type='text'
                                            name='message'
                                            fullWidth
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            label="Message (facultatif)"
                                            placeholder="Entrez le message ici"
                                            sx={{
                                                mb: 2
                                            }} />

                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    index === 3 &&

                                    <Box 
                                        component='form'
                                        onSubmit={verifyPinCode}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <Link to='/auth/reset-pin-code/'
                                            className='link' 
                                            style={{
                                                marginBottom: '10px',
                                                marginTop: '-30px',
                                                textAlign: 'right',
                                                display: 'block'
                                            }}>
                                            <Typography variant='caption' color='primary' sx={{fontWeight: 'bold'}}>
                                                Créer le PIN
                                            </Typography>
                                        </Link>

                                        <TextField
                                            type='password'
                                            name='pinCode'
                                            fullWidth
                                            value={pinCode}
                                            onChange={(e) => setPinCode(e.target.value)}
                                            label="Entrez votre code PIN"
                                            placeholder="code PIN"
                                            required
                                            sx={{
                                                mb: 2
                                            }} />

                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Vérifier PIN
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    index === 4 &&

                                    <Box sx={{mt: 2}}>

                                        <Typography sx={{
                                            p: 1,
                                            boxShadow: 1,
                                            borderRadius: 1,
                                            mb: 2
                                        }}>
                                            Dest ID : <b>{ receiverAccount?.user_id }</b>
                                        </Typography>

                                        <Typography sx={{
                                            p: 1,
                                            boxShadow: 1,
                                            borderRadius: 1,
                                            mb: 2
                                        }}>
                                            Dest : <b>{ receiverAccount?.lastName + " " + receiverAccount?.firstName }</b>
                                        </Typography>

                                        <Typography sx={{
                                            p: 1,
                                            boxShadow: 1,
                                            borderRadius: 1,
                                            mb: 3
                                        }}>
                                            Amount : <b>{ readabilityNumber(amount) + " " + asset.symbol }</b>
                                        </Typography>

                                        {
                                            (feesAndCommissionsInfo && !feesAndCommissionsInfo.senderIsCftPartner) &&
                                            <Typography sx={{
                                                p: 1,
                                                boxShadow: 1,
                                                borderRadius: 1,
                                                mb: 3
                                            }}>
                                                Fees : <b>{ readabilityNumber(cf_pay_info.calcFees(amount, feesAndCommissionsInfo?.senderFeesPercentage)) + " " + asset.symbol }</b>
                                            </Typography>
                                        }

                                        <Typography sx={{
                                            p: 1,
                                            boxShadow: 1,
                                            borderRadius: 1,
                                            mb: 3
                                        }}>
                                            Total : <b>{ readabilityNumber((parseFloat(amount) + cf_pay_info.calcFees(amount, feesAndCommissionsInfo?.senderFeesPercentage))) + " " + asset.symbol }</b>
                                        </Typography>


                                        <Button
                                            variant="contained"
                                            onClick={initTransaction}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Valider
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>
                                    </Box>
                                }

                            </StepContent>

                        </Step>

                    ))}

                </Stepper>

                {(activeStep === (steps.length - 1 )) && (

                    <Typography sx={{mt: 3}}>

                        <Alert severity='success' sx={{mb: 2}}>
                            Vous avez transféré <b>{ amount + ' ' + asset.symbol }</b> à  <b>{ receiverAccount?.lastName + " " + receiverAccount?.firstName }</b> avec succès. 
                        </Alert>

                        <Button 
                                onClick={() => {setActiveStep(0); setAmount('')}} 
                                sx={{ mt: 1, mr: 1, boxShadow: 1 }}>
                            Recommencer
                        </Button>

                    </Typography>
                )}

            </Box>
             
        </Box>
    )
}
