import { cf_pay_info } from '../../../../functions/basicFunctions';
import { useEffect, useState } from 'react'
import QueryString from 'qs';
import { Box, Avatar, Typography, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { basicProps, internalTransferType } from '../../../../utils/interfaces';
import axiosApi from '../../../../functions/axiosApi';
import PreviewTransfer from '../../../../components/internal-transfers/PreviewTransfer';
import ViewTransaction from './ViewTransaction';

export default function CFPAYtransactions(props: basicProps) {

    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        getTransactions();
    }, [queryValue])


    const getTransactions = () => {

        setIsLoading(true);

        axiosApi.post("ctr/finances/get-cfpay-transcations.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newtransactionsArray = transactions.concat(response.data.transactions);
                setTransactions(newtransactionsArray);
                setOffset(offset + 5);

                if(response.data.transactions.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <Box sx={{
            maxWidth: 600,
            margin: 'auto',
            pt: 3
        }}>

            <Typography
                    variant='h5'
                    sx={{
                        textAlign: 'center',
                        mb: 3
                    }}>
                Transactions { cf_pay_info.symbol }
            </Typography>

            <Box sx={{mt: 5}}>
                
                <Typography
                        sx={{
                            textAlign: 'center',
                            mb: 7
                        }}>
                    <TextField 
                        id="outlined-basic"
                        type='search' 
                        label="Rechercher une transaction : ID" 
                        variant="outlined"
                        placeholder="Entrez ID transaction"
                        value={queryValue}
                        onChange={
                            (e) => {
                                setQueryValue(e.target.value);
                                setTransactions([]);
                                setOffset(0);
                                setEnded(false)
                            }
                        }
                        />
                </Typography>

                {
                    transactions.length === 0 &&
                    <Typography sx={{textAlign: 'center'}}>
                        <Typography variant='caption'>
                            Aucune transaction
                        </Typography> 
                    </Typography>
                }

                {
                    transactions.map((transaction: internalTransferType) => {
                        return (
                            <ViewTransaction 
                                        transaction={transaction} 
                                        key={ transaction.transactionID } />
                        )
                    })
                }

                <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getTransactions()}>
                            Afficher plus
                        </Button>
                }

                </div>

            </Box>
             
        </Box>
    )
}
