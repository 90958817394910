import { useState, useEffect, ReactNode } from 'react'
import { User, basicProps, cftPartnerType } from '../../../../utils/interfaces'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextareaAutosize, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { partnerStatusType } from './Partner';

export interface attachedMessagesType {
    status: partnerStatusType,
    content: {
        title: string,
        message: string,
        icon: ReactNode
    }[]
}

export const attachedMessages: attachedMessagesType[] = [
    {
        status: 'pause',
        content: [
            {
                title: "Peu disponible",
                message: "Bonjour cher partenaire, nous avons pris la décision de mettre votre compte en pause car ayant remarqué que vous n'êtes pas généralement disponible pour satisfaire les clients. Si vous pensez que cela est une erreur, veuillez nous contacter.",
                icon: <HighlightOffIcon color='error' />
            }

        ]
    },

    {
        status: 'blocked',
        content: [
            {
                title: "Longtemps indisponible",
                message: "Bonjour cher partenaire, nous avons pris la décision de mettre votre compte en pause car ayant remarqué que vous n'êtes pas généralement disponible pour satisfaire les clients. Si vous pensez que cela est une erreur, veuillez nous contacter.",
                icon: <HighlightOffIcon color='error' />
            }
        ]
    },

    {
        status: 'certified',
        content: [
            {
                title: "Demande approuvée",
                message: "Nous vous remercions de l'intérêt que vous portez à CRYPTO-FIAT. Votre compte est maintenant certifié en tant que partenaire pour CRYPTO-FIAT PAY. Vous pouvez commencer à aider les utilisateurs à recharger et effectuer des retraits dans leurs comptes. Pour tout besoin d'assistance, veuillez nous contacter.",
                icon: <CheckCircleIcon color='success' />
            }
        ]
    }
]

interface myProps {
    partner: cftPartnerType,
    sw: string
}

export default function SetBasicInfos(props: myProps) {

    const partner = props.partner;

    const [countries, setCountries] = useState<any>([]);
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(partner?.status)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        
        axiosApi.get('https://restcountries.com/v2/region/Africa/?fields=translations,flag')
        .then(res => {
            setCountries(res.data);
        })

    }, [])

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('user_id', partner?.user_id + '');
        data.append('sw', props.sw)
        data.append('loaderText', "Traitement en cours...");

        axiosApi.post("ctr/team-manager/cft-partners/set-partner-account.php", data);
    }

    return (
        <Box
            sx={{
                p: 2,
                mb: 3,
                boxShadow: 1,
                borderRadius: 1
            }}>

            <Typography
                    sx={{
                        fontWeight: 'bold',
                        mb: 3
                    }}>
                Information de statut
            </Typography>

            <Box
                component='form'
                onSubmit={submit}
                >

                <FormControl 
                        fullWidth 
                        sx={{
                            mb: 3
                        }}>

                    <InputLabel id="select_country_label">Pays du partenaire</InputLabel>
                    
                    <Select
                        labelId="select_country_label"
                        name='newCountry'
                        defaultValue={partner.country}
                        label="Choisir le pays du patenaire"
                        fullWidth
                        margin="dense"
                        required
                        >

                        {
                            countries.map((country, index) => {

                                return (
                                    <MenuItem key={index} value={ country.translations.fr}>
                                        <Avatar 
                                            src={country.flag} 
                                            sx={{
                                                width:'30px', 
                                                height:'20px', 
                                                display:'inline-block', 
                                                mr: 2, 
                                                verticalAlign:'middle',
                                                borderRadius: 0,
                                                boxShadow: 1
                                            }} />
                                        <span>{ country.translations .fr}</span>
                                    </MenuItem>
                                )

                            })
                        }
        
                    </Select>        

                </FormControl>

                <FormControl 
                        fullWidth 
                        sx={{
                            mb: 3
                        }}>

                    <InputLabel id="select_status_label">Statut du compte</InputLabel>
                    
                    <Select
                        labelId="select_status_label"
                        name='newStatus'
                        defaultValue={partner.status}
                        value={status}
                        onChange={(e) => setStatus(e.target.value as any)}
                        label="Statut du compte"
                        fullWidth
                        margin="dense"
                        required
                        >

                        {
                            ['certified', 'blocked'].map((status, index) => {

                                return (
                                    <MenuItem key={index} value={ status}>
                                        { status }
                                    </MenuItem>
                                )

                            })
                        }
        
                    </Select>        

                </FormControl>

                <Button
                    startIcon={<StickyNote2Icon/>}
                    onClick={handleClickOpen}
                    sx={{
                        float: 'right'
                    }}>
                    Auto message
                </Button>

                <label htmlFor={"textarea-message"} style={{display:'block', marginBottom:'7px'}}>Ajouter un message</label>
                <TextareaAutosize minRows={3} 
                                name='message'
                                value={message}
                                onChange={(e) => setMessage(e.target.value) }
                                style={{width:'100%'}}
                                id={"textarea-message"}
                                placeholder="Message associé à la confirmation" />
                
                <Typography
                        sx={{
                            textAlign: 'right',
                            mt: 3
                        }}>
                    <Button
                        type='submit'
                        variant='contained'
                        >
                        Mettre à jour
                    </Button>
                </Typography>

            </Box>





            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Messages automatiques</DialogTitle>

                <DialogContent sx={{minWidth: 300}}>
                    
                    {
                        attachedMessages.map((itemMessage, i) => {

                            if(status === itemMessage.status) 

                            return (
                                <Box
                                    key={i}
                                    >
                                    <Typography sx={{fontWeight: 'bold', fontSize: 'large', mb: 2}}>
                                        { status }
                                    </Typography>


                                    {
                                        itemMessage.content.map((itemContent, j) => {

                                            return (
                                                <Box
                                                    key={j}
                                                    onClick={() => {setMessage(itemContent.message); handleClose()}}
                                                    sx={{
                                                        pb: 3,
                                                        mb: 3,
                                                        boxShadow: 2,
                                                        p: 2,
                                                        borderRadius: 1,
                                                        cursor: 'pointer'
                                                    }}>
                                                    
                                                    <Typography>
                                                        { itemContent.icon } { itemContent.title}
                                                    </Typography>

                                                    <Typography color="GrayText" fontSize='small'>
                                                        { itemContent.message }
                                                    </Typography>

                                                </Box>
                                            )

                                        })
                                    }
                                    
                                </Box>
                            )

                            return <></>
                        })
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>
            
        </Box>
    )
}
