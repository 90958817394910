import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { apiPath } from './basicFunctions';

const handleInstanceInterceptors = (instance: AxiosInstance) => {

	let displayLoader = false;

	instance.interceptors.request.use(
		(config) => {

			if(localStorage.getItem('ss_token')){

				if(typeof config.data === 'object'){

					if(config.data.get('loaderText')){

						const toastID = toast.loading(config.data.get('loaderText'));
						config.data.append('tostID', toastID)
						
						$('#loader_bg_flou').fadeIn();
						displayLoader = true;

						if(config.params) {
							config.params.toastID = toastID;
						}

						else {
							config.params = { toastID }
						}
					}

					config.data.append('jwt', localStorage.getItem('ss_token'));
					
				}

				else if(typeof config.data === 'string') {
	
					const uri = decodeURI(config.data);
					let uriArray = uri.split('&')
					const loaderTextArray = uriArray.filter((item) => item.match('loaderText='))
					
					if(loaderTextArray.length !== 0){
						let loaderText = loaderTextArray[0].split('=')[1]
						const toastID = toast.loading(loaderText);
						config.data += "&tostID=" + toastID	
						$('#loader_bg_flou').fadeIn();
						displayLoader = true;
					}

					config.data += "&jwt=" + localStorage.getItem('ss_token')
					
				}

				else if(config.data === undefined) {
					config.data = "jwt=" + localStorage.getItem('ss_token');
					config.data += "&tostID=null"
				}
				
			}

			config.headers = {
				...config.headers
			};

			return config;
		},
		(error) => {
			const myTostID = getTostID(error.config)
			if(myTostID && (myTostID != 'null')) toast.dismiss(myTostID);
			$('#loader_bg_flou').fadeOut();
			console.log(error)
			return Promise.reject(error);
		}
	);

	instance.interceptors.response.use(
		(response) => {

			const myTostID = getTostID(response.config)

			const responseData = response.data;
			const errors_container = document.getElementById('errors_container');

			if(errors_container && (typeof responseData !== 'object')){
				errors_container.innerHTML = responseData
			}

			if(responseData.ss_token) {
				localStorage.setItem('ss_token', responseData.ss_token);
			}

			else {
				//localStorage.removeItem('ss_token');
			}

			if(responseData.message) {

				if((responseData.status === 'success') && (responseData.message.length !== 0)){
					
					if(!displayLoader){
						toast.success(responseData.message);
					}

					else {
						toast.update(myTostID, {render: responseData.message, type:'success', isLoading:false, autoClose:10000})
					}
					
				}

				if((responseData.status === 'info') && (responseData.message.length !== 0)){
					
					if(!displayLoader){
						toast.info(responseData.message);
					}

					else {
						toast.update(myTostID, {render: responseData.message, type:'info', isLoading:false, autoClose:10000})
					}
					
				}

				else if((responseData.status === 'failed' || responseData.status === 'error') && (responseData.message.length !== 0)){
					if(!displayLoader){
						toast.error(responseData.message);
					}
					else {
						toast.update(myTostID, {render: responseData.message, type:'error', isLoading:false, autoClose:10000})
					}
					
				}

				else if((responseData.status === 'warning') && (responseData.message.length !== 0)){
					if(!displayLoader){
						toast.warn(responseData.message);
					}
					else {
						toast.update(myTostID, {render: responseData.message, type:'warning', isLoading:false, autoClose:10000})
					}
					
				}

				else if(responseData.message.length === 0){
					if(myTostID && (myTostID != 'null')) {
						toast.dismiss(myTostID);
					} 
				}
			}

			else {
				if(myTostID && (myTostID != 'null')) {
					toast.dismiss(myTostID);
				} 
			}

			$('#loader_bg_flou').fadeOut();
			displayLoader = false;

			return response;
			
		},
		(error) => {
			console.log(error)
			const myTostID = getTostID(error.config)
			if(myTostID && (myTostID != 'null')) toast.dismiss(myTostID);
			$('#loader_bg_flou').fadeOut();
			displayLoader = false;
			toast.warn("Requête non aboutie !");
    		return Promise.reject(error);
		}
	);
	
	return instance;

};

const getTostID = (config: any) => {

	let myTostID: any = null;

	if(typeof config.data === 'object') {
		myTostID = config.params ? config.params.toastID : '';
	}

	else if(typeof config.data === 'string') {
		const configData = config.data.split('&');
		myTostID = configData.filter(item => item.match('tostID'));
		myTostID = myTostID[0] ? myTostID[0].replace('tostID=', '') : '';
	}
	
	return myTostID;
}


const axiosApi = handleInstanceInterceptors(
	axios.create({
		baseURL: apiPath
	})
);

export default axiosApi;
