import { useState } from 'react'
import { 
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, 
    TextareaAutosize, Typography, TextField 
} from '@mui/material';
import { basicProps, orderType } from '../../../utils/interfaces';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import OrderStatusLabel from '../../../components/OrderStatusLabel';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { orderAttachedMessagesType } from './Orders';

interface CancelOrderFormProps extends basicProps {
    orderData: orderType,
    orderAttachedMessages: orderAttachedMessagesType[]
}

const waNumber = '+237 650 83 94 31';


export default function CancelOrderForm(props: CancelOrderFormProps) {

    const basicDatas = props.basicDatas;
    const orderData = props.orderData;
    const orderAttachedMessages = props.orderAttachedMessages;

    const [showForm, setShowForm] = useState(false);
    const [message, setMessage] = useState(orderData.basic.status === 'waiting_payment' ? 
    `
        <b>Le délai de paiement est dépassé !</b> 
        <br/><br/>
        Vous n'avez pas effectué le paiement dans les délais requis.
        <br/>
        Si vous avez déjà effectué le paiement mais n'avez pas marqué la commande comme payée, veuillez nous contacter immédiatement sur WhatsApp au <b>${waNumber}</b> pour vérification.
    ` : 
    `
        <b>Nous n'avons pas reçu le paiement pour cette commande.</b>
        <br/><br/>
        Si vous avez effectué le paiement, veuillez nous contacter sur WhatsApp au <b>${waNumber}</b> pour vérification.
    `);
    const [open, setOpen] = useState(false);
    const [sw, setSw] = useState('');
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cancelOrder = () => {

        basicDatas.dialogManager({
            dialogTitle: "Annulation de l'ordre (" + orderData.basic.orderID + ")",
            dialogText: "Vous allez annuler cet ordre. Faut-il vraiment exécuter cette action ?",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Oui annuler"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    orderID:orderData.basic.orderID,
                    message,
                    sw,
                    loaderText: "Annulation en cours..."
                }
                axiosApi.post('ctr/orders/cancel-order.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        setMessage('');
                        setShowForm(false);

                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                })
            }
      
        })
    }

    if(!showForm) return (
        <Button color='error' sx={{ml:2}} onClick={() => setShowForm(!showForm)}>Annuler l'ordre</Button>
    )

    return (
        <div className='mt-4 mb-4'>

            <Button color='inherit' variant='contained' onClick={() => setShowForm(!showForm)}>
                Fermer le formulaire 
            </Button>

            <br /> <br />

            <Button
                startIcon={<StickyNote2Icon/>}
                onClick={handleClickOpen}
                sx={{
                    float: 'right'
                }}>
                Auto message
            </Button>

            <label htmlFor={"textarea-cancellation-message" + orderData.basic.orderID} style={{display:'block', marginBottom:'7px'}}>
                Motif d'annulation
            </label>
            
            <TextareaAutosize minRows={3} 
                                style={{width:'100%'}}
                                id={"textarea-cancellation-message" + orderData.basic.orderID}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Entrez un motif pour expliquer à l'utilisateur la raison pour laquelle cet ordre a été annulé" />

            <br /> <br />

            <TextField
                type='password'
                label="sw"
                onChange={(e) => setSw(e.target.value)}
            />

            <br /><br />

            <Button color='error' variant='contained' fullWidth onClick={cancelOrder}>
                Annuler l'ordre
            </Button>

            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Messages automatiques</DialogTitle>

                <DialogContent sx={{minWidth: 300}}>
                    
                    {
                        orderAttachedMessages.map((itemMessage, i) => {

                            if(orderData.basic.status === itemMessage.status) 

                            return (
                                <Box
                                    key={i}
                                    >
                                    <Typography sx={{fontWeight: 'bold', fontSize: 'large', mb: 2}}>
                                        <OrderStatusLabel orderStatus={ itemMessage.status } />
                                    </Typography>

                                    <Typography sx={{mb: 2}}>
                                        <Typography variant='caption' sx={{fontWeight: 'bold'}}>
                                            Annuler par ce que :
                                        </Typography>
                                    </Typography>

                                    {
                                        itemMessage.content.map((itemContent, j) => {

                                            if(itemContent.allowedFor === 'cancel') return (
                                                <Box
                                                    key={j}
                                                    onClick={() => {setMessage(itemContent.message); handleClose()}}
                                                    sx={{
                                                        pb: 3,
                                                        mb: 3,
                                                        boxShadow: 2,
                                                        p: 2,
                                                        borderRadius: 1,
                                                        cursor: 'pointer'
                                                    }}>
                                                    
                                                    <Typography>
                                                        { itemContent.icon } { itemContent.title}
                                                    </Typography>

                                                    <Typography color="GrayText" fontSize='small'>
                                                        { itemContent.message }
                                                    </Typography>

                                                </Box>
                                            )

                                            return <></>
                                        })
                                    }
                                    
                                </Box>
                            )

                            return <></>
                        })
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>

        </div>
    )
}
