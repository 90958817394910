import { Avatar, Box, Stack, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { basicProps, prizePoolCampaignType, User } from '../../../../utils/interfaces';
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import { copyClipboard } from '../../../../functions/basicFunctions';

export default function WinnersList(props: basicProps) {

    const {id} = useParams();
    const basicDatas = props.basicDatas;

    const [campaignData, setCampaignData] = useState<prizePoolCampaignType>();
    const [winners, setWinners] = useState([])

    useEffect(() => {
        
        axiosApi.post("ctr/airdrops/prize-pools/get-campaign-data.php", QueryString.stringify({id}))
        .then(response => {

            if(response.data.status === 'success') {
                setCampaignData(response.data.campaignData)
            }
        })

        axiosApi.post("ctr/airdrops/prize-pools/get-winners-list.php", QueryString.stringify({id}))
        .then(response => {

            if(response.data.status === 'success') {
                setWinners(response.data.winners)
            }
        })

    }, [id])

    if(!campaignData) return (
        <Box sx={{pt: 5, textAlign: 'center'}}>
            Chargement...
        </Box>
    )

    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <Typography
                textAlign='center'
                className='Montserrat'
                sx={{
                    mb: 5,
                    opacity: 0.8
                }}
            >
                { campaignData.name } 
                <br />
                <Typography variant='h5'>
                    Liste des gagnants
                </Typography>
            </Typography>

            {
                winners.map((itemWinner: any) => {

                    const user: User = itemWinner.user;

                    return (
                        <Stack
                            direction='row'
                            sx={{
                                mb: 5
                            }}
                        >
                            <Avatar 
                                src={user.avatarUrl} 
                                sx={{
                                    mr: 2
                                }}
                            />

                            <Typography>
                                <Typography 
                                    fontWeight='bold'
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => copyClipboard(user.user_id, "Copie!")}
                                >
                                    { itemWinner.user_id }
                                </Typography>

                                <Typography sx={{opacity: 0.8}}>
                                    { user.lastName + ' ' + user.firstName }
                                </Typography>

                                <Typography sx={{opacity: 0.8}}>
                                    Rewards : { itemWinner.rewards_claimed == 1 ? <span style={{color: 'green'}}>Réclamé</span> : <span style={{color: 'red'}}>Non Réclamé</span>}
                                </Typography>

                            </Typography>
                        </Stack>
                    )
                })
            }

        </Box>
    )
}
