import { useEffect, useState } from 'react';
import { basicProps } from '../../../utils/interfaces';
import { Typography, Box, Tooltip, Chip, Button, TextField } from '@mui/material';
import RechargeForm from './RechargeForm';
import axiosApi from '../../../functions/axiosApi';
import { copyClipboard, readabilityNumber } from '../../../functions/basicFunctions';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import QueryString from 'qs';

interface transaction {
    transactionID: string,
    amount: number,
    status: 'pending' | 'failed' | 'success' | 'canceled',
    date: string
}

function TransactionStatusBadge(props: {status: string}) {

    const status = props.status;

    let badgeLabel = '';

    switch (status) {
        case 'success':
            badgeLabel = "Success"
            break;

        case 'failed':
            badgeLabel = "Failed"
            break;

        case 'canceled':
            badgeLabel = "Canceled"
            break;

        case 'pending':
            badgeLabel = "Pending"
            break;
    
        default:
            badgeLabel = 'Unknown'
            break;
    }

    if(status === 'failed') return (
        <Chip 
            label={ badgeLabel } 
            sx={{
                backgroundColor: "#FDEDED",
                color: "#E5593C",
                ml: 1,
                borderRadius: 1,
                fontSize: 'small'
            }} 
            variant="filled" 
            size="small" />
    )

    if(status === 'success') return (
        <Chip 
            label={ badgeLabel } 
            sx={{
                backgroundColor: "#E8F3EE",
                color: "#338F5C",
                ml: 1,
                borderRadius: 1,
                fontSize: 'small'
            }} 
            variant="filled" 
            size="small" />
    )

    return (
        <Chip 
            label={ badgeLabel } 
            sx={{
                backgroundColor: "rgba(240,240,240, 0.5)",
                color: "#aaa",
                ml: 1,
                borderRadius: 1,
                fontSize: 'small'
            }} 
            variant="filled" 
            size="small" />
    )
}

export default function Recharge(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [transactions, setTransactions] = useState<transaction[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getTransactions();
    }, [queryValue])
    

    const getTransactions = () => {

        setIsLoading(true);

        axiosApi.post('auto-payment/get-recharges.php', QueryString.stringify({offset, limit: 5, queryValue}))
        .then(response => {

            setIsLoading(false);

            if(response.data.status === 'success') {
                const newtransactionsArray = transactions.concat(response.data.transactions);
                setTransactions(newtransactionsArray);
                setOffset(offset + 5);

                if(response.data.transactions.length === 0){
                    setEnded(true)
                }
            }
        })
    }

    return (
        <Box 
            sx={{
                maxWidth: 400,
                margin: 'auto',
                pt: 3
            }}>
            
            <Typography
                variant='h6'
                sx={{
                    textAlign: 'center'
                }}>

                Recharger portefeuille
            </Typography>

            <Typography sx={{textAlign: 'center', mt: 2}}>
                <Typography variant='caption'>
                    Utilisez votre numéro MTN ou Orange money Cameroun pour recharger votre compte automatiquement.
                </Typography>
            </Typography>

            <RechargeForm basicDatas={basicDatas} />

            <Typography variant='h6' sx={{textAlign: 'center', mt: 4, mb: 3}}>
                Historique
            </Typography>

            <Typography
                    sx={{
                        textAlign: 'center',
                        mb: 5
                    }}>
                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher une transaction : ID" 
                    variant="outlined"
                    placeholder="Entrez ID transaction"
                    value={queryValue}
                    fullWidth
                    sx={{maxWidth: 250}}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setTransactions([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    />
            </Typography>

            {
                transactions.map(transaction => {
                    return (
                        <Box
                            key={transaction.transactionID}
                            sx={{
                                mb: 3,
                                pb: 3,
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #eee'
                            }}>

                            <Typography>

                                <Typography>
                                    <Tooltip title="Cliquez pour copier ID transaction">
                                        <span style={{cursor:'pointer', fontWeight: 'bold'}} onClick={() => copyClipboard(transaction.transactionID, "ID transaction copié !")}>
                                            {transaction.transactionID}
                                        </span>
                                    </Tooltip>

                                    <TransactionStatusBadge status={transaction.status} />
                                </Typography>
                                
                                <Typography sx={{mb: 1}}>
                                    <Typography 
                                            variant='caption'
                                            sx={{
                                                opacity: 0.7
                                            }}>
                                        { transaction.date }
                                        
                                    </Typography>
                                </Typography>

                            </Typography>

                            <Typography
                                    sx={{
                                        ml: 2
                                    }}>

                                <Typography
                                    sx={{
                                        fontSize: 'small',
                                        fontWeight: 'bold',
                                        color: transaction.status === 'success' ? 'green' : '',
                                        mb: 2,
                                        textAlign: 'right'
                                    }}>
                                    {transaction.status === 'success' ? '+' : ''}{ readabilityNumber(parseFloat((transaction.amount).toFixed(5))) }
                                </Typography>

                            </Typography>

                        </Box>
                    )
                })
            }

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getTransactions()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
