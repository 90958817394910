import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { isPC, publicPath } from '../../functions/basicFunctions'
import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';

const issues = [
    { title: {name: 'Comment acheter crypto avec MTN Money Cameroun?', link: '/help/trade-help/buy-with-mtn-cm-guide/'}},
    { title: {name: 'Comment acheter crypto avec Orange Money Cameroun?', link: '/help/trade-help/buy-with-om-cm-guide/'}},
    { title: {name: 'Comment acheter crypto avec MTN Money Congo Brazzaville?', link: '/help/trade-help/buy-with-mtn-cg-guide/'}},
    { title: {name: 'Comment acheter crypto avec Airtel Money Congo Brazzaville?', link: '/help/trade-help/buy-with-airtel-cg-guide/'}},
    { title: {name: 'Comment acheter crypto avec MOOV Money Tchad?', link: '/help/trade-help/buy-with-moov-td-guide/'}},
    { title: {name: 'Comment acheter crypto avec Airtel Money Tchad?', link: '/help/trade-help/buy-with-airtel-td-guide/'}},
    { title: {name: 'Comment acheter crypto avec MOOV Money Gabon?', link: '/help/trade-help/buy-with-moov-ga-guide/'}},
    { title: {name: 'Comment acheter crypto avec Airtel Money Gabon?', link: '/help/trade-help/buy-with-airtel-ga-guide/'}},
    { title: {name: 'CFT, c\'est quoi?', link: '/r/64afedfb9bb64', newTab: true}},
    { title: {name: 'CRYPTO-FIAT PAY, c\'est quoi?', link: '/r/652112aebf612/', newTab: true}},
    { title: {name: 'CRYPTO-FIAT PAY, Frais et commissions', link: '/r/64aff0dba47cd/', newTab: true}},
    { title: {name: 'Bonus CFT, Comment ça marche?', link: 'https://medium.com/@cryptofiat1022/gagnez-des-bonus-cft-sur-chaque-transaction-en-tant-que-client-fidèle-de-crypto-fiat-63122db6dfe4', newTab: true}},
    { title: {name: 'Comment enregistrer une nouvelle adresse de portefeuille?', link: 'https://medium.com/@cryptofiat1022/comment-enregistrer-une-nouvelle-adresse-de-portefeuille-sur-cryptofiat-73fbdb80a124', newTab: true}},
    { title: {name: 'Code OTP, non reçu ou ne fonctionne pas', link: '/help/auth-help/otp/'}},
];


export default function HomeHelp() {
    return (
        <Box
            maxWidth={600}
            margin='auto'
            sx={{
                pt: 3
            }}
        >

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Aide</title>
                <meta name="description" content="CRYPTO-FIAT | Aide" />
                <meta property="og:title" content="CRYPTO-FIAT | Aide" />
            </MetaTags>
            
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
      
                <img 
                    src={publicPath + "images/question.png"}
                    style={{
                        maxWidth: isPC ? 600 : '90vw'
                    }}
                />
            </div>
            
            <Typography 
                variant={isPC ? 'h4' : 'h5'} 
                textAlign='center'
                color='primary'
            >
                Comment pouvons-nous vous aider ?
            </Typography>

            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={issues.map((option) => option.title)}
                sx={{
                    maxWidth: 450,
                    margin: 'auto',
                    mt: 3,
                    boxShadow: 3,
                    borderRadius: 2
                }}

                renderOption={(params, itemIssue) => {

                    let _params = {...params, key: itemIssue.name } 
                    
                    return (
                        <Typography 
                            { ..._params } 
                            >

                            {
                                itemIssue.newTab 

                                ?
                                <a href={itemIssue.link} className='link' target='_blank'>
                                    <Button
                                        fullWidth
                                        sx={{
                                            textTransform: 'none',
                                            textAlign: 'left'
                                        }}
                                    >
                                        { itemIssue.name }
                                    </Button>
                                </a>
                                
                                :

                                <Link to={itemIssue.link} className='link'>
                                    <Button
                                        fullWidth
                                        sx={{
                                            textTransform: 'none',
                                            textAlign: 'left'
                                        }}
                                    >
                                        { itemIssue.name }
                                    </Button>
                                </Link>
                            }

                        </Typography>
                    )
                }}

                renderInput={(params) => {
                    
                    return (
                        <TextField
                            {...params}
                            label="Chercher une solution"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                            sx={{
                                border: 'none'
                            }}
                        />
                    )
                }}
            />

            <Box
                sx={{
                    mt: 10,
                }}
            >

                {
                    issues.map((itemIssue, i) => {

                        const _itemIssue = itemIssue.title;
                        const _content =    <Typography
                                                sx={{
                                                    bgcolor: '#F7F7F7',
                                                    p: 2,
                                                    mb: 2,
                                                    borderRadius: 1
                                                }}
                                            >
                                                
                                                <Button
                                                    sx={{
                                                        textTransform: 'none',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    { _itemIssue.name }
                                                </Button>
                                                
                                            </Typography>

                        return (

                            _itemIssue.newTab

                            ?

                            <a 
                                key={i}
                                href={_itemIssue.link} 
                                className='link'
                                target='_blank'
                            >
                                {_content}
                            </a>

                            :

                            <Link 
                                key={i}
                                to={_itemIssue.link} className='link'
                            >
                                { _content }
                            </Link>
                        )
                    })
                }
            </Box>

        </Box>
    )
}
