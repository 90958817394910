import { Box, Button, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import axiosApi from '../../../../functions/axiosApi';
import AddFundsLocationForm from './AddFundsLocationForm';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';


export default function FundsLocation() {

    const [fundsLocations, setfundsLocations] = useState<{name: string, id: number}[]>([]);

    useEffect(() => {

        getFundsLocations()
      
    }, [])

    const getFundsLocations = () => {

        axiosApi.post('ctr/stats/compta/funds-location/get.php')
        .then(response => {

            if(response.data.status === 'success'){
                setfundsLocations(response.data.fundsLocations)
            }
        })
    }
    


    return (
        <div
            style={{
                maxWidth: 400,
                margin: 'auto'
            }}
        >

            <br />
            
            <Typography variant='h5' textAlign='center' sx={{mb: 2}}>
                Localisations de fonds
            </Typography>

            <br />

            <AddFundsLocationForm getFundsLocations={getFundsLocations} />

            <List>

                {
                    fundsLocations.map(item => {
                        return(
                            <ListItem   
                                key={item.id}
                                secondaryAction={

                                    <>
                                        <Link to={'/ctr/stats/compta/funds-location/edit/' + item.id + '-' + item.name + '/' }>
                                            <Button>
                                                <EditIcon />
                                            </Button>
                                        </Link>
                                    </>
                                }
                                sx={{bgcolor: 'ButtonFace', mb:2}} >
                    
                                <ListItemText primary={item.name} secondary='' />


                            </ListItem>
                        )
                    })
                }
            </List>

            
        </div>
    )
}
