import React from 'react'
import { User, basicProps } from '../../../../utils/interfaces'
import { Avatar, Box, Button, TextField, TextareaAutosize, Typography } from '@mui/material'
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';

export default function AdjustmentForm(props: basicProps) {

    const basicDatas = props.basicDatas;

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);

        axiosApi.post("wallet/transfer/verify-receiver-account.php", QueryString.stringify({receiverID: data.get('userID')}))
        .then(response => {
            if(response.data.status === 'success'){

                const receiverInfo: User = response.data.receiverAccount;

                basicDatas.dialogManager({
                    dialogTitle: 'Utilisateur',
                    dialogText: (
                        <Box sx={{
                            textAlign: 'center'
                        }}>
                            <Avatar src={receiverInfo.avatarUrl}
                                    sx={{
                                        margin: 'auto',
                                        width: '60px',
                                        height: '60px',
                                        boxShadow: 1,
                                        mb: 2
                                    }} />

                            <Typography sx={{mb: 3}}>
                                
                                { receiverInfo.firstName + ' ' + receiverInfo.lastName }

                                <br /> <br />

                                <b style={{fontSize: 'small'}}>{ "ID : " + receiverInfo.user_id}</b>

                            </Typography>

                            

                            <Typography variant='caption'>
                                Est-ce bien le compte que vous souhaitez modifier ?
                            </Typography>
                        </Box>
                    ),
                    dialogAgreeBtnColor: 'info',
                    dalogCloseBtnText: 'Non',
                    dialogAgreeBtnText: 'Confirmer'
                  }, (response) => {
                    
                    if(response === 'ok') {
                        
                        data.set('loaderText', "Traitement en cours...");

                        axiosApi.post("ctr/finances/freeze-assets/adjustment.php", data)
                        .then(response => {

                            if(response.data.status === 'success') {
                                //setAction('');
                            }
                        })
                    }
              
                })
            }
        })
    }
    
    return (
        <Box>
            
            <Typography variant='h5' sx={{textAlign: 'center', mb: 5}}>
                Ajustement de solde
            </Typography>

            <Box 
                component='form'
                onSubmit={submit}
                >

                <TextField
                    type='text'
                    name='userID'
                    label='ID utilisateur'
                    placeholder='userID'
                    required
                    fullWidth
                    autoComplete='userID'
                    sx={{mb: 2}}
                />

                <TextField
                    type='number'
                    name='amount'
                    inputProps={{step: 'any'}}
                    label='Montant'
                    placeholder='Montant'
                    required
                    fullWidth
                    sx={{mb: 2}}
                />

                <TextareaAutosize
                    name='motif'
                    id="address"
                    minRows={3}
                    required
                    placeholder="Raison"
                    style={{ 
                        width:'100%', 
                        padding:'10px', 
                        borderRadius:'5px', 
                        border: "1px solid #bbb",
                        marginBottom: '15px'
                    }}
                />

                <TextField
                    type='password'
                    name='pinCode'
                    label='Code PIN'
                    placeholder='PIN'
                    required
                    fullWidth
                    sx={{mb: 2}}
                />

                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    fullWidth
                    sx={{mb: 3}}
                />

                <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    >
                    Exécuter
                </Button>

            </Box>

        </Box>
    )
}
