import { useEffect, useState } from 'react';
import { User, basicProps } from '../../../../utils/interfaces'
import { Box, Button, Stack, Typography, TextField, Avatar, Chip } from '@mui/material'
import { Link, Route, Routes } from 'react-router-dom'
import FreezeForm from './FreezeForm'
import axiosApi from '../../../../functions/axiosApi';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import QueryString from 'qs';
import { copyClipboard } from '../../../../functions/basicFunctions';
import AdjustmentForm from './AdjustmentForm';

interface histotyType {
    id: number,
    action: 'lock' | 'unlock',
    amount: number,
    date: string,
    user: User,
    motif: string
}

export default function FreezeAssets(props: basicProps) {

    const [lockHistory, setLockHistory] = useState<histotyType[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getHistory()
    }, [queryValue])

    const getHistory = () => {

        setIsLoading(true);

        axiosApi.post("ctr/finances/freeze-assets/get-history.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newHistoryArray = lockHistory.concat(response.data.history);
                setLockHistory(newHistoryArray);
                setOffset(offset + 5);
                if(response.data.history.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }
    
    return (
        <Box
            sx={{
                pt: 3,
                maxWidth: 500,
                margin: 'auto'
            }}>

            <Routes>
                <Route path='/' element={
                    <>

                        <Typography variant='h5' sx={{textAlign: 'center', mb: 5}}>
                            Géler et débloquer les actifs
                        </Typography>
                        
                        <Stack direction='row' spacing={3} sx={{mb: 3}} justifyContent='space-around'>

                            <Link to='/ctr/finances/freeze-assets/lock-unlock/'>
                                <Button variant='contained' color='inherit'>
                                    Géler/Débloquer les actifs
                                </Button>
                            </Link>

                            <Link to='/ctr/finances/freeze-assets/adjustment/'>
                                <Button variant='contained' color='inherit'>
                                    Ajustement
                                </Button>
                            </Link>

                        </Stack>

    
                        <TextField 
                            id="outlined-basic"
                            type='search' 
                            label="Rechercher un utilisateur" 
                            variant="outlined"
                            placeholder="Entrez ID utilisateur"
                            value={queryValue}
                            fullWidth
                            sx={{mt:3, mb: 5}}
                            onChange={
                                (e) => {
                                    setQueryValue(e.target.value);
                                    setLockHistory([]);
                                    setOffset(0);
                                    setEnded(false)
                                }
                            }
                            />


                        
                        {
                            lockHistory.map(item => {

                                const user = item.user;

                                return (
                                    <Box key={item.id}
                                        sx={{
                                            borderBottom: '1px solid #ddd',
                                            pb: 4,
                                            mb: 4
                                        }}
                                        >

                                        <Stack direction='row' spacing={2}>
                                            <Avatar src={ user.avatarUrl } sx={{borderRadius: 1, width: 60, height: 60}} />
                                            <Typography>

                                                <Typography>

                                                    <span style={{fontWeight: 'bold'}}>
                                                        { item.amount }
                                                    </span>

                                                    {" — "} 

                                                    <Chip label={ item.action } 
                                                            sx={{
                                                                backgroundColor: item.action === 'unlock' ? "#E8F3EE" : "#FDEDED",
                                                                color: item.action === 'unlock' ? "#338F5C" : "#E5593C",
                                                                borderRadius: 1
                                                            }} 
                                                            variant="filled" 
                                                            size="small" />
                                                </Typography>

                                                <Typography>
                                                    <Typography variant='caption'>
                                                        <span style={{fontWeight: 'bold'}}>Compte : </span> <span style={{fontWeight: 'bold', cursor:'pointer'}} title='Copier' onClick={() => copyClipboard(user.user_id, "Copie !")}>{ user.user_id }</span> { user.firstName + ' ' + user.lastName}
                                                    </Typography>
                                                </Typography>

                                                <Typography>
                                                    <Typography variant='caption'>
                                                        <span style={{fontWeight: 'bold'}}>Motif : </span> { item.motif }
                                                    </Typography>
                                                </Typography>

                                                <Typography>
                                                    <Typography variant='caption'>
                                                        { item.date }
                                                    </Typography>
                                                </Typography>
                                            </Typography>
                                        </Stack>

                                    </Box>
                                )
                            })
                        }
    

                        <div className="text-center">
                            
                            {
                                isloading

                                ? 
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="outlined"
                                    >
                                        Chrargement
                                    </LoadingButton>
                                :
                                    !ended &&
                                    <Button variant='contained' color='inherit' onClick={() => getHistory()}>
                                        Afficher plus
                                    </Button>
                            }

                        </div>
                    </>
                } />

                <Route path='/lock-unlock' element={<FreezeForm {...props} />} />
                <Route path='/adjustment' element={<AdjustmentForm {...props} />} />

            </Routes>

        </Box>
    )
}
