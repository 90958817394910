import { useState } from 'react'
import { basicProps } from '../../../../utils/interfaces'
import { Box, Button, TextareaAutosize, TextField, Typography } from '@mui/material'
import ImagesSelector from '../../../../components/ImageSelector'
import axiosApi from '../../../../functions/axiosApi';

export default function AddCampaign(props: basicProps) {

    const [images, setImages] = useState([]);

    const addCampaign = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('images', JSON.stringify(images))
        data.append("loaderText", "Traitement en cours...")

        axiosApi.post("ctr/airdrops/prize-pools/add-campaign.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                console.log(response.data)
            }
        })
    }

    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: 'auto',
                pt: 5
            }}
        >

            <Typography
                variant='h5'
                fontWeight='bold'
                className='Montserrat'
                sx={{
                    textAlign: 'center',
                    mb: 5
                }}
            >
                Créer une campagne
            </Typography>
            
            <Box
                component='form'
                onSubmit={addCampaign}
            >

                <TextField
                    type='text'
                    name='name'
                    label='Nom de la campagne'
                    placeholder='Attribuer un nom'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <TextField
                    type='text'
                    name='title'
                    label='Titre de campagne'
                    placeholder='Titre de campagne'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <Typography sx={{mb: 1}}>
                    Description
                </Typography>

                <TextareaAutosize 
                    minRows={3} 
                    maxRows={10}
                    name='description'
                    id='description'
                    placeholder="Description de la campagne" 
                    style={{
                        width: '100%',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        padding: '15px',
                        marginBottom: '15px'
                    }}
                />

                <TextField
                    type='date'
                    name='start_date'
                    label='Date de debut'
                    placeholder='Date de debut'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <TextField
                    type='date'
                    name='end_date'
                    label='Date de fin'
                    placeholder='Date de fin'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 2
                    }} 
                />

                <TextField
                    type='password'
                    name='sw'
                    label='sw'
                    margin='dense'
                    fullWidth
                    sx={{
                        mb: 3
                    }} 
                />
                
                <Typography sx={{mb: 1.5}}>
                    Ajouter une image de mise en avant
                </Typography>

                <div className='kyc-cni-images'>
                    <ImagesSelector images={images} max={1} setImages={(items: any) => {setImages(items)}} />
                </div>
                
                <br /><br />

                <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                >
                    Créer la campagne
                </Button>

            </Box>
        </Box>
    )
}
