import { Avatar, Box, Typography } from '@mui/material'
import { useState, useEffect } from 'react';
import { currencyType } from '../../utils/interfaces';
import axiosApi from '../../functions/axiosApi';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { cf_pay_info, isPC, readabilityNumber } from '../../functions/basicFunctions';

interface assetType extends currencyType {
    userBalance: number
}

export default function Preview() {

    const [assets, setAssets] = useState<assetType[]>([])

    useEffect(() => {
        axiosApi.post("wallet/get-assets.php")
        .then(response => {
            if(response.data.status === 'success'){
                setAssets(response.data.assets);
            }
        })
    }, [])
    
    return (
        <Box sx={{
            maxWidth: '500px',
            margin: 'auto'
        }}>
            
            <Typography variant='h5' sx={{textAlign: 'center', mt: 4, mb: 4}}>
                Apperçu portefeuille
            </Typography>

            <Typography sx={{mt: 2, textAlign: isPC ? 'center' : 'left'}}>
                <Typography variant='caption'>
                    Votre portefeuille vous permet de stocker vos CFT directement sur cryptofiat-ex.com. <br />
                    Rechargez votre compte et achetez vos cryptos avec { cf_pay_info.name } sans frais. <br />
                    Cliquez sur le token pour voir votre historique de transactions
                </Typography>
            </Typography>

            <Typography sx={{textAlign: 'center', mb: 4}}>

                <Link to={"/cft-token/sellers/"} className='link'>

                    <Typography sx={{
                        margin: 'auto',
                        fontWeight: 'bold',
                        mt: 3,
                        mr: 2,
                        p: 1,
                        pl: 2,
                        pr: 2,
                        boxShadow: 1,
                        borderRadius: 2,
                        maxWidth: 250,
                        display: 'inline-block'
                    }}>

                        <AddIcon sx={{mr: 1}} />

                        Recharger

                    </Typography>

                    </Link>
            </Typography>

            <Box>
                {
                    assets.map(asset => {

                        return(

                            <Link to={"/wallet/" + asset.symbol + "/"} className='link'>

                                <Typography sx={{
                                    boxShadow: isPC ? 1 : 3,
                                    borderRadius: isPC ? 2 : 0,
                                    p: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>

                                    <Avatar src={ asset.logo} sx={{mr: 2, boxShadow: 1}} />

                                    <Typography sx={{width: '100%'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            { asset.symbol }
                                        </Typography>
                                        <Typography sx={{fontSize: 'small'}}>
                                            { asset.name }
                                        </Typography>
                                    </Typography>

                                    <Typography sx={{minWidth: 140, textAlign: 'right'}}>
                                        { readabilityNumber(parseFloat((asset.userBalance).toFixed(5))) }
                                    </Typography>
                                    
                                </Typography>

                            </Link>
                        )
                    })
                }

            </Box>

        </Box>
    )
}
