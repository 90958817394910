import { useEffect, useState } from 'react'
import { basicProps } from '../../../../utils/interfaces';
import { Box, Typography } from '@mui/material';
import { cf_pay_info, readabilityNumber } from '../../../../functions/basicFunctions';
import axiosApi from '../../../../functions/axiosApi';

interface statType {
    totalTransactions: number,
    emitQte: number,
    marketCap: number,
    issuerAccountBalance: number,
    totalTransactionVolume: number,
    totalFees: number
}

export default function CFPAYstats(props: basicProps) {

    const [stats, setStats] = useState<statType>();

    useEffect(() => {
        axiosApi.post("ctr/finances/cfpay-stats.php")
        .then(response => {
            if(response.data.status === 'success') {
                setStats(response.data.stats);
            }
        })
    }, [])
    
    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: 'auto'
            }}>

            <Typography
                    variant='h5'
                    sx={{
                        textAlign: 'center',
                        mt: 3,
                        mb: 5
                    }}>
                Statistiques { cf_pay_info.name }
            </Typography>

            <Typography
                    sx={{
                        boxShadow: 1,
                        mb: 3,
                        p: 2,
                        borderRadius: 1
                    }}>

                Total transactions <br />
                <b>{ stats?.totalTransactions ? readabilityNumber(stats.totalTransactions) : '...' }</b>
            </Typography>

            <Typography
                    sx={{
                        boxShadow: 1,
                        mb: 3,
                        p: 2,
                        borderRadius: 1
                    }}>

                Quantité émise <br />
                <b>{ stats?.emitQte ? readabilityNumber(stats.emitQte) : '...' }</b>
            </Typography>

            <Typography
                    sx={{
                        boxShadow: 1,
                        mb: 3,
                        p: 2,
                        borderRadius: 1
                    }}>

                Volume total transactions <br />
                <b>{ stats?.totalTransactionVolume ? readabilityNumber(stats.totalTransactionVolume) : '...' }</b>
            </Typography>

            <Typography
                    sx={{
                        boxShadow: 1,
                        mb: 3,
                        p: 2,
                        borderRadius: 1
                    }}>

                Quantité totale frais <br />
                <b>{ stats?.totalFees ? readabilityNumber(stats.totalFees) : '...' }</b>
            </Typography>

            <Typography
                    sx={{
                        boxShadow: 1,
                        mb: 3,
                        p: 2,
                        borderRadius: 1
                    }}>

                Solde compte émetteur <br />
                <b>{ stats?.issuerAccountBalance ? readabilityNumber(stats.issuerAccountBalance) : '...' }</b>
            </Typography>

            <Typography
                    sx={{
                        boxShadow: 1,
                        mb: 3,
                        p: 2,
                        borderRadius: 1
                    }}>

                Quantité en circulation <br />
                <b>{ stats?.marketCap ? readabilityNumber(stats.marketCap) : '...' }</b>
            </Typography>

        </Box>
    )
}
