import { useState, useEffect } from 'react'
import { 
    Chip, Dialog, DialogTitle, DialogContent, Typography,
    Button, DialogActions
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosApi from '../../functions/axiosApi';
import { basicProps, currencyType, networkType } from '../../utils/interfaces';
import { isPC } from '../../functions/basicFunctions';
import { toast } from 'react-toastify';

interface myProps extends basicProps {
    title: string,
    action: 'buy' | 'sell',
    disabled: {disabled: boolean, message: string},
    selectedNetwork: networkType | undefined,
    selectedCrypto: currencyType | undefined,
    setSelectedNetwork: (a: networkType) => void
}

export default function SelectNetworkImput(props: myProps) {

    const basicDatas = props.basicDatas;
    const selectedNetwork = props.selectedNetwork;
    const selectedCrypto = props.selectedCrypto;
    const action = props.action;

    const [networks, setNetworks] = useState<networkType[]>([])
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        
        if(open) {
            getNetworks()
        }
    
    }, [open])

    useEffect(() => {

        const findAllowedNetwork = selectedCrypto?.allowed_networks?.find((n) => selectedNetwork?.symbol === n.network);
        
        if(findAllowedNetwork && (findAllowedNetwork.congested)) {
            basicDatas.dialogManager({
                dialogTitle: "Réseau congestionné",
                dialogText: <Typography>
                    Le réseau <b>{selectedNetwork?.symbol + " (" + selectedNetwork?.name + ")" }</b>  est actuellement congestionné. <br />
                    <Typography variant='caption'>
                        Cela signifie que le réseau est surchargé et que les transactions peuvent durer plus longtemps que d'habitude.
                        <br />
                        Les frais de transactions peuvent également augmenter considérablement.
                    </Typography>
                </Typography>,
                dialogAgreeBtnColor: 'primary',
                dialogAgreeBtnText: "J'ai compris",
                dalogCloseBtnText: ' '
                }, () => {}
            )
        }

    }, [selectedNetwork])
    
    

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if(props.disabled.disabled) {
            toast.info(props.disabled.message);
        }

        else {
            setOpen(true);
        }
    }

    const getNetworks = () => {

        setLoading(true);

        axiosApi.post('ctr/networks/get-networks.php')
        .then(response => {

            setLoading(false);

            if(response.data.status === 'success'){
                setNetworks(response.data.networks);
            }
        })
    }

    return (
        <>

            {
                selectedNetwork

                ?   <Chip
                        label={<> { selectedNetwork.symbol } <ExpandMoreIcon/> </>}
                        onClick={handleOpen}
                        disabled={props.disabled.disabled}
                        sx={{
                            borderRadius: 1
                        }}
                    />

                :
                    <Chip
                        label={<>Choisir le réseau <ExpandMoreIcon/> </>}
                        onClick={handleOpen}
                        disabled={props.disabled.disabled}
                        sx={{
                            borderRadius: 1
                        }}
                    />
            }
            

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                    maxWidth: 600, 
                    margin: 'auto'
                }}
            >
                <DialogTitle id="scroll-dialog-title">{ props.title }</DialogTitle>
                
                <DialogContent 
                    dividers={true}
                    sx={{
                        height: isPC ? 400 : '80vh',
                        minWidth: isPC ? 350 : 100
                    }}
                >

                    {
                        loading &&
                        <Typography sx={{textAlign: 'center'}}>
                            <Typography variant='caption'>
                                Chargement en cours...
                            </Typography>
                        </Typography>
                    }

                            
                    {
                        selectedCrypto?.allowed_networks?.map((allowed_network, index) => {

                            var matchNetwork = networks.filter((itemNetwork) => itemNetwork.symbol === allowed_network.network);
                            const networkInfos = matchNetwork.length !== 0 ? matchNetwork[0] : null;

                            if(!networkInfos) return <span key={index}></span> 

                            let networkDisabled = ((action === 'buy') && (!allowed_network.allowForBuy || (networkInfos?.allowForBuy == 0))) || (action === 'sell') && (!allowed_network.allowForSell || (networkInfos.allowForSell == 0));

                            return (

                                <Typography
                                    key={index}
                                    onClick={() => {

                                        if(!networkDisabled) {
                                            props.setSelectedNetwork(networkInfos);
                                            setOpen(false)
                                        }
                                    }}
                                    sx={{
                                        mb: 3,
                                        cursor: networkDisabled ?'inherit' : 'pointer',
                                        opacity: networkDisabled ? 0.6 : 1
                                    }}
                                >
                                    <Typography>
                                        
                                        { networkInfos.symbol }

                                        {
                                            (!networkDisabled && (action === 'buy')) &&

                                            <>

                                                {
                                                    selectedNetwork?.symbol === networkInfos.symbol &&
                                                    <br style={{marginBottom: '5px'}} />
                                                }

                                                <Chip
                                                    label={
                                                        selectedCrypto.feeInPercentage == 1
                                                        ?   "Frais : " + allowed_network.networkFee + "%"
                                                        :   "Frais : " + allowed_network.networkFee + " " + selectedCrypto.symbol
                                                    } 
                                                    sx={{
                                                        backgroundColor: "RGB(25, 118, 210, 0.1)",
                                                        color: "RGB(25, 118, 210)",
                                                        ml: selectedNetwork?.symbol === networkInfos.symbol ? 0 : 2,
                                                        fontSize: '11px',
                                                        cursor: 'pointer'
                                                    }} 
                                                    variant="filled"
                                                    size='small' 
                                                />
                                            </>
                                        }

                                        {
                                            (networkDisabled) &&
                                            <Chip label="Suspendu" 
                                                        sx={{
                                                            backgroundColor: "#FDEDED",
                                                            color: "#E5593C",
                                                            ml: selectedNetwork?.symbol === networkInfos.symbol ? 0 : 2,
                                                            fontSize: '11px',
                                                            cursor: 'pointer'
                                                        }} 
                                                        variant="filled"
                                                        size='small' />
                                        }

                                        {               
                                        (!networkDisabled && (allowed_network.congested)) &&
                                            <Chip 
                                                label="Congestionné" 
                                                sx={{
                                                    backgroundColor: "#FFF4E5",
                                                    color: "#663C01",
                                                    ml: selectedNetwork?.symbol === networkInfos.symbol ? 0 : 2,
                                                    fontSize: '11px',
                                                    cursor: 'pointer'
                                                }} 
                                                variant="filled"
                                                size='small' 
                                            />
                                        }

                                    </Typography>

                                    <Typography variant='caption'>
                                        { networkInfos.name }
                                    </Typography>

                                </Typography>
                            )
                        })
                    }   



                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
