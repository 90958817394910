import { useEffect, useState } from 'react'
import { basicProps, prizePoolCampaignType } from '../../../../utils/interfaces'
import { Box, Button, Typography } from '@mui/material'
import axiosApi from '../../../../functions/axiosApi'
import TelegramIcon from '@mui/icons-material/Telegram';
import QueryString from 'qs'
import { isPC, publicPath, readabilityNumber } from '../../../../functions/basicFunctions';
import { Link } from 'react-router-dom';

interface myProps extends basicProps {
    campaignData: prizePoolCampaignType
}

export default function ClaimRewards(props: myProps) {

    const campaignData = props.campaignData;
    const [imWinner, setImWinner] = useState<any>(false);

    useEffect(() => {
        getWinnerStatus();        
    }, [])

    const getWinnerStatus = () => {

        axiosApi.post("airdrops/prize-pools/check-if-winner.php", QueryString.stringify({campaignID: campaignData.id}))
        .then(response => {
            if(response.data.status === 'success') {
                setImWinner(response.data.imWinner)
            }
        })
    }

    const claimRewards = () => {

        const data = {
            campaignID: campaignData.id,
            loaderText: "Réclamation en cours..."
        }

        axiosApi.post("airdrops/prize-pools/claim-rewards.php", QueryString.stringify(data))
        .then(response => {

            if(response.data.status === 'success') {
                getWinnerStatus()
            }
        })
    }
    
    return (
        <Box>

            <Typography 
                variant='h5' 
                fontWeight='bold'
                className='Montserrat'
                sx={{
                    mt: 5,
                    mb: 2
                }}
            >
                Récompenses
            </Typography>
            
            <Typography
                fontWeight='bold'
                className='Montserrat'
                sx={{
                    mb: 3
                }}
            >

                {
                    imWinner

                    ? "Félicitations!!! Vous êtes l'un des heureux gagnants de cette cagnotte"
                    
                    :   <Typography>
                            Vous n'avez pas eu beaucoup de chance cette fois. Mais vous serrez peut-être l'un des prochains heureux gagnants.
                            <br />
                            Si ce n'est pas encore le cas, abonnez-vous au canal Telegram pour ne pas rater la prochaine cagnotte.
                            <br />
                            <a 
                                href='https://t.me/cryptofiatex' 
                                target='_blank'
                            >
                                <Button
                                    variant='outlined'
                                    className='Montserrat'
                                    sx={{
                                        fontWeight: 'bold',
                                        mt: 2
                                    }}
                                    endIcon={<TelegramIcon />}
                                >
                                    S'abonner au canal
                                </Button>
                            </a>
                        </Typography>
                }
                
            </Typography>

            {
                imWinner &&

                <Box>
                    <Typography color='primary' sx={{fontWeight: 'bold', mb: 3, textAlign: 'center'}}>
                        <Typography 
                            className='Montserrat' 
                            sx={{
                                fontSize: isPC ? '50px' : '30px', 
                                display: 'inline',
                                fontWeight: 'bold'
                            }}
                        >
                            { readabilityNumber(campaignData.amountPerWinner) }
                        </Typography>CFT
                    </Typography>

                    {
                        imWinner.rewards_claimed == 1 &&
                        <Typography sx={{mb: 3}}>
                            <Typography variant='caption'>
                                Vous avez réclamé vos CFT. Veuillez accéder à votre portefeuille pour vérifier le solde.
                            </Typography>
                            <br /> <br />

                            <Link to='/wallet/CFT/' className='link Montserrat' style={{color: '#1976D2', fontWeight: 'bold'}}>
                                Accéder au portefeuille
                            </Link>
                        </Typography>
                    }

                    {
                        imWinner.rewards_claimed == 1

                        ?   <Button
                                fullWidth
                                variant='contained'
                                className='Montserrat'
                                disabled
                                sx={{
                                    p: 1.5,
                                    fontWeight: 'bold',
                                    backgroundImage: 'url("' + publicPath + 'images/ballons.gif")',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover'
                                }}
                            >
                                Vous avez réclamé vos CFT
                            </Button>

                        :   <Button
                                fullWidth
                                variant='outlined'
                                className='Montserrat'
                                onClick={claimRewards}
                                sx={{
                                    p: 1.5,
                                    fontWeight: 'bold',
                                    fontSize: 20,
                                    backgroundImage: 'url("' + publicPath + 'images/ballons.gif")',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover'
                                }}
                            >
                                Réclamer CFT
                            </Button>
                    }

                    
                </Box>
            }
        </Box>
    )
}
