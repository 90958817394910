import { useState, useEffect } from 'react'
import { basicProps, cftPartnerType } from '../../../../utils/interfaces'
import { Avatar, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import SetBasicInfos from './SetBasicInfos';
import PartnerPhoneNumbers from './PartnerPhoneNumbers';
import { cf_pay_info } from '../../../../functions/basicFunctions';

export type partnerStatusType = 'pause' | 'certified' | 'blocked'

interface myProps extends basicProps {
    sw: string
}

export default function Partner(props: myProps) {

    const basicDatas = props.basicDatas;
    const { user_id } = useParams();

    const [partner, setPartner] = useState<cftPartnerType>();
    const userInfos = partner ? partner.userInfos : null;

    useEffect(() => {

        axiosApi.post("ctr/team-manager/cft-partners/get-partner-infos.php", QueryString.stringify({user_id}))
        .then(response => {
            
            if(response.data.status === 'success') {
                setPartner(response.data.partner)
            }
        })

    }, [user_id])

    if(!partner) return (
        <div className='text-center mt-3'>
            Chargement...
        </div>
    )
    
    return (
        <div style={{maxWidth: '500px', margin: 'auto', marginTop: '30px'}}>
            
            <Typography sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Avatar src={ userInfos?.avatarUrl } sx={{width: 60, height: 60}} />

            </Typography>

            <Typography variant='h5' sx={{mt: 2, mb:5, textAlign: 'center'}}>
                { userInfos?.lastName + ' ' + userInfos?.firstName}
            </Typography>

            <Typography
                sx={{
                    p: 2,
                    mb: 3,
                    boxShadow: 1,
                    borderRadius: 1
                }}
            >

                <Typography
                    sx={{
                        fontWeight: 'bold'
                    }}>
                Informations personnelles
            </Typography>

                <Link to={'/ctr/users/' + user_id + '/'}>
                    Afficher
                </Link>

            </Typography>

            <Typography
                sx={{
                    p: 2,
                    mb: 3,
                    boxShadow: 1,
                    borderRadius: 1
                }}
            >

                <Typography
                    sx={{
                        fontWeight: 'bold'
                    }}>
                Volumes { cf_pay_info.name }
            </Typography>

                <Link to={'/ctr/team/cft-partners/' + user_id + '/cf-pay-volumes/'}>
                    Afficher
                </Link>

            </Typography>

            <PartnerPhoneNumbers
                partner={partner}
                sw={props.sw}
            />

            <SetBasicInfos 
                partner={partner}
                sw={props.sw}
            />
            
        </div>
    )
}
