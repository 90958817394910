import { useState } from 'react'
import { Button, TextareaAutosize } from '@mui/material';
import QueryString from 'qs';
import { basicProps } from '../../../../utils/interfaces';
import axiosApi from '../../../../functions/axiosApi';

interface CancelOrderFormProps extends basicProps {
    orderData: any
}

export default function CancelOrderForm(props: CancelOrderFormProps) {

    const basicDatas = props.basicDatas;
    const orderData = props.orderData;

    const [showForm, setShowForm] = useState(false);

    const cancelOrder = () => {

        basicDatas.dialogManager({
            dialogTitle: "Annulation",
            dialogText: "Vous allez annuler cet ordre. Faut-il vraiment exécuter cette action ?",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui annuler"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    id:orderData.basic.id,
                    loaderText: "Annulation en cours..."
                }

                axiosApi.post('ctr/airdrops/cft-token/cancel-order.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        setShowForm(false);

                        setTimeout(() => {
                            window.location.reload();
                        }, 5000);
                    }
                })
            }
      
        })
    }

    if(!showForm) return (
        <Button color='inherit' sx={{ml:2}} variant='contained' onClick={() => setShowForm(!showForm)}>Annuler l'ordre</Button>
    )

    return (
        <div className='mt-4 mb-4'>

            <Button color='inherit' variant='contained' onClick={() => setShowForm(!showForm)}>
                Fermer le formulaire 
            </Button>

            <br /> <br />

            <Button color='info' variant='contained' fullWidth onClick={cancelOrder}>
                Confirmer l'annulation
            </Button>

        </div>
    )
}
