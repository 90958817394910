import { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import axiosApi from '../../functions/axiosApi';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function SetAvatar(props) {

  const basicDatas = props.basicDatas;
  const navigate = useNavigate()

  let default_crop = {
    unit: 'px',
    x: 40,
    y: 40,
    width: 100,
    height: 100,
    aspect: 1
  }

  const [img_src, set_img_src] = useState('');
  const [crop, set_crop] = useState();
  const [img_exist, set_img_exist] = useState(false);

  let cropChange = (new_crop) => {
    set_crop(new_crop);      
  } 

  let onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {

            var img = new Image();
            img.src = reader.result

            img.addEventListener('load', () => {

                set_img_src(reader.result);
                set_crop(default_crop)
                set_img_exist(true);

            })
        });
        reader.readAsDataURL(e.target.files[0]);
    }
  };

  let submitForm = (event) => {
    event.preventDefault();

    var react_jcrop_container = document.getElementById('react_jcrop_container');

    var img_dimensions = crop;
    img_dimensions.clientHeight = react_jcrop_container.clientHeight;
    img_dimensions = JSON.stringify(img_dimensions);

    var data = new FormData(event.target);
    data.append('img_dimensions', img_dimensions);
    data.append('loaderText', "Mise à jour de l'avatar en cours...");

    axiosApi.post("account/set_avatar.php", data)
    .then(response => {

      const responseData = response.data;

      if(responseData.status === 'success'){ 
          
        basicDatas.setAuthUser(responseData.auth_user);
        navigate("/account/");

      }
    })
  }

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="Set avatar modal"
    >

      <Box sx={style}>

        <form onSubmit={submitForm} encType="multipart/form-data">

          <Typography id="modal-modal-title" 
                      variant="h6" 
                      component="h2" 
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
            
            <Button type='button' color='inherit' onClick={(e) => e.p}>
              <label htmlFor="input_select_avatar_file" style={{cursor:'pointer'}}>
                {img_exist ? 'Changer' : 'Choisir'}</label>
            </Button>

            {
              img_exist &&
            
              <Button color='primary'>
                <label htmlFor="btn_submit_update_avatar_form" style={{cursor:'pointer'}}>Enregistrer</label>
              </Button>
            }

          </Typography>

          <br />

          {
            !img_exist ?

            <label htmlFor="input_select_avatar_file" 
                    style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        height:'150px',
                        fontSize:'20px',
                        cursor: 'pointer'
                    }}
                    title="Choisir une photo">

                <div style={{
                        display: 'inline-block',
                        border: "1px dashed gray",
                        padding:'30px',
                        borderRadius:'5px',
                        textAlign:'center',
                    }}> 
                    <InsertPhotoIcon />
                </div>
            </label>

            :

            <div id="react_jcrop_container" className="text_center" style={{maxHeight:'70%', overflow:'auto'}}>
          
              <ReactCrop  crop={crop}
                          minWidth={100}
                          minHeight={100}
                          keepSelection={true}
                          onChange={cropChange}
                          ruleOfThirds={true}
                          aspect={1}
                          style={{
                              width: '100%'
                          }} >
                  <img src={img_src} />
              </ReactCrop>

            </div>

          }

          <input  type="file" 
                  name="picture"
                  id="input_select_avatar_file" 
                  accept=".jpg,.jpeg,.png,.wav" 
                  onChange={onSelectFile}
                  style={{display:'none'}} />

          <input type="submit" className="d-none" id="btn_submit_update_avatar_form" />

        </form>
      </Box>

    </Modal>
  )
}
