import { useEffect, useState } from 'react'
import { Box, Skeleton, Typography } from '@mui/material';
import axiosApi from '../../../../functions/axiosApi';
import { cf_pay_info, cftTokenInfo, isPC, readabilityNumber } from '../../../../functions/basicFunctions';
import { basicProps } from '../../../../utils/interfaces';
import { useParams } from 'react-router-dom';
import QueryString from 'qs';

interface itemType {
    cfPayVolume: number,
    commissions: number,
    bonus: number
}

interface myDataType {
    today: itemType,
    month: itemType,
    allTime: itemType,
    cftBalance: number
}

export default function CfPayVolumes(props: basicProps) {

    const { user_id } = useParams();

    const [transactionVolumes, setTransactionVolumes] = useState<myDataType>()

    useEffect(() => {
        
        axiosApi.post('ctr/team-manager/cft-partners/get-transaction-volumes.php', QueryString.stringify({user_id}))
        .then(response => {

            if(response.data.status === 'success') {
                const responseData = response.data.data;
                setTransactionVolumes(responseData)
            }
        })

    }, [])
    
    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <Typography
                fontWeight='bold'
                variant='h5'
                textAlign='center'
                sx={{
                    mb: 5
                }}
            >
                Volumes de transactions du partenaire
            </Typography>

            <Box
                sx={{
                    p: 2,
                    borderRadius: 3,
                    boxShadow: 2,
                    mb: 7
                }}
            >

                <Typography fontWeight='bold'>
                    Solde CFT
                </Typography>

                <Typography variant='caption'>
                    Solde du compte { cf_pay_info.symbol }
                </Typography>

                <Typography
                    variant='h5'
                    sx={{
                        fontWeight: 'bold',
                        mt: 2,
                        color: 'var(--site-color)'
                    }}
                >

                    {
                        transactionVolumes
                        ?   readabilityNumber(transactionVolumes?.cftBalance) + " " + cftTokenInfo.symbol
                        : <Skeleton sx={{maxWidth: 150, p: 1}} />
                    }

                </Typography>

            </Box>






            <Box
                sx={{
                    p: 2,
                    borderRadius: 3,
                    boxShadow: 2,
                    mb: 7
                }}
            >

                <Typography variant='h5' sx={{mb: 2}}>
                    Aujourd'hui
                </Typography>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: isPC ? "repeat(2, 1fr)" : 'repeat(1, 1fr)',
                        gap: 3
                    }}
                >

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Volume transactions
                        </Typography>

                        <Typography variant='caption'>
                            C'est la valeur totale CFT échangée aujourd'hui
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >

                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.today.cfPayVolume) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }

                        </Typography>

                    </Box>

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Commissions
                        </Typography>

                        <Typography variant='caption'>
                            Quantité totale commissions générées aujourd'hui
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >
                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.today.commissions) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }
                        </Typography>
                        
                    </Box>

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Bonus
                        </Typography>

                        <Typography variant='caption'>
                            Quantité totale bonus reçue aujourd'hui
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >
                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.today.bonus) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }
                        </Typography>
                        
                    </Box>
                </Box>

            </Box>                




            <Box
                sx={{
                    p: 2,
                    borderRadius: 3,
                    boxShadow: 2,
                    mb: 7
                }}
            >

                <Typography variant='h5' sx={{mb: 2}}>
                    Ce mois
                </Typography>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: isPC ? "repeat(2, 1fr)" : 'repeat(1, 1fr)',
                        gap: 3
                    }}
                >

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Volume transactions
                        </Typography>

                        <Typography variant='caption'>
                            C'est la valeur totale CFT échangée ce mois
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >
                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.month.cfPayVolume) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }
                        </Typography>

                    </Box>

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Commissions
                        </Typography>

                        <Typography variant='caption'>
                            Quantité totale commissions générées ce mois
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >
                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.month.commissions) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }
                        </Typography>
                        
                    </Box>

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Bonus
                        </Typography>

                        <Typography variant='caption'>
                            Quantité totale bonus reçue ce mois
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >
                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.month.bonus) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }
                        </Typography>
                        
                    </Box>

                </Box>

            </Box>







            <Box
                sx={{
                    p: 2,
                    borderRadius: 3,
                    boxShadow: 2
                }}
            >

                <Typography variant='h5' sx={{mb: 2}}>
                    Tout le temps
                </Typography>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: isPC ? "repeat(2, 1fr)" : 'repeat(1, 1fr)',
                        gap: 3
                    }}
                >

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Volume transactions
                        </Typography>

                        <Typography variant='caption'>
                            C'est la valeur totale CFT échangée
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >
                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.allTime.cfPayVolume) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }
                        </Typography>

                    </Box>

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Commissions
                        </Typography>

                        <Typography variant='caption'>
                            Quantité totale commissions générées
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >
                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.allTime.commissions) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }
                        </Typography>
                        
                    </Box>

                    <Box 
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            boxShadow: 1
                        }}
                    >
                        <Typography fontWeight='bold'>
                            Bonus
                        </Typography>

                        <Typography variant='caption'>
                            Quantité totale bonus reçue
                        </Typography>

                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2,
                                color: 'var(--site-color)'
                            }}
                        >
                            {
                                transactionVolumes
                                ?   readabilityNumber(transactionVolumes?.allTime.bonus) + " " + cftTokenInfo.symbol
                                : <Skeleton sx={{maxWidth: 150, p: 1}} />
                            }
                        </Typography>
                        
                    </Box>

                </Box>

            </Box>

        </Box>
    )
}
