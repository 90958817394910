import { Box, Button, Typography } from '@mui/material'
import { publicPath } from '../../../functions/basicFunctions'
import { MetaTags } from 'react-meta-tags';

export default function BuyWithOMCmGuide() {
    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Achetez vos cryptos avec Orange Money Cameroun</title>
                <meta name="description" content="CRYPTO-FIAT | Achetez vos cryptos avec Orange Money Cameroun" />
                <meta property="og:title" content="CRYPTO-FIAT | Achetez vos cryptos avec Orange Money Cameroun" />
            </MetaTags>

            <Box 
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 5
                }}
            >

                <img 
                    src={publicPath + "images/orange-money.jpg"} 
                    alt="CRYPTO-FIAT"
                    style={{
                        width: '100%',
                        borderRadius: '5px'
                    }} />

            </Box>

            <Typography
                variant='h4'
                textAlign='center'
                sx={{
                    mb: 7
                }}
            >
                Achetez vos cryptos avec Orange Money Cameroun
            </Typography>

            <Typography variant='h6' sx={{opacity: 0.7, mb: 5}}>
                Vous souhaitez acheter vos cryptos depuis le Cameroun avec Orange money ? 
                <br />
                Vous êtes au bon endroit. Nous allons vous montrer comment procéder sur CRYPTO-FIAT et obtenir vos cryptos en un temps récord.

                <br /><br />

                Cliquez sur le bouton ci-dessous pour voir la procédure.
            </Typography>

            <a href="https://medium.com/@cryptofiat1022/comment-acheter-des-cryptomonnaies-avec-orange-money-cameroun-9d7ed8a3e3ae" target='_blank'>
                <Button variant='contained' sx={{p: 1.5}}>
                    Voir la procédure complète
                </Button>
            </a>
        </Box>
    )
}
