import { useState } from 'react'
import ImagesSelector from '../../../components/ImageSelector';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, TextField, Typography } from '@mui/material';
import { basicProps, orderType } from '../../../utils/interfaces';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { Link } from 'react-router-dom';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { orderAttachedMessagesType } from './Orders';
import OrderStatusLabel from '../../../components/OrderStatusLabel';

interface ConfirmOrderFormProps extends basicProps {
    orderData: orderType,
    orderAttachedMessages: orderAttachedMessagesType[]
}

export default function ConfirmOrderForm(props: ConfirmOrderFormProps) {

    const basicDatas = props.basicDatas;
    const orderData = props.orderData;
    const user = orderData.user;
    const orderAttachedMessages = props.orderAttachedMessages;

    const [showForm, setShowForm] = useState(false);

    const [images, setImages] = useState([]);
    const [links, setLinks] = useState("");
    const [txid, setTxid] = useState("");
    const [message, setMessage] = useState(`
        <b>Félicitations !</b> 
        <br/><br/>
        Votre commande a été validée avec succès. Nous vous remercions pour votre confiance. 
        <br/>
        Nous sommes impatients de continuer à travailler avec vous. Si vous avez des questions ou besoin d'assistance, n'hésitez pas à nous contacter.
    `);
    const [sponsorCommission, setSponsorCommission] = useState('');
    const [open, setOpen] = useState(false);
    const [sw, setSw] = useState('');
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmOrder = () => {

        basicDatas.dialogManager({
            dialogTitle: "Confirmation de l'ordre (" + orderData.basic.orderID + ")",
            dialogText: "Vous allez confirmer le règlement de cet ordre. Ceci implique que vous reconnaissez avoir reçu le paiement de l'utilisateur et transféré ses fonds. Cette action n'est pas reversible",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui valider"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    orderID:orderData.basic.orderID,
                    images,
                    txid,
                    links,
                    message,
                    sw,
                    sponsorCommission,
                    loaderText: "Validation en cours..."
                }

                axiosApi.post('ctr/orders/confirm-order.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        setMessage('');
                        setImages([]);
                        setLinks("");
                        setShowForm(false);

                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                })
            }
      
        })
    }

    if(!showForm) return (
        <Button color='primary' onClick={() => setShowForm(!showForm)}>Valider l'ordre</Button>
    )

    return (
        <div className='mt-4 mb-4'>

            <Button color='inherit' variant='contained' onClick={() => setShowForm(!showForm)}>
                Fermer le formulaire
            </Button>

            <br /> <br />

            <label htmlFor={"textarea-txid" + orderData.basic.orderID} style={{display:'block', marginBottom:'7px'}}>Ajouter des hash ou ID de transaction séparés par des point virgules</label>
            <TextareaAutosize minRows={3} 
                                style={{width:'100%'}}
                                id={"textarea-txid" + orderData.basic.orderID}
                                value={txid}
                                onChange={(e) => setTxid(e.target.value)}
                                placeholder="ID ou Hash de transaction séparés par des point virgules" />

            <br /> <br />

            <label htmlFor={"textarea-links" + orderData.basic.orderID} style={{display:'block', marginBottom:'7px'}}>Ajouter des liens de confirmations séparés par des point virgules</label>
            <TextareaAutosize minRows={3} 
                                style={{width:'100%'}}
                                id={"textarea-links" + orderData.basic.orderID}
                                value={links}
                                onChange={(e) => setLinks(e.target.value)}
                                placeholder="Liens de confirmations séparés par des point virgules" />

            <br /> <br />

            <Button
                startIcon={<StickyNote2Icon/>}
                onClick={handleClickOpen}
                sx={{
                    float: 'right'
                }}>
                Auto message
            </Button>

            <label htmlFor={"textarea-message" + orderData.basic.orderID} style={{display:'block', marginBottom:'7px'}}>Ajouter un message pour la confirmation de cette transaction</label>
            
            <TextareaAutosize minRows={3} 
                                style={{width:'100%'}}
                                id={"textarea-message" + orderData.basic.orderID}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Message associé à la confirmation" />

            <br /><br />

            <div style={{display:'block', marginBottom:'7px'}}>Ajouter des images</div>

            <div className='proof-form-images'>
                <ImagesSelector images={images} max={10} setImages={(items: any) => {setImages(items)}} />
            </div>

            <br />

            <TextField
                type='password'
                label="sw"
                onChange={(e) => setSw(e.target.value)}
            />

            <br />

            {
                user.sponsor &&

                <>
                    <br />

                    <TextField  type="number"
                                label="Commission du parrain (FCFA)"
                                margin='normal'
                                value={sponsorCommission}
                                onChange={(e) => setSponsorCommission(e.target.value)}
                                placeholder="Commission du parrain (FCFA)"
                                fullWidth />

                    Commision de { " " }

                    <Link to={'/ctr/users/' + user.sponsor.user_id + '/'} target='_blank' className='small'>
                        { user.sponsor.lastName + " " + user.sponsor.firstName}
                    </Link>
                </>
            }

            <br /> <br /> 

            <Button color='info' variant='contained' fullWidth onClick={confirmOrder}>
                Valider l'ordre
            </Button>

            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Messages automatiques</DialogTitle>

                <DialogContent sx={{minWidth: 300}}>
                    
                    {
                        orderAttachedMessages.map((itemMessage, i) => {

                            if(orderData.basic.status === itemMessage.status) 

                            return (
                                <Box
                                    key={i}
                                    >
                                    <Typography sx={{fontWeight: 'bold', fontSize: 'large', mb: 2}}>
                                        <OrderStatusLabel orderStatus={ itemMessage.status } />
                                    </Typography>

                                    {
                                        itemMessage.content.map((itemContent, j) => {

                                            if(itemContent.allowedFor === 'validate') return (
                                                <Box
                                                    key={j}
                                                    onClick={() => {setMessage(itemContent.message); handleClose()}}
                                                    sx={{
                                                        pb: 3,
                                                        mb: 3,
                                                        boxShadow: 2,
                                                        p: 2,
                                                        borderRadius: 1,
                                                        cursor: 'pointer'
                                                    }}>
                                                    
                                                    <Typography>
                                                        { itemContent.icon } { itemContent.title}
                                                    </Typography>

                                                    <Typography color="GrayText" fontSize='small'>
                                                        { itemContent.message }
                                                    </Typography>

                                                </Box>
                                            )

                                            return <></>
                                        })
                                    }
                                </Box>
                            )

                            return <></>
                        })
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>

        </div>
    )
}
