import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { isPC } from '../functions/basicFunctions';
import { basicProps, newsLetterType } from '../utils/interfaces';
import Carousel from './Carousel'
import Text from './Text';

interface PreviewNewsProps extends basicProps {
    post: newsLetterType,
    maxLength?: number
}

export default function PreviewNews(props: PreviewNewsProps) {

    const basicDatas = props.basicDatas;
    const post = props.post;
    const maxLength = props.maxLength;

    return (
        <ListItem 
                alignItems="flex-start"
                >

            <ListItemAvatar>
                <Avatar alt={ post.user.firstName + " " +  post.user.lastName } src={ post.user.avatarUrl } sx={{boxShadow:1}} />
            </ListItemAvatar>

            <ListItemText
            primary={ <span style={{fontWeight: 'bold', opacity: 0.8 }}>{ post.user.firstName + " " +  post.user.lastName }</span> }
            secondary={
                <>
                    <Typography
                        component="h3"
                        color="text.primary"
                        sx={{mb: 1, mt: 1, fontWeight: 'bold', opacity: 0.7}}
                    >
                        { post.title }
                    </Typography>

                    {
                        post.attachedImages && 

                        <Box component='div'
                            sx={{
                                mt: 2,
                                mb: 2
                            }}>
                            <Carousel   items_per_page={1}  
                                        allowFullScreen={false}
                                        duration={ 10000 }
                                        height={ isPC ? "300px" : '200px'}
                                        items = {   post.attachedImages.map((image, index) => {
                                                        return (
                                                            <Box 
                                                                component='div'
                                                                key={index}
                                                                overflow='auto'
                                                                sx={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    backgroundImage: 'url("' + image.url + '")',
                                                                    backgroundPosition: 'center',
                                                                    backgroundSize: 'contain',
                                                                    backgroundRepeat: 'no-repeat'
                                                                }}
                                                                />
                                                        )
                                                    })
                                                } />
                        </Box>
                    } 

                    <Text text={ post.message } 
                            basicDatas={basicDatas}
                            maxLength={ maxLength ? maxLength : isPC ? 500 : 250} />

                    <Typography
                        component="div"
                        variant="body2"
                        sx={{
                            fontSize: 'small',
                            opacity: 0.7,
                            mt: 1
                        }}
                    >
                        { post.date }
                    </Typography>
                </>
            }
            />
        </ListItem>
    )
}
