import { Box, Typography } from '@mui/material'
import { isPC } from '../../../functions/basicFunctions'
import { MetaTags } from 'react-meta-tags';

export default function OtpHelp() {
    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: 'auto',
                pt: 5
            }}
        >

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Aide Code OTP</title>
                <meta name="description" content="CRYPTO-FIAT | Aide Code OTP" />
                <meta property="og:title" content="CRYPTO-FIAT | Aide Code OTP" />
            </MetaTags>
            
            <Typography variant={isPC ? 'h4' : 'h5'} sx={{mb: 3, textAlign: 'center'}}>
                Code OTP
            </Typography>

            <p style={{color: "#555", fontWeight: 'bold'}}>
                Vous ne recevez pas de code OTP pour passer une vérification de sécurité, ou celui reçu ne fonctionne pas ?
                <br />
                Suivez les conseils suivants :
            </p>

            <ul>
                <li>
                    Actualisez la page avant de demander un nouveau code.
                    <br /><br />
                </li>

                <li>
                    Si vous ne trouvez pas le code dans votre dossier de réception, vérifiez également dans les SPAMS.
                    <br /><br />
                </li>

                <li>
                    Si le code reçu ne fonctionne pas, effacez tous les mails OTP de CRYPTO-FIAT et demandez un nouveau code. Assurez-vous ensuite de copier le code le plus recent.
                </li>
            </ul>

        </Box>
    )
}
