import React from 'react'
import { basicProps } from '../../../utils/interfaces'
import { Link, Route, Routes } from 'react-router-dom'
import { Box } from '@mui/material';
import DepositFunds from './deposit/DepositFunds'
import CFPAYtransactions from './transactions/CFPAYtransactions';
import { cf_pay_info } from '../../../functions/basicFunctions';
import CFPAYstats from './sfpay-stats/CFPAYstats';
import CfpayFeesManager from './cfpay-fees-comissions-manager/CfpayFeesCommissionsManager';
import Farming from './farming/Farming';
import FreezeAssets from './freeze-assets/FreezeAssets';

export default function Finances(props: basicProps) {
    return (
        <div>
            <Routes>

                <Route path='/' element={
                    <Box sx={{textAlign: 'center'}}>
                        <br /><br />

                        <Link to="/ctr/finances/farming/" className='link' style={{fontSize:'18px'}}>
                            Farming
                        </Link>
                        <br /><br />

                        <Link to="/ctr/finances/cfpay-stats/" className='link' style={{fontSize:'18px'}}>
                            Stats { cf_pay_info.symbol }
                        </Link>
                        <br /><br />

                        <Link to="/ctr/finances/deposit-funds/" className='link' style={{fontSize:'18px'}}>
                            Dépôt { cf_pay_info.symbol }
                        </Link>
                        <br /><br />

                        <Link to="/ctr/finances/cfpay-transactions/" className='link' style={{fontSize:'18px'}}>
                            Transactions { cf_pay_info.symbol }
                        </Link>
                        <br /><br />

                        <Link to="/ctr/finances/freeze-assets/" className='link' style={{fontSize:'18px'}}>
                            Géler/Débloquer les actifs
                        </Link>
                        <br /><br />

                        <Link to="/ctr/finances/cfpay-fees-commissions-manager/" className='link' style={{fontSize:'18px'}}>
                            Gestion frais/commissions { cf_pay_info.symbol }
                        </Link>
                        <br /><br />
                    </Box>
                    } />

                <Route path='/deposit-funds' element={<DepositFunds {...props} />} />

                <Route path='/cfpay-transactions' element={<CFPAYtransactions {...props} />} />

                <Route path='/cfpay-fees-commissions-manager' element={<CfpayFeesManager {...props} />} />

                <Route path='/cfpay-stats' element={<CFPAYstats {...props} />} />

                <Route path='/farming' element={<Farming {...props} />} />

                <Route path='/freeze-assets/*' element={<FreezeAssets {...props} />} />

            </Routes>
        </div>
    )
}
