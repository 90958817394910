import { Box} from '@mui/material'
import { basicProps} from '../../../../utils/interfaces';
import { Route, Routes } from 'react-router-dom'
import ListPartners from './ListPartners';
import Partner from './Partner';
import CfPayVolumes from './CfPayVolumes';

interface myProps extends basicProps {
    sw: string
}

export default function CftPartners(props: myProps) {

    const basicDatas = props.basicDatas;
    
    return (
        <Box>

            <Routes>
                <Route path='/' element={<ListPartners basicDatas={basicDatas} />} />
                <Route path='/:user_id' element={<Partner {...props} />} />
                <Route path='/:user_id/cf-pay-volumes' element={<CfPayVolumes {...props} />} />
            </Routes>

        </Box>
    )
}
