import { useState } from 'react'
import { Button, TextareaAutosize, TextField } from '@mui/material';
import QueryString from 'qs';
import { basicProps } from '../../../../utils/interfaces';
import axiosApi from '../../../../functions/axiosApi';
import { cftTokenInfo } from '../../../../functions/basicFunctions';
import { toast } from 'react-toastify';

interface ConfirmOrderFormProps extends basicProps {
    orderData: any
}

export default function ConfirmOrderForm(props: ConfirmOrderFormProps) {

    const basicDatas = props.basicDatas;
    const orderData = props.orderData;

    const [showForm, setShowForm] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");

    const confirmOrder = () => {

        if(transactionHash === ''){
            toast.error("Veuillez entrer le hash de transaction.");
            return;
        }

        basicDatas.dialogManager({
            dialogTitle: "Confirmation",
            dialogText: "Vous allez confirmer le règlement de cet ordre. Ceci implique que vous avez transféré les " + cftTokenInfo.symbol + " à l'utilisateur.",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui confirmer"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    id: orderData.basic.id,
                    transactionHash,
                    loaderText: "Validation en cours..."
                }

                axiosApi.post('ctr/airdrops/cft-token/confirm-claim-order.php', QueryString.stringify(data))
                .then(axiosResponse => {
                    if(axiosResponse.data.status === 'success'){
                        setTransactionHash("");
                        setShowForm(false);

                        setTimeout(() => {
                            window.location.reload();
                        }, 5000);
                    }
                })
            }
      
        })
    }

    if(!showForm) return (
        <Button color='inherit' variant='contained' onClick={() => setShowForm(!showForm)}>Valider pour confirmer</Button>
    )

    return (
        <div className='mt-4 mb-4'>

            <Button color='inherit' variant='contained' onClick={() => setShowForm(!showForm)}>
                Fermer le formulaire
            </Button>

            <br /> <br />

            <label htmlFor={"textarea-transaction-hash" + orderData.basic.orderID} style={{display:'block', marginBottom:'7px'}}>Hash de transaction</label>
            <TextareaAutosize minRows={3} 
                                style={{width:'100%'}}
                                id={"textarea-transaction-hash" + orderData.basic.orderID}
                                value={transactionHash}
                                onChange={(e) => setTransactionHash(e.target.value)}
                                placeholder="Hash de transaction"
                                required />

            <Button color='info' variant='contained' fullWidth onClick={confirmOrder} sx={{mt:2}}>
                Confirmer
            </Button>

        </div>
    )
}
