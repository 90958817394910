import { useEffect, useState } from 'react'
import { basicProps } from '../utils/interfaces'
import { Alert, AlertTitle, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material'
import axiosApi from '../functions/axiosApi'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import QueryString from 'qs';
import { MetaTags } from 'react-meta-tags';
import { toast } from 'react-toastify';

export default function ResetPinCode(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [pinExist, setPinExist] = useState(false);

    const [emailIsVerified, setEmailIsVerified] = useState(false);
    const [open, setOpen] = useState(false);
    const [emailCode, setEmailCode] = useState('');
    const [forgottenPin, setForgottenPin] = useState('0');

    useEffect(() => {
        
        axiosApi.post("auth/pin-code/pin-exist.php")
        .then(response => {

            if(response.data.status === 'success'){
                setPinExist(response.data.pinExist)
            }
        })

    }, [])

    useEffect(() => {
        
        if(forgottenPin === '1') {
            toast.info("Puisque vous avez oublié l'ancien PIN, vous recevrez par mail un code à 5 caractères pour vérifier votre nouveau code PIN.");
        }

    }, [forgottenPin])

    const handleOpenConfirmMailModal = () => setOpen(true);
    const handleCloseConfirmMailModal = () => setOpen(false);

    let verifyEmailCode = () => {

        axiosApi.post('auth/verify_email_code.php', QueryString.stringify({
            emailAddress: basicDatas.authUser.email, 
            emailCode,
            loaderText: 'Vérification email en cours...'
        }))
        .then(response => {
        
            if(response.data.status === "success") {
                setEmailIsVerified(true);
                handleCloseConfirmMailModal();

                const btn_submit_form = document.getElementById('btn_submit_form');
                if(btn_submit_form){
                    btn_submit_form.click();
                }
            }

        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('forgottenPin', forgottenPin + '');
        data.append('loaderText', emailIsVerified ? 'Modification du code PIN en cours...' : "Envoi du code en cours...");

        axiosApi.post('auth/pin-code/reset-pin-code.php', data)
        .then(response => {

            if(response.data.action === 'display_confirm_mail_modal'){
                handleOpenConfirmMailModal();
                if(forgottenPin === '1') setForgottenPin('2');
            }
        })

    }
    
    return (
        <Box 
            sx={{
                marginTop: window.screen.width > 700 ? 8 : 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: 400,
                margin: 'auto'
            }}
        >

            <MetaTags id="login">
                <title>Code PIN</title>
                <meta name="description" content={basicDatas.appName + " - Code PIN"} />
                <meta property="og:title" content={basicDatas.appName + " - Code PIN"} />
            </MetaTags>

            <Avatar sx={{ mb: 2, mt: 7, bgcolor: 'primary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            
            <Typography 
                    variant='h5'
                    sx={{
                        textAlign: 'center'
                    }}>
                Code PIN
            </Typography>

            <Typography variant='caption' sx={{textAlign: 'center', display: 'block', mb: 5}}>
                Votre code PIN vous permet de valider des transferts internes
            </Typography>

            <Box
                component='form'
                onSubmit={handleSubmit}>

                {
                    (pinExist && (forgottenPin === '0')) &&

                    <>

                        <TextField 
                            type='password'
                            name='currentPinCode'
                            label="Code PIN actuel"
                            placeholder='Code actuel'
                            fullWidth
                            required
                            sx={{
                                mb: 0.5
                            }} />

                        <Typography
                            onClick={() => setForgottenPin('1')}
                            sx={{
                                textAlign: 'right',
                                color: 'var(--site-color)',
                                fontSize: 'small',
                                mb: 2,
                                cursor: 'pointer'
                            }}
                        >
                            J'ai oublié l'ancien code
                        </Typography>

                    </>
                }

                
                {
                    (forgottenPin === '1') &&

                    <Alert 
                        severity="info" 
                        sx={{
                            mb: 3, 
                            textAlign:'left'
                            }}
                        >
                        <AlertTitle style={{fontWeight:'bold'}}>Veuillez noter</AlertTitle>
                        Puisque vous avez oublié l'ancien PIN, vous recevrez par mail un code à 5 caractères pour vérifier votre nouveau code PIN. 
                    </Alert>
                }

                <TextField 
                        type='password'
                        name='newPinCode'
                        label="Nouveau code PIN"
                        placeholder='4 chiffres'
                        fullWidth
                        required
                        sx={{
                            mb: 2
                        }} />

                <TextField 
                        type='password'
                        name='confirmPinCode'
                        label="Confirmer le code PIN"
                        placeholder='4 chiffres'
                        fullWidth
                        required
                        sx={{
                            mb: 2.5
                        }} />

                <Button
                    type='submit'
                    variant='contained'
                    fullWidth
                    color='info'
                    id='btn_submit_form'>
                    Valider
                </Button>
            </Box>

            <Dialog open={open}>

                <DialogTitle>Vérification de sécurité</DialogTitle>
                
                <DialogContent>
                    <DialogContentText style={{fontSize:'small'}}>
                    Nous vous avons envoyé un code à 5 (cinq) caractères à l'adresse <span style={{fontWeight:'bold'}}>{ basicDatas.authUser.email }</span> . <br />
                    Veuillez entrer ce code pour continuer.
                    </DialogContentText>

                    <br />

                    <TextField
                    autoFocus
                    onChange={(e) => setEmailCode(e.target.value)}
                    margin="dense"
                    id="email_code"
                    label="Code de confirmation"
                    type="text"
                    fullWidth
                    variant="standard"
                    />
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={handleCloseConfirmMailModal}>Annuler</Button>
                    <Button onClick={verifyEmailCode}>Vérifier</Button>
                </DialogActions>

            </Dialog>

        </Box>
    )
}
