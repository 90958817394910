import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axiosApi from '../functions/axiosApi';
import GoogleIcon from '@mui/icons-material/Google';
import { Divider, Box, Grid, Typography, TextField, Button, Avatar } from '@mui/material';
import QueryString from 'qs';
import { MetaTags } from 'react-meta-tags';
import { basicProps } from '../utils/interfaces';


export default function Login(props:  basicProps) {

  const basicDatas = props.basicDatas;
  const navigate = useNavigate();

  const googlePrompt = () => {
    const myWindow = window as any;
    if(myWindow.google) {
      const google = myWindow.google;
      google.accounts.id.prompt();
    }

  }

  const signWidthGoogle = (googleResponse) => {

    const credential = googleResponse.detail.credential;
    
    axiosApi.post('auth/signin_with_google.php', QueryString.stringify({
      credential,
      loaderText: "Connexion avec Google..."
    })).then(response => {
      if(response.data.status === 'success'){
        basicDatas.setIsLoggedIn(true);
        basicDatas.setAuthUser(response.data.user);
        navigate("/trade/buy/");
      }
    })
  }

  useEffect(() => {
    window.addEventListener('user_sign_with_google', signWidthGoogle)
    return () => {
      window.removeEventListener('user_sign_with_google', signWidthGoogle)
    }
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    data.append('loaderText', 'Connexion en cours...');

    axiosApi.post('auth/login.php', data)
    .then(response => {
      
      if(response.data.status === 'success'){
        basicDatas.setAuthUser(response.data.auth_user);
        basicDatas.setIsLoggedIn(true);
        navigate("/trade/buy/");
      }

    })
  };

  return (

      <div style={{maxWidth:'400px', margin:'auto'}}>

        <MetaTags id="login">
            <title>Se connecter</title>
            <meta name="description" content={basicDatas.appName + " - Se connecter"} />
            <meta property="og:title" content={basicDatas.appName + " - Se connecter"} />
        </MetaTags>
      
        <Box
          sx={{
            marginTop: window.screen.width > 700 ? 8 : 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5" sx={{mb:6}}>
            Se connecter
          </Typography>

          <Button onClick={googlePrompt}
                  variant="contained"
                  color='error'
                  fullWidth
                  startIcon={<GoogleIcon fontSize='small' />}>
            Connexion avec Google
          </Button>

          <Divider sx={{mt:3, mb:1, width:'100%'}}>Ou avec une adresse mail</Divider>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse E-mail"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{mb:2}}
            />

            <Grid container justifyContent="flex-end">
              <Grid item className='small'>
                En utilisant nos services, vous acceptez nos <a href="/terms/cgu/" target='_blank'>Conditions Générales d'Utilisation (CGU)</a>.
              </Grid>
            </Grid>
           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Connexion
            </Button>

            <Grid container>
              <Grid item xs>
                <Link to="/auth/reset-password/">
                  Mot de passe oublié?
                </Link>
              </Grid>
              <Grid item>
                Pas de compte? <Link to="/auth/signup/">Créez en un</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

    </div>
   
  );
}