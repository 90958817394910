import { useEffect, useState } from 'react'
import { basicProps } from '../../../utils/interfaces'
import { Link, useParams } from 'react-router-dom';
import axiosApi from '../../../functions/axiosApi';
import { kycDemandType } from './WaitingDemands';
import QueryString from 'qs';
import { 
    Box, Typography, Avatar, TableContainer, Table, TableBody,
    TableRow, Paper, Alert, AlertTitle
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { copyClipboard } from '../../../functions/basicFunctions';
import KycCompletionForm from './KycCompletionForm';
import VerifyDocumentID from './VerifyDocumentID';
import MessageIcon from '@mui/icons-material/Message';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function ItemDemand(props: basicProps) {

    const { user_id } = useParams();

    const [demand, setDemand] = useState<kycDemandType>()
    const userInfos = demand?.userInfos;

    useEffect(() => {

        axiosApi.post("ctr/kyc/get-item-demand.php", QueryString.stringify({user_id}))
        .then(response => {
            
            if(response.data.status === 'success') {
                setDemand(response.data.demand)
            }
        })

    }, [user_id])

    if(!userInfos) return (
        <Box sx={{mt: 5}}>
            <Typography sx={{textAlign: 'center'}}>
                Chargement...
            </Typography>
        </Box>
    )

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto',
                pt: 5
            }}>
            
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Avatar src={userInfos.avatarUrl} sx={{width:'150px', height:'150px'}} />
            </div>
            
            <Typography variant='h5' sx={{textAlign:'center', mt:3, mb:1}}>
                {userInfos.firstName + " " + userInfos.lastName}
            </Typography>

            <Typography variant='h5' sx={{textAlign:'center', mb:4, fontWeight: 'bold'}}>
                {userInfos.vip_level}
            </Typography>

            <VerifyDocumentID demand={demand} />

            {
                (((userInfos.vip_level === 'vip0') || (userInfos.vip_level === 'vip00')) && demand.message && (demand.message.length > 0)) &&

                <Box
                    sx={{
                        p: 2,
                        mb: 5,
                        boxShadow: 2,
                        borderRadius: 3
                    }}
                >

                    <Typography variant='h5' sx={{mb: 3}}>
                        Dernière raison d'échec
                    </Typography>
    
                    <Alert icon={ <MessageIcon /> } severity="error" sx={{mb: 2, textAlign:'left', mt:2, boxShadow: 2}} {...props}>
                        <AlertTitle sx={{fontWeight: 'bold'}}>Raison d'échec </AlertTitle>
                        <div dangerouslySetInnerHTML={{__html: demand.message}} />
                    </Alert>

                </Box>
            }
            

            <Box
                sx={{
                    p: 2,
                    mb: 5,
                    boxShadow: 2,
                    borderRadius: 3
                }}>

                <Typography variant='h5'>
                    Informations de base 

                    <Typography variant='caption'>
                        <Link to={'/ctr/users/' + userInfos.user_id + "/"} target='_blank' style={{marginLeft: 20}}>
                            Infos
                        </Link>
                    </Typography>
                </Typography>

                <TableContainer component={Paper}  sx={{mt:4}}>
                    <Table aria-label="customized table">

                        <TableBody>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    ID compte
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => copyClipboard(userInfos.user_id, "Copie !")}
                                    >
                                    <b>{ userInfos.user_id }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Nom de famille
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b>{ userInfos.lastName }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Prénom
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b>{ userInfos.firstName }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Date naissance
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b>{ demand.birthday }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Type document
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b>{ demand.documentType }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Numéro { demand.documentType }
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b onClick={() => copyClipboard(demand.documentID, "Copie !")} style={{cursor: 'pointer'}}>{ demand.documentID }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Expiration { demand.documentType }
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b>{ demand.expirationDate }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Localisation IP
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b>{ demand.ipCountry }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Date demande
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b>{ demand.date }</b>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell scope="row">
                                    Last Update
                                </StyledTableCell>
                                <StyledTableCell 
                                    align="left"
                                    >
                                    <b>{ demand.lastUpdate }</b>
                                </StyledTableCell>
                            </StyledTableRow>
                        
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

            <Box
                sx={{
                    p: 2,
                    mb: 5,
                    boxShadow: 2,
                    borderRadius: 3
                }}>

                <Typography variant='h5' sx={{mb: 3}}>
                    Documents : <b>{ demand.documentType }</b>
                </Typography>

                {
                    demand.documentType === 'CNI' &&

                    <Box>

                        <img 
                            src={demand.frontCard} 
                            alt="CNI : recto" 
                            width={'100%'}
                            style={{cursor: 'pointer'}}
                            onClick={() => window.open(demand.frontCard)} 
                            />

                        <br /><br />

                        <img 
                            src={demand.backsideCard} 
                            alt="CNI : verso" 
                            width={'100%'}
                            style={{cursor: 'pointer'}}
                            onClick={() => window.open(demand.backsideCard)} 
                            />
                    </Box>
                }

                {
                    demand.documentType === 'PERMIS_CONDUIRE' &&

                    <Box>

                        <img 
                            src={demand.frontDriverLicence} 
                            alt="CNI : recto" 
                            width={'100%'}
                            style={{cursor: 'pointer'}}
                            onClick={() => window.open(demand.frontDriverLicence)} 
                            />

                        <br /><br />

                        <img 
                            src={demand.backsideDiverLicence} 
                            alt="CNI : verso" 
                            width={'100%'}
                            style={{cursor: 'pointer'}}
                            onClick={() => window.open(demand.backsideDiverLicence)} 
                            />
                    </Box>
                }

                {
                    demand.documentType === 'PASSPORT' &&

                    <Box>

                        <img 
                            src={demand.passportImg} 
                            alt="Passport" 
                            width={'100%'}
                            style={{cursor: 'pointer'}}
                            onClick={() => window.open(demand.passportImg)} 
                            />

                    </Box>
                }
                

                <br /><br />

                <img 
                    src={demand.selfie} 
                    alt="selfie" 
                    width={'100%'}
                    style={{cursor: 'pointer'}}
                    onClick={() => window.open(demand.selfie)} 
                    />

            </Box>


            <Box
                sx={{
                    p: 2,
                    boxShadow: 2,
                    borderRadius: 3
                }}>

                <Typography variant='h5' sx={{mb: 3}}>
                    Compléter les informations
                </Typography>

                <KycCompletionForm demand={demand} {...props} />

            </Box>

        </Box>
    )
}
