import { useEffect, useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { 
    Box, Table, TableBody, TableContainer, Typography, 
    Paper, Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Button, TableRow,
    Avatar,
    TextareaAutosize
} from '@mui/material';
import { basicProps, investPlanType } from '../../../utils/interfaces';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { cf_pay_info, copyClipboard, copyPhoneNumber, readabilityNumber } from '../../../functions/basicFunctions';
import ImagesSelector from '../../../components/ImageSelector';
import QueryString from 'qs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

interface myProps extends basicProps {
    investPlanData: investPlanType
}

export default function MakePayment(props: myProps) {

    const basicDatas = props.basicDatas;
    const investPlanData = props.investPlanData;
    const paymentAddress = investPlanData.paymentAddress;
    const user = investPlanData.user;
 
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [links, setLinks] = useState("");
    const [txid, setTxid] = useState("");
    const [sw, setSw] = useState('')

    const totalWithterest = (parseFloat(investPlanData.amount + '') * parseFloat(investPlanData.interest_rate + '') / 100) + parseFloat(investPlanData.amount + '');
    let amountToPay = totalWithterest / parseInt(investPlanData.num_months + '');

    useEffect(() => {

       
    }, [open])

    const handleClose = () => {
        setOpen(false);
    };
    

    const confirmPayment = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        basicDatas.dialogManager({
            dialogTitle: 'Confirmer le paiement',
            dialogText: "Souhaitez-vous confirmer le paiement ?",
            dialogAgreeBtnColor: 'info',
            dalogCloseBtnText: 'Non',
            dialogAgreeBtnText: 'Confirmer'
            }, (response) => {
            
            if(response === 'ok') {

                const data = {
                    investPlanID: investPlanData.planID,
                    amount: amountToPay,
                    paymentMethod: investPlanData.paymentAddress?.paymentMethod.symbol,
                    images,
                    txid,
                    links,
                    sw,
                    loaderText: "Traitement en cours..."
                }

                axiosApi.post("ctr/liquidity/confirm-payment.php", QueryString.stringify(data))
                .then(response => {

                    if(response.data.status === 'success') {
                        setOpen(false);
                    }
                })
            }
        
        })
        
    }

    return (
        <Box>
            
            <Button 
                variant='contained' 
                color='inherit' 
                disabled={(investPlanData.status === 'canceled') || (investPlanData.status === 'completed')}
                onClick={() => setOpen(true)}
                sx={{
                    mt: 5,
                    mb: 5
                }}
            >
                Effectuer un paiement
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Moyen de paiemnt</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Box    
                        component='form'
                        onSubmit={confirmPayment}
                        sx={{
                            width: '500px'
                        }}
                    >

                        <TableContainer component={Paper}  sx={{mt:4}}>

                            <Table aria-label="customized table">

                                <TableBody>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Montant fourni
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                            { readabilityNumber(investPlanData.amount) + ' XAF' }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Nomnre de mois
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                            { investPlanData.num_months }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Taux intérêt
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                            { investPlanData.interest_rate + '%' }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Montant à payer
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{fontWeight: 'bold'}} onClick={() => copyClipboard(investPlanData.planID, 'Copie !')}>
                                            <b>{ readabilityNumber(amountToPay) + ' XAF' }</b>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                
                                </TableBody>
                            </Table>

                        </TableContainer>

                        <br /><br />

                        <Typography
                            fontWeight='bold'
                            variant='h4'
                            textAlign='center'
                            color='primary'
                            sx={{
                                mb: 3
                            }}
                        >
                            { readabilityNumber(amountToPay) + ' XAF' }
                        </Typography>

                        <Typography
                            variant='h5'
                        >
                            Adresse de paiement
                        </Typography>

                        {
                            !paymentAddress 

                            ?

                            <Typography variant='caption' color='error'>
                                Aucun moyen de paiement disponible
                            </Typography>

                            :

                            <>

                                <TableContainer component={Paper}  sx={{mt:4}}>

                                    <Table aria-label="customized table">

                                        <TableBody>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Moyen de payement
                                                </StyledTableCell>
                                                <StyledTableCell align="left">

                                                    <table>
                                                        <td>
                                                            <Avatar 
                                                                src={paymentAddress?.paymentMethod.logo} 
                                                                sx={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    mr: 1
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{verticalAlign: 'middle'}}>
                                                            { paymentAddress?.paymentMethod.symbol + " (" + paymentAddress?.paymentMethod.name + ")" }
                                                        </td>
                                                    </table>
                                                    
                                                    
                                                </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Numéro de compte
                                                </StyledTableCell>
                                                <StyledTableCell 
                                                    align="left" 
                                                    sx={{fontWeight: 'bold', cursor: 'pointer'}}
                                                    onClick={() => {
                                                        if(paymentAddress.paymentMethod.symbol === cf_pay_info.symbol) {
                                                            copyClipboard(user.user_id, "ID utilisateur copié !");
                                                        }

                                                        else copyPhoneNumber(paymentAddress?.paymentMethodNumber)
                                                    }}
                                                >
                                                    { paymentAddress.paymentMethod.symbol === cf_pay_info.symbol ? user.user_id : paymentAddress.paymentMethodNumber }
                                                </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Nom du compte
                                                </StyledTableCell>
                                                <StyledTableCell 
                                                    align="left" 
                                                    sx={{fontWeight: 'bold'}}
                                                >
                                                    { paymentAddress?.accountOwnerName }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        
                                        </TableBody>
                                    </Table>

                                </TableContainer>

                                <br /><br />

                                <label htmlFor={"textarea-txid"} style={{display:'block', marginBottom:'7px'}}>Ajouter des hash ou ID de transaction séparés par des point virgules</label>
                                <TextareaAutosize minRows={3} 
                                                    style={{width:'100%'}}
                                                    id={"textarea-txid"}
                                                    value={txid}
                                                    onChange={(e) => setTxid(e.target.value)}
                                                    placeholder="ID ou Hash de transaction séparés par des point virgules" />

                                <br /> <br />

                                <label htmlFor={"textarea-links"} style={{display:'block', marginBottom:'7px'}}>Ajouter des liens de confirmations séparés par des point virgules</label>
                                <TextareaAutosize minRows={3} 
                                                    style={{width:'100%'}}
                                                    id={"textarea-links"}
                                                    value={links}
                                                    onChange={(e) => setLinks(e.target.value)}
                                                    placeholder="Liens de confirmations séparés par des point virgules" />

                                <br /> <br />

                                <div style={{display:'block', marginBottom:'7px'}}>Ajouter des images</div>

                                <div className='proof-form-images'>
                                    <ImagesSelector images={images} max={10} setImages={(items: any) => {setImages(items)}} />
                                </div>

                                <br /><br />

                                <TextField
                                    type='password'
                                    name='sw'
                                    label="sw"
                                    value={sw}
                                    onChange={(e) => setSw(e.target.value)}
                                    required 
                                    fullWidth
                                />

                                <Button variant='contained'
                                        type='submit'
                                        color='info'
                                        fullWidth
                                        sx={{grid: 1, mt: 3}}
                                        >
                                    Confirmer le paiement
                                </Button>
                            </>
                        }

                    </Box>
                        
                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}


