import { useState } from 'react'
import ImagesSelector from '../../../components/ImageSelector';
import { Button, TextareaAutosize, TextField, Typography } from '@mui/material';
import { basicProps, orderType } from '../../../utils/interfaces';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { orderAttachedMessagesType } from './Orders';

interface myProps extends basicProps {
    orderData: orderType,
    orderAttachedMessages: orderAttachedMessagesType[]
}

export default function AddPaimentProofForm(props: myProps) {

    const basicDatas = props.basicDatas;
    const orderData = props.orderData;

    const [showForm, setShowForm] = useState(false);

    const [images, setImages] = useState([]);
    const [links, setLinks] = useState("");
    const [txid, setTxid] = useState("");
    const [sw, setSw] = useState('');


    const setProofs = () => {

        basicDatas.dialogManager({
            dialogTitle: "Modifier les preuves : " + orderData.basic.orderID,
            dialogText: "Vous allez modifier les preuves de cet ordre",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Modifier"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    orderID:orderData.basic.orderID,
                    images,
                    txid,
                    links,
                    sw,
                    loaderText: "Modification en cours..."
                }

                axiosApi.post('ctr/orders/set-paiment-proofs.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        setImages([]);
                        setLinks("");
                        setShowForm(false);

                        setTimeout(() => {
                            window.location.reload();
                        }, 5000);
                    }
                })
            }
      
        })
    }

    if(!showForm) return (
        <Button color='inherit' onClick={() => setShowForm(!showForm)}>Modifier les preuves</Button>
    )

    return (
        <div className='mt-4 mb-4'>

            <Button color='inherit' variant='contained' onClick={() => setShowForm(!showForm)}>
                Fermer le formulaire
            </Button>

            <br /> <br />

            <label htmlFor={"textarea-txid" + orderData.basic.orderID} style={{display:'block', marginBottom:'7px'}}>Ajouter des hash ou ID de transaction séparés par des point virgules</label>
            <TextareaAutosize minRows={3} 
                                style={{width:'100%'}}
                                id={"textarea-txid" + orderData.basic.orderID}
                                value={txid}
                                onChange={(e) => setTxid(e.target.value)}
                                placeholder="ID ou Hash de transaction séparés par des point virgules" />

            <br /> <br />

            <label htmlFor={"textarea-links" + orderData.basic.orderID} style={{display:'block', marginBottom:'7px'}}>Ajouter des liens de confirmations séparés par des point virgules</label>
            <TextareaAutosize minRows={3} 
                                style={{width:'100%'}}
                                id={"textarea-links" + orderData.basic.orderID}
                                value={links}
                                onChange={(e) => setLinks(e.target.value)}
                                placeholder="Liens de confirmations séparés par des point virgules" />

            <br /> <br />

            <div style={{display:'block', marginBottom:'7px'}}>Ajouter des images</div>

            <div className='proof-form-images'>
                <ImagesSelector images={images} max={10} setImages={(items: any) => {setImages(items)}} />
            </div>

            <br />

            <TextField
                type='password'
                label="sw"
                onChange={(e) => setSw(e.target.value)}
            />

            <br /><br />

            <Button color='info' variant='contained' fullWidth onClick={setProofs}>
                Modifier les preuves
            </Button>

        </div>
    )
}
