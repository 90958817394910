import { useState } from 'react'
import { basicProps } from '../../../../../utils/interfaces'
import { Box, Button, Typography, Checkbox} from '@mui/material'
import axiosApi from '../../../../../functions/axiosApi'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import QueryString from 'qs';

const contactCategories: {category: string, title: string, description: string}[] = [
    {
        category: 'all_users',
        title: 'Tous les utilisateurs',
        description: "Charger les contacts mails de tous les utilisateurs inscrits."
    },

    {
        category: 'kyc_verified',
        title: 'Utilisateurs vérifiés',
        description: "Charger les contacts mails de tous les utilisateurs dont le KYC a été validé."
    },

    {
        category: 'kyc_unverified',
        title: "Utilisateurs non vérifiés",
        description: "Charger les contacts mails de tous les utilisateurs qui n'on pas passé le KYC."
    },

    {
        category: 'clean_file',
        title: "Nétoyer le fichier",
        description: "Supprimer le contenu du fichier de stockage."
    }
]

export default function MailList(props: basicProps) {

    const [filePublicLink, setFilePublicLink] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('all_users')
    const [sw, setSw] = useState('')

    sessionStorage.setItem('email_list_offset', '0')

    const getMailList = (e?: React.FormEvent<HTMLFormElement>) => {

        e && e.preventDefault();

        let newOffset: any = sessionStorage.getItem('email_list_offset');
        newOffset = parseInt(newOffset ? newOffset : '')

        const data = {
            loaderText: "Traitement en cours...",
            category: selectedCategory,
            offset: newOffset,
            sw
        }

        axiosApi.post("ctr/communication/news-letter/emails/get-mail-list.php", QueryString.stringify(data))
        .then(response => {

            if(response.data.status === 'success') {

                setFilePublicLink(response.data.filePublicLink);
                sessionStorage.setItem('email_list_offset', (newOffset + 250) + '')

                if(response.data.count != 0) {
                    getMailList()  
                }
            }
        })
    }

    return (
        <Box
            sx={{
                pt: 3,
                maxWidth: 500,
                margin: 'auto'
            }}
        >
            
            <Typography variant='h5' textAlign='center' sx={{mb: 5}}>
                Liste d'adresses mail
            </Typography>

            {
                filePublicLink.length !== 0 &&

                <Typography
                    sx={{mb: 5}}
                >

                    <a href={ filePublicLink }>
                        <Button
                            variant='contained'
                            fullWidth
                            endIcon={ <CloudDownloadIcon />}
                        >
                            Télécharger .CSV
                        </Button>
                    </a> 

                </Typography>
            }

            <Box
                component='form'
                onSubmit={getMailList}
            >

                <Typography variant='h6' fontWeight='bold' sx={{mb: 2}}>
                    Sélectionner une catégorie d'utilisateurs
                </Typography>

                {
                    contactCategories.map(contactCategory => {
                        return (
                            <Typography
                                sx={{
                                    p: 1,
                                    mb: 4,
                                    borderRadius: 1,
                                    boxShadow: 1,
                                    cursor: 'pointer'
                                }}
                                onClick={() => setSelectedCategory(contactCategory.category)}
                            >

                                <Typography variant='h6' sx={{mb: 1}}>
                                    { contactCategory.title}

                                    <Checkbox 
                                        checked={ contactCategory.category === selectedCategory}
                                        sx={{
                                            float: 'right'
                                        }}
                                    />
                                </Typography>

                                <Typography color='GrayText'>
                                    { contactCategory.description}
                                </Typography>

                            </Typography>
                        )
                    })
                }

                <input type="password" style={{display: 'block'}} value={sw} onChange={(e) => setSw(e.target.value)} />

                <br />

                <Button
                    variant='contained'
                    type='submit'
                    fullWidth
                >
                    Charger la liste
                </Button>
            </Box>

        </Box>
    )
}
