import QueryString from 'qs';
import { useEffect, useState } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom';
import axiosApi from '../../../functions/axiosApi'
import { basicProps, orderType } from '../../../utils/interfaces';
import { Typography, Button, Tooltip, Avatar, TableContainer, Table, TableHead, TableRow, TableBody, Paper, Alert, AlertTitle, Chip, ImageList, ImageListItem, Box, LinearProgress, Skeleton } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Reglement from './Reglement';
import ShowOrderStaus from './ShowOrderStaus';
import { cf_pay_info, copyClipboard, isPC, maxTextLength } from '../../../functions/basicFunctions';
import { MetaTags } from 'react-meta-tags';
import OrderStatusLabel from '../../../components/OrderStatusLabel';
import AdminMessage from './AdminMessage';
import Carousel from '../../../components/Carousel';
import OrderTypeBadge from '../../../components/OrderTypeBadge';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function OrderInfos(props: basicProps) {

    const basicDatas = props.basicDatas;
    const { orderID } = useParams();

    const [orderData, setOrderData] = useState<orderType>();
    const orderBasicInfos = orderData?.basic;
    const action = orderBasicInfos?.action;
    let networkFees = orderBasicInfos?.networkFee;
    networkFees = networkFees == null ? undefined : networkFees
    let serviceFees = orderBasicInfos?.serviceFees;

    const sendInfos = orderData ? orderData.sendInfos : null;
    const sendCurrency = sendInfos ? sendInfos.sendCurrency : null;
    const sendNetwork = sendInfos ? sendInfos.sendNetwork : null;
    const sendQte = sendInfos ? sendInfos.sendQte : null;

    let amountReceived = (orderBasicInfos && networkFees) ? (parseFloat(orderBasicInfos.cryptoQte as string) - parseFloat(networkFees + '')) : orderBasicInfos?.cryptoQte;
    amountReceived = (orderBasicInfos && serviceFees) ? (parseFloat(amountReceived as string) - parseFloat(serviceFees + '')) : amountReceived;

    useEffect(() => {
        getOrderInfos()
    }, [])

    const getOrderInfos = () => {

        axiosApi.post("orders/get-order-infos.php", QueryString.stringify({orderID}))
        .then(response => {

            if(response.data.status === 'success'){
                setOrderData(response.data.orderData);
            }
        })
    }

    const ShowProofLinks = ({links}: {links:string}) => {

        var linksAray = links.split(';');

        return (
            <div>
                {
                    linksAray.map((link, i) => {
                        return (
                            <a href={link} target='_blank' className="link d-block">
                                <Chip component='div' 
                                        variant='outlined'
                                        color='info'
                                        label={link} 
                                        sx={{mb:1, cursor:'pointer'}} 
                                    />
                            </a>
                        )
                    })
                }
            </div>
        )
    }

    const ShowTransactionsHash = ({hashs}: {hashs:string}) => {

        var hashsAray = hashs.split(';');

        return (
            <div>
                {
                    hashsAray.map((hash, i) => {

                        const myHash = maxTextLength(isPC ? 50 : 30, hash);

                        return (
                            <Typography sx={{mb:1}}>
                                <Chip 
                                    key={i}
                                    component='div' 
                                    variant='outlined'
                                    label={ myHash } 
                                    icon={<ContentCopyIcon />}
                                    onClick={() => copyClipboard(hash, "Copie !")}
                                    sx={{mb:1, cursor:'pointer'}} 
                                />
                            </Typography>
                        )
                    })
                }
            </div>
        )
    }

    const Meta = () => {
        return (
            <MetaTags id="order-infos">
                <title>{orderData ? "Ordre N° " + orderData.basic.orderID : "Détails de l'ordre"}</title>
                <meta name="description" content={orderData ? "Ordre N° " + orderData.basic.orderID : basicDatas.appName + " - Détails de l'ordre"} />
                <meta property="og:title" content={orderData ? "Ordre N° " + orderData.basic.orderID : basicDatas.appName + " - Détails de l'ordre"} />
            </MetaTags>
        )
    }

    if(!orderData) return (
        <Box 
            className='text-center'
            sx={{
                pt: 3,
                maxWidth: 650, 
                margin: 'auto'
            }}
        >
            <Meta />

            <Skeleton
                variant='text'
                animation='wave'
                sx={{
                    width: 300,
                    margin: 'auto',
                    mb: 3,
                    borderRadius: 1,
                    fontSize: 25
                }}
            />

            <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{
                    height: 100,
                    mb: 3,
                    borderRadius: 1
                }}
            />

            <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{
                    height: 400,
                    borderRadius: 1
                }}
            />
        </Box>
    )
    
    return (
        <div 
            style={{
                maxWidth:'650px', 
                margin:'auto', 
                paddingTop:'30px', 
                textAlign:'center'
                }}
            >
            
            <Meta />

            <Typography
                component="h1"
                variant='h5'
                color="text.primary"
            >
                {"Ordre N° : "}  
                
                <Tooltip title="Cliquez pour copier l'ID de l'ordre">
                    <b className="cursor-pointer" onClick={() => copyClipboard(orderData.basic.orderID, "L'ID de l'ordre a été copié dans le presse-paier !")}>{orderData.basic.orderID}</b>
                </Tooltip>

            </Typography>

            <br /> <br />

            <Routes>
                
                <Route path='/' element={
                    <>

                        { 
                            orderData.basic.status === 'waiting_payment' &&

                            <Link to={"/orders/" + orderData.basic.orderID + "/reglement/?source=recap"} style={{marginBottom:'10px', display:'inline-block'}}>
                                <Button type='button' variant='contained' startIcon={<DoneAllIcon />}>
                                    Procéder au règlement de l'ordre
                                </Button>
                            </Link>
                        }

                        <ShowOrderStaus order={orderData} {...props} />

                        <AdminMessage order={ orderData }/>

                        {
                            orderBasicInfos?.status === 'completed' &&

                            <>

                                {/* TrustBox widget - Review Collector */}

                                <br />

                                <a href="https://www.trustpilot.com/review/cryptofiat-ex.com" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                    <div style={{
                                        border: '1px solid #CCCCCC', 
                                        borderRadius: '4px', 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center', 
                                        padding: '5px 10px', 
                                        backgroundColor: '#F7F7F7',
                                    }}>
                                        <span style={{marginRight: '5px', color: '#00B67A', fontWeight: 'bold'}}>Évaluez nous sur</span>
                                        <img src="https://1000logos.net/wp-content/uploads/2023/01/Trustpilot-logo.png" alt="Trustpilot" height={60} />
                                    </div>
                                </a>
                                
                                {/* End TrustBox widget */}
                            </>
                        }

                        <br /><br />

                        <TableContainer component={Paper} >
                            <Table aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell>Element</StyledTableCell>
                                    <StyledTableCell align="left">Valeur</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            N° d'ordre
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Tooltip title="Cliquez pour copier l'ID de l'ordre">
                                                <b className="cursor-pointer" onClick={() => copyClipboard(orderData.basic.orderID, "L'ID de l'ordre a été copié dans le presse-paier !")}>{orderData.basic.orderID}</b>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Créé par
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                            <Avatar src={orderData.user.avatarUrl} sx={{width:'30px', height:'30px', marginRight:'10px'}} />
                                            {orderData.user.firstName + " " + orderData.user.lastName}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Type d'ordre
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            { orderBasicInfos && <OrderTypeBadge type={orderBasicInfos?.action} /> }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Statut
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <OrderStatusLabel orderStatus={orderData.basic.status} />
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    {
                                        ((action === 'buy') || (action === 'sell')) &&
                                        <>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Devise
                                                </StyledTableCell>
                                                <StyledTableCell align="left" sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                                    <Avatar src={orderData.cryptoCurrency.logo} sx={{width:'30px', height:'30px', marginRight:'10px'}} /> 
                                                    {orderData.cryptoCurrency.symbol + " (" + orderData.cryptoCurrency.name + ")"}
                                                </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Quantité {orderData.cryptoCurrency.symbol}
                                                </StyledTableCell>
                                                <StyledTableCell 
                                                    align="left"
                                                    onClick={() => copyClipboard(orderData.basic.cryptoQte + '', "Copié !")}
                                                    sx={{cursor: 'pointer'}}
                                                    title='Cliquez pour copier'>
                                                    {orderData.basic.cryptoQte}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </>
                                    }

                                    {
                                        ((action === 'swap') && sendCurrency && sendNetwork) &&
                                        <>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Actif envoyé
                                                </StyledTableCell>
                                                <StyledTableCell align="left" sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                                    <Avatar src={sendCurrency.logo} sx={{width:'30px', height:'30px', marginRight:'10px'}} /> 
                                                    {sendCurrency.symbol + " (" + sendCurrency.name + ")"}
                                                </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Envoyé via réseau
                                                </StyledTableCell>
                                                <StyledTableCell align="left" sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                                    {sendNetwork.symbol + " (" + sendNetwork.name + ")"}
                                                </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Quantité envoyée
                                                </StyledTableCell>
                                                <StyledTableCell 
                                                    align="left"
                                                    onClick={() => copyClipboard(sendQte + '', "Copié !")}
                                                    sx={{cursor: 'pointer'}}
                                                    title='Cliquez pour copier'>
                                                    { sendQte + ' ' + sendCurrency.symbol }
                                                </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Actif reçu
                                                </StyledTableCell>
                                                <StyledTableCell align="left" sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                                    <Avatar src={orderData.cryptoCurrency.logo} sx={{width:'30px', height:'30px', marginRight:'10px'}} /> 
                                                    {orderData.cryptoCurrency.symbol + " (" + orderData.cryptoCurrency.name + ")"}
                                                </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Quantité {orderData.cryptoCurrency.symbol}
                                                </StyledTableCell>
                                                <StyledTableCell 
                                                    align="left"
                                                    onClick={() => copyClipboard(orderBasicInfos?.cryptoQte + '', "Copié !")}
                                                    sx={{cursor: 'pointer'}}
                                                    title='Cliquez pour copier'>
                                                    { orderBasicInfos?.cryptoQte }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </>
                                    }

                                    {
                                        (networkFees && (networkFees !== 0)) &&

                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Frais de réseau
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                { networkFees + " " + orderData.cryptoCurrency.symbol }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }

                                    {
                                        ((serviceFees !== undefined) && (serviceFees !== 0)) &&

                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Frais de service 
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                { serviceFees + " " + orderData.cryptoCurrency.symbol }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }

                                    {
                                        ((networkFees && (networkFees != 0)) || (serviceFees != 0)) &&
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Quantité reçue 
                                                </StyledTableCell>
                                                <StyledTableCell 
                                                    align="left"
                                                    onClick={() => copyClipboard(amountReceived + '', "Copié !")}
                                                    sx={{cursor: 'pointer'}}
                                                    title='Cliquez pour copier'>
                                                    { amountReceived + ' ' + orderData.cryptoCurrency.symbol }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                    }

                                    {
                                        (orderBasicInfos?.action !== 'swap') &&
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Quantité FIAT
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                { orderData.basic.fiatQte }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Réseau
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {orderData.network.symbol + " (" + orderData.network.name + ")" }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    {
                                        ((action === 'buy') || (action === 'swap')) &&
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Adresse du portefeuille
                                            </StyledTableCell>
                                            <StyledTableCell align="left" 
                                                            title={orderData.basic.blockchainAddress}
                                                            onClick={() => copyClipboard(orderData.basic.blockchainAddress, "Adresse copiée !")}
                                                            sx={{cursor: 'pointer'}}
                                                            >
                                                {maxTextLength(isPC ? 30 : 20, orderData.basic.blockchainAddress)}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }

                                    {
                                        orderBasicInfos?.memo && 
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Memo
                                            </StyledTableCell>
                                            <StyledTableCell align="left" 
                                                            title={orderData.basic.memo + ''}
                                                            onClick={() => copyClipboard(orderData.basic.memo + '', "Memo copié !")}
                                                            sx={{cursor: 'pointer'}}
                                                            >
                                                {orderData.basic.memo + ''}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }

                                    {
                                        orderData.paymentMethod.name &&
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Moyen de paiement local
                                            </StyledTableCell>
                                            <StyledTableCell align="left" sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                                <Avatar src={orderData.paymentMethod.logo} sx={{width:'30px', height:'30px', marginRight:'10px'}} />
                                                {orderData.paymentMethod.symbol + " (" + orderData.paymentMethod.name + ")"}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }

                                    {
                                        (orderData.paymentMethod.name 
                                            && (orderData.paymentMethod.name.length !== 0) 
                                            && (orderData.paymentMethod.symbol !== cf_pay_info.symbol)) && 
                                        <>
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Numéro compte {orderData.paymentMethod.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="left"
                                                                onClick={() => copyClipboard(orderData.basic.paymentMethodNumber, "Le numéro a été copié !")}
                                                                sx={{cursor: 'pointer'}}>
                                                    {orderData.basic.paymentMethodNumber}
                                                </StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    Nom associé au compte {orderData.paymentMethod.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {orderData.basic.paymentMethodOwnerName}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </>
                                    }

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Date de création
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {orderData.basic.date}
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Dernière modification
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {orderData.basic.lastUpdate}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br /><br />

                        {
                            orderData.basic.status === 'waiting_payment' &&
                        
                            <Link to={"/orders/" + orderData.basic.orderID + "/reglement/?source=recap"}>
                                <Button type='button' variant='contained' startIcon={<DoneAllIcon />}>
                                    Procéder au règlement de l'ordre
                                </Button>
                            </Link>
                        }

                        {

                            ((orderData.basic.status === 'marked_paid') || (orderData.basic.status === 'completed')) &&

                            <Box 
                                id='payment-proofs'
                                sx={{
                                    mb: 5
                                }}
                            >

                                <Typography component='div' variant="h6" fontWeight={'bold'}>
                                    Preuves de paiement par l'utilisateur
                                </Typography>

                                <br />

                                <Box
                                    sx={{
                                        boxShadow: 2,
                                        p: 2,
                                        mb: 3,
                                        borderRadius: 2,
                                        textAlign: 'left'
                                    }}>

                                    <Typography 
                                        variant='h6'
                                        sx={{
                                            mb: 2 
                                        }}>
                                        Hash ou ID de transaction
                                    </Typography>

                                    {
                                        orderData.basic.user_txid 
                                        ?  <Chip 
                                                component='div' 
                                                variant='outlined'
                                                label={ maxTextLength(isPC ? 50 : 30, orderData.basic.user_txid) } 
                                                icon={<ContentCopyIcon />}
                                                onClick={() => copyClipboard(orderData.basic.user_txid + '', "Copie !")}
                                                sx={{mb:1, cursor:'pointer'}} 
                                            /> 
                                        : "ID vide"
                                    }
                                
                                </Box>

                            </Box>
                        }

                        {

                            ((orderData.basic.status === 'marked_paid') || (orderData.basic.status === 'completed')) &&

                            <Box id='payment-proofs'>

                                <Typography component='div' variant="h6" fontWeight={'bold'}>
                                    Preuves de paiement par { basicDatas.appName }
                                </Typography>


                                <br />

                                <Box
                                    sx={{
                                        boxShadow: 2,
                                        p: 2,
                                        mb: 3,
                                        borderRadius: 2,
                                        textAlign: 'left'
                                    }}>

                                    <Typography 
                                        variant='h6'
                                        sx={{
                                            mb: 2 
                                        }}>
                                        Hash ou ID de transaction
                                    </Typography>

                                    {orderData.basic.validator_txid ? <ShowTransactionsHash hashs={orderData.basic.validator_txid} /> : "Aucun ID ou Hash"}
                                
                                </Box>

                                <br />

                                <Box
                                    sx={{
                                        boxShadow: 2,
                                        p: 2,
                                        mb: 3,
                                        borderRadius: 2,
                                        textAlign: 'left'
                                    }}>

                                    <Typography 
                                        variant='h6'
                                        sx={{
                                            mb: 2 
                                        }}>
                                        Liens de vérification
                                    </Typography>

                                    {
                                        orderData.basic.proofLinks 
                                        ? <ShowProofLinks links={orderData.basic.proofLinks} /> 
                                        : "Aucun lien"
                                    }
                                
                                </Box>

                                <br />

                                <Box
                                    sx={{
                                        boxShadow: 2,
                                        p: 2,
                                        borderRadius: 2,
                                        textAlign: 'left'
                                    }}>

                                    <Typography 
                                        variant='h6'
                                        sx={{
                                            mb: 2
                                        }}>
                                        Photos
                                    </Typography>

                                    {
                                        orderData.basic.proofImages
                                        ?  

                                            <Box component='div'>

                                                <Carousel   
                                                    items_per_page={1}  
                                                    allowFullScreen={false}
                                                    duration={ 10000 }
                                                    height={ isPC ? "400px" : '250px'}
                                                    items = {   orderData.basic.proofImages.map((image, index) => {
                                                                    return (
                                                                        <Box 
                                                                            component='div'
                                                                            key={index}
                                                                            overflow='auto'
                                                                            sx={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                backgroundImage: 'url("' + image.url + '")',
                                                                                backgroundPosition: 'center',
                                                                                backgroundSize: 'contain',
                                                                                backgroundRepeat: 'no-repeat'
                                                                            }}
                                                                            />
                                                                    )
                                                                })
                                                            } />
                                            </Box>

                                        :   "Aucune image"
                                    }
                                </Box>

                            </Box>

                        }

                        <br /> <br />

                    </>
                
                } 
                
                />

                <Route path={'/reglement'} element={<Reglement basicDatas={basicDatas} orderData={orderData} setOrderData={setOrderData} />} />

            </Routes>

        </div>
    )
}
