import { basicProps } from '../../../../utils/interfaces'
import { Link, Route, Routes } from 'react-router-dom';
import Emails from './emails/Emails';
import WebNews from './web-news/WebNews';


export default function NewsLetter(props: basicProps) {

    return (
        <>

            <Routes>

                <Route path='/' element={
                    <div style={{textAlign:'center'}}>

                    <br /><br />

                    <Link to="/ctr/communication/news-letter/emails/" className='link' style={{fontSize:'18px'}}>Emails</Link>
                    <br /><br />

                    <Link to="/ctr/communication/news-letter/web-news/" className='link' style={{fontSize:'18px'}}>Web news</Link>
                    <br /><br />

                </div>
                } />

                <Route path='/emails/*' element={<Emails {...props} />} />
                <Route path='/web-news/*' element={<WebNews {...props} />} />

            </Routes>

        </>
    )
}
