import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import FundLocation from './FundsLocation'
import EditFundLocation from './EditFundLocation'
import AddPeriodicBalance from './bians/AddPeriodicBalance'
import { basicProps } from '../../../../utils/interfaces'
import Bilans from './bians/Bilans'
import Graphic from './Graphic'
import Expenses from './Expenses'

export default function Compta(props: basicProps) {
    return (
        <div>

            <Routes>

                <Route path="/" element={
                    <div style={{textAlign:'center'}}>
            
                        <br /><br />
            
                        <Link to="/ctr/stats/compta/funds-location/" className='link' style={{fontSize:'18px'}}>Localisations de fonds</Link>
                        <br /><br />

                        <Link to="/ctr/stats/compta/add-periodic-balance/" className='link' style={{fontSize:'18px'}}>Ajouter un bilan périodique</Link>
                        <br /><br />

                        <Link to="/ctr/stats/compta/expenses/" className='link' style={{fontSize:'18px'}}>Dépenses</Link>
                        <br /><br />

                        <Link to="/ctr/stats/compta/bilans/" className='link' style={{fontSize:'18px'}}>Bilans</Link>
                        <br /><br />
            
                    </div>
                } />

                <Route path="/funds-location/" element={<FundLocation />} />
                <Route path="/funds-location/edit/:id" element={<EditFundLocation />} />
                <Route path="/add-periodic-balance" element={<AddPeriodicBalance {...props} />} />
                <Route path="/bilans" element={<Bilans {...props} />} />
                <Route path="/graphic/" element={<Graphic {...props} />} />
                <Route path="/expenses/" element={<Expenses {...props} />} />

            </Routes>

        </div>
    )
}
