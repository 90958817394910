import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState } from 'react'
import ImagesSelector from '../../../../components/ImageSelector';
import { toast } from 'react-toastify';
import axiosApi from '../../../../functions/axiosApi';

export default function AddBanner() {

    const [open, setOpen] = useState(false);
    const [images, setImages] = useState<{img_data: string, extension: string}[]>([]);

    const saveBanner = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(images.length === 0) {
            toast.error("Veuillez ajouter l'image de bannière.");
            return;
        }

        let data = new FormData(e.currentTarget);
        data.append('img_data', images[0].img_data);
        data.append('img_extension', images[0].extension);
        data.append('loaderText', "Veuillez patienter...")

        axiosApi.post("ctr/communication/banners/add-banner.php", data);
    }

    return (
        <div>
            
            <Typography sx={{textAlign: 'center'}}>
                <Button color='info' onClick={() => setOpen(true)} >
                    Ajouter une bannière
                </Button>
            </Typography>

            <Dialog open={open} onClose={() => setOpen(false)}>

                <DialogTitle>Ajouter une bannière</DialogTitle>

                <DialogContent sx={{maxWidth: 350}}>

                    <Box
                        component='form'
                        onSubmit={saveBanner}
                    >

                        <div className='add-banner-preview-img'>
                            <ImagesSelector images={images} max={1} setImages={(items: any) => {setImages(items)}} />
                        </div>

                        <TextField
                            name='buttonTitle'
                            fullWidth
                            margin='dense'
                            label="Bouton d'action"
                            placeholder='Titre du bouton'
                            sx={{mt: 3, mb: 2}}
                        />

                        <TextField
                            name='redirectUrl'
                            fullWidth
                            margin='dense'
                            label="Lien"
                            placeholder='Entrez le lien'
                            sx={{mb: 2}}
                        />

                        <FormControl 
                            fullWidth 
                            sx={{mb: 2}}
                        >

                            <InputLabel id="select_open_tap_label">Ouvir nouvel onglet</InputLabel>
                        
                            <Select
                                labelId="select_open_tap_label"
                                name='openNewTab'
                                defaultValue={ 1 }
                                label="Ouvir nouvel onglet"
                                fullWidth
                                margin="dense"
                                required
                            >

                                {
                                    [0, 1].map((type, index) => {

                                        return (
                                            <MenuItem key={ index } value={ type }>
                                                { type }
                                            </MenuItem>
                                        )

                                    })
                                }
                
                            </Select>        

                        </FormControl>


                        <FormControl 
                            fullWidth 
                            sx={{mb: 3}}
                        >

                            <InputLabel id="select_place_label">Place d'affichage</InputLabel>
                        
                            <Select
                                labelId="select_place_label"
                                name='type'
                                label="Place d'affichage"
                                fullWidth
                                margin="dense"
                                required
                            >

                                {
                                    [
                                        'home_mobile_top_carousel',
                                        'home_pc_top_bg'
                                    ].map((type, index) => {

                                        return (
                                            <MenuItem key={ index } value={ type }>
                                                { type }
                                            </MenuItem>
                                        )

                                    })
                                }
                
                            </Select>        

                        </FormControl>


                        <FormControl 
                            fullWidth 
                            sx={{mb: 2}}
                        >

                            <InputLabel id="select_priority_label">Priorité d'affichage</InputLabel>
                        
                            <Select
                                labelId="select_priority_label"
                                name='priority'
                                defaultValue={ 1 }
                                label="Priorité d'affichage"
                                fullWidth
                                margin="dense"
                                required
                            >

                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((type, index) => {

                                        return (
                                            <MenuItem key={ index } value={ type }>
                                                { type }
                                            </MenuItem>
                                        )

                                    })
                                }
                
                            </Select>        

                        </FormControl>

                        <TextField 
                            label='sw'
                            name='sw'
                            fullWidth
                            type='password'
                            sx={{
                                mb: 3
                            }}
                        />

                        <Button
                            fullWidth
                            variant='contained'
                            type='submit'
                        >
                            Valider
                        </Button>

                    </Box>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Fermer</Button>
                </DialogActions>

            </Dialog>

        </div>
    )
}
