import React from 'react';
import { 
    Box, FormControl, InputLabel, Select, 
    TextareaAutosize, Button, MenuItem, TextField 
} from '@mui/material';
import { User, permissionType } from '../../../../utils/interfaces';
import axiosApi from '../../../../functions/axiosApi';


interface myProps {
    user: User,
    permission: permissionType,
    getPermissions: () => void,
    handleClose: () => void
}

export default function EditPermissionForm(props: myProps) {

    const user = props.user;
    const permission = props.permission;

    const updateOnServer = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('permissionName', permission.name);
        data.append('user_id', user.user_id);
        data.append('loaderText', "Traitement en cours...");

        axiosApi.post("ctr/users/permissions/set-permission.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                props.getPermissions();
                props.handleClose();
            }
        })
    }

    return (
        <Box
            component='form'
            onSubmit={updateOnServer}
            sx={{
                mt: 2
            }}
            >

            <FormControl fullWidth sx={{mb: 2}}>

                <InputLabel id="select-action">Décision</InputLabel>
            
                <Select
                    labelId="select-action"
                    name='decision'
                    defaultValue={ permission.status }
                    label="Décision"
                    fullWidth
                    margin="dense"
                    required
                    >

                    {
                        ['allow', 'deny'].map((type, index) => {

                            return (
                                <MenuItem key={ index } value={ type }>
                                    { type }
                                </MenuItem>
                            )

                        })
                    }
    
                </Select>        

            </FormControl>

            <TextareaAutosize
                aria-label="Motif"
                name='motif'
                id="motif"
                minRows={3}
                placeholder="Motif"
                style={{ 
                    width:'100%', 
                    padding:'10px', 
                    borderRadius:'5px', 
                    border: "1px solid #bbb"
                }}
            />

            <TextField
                type='password'
                label="sw"
                name='sw'
                sx={{mb: 3, mt: 2}}
            />

            <Button 
                variant='contained'
                type='submit'
                fullWidth 
                >
                Update
            </Button>
            
        </Box>
    )
}
