import { useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom'
import { cf_pay_info } from '../../../functions/basicFunctions'
import { basicProps } from '../../../utils/interfaces'
import CftPartners from './cft-partners/CftPartners'
import TeamAvailability from './TeamAvailability'
import { TextField, Box } from '@mui/material';
import CtrsLogs from './CtrsLogs';

export default function TeamManager(props: basicProps) {

    const [sw, setSw] = useState('');

    return (
        <div>
            
            <Routes>
                <Route path='/' element={
                    <div style={{maxWidth:'500px', margin:'auto', textAlign:'center', marginTop:'40px'}}>

                        <Link to="/ctr/team/ctrs-logs/" className='link' style={{fontSize:'18px'}}>
                            Historique activités
                        </Link>

                        <br /><br />

                        <Link to="/ctr/team/availability/" className='link' style={{fontSize:'18px'}}>
                            Disponibilité de la team
                        </Link>

                        <br /><br />

                        <Link to="/ctr/team/cft-partners/" className='link' style={{fontSize:'18px'}}>
                            Partenaires { cf_pay_info.name }
                        </Link>
                    </div>
                } />

                <Route path='/ctrs-logs' element={<CtrsLogs />} />
                <Route path='/availability' element={<TeamAvailability {...props} sw={sw} />} />
                <Route path='/cft-partners/*' element={<CftPartners {...props} sw={sw} />} />

            </Routes>

            <Box sx={{mt: 7, textAlign: 'center'}}>

                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    value={sw}
                    onChange={(e) => setSw(e.target.value)}
                />
            </Box>

        </div>
    )
}
