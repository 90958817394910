import { ReactNode } from 'react'
import {
    List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Box
} from '@mui/material'

interface instructionType {
    step: number,
    title: ReactNode,
    content: ReactNode
}

interface myProps {
    instructions: instructionType[],
    note?: ReactNode,
    receptionAddressNote: ReactNode
}

export default function ListInstructions(props: myProps) {

    const instructions = props.instructions;

    return (
        <Box 
            sx={{
                mt: 5, 
                mb: 3, 
                p: 2,
                borderRadius: 1,
                background: 'white',
                boxShadow: 2,
                textAlign: 'left'
            }}
        >

            <Typography
                variant='h6'
                fontWeight='bold'
            >
                Instructions à suivre
            </Typography>
            
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

                {
                    instructions.map(itemInstruction => {
                        return (
                            <>

                                <ListItem alignItems="flex-start">
                                    
                                    <ListItemAvatar>
                                        <Avatar 
                                            sx={{
                                                background: 'var(--site-color)',
                                                width: 35,
                                                height: 35
                                            }} 
                                            alt={itemInstruction.step + ''} 
                                            src="/static/images/avatar/3.jpg" 
                                        />
                                    </ListItemAvatar>

                                    <ListItemText
                                    primary={<b>{itemInstruction.title}</b>}
                                    secondary={<div style={{marginTop: 3}}>{itemInstruction.content}</div>}
                                    />
                                </ListItem>

                            </>
                        )
                    })
                }
            </List>

            {
                props.receptionAddressNote
            }

            {
                props.note && 
                <>
                    <br /> <br />
                    { props.note }
                </>
            }

        </Box>
    )
}
