import { useState } from 'react';
import { Box, Button, TextareaAutosize, TextField } from '@mui/material'
import React from 'react'
import axiosApi from '../../../../../functions/axiosApi'
import { basicProps } from '../../../../../utils/interfaces'
import ImagesSelector from '../../../../../components/ImageSelector';
import QueryString from 'qs';


export default function AddNews(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [attachedImages, setAttachedImages] = useState([]);
    const [sw, setSw] = useState('');

    const publish = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        basicDatas.dialogManager({
            dialogTitle: "Confirmer la publication",
            dialogText: "Vous êtes sur le point de publier cette nouvelle. Fault-il vraiment exécuter ?",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui publier"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    attachedImages,
                    title,
                    message,
                    sw,
                    loaderText: "Publication en cours..."
                }
                axiosApi.post('ctr/communication/news-letter/web-news/add.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        setMessage('');
                        setTitle('');
                        setAttachedImages([]);

                        setTimeout(() => {
                            window.location.reload();
                        }, 5000);
                    }
                })
            }
      
        })
    }

    return (

        <Box component="form"   
            id="form_add_news"
            noValidate
            autoComplete="off"
            onSubmit={publish}
            sx={{
                p: 2,
                boxShadow: 2,
                mt: 3
            }}>

            <TextField 
                name='title'
                placeholder='Titre'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                label='Titre'
                sx={{
                    mb: 2
                }} />

            <TextareaAutosize
                    aria-label="Adresse blockchain"
                    name='message'
                    id="address"
                    minRows={3}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    placeholder="Entrez le message ici"
                    style={{ 
                        width:'100%', 
                        padding:'10px', 
                        borderRadius:'5px', 
                        border: "1px solid #bbb"
                    }}
                    />

            <TextField
                type='password'
                label="sw"
                name='sw'
                fullWidth
                value={sw}
                onChange={(e) => setSw(e.target.value)}
                sx={{mt: 2, mb: 3}}
            />

            <div style={{display:'block', marginBottom:'7px'}}>Attacher des images (max 10)</div>

            <div className='proof-form-images'>
                <ImagesSelector images={attachedImages} max={10} setImages={(items: any) => {setAttachedImages(items)}} />
            </div>

            <Button  variant='contained'
                    type='submit'
                    color='info'
                    fullWidth
                    sx={{mt: 2}}>
                Publier
            </Button>

        </Box>
    )
}
