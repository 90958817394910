import { useState } from 'react';
import { Avatar, Box, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, TextareaAutosize, Typography } from '@mui/material'
import { useEffect } from 'react'
import { basicProps, newsLetterType } from '../../../../../utils/interfaces';
import axiosApi from '../../../../../functions/axiosApi';
import QueryString from 'qs';
import { Link, Route, Routes } from 'react-router-dom';
import PreviewNews from '../../../../../components/PreviewNews';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import UpdateNews from './UpdateNews';
import AddNews from './AddNews';

export default function WebNews(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [news, setNews] = useState<newsLetterType[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);

    useEffect(() => {
        getNews();
    }, [])

    const getNews = () => {

        setIsLoading(true);

        axiosApi.post("ctr/communication/news-letter/web-news/get-news.php", QueryString.stringify({
            offset, limit: 3
        }))
        .then(response => {

            if(response.data.status === 'success'){
                const newDataArray = news.concat(response.data.news);
                setNews(newDataArray);
                setIsLoading(false);
                setOffset(offset + 3);

                if(response.data.news.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <>

            <Routes>

                <Route path='/' element={
                    
                    <div style={{maxWidth: '500px', margin: 'auto'}}>
            
                        <br /> <br />
                        
                        <Typography variant='h5' sx={{textAlign: 'center'}}>
                            News letter
                        </Typography>
            
                        <AddNews basicDatas={basicDatas} />
            
                        <br /><br />
            
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            
                            {
                                news.map((post) => {
                                    return (
            
                                        <Box 
                                            key={post.postID}
                                            sx={{
                                                boxShadow: 2,
                                                mb: 4
                                            }}>
            
                                            
                                            <Box sx={{
                                                textAlign: 'right'
                                            }}>
                                            
                                                <Link to={'/ctr/communication/news-letter/web-news/' + post.postID + "/"} className='link'>
                                                    <Button variant='contained' color='inherit'>
                                                        Modifier
                                                    </Button>
                                                </Link>
            
                                            </Box>
            
                                            <PreviewNews basicDatas={basicDatas} post={post} />
            
                                        </Box>
                                    )
                                })
                            }
            
                        </List>
            
                            
                        <div className="text-center">
            
                            {
                                isloading
            
                                ? 
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="outlined"
                                    >
                                        Chrargement
                                    </LoadingButton>
                                :
                                    !ended &&
                                    <Button variant='contained' color='inherit' onClick={() => getNews()}>
                                        Afficher plus
                                    </Button>
                            }
            
                        </div>
            
                    </div>
                } />


                <Route path='/:postID' element={<UpdateNews basicDatas={basicDatas} />} />

            </Routes>

        </>
    )
}
