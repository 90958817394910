import { useState, useEffect } from 'react'
import { Box, Button, TextareaAutosize, TextField } from '@mui/material';
import axiosApi from '../../../../functions/axiosApi';

interface myProps {
    setOpen: (a: boolean) => void
}

export default function AddLink(props: myProps) {

    const saveLink = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loadertext', "Ajout en cours...");

        axiosApi.post("ctr/communication/links/add.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                props.setOpen(false);
            }

        })
    }

    return (
        <Box
            component='form'
            onSubmit={saveLink}
            sx={{
                maxWidth: 400
            }}
            >

            <TextField 
                name='title'
                label="Titre"
                placeholder='Ajouter un titre'
                fullWidth
                required
                sx={{mb:2}}
            />

            <TextareaAutosize
                aria-label="Lien"
                name='link'
                id="link"
                minRows={3}
                required
                placeholder='Entrez le lien ici'
                style={{ 
                    width:'100%', 
                    padding:'10px', 
                    borderRadius:'5px', 
                    border: "1px solid #bbb"
                }}
            />

            <TextField
                type='password'
                label="sw"
                name='sw'
                fullWidth
                sx={{mt: 2}}
            />


            <Button
                type='submit'
                variant='contained'
                fullWidth
                sx={{mt: 3}}>
                Enregistrer
            </Button>
        </Box>
    )
}
