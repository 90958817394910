import { Avatar, Typography } from '@mui/material';
import { currencyType, networkType, paymentMethodType } from '../../utils/interfaces';
import { cftTokenInfo, maxTextLength } from '../../functions/basicFunctions';

interface myProps {
    action: 'buy' | 'sell',
    selectedCrypto: currencyType | null | undefined,
    selectedNetwork: networkType | null | undefined,
    blockchainAddress: string,
    memo: string,
    cryptoQuantity: number | string,
    fiatQuantity: number | string,
    selectedPaymentMethod: paymentMethodType | null | undefined,
    phoneNumber: string,
    paymentMethodOwnerName: string,
}

export default function RecapOrder(props: myProps) {

    const selectedCrypto = props.selectedCrypto;
    const selectedNetwork = props.selectedNetwork;
    const blockchainAddress = props.blockchainAddress;
    const memo = props.memo;

    return (
        <div>
            
            <Typography sx={{fontWeight: 'bold'}}>
                Veuillez vérifier les informations avant de valider
            </Typography>

            <Typography
                sx={{
                    p: 1,
                    boxShadow: 2,
                    mt: 3,
                    mb: 2
                }}
                >
                <Typography variant='caption'>
                    Devise
                </Typography>

                <Typography
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 1
                    }}>
                    <Avatar
                        src={selectedCrypto ? selectedCrypto.logo : ''}
                        sx={{
                            mr: 2,
                            width: 30,
                            height: 30
                        }}
                    />

                    <Typography sx={{width: '100%'}}>
                        { selectedCrypto?.symbol + ' (' + selectedCrypto?.name + ')'}
                    </Typography>
                </Typography>
            </Typography>

            <Typography
                sx={{
                    p: 1,
                    boxShadow: 2,
                    mb: 2
                }}
                >
                <Typography variant='caption'>
                    Réseau
                </Typography>

                <Typography>
                    { selectedNetwork?.symbol + ' (' + selectedNetwork?.name + ')'}
                </Typography>
            </Typography>

            {
                props.action === 'buy' &&
                <Typography
                    sx={{
                        p: 1,
                        boxShadow: 2,
                        mb: 2
                    }}
                    >
                    <Typography variant='caption'>
                        Adresse portefeuille { selectedNetwork?.symbol }
                    </Typography>

                    <Typography>
                        { maxTextLength(30, blockchainAddress) }
                    </Typography>
                </Typography>
            }

            { 
                memo.length !== 0 &&
    
                <Typography
                    sx={{
                        p: 1,
                        boxShadow: 2,
                        mb: 2
                    }}
                    >
                    <Typography variant='caption'>
                        Memo
                    </Typography>

                    <Typography>
                        { memo }
                    </Typography>
                </Typography>
            }

            <Typography
                sx={{
                    p: 1,
                    boxShadow: 2,
                    mb: 2
                }}
                >
                <Typography variant='caption'>
                    Quantité { selectedCrypto?.symbol }
                </Typography>

                <Typography>
                    { props.cryptoQuantity }
                </Typography>
            </Typography>

            <Typography
                sx={{
                    p: 1,
                    boxShadow: 2,
                    mb: 2
                }}
                >
                <Typography variant='caption'>
                    Quantité FIAT
                </Typography>
                
                <Typography>
                    { props.fiatQuantity }
                </Typography>

                <Typography variant='caption' sx={{fontStyle: 'oblique', opacity: 0.6}}>
                    La quantité FIAT pourra être légèrement différente dû à la fluctuation du marché.
                </Typography>
            </Typography>

            <Typography
                sx={{
                    p: 1,
                    boxShadow: 2,
                    mb: 2
                }}
                >
                <Typography variant='caption'>
                    Moyen de paiement
                </Typography>

                <Typography>
                    { props.selectedPaymentMethod?.name }
                </Typography>
            </Typography>

            {
                (props.selectedPaymentMethod && (props.selectedPaymentMethod.symbol !== 'CF_PAY') && (props.selectedPaymentMethod.symbol !== cftTokenInfo.symbol)) &&
                
                <>

                    <Typography
                        sx={{
                            p: 1,
                            boxShadow: 2,
                            mb: 2
                        }}
                        >
                        <Typography variant='caption'>
                            Numéro { props.selectedPaymentMethod.name }
                        </Typography>

                        <Typography>
                            { props.phoneNumber }
                        </Typography>
                    </Typography>

                    <Typography
                        sx={{
                            p: 1,
                            boxShadow: 2,
                            mb: 2
                        }}
                        >
                        <Typography variant='caption'>
                            Nom du compte { props.selectedPaymentMethod.name }
                        </Typography>

                        <Typography>
                            { props.paymentMethodOwnerName }
                        </Typography>
                    </Typography>

                </>
            }

        </div>
    )
}
