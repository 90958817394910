import { Chip } from '@mui/material'

export default function PrizePoolStatusBadge(props: {status: 'being_created' | 'progress' | 'completed'}) {

    let label = 'Création en cours';
    let color = 'var(--site-color)';
    let bgColor = 'rgba(167, 202, 237, 0.2)';

    switch (props.status) {
        case 'being_created':
            label = 'Création en cours';
            color = 'var(--site-color)';
            bgColor = 'rgba(167, 202, 237, 0.2)';
        break;

        case 'progress':
            label = 'En cours';
            color = '#338F5C';
            bgColor = '#E8F3EE';
        break;

        case 'completed':
            label = 'Terminé';
            color = '#E5593C';
            bgColor = '#FDEDED';
        break;
    
        default:
            label = 'Création en cours';
            color = 'var(--site-color)';
            bgColor = 'rgba(167, 202, 237, 0.2)';
        break;
    }

    return (
        <Chip label={ label } 
            sx={{
                backgroundColor: bgColor,
                color: color,
                borderRadius: 1
            }} 
            variant="filled" 
            size="small" 
        />
    )
}
