import { useEffect, useState } from 'react'
import { orderType } from '../../utils/interfaces'
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import moment from 'moment';
import axiosApi from '../../functions/axiosApi';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';


export default function LatestOrderAlert(props: any) {

    const date = new Date();

    const [latestOrder, setLatestOrder] = useState<orderType>()
    const [progress, setProgress] = useState(0);
    const [orderDuration, setOrderDuration] = useState(0);
    const [paymentTimeLimit, setPaymentTimeLimit] = useState(0);

    useEffect(() => {

        if(latestOrder) {

            const _orderBasicData = latestOrder.basic;
            let _paymentTimeLimit = _orderBasicData.action === 'buy' ? latestOrder.paymentMethod.maxTimePaymentLimit : latestOrder.network.maxTimePaymentLimit;
            _paymentTimeLimit = parseInt(_paymentTimeLimit + '');

            const _orderDuration = (moment(latestOrder.basic.date).diff(date.getTime()) / 1000 / 60) * -(1)
            let percentage = _orderDuration * 100 / _paymentTimeLimit;

            setProgress(percentage > 100 ? 100 : percentage);
            setOrderDuration(_orderDuration)
            setPaymentTimeLimit(_paymentTimeLimit)
        }

    }, [latestOrder]);

    useEffect(() => {

        getLatestOrder()

        const interval = setInterval(() => {
            getLatestOrder();
        }, 30000);

        return () => {
            clearInterval(interval)
        }

    }, [])
    

    const getLatestOrder = () => {

        axiosApi.post('home-content/latest-order.php')
        .then(response => {

            if(response.data.status === 'success') {
                setLatestOrder(response.data.latestOrder)
            }
        })
    }


    if(!latestOrder) return <></>

    const orderBasicData = latestOrder.basic;
    const limitTime30min = (moment(latestOrder.basic.date).diff(date.getTime()) / 1000 / 60 / 30) * -(1)
    const limitTime6h = (moment(latestOrder.basic.date).diff(date.getTime()) / 1000 / 60 / 360) * -(1)
    const limitTime12h = (moment(latestOrder.basic.date).diff(date.getTime()) / 1000 / 60 / 720) * -(1)

    if(latestOrder.basic.status === 'canceled_by_user') return <></>

    if((latestOrder.basic.status ==='canceled_by_admin') && (limitTime6h >= 1)) return <></>

    if((latestOrder.basic.status === 'completed') && (limitTime12h >= 1)) return <></>

    return (
        <Box
            sx={{
                mb: 3,
                mt: 3
            }}
        >
            
            {
                latestOrder.basic.status === 'waiting_payment' &&

                <Alert 
                    severity='info' 
                    sx={{
                        textAlign:'left', 
                        mt:2, 
                        boxShadow: 3, 
                        bgcolor: '#eee'
                    }} 
                    {...props}
                >
                    <AlertTitle sx={{fontWeight: 'bold', mb: 2}}>
                        {
                            orderBasicData.action === 'buy' &&
                            <>Votre achat {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est en attente de paiement</>
                        }

                        {
                            orderBasicData.action === 'sell' &&
                            <>Votre vente {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est en attente de paiement</>
                        }

                        {
                            orderBasicData.action === 'swap' &&
                            <>Votre SWAP {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est en attente de paiement</>
                        }
                    </AlertTitle>

                    Veuillez effectuer le paiement avant <Typography color={progress < 60 ? "primary" : progress < 80 ? 'error' : 'error'} sx={{display: 'inline'}}>{ orderBasicData.action === 'buy' ? latestOrder.paymentMethod.maxTimePaymentLimit : latestOrder.network.maxTimePaymentLimit } minutes</Typography> sinon l'ordre sera annulé pour delai dépassé.
                    
                    <Box
                        sx={{
                            mt: 2,
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <LinearProgress 
                            variant="determinate" 
                            value={progress} 
                            color={progress < 60 ? "info" : progress < 80 ? 'warning' : 'error'}
                            sx={{
                                p: 1,
                                width: '100%',
                                borderRadius: 0.5
                            }}
                        />

                        <Typography
                            color={progress < 60 ? "primary" : progress < 80 ? 'error' : 'error'}
                            sx={{
                                width: '70px',
                                fontSize: 'small',
                                textAlign: 'right'
                            }}
                        >
                            {Math.round(paymentTimeLimit - orderDuration) > 0 ? Math.round(paymentTimeLimit - orderDuration) + ' Min' : "Expiré"}
                        </Typography>
                    </Box>

                    <Typography variant='caption'>
                        Si vous avez déjà effectué le paiement, veuillez vérifier à nouveau l'adresse de réception avant de marquer payé.
                    </Typography>

                    <br /><br />

                    <Link 
                        to={'/orders/' + latestOrder.basic.orderID + '/reglement/'} 
                        className='link'
                        style={{
                            color: 'var(--site-color)',
                            fontWeight: 'bold'
                        }}
                    >
                        
                        <Button
                            sx={{
                                textTransform: 'none',
                                fontWeight: 'bold'
                            }}
                        >
                            Accéder à la page de paiement
                        </Button>
                    </Link>
                </Alert>
            }

            {
                latestOrder.basic.status === 'marked_paid' &&

                <Alert severity="info" sx={{textAlign:'left', mt:2, boxShadow: 3}} {...props}>

                    <AlertTitle sx={{fontWeight: 'bold', mb: 2}}>
                        
                        <LinearProgress color='info' sx={{mb: 1, display: 'block'}} />

                        {
                            orderBasicData.action === 'buy' &&
                            <>Votre achat {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est en cours de traitement...</>
                        }

                        {
                            orderBasicData.action === 'sell' &&
                            <>Votre vente {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est en cours de traitement...</>
                        }

                        {
                            orderBasicData.action === 'swap' &&
                            <>Votre SWAP {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est en cours de traitement...</>
                        }
                    </AlertTitle>

                    Veuillez patienter pendant que les validateurs traitent votre commande.

                    {
                        limitTime30min > 1 &&
                        
                        <>
                    
                            <br /><br />

                            <Typography variant='caption'>
                                Si le traitement n'est pas terminé après <b>1_Heure</b> à partir du moment où vous avez marqué payé, veuillez contacter le support.
                            </Typography>
                            <br /><br />

                            <a 
                                href={"https://wa.me/+237650839431/?text=Bonjour, j'ai effectué le paiement pour l'ordre " + latestOrder.basic.orderID + ". Mais cela fait plus de 1 Heure et le traitement n'est pas terminé."} 
                                className='link'
                                target='_blank'
                                style={{
                                    color: 'var(--site-color)',
                                    fontWeight: 'bold'
                                }}
                            >
                                <Button
                                    startIcon={<WhatsAppIcon />}
                                    sx={{
                                        color: '#128C7E',
                                        textTransform: 'none',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Contacter le support
                                </Button>
                            </a>
                        </>
                    }
                </Alert>
            }


            {
                latestOrder.basic.status === 'canceled_by_admin' &&

                <Alert severity="error" sx={{textAlign:'left', mt:2, boxShadow: 3}} {...props}>
                    <AlertTitle sx={{fontWeight: 'bold', mb: 2}}>Votre dernier ordre a été annulé </AlertTitle>
                    
                    La validateur a écrit ceci : 
                    <br /> <br />

                    <Box
                        sx={{
                            bgcolor: '#fdd',
                            p: 2,
                            borderRadius: 1
                        }}
                    >
                        <div dangerouslySetInnerHTML={{__html: latestOrder.basic.message + ''}} />
                    </Box>
                    <br />

                    <a 
                        href={"https://wa.me/+237650839431/?text=Bonjour, j'ai effectué le paiement pour l'ordre " + latestOrder.basic.orderID + ". Mais l'ordre a été annulé."} 
                        className='link'
                        target='_blank'
                        style={{
                            color: 'var(--site-color)',
                            fontWeight: 'bold'
                        }}
                    >
                        <Button
                            startIcon={<WhatsAppIcon />}
                            sx={{
                                color: '#128C7E',
                                textTransform: 'none',
                                fontWeight: 'bold'
                            }}
                        >
                            Contacter le support
                        </Button>
                    </a>
                </Alert>
            }


            {
                latestOrder.basic.status === 'completed' &&

                <Alert severity="success" sx={{textAlign:'left', mt:2, boxShadow: 3}} {...props}>
                    <AlertTitle sx={{fontWeight: 'bold', mb: 2}}>
                        {
                            orderBasicData.action === 'buy' &&
                            <>Votre achat {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est terminé</>
                        }

                        {
                            orderBasicData.action === 'sell' &&
                            <>Votre vente {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est terminée</>
                        }

                        {
                            orderBasicData.action === 'swap' &&
                            <>Votre SWAP {orderBasicData.cryptoQte + ' ' + latestOrder.cryptoCurrency.symbol } est terminé</>
                        }
                    </AlertTitle>
                    
                    Veuillez consulter le Portefeuille de réception pour vérifier le solde 
                    <br /> <br />
                    
                    <Typography variant='caption'>
                        Si vous avez rencontré un souci, veuillez contacter le support pour de l'aide.
                    </Typography>

                    <br /><br />

                    <a 
                        href={"https://wa.me/+237650839431/?text=Bonjour, j'ai besoin d'aide concernant l'ordre " + latestOrder.basic.orderID + "."} 
                        className='link'
                        target='_blank'
                        style={{
                            color: 'var(--site-color)',
                            fontWeight: 'bold'
                        }}
                    >
                        <Button
                            startIcon={<WhatsAppIcon />}
                            sx={{
                                color: '#128C7E',
                                textTransform: 'none',
                                fontWeight: 'bold'
                            }}
                        >
                            Contacter le support
                        </Button>
                    </a>
                </Alert>
            }
        </Box>
    )
}
