import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Cgu from './Cgu'
import { basicProps } from '../../utils/interfaces'
import Privacy from './Privacy'

export default function Terms(props: basicProps) {
    return (
        <div>
            <Routes>
                <Route path='/' element={ <Cgu {...props} /> } />
                <Route path='/cgu' element={ <Cgu {...props} /> } />
                <Route path='/privacy' element={ <Privacy {...props} /> } />
            </Routes>
        </div>
    )
}
