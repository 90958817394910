import { useEffect, useState} from 'react'
import { 
    Typography, Badge, TextField, List, ListItem, Divider,
    ListItemAvatar, Avatar, ListItemText, Button, Tooltip,
    Chip
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { copyClipboard } from '../../functions/basicFunctions';
import QueryString from 'qs';
import axiosApi from '../../functions/axiosApi';
import { basicProps, User } from '../../utils/interfaces';

export default function Referrals(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [referrals, setreferrals] = useState<User[]>([]);
    const [numReferrals, setNumReferrals] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [linkCopied, setLinkCopied] = useState(false);

    useEffect(() => {
        getreferrals()
    }, [queryValue])

    const getreferrals = () => {

        setIsLoading(true);

        axiosApi.post("sponsorships/get-referrals.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {
            if(response.data.status === 'success'){
                setreferrals(referrals.concat(response.data.referrals))
            }

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newReferralsArray = referrals.concat(response.data.referrals);
                setreferrals(newReferralsArray);
                setNumReferrals(response.data.numReferrals);
                setOffset(offset + 5);

                if(response.data.referrals.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <div style={{
            maxWidth: '700px',
            margin: 'auto'
        }}>

            <div className="text-center" style={{
                marginTop: '50px',
                marginBottom: '40px'
            }}>
            
                <Typography variant='h5' component='h5' className='text-center'>
                    <Badge max={9999999999999} badgeContent={numReferrals} color="primary">
                        Parrainages
                    </Badge>
                </Typography>

                <div className='mt-3 mb-3'>

                    <Tooltip title="Cliquez pour copier le lien d'invitation">
                        <Chip
                                label={<span className='text-muted' style={{fontSize:'small'}}>{"https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id}</span>}
                                avatar={
                                    !linkCopied 
                                    ? <ContentCopyIcon sx={{fontSize:'small'}} />
                                    : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                                }
                                variant="outlined"
                                sx={{fontWeight:'bold', fontSize:'20px', marginTop:'10px', cursor:'pointer'}}
                                onClick={() => {copyClipboard("https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id, "Le lien d'invitation a été copié dans le presse-paier."); setLinkCopied(true)}}
                            />
                    </Tooltip>
                </div>

                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher un utilisateur" 
                    variant="outlined"
                    placeholder="Entrez le nom de l'utilisateur"
                    value={queryValue}
                    sx={{mt:3}}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setreferrals([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    />

            </div>

            
            {
                ((referrals.length === 0) && (queryValue === '')) &&
                <div className='small text-center mt-4' style={{maxWidth: '400px', margin:'auto'}}>
                    Lorsque les gens s'enregistrent grâce à votre lien d'invitation, leurs comptes s'affichent ici.
                </div>
            }


            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', margin:'auto' }}>
                
                {
                    referrals.map((user: User) => {
                        return (

                            <div key={user.user_id}>

                                <ListItem alignItems="flex-start" >

                                    <ListItemAvatar>
                                        <Avatar alt={user.lastName + " " + user.firstName} src={user.avatarUrl} />
                                    </ListItemAvatar>

                                    <ListItemText
                                        primary={user.firstName + " " + user.lastName}
                                        secondary={
                                            <>

                                                <Typography
                                                    sx={{ display: 'block' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Ordres validés : {user.numCompletedOrders}
                                                </Typography>

                                                <Typography
                                                    sx={{ display: 'block' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    KYC : {user.vip_level !== 'vip1' ? <Typography variant='caption' sx={{color: 'red'}}>Non vérifié</Typography> : <Typography variant='caption' sx={{color: 'green'}}>Vérifié</Typography>}
                                                </Typography>
                                            
                                                <Typography
                                                    sx={{ display: 'block', fontSize: 'small' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    Inscription : {user.inscriptionDate}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>

                                <Divider variant="inset" component="li" />

                            </div>
                        )
                    })
                }
            </List>

            <br />

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getreferrals()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </div>
    )
}
