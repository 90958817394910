import { Box, Button, TextField, TextareaAutosize } from '@mui/material'
import { useState } from 'react'
import { basicProps } from '../../../../utils/interfaces'
import axiosApi from '../../../../functions/axiosApi';

export default function SendMessage(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [open, setOpen] = useState(false);

    const sendPushMessage = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);

        basicDatas.dialogManager(
            {
                dialogTitle: "Confirmer l'envoi",
                dialogText: "Vous confirmez l'envoi de ce message ? Il n'est pas possible de supprimer.",
                dialogAgreeBtnColor: 'info',
                dialogAgreeBtnText: "Envoyer"
            },
            (response => {

                if(response === 'ok') {
                    axiosApi.post("ctr/communication/push-messages/send-message.php", data)
                    .then(response => {

                        if(response.data.status === 'success') {
                            console.log("coco")
                        }
                    })
                }
            }))

    }

    return (
        <Box
            sx={{
                boxShadow: 3,
                p: 2,
                borderRadius: 2
            }}
        >

            {
                !open 
                
                ? 
                <Button onClick={() => setOpen(true)}>
                    Envoyer un message
                </Button>
            :
                <Button onClick={() => setOpen(false)}>
                    Fermer
                </Button>
            }

            {
                open &&

                <Box
                    component='form'
                    onSubmit={sendPushMessage}
                >
                
                    <TextField
                        name='title'
                        fullWidth
                        margin='dense'
                        label="Titre"
                        required
                        placeholder='Entrez le titre'
                        sx={{
                            mb: 2
                        }}
                    />

                    <TextField
                        name='url'
                        fullWidth
                        margin='dense'
                        label="Url"
                        placeholder="Entrez l'url"
                        required
                        defaultValue={'https://cryptofiat-ex.com/'}
                        sx={{
                            mb: 2
                        }}
                    />

                    <TextField
                        name='btnText'
                        fullWidth
                        margin='dense'
                        label="Texte du bouton"
                        placeholder='Texte du bouton'
                        defaultValue={'Accéder à ' + basicDatas.appName}
                        sx={{
                            mb: 2
                        }}
                    />

                    <TextareaAutosize
                        aria-label="Message"
                        name='message'
                        minRows={3}
                        required
                        placeholder='Entrez le message'
                        style={{ 
                            width:'100%', 
                            padding:'10px', 
                            borderRadius:'5px', 
                            border: "1px solid #bbb"
                        }}
                    />

                    <TextField
                        name='sw'
                        type='password'
                        fullWidth
                        margin='dense'
                        label="sw"
                        sx={{
                            mb: 2
                        }}
                    />

                    <Button
                        variant='contained'
                        type='submit'
                        sx={{
                            mt: 3
                        }}
                    >
                        Envoyer
                    </Button>

                </Box>

            }

        </Box>
    )
}
