import React from 'react'
import { cf_pay_info, cftTokenInfo } from '../../functions/basicFunctions'
import { basicProps } from '../../utils/interfaces';
import { Typography, Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link, Route, Routes } from 'react-router-dom';
import ClaimAirdrop from './ClaimAirdrop';
import BecomePartner from './BecomePartner';
import CftPartners from './CftPartners';

export default function CftToken(props: basicProps) {
    return (
        <div>   

            <Routes>

                <Route path='/' element={
                    
                    <div className='text-center' style={{paddingTop:'50px', maxWidth:'500px', margin:'auto'}}>
                        
                        <Typography variant='h5' fontWeight='bold' sx={{mb:4}}>
                            Le token { cftTokenInfo.symbol }
                        </Typography>

                        <Box>
                            <List>

                                <Link to="/cft-token/sellers/" className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1}}>
                                        <ListItemButton>
                                            <ListItemText primary={"Acheter " + cftTokenInfo.symbol + "?"} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                <Link to="/wallet/" className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt:3, mb: 3}}>
                                        <ListItemButton>
                                            <ListItemText primary="Accéder au Portefeuille" />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                <Link to="/cft-token/become-partner/" className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt:3, mb: 3}}>
                                        <ListItemButton>
                                            <ListItemText primary="Devenir réprésentant dans mon pays" />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                <a href="https://medium.com/@cryptofiat1022/cest-quoi-crypto-fiat-pay-et-comment-l-utiliser-814e2ca1568a" target='_blanck' className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt: 3}}>
                                        <ListItemButton>
                                            <ListItemText primary={"Comment acheter crypto avec " + cf_pay_info.symbol + ' ?' } />
                                        </ListItemButton>
                                    </ListItem>
                                </a>

                                <a href="https://drive.google.com/file/d/10YeBm7K_jCgbX1LFBYlBtggM3EQ0q-Pn/view?usp=sharing" target='_blanck' className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt: 3}}>
                                        <ListItemButton>
                                            <ListItemText primary={"Frais et commissions " + cf_pay_info.name } />
                                        </ListItemButton>
                                    </ListItem>
                                </a>

                                <a href="https://medium.com/@cryptofiat1022/cest-quoi-le-cft-token-et-à-quoi-ça-sert-c5ed6143e256" target='_blanck' className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt: 3}}>
                                        <ListItemButton>
                                            <ListItemText primary={"A quoi sert le " + cftTokenInfo.symbol + " (" + cftTokenInfo.name + ") ?"} />
                                        </ListItemButton>
                                    </ListItem>
                                </a>

                                <a href="https://medium.com/@cryptofiat1022/comment-ajouter-le-cft-cryptofiat-token-dans-son-portefeuille-trust-wallet-57375e487b1c" target='_blanck' className='link'>
                                    <ListItem disablePadding sx={{boxShadow:1, mt: 3}}>
                                        <ListItemButton>
                                            <ListItemText primary={"Comment ajouter " + cftTokenInfo.symbol + " dans Trust Wallet ?"} />
                                        </ListItemButton>
                                    </ListItem>
                                </a>

                            </List>
                        </Box>
                    </div>
                } />

                <Route path='/sellers' element={<CftPartners { ...props } />}  />

                <Route path='/become-partner' element={<BecomePartner { ...props } />}  />
                
                <Route path='/claim-airdrop' element={<ClaimAirdrop { ...props } />}  />

            </Routes>

        </div>
    )
}
