import { Box, Button, Dialog, DialogActions, Alert, DialogContent, DialogTitle, Typography } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input';
import { useState } from 'react'
import { basicProps } from '../../../utils/interfaces';
import axiosApi from '../../../functions/axiosApi';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface myProps extends basicProps {
    getNumbers: () => void
}

export default function PartnerPhoneNumbers(props: myProps) {

    const [open, setOpen] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState('')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addNumber = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);

        axiosApi.post("/pro-account/phone-numbers/add-phone-number.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                props.getNumbers();
                setOpen(false);
                setNewPhoneNumber('')
            }
        })
    }

    return (
        <Box>

            <Typography textAlign='center'>

                <Button
                    variant='contained'
                    onClick={() => setOpen(true)}
                    startIcon={<AddCircleIcon/>}
                    sx={{
                        mb: 5
                    }}
                >
                    Ajouter un numéro
                </Button>
            </Typography>

            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Ajouter un numéro</DialogTitle>

                <DialogContent sx={{minWidth: 200}}>

                    <Alert severity="info" sx={{mb: 3}}>
                        Nous recommandons d'ajouter des numéros qui possèdent des comptes WhatsApp pour permettre aux clients de vous contacter facilement.
                    </Alert>

                    <Box
                        component='form'
                        onSubmit={addNumber}
                    >
                    
                        <MuiTelInput
                            margin="normal"
                            fullWidth
                            value={newPhoneNumber}
                            onChange={(value: string) => setNewPhoneNumber(value)}
                            id="newPhoneNumber"
                            label="Téléphone"
                            name="newPhoneNumber"
                            autoComplete="phone"
                            sx={{mb: 2}}
                        />

                        <Typography sx={{textAlign: 'right'}}>
                            <Button
                                variant='contained'
                                type='submit'
                            >
                                Ajouter
                            </Button>
                        </Typography>

                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>
            
        </Box>
    )
}
