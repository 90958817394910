import { useState } from 'react';
import { Avatar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import axiosApi from '../functions/axiosApi';
import QueryString from 'qs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isPC } from '../functions/basicFunctions';
import { MetaTags } from 'react-meta-tags';
import { basicProps } from '../utils/interfaces';


export default function SetPassword(props: basicProps) {

    const basicDatas = props.basicDatas;
    const navigate = useNavigate()

    const [emailIsVerified, setEmailIsVerified] = useState(false);
    const [open, setOpen] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [emailCode, setEmailCode] = useState('');

    const handleOpenConfirmMailModal = () => setOpen(true);
    const handleCloseConfirmMailModal = () => setOpen(false);

    let verifyEmailCode = () => {

        axiosApi.post('auth/verify_email_code.php', QueryString.stringify({
            emailAddress, 
            emailCode,
            loaderText: 'Vérification email en cours...'
        }))
        .then(response => {
        
            if(response.data.status === "success") {
                setEmailIsVerified(true);
                handleCloseConfirmMailModal();
                toast.info("Choisissez un nouveau mot de passe.");
            }

        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loaderText', emailIsVerified ? 'Modification du mot de passe en cours...' : "Envoi du code en cours...");

        axiosApi.post('auth/reset_password.php', data)
        .then(response => {

            if(response.data.action === 'display_confirm_mail_modal'){
                handleOpenConfirmMailModal();
            }

            else if(response.data.status === 'success'){
                basicDatas.setAuthUser(response.data.auth_user);
                basicDatas.setIsLoggedIn(true);
                navigate("/");
            }
        })

    }

    return (
        <div style={{maxWidth:'400px', margin:'auto'}}>

            <MetaTags id="set-password">
                <title>Réinitialiser mon mot de passe</title>
                <meta name="description" content={basicDatas.appName + " - Réinitialiser mon mot de passe"} />
                <meta property="og:title" content={basicDatas.appName + " - Réinitialiser mon mot de passe"} />
            </MetaTags>
            
            <Box
                sx={{
                    marginTop: isPC ? 8 : 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >

                <LockIcon sx={{m:1, width:'35px', height:'35px'}} />

                <Typography component="h1" variant="h5" sx={{mb:3}}>
                    Mot de passe
                </Typography>

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                    {
                        (!emailIsVerified && !basicDatas.isLoggedIn) &&
                        <div className='mutted'>
                            Veuillez entrer votre adresse e-mail pour obtenir le code de réinitialisation de votre mot de passe
                        </div>
                    }

                    <TextField
                        type="email"
                        margin="normal"
                        fullWidth
                        onChange={(e) => setEmailAddress(e.target.value)}
                        id="email"
                        label="Adresse e-mail"
                        name="email"
                        autoComplete="email"
                        required
                        style={{display: (!emailIsVerified && !basicDatas.isLoggedIn) ? 'block' : 'none'}}
                        />
            

                    {
                        basicDatas.isLoggedIn &&
                    
                        <TextField
                            type="password"
                            margin="normal"
                            fullWidth
                            id="current_password"
                            label="Mot de passe actuel"
                            name="currentPassword"
                            required
                            />
                    }

                    {
                        (emailIsVerified || basicDatas.isLoggedIn) &&
                    
                        <>

                            <TextField
                                margin="normal"
                                type='password'
                                required
                                fullWidth
                                id="password"
                                label="Nouveau mot de passe"
                                name="password"
                                autoFocus
                                />

                            <TextField
                                type="password"
                                margin="normal"
                                required
                                fullWidth
                                id="confirm_password"
                                label="Confirmer le nouveau mot de passe"
                                name="confirmPassword"
                                autoFocus
                                />
                        </>
                    }
                    

                    <br />
                
                    <Button
                        type="submit"
                        id="reset_password_btn"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2 }}
                        >

                            {(emailIsVerified || basicDatas.isLoggedIn) ? "VALIDER" : "OBTENIR LE CODE"}

                    </Button>

                </Box>

            </Box>

            <Dialog open={open}>

                <DialogTitle>Vérifier l'adresse Email</DialogTitle>
                
                <DialogContent>
                    <DialogContentText style={{fontSize:'small'}}>
                    Nous vous avons envoyé un code à 5 (cinq) caractères à l'adresse <span style={{fontWeight:'bold'}}>{emailAddress}</span> . <br />
                    Veuillez entrer ce code pour confirmer votre adresse Email.
                    </DialogContentText>

                    <br />

                    <TextField
                    autoFocus
                    onChange={(e) => setEmailCode(e.target.value)}
                    margin="dense"
                    id="email_code"
                    label="Code de confirmation"
                    type="text"
                    fullWidth
                    variant="standard"
                    />
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={handleCloseConfirmMailModal}>Annuler</Button>
                    <Button onClick={verifyEmailCode}>Vérifier</Button>
                </DialogActions>

            </Dialog>

        </div>
    )
}
