import { useEffect, useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { basicProps, User } from '../../../utils/interfaces';
import { 
    Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, 
    Divider, Button, TextField, Badge, Tooltip 
} from '@mui/material';
import QueryString from 'qs';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import UserInfos from './UserInfos';
import { copyClipboard } from '../../../functions/basicFunctions';

export default function Users(props: basicProps) {

    const navigate = useNavigate();

    const [users, setUsers] = useState<User[]>([]);
    const [numUsers, setNumUsers] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getUsers()
    }, [queryValue])

    const getUsers = () => {

        setIsLoading(true);

        axiosApi.post("ctr/users/get-users.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newUsersArray = users.concat(response.data.users);
                setUsers(newUsersArray);
                setNumUsers(response.data.numUsers);
                setOffset(offset + 5);

                if(response.data.users.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }
    

    return (

        <div>

            <Routes>
                <Route path='/' element={
                    <div style={{
                        maxWidth: '700px',
                        margin: 'auto'
                    }}>
    
                        <div className="text-center" style={{
                            marginTop: '50px',
                            marginBottom: '40px'
                        }}>
                        
                            <Typography variant='h5' component='h5' className='text-center'>
                                <Badge max={9999999999999} badgeContent={numUsers} color="primary">
                                    Utilisateurs
                                </Badge>
                            </Typography>
    
                            <TextField 
                                id="outlined-basic"
                                type='search' 
                                label="Rechercher un utilisateur" 
                                variant="outlined"
                                placeholder="Entrez le nom de l'utilisateur"
                                value={queryValue}
                                sx={{mt:3}}
                                onChange={
                                    (e) => {
                                        setQueryValue(e.target.value);
                                        setUsers([]);
                                        setOffset(0);
                                        setEnded(false)
                                    }
                                }
                                />
    
                        </div>
    
    
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', margin:'auto' }}>
                            
                            {
                                users.map((user: User) => {
                                    return (
    
                                        <div key={user.user_id}>
    
                                            <ListItem alignItems="flex-start" >
    
                                                <ListItemAvatar sx={{cursor:'pointer'}} onClick={() => navigate("/ctr/users/" + user.user_id + "/")}>
                                                    <Avatar alt={user.lastName + " " + user.firstName} src={user.avatarUrl} />
                                                </ListItemAvatar>
    
                                                <ListItemText
                                                    primary={

                                                            <>
                                                                <Typography>
                                                                    <Tooltip title="Cliquez pour copier ID utilisateur">
                                                                        <span style={{cursor:'pointer', fontWeight: 'bold'}} onClick={() => copyClipboard(user.user_id, "L'ID de l'utilisateur a été copié !")}>{"ID : " + user.user_id}</span>
                                                                    </Tooltip>
                                                                </Typography>

                                                                <Typography 
                                                                        className='cursor-pointer' 
                                                                        onClick={() => navigate("/ctr/users/" + user.user_id + "/")}
                                                                        sx={{
                                                                            fontWeight: 'bold'
                                                                        }}>
                                                                    {user.firstName + " " + user.lastName}
                                                                </Typography>
                                                            </>
                                                        }
                                                    secondary={
                                                        <>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {user.phone}
                                                            </Typography>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Total ordres : {user.totalOrders}
                                                            </Typography>

                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Ordres validés : {user.numCompletedOrders}
                                                            </Typography>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {user.email}
                                                            </Typography>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                GoogleID : {user.googleID}
                                                            </Typography>

                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Invité par : <Link to={"/ctr/users/" + user.sponsor?.user_id} target='_blank' className='small link' style={{color: 'var(--site-color)'}} >{user.sponsor && user.sponsor.firstName + " " + user.sponsor.lastName}</Link>
                                                            </Typography>

                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                Invitations : {user.numRferrals}
                                                            </Typography>
    
                                                            <Typography
                                                                sx={{ display: 'block' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {user.location.city}
                                                            </Typography>
                                                        
                                                            <Typography
                                                                sx={{ display: 'block', fontSize: 'small' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.secondary"
                                                            >
                                                                {user.inscriptionDate}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
    
                                            <Divider variant="inset" component="li" />
    
                                        </div>
                                    )
                                })
                            }
                        </List>
    
                        <br />
    
                        <div className="text-center">
    
                            {
                                isloading
    
                                ? 
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="outlined"
                                    >
                                        Chrargement
                                    </LoadingButton>
                                :
                                    !ended &&
                                    <Button variant='contained' color='inherit' onClick={() => getUsers()}>
                                        Afficher plus
                                    </Button>
                            }
    
                        </div>
    
                    </div>

                } />

                <Route path='/:userID/*' element={<UserInfos {...props} />} />

            </Routes>

        </div>
    )
}
