import { useEffect, useRef, useState } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { basicProps, mobileMoneyProAccount } from '../../../utils/interfaces';
import { 
    Typography, List, Avatar, 
    Button, TextField, Box, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import axiosApi from '../../../functions/axiosApi';

interface myProps extends basicProps {
    sw: string
}


export default function MobileMoneyPro(props: myProps) {

    const [open, setOpen] = useState(false);
    const [proAccounts, setProAccounts] = useState([])
    const sw = props.sw;

    useEffect(() => {
        open && getMobileMoneyProAccounts()
    }, [open])

    const getMobileMoneyProAccounts = () => {

        axiosApi.post("ctr/payment-methods/mobile-money-pro/get-accounts.php")
        .then(response => {
            if(response.data.status === 'success'){
                setProAccounts(response.data.proAccounts)
            }  
        })
    }

    const handleClose = () => {
        setOpen(false);
    };

    const addAccount = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('sw', sw);
        data.append('loaderText', "Traitement en cours...");

        axiosApi.post("ctr/payment-methods/mobile-money-pro/add.php", data)
        .then(response => {
            if(response.data.status === 'success'){
                getMobileMoneyProAccounts();
            }
        })
    }

    const setAccount = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('sw', sw);
        data.append('loaderText', "Traitement en cours...");

        axiosApi.post("ctr/payment-methods/mobile-money-pro/set-account.php", data)
        .then(response => {
            if(response.data.status === 'success'){
                getMobileMoneyProAccounts();
            }
        })
    }

    return (

        <div>

            <Button variant='contained' color='inherit' onClick={() => setOpen(true)}>
                Comptes mobile money pro
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Comptes mobile money PRO</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Box    
                        component='form'
                        onSubmit={addAccount}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: 2,
                            width: '500px'
                        }}
                    >

                        <TextField
                            type='text'
                            name='symbol'
                            label="Symbol"
                            placeholder='Symbol'
                            required />

                        <TextField
                            type='text'
                            name='accountOwnerName'
                            label="Nom du propriétaire"
                            placeholder='Nom'
                            required />

                        <TextField
                            type='text'
                            name='address'
                            label="Adresse du compte"
                            placeholder='Adresse'
                            required />
                        
                        <TextField
                            type='text'
                            name='cashOutCode'
                            label="Code d'encaissement"
                            placeholder="Code de retrait d'argent"
                            required 
                        />

                        <Button variant='contained'
                                type='submit'
                                color='info'
                                fullWidth
                                sx={{grid: 1}}
                                >
                            Ajouter
                        </Button>

                    </Box>

                    <Typography 
                            variant='h5'
                            sx={{
                                textAlign: 'center',
                                mt: 4,
                                mb: 2
                            }}>
                        Liste des comptes
                    </Typography>

                    <List sx={{ 
                            width: '100%', 
                            bgcolor: 'background.paper', 
                            margin:'auto'
                        }}>
                            
                            {
                                proAccounts.map((proAccount: mobileMoneyProAccount, i) => {
                                    return (
                                        <Box    component='form'
                                                onSubmit={setAccount}
                                                key={i}
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: 2,
                                                    width: '500px',
                                                    boxShadow: 2,
                                                    p: 2,
                                                    mb: 5
                                                }}>

                                            <TextField type='number' name='id' value={proAccount.id} sx={{display:'none'}} />

                                            <TextField
                                                type='text'
                                                name='symbol'
                                                defaultValue={proAccount.symbol}
                                                label="Symbol"
                                                placeholder='Symbol'
                                                required />

                                            <TextField
                                                type='text'
                                                name='accountOwnerName'
                                                defaultValue={proAccount.accountOwnerName}
                                                label="Nom du propriétaire"
                                                placeholder='Nom'
                                                required />

                                            <TextField
                                                type='text'
                                                name='address'
                                                defaultValue={proAccount.address}
                                                label="Adresse du compte"
                                                placeholder='Adresse'
                                                required 
                                            />

                                            <TextField
                                                type='text'
                                                name='cashOutCode'
                                                defaultValue={proAccount.cashOutCode}
                                                label="Code d'encaissement"
                                                placeholder="Code de retrait d'argent"
                                                required 
                                            />

                                            <FormControl
                                                fullWidth >

                                                <InputLabel id="select_activation">Activé / Désactivé</InputLabel>
                                                
                                                <Select
                                                    labelId="select_activation"
                                                    name='activated'
                                                    defaultValue={ proAccount.activated }
                                                    label="Activé / Désactivé"
                                                    fullWidth
                                                    margin="dense"
                                                    required
                                                    >

                                                    {
                                                        [1, 0].map((activated, index) => {

                                                            return (
                                                                <MenuItem key={ index } value={ activated }>
                                                                    { activated === 1 ? "Activée" : "Désactivée" }
                                                                </MenuItem>
                                                            )

                                                        })
                                                    }
                                    
                                                </Select>        

                                            </FormControl>

                                            <Button variant='contained'
                                                    type='submit'
                                                    color='info'
                                                    fullWidth
                                                    sx={{grid: 1}}
                                                    >
                                                Modifier
                                            </Button>


                                        </Box>
                                    )
                                })
                            }
                        </List>
                        
                        <br />
                        
                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
