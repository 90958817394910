import { useState, useEffect } from 'react'
import { basicProps } from '../../../../../utils/interfaces'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, TextField, Typography } from '@mui/material'
import axiosApi from '../../../../../functions/axiosApi';
import QueryString from 'qs';

export type categoryType = {id: number, category: string, title: string, description: string}


const fristCategory = {
    id: 1,
    category: 'test',
    title: 'Une seule adresse',
    description: "Envoyer un mail de test à une adresse spécifique."
}

const contactCategories: categoryType[] = [
    fristCategory,

    {
        id: 2,
        category: 'all_users',
        title: 'Tous les utilisateurs',
        description: "Charger les contacts mails de tous les utilisateurs inscrits."
    },

    {
        id: 3,
        category: 'kyc_verified',
        title: 'Utilisateurs vérifiés',
        description: "Charger les contacts mails de tous les utilisateurs dont le KYC a été validé."
    },

    {
        id: 4,
        category: 'kyc_unverified',
        title: "Utilisateurs non vérifiés",
        description: "Charger les contacts mails de tous les utilisateurs qui n'on pas passé le KYC."
    }
]

export default function SendMail(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [selectedCategory, setSelectedCategory] = useState<categoryType>(fristCategory)
    const [open, setOpen] = useState(false);
    const [sw, setSw] = useState('')
    const [emailAddresses, setEmailAddresses] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [totalSent, setTotalSent] = useState(0);
    const [currentOffset, setCurrentOffset] = useState('0')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        setEmailAddresses('')
    }, [selectedCategory])
    


    const ItemCategory = (props: {itemCategory: categoryType}) => {

        const itemCategory = props.itemCategory;

        return (
            <Typography
                sx={{
                    p: 1,
                    mb: 4,
                    borderRadius: 1,
                    boxShadow: 1,
                    cursor: 'pointer'
                }}
                onClick={() => {
                    setSelectedCategory(itemCategory)
                    handleClose()
                }}
            >

                <Typography variant='h6' sx={{mb: 1}}>
                    { itemCategory.title}

                    <Checkbox 
                        checked={ itemCategory === selectedCategory}
                        sx={{
                            float: 'right'
                        }}
                    />
                </Typography>

                <Typography color='GrayText'>
                    { itemCategory.description}
                </Typography>

            </Typography>
        )
    }

    sessionStorage.setItem('send_email_offset', ''+ currentOffset + '')
      

    const send = (e: React.FormEvent<HTMLFormElement>) => {

        e && e.preventDefault();

        const sendToServer = () => {

            let newOffset: any = sessionStorage.getItem('send_email_offset');
            newOffset = parseInt(newOffset ? newOffset : '');

            console.log(newOffset)

            const data = {
                loaderText: "Traitement en cours...",
                category: selectedCategory.category,
                offset: newOffset,
                sw,
                emailAddresses,
                title,
                message
            }

            axiosApi.post('ctr/communication/news-letter/emails/send.php', QueryString.stringify(data))
            .then(response => {
                if(response.data.status === 'success'){

                    sessionStorage.setItem('send_email_offset', (newOffset + 10) + '')
                    setCurrentOffset((_offset) => newOffset + 10)
    
                    if(response.data.count != 0) {

                        setTimeout(() => {
                            sendToServer()
                        }, 60000);
                          
                        setTotalSent((count) => count + parseInt(response.data.count + ''))
                    }  
                }
            })
        }

        basicDatas.dialogManager({
            dialogTitle: "Confirmer l'envoi du mail",
            dialogText: "Souhaitez-vous confirmer l'envoi de ce mail ?",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui publier"
          }, (response: string) => {
            
            if(response === 'ok') {
                sendToServer()
            }
      
        })

    }

    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: 'auto',
                pt: 5
            }}
        >
            
            <Typography 
                textAlign='center' 
                variant='h5' 
                className='Montserrat' 
                fontWeight='bold'
            >
                Envoi mails
            </Typography>

            {
                totalSent > 0 &&
            
                <Typography className='Montserrat' textAlign='center' sx={{mt: 5}}>
                    Total envoyé :

                    <Typography  variant='h4' color='primary' sx={{ml: 1, display: 'inline-block'}} fontWeight='bold'>
                        { totalSent }
                    </Typography>
                </Typography>
            }

            <Box
                component='form'
                onSubmit={send}
                sx={{
                    mt: 5
                }}
            >

                <Typography fontWeight='bold' sx={{mb: 2}}>
                    Sélectionner une catégorie d'utilisateurs
                </Typography>

                <Button 
                    onClick={() => handleClickOpen()} 
                    sx={{textTransform: 'none', fontWeight: 'bold', mb: 1}}
                >
                    Choisir une autre catégorie
                </Button>

                <ItemCategory itemCategory={selectedCategory} />

                {
                    selectedCategory?.category === 'test' &&

                    <TextareaAutosize
                        aria-label="Adresses mail"
                        name='emailAddress'
                        id="emailAddress"
                        minRows={3}
                        maxRows={5}
                        required
                        value={emailAddresses}
                        onChange={(e) => setEmailAddresses(e.target.value)}
                        placeholder='Message'
                        style={{ 
                            width:'100%', 
                            padding:'10px', 
                            borderRadius:'5px', 
                            border: "1px solid #bbb",
                            marginBottom: 15
                        }}
                    />
                }

                <TextField 
                    type='text'
                    fullWidth
                    placeholder='Offset'
                    label='Commencer à partir de ...'
                    required
                    value={currentOffset}
                    onChange={(e) => setCurrentOffset(e.target.value)}
                    sx={{
                        mb: 2
                    }}
                />

                <TextField 
                    name='title'
                    type='text'
                    fullWidth
                    placeholder='Objet'
                    label='Objet'
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{
                        mb: 2
                    }}
                />

                <TextareaAutosize
                    aria-label="Adresse"
                    name='message'
                    id="message"
                    minRows={3}
                    maxRows={10}
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Message'
                    style={{ 
                        width:'100%', 
                        padding:'10px', 
                        borderRadius:'5px', 
                        border: "1px solid #bbb"
                    }}
                />

                <TextField 
                    name='sw'
                    type='password'
                    fullWidth
                    label='sw'
                    required
                    value={sw}
                    onChange={(e) => setSw(e.target.value)}
                    sx={{
                        mt: 2
                    }}
                />

                <Button
                    variant='contained'
                    type='submit'
                    fullWidth
                    sx={{
                        mt: 3
                    }}
                >
                    Envoyer le mail
                </Button>

            </Box>

            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Catégories</DialogTitle>

                <DialogContent sx={{minWidth: 300}}>
                    
                    {
                        contactCategories.map(itemCategory => {
                            return (
                                <ItemCategory itemCategory={itemCategory} />
                            )
                        })
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>

        </Box>
    )
}
