import { useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, Tooltip, Typography } from '@mui/material'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { readClipboard } from '../../../functions/basicFunctions';
import axiosApi from '../../../functions/axiosApi';
import InfoPopup from '../../../components/InfoPopup';
import InfoIcon from '@mui/icons-material/Info';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { basicProps } from '../../../utils/interfaces';

interface myProps extends basicProps {
    confirmPayment: () => void
}

export default function MarkAsPaidBtn(props: myProps) {

    const basicDatas = props.basicDatas;

    const [open, setOpen] = useState(false);
    const [txID, setTxID] = useState('');
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const readTxIdClipboard = () => {

        readClipboard((clipboardContent) => {
            if(clipboardContent && typeof clipboardContent === 'string') setTxID(clipboardContent)
        })
    }

    const sendProof = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append("loaderText", "Plese wait...");

        axiosApi.post("orders/set-payment-proof.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                handleClose()
                props.confirmPayment();
            }
        })
    }

    return (
        <>

            <Button type='button' variant='contained' startIcon={<DoneAllIcon />} onClick={() => setOpen(true)}>
                MARQUER PAYÉ
            </Button>

            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Preuve de paiement</DialogTitle>

                <DialogContent sx={{minWidth: 300}}>

                    <Typography 
                        sx={{
                            mb: 2,
                            textAlign: 'center'
                        }}
                    >
                        <Typography variant='caption'>
                            Afin de faciliter le traitement de votre ordre, veuillez fournir l'ID (hash) de transaction.
                        </Typography>

                    </Typography>

                    <Typography
                        sx={{
                            mb: 2
                        }}
                    >

                        <InfoPopup 
                            basicDatas={basicDatas}
                            title="Comment trouver le hash de transaction?"
                            text={
                                <div>
                                    Il s'agit de l'identifiant de transaction sur la blockchain ou sur la plateforme qui a été utilisée pour le transfert. 
                                    <br /> <br />
                                    Pour trouver le hash ou ID de transaction, veuillez ouvrir votre portefeuille puis allez dans l'historique de transactions et affichez les détails de la transaction concernée.
                                    
                                    <br />

                                    <Typography className="small" sx={{fontStyle:"oblique", mt: 2, fontSize:'small'}}>
                                        Si vous ne parvenez pas à trouver le hash de votre transaction, veuillez contacter le support pour de l'aide.
                                    </Typography>

                                    <div style={{fontSize: '13px', marginTop:'20px', textAlign:'right'}}>
                                        <a href={"https://wa.me/+237650839431/?text=Bonjour, j'ai effectué une vente mais je ne sais pas comment trouver le hash de transaction afin de confirmer le paiement. L'ID de mon ordre " + basicDatas.appName + " est : ..."}  target='_blank'>
                                            <WhatsAppIcon fontSize='small' /> J'ai besoin d'aide
                                        </a>
                                    </div>
                                    
                                    <div className='text-danger' style={{fontSize: '13px', marginTop:'20px'}}>
                                        <b>Important : </b> N'envoyez jamais votre clé privée à qui que ce soit sous aucun prétexte. 
                                    </div>
                                </div>
                            }
                            >

                            <span   
                                className='small'
                                style={{
                                    fontStyle: 'oblique',
                                    cursor: 'pointer',
                                    color: 'var(--site-color)'
                                }}
                            > 
                                <InfoIcon sx={{mr:0.5, fontSize:'16px'}} color='info' />
                                Comment trouver le hash de transaction?
                            </span>

                        </InfoPopup>

                    </Typography>
                    
                        
                    <Box
                        component='form'
                        onSubmit={sendProof}
                    >
                        
                        <Typography sx={{mb: 1}}>
                            <label htmlFor="txID" className="small">
                                Hash de transaction
                            </label> 

                            {
                                txID.length === 0 &&

                                <Button color='info' 
                                        style={{float: 'right'}}
                                        onClick={readTxIdClipboard} >
                                    Coller
                                </Button>
                            }

                        </Typography>

                        <TextareaAutosize
                            aria-label="Tx ID"
                            name='txID'
                            id="txID"
                            minRows={3}
                            required
                            value={txID}
                            onChange={(e) => setTxID(e.target.value)}
                            placeholder="Entrez l'ID de transaction"
                            style={{ 
                                width:'100%', 
                                padding:'10px', 
                                borderRadius:'5px', 
                                border: "1px solid #bbb"
                            }}
                        />

                        <Button 
                            type='submit' 
                            variant='contained'
                            fullWidth
                            sx={{
                                mt: 2
                            }}
                        >
                            Valider
                        </Button>

                    </Box> 

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>
        </>
        
    )
}
