import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UserIsNotConnectedMessage from '../../components/UserIsNotConnectedMessage';
import { basicProps } from '../../utils/interfaces'
import ListOrders from './ListOrders'
import OrderInfos from './order-infos/OrderInfos';
import MetaTags from 'react-meta-tags';

export default function Orders(props: basicProps) {

    const basicDatas = props.basicDatas;

    const Meta = () => {
        return (
            <MetaTags id="orders">
                <title>Ordres</title>
                <meta name="description" content={basicDatas.appName + " - Ordres"} />
                <meta property="og:title" content={basicDatas.appName + " - Ordres"} />
            </MetaTags>
        )
    }

    if(!basicDatas.isLoggedIn) return (
        <div className='text-center' style={{maxWidth:'500px', margin: 'auto', marginTop:'60px'}}>
            <Meta />
            <UserIsNotConnectedMessage message="Pour afficher et gérer vos ordres, veuillez vous identifier." />
        </div>
    )
    
    return (
        <div>

            <Meta />

            <Routes>
                <Route path='/' element={<ListOrders basicDatas={ basicDatas } />} />
                <Route path='/:orderID/*' element={<OrderInfos basicDatas={ basicDatas } />} />
            </Routes>
        </div>
    )
}
