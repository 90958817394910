import React from 'react'
import { basicProps } from '../utils/interfaces';
import { Box, Avatar, Typography, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { isPC, publicPath } from '../functions/basicFunctions';
import { Link } from 'react-router-dom';

interface Page404Props extends basicProps{
  title?: string;
  message?: string
}

export default function Page404(props: Page404Props) {

  const basicDatas = props.basicDatas;

  const title = props.title ? props.title : "Malheureusement cette page n'est pas disponoble !";
  const message = props.message ? props.message : "Le lien que vous avez suivi est rompu ou la page a été retirée de " + basicDatas.appName;
  
  return (
    <Box sx={{
      textAlign: 'center',
      maxWidth: '750px',
      margin: 'auto',
      marginTop: isPC ? '40px' : '25px'
    }}>

      <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
      
        <Avatar src={publicPath + "images/search_nothing_matched.png"}
                sx={{
                  width: isPC ? "250px" : '150px',
                  height: isPC ? '250px' : '150px',
                  borderRadius: '0px'
                }} />
      </div>

      <br />

      <Typography variant={isPC ? 'h4' : 'h5'}>
        {title}
      </Typography>

      <br />

      <Typography variant={isPC ? 'h6' : 'inherit'}>
        {message}
      </Typography>

      <br /> <br />

      <Link to="/" className='link'>
        <Button variant='contained' 
                color='info'
                startIcon={<HomeIcon />}>
          Page d'accueil
        </Button>
      </Link>

    </Box>
  )
}
