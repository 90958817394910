import { Avatar, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import QueryString from 'qs';
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import axiosApi from '../../functions/axiosApi';
import { cf_pay_info, cftTokenInfo, readabilityNumber } from '../../functions/basicFunctions';
import { basicProps, paymentMethodType } from '../../utils/interfaces'

interface myProps extends basicProps {
    getWithdrawalHistory: () => void
}

export default function WithdrawForm(props: myProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const [amount, setAmount] = useState('');
    const [paymentMethods, setpaymentMethods] = useState<paymentMethodType[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<paymentMethodType>();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(authUser.phone ? authUser.phone : "");
    const [paymentMethodOwnerName, setPaymentMethodOwnerName] = useState(authUser.lastName || authUser.firstName ? authUser.lastName + " " + authUser.firstName : "")
    const [sponsorshipInfos, setSponsorshipInfos] = useState({
        totalReferrals: 0,
        latestReferrals: [],
        commissions: {
            allTimeBalance: 0,
            availableBalance: 0
        }
    });
    const [cftReceptionAddress, setCftReceptionAddress] = useState('');

    useEffect(() => {
        if(phoneNumber === '') setPhoneNumber(authUser.phone ? authUser.phone : "")
        if(paymentMethodOwnerName === '') setPaymentMethodOwnerName(authUser.lastName || authUser.firstName ? authUser.lastName + " " + authUser.firstName : "")
    }, [authUser])

    useEffect(() => {
        
        axiosApi.post("sponsorships/get-sponsorships-basic-datas.php")
        .then(response => {
            setSponsorshipInfos(response.data.sponsorshipInfos)
        })

        getPaymentMethods();

    }, []) 

    useEffect(() => {
        
        const cftMethod = paymentMethods.filter((method) => method.symbol === cf_pay_info.symbol);
        if(cftMethod[0]){
            setSelectedPaymentMethod(cftMethod[0]);
            setPaymentMethod(cftMethod[0].symbol);
        }
    }, [paymentMethods])
    

    const getPaymentMethods = () => {
        axiosApi.post('ctr/payment-methods/get-payment-methods.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setpaymentMethods(response.data.paymentMethods);
            }
        })
    }

    const handlePaymentMethodChange = (e: SelectChangeEvent) => {

        const matchedPaymentMethod = paymentMethods.filter((item) => item.symbol === e.target.value);
        if(matchedPaymentMethod.length !== 0){
            setSelectedPaymentMethod(matchedPaymentMethod[0]);
        }

        setPaymentMethod(e.target.value as string);

    }

    const handleWithdraw = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if((phoneNumber.length > 0) && !matchIsValidTel(phoneNumber)){
            toast.error("Veuillez entrer un numéro de téléphone valide.");
            return;
        }
 

        let data = new FormData(e.currentTarget);
        
        data.append('loaderText', 'Demande de retrait en cours...');

        axiosApi.post('sponsorships/withdraw.php', data)
        .then(res => {
            if(res.data.status === 'success'){
                setAmount('');
                props.getWithdrawalHistory()
            }
        })

    }

    return (
            
        <Box component="form" onSubmit={handleWithdraw} 
            sx={{ 
                mt: 1, 
                boxShadow:2,
                borderRadius: '3px',
                margin: 'auto',
                padding: '20px'
            }}
        >

            <Typography variant='h5' className='text-center' sx={{mb:2}}>
                Retrait
            </Typography>

            <Typography variant='h6' className='text-center' sx={{mb:2, fontSize:'small'}} >
                Solde : <b>{ readabilityNumber(sponsorshipInfos.commissions.availableBalance) + " CFT"}</b>
            </Typography>


            <TextField
                type="number"
                margin="normal"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                label="Montant à retirer"
                placeholder='Entrez le montant'
                name="amount"
                autoComplete="amount"
                required
                fullWidth
            />

            <FormControl fullWidth sx={{mb:1, mt:2}}>

                <InputLabel id="select_payment_method_label">Moyen de paiement</InputLabel>
                
                <Select
                    labelId="select_payment_method_label"
                    id="demo-simple-select"
                    name='paymentMethod'
                    label="Moyen de paiement"
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                    fullWidth
                    required
                    >

                    {
                        paymentMethods.map((method: paymentMethodType, index) => {
                            return (
                                <MenuItem key={index} 
                                            value={method.symbol}
                                            disabled={method.symbol !== cf_pay_info.symbol}> 
                                    <Avatar src={method.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                    <span>{method.symbol + " (" + method.name + ")"}</span>
                                </MenuItem>
                            )
                        })
                    }
        
                </Select>

            </FormControl>

            {
                (selectedPaymentMethod && (selectedPaymentMethod.symbol !== cftTokenInfo.symbol) && (selectedPaymentMethod.symbol !== cf_pay_info.symbol)) &&

                <>
                    <MuiTelInput 
                        label={"Numéro " + selectedPaymentMethod.name}
                        margin="normal"
                        id="paymentMethodNumber"
                        value={phoneNumber} 
                        onChange={(value) => setPhoneNumber(value)}
                        name="paymentMethodNumber"
                        autoComplete="paymentMethodNumber"
                        placeholder={"Exemple : +237 " + selectedPaymentMethod.address}
                        fullWidth
                        required 
                    />

                    <TextField
                        type="text"
                        margin="normal"
                        value={paymentMethodOwnerName}
                        onChange={(e) => setPaymentMethodOwnerName(e.target.value)}
                        id="paymentMethodOwnerName"
                        label={"Nom associé à votre compte " + selectedPaymentMethod.name}
                        name="paymentMethodOwnerName"
                        autoComplete="paymentMethodOwnerName"
                        placeholder={"Exemple : " + selectedPaymentMethod.accountOwnerName}
                        fullWidth
                        required
                        />
                </>
            }

            {
                (selectedPaymentMethod && (selectedPaymentMethod.symbol === cftTokenInfo.symbol)) &&
                <>
                    <TextField
                        type="text"
                        margin="normal"
                        value={cftReceptionAddress}
                        onChange={(e) => setCftReceptionAddress(e.target.value)}
                        id="cftReceptionAddress"
                        label="Adresse BEP20 (Binance Smart Chain)"
                        name="cftReceptionAddress"
                        autoComplete="cftReceptionAddress"
                        placeholder={"Exemple : " + cftTokenInfo.ownerAddress}
                        fullWidth
                        required
                        />

                    <Typography color='error' sx={{
                        fontSize: 'small'
                    }}>
                        Ne mettez pas une adresse de plate-forme d'échanges sinon vous perdrez vos fonds. Mettez plutôt une adresse de portefeuille privé comme <a href="https://trustwallet.com/" target='_blank'>Trust Wallet</a> ou <a href="https://metamask.io/" target='_blank'>Metamask</a>.
                    </Typography>
                </>
            }

            {
                (selectedPaymentMethod && (selectedPaymentMethod.symbol === cf_pay_info.symbol)) &&
                <Typography>

                    <Typography variant='caption'>
                        Votre solde parrainage sera débité et vous recevrez des CFT dans votre compte { cf_pay_info.name } sous forme de bonus.
                    </Typography>
                </Typography>
            }


            <Button
                type="submit"
                fullWidth
                variant="contained"
                color='info'
                sx={{ mt: 2 }}
                >
                Retirer
            </Button>

        </Box>
    )
}
