import React from 'react'
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { basicProps } from '../../utils/interfaces';

export default function DashboardHome(props: basicProps) {
    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 500,
                margin: 'auto'
            }}
        >
            
            <Typography
                fontWeight='bold'
                variant='h5'
                textAlign='center'
                sx={{
                    mb: 5
                }}
            >
                Dashboard Professionnel
            </Typography>

            <Link to='/pro-dashboard/phone-numbers/' className='link'>

                <Typography
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        boxShadow: 1
                    }}
                >
                    <Typography variant='h6' fontWeight='bold'>
                        Mes numéros de téléphone
                    </Typography>
                    <Typography>
                        <Typography variant='caption'>
                            Ajoutez jusqu'à 3 numéros de téléphone pour permettre à vos clients d'entrer en contact avec vous facilement.
                        </Typography>
                    </Typography>
                </Typography>

            </Link>

            <br />

            <Link to='/pro-dashboard/transaction-volumes/' className='link'>

                <Typography
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        boxShadow: 1
                    }}
                >
                    <Typography variant='h6' fontWeight='bold'>
                        Mes volumes de transactions
                    </Typography>
                    <Typography>
                        <Typography variant='caption'>
                            Obtenez une vue d'ensemble sur vos volumes transactions et commissions générées.
                        </Typography>
                    </Typography>
                </Typography>

            </Link>

        </Box>
    )
}
