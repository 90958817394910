import { Chip } from "@mui/material";
import { investPlanStatusType } from "../utils/interfaces";

export default function InvestPlanStatusBadge(props: {status: investPlanStatusType}) {

    let label = 'Achat';
    let color = '#338F5C';
    let bgColor = '#E8F3EE';

    switch (props.status) {
        case 'progress':
            label = 'En cours';
            color = '#338F5C';
            bgColor = '#E8F3EE';
        break;

        case 'completed':
            label = 'Terminé';
            color = 'var(--site-color)';
            bgColor = 'rgba(167, 202, 237, 0.2)';
        break;

        case 'canceled':
            label = 'Annulé';
            color = '#E5593C';
            bgColor = '#FDEDED';
        break;
    
        default:
            label = 'Achat';
            color = '#338F5C';
            bgColor = '#E8F3EE';
        break;
    }

    return (
        <Chip label={ label } 
            sx={{
                backgroundColor: bgColor,
                color: color,
                borderRadius: 1
            }} 
            variant="filled" 
            size="small" 
        />
    )
}

