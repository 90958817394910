import { useState, useEffect } from 'react'
import { basicProps } from '../../../../utils/interfaces'
import { Box, Button, TextField, Typography } from '@mui/material'
import axiosApi from '../../../../functions/axiosApi'
import { cftTokenInfo } from '../../../../functions/basicFunctions'

interface farmDataType {
    totalPool: number,
    totalRewards: number
}

export default function Farming(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [farmData, setFarmData] = useState<farmDataType>()

    useEffect(() => {
        getFarmInfos();
    }, [])
    

    const getFarmInfos = () => {

        axiosApi.post("ctr/finances/farming/get-farming-infos.php")
        .then(response => {
            if(response.data.status === 'success'){
                const responseData = response.data;
                setFarmData(responseData.farmData)
            }
        })
    }

    const sendRewards = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append("loaderText", "Retrait en cours...");

        basicDatas.dialogManager({
            dialogTitle: 'Confirmer',
            dialogText: "Vous envoyer " + data.getAll('amount') + " " + cftTokenInfo.symbol + " aux utilisateurs ayant déposé des fonds dans le programme de Farming. Souhaitez-vous vraiment exécuter cette action ?",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui envoyer"
          }, (response: string) => {
            
            if(response === 'ok') {

                axiosApi.post("ctr/finances/farming/send-rewards.php", data)
                .then(response => {

                    if(response.data.status === 'success') {
                        getFarmInfos();
                    }
                })
            }
        })
    }

    if(!farmData) return (
        <Box sx={{textAlign: 'center', mt: 5}}>
            Chargement...
        </Box>
    )

    return (
        <Box
            sx={{
                maxWidth: 400,
                margin: 'auto'
            }}>
            
            <Typography variant='h5' sx={{mt: 3, mb: 5, textAlign: 'center'}}>
                Farming
            </Typography>

            <Typography
                sx={{
                    p: 2,
                    borderRadius: 1,
                    boxShadow: 2,
                    mb: 3
                }}>

                <Typography variant='caption'>
                    Total engagé
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    { farmData.totalPool + ' ' + cftTokenInfo.symbol}
                </Typography>

            </Typography>

            <Typography
                sx={{
                    p: 2,
                    borderRadius: 1,
                    boxShadow: 2,
                    mb: 4
                }}>

                <Typography variant='caption'>
                    Total bonus payé
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    { farmData.totalRewards + ' ' + cftTokenInfo.symbol}
                </Typography>

            </Typography>

            
            <Box
                component='form'
                onSubmit={sendRewards}
                sx={{
                    boxShadow: 2,
                    p: 2,
                    borderRadius: 1
                }}
                >

                <Typography variant='h6' sx={{mb: 2, textAlign: 'center'}}>
                    Verser des bonus
                </Typography>

                <TextField 
                    name='amount'
                    type='number'
                    label="Montant à verser"
                    placeholder='Montant'
                    fullWidth
                    required
                    sx={{mb: 2}}
                />

                <TextField 
                    name='pinCode'
                    type='password'
                    label="Code PIN"
                    placeholder='PIN'
                    fullWidth
                    required
                    sx={{mb: 2}}
                />

                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    fullWidth
                    sx={{mb: 3}}
                />

                <Button
                    fullWidth
                    variant='contained'
                    type='submit'
                    >
                    Valider
                </Button>
            </Box>
        </Box>
    )
}
