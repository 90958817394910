import { useState, useEffect } from 'react'
import axiosApi from '../../../functions/axiosApi'
import { 
    Box, TableContainer, Table, Paper, TableBody, TableRow, 
    Typography, Button, TextField, FormControl, InputLabel,
    Select, MenuItem
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { User } from '../../../utils/interfaces';
import { copyClipboard } from '../../../functions/basicFunctions';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import QueryString from 'qs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));


interface logType {
    service: string,
    action: string,
    serviceContentID?: string,
    admin: User,
    date: string
}

export default function CtrsLogs() {

    const [logs, setLogs] = useState<logType[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [adminID, setAdminID] = useState('');
    const [queryValue, setQueryValue] = useState('');
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [service, setService] = useState("ALL_SERVICES")

    useEffect(() => {
        getLogs()
    }, [queryValue, adminID, year, month, day, hour, minutes, service])

    const getLogs = () => {

        setIsLoading(true);

        axiosApi.post("ctr/team-manager/get-ctrs-logs.php", QueryString.stringify({
            offset, 
            limit:5, 
            adminID,
            queryValue,
            year,
            month,
            day,
            hour,
            minutes,
            service
        }))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newLogsArray = logs.concat(response.data.logs);
                setLogs(newLogsArray);
                setOffset(offset + 5);

                if(response.data.logs.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }
    
    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto',
                pt: 5
            }}
        >
            
            <Typography variant='h6' sx={{textAlign: 'center', mb: 5}}>
                Historique activités admins
            </Typography>


            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 2,
                    mb: 2
                }}
            >

                <FormControl fullWidth>

                    <InputLabel id="select_year_label">Year</InputLabel>

                    <Select
                        labelId="select_year_label"
                        label="Year"
                        value={year}
                        onChange={(e) => {
                            setYear(e.target.value as any);
                            setLogs([]);
                            setOffset(0);
                            setEnded(false)
                        }}
                        fullWidth
                        required
                        >

                        {
                            [
                                0,2022,2023,2024,2025,2026,
                                2027,2028,2029,2030,2031,
                                2032,2033,2034,2035,2036
                            ].map((_year, index) => {

                                return (
                                    <MenuItem key={index} value={_year} > 
                                        <span>{ _year }</span>
                                    </MenuItem>
                                )
                            })
                        }

                    </Select>

                </FormControl>

                <FormControl fullWidth>

                    <InputLabel id="select_month_label">Month</InputLabel>

                    <Select
                        labelId="select_month_label"
                        label="Month"
                        value={month}
                        onChange={(e) => {
                            setMonth(e.target.value as any);
                            setLogs([]);
                            setOffset(0);
                            setEnded(false)
                        }}
                        fullWidth
                        required
                        >

                        {
                            [0,1,2,3,4,5,6,7,8,9,10,11,12].map((_month, index) => {

                                return (
                                    <MenuItem key={index} value={_month} > 
                                        <span>{ _month }</span>
                                    </MenuItem>
                                )
                            })
                        }

                    </Select>

                </FormControl>


                <FormControl fullWidth>

                    <InputLabel id="select_day_label">Day</InputLabel>

                    <Select
                        labelId="select_day_label"
                        label="Day"
                        value={day}
                        onChange={(e) => {
                            setDay(e.target.value as any);
                            setLogs([]);
                            setOffset(0);
                            setEnded(false)
                        }}
                        fullWidth
                        required
                        >

                        {
                            [
                                0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,
                                16,17,18,19,20,21,22,23,24,25,26,27,
                                28,29,30,31
                            ].map((_day, index) => {

                                return (
                                    <MenuItem key={index} value={_day} > 
                                        <span>{ _day }</span>
                                    </MenuItem>
                                )
                            })
                        }

                    </Select>

                </FormControl>

                <FormControl fullWidth>

                    <InputLabel id="select_hour_label">Hour</InputLabel>

                    <Select
                        labelId="select_hour_label"
                        label="Hour"
                        value={hour}
                        onChange={(e) => {
                            setHour(e.target.value as any);
                            setLogs([]);
                            setOffset(0);
                            setEnded(false)
                        }}
                        fullWidth
                        required
                        >

                        {
                            [
                                0,1,2,3,4,5,6,7,8,9,10,11,12,13,
                                14,15,16,17,18,19,20,21,22,23,24
                            ].map((_hour, index) => {

                                return (
                                    <MenuItem key={index} value={_hour} > 
                                        <span>{ _hour }</span>
                                    </MenuItem>
                                )
                            })
                        }

                    </Select>

                </FormControl>

                <FormControl fullWidth>

                    <InputLabel id="select_minutes_label">Minutes</InputLabel>

                    <Select
                        labelId="select_minutes_label"
                        label="Minutes"
                        value={minutes}
                        onChange={(e) => {
                            setMinutes(e.target.value as any);
                            setLogs([]);
                            setOffset(0);
                            setEnded(false)
                        }}
                        fullWidth
                        required
                        >

                        {
                            [
                                0,1,2,3,4,5,6,7,8,9,10,11,12,13,
                                14,15,16,17,18,19,20,21,22,23,
                                24,25,26,27,28,29,30,31,32,33,
                                34,35,36,37,38,39,40,41,42,43,
                                44,45,46,47,48,49,50,51,52,53,
                                54,55,56,57,58,59,60
                            ].map((_minutes, index) => {

                                return (
                                    <MenuItem key={index} value={_minutes} > 
                                        <span>{ _minutes }</span>
                                    </MenuItem>
                                )
                            })
                        }

                    </Select>

                </FormControl>

                <FormControl fullWidth>

                    <InputLabel id="select_service_label">Service</InputLabel>

                    <Select
                        labelId="select_service_label"
                        label="Service"
                        value={service}
                        onChange={(e) => {
                            setService(e.target.value as any);
                            setLogs([]);
                            setOffset(0);
                            setEnded(false)
                        }}
                        fullWidth
                        required
                        >

                        {
                            [
                                'ALL_SERVICES',
                                'TEAM_MANAGER',
                                'BUY_SELL_CRYPTO',
                                'FINANCES',
                                'COMMUNICATION',
                                'CURRENCIES',
                                'KYC',
                                'NETWORKS',
                                'PAYMENT_METHODS',
                                'SPONSORSHIPS',
                                'USERS'
                            ].map((_minutes, index) => {

                                return (
                                    <MenuItem key={index} value={_minutes} > 
                                        <span>{ _minutes }</span>
                                    </MenuItem>
                                )
                            })
                        }

                    </Select>

                </FormControl>

                <TextField 
                    type='search' 
                    label="ID Admin" 
                    placeholder="Entrez ID Admin"
                    value={adminID}
                    onChange={
                        (e) => {
                            setAdminID(e.target.value);
                            setLogs([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                />

                <TextField 
                    type='search' 
                    label="Rechercher" 
                    placeholder="Entrez userID, serviceContentID"
                    value={queryValue}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setLogs([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                />

            </Box>


            {
                logs.map((itemLog, i) => {
                    return (
                        <Box key={i}>

                            <TableContainer component={Paper}  sx={{mt:7}}>

                                <Table aria-label="customized table">

                                    <TableBody>

                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Admin
                                            </StyledTableCell>
                                            <StyledTableCell 
                                                align="left"
                                                sx={{cursor: 'pointer'}}
                                                onClick={() => copyClipboard(itemLog.admin.user_id, "ID admin copié")}
                                                >
                                                <b>{ itemLog.admin.firstName + ' ' + itemLog.admin.lastName }</b>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Service
                                            </StyledTableCell>
                                            <StyledTableCell 
                                                align="left"
                                                >
                                                <b>{ itemLog.service }</b>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                ID Contenu
                                            </StyledTableCell>
                                            <StyledTableCell 
                                                align="left"
                                                sx={{cursor: 'pointer'}}
                                                onClick={() => copyClipboard(itemLog?.serviceContentID + '', "ID contenu copié")}
                                                >
                                                <b>{ itemLog?.serviceContentID }</b>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Acttion
                                            </StyledTableCell>
                                            <StyledTableCell 
                                                align="left"
                                                sx={{cursor: 'pointer'}}
                                                >
                                                <b>{ itemLog.action }</b>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                Date
                                            </StyledTableCell>
                                            <StyledTableCell 
                                                align="left"
                                                sx={{cursor: 'pointer'}}
                                                >
                                                <b>{ itemLog.date }</b>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Box>
                    )
                })
            }

            <br /><br />

            <div className="text-center">
                
                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getLogs()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
