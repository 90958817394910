import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { basicProps } from '../utils/interfaces';
import axiosApi from '../functions/axiosApi';

const reactStringReplace = require('react-string-replace');
const nl2br = require('react-nl2br');


/**
 * Ce composant permet d'afficher un texte
 * @param {text} props String : (Obligatoire) le texte à afficher
 * @param {maxLength} props Int : (Facultatif) si cette valeur est spécifiée, alors on 
 * affiche {maxLength} caractères et un bouton "Tout afficher" apparait
 * @param {linkColor} props String : (Facultatif) la couleur des liens figurants dans le texte (couleur principale du site par défaut).
 * @param {showAllTestBtn} props Component : Bouton (Tout afficher) personnalisé.
 */

interface textProps extends basicProps {
    text: string,
    linkColor?: string,
    maxLength?: number,
    nl2br?: boolean,
    displayShowAllBtn?: boolean,
    showAllTestBtn?: boolean
}

export default function Text(props: textProps) {

    const basicDatas = props.basicDatas;

    let navigate = useNavigate();

    const [text, set_text] = useState(props.text ? props.text : '')
    const [show_all_text, set_show_all_text] = useState(false);
    let linkColor = props.linkColor ? props.linkColor : 'var(--site-color)';

    useEffect(() => {
        
        if(text && props.maxLength && (text.length > props.maxLength)){

            var replacedText = text.substring(0, props.maxLength) + "...";
            set_text(replacedText);
            set_show_all_text(false);
            
        }

        else {
            set_text(props.text);
            set_show_all_text(true);
        }

    }, [props.text]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        
        if(show_all_text) set_text(props.text ? props.text : '');

    }, [show_all_text]) // eslint-disable-line react-hooks/exhaustive-deps

    let verifyUrl = (url) => {

        var splitUrlHttps = url.split(/https?:\/\//gi);
        var newUrl = splitUrlHttps[1] ? splitUrlHttps[1] : '';
        var splitUrlDomain = newUrl.split('/');
        var domain = splitUrlDomain[0];
        domain = domain && (domain !== '') ? domain.toLowerCase() : '';
        domain = domain.replace('www.', '');

        var parseDomain = domain.split(':');
        domain = parseDomain[0];

        var isExternal = (domain === window.location.hostname) ? false : true;

        return { isExternal, domain };
    }

    let openLink = (e, url, secured = true) => {
        
        e.preventDefault();

        var { isExternal, domain } = verifyUrl(url);


        if(!isExternal){

            var splitUrlHttps = url.split(/https?:\/\//gi);
            var newUrl = splitUrlHttps[1] ? splitUrlHttps[1] : '';
            var splitUrlDomain = newUrl.split('/');
            var urlDomain = splitUrlDomain[0];
            urlDomain = urlDomain && (urlDomain !== '') ? urlDomain.toLowerCase() : '';
            urlDomain = urlDomain.replace('www.', '');

            var splitUrl = url.split(urlDomain);
            var urlParams = splitUrl[1] ? splitUrl[1] : '/';

            navigate(urlParams);
            
        }

        else {

            basicDatas.dialogManager({
                dialogTitle: "Lien externe",
                dialogText: "Vous allez être rédirigé.e vers un site externe : " + domain,
                dialogAgreeBtnColor: 'info',
                dialogAgreeBtnText: "Ouvrir"
              }, (response: string) => {
                
                if(response === 'ok') {
    
                    window.open(url)
                }
          
            })

        }
        
    }

    var replacedText = text;

    if(show_all_text || !props.maxLength){

        if(props.nl2br !== false) {

            replacedText = reactStringReplace(text, /([\n])+/gi, (match, i) => (
                <br key={match+i+Math.random()} />
            ));
        }

        replacedText = reactStringReplace(replacedText, /@(\w+)/gi, (match, i) => (
            <Link   to={"/profile/" + match + "/"} 
                    className="cursor_pointer" 
                    key={match+i+Math.random()} 
                    title={match}
                    style={{color:linkColor}} >

                {"@"+match}

            </Link>
        ));

        replacedText = reactStringReplace(replacedText, /#(\w+)/gi, (match, i) => (
            <span   className="text_bold" 
                    key={match + i + Math.random()} 
                    title={match} >

                {match}

            </span>
        ));

        replacedText = reactStringReplace(replacedText, /(https?:\/\/[^\s]+)/g, (match, i) => (
            <a  key={match + i + Math.random()}  
                href={match} 
                onClick={(e) => openLink(e, match)}
                target='_blank'  
                rel='noreferrer'
                style={{color:linkColor}}
                className='link'>
                
                { match }

            </a>
        ));

        replacedText = reactStringReplace(replacedText, /([a-z][^\s]+\.[^\s]+)/gi, (match, i) => (
            <a  key={match + i + Math.random()}  
                href={match}
                onClick={(e) => openLink(e, 'https://' + match)}
                target='_blank'  
                rel='noreferrer'
                style={{color:linkColor}}
                className='link'>
                
                {match}
            </a>
        ));
    }


    if(!props.text) return <></>;


    return (
        <>

            {props.nl2br !== false ? nl2br(replacedText) : replacedText}

            {((!show_all_text) && (props.displayShowAllBtn !== false)) && !props.showAllTestBtn && 

                <span   className="cursor-pointer text-oblique" 
                        onClick={() => set_show_all_text(true)}
                        title="Afficher tout le texte"
                        style={{fontStyle: 'oblique', fontSize: 'small', fontWeight:'bold'}}>
                    Tout afficher
                </span>
            }

            {
                ((!show_all_text) && (props.displayShowAllBtn !== false)) && props.showAllTestBtn &&
                props.showAllTestBtn
            }

        </>
    )
}
