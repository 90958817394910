import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import { basicProps } from '../../../utils/interfaces'
import NewsLetter from './news-letter/NewsLetter'
import ShortLinkst from './links/ShortLinkst'
import PushMessages from './push-messages/PushMessages'
import Banners from './banners/Banners'
import MailList from './news-letter/emails/MailList'

export default function Com(props: basicProps) {
    return (
        <div>
            <Routes>

                <Route path='/' element={
                    <div style={{textAlign:'center'}}>

                    <br /><br />

                    <Link to="/ctr/communication/links/" className='link' style={{fontSize:'18px'}}>Liens</Link>
                    <br /><br />

                    <Link to="/ctr/communication/banners/" className='link' style={{fontSize:'18px'}}>Bannières</Link>
                    <br /><br />

                    <Link to="/ctr/communication/news-letter/" className='link' style={{fontSize:'18px'}}>News letter</Link>
                    <br /><br />

                    <Link to="/ctr/communication/push-messages/" className='link' style={{fontSize:'18px'}}>Messages Push</Link>
                    <br /><br />
              
                  </div>
                } />

                <Route path='/news-letter/*' element={<NewsLetter {...props} />} />
                <Route path='/links/*' element={<ShortLinkst {...props} />} />
                <Route path='/banners/*' element={<Banners {...props} />} />
                <Route path='/push-messages/*' element={<PushMessages {...props} />} />

            </Routes>
        </div>
    )
}
