import React from 'react'
import { basicProps } from '../../utils/interfaces'
import { Box } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import Preview from './Preview'
import Asset from './Asset'
import Transfer from './transfer/Transfer';
import UserIsNotConnectedMessage from '../../components/UserIsNotConnectedMessage';
import { MetaTags } from 'react-meta-tags';
import { cf_pay_info } from '../../functions/basicFunctions'
import Recharge from './recharge/Recharge'
import Farming from '../invest/farming/Farming'

export default function Wallet(props: basicProps) {

    const basicDatas = props.basicDatas;

    if(!basicDatas.isLoggedIn) return (
        <div className='text-center' style={{maxWidth:'500px', margin: 'auto', marginTop:'60px'}}>
            <MetaTags id="wallet">
                <title>{'Portefeuille ' + cf_pay_info.name}</title>
                <meta name="description" content={'Portefeuille ' + cf_pay_info.name} />
                <meta property="og:title" content={'Portefeuille ' + cf_pay_info.name} />
            </MetaTags>
            <UserIsNotConnectedMessage message="Pour créer un ordre, veuillez vous identifier pour nous permettre d'avoir les informations necessaires pour votre transaction." />
        </div>
    )
    
    return (
        <Box>

            <MetaTags id="wallet">
                <title>{'Portefeuille ' + cf_pay_info.name}</title>
                <meta name="description" content={'Portefeuille ' + cf_pay_info.name} />
                <meta property="og:title" content={'Portefeuille ' + cf_pay_info.name} />
            </MetaTags>
            
            <Routes>
                <Route path='/' element={<Preview />} />
                <Route path='/farming' element={<Farming basicDatas={basicDatas} />} />
                <Route path='/:assetSymbol/recharge' element={<Recharge basicDatas={basicDatas} />} />
                <Route path='/:assetSymbol/transfer' element={<Transfer basicDatas={basicDatas} />} />
                <Route path='/:assetSymbol' element={<Asset basicDatas={basicDatas} />} />
            </Routes>

        </Box>
    )
}
