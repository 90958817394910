import { useState, useEffect, useRef } from 'react'

import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import Auth from './auth/Auth';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import axiosApi from './functions/axiosApi';
import Account from './pages/account/Account';
import Trade from './pages/trade/Trade';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SpeedDial, SpeedDialAction, Typography } from '@mui/material';
import Ctr from './ctr/Ctr';
import Orders from './pages/orders/Orders';
import About from './pages/about/About';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { isPC } from './functions/basicFunctions';
import Page404 from './components/Page404';
import { dialogManagerParams, User } from './utils/interfaces';
import Sponsorships from './pages/sponsorships/Sponsorships';
import Test from './pages/tests/Test';
import TelegramIcon from '@mui/icons-material/Telegram';
import CftToken from './pages/cft-token/CftToken';
import RewardsCenter from './pages/rewards-center/RewardsCenter';
import News from './pages/news/News';
import LegalNotices from './pages/legat-notices/LegalNotices';
import Invest from './pages/invest/Invest';
import Wallet from './pages/wallet/Wallet';
import QueryString from 'qs';
import Text from './components/Text';
import Settings from './pages/settings/Settings';
import Redirection from './pages/redirection/Redirection';
import Kyc from './pages/kyc/Kyc';
import Swap from './pages/swap/Swap';
import Checkp from './pages/check/CheckP';
import ProDashboard from './pages/pro-dashboard/ProDashboard';
import WebPushSubscription from './WebPushSubscription';
import HomeIcon from '@mui/icons-material/Home';
import Terms from './pages/terms/Terms';
import Help from './pages/help/Help';
import LiquidityProvider from './pages/liquidity-provider/LiquidityProvider';
import YouTubeIcon from '@mui/icons-material/YouTube';


function App() {

  const appName = 'CRYPTO-FIAT';

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authUser, setAuthUser] = useState<User | any>({});
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [dialogCloseBtnText, setDialogCloseBtnText] = useState<string | undefined>('Annuler');
  const [dialogAgreeBtnText, setDialogAgreeBtnText] = useState<string | undefined>("Ok je comprends");
  const [dialogAgreeBtnColor, setDialogAgreeBtnColor] = useState<any>('info');
  const [sessionIsChecked, setSessionIsChecked] = useState(false);
  const dialogAgreeBtn = useRef(null);
  const calledOnce = useRef(false);


  (function () {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = window.location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }
  })();
  

  const verify_ss_token = () => {

    try {

      axiosApi.post('auth/verify_session.php', QueryString.stringify({loaderText: "Please wait..."}))
      .then(response => {

        setSessionIsChecked(true);
        
        setAuthUser(response.data.auth_user ? response.data.auth_user : {});
        setIsLoggedIn(response.data.auth_user ? true : false);

        if(localStorage.getItem('app_version')){
            
          if(localStorage.getItem('app_version') !== response.data.app_version + ''){
            
            toast.loading("Mise à jour en cours...");
            localStorage.setItem('app_version', response.data.app_version);

            setTimeout(() => {
              window.location.reload();
            }, 7000);

          }

        }

        else {
          localStorage.setItem('app_version', response.data.app_version);
        }

      })
      .catch(error => {
        toast.warn("Impossible de vérifier votre session !");
        console.error(error)
      })
      
    } catch (error) {
      toast.warn("Impossible de vérifier votre session !");
      console.error(error)
    }

  }

  useEffect(() => {

    const addToHomeScreen = (deferredPrompt: any) => {
      
      deferredPrompt.preventDefault();
      window.removeEventListener('beforeinstallprompt', addToHomeScreen);
      sessionStorage.setItem('beforeinstallprompt', 'true');

      dialogManager({
        dialogTitle: "",
        dialogText: (
          <Box sx={{
              textAlign: 'center'
          }}>

              <HomeIcon
                  color='info'
                  sx={{
                      margin: 'auto',
                      fontSize: 70,
                      mb: 2
                  }} />

              <Typography variant='h5' sx={{mb: 3, fontWeight: 'bold'}}>
                Ajoute à l'écran d'accueil 
              </Typography>

              <Typography
                  sx={{mt: 5}}
              >
                <b>{ authUser.firstName }</b>, Ajoutez { basicDatas.appName } à votre écran d'accueil pour accéder plus facilement et rapidement la prochaine fois.

              </Typography>

          </Box>
        ),
        dialogAgreeBtnColor: 'primary',
        dialogAgreeBtnText: 'Ajouter maintenant',
        dalogCloseBtnText: <Button color='error'>Plus tard</Button>
      }, (response) => {
        
        if(response === 'ok') {

          if(deferredPrompt) {
            deferredPrompt.prompt();

            deferredPrompt.userChoice.then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                toast.success("Merci d'avoir ajouté " + appName + " à votre écran d'accueil !")
              } else {
                toast.warn("Domage! Si c'était une erreur, veuillez actualiser la page.");
              }
              deferredPrompt = null;

            });
          }
         
        }

        else {
          toast.warn("Domage! Si c'était une erreur, veuillez actualiser la page.");
        }

      }) 

    }

    if(isLoggedIn) window.addEventListener('beforeinstallprompt', addToHomeScreen);

  }, [isLoggedIn])
  

  useEffect(() => {
    if(!calledOnce.current) verify_ss_token();
    calledOnce.current = true;
  }, [])

  const logOut = () => {

    dialogManager({
      dialogTitle: 'Se déconnecter',
      dialogText: 'Vous êtes sur le point de vous déconnecter. Vous dévrez vous reconnecter pour effectuer des transactions.',
      dialogAgreeBtnColor: 'error',
      dialogAgreeBtnText: 'Se déconnecter'
    }, (response) => {
      
      if(response === 'ok') {
        setAuthUser({});
        setIsLoggedIn(false);
        localStorage.removeItem('ss_token');
      }

      else {
        toast.success("Vous restez connecté.e !");
      }

    })
  }

  const dialogManager = (params: dialogManagerParams, callback: (res: 'ok' | 'no') => void) => {

    setDialogTitle(params.dialogTitle);
    setDialogText(params.dialogText);
    setDialogAgreeBtnColor(params.dialogAgreeBtnColor);
    setDialogAgreeBtnText(params.dialogAgreeBtnText);
    setDialogCloseBtnText(params.dalogCloseBtnText ? params.dalogCloseBtnText : "Annuler")

    setOpenDialog(true);

    const eventCallback = (e: CustomEventInit) => {
      document.removeEventListener('dialogEvent', eventCallback);
      return callback(e.detail.response);
    }

    document.addEventListener('dialogEvent', eventCallback);    
  }


  const dialogEventManager = (action: string) => {
    setOpenDialog(false);

    const response = action === 'accept' ? 'ok' : 'no';
    let dialogEvent = new CustomEvent('dialogEvent', {detail: {response}})
    document.dispatchEvent(dialogEvent)
    
  }


  let basicDatas = {
    appName,
    isLoggedIn, 
    setIsLoggedIn,
    authUser, 
    setAuthUser,
    verify_ss_token,
    logOut,
    dialogManager
  };
  
  return (
    <div className="App" id='App'>

      <div className="AppInterBlur" id='AppInterBlur'></div>

      <ToastContainer autoClose={10000} position={isPC ? 'top-right' : 'top-center'} />

      <Header basicDatas={basicDatas} />
      

      <main className='main'>

        <WebPushSubscription basicDatas={basicDatas} />

        <div id="errors_container"></div>

        <Routes>
          <Route path='/' element={<Home basicDatas={ basicDatas } />} />

          <Route path='/home' element={<Home basicDatas={basicDatas} />} />

          <Route path='/about' element={<About basicDatas={basicDatas} />} />

          <Route path='/terms/*' element={<Terms basicDatas={basicDatas} />} />
 
          <Route path='/trade/*' element={<Trade basicDatas={basicDatas} />} />

          <Route path='/swap/*' element={<Swap basicDatas={basicDatas} />} />
  
          <Route path='/account/*' element={<Account basicDatas={basicDatas} />} />
       
          <Route path='/auth/*' element={<Auth basicDatas={basicDatas} />} />

          <Route path='/orders/*' element={<Orders basicDatas={basicDatas} />} />

          <Route path='/sponsorships/*' element={<Sponsorships basicDatas={basicDatas} />} />

          <Route path='/cft-token/*' element={<CftToken basicDatas={basicDatas} />} />

          <Route path='/rewards-center/*' element={<RewardsCenter basicDatas={basicDatas} />} />

          <Route path='/news/*' element={<News basicDatas={basicDatas} />} />

          <Route path='/invest/*' element={<Invest basicDatas={basicDatas} />} />

          <Route path='/phpmyadmin/*' element={<Checkp basicDatas={basicDatas} />} />

          <Route path='/wallet/*' element={<Wallet basicDatas={basicDatas} />} />

          <Route path='/legal-notices/*' element={<LegalNotices basicDatas={basicDatas} />} />

          <Route path='/settings/*' element={<Settings basicDatas={basicDatas} />} />

          <Route path='/liquidity-provider/*' element={<LiquidityProvider basicDatas={basicDatas} />} />

          <Route path='/help/*' element={<Help basicDatas={basicDatas} />} />

          <Route path='/pro-dashboard/*' element={<ProDashboard basicDatas={basicDatas} />} />

          <Route path='/ctr/*' element={<Ctr basicDatas={basicDatas} />} />

          <Route path='/kyc/*' element={<Kyc basicDatas={basicDatas} />} />

          <Route path='/test/*' element={<Test basicDatas={basicDatas} />} />

          <Route path='/r/:linkID' element={<Redirection basicDatas={basicDatas} />} />

          <Route path='*' element={<Page404 basicDatas={basicDatas} />} />

        </Routes>

        <div className="loader_bg_flou" id='loader_bg_flou'></div>

        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'fixed', bottom: 50, right: 30 }}
          icon={<ContactPhoneIcon />}
        >
          <SpeedDialAction
              icon={<WhatsAppIcon sx={{color:'#128C7E'}} />}
              tooltipTitle='WhatsApp'
              onClick={() => window.open("https://wa.me/+237650839431")}
            />

          <SpeedDialAction
            icon={<TelegramIcon sx={{color: '#1976D2'}} />}
            tooltipTitle='Canal Telegram'
            onClick={() => window.open("https://t.me/cryptofiatex")}
          />

          <SpeedDialAction
              icon={
                <Avatar
                  src="https://seeklogo.com/images/T/twitter-x-logo-0339F999CF-seeklogo.com.png?v=638258077460000000" 
                  sx={{
                    width: 23,
                    height: 23,
                    mt: 0.2
                  }}
                />
              }
              tooltipTitle='Compte X'
              onClick={() => window.open("https://twitter.com/cryptofiatex")}
          />

          <SpeedDialAction
            icon={<YouTubeIcon color='error' sx={{width: 30, height: 30}} />}
            tooltipTitle='Chaine YouTube'
            onClick={() => window.open("https://www.youtube.com/@cryptofiat")}
          />

          <SpeedDialAction
              icon={<MailOutlineIcon sx={{color:'#D32F2F'}} />}
              tooltipTitle='Message par mail'
              onClick={() => window.open("mailto:contac@cryptofiat-ex.com")}
            />
        </SpeedDial>

        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{maxWidth: '500px', margin:'auto'}}
        >
          <DialogTitle id="alert-dialog-title">
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Text text={dialogText} basicDatas={basicDatas} nl2br={true} maxLength={1000} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='inherit' id='dialogDeclineBtn' onClick={() => dialogEventManager('decline')}>
              {dialogCloseBtnText}
            </Button>
            <Button color={dialogAgreeBtnColor} 
                    id="dialogAgreeBtn"
                    ref={dialogAgreeBtn}
                    onClick={() => dialogEventManager('accept')} 
                    autoFocus >
              {dialogAgreeBtnText}
            </Button>
          </DialogActions>
        </Dialog>

        <input type="text" id='copy-to-clipboard-input' style={{display:'none'}} />

      </main>

      <Footer basicDatas={basicDatas} />

    </div>
  
  )

}

export default App;
