import {useState, useEffect} from 'react'
import { basicProps } from '../utils/interfaces';
import axiosApi from '../functions/axiosApi';
import QueryString from 'qs';
import { Avatar, Skeleton } from '@mui/material';


export default function QrCodeDisplay(props: any) {

    const text = props.text;

    const [imgSrc, setImgSrc] = useState("")

    useEffect(() => {

        axiosApi.post("qrcode/generate-qrcode.php", QueryString.stringify({text}))
        .then(response => {

            if(response.data.status === 'success') {

                let src = "data:image/png;base64, " + response.data.imageData;

                let img = new Image();
                img.src = src;

                img.addEventListener('load', (e) => {
                    setImgSrc(src)
                })
            }
        })

    }, [])


    if(imgSrc.length == 0) return (
        <Skeleton 
            variant='rectangular'
            animation='wave'
            {...props}
        />
    )



    return (
        <Avatar 
            src={imgSrc} alt="Image" 
            {...props}
        />
    )
}
