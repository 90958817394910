import { Box, TextField, Typography, Button, Stack, Skeleton, Avatar } from '@mui/material'
import { useEffect, useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { basicProps } from '../../../utils/interfaces';
import { cftTokenInfo, isPC, publicPath } from '../../../functions/basicFunctions';
import { MetaTags } from 'react-meta-tags';
import AgricultureIcon from '@mui/icons-material/Agriculture';

interface farmData {
    userBalance: number,
    rewards: number
}

export default function Farming(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [farmData, setfarmData] = useState<farmData>()

    useEffect(() => {
        getFarmInfos();
    }, [])
    

    const getFarmInfos = () => {
        axiosApi.post("invest/farming/get-farm-infos.php")
        .then(response => {

            if(response.data.status === 'success') {
                const responseData = response.data.farmData;
                setfarmData(responseData);
            }
        })
    }

    const addFunds = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append("loaderText", "Traitement en cours...");

        basicDatas.dialogManager({
            dialogTitle: 'Confirmer',
            dialogText: "Votre compte sera débité de " + data.getAll('amount') + " " + cftTokenInfo.symbol + " pour le règlement de cette commande. Souhaitez-vous vraiment exécuter cette action ?",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui investir"
          }, (response: string) => {
            
            if(response === 'ok') {
                axiosApi.post("invest/farming/add-funds.php", data)
                .then(response => {
                    if(response.data.status === 'success') {
                        getFarmInfos();
                    }
                });
            }
        })
 
    }

    const withdrawal = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append("loaderText", "Retrait en cours...");

        basicDatas.dialogManager({
            dialogTitle: 'Confirmer',
            dialogText: "Vous allez déplacer " + data.getAll('amount') + " " + cftTokenInfo.symbol + " vers votre portefeuille. Souhaitez-vous vraiment exécuter cette action ?",
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui retirer"
          }, (response: string) => {
            
            if(response === 'ok') {
                axiosApi.post("invest/farming/withdrawal.php", data)
                .then(response => {
                    if(response.data.status === 'success') {
                        getFarmInfos();
                    }
                });
            }
        })
 
    }

    if(!farmData) return (
        <Box
            sx={{
                pt: 4,
                maxWidth: 600,
                margin: 'auto'
            }}>

            <MetaTags id="farming">
                <title>{'Farming'}</title>
                <meta name="description" content="Farming" />
                <meta property="og:title" content="Farming" />
            </MetaTags>

            <Typography
                variant='h5'
                sx={{
                    textAlign: 'center',
                    mb: 5
                }}>

                Farming
            </Typography>

            <Stack spacing={5}>
                <Skeleton variant='rectangular' width='100%' height={150} />
                <Skeleton variant='rectangular' width='100%' height={150} />
                <Skeleton variant='rectangular' width='100%' height={150} />
            </Stack>

        </Box>
    )

    return (
        <Box  
            sx={{
                maxWidth: 600,
                margin: 'auto'
            }}>

            <MetaTags id="farming">
                <title>{'Farming'}</title>
                <meta name="description" content="Farming" />
                <meta property="og:title" content="Farming" />
            </MetaTags>

            <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 5
                }}>

                <img 
                    src={publicPath + "/images/farming_banner.png"} 
                    alt="CRYPTO-FIAT"
                    style={{
                        width: '100%',
                        borderBottomLeftRadius: '5px',
                        borderBottomRightRadius: '5px'
                    }} />

            </Box>

            <Box
                sx={{
                    boxShadow: 2,
                    borderRadius: 1,
                    p: 2,
                    mb: 5
                }}>

                <Typography
                    sx={{mb: 1, fontWeight: 'bold'}}>
                    Ajouter des fonds
                </Typography>

                <Typography variant='caption'>
                    Lorsque vous investissez vos fonds, { basicDatas.appName } les utilise comme liquidité afin de rendre les transactions plus rapides.
                    <br />
                    En retour, vous recevez des bonus en fonction de votre montant investi
                </Typography>

                <Box
                    component="form"
                    onSubmit={addFunds}
                    
                    >

                    <Typography
                        sx={{
                            display: isPC ? 'flex' : 'block',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mt: 2
                        }}
                        >

                        <TextField
                            type='number'
                            inputProps={{step: 0}}
                            name='amount'
                            label="Quantité CFT à investir"
                            placeholder='Min 25000 CFT'
                            fullWidth
                            required
                            />

                        <TextField
                            type='password'
                            name='pinCode'
                            label="Code PIN"
                            placeholder='PIN'
                            fullWidth
                            required
                            sx={{
                                ml: isPC ? 2 : 0,
                                mt: isPC ? 0 : 2
                            }}
                            />
                    </Typography>
                    

                    <Button
                        variant='contained'
                        type='submit'
                        fullWidth
                        sx={{mt: 2}}
                        >
                        Ajouter { cftTokenInfo.symbol }
                    </Button>

                </Box>

            </Box>


            <Box
                sx={{
                    mb: 5,
                    display: 'grid',
                    gap: 5,
                    gridTemplateColumns: isPC ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'
                }}>

                <Box sx={{
                    borderRadius: 1,
                    boxShadow: 2,
                    p: 2
                }}>
                
                    <Typography
                        sx={{fontWeight: 'bold'}}>
                        Semence
                    </Typography>

                    <Typography variant='caption'>
                        Montant total que vous avez engagé
                    </Typography>

                    <Typography
                        variant='h5'
                        sx={{mt: 2, fontWeight: 'bold', color: '#1976D2'}}>
                        { farmData.userBalance } <Typography variant='caption'>{ cftTokenInfo.symbol }</Typography>
                    </Typography>

                </Box>

                <Box sx={{
                    p: 2,
                    borderRadius: 1,
                    boxShadow: 2
                }}>
                
                    <Typography
                        sx={{fontWeight: 'bold'}}>
                        Récoltes
                    </Typography>

                    <Typography variant='caption'>
                        Montant total que vous avez gagné
                    </Typography>

                    <Typography
                        variant='h5'
                        color="info"
                        sx={{mt: 2, fontWeight: 'bold', color:'#2E7D32'}}>
                        { farmData.rewards } <Typography variant='caption'>{ cftTokenInfo.symbol }</Typography>
                    </Typography>

                </Box>

            </Box>


            <Box
                sx={{
                    boxShadow: 2,
                    borderRadius: 1,
                    p: 2
                }}>

                <Typography
                    sx={{mb: 1, fontWeight: 'bold'}}>
                    Retirer des fonds
                </Typography>

                <Typography variant='caption'>
                    Lorsque vous mettez les fonds en staking, ils doivent rester vérouillés pendant au moins 10 jours avant que vous ne puissiez faire un retrait.
                </Typography>

                <Box
                    component="form"
                    onSubmit={withdrawal}
                    
                    >

                    <Typography
                        sx={{
                            display: isPC ? 'flex' : 'block',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mt: 2
                        }}
                        >

                        <TextField
                            type='number'
                            inputProps={{step: 0}}
                            name='amount'
                            label="Quantité CFT à retirer"
                            placeholder='Min 10000 CFT'
                            fullWidth
                            required
                            />

                        <TextField
                            type='password'
                            name='pinCode'
                            label="Code PIN"
                            placeholder='PIN'
                            fullWidth
                            required
                            sx={{
                                ml: isPC ? 2 : 0,
                                mt: isPC ? 0 : 2
                            }}
                            />
                    </Typography>
                    

                    <Button
                        variant='contained'
                        type='submit'
                        color='error'
                        fullWidth
                        sx={{mt: 2}}
                        >
                        Retirer { cftTokenInfo.symbol }
                    </Button>

                </Box>

            </Box>

            <Typography variant='h5' sx={{mt: 5, mb: 2, fontWeight: 'bold'}}>
                Comment ça fonctionne ?
            </Typography>

            <Typography sx={{mb: 2}}>

                <Typography variant='h6' sx={{mb: 1}}>
                    Pourquoi le service de Farming ?
                </Typography>
                
                <Typography variant='caption'>
                    Lorque vous engagez vos fonds, { basicDatas.appName } les utilise pour augmenter sa liquidité ce qui lui permet de pouvoir servir les commandes en un temps plus court.
                    <br />
                    Votre liquidité permet également à { basicDatas.appName } de dépenser moins en frais de transactions. <br />
                    En retour, vous recevez tous les jours ouvrables des commissions directement dans votre compte en fonction du montant engagé.
                    <br />
                    Vous pouvez retirer vos fonds après un delai minimum de 10 jours à partir de votre dernier ajout.
                </Typography>
            </Typography>

            <Typography>

                <Typography variant='h6' sx={{mb: 1}}>
                    Comment les gains sont calculés ?
                </Typography>

                <Typography variant='caption'>
                    La commission que vous recevez dépend du montant que vous avez engagé. <br />
                    Supposons que tous les participants ont engagé un total de <b>1,000,000 CFT</b> et que Ali en fait partie avec un engagement de <b>100,000 CFT</b>. <br />
                    Dans ce cas :

                    <ul>
                        <li>
                            Ali a une participation équivalente à 100,000X100/100,000,000=<b>10%</b> du total.
                        </li>
                        <li>
                            Si { basicDatas.appName } verse <b>20,000 CFT</b> aux participants, alors Ali recevra <b>20,000X20/100=2000 CFT</b> dans son compte CRYPTO-FIAT sous-forme de bonus.
                        </li>
                    </ul>

                    <b>NB : </b> Veuillez noter que si votre semence est inférieur à <b>5000 { cftTokenInfo.symbol }</b>, vous ne recevrez pas de récoltes.
                </Typography>
            </Typography>

        </Box>
    )
}
