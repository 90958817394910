import { useEffect, useState } from 'react'
import { banner, basicProps } from '../../../../utils/interfaces'
import { Box, Button, Typography } from '@mui/material'
import AddBanner from './AddBanner'
import axiosApi from '../../../../functions/axiosApi'
import QueryString from 'qs'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import ItemBanner from './ItemBanner'

export default function Banners(props: basicProps) {

    const [banners, setBanners] = useState<banner[]>([]);
    const [numUsers, setNumUsers] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getUsers()
    }, [queryValue])

    const getUsers = () => {

        setIsLoading(true);

        axiosApi.post("ctr/communication/banners/get-banners.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newUsersArray = banners.concat(response.data.banners);
                setBanners(newUsersArray);
                setNumUsers(response.data.numUsers);
                setOffset(offset + 5);

                if(response.data.banners.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 500,
                margin: 'auto'
            }}
        >

            <Typography variant='h5' sx={{mb: 2, textAlign: 'center'}}>
                Gestion des Bannières
            </Typography>

            <AddBanner />

            <br />

            {
                banners.map((itemBanner, i) => {
                    return (
                        <ItemBanner
                            key={i}
                            banner={itemBanner}
                            { ...props }
                        />
                    )
                })
            }

            <br />

            <div className="text-center">
    
                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getUsers()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
