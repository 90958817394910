import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { Box, Table, TableBody, TableContainer, Typography, TableRow, Paper, Stack, Button } from '@mui/material';
import { basicProps, investPlanType } from '../../../utils/interfaces';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { copyClipboard, readabilityNumber } from '../../../functions/basicFunctions';
import InvestPlanStatusBadge from '../../../components/InvestPlanStatusBadge';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function ItemInvestPlan(props: basicProps) {

    const { investPlanID } = useParams();
    const basicDatas = props.basicDatas;

    const [investPlanData, setInvestPlanData] = useState<investPlanType>();
    const [sw, setSw] = useState('');
    
    useEffect(() => {
        
        axiosApi.post('ctr/liquidity/get-plan-info.php', QueryString.stringify({investPlanID}))
        .then(response => {

            if(response.data.status === 'success') {
                setInvestPlanData(response.data.investPlanData)
            }
        })

    }, [investPlanID])

    const deletePlan = (_planID: string) => {

        basicDatas.dialogManager({
            dialogTitle: 'Supprimer un plan',
            dialogText: "Souhaitez-vous vraiment supprimer ce plan d'investissement ?",
            dialogAgreeBtnColor: 'error',
            dalogCloseBtnText: 'Non',
            dialogAgreeBtnText: 'Oui supprimer'
          }, (response) => {
            
            if(response === 'ok') {

                const data = {
                    loaderText: "Suppression en cours...",
                    investPlanID: _planID,
                    sw
                }
                
                axiosApi.post("ctr/liquidity/delete-investment-plan.php", QueryString.stringify(data))
                .then(() => {})
            }
      
        })

    }

    const cancelPlan = (_planID: string) => {

        basicDatas.dialogManager({
            dialogTitle: 'Supprimer un plan',
            dialogText: "Souhaitez-vous vraiment annuler ce plan d'investissement ?",
            dialogAgreeBtnColor: 'error',
            dalogCloseBtnText: 'Non',
            dialogAgreeBtnText: 'Oui annuler'
          }, (response) => {
            
            if(response === 'ok') {

                const data = {
                    loaderText: "Annulation en cours...",
                    investPlanID: _planID,
                    sw
                }
                
                axiosApi.post("ctr/liquidity/cancel-investment-plan.php", QueryString.stringify(data))
                .then(() => {})
            }
      
        })

    }

    if(!investPlanData) return (
        <Box>
            <Typography textAlign='center' sx={{mt: 3}}>
                Chargement...
            </Typography>
        </Box>
    )

    const user = investPlanData.user;
    
    return (
        <Box>

            <Typography 
                variant='h5'
                textAlign='center'
                sx={{
                    mt: 2,
                    mb: 5
                }}
            >
                Plan d'investissement <br />
                { investPlanID }
            </Typography>
            
            <TableContainer 
                component={Paper}  
                sx={{
                    mt: 4,
                    opacity: ((investPlanData.paymentExistForCurrentMonth > 0) || (investPlanData.status !== 'progress')) ? 0.5 : 1
                }}
            >

                <Table aria-label="customized table">

                    <TableBody>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Utilisateur
                            </StyledTableCell>
                            <StyledTableCell 
                                align="left"
                                sx={{cursor: 'pointer'}}
                            >
                                <a href={"/ctr/users/" + user.user_id + "/"} target='_blank' className='link' >
                                    <Typography sx={{mb: 0.5, color: 'blue'}}>
                                        { user.lastName + ' ' + user.firstName }
                                    </Typography>
                                </a>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                ID
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold', cursor:'pointer'}} onClick={() => copyClipboard(investPlanData.planID, 'Copie !')}>
                                { investPlanData.planID }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Statut
                            </StyledTableCell>
                            <StyledTableCell align="left" >
                                <InvestPlanStatusBadge status={investPlanData.status} />
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Paiements ce mois
                            </StyledTableCell>
                            <StyledTableCell align="left" >
                                <b style={{color: investPlanData.paymentExistForCurrentMonth > 0 ? 'red' : ''}}>{ investPlanData.paymentExistForCurrentMonth + ' paiements ce mois' }</b>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Montant fourni
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                <b>{ readabilityNumber(investPlanData.amount) } XAF</b>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Total à recevoir
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                <b>{ readabilityNumber(investPlanData.totalAmountToReceive) } XAF</b>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Total reçu
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                <b>{ readabilityNumber(investPlanData.totalPayments) } XAF</b>
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Nomnre de mois
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                { investPlanData.num_months }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Taux intérêt
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                { investPlanData.interest_rate + '%' }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Debut validité
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                { investPlanData.month + ' / ' + investPlanData.year }
                            </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                Actions
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{fontWeight: 'bold'}}>
                                
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                >
                                    
                                    <Link to={'/ctr/liquidity/invest-plans/' + investPlanData.planID + '/payments/'} className='link'>
                                        <Button
                                            sx={{textTransform: 'none'}}
                                        >
                                            Paiements
                                        </Button>
                                    </Link>

                                    {
                                        investPlanData.status !== 'canceled' &&

                                        <Button
                                            color='warning'
                                            sx={{textTransform: 'none'}}
                                            onClick={() => cancelPlan(investPlanData.planID)}
                                        >
                                            Annuler
                                        </Button>
                                    }

                                    <Button
                                        color='error'
                                        sx={{textTransform: 'none'}}
                                        onClick={() => deletePlan(investPlanData.planID)}
                                    >
                                        Supprimer
                                    </Button>
                                </Stack>
                            </StyledTableCell>
                        </StyledTableRow>
                    
                    </TableBody>
                </Table>

            </TableContainer>

            <br /><br /><br />

            <input type="password" value={sw} onChange={(e) => setSw(e.target.value)} />

        </Box>
    )
}
