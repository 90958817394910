import { useState } from 'react'
import { 
    Box, Typography, 
    Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Button,
    TextareaAutosize,
    Chip
} from '@mui/material';
import { basicProps, investPlanPaymentType } from '../../utils/interfaces';
import { copyClipboard, isPC, maxTextLength } from '../../functions/basicFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QueryString from 'qs';
import Carousel from '../../components/Carousel';
import ImagesSelector from '../../components/ImageSelector';
import axiosApi from '../../functions/axiosApi';


interface myProps extends basicProps {
    paymentInfo: investPlanPaymentType
}

export default function PaymentProofs(props: myProps) {

    const basicDatas = props.basicDatas;
    const paymentInfo = props.paymentInfo;
 
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [links, setLinks] = useState("");
    const [txid, setTxid] = useState("");
    const [sw, setSw] = useState('')


    const handleClose = () => {
        setOpen(false);
    };

    const ShowProofLinks = ({links}: {links:string}) => {

        var linksAray = links.split(';');

        return (
            <div>
                {
                    linksAray.map((link, i) => {
                        return (
                            <a href={link} target='_blank' className="link d-block">
                                <Chip component='div' 
                                        variant='outlined'
                                        color='info'
                                        label={link} 
                                        sx={{mb:1, cursor:'pointer'}} 
                                    />
                            </a>
                        )
                    })
                }
            </div>
        )
    }

    const ShowTransactionsHash = ({hashs}: {hashs:string}) => {

        var hashsAray = hashs.split(';');

        return (
            <div>
                {
                    hashsAray.map((hash, i) => {

                        const myHash = maxTextLength(isPC ? 50 : 30, hash);

                        return (
                            <Typography sx={{mb:1}}>
                                <Chip 
                                    key={i}
                                    component='div' 
                                    variant='outlined'
                                    label={ myHash } 
                                    icon={<ContentCopyIcon />}
                                    onClick={() => copyClipboard(hash, "Copie !")}
                                    sx={{mb:1, cursor:'pointer'}} 
                                />
                            </Typography>
                        )
                    })
                }
            </div>
        )
    }
    

    const setPaymentProofs = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        basicDatas.dialogManager({
            dialogTitle: 'Destinataire',
            dialogText: "Souhaitez-vous confirmer le paiement ?",
            dialogAgreeBtnColor: 'info',
            dalogCloseBtnText: 'Non',
            dialogAgreeBtnText: 'Confirmer'
            }, (response) => {
            
            if(response === 'ok') {

                const data = {
                    paymentID: paymentInfo.id,
                    images,
                    txid,
                    links,
                    sw,
                    loaderText: "Traitement en cours..."
                }

                axiosApi.post("ctr/liquidity/set-payment-proofs.php", QueryString.stringify(data))
                .then(response => {

                    if(response.data.status === 'success') {
                        setOpen(false);
                    }
                })
            }
        
        })
        
    }

    return (
        <Box>
            
            <Button 
                onClick={() => setOpen(true)}
                sx={{
                    mt: 2,
                    mb: 3,
                    textTransform: 'none'
                }}
            >
                Preuves de paiement
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Preuves de paiement</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Box 
                        id='payment-proofs'
                        sx={{
                            width: isPC ? '500px' : 'auto',
                        }}
                    >

                        <Box
                            sx={{
                                boxShadow: 2,
                                p: 2,
                                mb: 3,
                                borderRadius: 2,
                                textAlign: 'left'
                            }}>

                            <Typography 
                                variant='h6'
                                sx={{
                                    mb: 2 
                                }}>
                                Hash ou ID de transaction
                            </Typography>

                            {paymentInfo.txid ? <ShowTransactionsHash hashs={paymentInfo.txid} /> : "Aucun ID ou Hash"}
                        
                        </Box>

                        <br />

                        <Box
                            sx={{
                                boxShadow: 2,
                                p: 2,
                                mb: 3,
                                borderRadius: 2,
                                textAlign: 'left'
                            }}>

                            <Typography 
                                variant='h6'
                                sx={{
                                    mb: 2 
                                }}>
                                Liens de vérification
                            </Typography>

                            {
                                paymentInfo.proofLinks 
                                ? <ShowProofLinks links={paymentInfo.proofLinks} /> 
                                : "Aucun lien"
                            }
                        
                        </Box>

                        <br />

                        <Box
                            sx={{
                                boxShadow: 2,
                                p: 2,
                                borderRadius: 2,
                                textAlign: 'left'
                            }}>

                            <Typography 
                                variant='h6'
                                sx={{
                                    mb: 2
                                }}>
                                Photos
                            </Typography>

                            {
                                paymentInfo.proofImages
                                ?  

                                    <Box component='div'>

                                        <Carousel   
                                            items_per_page={1}  
                                            allowFullScreen={false}
                                            duration={ 10000 }
                                            height={ isPC ? "400px" : '250px'}
                                            items = {   paymentInfo.proofImages.map((image, index) => {
                                                            return (
                                                                <Box 
                                                                    component='div'
                                                                    key={index}
                                                                    overflow='auto'
                                                                    sx={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        backgroundImage: 'url("' + image.url + '")',
                                                                        backgroundPosition: 'center',
                                                                        backgroundSize: 'contain',
                                                                        backgroundRepeat: 'no-repeat'
                                                                    }}
                                                                    />
                                                            )
                                                        })
                                                    } />
                                    </Box>

                                :   "Aucune image"
                            }
                        </Box>

                    </Box>
                        
                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}


