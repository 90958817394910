import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';

export default function Reglement() {

    const { orderID } = useParams();

    useEffect(() => {
        getOrderInfo();
    }, [])
    

    const getOrderInfo = () => {
        
        axiosApi.post("invest/invest-order-data.php", QueryString.stringify({orderID}))
        .then(response => {
            if(response.data.status === 'success'){
                const orderData = response.data.orderData;
                console.log(orderData)
            }
        })
    }

    return (
        <div>

            {/* {

                ((cryptoCurrency.symbol === cftTokenInfo.symbol) && (orderData.basic.action === 'sell'))

                ?
                
                    paymentMethod.availableInCameroon == 1
                    
                    ?   <SellCftInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props}/>
                    :   <ExternalSaleCftInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props}/>

                :

                    (paymentMethod.symbol === cftTokenInfo.symbol) 
                    
                    ?   orderData.basic.action === 'buy' 

                        ?   <BuyWithCftInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} {...props} /> 
                        :   <SellWithCftInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} {...props} /> 

                    :
                        orderData.basic.action === 'buy' 

                        ?   paymentMethod.availableInCameroon == 1
                            ?   <BuyInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} selectMomoPaymentType={selectMomoPaymentType} {...props} /> 
                            :   <ExternalBuyInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} {...props} />
                        
                        :   paymentMethod.availableInCameroon == 1
                        
                            ?   <SellInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props} />
                            :   <ExternalSellInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props} />
            } */}
        </div>
    )
}
