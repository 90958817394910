import { useEffect, useState } from 'react'
import { basicProps } from '../../../../utils/interfaces'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import { LoadingButton } from '@mui/lab';
import AddLink from './AddLink';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyClipboard } from '../../../../functions/basicFunctions';

interface linkType {
    linkID: string,
    title: string,
    link: string
}

export default function ShortLinkst(props: basicProps) {

    const [open, setOpen] = useState(false);
    const [links, setLinks] = useState<linkType[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getLinks();
    }, [queryValue])
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getLinks = () => {

        setIsLoading(true);

        axiosApi.post("ctr/communication/links/get-links.php", QueryString.stringify({offset, limit:3, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newLinksArray = links.concat(response.data.links);
                setLinks(newLinksArray);
                setOffset(offset + 3);

                if(response.data.links.length === 0){
                    setEnded(true)
                }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: 'auto',
                pt: 3
            }}>

            <Typography
                variant='h5'
                sx={{
                    textAlign: 'center',
                    mb: 3
                }}>
                Liens
            </Typography>

            <Typography
                sx={{
                    textAlign: 'center',
                    mb: 7
                }}>
                <Button variant="outlined" onClick={handleClickOpen} startIcon={<AddIcon />}>
                    Ajouter un lien
                </Button>
            </Typography>

            {
                links.map(itemLink => {
                    return (
                        <Box
                            onClick={() => copyClipboard(window.location.hostname === 'localhost' ? "http://localhost:3000/r/" + itemLink.linkID : "http://cryptofiat-ex.com/r/" + itemLink.linkID, "Copié !")}
                            sx={{
                                boxShadow: 2,
                                p: 2,
                                mb: 3,
                                borderRadius: 1,
                                cursor: 'pointer'
                            }}>

                            <ContentCopyIcon sx={{float: 'right'}} />

                            <Typography variant='h6'>
                                {itemLink.title}
                            </Typography>

                            <Typography color='GrayText'>
                                {window.location.hostname === 'localhost' ? "http://localhost:3000/r/" + itemLink.linkID : "http://cryptofiat-ex.com/r/" + itemLink.linkID}
                            </Typography>

                        </Box>
                    )
                })
            }

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getLinks()}>
                            Afficher plus
                        </Button>
                }

            </div> 

            <Dialog open={open} onClose={handleClose} >

                <DialogTitle>Ajouter un lien</DialogTitle>

                <DialogContent>
                    <AddLink setOpen={setOpen} />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>
            
        </Box>
    )
}
