import { useState, useEffect, ReactNode } from 'react'
import { basicProps } from '../../utils/interfaces'
import { 
    Alert, AlertTitle, Box, Button, FormControl, 
    InputLabel, MenuItem, Select, Step, 
    StepContent, StepLabel, Stepper, TextField, Typography 
} from '@mui/material'
import axiosApi from '../../functions/axiosApi';
import ImagesSelector from '../../components/ImageSelector';
import { toast } from 'react-toastify';
import QueryString from 'qs';
import KycStatusBox from './KycStatusBox';
import { Link } from 'react-router-dom';
import { BacksideCardInstructions, FrontCardInstructions, SelfieInstructions } from './Instructions';
import Conseils from './Conseils';
import { isPC } from '../../functions/basicFunctions';
import LastMotifMessage from './LastMotifMessage';


export default function SendDocuments(props: basicProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const [showChangeNameAlert, setShowChangeNameAlert] = useState(true);
    const [displayConseils, setDisplayConseils] = useState(true);

    const [documentType, setDocumentType] = useState<'CNI' | 'PERMIS_CONDUIRE' | 'PASSPORT'>('CNI');
    const [country, setCountry] = useState<'Cameroun' | 'République du Congo' | 'Tchad' | 'Gabon' | 'République Centrafricaine'>('Cameroun');
    const [documentID, setDocumentID] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [frontCard, setFrontCard] = useState([]);
    const [backsideCard, setBacksideCard] = useState([]);
    const [frontDriverLicence, setFrontDriverLicence] = useState([]);
    const [backsideDiverLicence, setBacksideDiverLicence] = useState([]);
    const [passportImg, setPassportImg] = useState([]);
    const [selfie, setSelfie] = useState([]);
    const [adminMessage, setAdminMessage] = useState('');
    const [birthday, setBirthday] = useState('');

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    let steps: {label: string, description: ReactNode}[] = [];

    switch (documentType) {
        case 'CNI':
            steps = [
                {
                    label: 'Informations de base',
                    description: `Entrez les informations telles que sur votre pièce d'identité`,
                },
        
                {
                    label: 'Recto de la carte',
                    description: <Typography>
                        Veuillez charger la photo de la face principale de votre carte.
                        <br/>
                        {<FrontCardInstructions {...props} />}
                    </Typography>,
                },
        
                {
                    label: 'Verso de la carte',
                    description: <Typography>
                        Veuillez charger la photo de la face arrière de votre carte.
                        <br/>
                        {<BacksideCardInstructions {...props} />}
                    </Typography>,
                },
        
                {
                    label: 'Selfie avec carte en main',
                    description: <Typography>
                        Veuillez charger un selfie.
                        <br/>
                        {<SelfieInstructions {...props} />}
                    </Typography>,
                }
            ]
        break;

        case 'PERMIS_CONDUIRE':
            steps = [
                {
                    label: 'Informations de base',
                    description: `Entrez les informations telles que sur votre Permis de conduire`,
                },
        
                {
                    label: 'Recto du permis',
                    description: <Typography>
                        Veuillez charger la photo de la face principale de votre Permis de conduire.
                        <br/>
                        {<FrontCardInstructions {...props} />}
                    </Typography>,
                },
        
                {
                    label: 'Verso du permis',
                    description: <Typography>
                        Veuillez charger la photo de la face arrière de votre Permis de conduire.
                        <br/>
                        {<BacksideCardInstructions {...props} />}
                    </Typography>,
                },
        
                {
                    label: 'Selfie avec permis en main',
                    description: <Typography>
                        Veuillez charger un selfie.
                        <br/>
                        {<SelfieInstructions {...props} />}
                    </Typography>,
                }
            ]
        break;

        case 'PASSPORT' :
    
            steps = [
                {
                label: 'Informations de base',
                description: `Entrez les informations telles que sur votre pièce d'identité`,
                },
        
                {
                label: "Page d'informations",
                description:<Typography>
                        Veuillez charger la photo de la page contenant les informations de votre Passeport.
                        <br/>
                        {<FrontCardInstructions {...props} />}
                    </Typography>,
                },
        
                {
                    label: 'Selfie avec Passeport en main',
                    description: <Typography>
                        Veuillez charger un selfie.
                        <br/>
                        {<SelfieInstructions {...props} />}
                    </Typography>,
                }
            ]
    }


    useEffect(() => {

        axiosApi.post("ctr/kyc/get-kyc-infos.php")
        .then(response => {
            
            if(response.data.status === 'success') {

                const kyc_progress_info = response.data.kyc_infos;

                if(kyc_progress_info) {
                    kyc_progress_info.documentType && setDocumentType(kyc_progress_info.documentType);
                    kyc_progress_info.country && setCountry(kyc_progress_info.country);
                    kyc_progress_info.documentID && setDocumentID(kyc_progress_info.documentID);
                    kyc_progress_info.expirationDate && setExpirationDate(kyc_progress_info.expirationDate);
                    kyc_progress_info.message && setAdminMessage(kyc_progress_info.message)
                    kyc_progress_info.birthday && setBirthday(kyc_progress_info.birthday)
                }
                
            }
        })

    }, [])
    

    const submitBasicInfos = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Traitement en cours...");

        axiosApi.post("ctr/kyc/set-basic-infos.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                handleNext();
            }

        })
    }

    const submitFrontCard = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(frontCard.length === 0) {
            toast.error("Veuillez sélectionner la photo de la face principale de votre Cartte Nationale d'Identité.");
            return;
        }
        
        axiosApi.post("ctr/kyc/submit-front-card.php", QueryString.stringify({
            frontCard: frontCard[0],
            loaderText: "Traitement en cours..."
        }))
        .then(response => {

            if(response.data.status === 'success') {
                handleNext();
            }

        })
    }

    const submitBacksideCard = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(backsideCard.length === 0) {
            toast.error("Veuillez sélectionner la photo de la face arrière de votre Cartte Nationale d'Identité.");
            return;
        }

        axiosApi.post("ctr/kyc/submit-backside-card.php", QueryString.stringify({
            backsideCard: backsideCard[0],
            loaderText: "Traitement en cours..."
        }))
        .then(response => {

            if(response.data.status === 'success') {
                handleNext();
            }

        })
    }

    const submitFrontDriverLicence = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(frontDriverLicence.length === 0) {
            toast.error("Veuillez sélectionner la photo de la face principale de votre Permis de conduire.");
            return;
        }

        axiosApi.post("ctr/kyc/submit-front-driver-licence.php", QueryString.stringify({
            frontDriverLicence: frontDriverLicence[0],
            loaderText: "Traitement en cours..."
        }))
        .then(response => {

            if(response.data.status === 'success') {
                handleNext();
            }

        })
    }

    const submitBacksideDriverLicence = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(backsideDiverLicence.length === 0) {
            toast.error("Veuillez sélectionner la photo de la face principale de votre Permis de conduire.");
            return;
        }

        const data = new FormData(e.currentTarget);

        axiosApi.post("ctr/kyc/submit-backside-driver-licence.php", QueryString.stringify({
            backsideDiverLicence: backsideDiverLicence[0],
            loaderText: "Traitement en cours..."
        }))
        .then(response => {

            if(response.data.status === 'success') {
                handleNext();
            }

        })
    }
    

    const submitPassportImg = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(passportImg.length === 0) {
            toast.error("Veuillez sélectionner la photo de la page d'informations de votre passport.");
            return;
        }

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Traitement en cours...");

        axiosApi.post("ctr/kyc/submit-passport-img.php", QueryString.stringify({
            passportImg: passportImg[0],
            loaderText: "Traitement en cours..."
        }))
        .then(response => {

            if(response.data.status === 'success') {
                handleNext();
            }

        })
    }

    const submitSelfie = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(selfie.length === 0) {
            toast.error("Veuillez charger un selfie de vous tenant votre pièce d'identité en main.");
            return;
        }

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Traitement en cours...");

        axiosApi.post("ctr/kyc/submit-selfie.php", QueryString.stringify({
            selfie: selfie[0],
            loaderText: "Traitement en cours..."
        }))
        .then(response => {

            if(response.data.status === 'success') {
                const responseData = response.data;
                basicDatas.setAuthUser(responseData.auth_user);
            }

        })
    }

    if(authUser.vip_level !== 'vip0') return (
        <Box
            sx={{
                pt: 4,
                maxWidth: 600,
                margin: 'auto'
            }}>

            <KycStatusBox basicDatas={basicDatas} vip_level={ authUser.vip_level } />

        </Box>
    )

    return (
        <Box
            sx={{
                pt: 4,
                maxWidth: 500,
                margin: 'auto'
            }}>
            
            <Typography variant='h5' sx={{textAlign: 'center', mb: 5, fontWeight: 'bold'}}>
                Soumettre les documents
            </Typography>

            {
                (!displayConseils && showChangeNameAlert) &&

                <Alert severity="warning" sx={{mb: 5, textAlign:'left'}}>
                        
                    <AlertTitle style={{fontWeight:'bold'}}>Note importante</AlertTitle>
                    
                    Avant de soumettre votre demande, veuillez vous assurer que votre nom sur { basicDatas.appName } est exactement le même que celui figurant sur votre pièce d'identité sinon votre demande sera rejettée sans vérification supplémentaire. 
                    

                    <div style={{marginTop:'20px'}}>
                        <Link to={"/account"} className="link">
                            <Button variant='contained' color='inherit'>
                                Modifier le nom
                            </Button>
                        </Link>
                    </div>

                    <Box textAlign='right' sx={{mt:isPC ? 0 : 2}}>
                        <Button
                            onClick={() => setShowChangeNameAlert(false)}
                            sx={{textTransform: 'none', fontWeight: 'bold'}}
                        >
                            Mon nom est correct
                        </Button>
                    </Box>

                </Alert>
            }


            {
                displayConseils &&
            
                <Conseils
                    setDisplayConseils={setDisplayConseils}
                />
            } 

            {
                (!displayConseils && (authUser.vip_level === 'vip0') && adminMessage && (adminMessage.length > 0)) &&
                <LastMotifMessage 
                    adminMessage={adminMessage}
                    {...props}
                />
            }

            {
                !displayConseils && 

                <Stepper activeStep={activeStep} orientation="vertical">
                        
                    { steps.map((step, index) => (

                        <Step key={step.label}>

                            <StepLabel>
                                
                                <span style={{fontWeight: 'bold'}}>
                                    {step.label}
                                </span>
                            
                            </StepLabel>

                            <StepContent>

                                <Typography 
                                        variant='caption'
                                        sx={{
                                            fontSize: 'small',
                                            opacity: 0.7
                                        }}>
                                    {step.description}
                                </Typography>

                                {
                                    index === 0 &&

                                    <Box 
                                        component='form'
                                        onSubmit={submitBasicInfos}
                                        sx={{
                                            mt: 3
                                        }}>

                                        <FormControl 
                                            fullWidth 
                                            sx={{
                                                mb: 2
                                            }}>

                                            <InputLabel id="select_document_type_label">Type de document</InputLabel>
                                            
                                            <Select
                                                labelId="select_document_type_label"
                                                name='documentType'
                                                defaultValue='CNI'
                                                label="Type de document"
                                                value={documentType}
                                                onChange={(e) => setDocumentType(e.target.value as 'CNI' | 'PASSPORT')}
                                                fullWidth
                                                margin="dense"
                                                required
                                                >

                                                {
                                                    ['CNI', 'PERMIS_CONDUIRE', 'PASSPORT'].map((type, index) => {

                                                        return (
                                                            <MenuItem key={ index } value={ type }>
                                                                { type }
                                                            </MenuItem>
                                                        )

                                                    })
                                                }
                                
                                            </Select>        

                                        </FormControl>

                                        <FormControl 
                                            fullWidth 
                                            sx={{
                                                mb: 2
                                            }}>

                                            <InputLabel id="select_country_label">Pays d'émission</InputLabel>
                                            
                                            <Select
                                                labelId="select_country_label"
                                                name='country'
                                                defaultValue='Cameroun'
                                                label="Pays d'émission"
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value as 'Cameroun' | 'Gabon')}
                                                fullWidth
                                                margin="dense"
                                                required
                                                >

                                                {
                                                    [
                                                       {name: 'Tchad', value: 'Tchad'},
                                                       {name: 'Gabon', value: 'Gabon'},
                                                       {name: 'Cameroun', value: 'Cameroun'},
                                                       {name: 'Congo Brazzaville', value: 'Congo'},
                                                       {name: 'République Centrafricaine', value: 'République centrafricaine'}
                                                    ].map((item, index) => {

                                                        return (
                                                            <MenuItem key={ index } value={ item.value }>
                                                                { item.name }
                                                            </MenuItem>
                                                        )

                                                    })
                                                }
                                
                                            </Select>        

                                        </FormControl>

                                        <TextField
                                            name='documentID'
                                            label='Numéro du document'
                                            placeholder='Identifiant du document'
                                            value={documentID}
                                            onChange={(e) => setDocumentID(e.target.value)}
                                            fullWidth
                                            required
                                            sx={{ mb: 2}}
                                        /> 

                                        <TextField
                                            type='date'
                                            name='expirationDate'
                                            label="Date d'expiration"
                                            value={expirationDate}
                                            onChange={(e) => setExpirationDate(e.target.value)}
                                            fullWidth
                                            required
                                            sx={{ mb: 2}}
                                        />

                                        <TextField
                                            name='ownerName'
                                            label='Nom du titulaire'
                                            defaultValue={ authUser.lastName + ' ' + authUser.firstName}
                                            fullWidth
                                            required
                                            disabled
                                            sx={{ mb: 1 }}
                                        />

                                        <Typography
                                            sx={{
                                                textAlign: 'right',
                                                color: 'var(--site-color)',
                                                fontSize: 'small',
                                                mb: 2,
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Link 
                                                to="/account/"
                                            >
                                                Modifier le nom
                                            </Link>
                                        </Typography>

                                        <TextField
                                            type='date'
                                            name='birthday'
                                            label="Date de naissance"
                                            value={birthday}
                                            onChange={(e) => setBirthday(e.target.value)}
                                            fullWidth
                                            required
                                            sx={{ mb: 2}}
                                        />

                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                    </Box>
                                }

                                {
                                    ((index === 1) && (documentType === 'CNI')) &&

                                    <Box 
                                        component='form'
                                        onSubmit={submitFrontCard}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <div className='kyc-cni-images'>
                                            <ImagesSelector images={frontCard} max={1} setImages={(items: any) => {setFrontCard(items)}} />
                                        </div>


                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 2.5, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    ((index === 2) && (documentType === 'CNI')) &&

                                    <Box 
                                        component='form'
                                        onSubmit={submitBacksideCard}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <div className='kyc-cni-images'>
                                            <ImagesSelector images={backsideCard} max={1} setImages={(items: any) => {setBacksideCard(items)}} />
                                        </div>


                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 2.5, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    ((index === 1) && (documentType === 'PERMIS_CONDUIRE')) &&

                                    <Box 
                                        component='form'
                                        onSubmit={submitFrontDriverLicence}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <div className='kyc-cni-images'>
                                            <ImagesSelector images={frontDriverLicence} max={1} setImages={(items: any) => {setFrontDriverLicence(items)}} />
                                        </div>


                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 2.5, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    ((index === 2) && (documentType === 'PERMIS_CONDUIRE')) &&

                                    <Box 
                                        component='form'
                                        onSubmit={submitBacksideDriverLicence}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <div className='kyc-cni-images'>
                                            <ImagesSelector images={backsideDiverLicence} max={1} setImages={(items: any) => {setBacksideDiverLicence(items)}} />
                                        </div>


                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 2.5, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    ((index === 1) && (documentType === 'PASSPORT')) &&

                                    <Box 
                                        component='form'
                                        onSubmit={submitPassportImg}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <div className='kyc-cni-images'>
                                            <ImagesSelector images={passportImg} max={1} setImages={(items: any) => {setPassportImg(items)}} />
                                        </div>


                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 2.5, mr: 1 }}
                                        >
                                            Continuer
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }

                                {
                                    (
                                        ((index === 3) && (documentType === 'CNI')) 
                                        || ((index === 3) && (documentType === 'PERMIS_CONDUIRE')) 
                                        || ((index === 2) && (documentType === 'PASSPORT'))
                                    ) &&

                                    <Box 
                                        component='form'
                                        onSubmit={submitSelfie}
                                        sx={{
                                            mt: 2
                                        }}>

                                        <div className='kyc-cni-images'>
                                            <ImagesSelector images={selfie} max={1} setImages={(items: any) => {setSelfie(items)}} />
                                        </div>


                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{ mt: 2.5, mr: 1 }}
                                        >
                                            Soumettre
                                        </Button>

                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Retour
                                        </Button>

                                    </Box>
                                }


                            </StepContent>

                        </Step>

                    ))}

                </Stepper>
            }

        </Box>
    )
}
