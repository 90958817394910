import { useEffect, useState } from 'react'
import axiosApi from '../../../functions/axiosApi';
import { 
    Box, Typography, 
    Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Button, TableRow,
    TextareaAutosize,
    Chip
} from '@mui/material';
import { basicProps, investPlanPaymentType } from '../../../utils/interfaces';
import { copyClipboard, isPC, maxTextLength } from '../../../functions/basicFunctions';
import ImagesSelector from '../../../components/ImageSelector';
import QueryString from 'qs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Carousel from '../../../components/Carousel';

interface myProps extends basicProps {
    paymentInfo: investPlanPaymentType
}

export default function PaymentProofs(props: myProps) {

    const basicDatas = props.basicDatas;
    const paymentInfo = props.paymentInfo;
 
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [links, setLinks] = useState("");
    const [txid, setTxid] = useState("");
    const [sw, setSw] = useState('')


    const handleClose = () => {
        setOpen(false);
    };

    const ShowProofLinks = ({links}: {links:string}) => {

        var linksAray = links.split(';');

        return (
            <div>
                {
                    linksAray.map((link, i) => {
                        return (
                            <a href={link} target='_blank' className="link d-block">
                                <Chip component='div' 
                                        variant='outlined'
                                        color='info'
                                        label={link} 
                                        sx={{mb:1, cursor:'pointer'}} 
                                    />
                            </a>
                        )
                    })
                }
            </div>
        )
    }

    const ShowTransactionsHash = ({hashs}: {hashs:string}) => {

        var hashsAray = hashs.split(';');

        return (
            <div>
                {
                    hashsAray.map((hash, i) => {

                        const myHash = maxTextLength(isPC ? 50 : 30, hash);

                        return (
                            <Typography sx={{mb:1}}>
                                <Chip 
                                    key={i}
                                    component='div' 
                                    variant='outlined'
                                    label={ myHash } 
                                    icon={<ContentCopyIcon />}
                                    onClick={() => copyClipboard(hash, "Copie !")}
                                    sx={{mb:1, cursor:'pointer'}} 
                                />
                            </Typography>
                        )
                    })
                }
            </div>
        )
    }
    

    const setPaymentProofs = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        basicDatas.dialogManager({
            dialogTitle: 'Destinataire',
            dialogText: "Souhaitez-vous confirmer le paiement ?",
            dialogAgreeBtnColor: 'info',
            dalogCloseBtnText: 'Non',
            dialogAgreeBtnText: 'Confirmer'
            }, (response) => {
            
            if(response === 'ok') {

                const data = {
                    paymentID: paymentInfo.id,
                    images,
                    txid,
                    links,
                    sw,
                    loaderText: "Traitement en cours..."
                }

                axiosApi.post("ctr/liquidity/set-payment-proofs.php", QueryString.stringify(data))
                .then(response => {

                    if(response.data.status === 'success') {
                        setOpen(false);
                    }
                })
            }
        
        })
        
    }

    return (
        <Box>
            
            <Button 
                onClick={() => setOpen(true)}
                sx={{
                    mt: 2,
                    textTransform: 'none'
                }}
            >
                Preuves de paiement
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Preuves de paiement</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Box    
                        component='form'
                        onSubmit={setPaymentProofs}
                        sx={{
                            width: '500px'
                        }}
                    >

                        <Box id='payment-proofs'>

                            <Box
                                sx={{
                                    boxShadow: 2,
                                    p: 2,
                                    mb: 3,
                                    borderRadius: 2,
                                    textAlign: 'left'
                                }}>

                                <Typography 
                                    variant='h6'
                                    sx={{
                                        mb: 2 
                                    }}>
                                    Hash ou ID de transaction
                                </Typography>

                                {paymentInfo.txid ? <ShowTransactionsHash hashs={paymentInfo.txid} /> : "Aucun ID ou Hash"}
                            
                            </Box>

                            <br />

                            <Box
                                sx={{
                                    boxShadow: 2,
                                    p: 2,
                                    mb: 3,
                                    borderRadius: 2,
                                    textAlign: 'left'
                                }}>

                                <Typography 
                                    variant='h6'
                                    sx={{
                                        mb: 2 
                                    }}>
                                    Liens de vérification
                                </Typography>

                                {
                                    paymentInfo.proofLinks 
                                    ? <ShowProofLinks links={paymentInfo.proofLinks} /> 
                                    : "Aucun lien"
                                }
                            
                            </Box>

                            <br />

                            <Box
                                sx={{
                                    boxShadow: 2,
                                    p: 2,
                                    borderRadius: 2,
                                    textAlign: 'left'
                                }}>

                                <Typography 
                                    variant='h6'
                                    sx={{
                                        mb: 2
                                    }}>
                                    Photos
                                </Typography>

                                {
                                    paymentInfo.proofImages
                                    ?  

                                        <Box component='div'>

                                            <Carousel   
                                                items_per_page={1}  
                                                allowFullScreen={false}
                                                duration={ 10000 }
                                                height={ isPC ? "400px" : '250px'}
                                                items = {   paymentInfo.proofImages.map((image, index) => {
                                                                return (
                                                                    <Box 
                                                                        component='div'
                                                                        key={index}
                                                                        overflow='auto'
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            backgroundImage: 'url("' + image.url + '")',
                                                                            backgroundPosition: 'center',
                                                                            backgroundSize: 'contain',
                                                                            backgroundRepeat: 'no-repeat'
                                                                        }}
                                                                        />
                                                                )
                                                            })
                                                        } />
                                        </Box>

                                    :   "Aucune image"
                                }
                            </Box>

                        </Box>


                        <br /><br />

                        <Typography variant='h6' sx={{mb: 2, fontWeight: 'bold'}}>
                            Modifier les preuves de paiement
                        </Typography>

                        <label htmlFor={"textarea-txid"} style={{display:'block', marginBottom:'7px'}}>Ajouter des hash ou ID de transaction séparés par des point virgules</label>
                        <TextareaAutosize minRows={3} 
                                            style={{width:'100%'}}
                                            id={"textarea-txid"}
                                            value={txid}
                                            onChange={(e) => setTxid(e.target.value)}
                                            placeholder="ID ou Hash de transaction séparés par des point virgules" />

                        <br /> <br />

                        <label htmlFor={"textarea-links"} style={{display:'block', marginBottom:'7px'}}>Ajouter des liens de confirmations séparés par des point virgules</label>
                        <TextareaAutosize minRows={3} 
                                            style={{width:'100%'}}
                                            id={"textarea-links"}
                                            value={links}
                                            onChange={(e) => setLinks(e.target.value)}
                                            placeholder="Liens de confirmations séparés par des point virgules" />

                        <br /> <br />

                        <div style={{display:'block', marginBottom:'7px'}}>Ajouter des images</div>

                        <div className='proof-form-images'>
                            <ImagesSelector images={images} max={10} setImages={(items: any) => {setImages(items)}} />
                        </div>

                        <br /><br />

                        <TextField
                            type='password'
                            name='sw'
                            label="sw"
                            value={sw}
                            onChange={(e) => setSw(e.target.value)}
                            required 
                            fullWidth
                        />

                        <Button variant='contained'
                                type='submit'
                                color='info'
                                fullWidth
                                sx={{grid: 1, mt: 3}}
                                >
                            Mettre les preuves à jour
                        </Button>

                    </Box>
                        
                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}


