import { useState, useEffect } from 'react'

import  { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Avatar, TextField } from '@mui/material';
import { cftTokenInfo, readabilityNumber } from '../../../functions/basicFunctions';
import axiosApi from '../../../functions/axiosApi';
import { basicProps, paymentMethodType } from '../../../utils/interfaces';
import QueryString from 'qs';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { toast } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import Reglement from './Reglement';

const packs = [
    {
        name: 'Basic',
        amount: 50000
    },

    {
        name: 'Basic',
        amount: 100000
    },

    {
        name: 'Basic',
        amount: 250000
    },

    {
        name: 'Basic',
        amount: 500000
    },

    {
        name: 'Basic',
        amount: 1000000
    }
]

export default function InvestNow(props: basicProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const [amount, setAmount] = useState('');
    const [paymentMethods, setpaymentMethods] = useState<paymentMethodType[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<paymentMethodType>();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(authUser.phone ? authUser.phone : "");
    const [paymentMethodOwnerName, setPaymentMethodOwnerName] = useState(authUser.lastName || authUser.firstName ? authUser.lastName + " " + authUser.firstName : "")

    useEffect(() => {
        getPaymentMethods();

    }, []) 

    useEffect(() => {
        
        const cftMethod = paymentMethods.filter((method) => method.symbol === cftTokenInfo.symbol);
        if(cftMethod[0]){
            setSelectedPaymentMethod(cftMethod[0]);
            setPaymentMethod(cftMethod[0].symbol);
        }

    }, [paymentMethods])

    const getPaymentMethods = () => {
        axiosApi.post('ctr/payment-methods/get-payment-methods.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setpaymentMethods(response.data.paymentMethods);
            }
        })
    }

    const handlePaymentMethodChange = (e: SelectChangeEvent) => {

        const matchedPaymentMethod = paymentMethods.filter((item) => item.symbol === e.target.value);
        if(matchedPaymentMethod.length !== 0){
            setSelectedPaymentMethod(matchedPaymentMethod[0]);
        }

        setPaymentMethod(e.target.value as string);

    }

    const handleWithdraw = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if((phoneNumber.length > 0) && !matchIsValidTel(phoneNumber)){
            toast.error("Veuillez entrer un numéro de téléphone valide.");
            return;
        }
 

        let data = new FormData(e.currentTarget);
        
        data.append('loaderText', 'Demande de retrait en cours...');

        axiosApi.post('invest/invest-now.php', data)
        .then(res => {

            if(res.data.status === 'success'){
                window.location.href = "/invest/invest-now/" + res.data.orderID + "/"
            }
        })

    }

    return (
        <Box>

            <Routes>
                <Route path='/' element={
                    <Box sx={{
                        maxWidth: '450px',
                        margin: 'auto'
                    }}>
                        
                        <Typography variant='h5' sx={{textAlign: 'center', mt: 3, mb: 4}}>
                            J'investis maintenant
                        </Typography>
            
                        <Box 
                            component='form'
                            onSubmit={handleWithdraw}>
            
                            <FormControl fullWidth sx={{mb: 3}}>
            
                                <InputLabel id="demo-simple-select-label">Choisir un montant</InputLabel>
                                
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Montant à investir"
                                    name='amount'
                                    value={ amount }
                                    required
                                    onChange={(e) => setAmount(e.target.value)}
                                >
                                    {
                                        packs.map((pack, i) => {
                                            return (
                                                <MenuItem value={pack.amount} key={i}>{ readabilityNumber(pack.amount)}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
            
                            </FormControl>
            
                            <FormControl fullWidth sx={{mb: 1}}>
            
                                <InputLabel id="select_payment_method_label">Moyen de paiement</InputLabel>
                                
                                <Select
                                    labelId="select_payment_method_label"
                                    id="demo-simple-select"
                                    name='paymentMethod'
                                    label="Moyen de paiement"
                                    value={paymentMethod}
                                    onChange={handlePaymentMethodChange}
                                    fullWidth
                                    required
                                    >
            
                                    {
                                        paymentMethods.map((method: paymentMethodType, index) => {
            
                                            return (
                                                <MenuItem key={index} 
                                                            value={method.symbol}
                                                            disabled={ method.allowForBuy == 1 ? false : true }> 
                                                    <Avatar src={method.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                                    <span>{method.symbol + " (" + method.name + ")"}</span>
                                                </MenuItem>
                                            )
                                        })
                                    }
                        
                                </Select>
            
                            </FormControl>
            
                            {
                            (selectedPaymentMethod && (selectedPaymentMethod.symbol !== cftTokenInfo.symbol)) &&
            
                                <>
                                    <MuiTelInput 
                                        label={"Numéro " + selectedPaymentMethod.name}
                                        margin="normal"
                                        id="paymentMethodNumber"
                                        value={phoneNumber} 
                                        onChange={(value) => setPhoneNumber(value)}
                                        name="paymentMethodNumber"
                                        autoComplete="paymentMethodNumber"
                                        placeholder={"Exemple : +237 " + selectedPaymentMethod.address}
                                        fullWidth
                                        required 
                                    />
            
                                    <TextField
                                        type="text"
                                        margin="normal"
                                        value={paymentMethodOwnerName}
                                        onChange={(e) => setPaymentMethodOwnerName(e.target.value)}
                                        id="paymentMethodOwnerName"
                                        label={"Nom associé à votre compte " + selectedPaymentMethod.name}
                                        name="paymentMethodOwnerName"
                                        autoComplete="paymentMethodOwnerName"
                                        placeholder={"Exemple : " + selectedPaymentMethod.accountOwnerName}
                                        fullWidth
                                        required
                                        />
                                </>
                            }
            
                            {
                                (selectedPaymentMethod && (selectedPaymentMethod.symbol === cftTokenInfo.symbol)) &&
                                <>
                                    <TextField
                                        type="text"
                                        margin="normal"
                                        id="cftReceptionAddress"
                                        label="Asresse BEP20 (Binance Smart Chain)"
                                        name="cftReceptionAddress"
                                        autoComplete="cftReceptionAddress"
                                        placeholder={"Exemple : " + cftTokenInfo.ownerAddress}
                                        fullWidth
                                        required
                                        />
            
                                    <Typography color='primary' sx={{
                                        fontSize: 'small'
                                    }}>
                                        Votre adresse nous permettra de savoir que c'est vous qui avez envoyé des fonds.
                                    </Typography>
                                </>
                            }
            
                            <Button type='submit'
                                    variant='contained' 
                                    color='info' 
                                    fullWidth
                                    sx={{
                                        mt: 2
                                    }}
                                    >
                                Continuer
                            </Button>
            
                        </Box>
            
                    </Box>
                } />

                <Route path='/:orderID' element={ <Reglement />} />
                
            </Routes>
            
        </Box>
    )
}
