import { useState, useEffect } from 'react'
import { basicProps, currencyType, networkType } from '../../utils/interfaces';
import {
    Box, Typography, Stack, FormControl, Input, InputAdornment, 
    Button, Alert, Chip
} from '@mui/material';
import axiosApi from '../../functions/axiosApi';
import SearchImput from './SearchCryptoImput';
import SelectNetworkImput from './SelectNetworkInput';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import WalletAddress from './WalletAddress';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import KycInvitationPopup from '../../components/KycInvitationPopup';
import { MetaTags } from 'react-meta-tags';
import UserIsNotConnectedMessage from '../../components/UserIsNotConnectedMessage';
import TeamAvailabilityAlert from '../../components/TeamAvailabilityAlert';

export default function Swap(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [sendCrypto, setSendCrypto] = useState<currencyType>()
    const [receivedCrypto, setReceivedCrypto] = useState<currencyType>()
    const [sendNetwork, setSendNetwork] = useState<networkType>()
    const [receivedNetwork, setReceivedNetwork] = useState<networkType>()
    const [walletAddress, setWalletAddress] = useState('');
    const [addessIsValid, setaddessIsValid] = useState<boolean>(true);
    const [memo, setMemo] = useState('');
    const [sendQte, setSendQte] = useState('');
    const [receivedQte, setReceivedQte] = useState('');

    useEffect(() => {
        setSendNetwork(undefined);
    }, [sendCrypto?.symbol])

    useEffect(() => {
        setReceivedNetwork(undefined);
    }, [receivedCrypto?.symbol])

    useEffect(() => {

        if(receivedCrypto) {
            checkValidAddress(walletAddress);
        }

        if(walletAddress.length === 0) setaddessIsValid(true)

    }, [receivedNetwork, walletAddress, receivedCrypto])

    useEffect(() => {

        if(sendCrypto && receivedCrypto) {
            getCurrenciesInfos()
            handleSendQteChange(sendQte);
        }
        
    }, [sendCrypto?.symbol, receivedCrypto?.symbol])

    useEffect(() => {

        if(sendCrypto && receivedCrypto && sendNetwork && receivedNetwork) {
            if((sendCrypto.symbol === receivedCrypto.symbol) && (sendNetwork.symbol === receivedNetwork.symbol)) {
                toast.warn("Important : Il est recommandé de transférer directement dans l'autre portefeuille si vos actifs sont sur le même réseau que le réseau de destination.");
            }
        }
    }, [sendCrypto?.symbol, receivedCrypto?.symbol, sendNetwork?.symbol, receivedNetwork?.symbol])
    

    const checkValidAddress = (addressValue: string) => {

        const findAllowedNetwork = receivedCrypto?.allowed_networks?.find((n) => receivedNetwork?.symbol === n.network);
            
        if(findAllowedNetwork && findAllowedNetwork.addressRegex) {
            const addressRegex = new RegExp(findAllowedNetwork.addressRegex, 'g');
            
            if(!addressRegex.exec(addressValue)) {
                setaddessIsValid(false);
            }

            else {
                setaddessIsValid(true);
            }
        }

    }


    const getCurrenciesInfos = () => {

        axiosApi.post('ctr/currencies/get-currency-infos.php', QueryString.stringify({
            symbol: sendCrypto?.symbol,
            loaderText: "Please wait..."
        }))
        .then(response => {
            if(response.data.status === 'success'){
                
                setSendCrypto(response.data.currencyData);
                    
                axiosApi.post('ctr/currencies/get-currency-infos.php', QueryString.stringify({
                    symbol: receivedCrypto?.symbol,
                    loaderText: "Please wait..."
                }))
                .then(_response => {
                    if(_response.data.status === 'success'){
                        setReceivedCrypto(_response.data.currencyData);
                    }
                })
            }
        })


    }

    const handlesendCryptoChange = (newValue: currencyType) => {
        setSendNetwork(undefined)
        setSendCrypto(newValue)
    }

    const handlereceivedCryptoChange = (newValue: currencyType) => {
        setReceivedNetwork(undefined)
        setReceivedCrypto(newValue)
    }

    const handleSendQteChange = (newValue: any) => {

        setSendQte(newValue);
        
        newValue = parseFloat(newValue);

        if(sendCrypto 
            && receivedCrypto
            && sendCrypto.purchasePrice 
            && sendCrypto.salePrice
            && receivedCrypto.purchasePrice 
            && receivedCrypto.salePrice) {

            if((sendCrypto.isDigitalCurrency == 1) || (receivedCrypto.isDigitalCurrency == 1)) {
                let _receivedCryptoQte = (sendCrypto.purchasePrice / receivedCrypto.salePrice) * newValue;
                setReceivedQte(_receivedCryptoQte.toFixed(6) + '');
            }

            else {

                const sendCryptoPrice = sendCrypto.purchasePrice !== sendCrypto.salePrice ? (parseFloat(sendCrypto.purchasePrice + '') + parseFloat(sendCrypto.salePrice + '')) / 2 : parseFloat(sendCrypto.purchasePrice + '');
                const receivedCryptoPrice = receivedCrypto.purchasePrice !== receivedCrypto.salePrice ? (parseFloat(receivedCrypto.purchasePrice + '') + parseFloat(receivedCrypto.salePrice + '')) / 2 : parseFloat(receivedCrypto.purchasePrice + '');
                let _receivedCryptoQte = (sendCryptoPrice / receivedCryptoPrice) * newValue;
                setReceivedQte(_receivedCryptoQte.toFixed(6) + '')
            }
                
        }
    }


    const handleReceivedQteChange = (newValue: any) => {

        setReceivedQte(newValue);

        newValue = parseFloat(newValue);

        if(sendCrypto 

            && receivedCrypto
            && sendCrypto.purchasePrice 
            && sendCrypto.salePrice
            && receivedCrypto.purchasePrice 
            && receivedCrypto.salePrice) {

            if((sendCrypto.isDigitalCurrency == 1) || (receivedCrypto.isDigitalCurrency == 1)) {
                let _sendCryptoQte = (receivedCrypto.salePrice / sendCrypto.purchasePrice) * newValue;
                setSendQte(_sendCryptoQte.toFixed(6) + '');
                console.log(sendCrypto.salePrice , receivedCrypto.purchasePrice)
            }

            else {

                const sendCryptoPrice = sendCrypto.purchasePrice !== sendCrypto.salePrice ? (parseFloat(sendCrypto.purchasePrice + '') + parseFloat(sendCrypto.salePrice + '')) / 2 : parseFloat(sendCrypto.purchasePrice + '');
                const receivedCryptoPrice = receivedCrypto.purchasePrice !== receivedCrypto.salePrice ? (parseFloat(receivedCrypto.purchasePrice + '') + parseFloat(receivedCrypto.salePrice + '')) / 2 : parseFloat(receivedCrypto.purchasePrice + '');
                let _sendCryptoQte = (receivedCryptoPrice / sendCryptoPrice) * newValue;
                setSendQte(_sendCryptoQte + '')
            }

        }
    }

    const handleChangeDirection = () => {
        setSendCrypto(receivedCrypto);
        setSendNetwork(receivedNetwork)
        setReceivedCrypto(sendCrypto);
        setReceivedNetwork(sendNetwork)
    }

    const handlewalletAddressChange = (value: string) => {

        setWalletAddress(value);
        checkValidAddress(value);
    }

    const makeOrder = () => {

        if(!sendCrypto) {
            toast.error("Veuillez choisir la devise à envoyer.");
            return
        }

        if(!sendNetwork) {
            toast.error("Veuillez choisir le réseau sur lequel envoyer.");
            return
        }

        if(!receivedNetwork) {
            toast.error("Veuillez choisir le réseau sur lequel récevoir.");
            return
        }

        if(!receivedCrypto) {
            toast.error("Veuillez choisir la devise à récevoir.");
            return
        }

        if(sendQte.length === 0) {
            toast.error("Veuillez entrer la quantité à envoyer.");
            return
        }

        if(receivedQte.length === 0) {
            toast.error("Veuillez entrer la quantité à récevoir.");
            return
        }

        if(walletAddress.length === 0) {
            toast.error("Veuillez entrer l'adresse de réception.");
            return
        }

        axiosApi.post("swap/create-order.php", QueryString.stringify({
            loaderText: 'Création de votre ordre en cours...',
            sendCrypto: sendCrypto?.symbol,
            sendNetwork: sendNetwork.symbol,
            sendQte,
            receivedCrypto: receivedCrypto?.symbol,
            receivedNetwork: receivedNetwork.symbol,
            walletAddress,
            memo
        })).then(response => {

            if(response.data.status === 'success') {
                const orderID = response.data.orderID;
                window.location.href = "/orders/" + orderID + '/reglement/';
            }
        })
    }

    if(!basicDatas.isLoggedIn) return (
        <div className='text-center' style={{maxWidth:'500px', margin: 'auto', marginTop:'60px'}}>
            <MetaTags id="create-order">
                <title>{basicDatas.appName + " SWAP"}</title>
                <meta name="description" content={basicDatas.appName + " - SWAP"} />
                <meta property="og:title" content={basicDatas.appName + " - SWAP"} />
            </MetaTags>
            <UserIsNotConnectedMessage message="Pour créer un ordre, veuillez vous identifier pour nous permettre d'avoir les informations necessaires pour votre transaction." />
        </div>
    )
    

    return (
        <Box
            sx={{
                maxWidth: 450,
                pt: 5,
                margin: 'auto'
            }}
        >

            <MetaTags id="create-order">
                <title>{basicDatas.appName + " SWAP"}</title>
                <meta name="description" content={basicDatas.appName + " - SWAP"} />
                <meta property="og:title" content={basicDatas.appName + " - SWAP"} />
            </MetaTags>
            
            <Typography
                variant='h5'
                className='goldman'
                fontWeight={800}
                sx={{
                    mb: 2,
                    textAlign: 'center'
                }}
            >
                { basicDatas.appName + " SWAP"}
            </Typography>
            
            <Typography sx={{textAlign: 'center'}}>
                <Typography variant='caption'>
                    Échangez un actif contre un autre directement.
                </Typography>
            </Typography>

            <KycInvitationPopup basicDatas={basicDatas} />
            <TeamAvailabilityAlert basicDatas={basicDatas} />

            <Box
                sx={{ 
                    mt: 5, 
                    boxShadow: 2, 
                    borderRadius: 3,
                    minHeight: 350,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2
                }}
            >


                {/* Send section */}

                <Box
                    sx={{
                        border: '1px solid #eee',
                        p: 2,
                        borderRadius: 2
                    }}
                >

                    <Typography
                        variant='h6'
                        color='GrayText'
                        sx={{
                            fontWeight: 'bold',
                            mb: 2
                        }}
                    >
                        Je vais envoyer :
                    </Typography>

                    <Stack
                        spacing={2}
                        direction={'row'}
                    >

                        <SearchImput
                            title='Je vais envoyer :'
                            selectedCrypto={sendCrypto}
                            setSelectedCrypto={handlesendCryptoChange}
                        />

                        <SelectNetworkImput
                            title="Je vais envoyer sur le réseau :"
                            action='sell'
                            basicDatas={basicDatas}
                            selectedCrypto={sendCrypto}
                            selectedNetwork={sendNetwork}
                            setSelectedNetwork={setSendNetwork}
                            disabled={{
                                disabled: sendCrypto ? false : true,
                                message: "Veuillez d'abord choisir la devise."
                            }}
                        />

                    </Stack>

                    {
                        (sendCrypto 
                            && sendNetwork 
                            && sendCrypto.allowed_networks?.filter(allowed_network => sendNetwork.symbol === allowed_network.network)[0].sellAlertMessage
                        ) &&

                        <Alert severity="info" 
                                sx={{mt: 2, textAlign:'left', fontSize:'small'}}>
                                
                            { sendCrypto.allowed_networks?.filter(allowed_network => sendNetwork.symbol === allowed_network.network)[0].sellAlertMessage }

                        </Alert>
                    }

                    {sendCrypto &&
                        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '100%' }}>
                            <Input
                                id="standard-adornment-weight"
                                endAdornment={<InputAdornment position="end"> { sendCrypto.symbol } </InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                type='number'
                                value={sendQte}
                                onChange={(e) => handleSendQteChange(e.target.value)}
                                inputProps={{
                                    'aria-label': 'weight',
                                    'step': 'any'
                                }}
                                sx={{
                                    fontSize: 20
                                }}
                            />
                            
                            <Typography sx={{ mt: 1 }}>

                                {
                                    (sendNetwork?.id && sendCrypto?.allowed_networks && (sendCrypto.allowed_networks.length > 0)) &&
                                    
                                    <>

                                        <Chip label={
                                                'Min : ' + sendCrypto?.allowed_networks.filter(allowed_network => sendNetwork?.symbol === allowed_network.network)[0]?.minSellPerTransaction + " " + sendCrypto?.symbol
                                            } 
                                                sx={{
                                                    backgroundColor: "rgba(167, 202, 237, 0.2)",
                                                    color: "var(--site-color)"
                                                }} 
                                                variant="filled" 
                                                size="small" />

                                        <Chip label={
                                                'Max : ' + sendCrypto?.allowed_networks.filter(allowed_network => sendNetwork?.symbol === allowed_network.network)[0].maxSellPerTransaction + " " + sendCrypto?.symbol
                                            }
                                                sx={{
                                                    backgroundColor: "rgba(167, 202, 237, 0.2)",
                                                    color: "var(--site-color)",
                                                    ml: 2
                                                }} 
                                                variant="filled" 
                                                size="small" />
                                    </>
                                }

                            </Typography>
                            
                        </FormControl>
                    }


                </Box>

                
                {/* SWAP Symbol */}
                <Typography
                    sx={{
                        textAlign: 'center',
                        mt: 3,
                        mb: 3
                    }}
                >
                    <SwapVerticalCircleIcon  
                        color='primary'
                        onClick={handleChangeDirection}
                        sx={{
                            fontSize: 50,
                            cursor: 'pointer'
                        }}
                    />
                </Typography>


                
                {/* Receive section */}

                <Box
                    sx={{
                        border: '1px solid #eee',
                        p: 2,
                        borderRadius: 2
                    }}
                >

                    <Typography
                        variant='h6'
                        color='GrayText'
                        sx={{
                            fontWeight: 'bold',
                            mb: 2
                        }}
                    >
                        Je veux recevoir :
                    </Typography>

                    <Stack
                        spacing={2}
                        direction={'row'}
                    >

                        <SearchImput
                            title='Je veux recevoir :'
                            selectedCrypto={receivedCrypto}
                            setSelectedCrypto={handlereceivedCryptoChange}
                        />

                        <SelectNetworkImput
                            title="Je veux recevoir sur le réseau :"
                            action='buy'
                            basicDatas={basicDatas}
                            selectedCrypto={receivedCrypto}
                            selectedNetwork={receivedNetwork}
                            setSelectedNetwork={setReceivedNetwork}
                            disabled={{
                                disabled: receivedCrypto ? false : true,
                                message: "Veuillez d'abord choisir la devise."
                            }}
                        />

                    </Stack>

                    {receivedCrypto &&
                        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '100%' }}>
                            <Input
                                id="standard-adornment-weight"
                                endAdornment={<InputAdornment position="end"> { receivedCrypto.symbol } </InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                type='number'
                                value={receivedQte}
                                onChange={(e) => handleReceivedQteChange(e.target.value)}
                                inputProps={{
                                    'aria-label': 'weight',
                                    'step': 'any'
                                }}
                                sx={{
                                    fontSize: 20
                                }}
                            />

                            <Typography sx={{ mt: 1 }}>

                                {
                                    (receivedNetwork?.id && receivedCrypto?.allowed_networks && (receivedCrypto.allowed_networks.length > 0)) &&
                                    
                                    <>

                                        <Chip label={
                                                'Min : ' + receivedCrypto?.allowed_networks.filter(allowed_network => receivedNetwork?.symbol === allowed_network.network)[0]?.minBuyPerTransaction + " " + receivedCrypto?.symbol
                                            } 
                                                sx={{
                                                    backgroundColor: "rgba(167, 202, 237, 0.2)",
                                                    color: "var(--site-color)"
                                                }} 
                                                variant="filled" 
                                                size="small" />

                                        <Chip label={
                                                'Max : ' + receivedCrypto?.allowed_networks.filter(allowed_network => receivedNetwork?.symbol === allowed_network.network)[0].maxBuyPerTransaction + " " + receivedCrypto?.symbol
                                            }
                                                sx={{
                                                    backgroundColor: "rgba(167, 202, 237, 0.2)",
                                                    color: "var(--site-color)",
                                                    ml: 2
                                                }} 
                                                variant="filled" 
                                                size="small" />
                                    </>
                                }

                            </Typography>
                            
                        </FormControl>
                    }

                    <Typography sx={{mb: 2}}></Typography>

                    {
                        receivedNetwork &&
                        <WalletAddress
                            basicDatas={basicDatas}
                            blockchainAddress={walletAddress} 
                            selectedCrypto={receivedCrypto}
                            selectedNetwork={receivedNetwork}
                            addessIsValid={addessIsValid}
                            setBlockchainAddress={setWalletAddress}
                            memo={memo}
                            setMemo={setMemo}
                            handleBlockchainAddressChange={handlewalletAddressChange}
                        />
                    }


                </Box>

                <Button
                    variant='contained'
                    fullWidth
                    onClick={makeOrder}
                    sx={{
                        p: 1,
                        mt: 3
                    }}
                >
                    Valider
                </Button>
                
            </Box>

        </Box>
    )
}
