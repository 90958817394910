import { useState, useEffect } from 'react'
import { basicProps, prizePoolCampaignType } from '../../../../utils/interfaces'
import { Avatar, Box, Button, Paper, Stack, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material'
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import PrizePoolStatusBadge from '../../../../components/PrizePoolStatusBadge';
import WinnersList from './WinnersList';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#B1B1B1",
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function ItemCampaign(props: basicProps) {

    const {id} = useParams();
    const navigate = useNavigate();
    const basicDatas = props.basicDatas;

    const [campaignData, setCampaignData] = useState<prizePoolCampaignType>();
    const [sw, setSw] = useState('')

    useEffect(() => {
        
        axiosApi.post("ctr/airdrops/prize-pools/get-campaign-data.php", QueryString.stringify({id}))
        .then(response => {

            if(response.data.status === 'success') {
                setCampaignData(response.data.campaignData)
            }
        })

    }, [id])

    const endCampaign = () => {

        basicDatas.dialogManager({
            dialogTitle: "Marquer la fin de cette campagne",
            dialogText: "Cette action marquera la fin de campagne en créant la liste de personnes gagnantes. Exécuter ?",
            dialogAgreeBtnColor: 'warning',
            dialogAgreeBtnText: "Oui Exécuter"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    id,
                    sw,
                    loaderText: "Traitement en cours..."
                }
                axiosApi.post('ctr/airdrops/prize-pools/end-campaign.php', QueryString.stringify(data))
                .then(() => {})
            }
      
        })
    }

    const deleteCampaign = () => {

        basicDatas.dialogManager({
            dialogTitle: "Supprimer cette campagne",
            dialogText: "Vous allez supprimer cette campagne. Faut-il vraiment exécuter cette action ?",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Oui annuler"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    id,
                    sw,
                    loaderText: "Suppression en cours..."
                }
                axiosApi.post('ctr/airdrops/prize-pools/delete-campaign.php', QueryString.stringify(data))
                .then(() => {})
            }
      
        })
    }

    if(!campaignData) return (
        <Box sx={{pt: 5, textAlign: 'center'}}>
            Chargement...
        </Box>
    )
    
    return (

        <>

            <Routes>
                <Route path='/' element={
                    <Box
                        sx={{
                            pt: 5,
                            maxWidth: 600,
                            margin: 'auto'
                        }}
                    >

                        <Typography
                            textAlign='center'
                            className='Montserrat'
                            sx={{
                                mb: 2,
                                opacity: 0.8
                            }}
                        >
                            { campaignData.name } 
                            <br />
                            <PrizePoolStatusBadge status={ campaignData.status } />
                        </Typography>

                        <Typography
                            textAlign='center'
                            className='Montserrat'
                            fontWeight='bold'
                            variant='h5'
                            sx={{
                                mb: 5
                            }}
                        >
                            { campaignData.title }
                        </Typography>

                        <Avatar
                            src={ campaignData.img } 
                            sx={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: 5,
                                mb: 5
                            }}
                        />

                        <div style={{opacity: 0.8}} dangerouslySetInnerHTML={{__html: campaignData.description}} />


                        <TableContainer component={Paper}  sx={{mt:4}}>
                            <Table aria-label="customized table">

                                <TableBody>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Statut
                                        </StyledTableCell>
                                        <StyledTableCell 
                                            align="left"
                                            sx={{cursor: 'pointer'}}
                                        >
                                            <PrizePoolStatusBadge status={ campaignData.status } />
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Nombre de gagnants
                                        </StyledTableCell>
                                        <StyledTableCell 
                                            align="left"
                                            sx={{cursor: 'pointer'}}
                                        >
                                            <b>{ campaignData.num_winners}</b>
                                            {
                                                campaignData.status === 'completed' &&
                                                <Link to={'/ctr/airdrops/prize-pools/campaigns/' + campaignData.id + '/winners-list/'}>
                                                    Afficher
                                                </Link>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Date de debut
                                        </StyledTableCell>
                                        <StyledTableCell 
                                            align="left"
                                            sx={{cursor: 'pointer'}}
                                        >
                                            <b>{ campaignData.start_date}</b>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Date de fin
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <b>{ campaignData.end_date }</b>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Stack
                            direction='row'
                            spacing={5}
                            sx={{
                                mt: 5,
                                mb: 3
                            }}
                        >

                            <Button
                                onClick={() => navigate('/ctr/airdrops/prize-pools/campaigns/' + campaignData.id + '/edit/')}
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'none'
                                }}
                            >
                                Modifier
                            </Button>

                            {
                                campaignData.status === 'progress' &&
                                <Button
                                    color='warning'
                                    onClick={endCampaign}
                                    sx={{
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}
                                >
                                    Marquer la fin
                                </Button>
                            }

                            <Button
                                color='error'
                                onClick={deleteCampaign}
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'none'
                                }}
                            >
                                Supprimer
                            </Button>

                        </Stack>

                        <input type="password" value={sw} onChange={(e) => setSw(e.target.value)} />
                        
                    </Box>
                } />

                <Route path='/winners-list' element={<WinnersList basicDatas={basicDatas} />} />
            </Routes>
                
        </>
    )
}
