import { Link, Route, Routes } from 'react-router-dom'
import { basicProps } from '../../../utils/interfaces'
import WithdrawCommissions from './WithdrawCommissions'

export default function Sponsorships(props: basicProps) {

    const basicDatas = props.basicDatas;

    return (
        <div>

            <Routes>
                
                <Route path='/' element={
                    <div style={{textAlign:'center'}}>
            
                        <br /><br />
            
                        <Link to="/ctr/sponsorships/withdraw-commissions" className='link' style={{fontSize:'18px'}}>Retraits commissions</Link>
                        <br /><br />
            
                    </div>
                } />

                <Route path='/withdraw-commissions' element={<WithdrawCommissions basicDatas={basicDatas} />} />

            </Routes>

        </div>
    )
}
