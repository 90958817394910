import { Avatar, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, TextareaAutosize, Typography } from '@mui/material'
import { useState } from 'react'
import axiosApi from '../../../../functions/axiosApi';
import { User, basicProps } from '../../../../utils/interfaces';
import QueryString from 'qs';

export default function FreezeForm(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [action, setAction] = useState('')

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);

        axiosApi.post("wallet/transfer/verify-receiver-account.php", QueryString.stringify({receiverID: data.get('userID')}))
        .then(response => {
            if(response.data.status === 'success'){

                const receiverInfo: User = response.data.receiverAccount;

                basicDatas.dialogManager({
                    dialogTitle: 'Utilisateur',
                    dialogText: (
                        <Box sx={{
                            textAlign: 'center'
                        }}>
                            <Avatar src={receiverInfo.avatarUrl}
                                    sx={{
                                        margin: 'auto',
                                        width: '60px',
                                        height: '60px',
                                        boxShadow: 1,
                                        mb: 2
                                    }} />

                            <Typography sx={{mb: 3}}>
                                
                                { receiverInfo.firstName + ' ' + receiverInfo.lastName }

                                <br /> <br />

                                <b style={{fontSize: 'small'}}>{ "ID : " + receiverInfo.user_id}</b>

                            </Typography>

                            

                            <Typography variant='caption'>
                                Est-ce bien le compte que vous souhaitez modifier ?
                            </Typography>
                        </Box>
                    ),
                    dialogAgreeBtnColor: 'info',
                    dalogCloseBtnText: 'Non',
                    dialogAgreeBtnText: 'Confirmer'
                  }, (response) => {
                    
                    if(response === 'ok') {
                        
                        data.set('loaderText', "Traitement en cours...");

                        axiosApi.post("ctr/finances/freeze-assets/lock-and-unlock.php", data)
                        .then(response => {

                            if(response.data.status === 'success') {
                                setAction('');
                            }
                        })
                    }
              
                })
            }
        })
    }

    return (
        <Box>
            
            <Typography variant='h5' sx={{textAlign: 'center', mb: 5}}>
                Géler et débloquer les actifs
            </Typography>

            <Box 
                component='form'
                onSubmit={submit}
                >

                <TextField
                    type='text'
                    name='userID'
                    label='ID utilisateur'
                    placeholder='userID'
                    required
                    fullWidth
                    autoComplete='userID'
                    sx={{mb: 2}}
                />

                <TextField
                    type='number'
                    name='amount'
                    inputProps={{step: 'any'}}
                    label='Montant'
                    placeholder='Montant'
                    required
                    fullWidth
                    sx={{mb: 2}}
                />

                <FormControl fullWidth sx={{mb: 2}}>

                    <InputLabel id="action_label">Action</InputLabel>
                
                    <Select
                        labelId="action_label"
                        name='action'
                        label="Fournisseur prix"
                        fullWidth
                        margin="dense"
                        required
                        value={action}
                        onChange={(e) => setAction(e.target.value + '')}
                        >

                        {
                            ['lock', 'unlock'].map((type, index) => {

                                return (
                                    <MenuItem key={ index } value={ type }>
                                        { type }
                                    </MenuItem>
                                )

                            })
                        }
        
                    </Select>        

                </FormControl>

                {
                    action === 'lock' &&
                    <TextareaAutosize
                        name='motif'
                        id="address"
                        minRows={3}
                        required
                        placeholder="Raison"
                        style={{ 
                            width:'100%', 
                            padding:'10px', 
                            borderRadius:'5px', 
                            border: "1px solid #bbb",
                            marginBottom: '15px'
                        }}
                    />
                }

                <TextField
                    type='password'
                    name='pinCode'
                    label='Code PIN'
                    placeholder='PIN'
                    required
                    fullWidth
                    sx={{mb: 2}}
                />

                <TextField
                    type='password'
                    label="sw"
                    name='sw'
                    fullWidth
                    sx={{mb: 3}}
                />

                <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    >
                    Exécuter
                </Button>

            </Box>

        </Box>
    )
}
