import React from 'react'
import { basicProps } from '../../utils/interfaces'
import { Route, Routes } from 'react-router-dom'
import HomeHelp from './HomeHelp'
import TradeHelp from './trade-help/TradeHelp'
import AuthHelp from './auth-help/AuthHelp'

export default function Help(props: basicProps) {
    return (
        <div>
            <Routes>
                <Route
                    path='/'
                    element={<HomeHelp />}
                />

                <Route
                    path='/trade-help/*'
                    element={<TradeHelp />}
                />

                <Route
                    path='/auth-help/*'
                    element={<AuthHelp />}
                />
            </Routes>
        </div>
    )
}
