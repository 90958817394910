import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { basicProps } from '../../../../../utils/interfaces'
import VerifiedIcon from '@mui/icons-material/Verified';
import { Link } from 'react-router-dom';
import TaskStatusBadge from './TaskStatusBadge';
import { isPC } from '../../../../../functions/basicFunctions';

export default function KycTask(props: basicProps) {

    const basicDatas = props.basicDatas;
    let authUser = basicDatas.authUser;
    let accountIsVerified = authUser.vip_level === 'vip1';

    return (
        <Box
            sx={{
                p: 2,
                mb: 5,
                bgcolor: '#F7F7F7',
                borderLeft: accountIsVerified ? '5px solid green' : 'none',
                borderRadius: 3
            }}
        >

            <Stack
                direction='row'
                spacing={isPC ? 3 : 1.5}
            >

                <Avatar
                    src={ authUser.avatarUrl } 
                    sx={{
                        width: isPC ? 50 : 40,
                        height: isPC ? 50 : 40
                    }}
                />
                <Typography>

                    <TaskStatusBadge 
                        status={accountIsVerified ? 'completed' : 'not_completed'} 
                        sx={{mb: 1}}
                    />

                    <Typography variant='h6' fontWeight='bold' className='Montserrat'>
                        Passer le KYC <VerifiedIcon color={accountIsVerified ? 'success' : 'inherit'} fontSize='small' />
                    </Typography>
                    <Typography sx={{opacity: 0.8}}>
                        Faites vérifier votre compte CRYPTO-FIAT
                    </Typography>

                    {
                        !accountIsVerified &&
                        <Link to={'/kyc/'}>
                            <Button
                                variant='outlined'
                                className='Montserrat'
                                sx={{
                                    fontWeight: 'bold',
                                    mt: 2
                                }}
                            >
                                Vérifier maintenant
                            </Button>
                        </Link>
                    }

                    
                </Typography>
            </Stack>
        </Box>
    )
}
