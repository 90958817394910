import React from 'react'

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoPopup from '../../components/InfoPopup';
import InfoIcon from '@mui/icons-material/Info';
import { publicPath } from '../../functions/basicFunctions';
import { Box, Typography } from '@mui/material';
import { basicProps } from '../../utils/interfaces';


const MustNotBlurred = <Typography>
    <Typography variant='caption'>
        <HighlightOffIcon color='error' fontSize='small' /> La photo ne doit pas être floue
    </Typography>
</Typography>

const MustNotCropped = <Typography>
    <Typography variant='caption'>
        <HighlightOffIcon color='error' fontSize='small' /> La photo ne doit pas être rognée (coupée).
    </Typography>
</Typography>

const MustNotReturned = <Typography>
    <Typography variant='caption'>
        <HighlightOffIcon color='error' fontSize='small' /> L'image ne doit pas être retournée ou inclinée. 
    </Typography>
</Typography>

const MustContainWord = <Typography>
    <Typography variant='caption'>
        <CheckCircleIcon fontSize='small' /> Écrivez "CRYPTO-FIAT" et la "DATE" d'aujourd'hui sur une feuille. 
    </Typography>
</Typography>

const MustContaindDocument = <Typography>
    <Typography variant='caption'>
        <CheckCircleIcon fontSize='small' /> Tenez votre carte et la feuille en main, assurez-vous que le texte est clairement visible et prenez un selfie. 
    </Typography>
</Typography>

export const FrontCardInstructions = (props: basicProps) => 

{

    return(
        <Box>
            <Typography variant='caption' fontWeight='bold'>
                Respectez ces consignes :
            </Typography>
            { MustNotBlurred }
            { MustNotCropped }
            { MustNotReturned }

            <InfoPopup {...props}
                title={"Exemple Photo KYC"}
                text={
                    <div>
                        <img 
                            src={publicPath + 'images/kyc_front_card_example.png'} 
                            alt="kyc_front_card_example" 
                            width={'100%'} 
                        />
                    </div>
                }>

                <Typography   
                    className='small text-muted'
                    sx={{
                        fontStyle: 'oblique',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        mt: 2
                    }}
                > 
                    <InfoIcon sx={{mr:0.5, fontSize:'16px'}} color='info' />
                    Voir un exemple
                </Typography>
            </InfoPopup>
        </Box>
    )
}


export const BacksideCardInstructions = (props: basicProps) => 

{

    return(
        <Box>
            <Typography variant='caption' fontWeight='bold'>
                Respectez ces consignes :
            </Typography>
            { MustNotBlurred }
            { MustNotCropped }
            { MustNotReturned }

            <InfoPopup {...props}
                title={"Exemple Photo KYC"}
                text={
                    <div>
                        <img 
                            src={publicPath + 'images/kyc_backside_card_example.png'} 
                            alt="kyc_front_card_example" 
                            width={'100%'} 
                        />
                    </div>
                }>

                <Typography   
                    className='small text-muted'
                    sx={{
                        fontStyle: 'oblique',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        mt: 2
                    }}
                > 
                    <InfoIcon sx={{mr:0.5, fontSize:'16px'}} color='info' />
                    Voir un exemple
                </Typography>
            </InfoPopup>
        </Box>
    )
}

export const SelfieInstructions = (props: basicProps) => 

{

    return(
        <Box>
            <Typography variant='caption' fontWeight='bold'>
                Respectez ces consignes :
            </Typography>
            
            { MustContainWord }
            { MustContaindDocument }
            { MustNotBlurred }
            { MustNotCropped }
            { MustNotReturned }

            <InfoPopup {...props}
                title={"Exemple Selfie KYC"}
                text={
                    <div>
                        <img 
                            src={publicPath + 'images/kyc_selfie_example.png'} 
                            alt="kyc_front_card_example" 
                            width={'100%'} 
                        />
                    </div>
                }>

                <Typography   
                    className='small text-muted'
                    sx={{
                        fontStyle: 'oblique',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        mt: 2
                    }}
                > 
                    <InfoIcon sx={{mr:0.5, fontSize:'16px'}} color='info' />
                    Voir un exemple
                </Typography>
            </InfoPopup>
        </Box>
    )
}
