import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material'
import axiosApi from '../../../../functions/axiosApi'
import { readabilityNumber } from '../../../../functions/basicFunctions';

interface generalStatsType {
    totalTransactionVolume: number,
    dayTransactionVolume: number,
    monthTransactionVolume: number
}

export default function () {

    const [generalStats, setGeneralStats] = useState<generalStatsType>()

    useEffect(() => {
        getGeneralStats()
    }, [])
    

    const getGeneralStats = () => {
        axiosApi.post("ctr/stats/general/general-stats.php")
        .then(response => {

            if(response.data.status === 'success'){
                setGeneralStats(response.data.generalStats);
            }
        })
    }
    return (
        <div style={{maxWidth: '700px', margin:'auto', paddingTop:'30px'}}>
            
            <Typography variant='h5' className='text-center'>
                Statistiques générales
            </Typography>

            <Box component={'div'}
                sx={{
                    p: 2, 
                    mt: 5
                }}>

                <Typography sx={{
                    boxShadow: 2,
                    p: 2,
                    mb: 3
                }}>
                    Volume transactions aujourd'hui
                    <Typography variant='h5' sx={{fontWeight: 'bold', mt: 1}}>
                        { generalStats?.dayTransactionVolume ? readabilityNumber(generalStats.dayTransactionVolume) + " FCFA" : " ..."}
                    </Typography>
                </Typography> 

                <Typography sx={{
                    boxShadow: 2,
                    p: 2,
                    mb: 3
                }}>
                    Volume transactions ce mois
                    <Typography variant='h5' sx={{fontWeight: 'bold', mt: 1}}>
                        { generalStats?.monthTransactionVolume ? readabilityNumber(generalStats.monthTransactionVolume) + " FCFA" : " ..."}
                    </Typography>
                </Typography> 

                <Typography sx={{
                    boxShadow: 2,
                    p: 2,
                    mb: 3
                }}>
                    Volume total transactions
                    <Typography variant='h5' sx={{fontWeight: 'bold', mt: 1}}>
                        { generalStats?.totalTransactionVolume ? readabilityNumber(generalStats.totalTransactionVolume) + " FCFA" : " ..."}
                    </Typography>
                </Typography>  

            </Box>
        </div>
    )
}
