import { useState, useEffect } from 'react'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Chip, Alert, AlertTitle } from '@mui/material';
import AddAddress from './AddAddress';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import axiosApi from '../../../functions/axiosApi';
import { basicProps, saveAddressType } from '../../../utils/interfaces';
import QueryString from 'qs';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MetaTags } from 'react-meta-tags';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoPopup from '../../../components/InfoPopup';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { isPC, maxTextLength } from '../../../functions/basicFunctions';

export default function Addresses(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [open, setOpen] = useState(false);
    const [addresses, setAddresses] = useState<saveAddressType[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [havePendingAddress, setHavePendingAddress] = useState(false);

    useEffect(() => {

        let _havePendingAddress = 0;

        addresses.forEach(item => {

            if((item._system === 'blockchain') && (item.status === 'basic')) {
                _havePendingAddress++;
            }
        });

        setHavePendingAddress(_havePendingAddress > 0 ? true : false);

    }, [addresses])
    

    useEffect(() => {
        getAddresses();
    }, [queryValue])
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getAddresses = () => {

        setIsLoading(true);

        axiosApi.post("settings/saved_addresses/get-addresses.php", QueryString.stringify({offset, limit:3, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                //const newAddressesArray = addresses.concat(response.data.addresses);
                setAddresses(response.data.addresses);
                // setOffset(offset + 3);

                // if(response.data.addresses.length === 0){
                //     setEnded(true)
                // }
            }

        }).catch(() => {
            setIsLoading(false);
        })
    }

    const deleteAddress = (id) => {

        basicDatas.dialogManager({
            dialogTitle: 'Retirer une adresse',
            dialogText: "Souhaitez-vous vraiment retirer définitivement cette adresse ?",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Retirer"
          }, (response) => {
            
            if(response === 'ok') {
                axiosApi.post("settings/saved_addresses/remove.php", QueryString.stringify({id}))
                .then(response => {
                    if(response.data.status === 'success') {
                        setOffset(0);
                        setAddresses([]);
                        getAddresses();
                    }
                })
            }
      
        })
    }

    return (
        <Box
            sx={{
                maxWidth: 600,
                pt: 4,
                margin: 'auto'
            }}>

            <MetaTags id="settings">
                <title>Mes adresses enregistrées</title>
                <meta name="description" content={basicDatas.appName + " - Mes adresses enregistrées"} />
                <meta property="og:title" content={basicDatas.appName + " - Mes adresses enregistrées"} />
            </MetaTags>
            
            <Typography variant='h5' sx={{textAlign: 'center', mb: 5}}>
                Adresses

                <br />

                <Typography variant='caption'>
                    Enregistrez vos adresses pour utiliser plus facilement lors de la création de vos ordres
                </Typography>

            </Typography>

            <Typography
                sx={{
                    textAlign: 'center',
                    mb: 5
                }}>
                <Button variant="outlined" onClick={handleClickOpen} startIcon={<NoteAddIcon />}>
                    Ajouter une adresse
                </Button>
            </Typography>

            {
                addresses.length === 0 && 
                <Typography
                    sx={{
                        textAlign: 'center'
                    }}>
                    <Typography variant='caption'> 
                        Vous n'avez aucune adresse enregistrée
                    </Typography>
                </Typography>
            }

            <Alert severity="info" sx={{textAlign:'left', mb: 5}} {...props}>
                <AlertTitle sx={{fontWeight: 'bold'}}>Statut d'adresse</AlertTitle>

                {
                    havePendingAddress
                    ? "Pour vérifier le statut d'une adresse en attente de vérification, veuillez actualiser la page après 5 minutes."
                    : "Si une adresse en attente de vérification ne s'affiche plus, cela signifie qu'elle a été rejetée. Veuillez consulter vos mails pour trouver le motif du rejet."
                }
                
            </Alert>

            {
                addresses.map(itemAddress => {
                    return (
                        <Box
                            key={itemAddress.id}
                            sx={{
                                pb: 3,
                                mb: 3,
                                borderBottom: '1px solid #eee'
                            }}>
                            <Typography sx={{fontWeight: 'bold'}}>
                                {itemAddress.walletName}

                                <Button
                                    onClick={() => deleteAddress(itemAddress.id)}
                                    sx={{
                                        float: 'right'
                                    }}>
                                    <DeleteForeverIcon color='error' />
                                </Button>

                            </Typography>
                            <Typography
                                color='GrayText'>
                                { itemAddress.network }
                            </Typography>
                         
                            <Typography >
                              
                                { 
                                    itemAddress._system === 'blockchain' &&  
                                    <>
                                        { maxTextLength(isPC ? 30 : 20, itemAddress.blockchainAddress) } { itemAddress.memo &&  <Typography variant='caption'> ( { itemAddress.memo } ) </Typography> }
                                    </>
                                }
                                {
                                    itemAddress._system === 'mobile-money' && 
                                    <>
                                        { itemAddress.phoneNumber } (<Typography variant='caption'> { itemAddress.phoneOwnerName } </Typography>)
                                    </>
                                }
                            </Typography>
                            
                            { 
                                ((itemAddress._system === 'blockchain') && (itemAddress.status === 'basic')) &&  
                                <>
                                    <Chip 
                                        label="En cours de vérification..." 
                                        sx={{
                                            backgroundColor: "#FDEDED",
                                            color: "#E5593C",
                                            borderRadius: 1,
                                            mr: 2
                                        }} 
                                        variant="filled" 
                                        size="small" 
                                        icon={<AccessTimeIcon fontSize='small' sx={{mr:0.5}} />}
                                    />

                                    <InfoPopup basicDatas={basicDatas}
                                        title={"Pourquoi la vérification d'adresse est essentielle chez " + basicDatas.appName + "?"}
                                        text={
                                            <div>
                                                <b>Sécurité accrue :</b> Nous vérifions les adresses de portefeuilles avant les transactions pour prévenir les détournements de fonds causés par des malwares qui remplacent les adresses copiées.
                                                <br /><br />
                                                <b>Conformité obligatoire :</b> Il est impératif que l'adresse de portefeuille vous appartienne personnellement pour prévenir les fraudes et respecter nos règles de sécurité.
                                                <br /><br />
                                                Ces mesures, bien que nouvelles, sont essentielles pour sécuriser vos actifs et empêcher les pertes. Nous vous remercions de votre compréhension et sommes à votre disposition pour toute assistance nécessaire.
                                                <br /><br />
                                                <a href="https://medium.com/@cryptofiat1022/pourquoi-lenregistrement-d-adresse-est-désormais-indispensable-sur-cryptofiat-229f502dcadf" target='_blank'>En savoir plus</a>
                                            </div>
                                        }>

                                        <Typography   
                                            variant='caption'
                                            className='small text-muted'
                                            sx={{
                                                fontStyle: 'oblique',
                                                cursor: 'pointer'
                                            }}
                                        > 
                                            <InfoIcon sx={{mr:0.5, fontSize:'16px'}} color='info' />
                                            Pourquoi {basicDatas.appName} doit vérifier mon adresse ?
                                        </Typography>
                                    </InfoPopup>
                                </>
                            }

                            { 
                                ((itemAddress._system === 'blockchain') && (itemAddress.status === 'verified')) &&  
                                <>
                                    <Chip 
                                        label="Adresse vérifiée" 
                                        sx={{
                                            backgroundColor: "#E8F3EE",
                                            color: "#2E7D32",
                                            borderRadius: 1,
                                            mr: 2
                                        }} 
                                        variant="filled" 
                                        size="small" 
                                        icon={<CheckCircleIcon fontSize='small' sx={{mr:0.5}} color='success'/>}
                                    />
                                </>
                            }
                            
                        </Box>
                    )
                })
            }

            {/* <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getAddresses()}>
                            Afficher plus
                        </Button>
                }

            </div> */}

            <Dialog open={open} onClose={handleClose} >

                <DialogTitle>Ajouter une adresse</DialogTitle>

                <DialogContent>
                    <AddAddress basicDatas={basicDatas} setOpen={setOpen} getAddresses={getAddresses} />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>

        </Box>
    )
}
