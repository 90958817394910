import { Box, Button, Checkbox, Chip, TextareaAutosize, TextField, Typography } from '@mui/material';
import QueryString from 'qs';
import React, {useState} from 'react'
import axiosApi from '../../../functions/axiosApi';
import { basicProps, currencyType, networkType } from '../../../utils/interfaces';

interface itemNetworkProps {
  network: networkType,
  currency: currencyType,
  handleManageBuyAndSellAction: (action: string, network?: string) => void
}

export default function ItemNetwork(props : itemNetworkProps) {

    const network = props.network;
    const currency = props.currency;
    const allowed_networks = currency.allowed_networks;

    let networkIsAllowed = allowed_networks?.filter((item) => item.network === network.symbol)

    const [minBuyPerTransaction, setMinBuyPerTransaction] = useState<number | string>(networkIsAllowed && networkIsAllowed.length > 0 ? networkIsAllowed[0].minBuyPerTransaction : '');
    const [maxBuyPerTransaction, setMaxBuyPerTransaction] = useState<number | string>(networkIsAllowed && networkIsAllowed.length > 0 ? networkIsAllowed[0].maxBuyPerTransaction : '');
    const [minSellPerTransaction, setMinSellPerTransaction] = useState<number | string>(networkIsAllowed && networkIsAllowed.length > 0 ? networkIsAllowed[0].minSellPerTransaction : '');
    const [maxSellPerTransaction, setMaxSellPerTransaction] = useState<number | string>(networkIsAllowed && networkIsAllowed.length > 0 ? networkIsAllowed[0].maxSellPerTransaction : '');
    const [buyAlertMessage, setBuyAlertMessage] = useState(networkIsAllowed && networkIsAllowed.length > 0 ? networkIsAllowed[0].buyAlertMessage : '');
    const [sellAlertMessage, setSellAlertMessage] = useState(networkIsAllowed && networkIsAllowed.length > 0 ? networkIsAllowed[0].sellAlertMessage : '');
    const [allowNetwork, setAllowNetwork] = useState(networkIsAllowed && networkIsAllowed?.length > 0);
    const [networkFee, setNetworkFee] = useState<number | string>(networkIsAllowed && networkIsAllowed.length > 0 ? networkIsAllowed[0].networkFee : '');
    const [sw, setSw] = useState('');

    const handleManageNetwork = () => {
      axiosApi.post('ctr/currencies/set-allowed-networks.php', QueryString.stringify({
        currencyID:currency.id, 
        network:network.symbol, 
        allowNetwork, 
        networkFee,
        minBuyPerTransaction,
        maxBuyPerTransaction,
        minSellPerTransaction,
        maxSellPerTransaction,
        buyAlertMessage,
        sellAlertMessage,
        sw
      }))
    }

    return (
      <Box>

        <Typography sx={{
            display:'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 2
        }}>

          <Chip
            label={network.symbol}
            avatar={<Checkbox onChange={(e) => setAllowNetwork(e.target.checked)} 
                              defaultChecked={networkIsAllowed && networkIsAllowed?.length > 0}
              />
            }
            variant="outlined"
            sx={{verticalAlign:'middle'}}
          /> <br />

          <Button variant='outlined' sx={{mt:1, verticalAlign:'middle'}} onClick={handleManageNetwork}>Save</Button>

        </Typography>

        <Box sx={{
            display: 'grid', 
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 3
        }}>

            <TextField
                    type='number'
                    name='minBuyPerTransaction'
                    label="Min achat"
                    value={minBuyPerTransaction}
                    onChange={(e) => setMinBuyPerTransaction(e.target.value)}
                    placeholder='Quantité min'
                    />

            <TextField
                    type='number'
                    name='maxBuyPerTransaction'
                    label="Max achat"
                    value={maxBuyPerTransaction}
                    onChange={(e) => setMaxBuyPerTransaction(e.target.value)}
                    placeholder='Quantité max'
                    />

            <TextField
                    type='number'
                    name='minSellPerTransaction'
                    label="Min vente"
                    value={minSellPerTransaction}
                    onChange={(e) => setMinSellPerTransaction(e.target.value)}
                    placeholder='Quantité min'
                    />

            <TextField
                    type='number'
                    name='maxSellPerTransaction'
                    label="Max vente"
                    value={maxSellPerTransaction}
                    onChange={(e) => setMaxSellPerTransaction(e.target.value)}
                    placeholder='Quantité max'
                    />

            <TextField  name='fee'
                        type='number'
                        label="Frais"
                        value={networkFee}
                        onChange={(e) => setNetworkFee(e.target.value)}
                        placeholder='Frais réseau' />
            
            <TextareaAutosize
                    aria-label="Message d'alerte Achat"
                    name='buyAlertMessage'
                    minRows={2}
                    value={buyAlertMessage}
                    onChange={(e) => setBuyAlertMessage(e.target.value)}
                    placeholder="Message d'alerte Achat"
                    style={{ 
                        padding:'10px', 
                        borderRadius:'5px', 
                        border: "1px solid #bbb", 
                        backgroundColor:'transparent' 
                    }}
                    />

            <TextareaAutosize
                    aria-label="Message d'alerte Vente"
                    name='sellAlertMessage'
                    minRows={2}
                    value={sellAlertMessage}
                    onChange={(e) => setSellAlertMessage(e.target.value)}
                    placeholder="Message d'alerte Vente"
                    style={{ 
                        padding:'10px', 
                        borderRadius:'5px', 
                        border: "1px solid #bbb", 
                        backgroundColor:'transparent' 
                    }}
                    />

            <TextField
                type='password'
                label="sw"
                name='sw'
                sx={{mb: 3, mt: 2}}
                value={sw}
                onChange={(e) => setSw(e.target.value)}
            />
        </Box>

        <Typography sx={{mt:5, mb:2}}>
            Action d'achat et de vente pour { network.symbol } :
        </Typography>

        <Chip
            label={"Autoriser l'achat sur " + network.symbol}
            onClick={() => {}}
            avatar={<Checkbox onChange={
                            () => props.handleManageBuyAndSellAction('buy', network.symbol)
                        }
                        checked={allowed_networks && allowed_networks.filter((item_network) => (item_network.network === network.symbol) && item_network?.allowForBuy).length > 0 ? true : false }
                        />
                    }
            variant="outlined"
            sx={{m:1}}
            />

        <Chip
            label={"Autoriser la vente sur " + network.symbol}
            onClick={() => {}}
            avatar={<Checkbox onChange={
                            () => props.handleManageBuyAndSellAction('sell', network.symbol)
                        }
                        checked={ allowed_networks && allowed_networks.filter((item_network) => (item_network.network === network.symbol) && item_network?.allowForSell).length > 0 ? true : false }
                        />
                    }
            variant="outlined"
            sx={{m:1}}
            />

      </Box>
    )
}
