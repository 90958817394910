import { 
    Avatar, Badge, Box, ListItem, List, TextField, ToggleButton, 
    ToggleButtonGroup, Typography, ListItemAvatar, ListItemText,
    Button
} from '@mui/material'
import { useEffect, useState } from 'react'
import axiosApi from '../../../../functions/axiosApi'
import { basicProps, cftPartnerType } from '../../../../utils/interfaces'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import QueryString from 'qs';
import { Link } from 'react-router-dom';
import AddPartner from './AddPartner';

interface partnersStatusCountType {
    all_count: number,
    pause: number,
    certified: number,
    blocked: number
}

export default function ListPartners(props: basicProps) {

    const [partners, setPartners] = useState<cftPartnerType[]>([]);
    const [partnersStatusCount, setPartnersStatusCount] = useState<partnersStatusCountType>();
    const [status, setStatus] = useState('all');
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getPartners()
    }, [status, queryValue])

    const getPartners = () => {

        setIsLoading(true);

        axiosApi.post("ctr/team-manager/cft-partners/get-partners.php", QueryString.stringify({status, offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);

            if(response.data.status === 'success'){
                
                const newPartnersArray = partners.concat(response.data.partners);
                setPartners(newPartnersArray);
                setPartnersStatusCount(response.data.partnersStatusCount);
                setOffset(offset + 5);
                
                if(response.data.partners.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }
    
    return (
        <Box
            sx={{
                maxWidth: 650,
                margin: 'auto'
            }}>

            <Typography 
                    variant='h5'
                    sx={{
                        textAlign: 'center',
                        mt: 3,
                        mb: 5
                    }}>
                Partenaires CFT
            </Typography>

            <AddPartner basicDatas={props.basicDatas}/>

            <div className="text-center">

                <ToggleButtonGroup
                    color="primary"
                    value={status}
                    exclusive
                    onChange={
                        (e, newStatus) => {
                            setStatus(newStatus);
                            setPartners([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    sx={{
                        maxWidth: '100%',
                        overflow: 'auto'
                    }}
                    aria-label="order-status"
                    >
                    
                    <ToggleButton value="all" sx={{minWidth: 150}}>
                        Tout ({partnersStatusCount ? partnersStatusCount.all_count : 0})
                    </ToggleButton>

                    <ToggleButton value="certified" sx={{minWidth: 150}}>
                        <Badge max={9999999999999} badgeContent={partnersStatusCount ? partnersStatusCount.certified : 0} color="success">
                            Certifié
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="pause" sx={{minWidth: 200}}>
                        <Badge max={9999999999999} badgeContent={partnersStatusCount ? partnersStatusCount.pause : 0} color="primary">
                            En pause
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="blocked" sx={{minWidth: 150}}>
                        <Badge max={9999999999999} badgeContent={partnersStatusCount ? partnersStatusCount.blocked : 0} color="error">
                            Bloqué
                        </Badge>
                    </ToggleButton>

                </ToggleButtonGroup>

                <br /> <br />

                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher un utilisateur : ID" 
                    variant="outlined"
                    placeholder="Entrez ID utilisateur"
                    value={queryValue}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setPartners([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    />

            </div>

            <List 
                sx={{ 
                    width: '100%', 
                    bgcolor: 'background.paper',
                    mt: 3
                }}>

                {
                    partners.map(partner => {

                        const userInfos = partner.userInfos;

                        return (
                            <ListItem 
                                    key={partner.user_id}
                                    alignItems="flex-start">

                                <ListItemAvatar>
                                    <Avatar alt={ userInfos.lastName + " " + userInfos.firstName } 
                                            src={ userInfos.avatarUrl } />
                                </ListItemAvatar>

                                <ListItemText
                                        primary={ userInfos.lastName + " " + userInfos.firstName }
                                        secondary={
                                    <>
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            Statut : <b>{ partner.status }</b>
                                        </Typography>


                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            Pays : <b>{ partner.country }</b>
                                        </Typography>
 
                                        <Link to={'/ctr/team/cft-partners/' + partner.user_id + '/'} className='link'>
                                            <Button>
                                                Accéder
                                            </Button>
                                        </Link>
                                    </>
                                }
                                />
                            </ListItem>
                        )
                    })
                }
                
            </List>

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getPartners()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
