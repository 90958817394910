import { useState, useEffect } from 'react'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import axiosApi from '../../../../../functions/axiosApi';
import QueryString from 'qs';
import { Button, Typography } from '@mui/material';
import { basicProps } from '../../../../../utils/interfaces';
import { Link } from 'react-router-dom';
import ItemBilan from './ItemBilan';

export interface bilan {
    id: number,
    totalValue: number,
    balances: {estimatedValue: number, fundLocationId: number, fundLocation: string}[],
    expenses: {
        total: number,
        items: {
            expenseName: string,
            amount: number,
            date: string
        }[]
    },
    period: string,
    date: string
}

export default function Bilans(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [bilans, setBilans] = useState<bilan[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [sw, setSw] = useState('')

    useEffect(() => {
        getBilans()
    }, [])

    const getBilans = () => {

        setIsLoading(true);

        axiosApi.post("ctr/stats/compta/bilans/get.php", QueryString.stringify({offset, limit:5}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newUsersArray = bilans.concat(response.data.bilans);
                setBilans(newUsersArray);
                setOffset(offset + 5);

                if(response.data.bilans.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    const removeBilan = (bilan: bilan) => {

        basicDatas.dialogManager({
            dialogTitle: "Supprimer le bilan du " + bilan.date,
            dialogText: "Souhaitez-vous confirmer supprimer ce bilan ?",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Oui supprimer"
          }, (response: string) => {
            
            if(response === 'ok') {

                axiosApi.post("ctr/stats/compta/bilans/delete.php", QueryString.stringify({id: bilan.id, sw}))
                .then((_response) => {

                    if(_response.data.status === 'success') {
                        document.location.reload()
                    }
                    
                });
            }
        })
    }
    

    return (

        <div
            style={{
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <br />

            <Typography
                variant='h5'
                textAlign='center'
            >
                Bilans
            </Typography>

            <br /><br />

            <Link to='/ctr/stats/compta/graphic/'>
                <Button
                    type='button'
                    sx={{textTransform: 'none'}}
                >
                    Graphique
                </Button>
            </Link>

            <br /><br />
                            
            {
                bilans.map((bilan: bilan) => {
                    return (
                        <ItemBilan key={bilan.id} bilan={bilan} removeBilan={removeBilan} />
                    )
                })
            } 

            <br />

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getBilans()}>
                            Afficher plus
                        </Button>
                }

            </div>

            <br /><br />

            <input type="password" name='sw' value={sw} onChange={(e) => setSw(e.currentTarget.value)} />

        </div>
    )
}
