import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { isPC, publicPath } from '../../functions/basicFunctions'

interface myProps {
    setDisplayConseils: (value: boolean) => void
}

export default function Conseils(props: myProps) {
    return (
        <Box>
            
            <Typography fontWeight='bold' sx={{fontSize: 18}}>
                Comment bien prendre des photos pour KYC ?
            </Typography>

            <br />

            <p style={{fontWeight: 'bold', opacity: 0.8}}>
                Assurez-vous que vos photos respectent les consignes ci-dessous :
            </p>

            <ul>

                <li style={{marginBottom: '10px'}}>
                    Assurez-vous de filmer dans un endroit bien éclairé.
                </li>

                <li style={{marginBottom: '10px'}}>
                    Assurez-vous que la pièce d'identité est entièrement visible et que les informations sont bien lisibles.
                </li>

                <li style={{marginBottom: '10px'}}>
                    Assurez-vous que la photo est bien cadrée et dans le bon sens de lecture. Une photo inclinée sera rejetée.
                </li>

                <li>
                    Les captures d'écran ou scanners ne sont pas acceptés.
                </li>
            </ul>

            <img 
                src={isPC ? publicPath + 'images/kyc_img_exemples.png' : publicPath + 'images/kyc_img_exemples_mobile.png'} 
                alt="kyc_img_exemples" 
                width={'100%'} 
                style={{
                    borderRadius: '5px',
                    marginTop: '10px',
                    marginBottom: '50px'
                }}
            />
            
            <p style={{fontWeight: 'bold', opacity: 0.8}}>
                Conseils pour un selfie KYC :
            </p>

            <ul>

                <li style={{marginBottom: '10px'}}>
                    Écrivez <b>"CRYPTO-FIAT"</b> et la <b>"DATE"</b> du jour sur une feuille.
                </li>

                <li style={{marginBottom: '10px'}}>
                    Tenez la feuille et la carte en main et prenez un selfie.
                </li>

                <li style={{marginBottom: '10px'}}>
                    Assurez-vous d'être dans un endroit bien éclairé.
                </li>

                <li style={{marginBottom: '10px'}}>
                    Votre visage doit être entièrement et clairement visible.
                </li>

                <li style={{marginBottom: '10px'}}>
                    Assurez-vous que le texte sur la feuille est clairement visible et que la carte aussi.
                </li>

                <li style={{marginBottom: '10px'}}>
                    Évitez les reflets ou les ombres qui pourraient rendre le texte illisible.
                </li>

                <li>
                    Assurez-vous que la photo est bien cadrée et que rien n'obstrue le texte sur la feuille ou la carte.
                </li>
            </ul>

            <img 
                src={publicPath + 'images/kyc_selfie_example.png'} 
                alt="kyc_selfie_example" 
                width={'100%'} 
                style={{
                    borderRadius: '5px',
                    marginTop: '10px',
                    marginBottom: '10px'
                }}
            />


            <Button 
                variant='contained'
                fullWidth
                sx={{
                    textTransform: 'none',
                    mt: 5,
                    p: 2
                }}
                onClick={() => props.setDisplayConseils(false)}
            >
                J'ai bien compris comment prendre des photos KYC
            </Button>

        </Box>
    )
}
