import { Avatar, Badge, Box, Button, Chip, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import axiosApi from '../../functions/axiosApi';
import SetAvatar from './SetAvatar';
import { MetaTags } from 'react-meta-tags';
import { basicProps } from '../../utils/interfaces';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { copyClipboard, isPC, publicPath } from '../../functions/basicFunctions';
import KycStatusBox from '../kyc/KycStatusBox';

export default function Account(props: basicProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;
    const navigate = useNavigate();

    const [idCopied, setIdCopied] = useState(false);

    const [lastName, setLastName] = useState(authUser.lastName);
    const [firstName, setFirstName] = useState(authUser.firstName);
    const [email, setEmail] = useState(authUser.email);

    useEffect(() => {
        setLastName(authUser.lastName);
        setFirstName(authUser.firstName);
        setEmail(authUser.email);
    }, [authUser])
    

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loaderText', 'Modification des informations en cours...');

        axiosApi.post('account/update_account.php', data)
        .then(response => {

            const responseData = response.data;

            if((responseData.status === 'success') && (response.data.message.length !== 0)){
                basicDatas.setAuthUser(responseData.auth_user);            
            }
        })

    }

    return (
        <div style={{maxWidth:'450px', margin:'auto'}}>

            <MetaTags id="my-account">
                <title>Mon compte | { basicDatas.appName } </title>
                <meta name="description" content={basicDatas.appName + " - Mon compte"} />
                <meta property="og:title" content={basicDatas.appName + " - Mon compte"} />
            </MetaTags>

            <Box
                sx={{
                    marginTop: window.screen.width > 700 ? 8 : 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >

                <Tooltip title="Modifier l'avatar">
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <CameraAltIcon sx={{boxShadow:20, borderRadius:'5px'}} />
                        }
                        sx={{mb:3, cursor:'pointer'}}
                        onClick={() => navigate("/account/set-avatar/")}
                    >

                        <Avatar sx={{ bgcolor: 'secondary', width:'75px', height:'75px' }} 
                                src={authUser.avatarUrl}
                                alt={authUser.lastName || authUser.firstName ? authUser.firstName + ' ' + authUser.lastName : 'I'} 
                                />
                    </Badge>
                </Tooltip>


                <Routes>
                    <Route path='/set-avatar' element={<SetAvatar basicDatas={basicDatas} />} />
                </Routes>

                <Typography component="h1" variant="h5">
                    Mon compte
                </Typography>

                <Box sx={{mb: 2}}>
                    <Tooltip title="Cliquez pour copier l'ID du compte">
                        <Chip
                                label={<span className='text-muted' style={{fontSize:'small'}}>{ "ID : " + basicDatas.authUser.user_id}</span>}
                                avatar={
                                    !idCopied 
                                    ? <ContentCopyIcon sx={{fontSize:'small'}} />
                                    : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                                }
                                variant="outlined"
                                sx={{ fontWeight:'bold', fontSize:'20px', marginTop:'10px', cursor:'pointer' }}
                                onClick={() => { copyClipboard(basicDatas.authUser.user_id, "L'ID du compte a été copié !"); setIdCopied(true)}}
                            />
                    </Tooltip>
                </Box>

                {
                    authUser.vip_level !== 'vip1' &&
                    <Typography sx={{mt: 3, mb: 4}}>

                        <Avatar 
                            src={ publicPath + "images/kyc_airdrop_page_banner.png" }
                            sx={{
                                width: '100%',
                                height: isPC ? '150px' : '120px',
                                borderRadius: '3px',
                                mb: 3
                            }} 
                        />
                        <KycStatusBox basicDatas={basicDatas} vip_level={authUser.vip_level} />
                    </Typography>
                }

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                    <TextField
                        type="text"
                        name="lastName"
                        margin="normal"
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        label="Nom"
                        placeholder='Entrez le nom de famille'
                        autoComplete="lastName"
                        disabled={authUser.vip_level !== 'vip0'}
                        />

                    <TextField
                        margin="normal"
                        type="text"
                        name="firstName"
                        required
                        fullWidth
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        label="Prénom"
                        placeholder='Entrez le prénom'
                        autoComplete="firstName"
                        disabled={authUser.vip_level !== 'vip0'}
                        />

                    
                    <TextField
                        margin="normal"
                        type="text"
                        disabled
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        label="Adresse E-mail"
                        name="email"
                        autoComplete="email"
                    />

                    <Typography
                            sx={{
                                textAlign: 'right',
                                color: 'var(--site-color)',
                                fontSize: 'small',
                                mb: 1,
                                cursor: 'pointer'
                            }}
                        >
                            <a 
                                href={"https://wa.me/+237650839431/?text=Bonjour, je souhaites changer l'adresse mail associée à mon compte CRYPTO-FIAT. Mon identifiant de compte est : " + authUser.user_id + " . Je veux utiliser cette nouvelle adresse : "}
                                target='_blank'
                                onClick={(e) => {
                                    if(authUser.vip_level && authUser.vip_level !== 'vip1') {
                                        e.preventDefault();
                                        alert("Pour modifier votre adresse mail, votre compte doit être vérifié.")
                                    }
                                }}
                            >
                                Modifier l'adresse mail
                            </a>
                    </Typography>
                
                    <Button
                        type="submit"
                        id="update_account_btn"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        >
                        Mettre à jour
                    </Button>
  
                </Box>
            </Box>

        </div>
    )
}
