import { useEffect,useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import CurrenciesList from './pages/currencies/CurrenciesList';
import CtrHome from './pages/CtrHome';
import Networks from './pages/networks/Networks';
import PaymentMethods from './pages/payment-methods/PaymentMethods';
import Orders from './pages/orders/Orders';
import { Box } from '@mui/material';
import Users from './pages/users/Users';
import TeamManager from './pages/team-manager/TeamManager';
import Sponsorships from './pages/sponsorships/Sponsorships';
import Airdrops from './pages/airdrops/Airdrops';
import Stats from './pages/stats/Stats';
import Com from './pages/communication/Com';
import Finances from './pages/finances/Finances';
import Kyc from './pages/kyc/Kyc';
import axiosApi from '../functions/axiosApi';
import Page404 from '../components/Page404';
import Liquidity from './pages/liquidity/Liquidity';

interface ctrProps {
    basicDatas: any
}

export default function Ctr(props: ctrProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const [verified, setVerified] = useState(false)

    useEffect(() => {

        if(sessionStorage.getItem('sw') && (sessionStorage.getItem('sw') === '1')) {
            setVerified(true);
        }
        
    }, [])

    const check = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const data = new FormData(e.currentTarget);

        axiosApi.post('ctr/check.php', data)
        .then(response => {

            if(response.data.status === 'success') {
                setVerified(true);
                sessionStorage.setItem('sw', '1');
            }
        })
    }

    if(!basicDatas.isLoggedIn || (authUser.accountType !== 'admin')) return (
        <Page404 basicDatas={basicDatas} />
    )

    if(!verified) return (
        <Box
            sx={{
                maxWidth: 500,
                margin: 'auto',
                mt: 3
            }}
        >

            <Box
                component='form'
                onSubmit={check}
            >
                <input type="password" name='sw'/>
            </Box>

        </Box>
    )

    return (
        <div>
            <Routes>
                <Route path='/' element={<CtrHome {...props} />} />
                <Route path='/orders/*' element={<Orders {...props} />} />
                <Route path='/users/*' element={<Users {...props} />} />
                <Route path='/stats/*' element={<Stats {...props} />} />
                <Route path='/crypto-list/*' element={<CurrenciesList {...props} />} />
                <Route path='/networks/*' element={<Networks {...props} />} />
                <Route path='/payment-methods/*' element={<PaymentMethods {...props} />} />
                <Route path='/team/*' element={<TeamManager {...props} />} />
                <Route path='/sponsorships/*' element={<Sponsorships {...props} />} />
                <Route path='/finances/*' element={<Finances {...props} />} />
                <Route path='/airdrops/*' element={<Airdrops {...props} />} />
                <Route path='/communication/*' element={<Com {...props} />} />
                <Route path='/kyc/*' element={<Kyc {...props} />} />
                <Route path='/liquidity/*' element={<Liquidity {...props} />} />
            </Routes>
        </div>
    )
}
