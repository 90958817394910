import { Box, Button, TextField, Typography } from '@mui/material'
import AddInvestmentPlan from './AddInvestmentPlan'
import { basicProps, investPlanType } from '../../../utils/interfaces'
import { useState, useEffect } from 'react';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { copyClipboard, readabilityNumber } from '../../../functions/basicFunctions';
import { Link, Route, Routes } from 'react-router-dom';
import ItemInvestPlan from './ItemInvestPlan';
import Payments from './Payments';
import InvestPlanStatusBadge from '../../../components/InvestPlanStatusBadge';

export default function InvestPlans(props: basicProps) {

    const [investPlans, setInvestPlans] = useState<investPlanType[]>([]);
    const [numInvestPlans, setNumInvestPlans] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');


    useEffect(() => {
        getInvestPlans();
    }, [queryValue])

    const getInvestPlans = () => {

        setIsLoading(true);

        axiosApi.post("ctr/liquidity/get-invest-plans.php", QueryString.stringify({
            queryValue,
            offset, 
            limit: 3
        }))
        .then(response => {

            if(response.data.status === 'success'){
                const newDemandsArray = investPlans.concat(response.data.investPlans);
                setInvestPlans(newDemandsArray);
                setNumInvestPlans(response.data.numInvestPlans);
                setIsLoading(false);
                setOffset(offset + 3);

                if(response.data.investPlans.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto',
                pt: 5
            }}
        >

            <Routes>
                <Route 
                    path='/'
                    element={
                        <>

                            <Typography
                                variant='h5'
                                textAlign='center'
                            >
                                Plans d'investissement 
                                <br />
                                { numInvestPlans }
                            </Typography>

                            <AddInvestmentPlan {...props} />

                            <div className="text-center" style={{
                                marginBottom: '40px'
                            }}>
        
                                <TextField 
                                    id="outlined-basic"
                                    type='search' 
                                    label="Rechercher un plan" 
                                    variant="outlined"
                                    placeholder="Entrez l'ID du plan"
                                    value={queryValue}
                                    sx={{mt:3}}
                                    onChange={
                                        (e) => {
                                            setQueryValue(e.target.value);
                                            setInvestPlans([]);
                                            setOffset(0);
                                            setEnded(false)
                                        }
                                    }
                                    />
        
                            </div>

                            {
                                investPlans.length === 0 &&
                                <Typography textAlign='center'>
                                    <Typography variant='caption'>
                                        Aun plan d'investissement trouvé
                                    </Typography>
                                </Typography>
                            }

                            {
                                investPlans.map((itemPlan) => {

                                    const user = itemPlan.user;
                                    
                                    return (
                                        <Box 
                                            key={ itemPlan.planID }
                                            sx={{
                                                mb: 5,
                                                p: 2,
                                                boxShadow: 2,
                                                borderRadius: 2,
                                                opacity: ((itemPlan.paymentExistForCurrentMonth > 0) || (itemPlan.status !== 'progress')) ? 0.5 : 1
                                            }}
                                        >

                                            <Typography variant='h5' color='primary' sx={{mb: 2}}>
                                                { readabilityNumber(itemPlan.amount) } <Typography variant='caption'>FCFA</Typography>
                                            </Typography>

                                            <a href={"/ctr/users/" + user.user_id + "/"} target='_blank' className='link' >
                                                <Typography sx={{mb: 0.5, color: 'blue'}}>
                                                    { user.lastName + ' ' + user.firstName }
                                                </Typography>
                                            </a>

                                            <Typography 
                                                fontWeight='bold'
                                                onClick={() => copyClipboard(itemPlan.planID, "Copie !")}
                                                sx={{cursor: 'pointer', mb: 0.5}}
                                            >
                                                { 'ID : ' + itemPlan.planID }
                                            </Typography>

                                            <Typography sx={{mb: 0.5}}>
                                                Statut : <InvestPlanStatusBadge status={itemPlan.status} />
                                            </Typography>

                                            <Typography sx={{mb: 0.5}}>
                                                Paimments ce mois : <b style={{color: itemPlan.paymentExistForCurrentMonth > 0 ? 'red' : ''}}>{ itemPlan.paymentExistForCurrentMonth } paiements ce mois</b>
                                            </Typography>

                                            <Typography sx={{mb: 0.5}}>
                                                Nombre mois : <b>{ itemPlan.num_months }</b>
                                            </Typography>

                                            <Typography sx={{mb: 0.5}}>
                                                Taux t'intérêt : <b>{ itemPlan.interest_rate + '%' }</b>
                                            </Typography>

                                            <Typography sx={{mb: 2}}>
                                                Debut validité : <b>{ itemPlan.month + ' / ' + itemPlan.year }</b>
                                            </Typography>
                                                
                                            <Link to={'/ctr/liquidity/invest-plans/' + itemPlan.planID + '/'} className='link'>
                                                <Button
                                                    sx={{textTransform: 'none'}}
                                                >
                                                    Accéder au plan
                                                </Button>
                                            </Link>
                                        </Box>
                                    )
                                })
                            }

                            <div className="text-center">

                                {
                                    isloading

                                    ? 
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="outlined"
                                        >
                                            Chrargement
                                        </LoadingButton>
                                    :
                                        !ended &&
                                        <Button variant='contained' color='inherit' onClick={() => getInvestPlans()}>
                                            Afficher plus
                                        </Button>
                                }

                            </div>

                        </>
                    }
                />

                <Route 
                    path='/:investPlanID'
                    element={<ItemInvestPlan {...props} />}
                />

                <Route 
                    path='/:investPlanID/payments'
                    element={<Payments {...props} />}
                />
            </Routes>

        </Box>
    )
}
