import { useState, useEffect } from 'react';
import { basicProps } from '../../../../utils/interfaces'
import { Box, Typography } from '@mui/material'
import SendMessage from './SendMessage'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import { Button, Divider } from '@mui/material';

interface Message {
    title: string,
    url: string,
    message: string,
    btnText: string
}

export default function PushMessages(props: basicProps) {

    const [pushMessages, setPushMessages] = useState<Message[]>([]);
    const [numPushSubscriptions, setNumPushSubscriptions] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getPushMessages()
    }, [queryValue])

    const getPushMessages = () => {

        setIsLoading(true);

        axiosApi.post("ctr/communication/push-messages/get-mesages.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newpushMessagesArray = pushMessages.concat(response.data.pushMessages);
                setPushMessages(newpushMessagesArray);
                setNumPushSubscriptions(response.data.numPushSubscriptions);
                setOffset(offset + 5);

                if(response.data.pushMessages.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }


    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 500,
                margin: 'auto', 
            }}
        >
            
            <Typography variant='h5' fontWeight='bold' textAlign='center' sx={{mb: 2}}>
                Messages Push
            </Typography>

            <Typography textAlign='center' variant='h6'>
                <b>{ numPushSubscriptions }</b> <br />
                comptes inscrits
            </Typography>

            <br /><br />

            <SendMessage {...props} />

            <br /><br /><br />


            {
                pushMessages.map((message, i) => {
                    return (
                        <Box key={i}>
                            
                            <Typography fontWeight={'bold'}>
                                { message.title }
                            </Typography>

                            <Typography>
                                { message.message }
                            </Typography>

                            <Typography variant='caption'>
                                { message.url }
                            </Typography>

                            {
                                (i !== (pushMessages.length - 1)) &&
                                <Divider sx={{mt: 3, mb: 3}}/>
                            }

                        </Box>
                    )
                })
            }

            

            <br />
    
            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getPushMessages()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
