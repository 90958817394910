import { useEffect, useState } from 'react';
import { TextField, Box, Typography, Chip } from '@mui/material';
import { basicProps, currencyType, networkType, paymentMethodType } from '../../utils/interfaces';
import { readabilityNumber } from '../../functions/basicFunctions';
import InfoPopup from '../../components/InfoPopup';
import InfoIcon from '@mui/icons-material/Info';
import axiosApi from '../../functions/axiosApi';

interface myProps extends basicProps {
    action: string,
    cryptoQuantity: number | string,
    fiatQuantity: number | string;
    fiatTaxEstimation: number;
    selectedCrypto: currencyType | undefined | null,
    selectedNetwork: networkType | undefined | null,
    selectedPaymentMethod: paymentMethodType,
    handleCryptoQuantityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleFiatQuantityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Quantity(props: myProps) {

    const basicDatas = props.basicDatas;

    const selectedCrypto = props.selectedCrypto;
    const selectedNetwork = props.selectedNetwork;
    const selectedPaymentMethod = props.selectedPaymentMethod;
    const action = props.action;
    const cryptoQuantity = props.cryptoQuantity;
    const fiatQuantity = props.fiatQuantity;
    const fiatTaxEstimation = props.fiatTaxEstimation;

    const [usdtPurchasePrice, setUsdtPurchasePrice] = useState(0);
    const [usdtSalePrice, setUsdtSalePrice] = useState(0);
    const [dollarValue, setDollarValue] = useState(0);

    useEffect(() => {
        
        axiosApi.post('ctr/currencies/get-usdtxaf-price.php')
        .then(response => {

            if(response.data.status === 'success') {
                setUsdtPurchasePrice(parseFloat(response.data.usdtInfos.purchasePrice));
                setUsdtSalePrice(parseFloat(response.data.usdtInfos.salePrice));
            }
        })

    }, [])

    useEffect(() => {

        let v;
        
        if(action === 'buy') {
            v = parseFloat(fiatQuantity as string) / usdtSalePrice;
        }

        else {
            v = parseFloat(fiatQuantity as string) / usdtPurchasePrice;  
        }

        if(fiatQuantity) {
            v = v.toFixed(2);
            v = parseFloat(v);
            setDollarValue(v)
        }

    }, [fiatQuantity])    
    

    return (
        <Box>

            <Typography sx={{mb: 1}}>
                {
                    selectedCrypto 
                    ?
                        action === 'buy' ? "Quelle quantité " + selectedCrypto.symbol + " souhaitez-vous recevoir?" : "Quelle quantité " + selectedCrypto.symbol + " souhaitez-vous vendre?"
                    :
                    ''
                }
            </Typography>

            <Typography textAlign={'right'} sx={{mb:-2}}>
                <Typography variant='caption'>
                    { "≈ " + dollarValue + '$'}
                </Typography>
            </Typography>

            <TextField
                type="number"
                inputProps={{step: 'any'}}
                margin="normal"
                value={cryptoQuantity}
                onChange={props.handleCryptoQuantityChange}
                id="cryptoQte"
                label={action === 'sell' ? "Quantité " + selectedCrypto?.symbol + " à envoyer" : "Quantité " + selectedCrypto?.symbol + " à recevoir"}
                name="cryptoQte"
                autoComplete="cryptoQte"
                required
                fullWidth
                sx={{mb: 1}}
            />

            <Typography sx={{ mb: 2 }}>

                {
                    (selectedNetwork?.id && selectedCrypto?.allowed_networks && (selectedCrypto.allowed_networks.length > 0)) &&
                    
                    <>

                        <Chip label={
                                action === 'buy'
                                    ?  "Min : " + readabilityNumber(selectedCrypto?.allowed_networks.filter(allowed_network => selectedNetwork?.symbol === allowed_network.network)[0]?.minBuyPerTransaction) + " " + selectedCrypto?.symbol
                                    :   "Min : " + readabilityNumber(selectedCrypto?.allowed_networks.filter(allowed_network => selectedNetwork?.symbol === allowed_network.network)[0]?.minSellPerTransaction) + " " + selectedCrypto?.symbol
                            } 
                                sx={{
                                    backgroundColor: "rgba(167, 202, 237, 0.2)",
                                    color: "var(--site-color)"
                                }} 
                                variant="filled" 
                                size="small" />

                        <Chip label={
                                action === 'buy'
                                    ?  "Max : " + readabilityNumber(selectedCrypto?.allowed_networks.filter(allowed_network => selectedNetwork?.symbol === allowed_network.network)[0].maxBuyPerTransaction) + " " + selectedCrypto?.symbol
                                    :   "Max : " + readabilityNumber(selectedCrypto?.allowed_networks.filter(allowed_network => selectedNetwork?.symbol === allowed_network.network)[0].maxSellPerTransaction) + " " + selectedCrypto?.symbol
                            }
                                sx={{
                                    backgroundColor: "rgba(167, 202, 237, 0.2)",
                                    color: "var(--site-color)",
                                    ml: 2
                                }} 
                                variant="filled" 
                                size="small" />
                    </>
                }
            </Typography>

            <TextField
                type="number"
                inputProps={{step: 'any'}}
                margin="normal"
                value={fiatQuantity}
                onChange={props.handleFiatQuantityChange}
                id="fiatQte"
                label={action === 'sell' ? "Quantité FCFA à recevoir" : "Quantité FCFA à payer"}
                name="fiatQte"
                autoComplete="fiatQte"
                required
                fullWidth
            />

            <Typography sx={{ mb: 2 }}>

                {
                    (selectedPaymentMethod) &&
                    <Chip 
                        label={action === 'buy' ? "Max : " + readabilityNumber(selectedPaymentMethod.maxBuyAmount) : "Max : " + readabilityNumber(selectedPaymentMethod.maxSellAmount) } 
                        sx={{
                            backgroundColor: "rgba(167, 202, 237, 0.2)",
                            color: "var(--site-color)"
                        }} 
                        variant="filled" 
                        size="small" 
                    />
                }
            </Typography>

            {
                (
                    selectedPaymentMethod && 
                    (   ((selectedPaymentMethod.percentagePurchaseTax != 0) && (action === 'buy')) 
                            || 
                        ((selectedPaymentMethod.percentageSaleTax != 0) && (action === 'sell'))
                    )
                ) &&
                <div style={{textAlign:'right'}}>
                    
                    <span style={{fontSize:'small'}}> 
                        Frais estimé : <b>{fiatTaxEstimation + " FCFA"}</b>
                    </span>

                    <InfoPopup basicDatas={basicDatas}
                                title="Pourquoi ces frais?"
                                text={
                                    <div>
                                        {
                                            action === 'buy' 
                                            ? selectedPaymentMethod.purchaseTaxReason 
                                            : selectedPaymentMethod.saleTaxReason
                                        }
                                    </div>
                                }>
                        <span   className='small text-muted'
                                style={{
                                    marginLeft: '20px',
                                    fontStyle: 'oblique',
                                    cursor: 'pointer',
                                    float: 'right'
                                }}> 
                                    <InfoIcon sx={{mr:0.5, fontSize:'16px'}} color='info' />
                                    En savoir plus
                        </span>
                    </InfoPopup>

                </div>
            }

        </Box>
    )
}
