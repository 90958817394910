import { useState } from 'react';
import { Box, Button, List, Typography } from '@mui/material'
import { useEffect } from 'react'
import QueryString from 'qs';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { basicProps, newsLetterType } from '../../utils/interfaces';
import axiosApi from '../../functions/axiosApi';
import PreviewNews from '../../components/PreviewNews';
import TelegramIcon from '@mui/icons-material/Telegram';
import { MetaTags } from 'react-meta-tags';


export default function News(props: basicProps) {
    
    const basicDatas = props.basicDatas;

    const [news, setNews] = useState<newsLetterType[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);

    useEffect(() => {
        getNews();
    }, [])

    const getNews = () => {

        setIsLoading(true);

        axiosApi.post("ctr/communication/news-letter/web-news/get-news.php", QueryString.stringify({
            offset, limit: 3
        }))
        .then(response => {

            if(response.data.status === 'success'){
                const newDataArray = news.concat(response.data.news);
                setNews(newDataArray);
                setIsLoading(false);
                setOffset(offset + 3);

                if(response.data.news.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <div style={{maxWidth: '500px', margin: 'auto'}}>

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Nouvelles</title>
                <meta name="description" content="CRYPTO-FIAT | Nouvelles" />
                <meta property="og:title" content="CRYPTO-FIAT | Nouvelles" />
            </MetaTags>

            <br /> <br />
            
            <Typography variant='h5' sx={{textAlign: 'center'}}>
                Nouvelles
            </Typography>

            <br /><br />

            <Box sx={{
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                mb: 2,
                boxShadow: 2,
                borderRadius: 2
                }}>

            <div style={{width: '50%', fontSize: 'small', paddingRight: '5px'}}>
                Abonnez-vous au canal Telegram pour ne manquer aucune nouvelle.
            </div> 

            <div style={{width: '50%'}}>
                <a href='https://t.me/cryptofiatex' target='_blank'>
                <Button variant='contained' startIcon={<TelegramIcon />} fullWidth>
                    S'abonner
                </Button>
                </a>
            </div>

            </Box>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

                {
                    news.map((post) => {
                        return (
                            
                            <Box 
                                key={post.postID}
                                sx={{
                                    boxShadow: 2,
                                    mb: 4,
                                    borderRadius: 2
                                }}>
                                <PreviewNews basicDatas={basicDatas} post={post} />
                            </Box>
                        )
                    })
                }

            </List>

                


            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getNews()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </div>
    )
}
