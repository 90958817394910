import { useEffect, useState } from 'react'
import axiosApi from '../../functions/axiosApi';
import { cf_pay_info, copyClipboard, isPC } from '../../functions/basicFunctions';
import { basicProps, cftPartnerType } from '../../utils/interfaces';
import { 
    Typography, Box, Avatar, 
    List, Alert, AlertTitle, Button, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import QueryString from 'qs';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function CftPartners(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [countries, setCountries] = useState<any>([])
    const [partners, setPartners] = useState<cftPartnerType[]>([]);
    const [status, setStatus] = useState('all');
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        
        axiosApi.get('https://restcountries.com/v2/region/Africa/?fields=translations,flag')
        .then(res => {
            setCountries(res.data);
        })

    }, [])

    useEffect(() => {

        getPartners();

    }, [queryValue])

    const getCountryFlag = (country) => {
        var matchedCountries = countries.filter(itemCountry => itemCountry.translations.fr === country);
        if(matchedCountries.length !== 0) {
            return matchedCountries[0].flag;
        }
        return '';
    }

    const getPartners = () => {

        setIsLoading(false);

        axiosApi.post("ctr/team-manager/cft-partners/get-partners-front.php", QueryString.stringify({offset, limit:3, queryValue}))
        .then(response => {
            if(response.data.status === 'success'){

                const newPartnersArray = partners.concat(response.data.partners);
                setPartners(newPartnersArray)
                setOffset(offset + 3);
                
                if(response.data.partners.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: 'auto'
            }}>
            
            <Typography variant='h5' className='text-center' sx={{mb: 5, mt: 3}}>
                Liste officielle des partenaires  <br />
                { cf_pay_info.name }
            </Typography>

            <Typography sx={{mb: 3, mt: 3, textAlign: 'center'}}>
                <Typography variant='caption'>
                    Pour échanger vos CFT contre FCFA, veuillez vérifier s'il y a un vendeur dans votre pays et contactez-le.
                </Typography>
            </Typography>

            <Alert severity="error" sx={{mb:3}}>
                <AlertTitle sx={{fontWeight:'bold'}}>Important</AlertTitle>
                Seuls les vendeurs sur cette liste sont reconnus par { basicDatas.appName }.
                
                <div className='small'>
                    Si quelqu'un prétend être en partenariat avec nous mais ne figure pas sur cette liste, alors il veut probablement vous arnaquer.
                </div>
                
            </Alert>

            <Typography sx={{mb: 5, fontSize: 'small'}}>
                Vous souhaitez devenir partenaire et réprésenter votre pays, <Link to='/cft-token/become-partner/'>faites votre demande ici</Link>
            </Typography>

            <FormControl 
                    fullWidth 
                    sx={{
                        mb: 3
                    }}>

                <InputLabel id="select_country_label">Pays du partenaire</InputLabel>
                
                <Select
                    labelId="select_country_label"
                    name='newCountry'
                    label="Choisir le pays"
                    fullWidth
                    margin="dense"
                    required
                    value={queryValue}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setPartners([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    >

                    {
                        countries.map((country, index) => {

                            return (
                                <MenuItem key={index} value={ country.translations.fr}>
                                    <Avatar 
                                        src={country.flag} 
                                        sx={{
                                            width:'30px', 
                                            height:'20px', 
                                            display:'inline-block', 
                                            mr: 2, 
                                            verticalAlign:'middle',
                                            borderRadius: 0,
                                            boxShadow: 1
                                        }} />
                                    <span>{ country.translations .fr}</span>
                                </MenuItem>
                            )

                        })
                    }
    
                </Select>        

            </FormControl>

            {
                partners.length === 0 &&
                <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: 'small'
                        }}>
                    Aucun partenaire trouvé !
                </Typography>
            }

            <List sx={{ width: '100%'}}>

                {
                    partners.map(partner => {

                        const userInfos = partner.userInfos;

                        return (
                            <Box
                                sx={{
                                    p: 2,
                                    boxShadow: 2,
                                    borderRadius: 2,
                                    bgcolor: '#F7F7F7',
                                    mb: 5
                                }}
                            >

                                <table>

                                    <tbody>

                                        <td>

                                            <Avatar 
                                                alt="Remy Sharp" 
                                                src={ userInfos.avatarUrl } 
                                                sx={{
                                                    mr: 2,
                                                    width: 60,
                                                    height: 60
                                                }}
                                            />
                                        </td>

                                        <td>
                                            <Typography fontWeight='bold' variant='h6'>
                                                { partner.userInfos.firstName + " " +  partner.userInfos.lastName}
                                            </Typography>

                                            <Typography
                                                sx={{ 
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Avatar 
                                                    src={getCountryFlag(partner.country)}
                                                    sx={{
                                                        width: 25,
                                                        height: 15,
                                                        mr: 1,
                                                        borderRadius: 1
                                                    }}
                                                    />
                                                
                                                <Typography sx={{width: '100%'}}>
                                                    { partner.country }
                                                </Typography>

                                            </Typography>
                                        </td>

                                    </tbody>

                                </table>

                                <Box
                                    sx={{
                                        
                                    }}
                                >

                                    <Typography
                                        sx={{ 
                                            p: 1,
                                            borderRadius: 1,
                                            mt: 2
                                        }}
                                    >

                                        {
                                            partner.phones.map((phoneNumber, i) => {
                                                return (
                                                    <Typography 
                                                        key={i}
                                                        sx={{
                                                            bgcolor: '#fff',
                                                            display: 'inline-block',
                                                            p: 1.5,
                                                            borderRadius: 1,
                                                            mb: (i != (partner.phones.length - 1)) ? 2 : 0
                                                        }}
                                                    >
                                                        <Typography sx={{fontSize: 14, display: 'inline'}}>{ phoneNumber }</Typography>

                                                        <a href={"https://wa.me/" + phoneNumber.replaceAll(' ', '') + "?text=Bonjour, je souhaite échanger les CFT de CRYPTO-FIAT."} target='_blank' className='link'>
                                                            <WhatsAppIcon sx={{color:'#128C7E', ml: 3}} titleAccess='Écrire sur WhatsApp' />
                                                        </a>

                                                        <a href={"tel:" + phoneNumber} target='_blank' className='link'>
                                                            <CallIcon sx={{ml: 3, color:'GrayText'}} titleAccess='Appeler' />
                                                        </a>

                                                        <ContentCopyIcon 
                                                            fontSize='small'
                                                            sx={{
                                                                ml: 3, 
                                                                color:'GrayText', 
                                                                cursor: 'pointer'
                                                            }} 
                                                            titleAccess='Copier le numéro' 
                                                            onClick={() => copyClipboard(phoneNumber.replaceAll(' ', ''), "Le numéro a été copié !")}
                                                        />
                                                    </Typography>
                                                )
                                            })
                                        }
                                    </Typography>

                                </Box>

                                    
                            </Box>
                        )
                    })
                }
                
            </List>

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getPartners()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
