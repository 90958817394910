import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom'
import { User, basicProps } from '../../utils/interfaces'
import { Alert, AlertTitle, Avatar, Box, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import { MetaTags } from 'react-meta-tags';
import { publicPath } from '../../functions/basicFunctions';
import UserIsNotConnectedMessage from '../../components/UserIsNotConnectedMessage';
import SendDocuments from './SendDocuments';
import KycStatusBox from './KycStatusBox';
import MessageIcon from '@mui/icons-material/Message';
import axiosApi from '../../functions/axiosApi';

export default function Kyc(props: basicProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser as User;

    const [adminMessage, setAdminMessage] = useState('');

    useEffect(() => {

        axiosApi.post("ctr/kyc/get-kyc-infos.php")
        .then(response => {
            
            if(response.data.status === 'success') {

                basicDatas.setAuthUser(response.data.auth_user);

                const kyc_progress_info = response.data.kyc_infos;

                if(kyc_progress_info) {
                    setAdminMessage(kyc_progress_info.message)
                }
                
            }
        })

    }, [])

    if(!basicDatas.isLoggedIn) return (
        <div className='text-center' style={{maxWidth:'500px', margin: 'auto', marginTop:'60px'}}>
            <MetaTags id="create-order">
                <title>Identification</title>
                <meta name="description" content={basicDatas.appName + " - Identification"} />
                <meta property="og:title" content={basicDatas.appName + " - Identification"} />
            </MetaTags>
            <UserIsNotConnectedMessage message="Pour faire vérifier votre compte, veuillez vous connecter afin de profiter pleinement de nos services." />
        </div>
    )

    return (
        <div>
            
            <Routes>

                <Route path='/' element={
                    <Box
                        sx={{
                            maxWidth: 600,
                            margin: 'auto'
                        }}>

                        <MetaTags id="about">
                            <title>Identification</title>
                            <meta name="description" content={basicDatas.appName + " - Identification"} />
                            <meta property="og:title" content={basicDatas.appName + " - Identification"} />
                        </MetaTags>

                        <div className="text-center"
                                style={{
                                    marginTop: '0px',
                                    marginBottom: '30px'
                                }}>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>

                                <img 
                                    src={publicPath + "images/kyc_banner.png"} 
                                    alt="CRYPTO-FIAT"
                                    style={{
                                        width: '100%',
                                        borderBottomLeftRadius: '5px',
                                        borderBottomRightRadius: '5px'
                                    }} />

                            </div>

                        </div>

                        <Typography variant='h5' sx={{textAlign: 'center', mb: 7, fontWeight: 'bold'}}>
                            Identification
                        </Typography>

                        {
                            ((authUser.vip_level === 'vip0') &&  adminMessage && (adminMessage.length > 0)) &&
                            <Alert icon={ <MessageIcon /> } severity="error" sx={{mb: 2, textAlign:'left', mt:2, boxShadow: 2}} {...props}>
                                <AlertTitle sx={{fontWeight: 'bold'}}>Raison d'échec </AlertTitle>
                                <div dangerouslySetInnerHTML={{__html: adminMessage}} />
                            </Alert>
                        }

                        <KycStatusBox basicDatas={basicDatas} vip_level={authUser.vip_level} />

                        {
                            authUser.vip_level === 'vip0' &&
                            <>

                                <Typography variant='h5' sx={{mt: 7, mb: 3}}>
                                    Quelques étapes simples pour vérifier votre compte !
                                </Typography>

                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    
                                    <ListItem alignItems="flex-start">
                                        
                                        <ListItemAvatar>
                                            <Avatar 
                                                alt='1' 
                                                src="/static/images/avatar/3.jpg" 
                                                sx={{
                                                    bgcolor: 'var(--site-color)',
                                                    width: 30,
                                                    height: 30
                                                }}
                                            />
                                        </ListItemAvatar>

                                        <ListItemText
                                        primary="Choisissez le type de document"
                                        secondary={
                                            <span>
                                                Veuillez spécifier le type de document avec lequel vous souhaitez vous faire identifier. Nous acceptons actuellement la CNI (Carte Nationale d'Identité) ou son récépissé en cours de validité, le permis de conduire et le passeport.
                                            </span>
                                        }
                                        />
                                    </ListItem>

                                    <Divider variant="inset" component="li" />

                                    <ListItem alignItems="flex-start">
                                        
                                        <ListItemAvatar>
                                            <Avatar 
                                                alt='2' 
                                                src="/static/images/avatar/3.jpg" 
                                                sx={{
                                                    bgcolor: 'var(--site-color)',
                                                    width: 30,
                                                    height: 30
                                                }}
                                            />
                                        </ListItemAvatar>

                                        <ListItemText
                                        primary="Entrez l'ID de votre document"
                                        secondary={
                                            <span>
                                                Chaque document d'identité comporte un identifiant. Veuillez renseigner celui qui se trouve sur le vôtre.
                                            </span>
                                        }
                                        />
                                    </ListItem>

                                    <Divider variant="inset" component="li" />

                                    <ListItem alignItems="flex-start">
                                        
                                        <ListItemAvatar>
                                            <Avatar 
                                                alt='3' 
                                                src="/static/images/avatar/3.jpg" 
                                                sx={{
                                                    bgcolor: 'var(--site-color)',
                                                    width: 30,
                                                    height: 30
                                                }}
                                            />
                                        </ListItemAvatar>

                                        <ListItemText
                                        primary="Entrez la date d'expriration"
                                        secondary={
                                            <span>
                                                Il s'agit de la date à laquelle votre document ne sera plus valide. Cette date est indiquée sur votre document d'identité.
                                            </span>
                                        }
                                        />
                                    </ListItem>

                                    <Divider variant="inset" component="li" />

                                    <ListItem alignItems="flex-start">
                                        
                                        <ListItemAvatar>
                                            <Avatar 
                                                alt='4' 
                                                src="/static/images/avatar/3.jpg" 
                                                sx={{
                                                    bgcolor: 'var(--site-color)',
                                                    width: 30,
                                                    height: 30
                                                }}
                                            />
                                        </ListItemAvatar>

                                        <ListItemText
                                        primary="Prenez une photo du recto"
                                        secondary={
                                            <span>
                                                Veuillez télécharger une photo claire de la face principale de votre document. Les documents scannés ne sont pas acceptés. Assurez-vous que toutes les informations sont bien visibles.
                                            </span>
                                        }
                                        />
                                    </ListItem>

                                    <Divider variant="inset" component="li" />

                                    <ListItem alignItems="flex-start">
                                        
                                        <ListItemAvatar>
                                            <Avatar 
                                                alt='5' 
                                                src="/static/images/avatar/3.jpg" 
                                                sx={{
                                                    bgcolor: 'var(--site-color)',
                                                    width: 30,
                                                    height: 30
                                                }}
                                            />
                                        </ListItemAvatar>

                                        <ListItemText
                                        primary="Prenez une photo du verso"
                                        secondary={
                                            <span>
                                                Veuillez télécharger une photo claire du verso de votre document. Les documents scannés ne sont pas acceptés. Assurez-vous que toutes les informations sont bien visibles.
                                            </span>
                                        }
                                        />
                                    </ListItem>

                                    <Divider variant="inset" component="li" />

                                    <ListItem alignItems="flex-start">
                                        
                                        <ListItemAvatar>
                                            <Avatar 
                                                alt='6' 
                                                src="/static/images/avatar/3.jpg" 
                                                sx={{
                                                    bgcolor: 'var(--site-color)',
                                                    width: 30,
                                                    height: 30
                                                }}
                                            />
                                        </ListItemAvatar>

                                        <ListItemText
                                        primary="Prenez un selfie de vous avec document en main"
                                        secondary={
                                            <span>
                                                Prenez un selfie de vous en tenant votre document en main. Assurez-vous que votre visage et votre document sont clairement visibles.
                                            </span>
                                        }
                                        />
                                    </ListItem>

                                </List>

                                <Typography sx={{textAlign: 'center', mt: 3}}>
                                    <Link to="/kyc/send-documents/" className='link'>
                                        <Button 
                                            variant='contained'
                                            sx={{backgroundImage: 'linear-gradient(#277ED5, #A5CFEF)'}}
                                            >
                                            Vérifier maintenant
                                        </Button>
                                    </Link>
                                </Typography>

                            </>
                        }

                    </Box>
                } />

                <Route path='/send-documents' element={<SendDocuments {...props} />} />
            </Routes>

        </div>
    )
}
