import { useState } from 'react';
import { 
    FormControl, Avatar, TextField, InputLabel, MenuItem, Select,
    TextareaAutosize, Chip, SelectChangeEvent, Typography, Button, Dialog, DialogTitle, DialogContent, Box, DialogActions
} from '@mui/material';
import { basicProps, currencyType, paymentMethodType, saveAddressType } from '../../utils/interfaces';
import { cf_pay_info, cftTokenInfo } from '../../functions/basicFunctions';
import { MuiTelInput } from 'mui-tel-input';
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Link } from 'react-router-dom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

interface myProps extends basicProps {
    action: string,
    paymentMethod: string,
    paymentMethods: paymentMethodType[],
    selectedCrypto: currencyType | undefined | null,
    selectedPaymentMethod: paymentMethodType,
    phoneNumber: string,
    paymentMethodOwnerName: string,
    suggestedNames: {accountOwnerName: string, paymentMethodNumber: string}[];
    cftReceptionAddress: string,
    setCftReceptionAddress: (value: string) => void,
    setPhoneNumber: (value: string) => void;
    setPaymentMethodOwnerName: (value: string) => void;
    handlePaymentMethodChange: (e: SelectChangeEvent) => void
}

export default function PaymentMethod(props: myProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const action = props.action;
    const paymentMethod = props.paymentMethod;
    const paymentMethods = props.paymentMethods;
    const selectedCrypto = props.selectedCrypto;
    const selectedPaymentMethod = props.selectedPaymentMethod;
    const phoneNumber = props.phoneNumber;
    const paymentMethodOwnerName = props.paymentMethodOwnerName;
    const suggestedNames = props.suggestedNames;

    const [open, setOpen] = useState(false);
    const [savedAddresses, setSavedAddresses] = useState<saveAddressType[]>([])
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const showSavedAddresses = () => {
        handleClickOpen();

        axiosApi.post("settings/saved_addresses/get-mobile-money-addresses.php", QueryString.stringify({network: selectedPaymentMethod?.symbol}))
        .then(response => {
            if(response.data.status === 'success') {
                setSavedAddresses(response.data.addresses)
            }
        })
    }


    const selectSavedAddress = (address: saveAddressType) => {
        props.setPhoneNumber(address.phoneNumber as string);
        props.setPaymentMethodOwnerName(address.phoneOwnerName as string)
        handleClose();
    }

    return (
        <div>

            <Typography sx={{mb: 2}}>
                {
                    action === 'buy' ? "Par quel moyen souhaitez-vous payer?" : "Par quel moyen souhaitez-vous recevoir vos fonds?"
                }
            </Typography>
            
            <FormControl fullWidth>

                <InputLabel id="select_payment_method_label">Moyen de paiement</InputLabel>

                <Select
                    labelId="select_payment_method_label"
                    id="demo-simple-select"
                    name='paymentMethod'
                    label="Moyen de paiement"
                    value={paymentMethod}
                    onChange={props.handlePaymentMethodChange}
                    fullWidth
                    required
                    >

                    {
                        paymentMethods.map((method: paymentMethodType, index) => {

                            return (
                                <MenuItem key={index} 
                                            value={method.symbol} 
                                            disabled={
                                                ((action === 'buy') && (method.allowForBuy != 1)) 
                                                || 
                                                ((action === 'sell') && (method.allowForSell != 1))
                                                ||
                                                ((selectedCrypto?.symbol === cftTokenInfo.symbol) && (method.symbol === cftTokenInfo.symbol))
                                            }
                                            > 
                                    <Avatar src={method.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                    <span>{method.symbol + " (" + method.name + ")"}</span>
                                </MenuItem>
                            )
                        })
                    }

                </Select>

                {
                    (selectedPaymentMethod && (selectedPaymentMethod.symbol !== cftTokenInfo.symbol) && (selectedPaymentMethod.symbol !== cf_pay_info.symbol)) &&
                    <Typography sx={{textAlign: 'left', mt: 2}}>
                        <Button startIcon={<StickyNote2Icon />} onClick={showSavedAddresses}>
                            Carnet adresses
                        </Button>
                    </Typography>
                }

                <Dialog open={open} onClose={handleClose}>

                    <DialogTitle>Carnet d'adresses</DialogTitle>

                    <DialogContent sx={{minWidth: 300}}>

                        <Typography sx={{mb: 3}}>
                            <Typography variant='caption'>
                                Choisir parmi vos adresses <b>{ selectedPaymentMethod?.name }</b> enregistrées
                            </Typography>
                        </Typography>

                        {
                            savedAddresses.length === 0 &&
                            <Box
                                sx={{textAlign: 'center'}}>
                                Vous n'avez aucune adresse { selectedPaymentMethod?.name } enregistrée !
                                <br /><br />
                                <Link to='/settings/addresses/'>
                                    <Button variant="outlined" startIcon={<NoteAddIcon />}>
                                        Enregistrer une adresse
                                    </Button>
                                </Link>
                            </Box>
                        }
                        
                        {
                            savedAddresses.map(itemAddress => {
                                return (
                                    <Box
                                        key={itemAddress.id}
                                        onClick={() => selectSavedAddress(itemAddress)}
                                        sx={{
                                            pb: 3,
                                            mb: 3,
                                            boxShadow: 2,
                                            p: 2,
                                            borderRadius: 1,
                                            cursor: 'pointer'
                                        }}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            {itemAddress.walletName}
                                        </Typography>

                                        <Typography
                                            color='GrayText'>
                                            { itemAddress.network }
                                        </Typography>
                                    
                                        <Typography >
                                        
                                            {
                                                itemAddress._system === 'mobile-money' && 
                                                <>
                                                    { itemAddress.phoneNumber } (<Typography variant='caption'> { itemAddress.phoneOwnerName } </Typography>)
                                                </>
                                            }

                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>

                </Dialog>

            </FormControl>

            {
                (selectedPaymentMethod && (selectedPaymentMethod.symbol !== 'CF_PAY') && (selectedPaymentMethod.symbol !== cftTokenInfo.symbol)) &&

                <>
                    <MuiTelInput 
                        label={"Numéro " + selectedPaymentMethod.name}
                        margin="normal"
                        id="paymentMethodNumber"
                        value={phoneNumber} 
                        onChange={(value) => props.setPhoneNumber(value)}
                        name="paymentMethodNumber"
                        autoComplete="paymentMethodNumber"
                        placeholder={"Exemple : +237 " + selectedPaymentMethod.address}
                        fullWidth
                        required 
                        sx={{mt: 3}}
                    />

                    <TextField
                        value={paymentMethodOwnerName}
                        onChange={(e) => props.setPaymentMethodOwnerName(e.target.value)}
                        id="paymentMethodOwnerName"
                        label={"Nom associé à votre compte " + selectedPaymentMethod.name}
                        name="paymentMethodOwnerName"
                        autoComplete="paymentMethodOwnerName"
                        placeholder={"Exemple : " + selectedPaymentMethod.accountOwnerName}
                        fullWidth
                        required
                        sx={{mt:2}}
                        />

                    {
                        ((authUser.lastName || authUser.firstName) && (!paymentMethodOwnerName || paymentMethodOwnerName == '')) &&
                        <Chip variant='outlined' 
                                label={authUser.firstName + " " + authUser.lastName} 
                                sx={{fontSize:'small', mt:1}} 
                                onClick={() => props.setPaymentMethodOwnerName("" + authUser.lastName + " " + authUser.firstName)}
                                />
                    }

                    {
                        suggestedNames.map((suggestedName, i) => {


                            if  (   (suggestedName.accountOwnerName !== authUser.lastName) &&
                                    ((phoneNumber === suggestedName.paymentMethodNumber) || (phoneNumber === '')) &&
                                    (!paymentMethodOwnerName || paymentMethodOwnerName == '')
                                ) 
                            return (
                                <Chip variant='outlined' 
                                    label={ suggestedName.accountOwnerName } 
                                    sx={{fontSize:'small', mt:1, ml:2}} 
                                    key={i}
                                    onClick={ () => props.setPaymentMethodOwnerName("" + suggestedName.accountOwnerName) }
                                    />
                            )

                            return <span></span>
                        })
                    }
                    
                    
                </>
            }

            {
                (selectedPaymentMethod && (selectedPaymentMethod.symbol === cftTokenInfo.symbol) && (action === 'sell')) &&
                <>
                    <label htmlFor="address" className='d-block mb-2 mt-3'>
                        Adresse de réception <b>{ cftTokenInfo.symbol }</b> <span className='small' style={{fontStyle:'oblique'}}>Sur le réseau <b>BEP20</b></span>
                    </label> 

                    <TextareaAutosize
                        aria-label={"Adresse de réception " + cftTokenInfo.symbol + " tokens"}
                        name='cftReceptionAddress'
                        id="address"
                        value={props.cftReceptionAddress}
                        onChange={(e) => props.setCftReceptionAddress(e.target.value)}
                        minRows={3}
                        required
                        placeholder={"Adresse de réception " + cftTokenInfo.symbol + " (" + cftTokenInfo.name + ") sur le réseau BEP20 (Binance Smart Chain)"}
                        style={{ 
                            width:'100%', 
                            padding:'10px', 
                            borderRadius:'5px', 
                            border: "1px solid #bbb"
                        }}
                        />
                </>
            }

        </div>
    )
}
