import { 
    FormControl, InputLabel, MenuItem, Select, Chip,
    Typography, Box
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoPopup from '../../components/InfoPopup';
import { basicProps, currencyType, networkType } from '../../utils/interfaces';

interface myProps extends basicProps {
    action: string,
    network: string,
    networks: networkType[],
    selectedNetwork: networkType | undefined;
    selectedCrypto: currencyType | undefined | null,
    handleNetworkChange: (network_symbol: string) => void
}

export default function SelectNetwork(props: myProps) { 

    const basicDatas = props.basicDatas;

    const action = props.action;
    const network = props.network;
    const networks = props.networks;
    const selectedNetwork = props.selectedNetwork;
    const selectedCrypto = props.selectedCrypto;

    return (

        <Box>

            <Typography sx={{mb: 2}}>
                Sur quel réseau {action === 'buy' ? "souhaitez-vous recevoir?" : "vous allez transférer?"} 
                <br />
                <Typography variant='caption' color='error'>
                    Si vous ne sélectionnez pas le bon réseau, vous risquez de perdre vos fonds.
                </Typography>
            </Typography>

            <FormControl fullWidth> 

                <InputLabel id="select_network_label">Réseau de transfert (blockchain)</InputLabel>

                <Select
                    labelId="select_network_label"
                    id="demo-simple-select"
                    name='network'
                    label="Réseau de transfert"
                    value={network}
                    onChange={(e) => props.handleNetworkChange(e.target.value)}
                    fullWidth
                    margin="dense"
                    required
                    >

                    {

                        selectedCrypto?.allowed_networks?.map((allowed_network, index) => {

                            var matchNetwork = networks.filter((itemNetwork) => itemNetwork.symbol === allowed_network.network);
                            const networkInfos = matchNetwork.length !== 0 ? matchNetwork[0] : null;

                            if(!networkInfos) return <MenuItem key={index} value={''}></MenuItem> 

                            let networkDisabled = ((action === 'buy') && (!allowed_network.allowForBuy || (networkInfos?.allowForBuy == 0))) || (action === 'sell') && (!allowed_network.allowForSell || (networkInfos.allowForSell == 0));

                            return (

                                <MenuItem   key={index} 
                                            value={networkInfos.symbol}
                                            disabled={networkDisabled}>

                                    {networkInfos.symbol + " (" + networkInfos.name + ")"} 

                                    {
                                        networkDisabled &&
                                        <Chip label="Suspendu" 
                                                    sx={{
                                                        backgroundColor: "#FDEDED",
                                                        color: "#E5593C",
                                                        ml: selectedNetwork?.symbol === networkInfos.symbol ? 0 : 2,
                                                        fontSize: '11px',
                                                        cursor: 'pointer'
                                                    }} 
                                                    variant="filled"
                                                    size='small' />
                                    }

                                    {
                                        ((action === 'buy') && !networkDisabled) &&

                                        <>

                                            {
                                                selectedNetwork?.symbol === networkInfos.symbol &&
                                                <br style={{marginBottom: '5px'}} />
                                            }

                                            <Chip 
                                                label={
                                                    selectedCrypto.feeInPercentage == 1
                                                    ?   "Frais : " + allowed_network.networkFee + "%"
                                                    :   "Frais : " + allowed_network.networkFee + " " + selectedCrypto.symbol
                                                } 
                                                sx={{
                                                    backgroundColor: "RGB(25, 118, 210, 0.1)",
                                                    color: "RGB(25, 118, 210)",
                                                    ml: selectedNetwork?.symbol === networkInfos.symbol ? 0 : 2,
                                                    fontSize: '11px',
                                                    cursor: 'pointer'
                                                }} 
                                                variant="filled"
                                                size='small' 
                                            />
                                        </>
                                    }

                                    {
                                        (!networkDisabled && allowed_network.congested) &&
                                        <Chip label="Congestionné" 
                                                    sx={{
                                                        backgroundColor: "#FFF4E5",
                                                        color: "#663C01",
                                                        ml: selectedNetwork?.symbol === networkInfos.symbol ? 0 : 2,
                                                        fontSize: '11px',
                                                        cursor: 'pointer'
                                                    }} 
                                                    variant="filled"
                                                    size='small' />
                                    }

                                </MenuItem>
                            )
                        }
                    )
                }

                </Select>

                <div>

                    <InfoPopup 
                        basicDatas={basicDatas}
                        title="Quel réseau choisir?"
                        text={
                            <div>
                                Le réseau c'est la blockchain sur laquelle le transfert sera effectué.

                                <div style={{fontSize: '13px', marginTop:'20px', textAlign:'right'}}>
                                    <a href="https://wa.me/+237650839431/?text=Bonjour, je ne sais pas quel réseau choisir pour ma transaction." target='_blank'>
                                        <WhatsAppIcon fontSize='small' /> J'ai besoin d'aide
                                    </a>
                                </div>
                                
                                <div className='text-danger' style={{fontSize: '13px', marginTop:'20px'}}>
                                    <b>Important : </b> Si vous ne choissez pas le bon réseau, vous pourrez perdre vos fonds. 
                                </div>
                            </div>
                        }
                    >

                    <span   className='small text-muted'
                        style={{
                            marginLeft: '20px',
                            fontStyle: 'oblique',
                            cursor: 'pointer',
                            float: 'right',
                            display: 'inline-block',
                            marginTop: '5px'
                        }}> 
                            <InfoIcon sx={{mr: 0.5, fontSize:'16px'}} color='info' />
                            Je ne sais pas quel réseau choisir
                    </span>
                    </InfoPopup>

                </div>

            </FormControl>
        </Box>
    )
}
