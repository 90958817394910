import { useState, useEffect } from 'react'
import { User, basicProps} from '../../../../utils/interfaces'
import { LoadingButton } from '@mui/lab';
import { 
    Typography, Box, Button,
    Badge, TextField, 
    Stack
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import QueryString from 'qs';
import axiosApi from '../../../../functions/axiosApi';
import Carousel from '../../../../components/Carousel';
import { copyClipboard } from '../../../../functions/basicFunctions';
import RemoveAddress from './RemoveAddress';

export interface kycDemandType {
    user: User,
    id: number,
    status: 'basic' | 'verified',
    blockchainAddress: string,
    memo: string,
    network: string,
    walletName: string,
    _system: 'blockchain' | 'mobile_money',
    proofImages: {url: string, id: number}[]
}

export default function WaitingDemands(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [demands, setDemands] = useState<kycDemandType[]>([]);
    const [numAddresses, setNumAddresses] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [message, setMessage] = useState(`
        Cher utilisateur,
        <br/><br/>
        Nous avons noté que l'adresse de portefeuille soumise ne semble pas être associée à un portefeuille personnel dont vous détenez le contrôle exclusif. Pour garantir la sécurité de vos fonds et respecter nos protocoles de conformité, il est essentiel que l'adresse fournie soit celle d'un portefeuille sur lequel vous avez une autorité complète.
        <br/><br/>
        Veuillez soumettre une adresse de portefeuille privé, géré uniquement par vous, pour continuer en toute sécurité.
        <br/><br/>
        Nous apprécions votre compréhension et coopération.
        <br/><br/>
    `
    );

    useEffect(() => {
        getDemands()
    }, [queryValue])

    const getDemands = () => {

        setIsLoading(true);

        axiosApi.post("ctr/kyc/wallet-addresses/get-demands.php", QueryString.stringify({offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);

            if(response.data.status === 'success'){
                
                const newDemandsArray = demands.concat(response.data.demands);
                setDemands(newDemandsArray);
                setOffset(offset + 5);
                setNumAddresses(response.data.numAddresses)
                
                if(response.data.demands.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }


    const confirmAddress = (demand:kycDemandType, decision: 'deny' | 'allow') => {

        basicDatas.dialogManager({
            dialogTitle: decision === 'allow' ? "Confirmer l'adresse" : "Refuser l'adresse",
            dialogText: decision === 'allow' ? "Souhaitez-vous vraiment valider cette adresse?" : "Souhaitez-vous vraiment supprimer cette adresse?",
            dialogAgreeBtnColor: decision === 'allow' ? 'info' : 'error',
            dialogAgreeBtnText: decision === 'allow' ? "Oui valider" : "Supprimer"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    addressID: demand.id,
                    decision,
                    message,
                    loaderText: "Traitement en cours..."
                }

                axiosApi.post('ctr/kyc/wallet-addresses/confirm-address.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                })
            }
      
        })
    }

    return (
        <Box
            sx={{
                maxWidth: 700,
                margin: 'auto'
            }}>

            <Typography 
                variant='h5'
                sx={{
                    textAlign: 'center',
                    mt: 5,
                    mb: 5
                }}
            > 
                <Badge max={9999999999999} badgeContent={numAddresses} color="primary">
                    Adresses en attente
                </Badge>

            </Typography>

            <div className="text-center">

                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher un utilisateur : ID" 
                    variant="outlined"
                    placeholder="Entrez ID utilisateur"
                    value={queryValue}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setDemands([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    />

            </div>

            <Box 
                sx={{ 
                    mt: 5
                }}
            >

                {
                    demands.map(itemDemand => {

                        return (
                            <Box 
                                key={itemDemand.id}
                                alignItems="flex-start"
                                sx={{
                                    p: 2,
                                    mb: 10,
                                    borderRadius: 1,
                                    boxShadow: 2
                                }}
                            >

                                <Typography sx={{mb: 2}}>
                                    <a href={ '/ctr/users/' + itemDemand.user.user_id } target='_blank'>
                                        { itemDemand.user.firstName + ' ' + itemDemand.user.lastName }
                                    </a>
                                </Typography>

                                <Typography 
                                    sx={{
                                        wordWrap: 'break-word',
                                        cursor: 'pointer'
                                    }}
                                    title="Copier l'adresse"
                                    onClick={() => copyClipboard(itemDemand.blockchainAddress, "Adresse copiée !")}
                                >
                                    <Typography variant='caption'>
                                        Adresse :
                                    </Typography>
                                    { ' ' + itemDemand.blockchainAddress }
                                </Typography>

                                <Typography>
                                    <Typography variant='caption'>
                                        Réseau :
                                    </Typography>
                                    { ' ' + itemDemand.network }
                                </Typography>

                                <Typography>
                                    <Typography variant='caption'>
                                        Nom associé :
                                    </Typography>
                                    { ' ' + itemDemand.walletName }
                                </Typography>

                                <Typography>
                                    <Typography variant='caption'>
                                        Statut :
                                    </Typography>
                                    <b>{ ' ' + itemDemand.status }</b>
                                </Typography>

                                <Box 
                                    component='div'
                                    sx={{
                                        mt: 3,
                                        mb: 4,
                                        width: 150
                                    }}
                                >
                                    <Carousel   
                                        items_per_page={1}  
                                        allowFullScreen={false}
                                        duration={ 10000 }
                                        height='150px'
                                        items = {   
                                            itemDemand.proofImages.map((image, index) => {
                                                return (
                                                    <Box 
                                                        component='div'
                                                        key={index}
                                                        overflow='auto'
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundImage: 'url("' + image.url + '")',
                                                            backgroundPosition: 'center',
                                                            backgroundSize: 'contain',
                                                            backgroundRepeat: 'no-repeat'
                                                        }}
                                                        />
                                                )
                                            })
                                        } 
                                    />
                                </Box>

                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    gap={2}
                                >

                                    <Button
                                        fullWidth
                                        variant='contained'
                                        onClick={() => confirmAddress(itemDemand, 'allow')}
                                    >
                                        Valider l'adresse
                                    </Button>

                                    <RemoveAddress 
                                        confirmAddress={confirmAddress}
                                        setMessage={setMessage}
                                        message={message}
                                        demand={itemDemand}
                                    />

                                </Stack>

                            </Box>
                        )
                    })
                }
                
            </Box>

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getDemands()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
