import React from 'react'
import { basicProps } from '../../utils/interfaces'
import { Box, Typography } from '@mui/material'
import { Link, Route, Routes } from 'react-router-dom'
import Addresses from './adresses/Addresses'
import { MetaTags } from 'react-meta-tags';

export default function Settings(props: basicProps) {

    const basicDatas = props.basicDatas;

    return (
        <Box>

            <MetaTags id="settings">
                <title>Paramètres</title>
                <meta name="description" content={basicDatas.appName + " - Paramètres"} />
                <meta property="og:title" content={basicDatas.appName + " - Paramètres"} />
            </MetaTags>

            <Routes>
                <Route path='/' element={
                    <>
                        <Box
                            sx={{
                                pt: 4,
                                maxWidth: 500,
                                margin: 'auto'
                            }}>
                            
                            <Typography variant='h5' sx={{textAlign: 'center', mb: 4}}>
                                Paramètres
                            </Typography>

                            {
                                [
                                    {
                                        label: "Mon compte",
                                        desc: 'Gérez les informations de votre compte',
                                        path: '/account/'
                                    },

                                    {
                                        label: "Adresses enregistrées",
                                        desc: 'Enregistrez des adresses pour utiliser facilement lors de la création de vos ordres',
                                        path: '/settings/addresses/'
                                    },

                                    {
                                        label: "Mot de passe",
                                        desc: 'Modifier le mot de passe de connexion',
                                        path: '/auth/reset-password/'
                                    },

                                    {
                                        label: "Code PIN",
                                        desc: 'Définir un PIN pour sécuriser le compte',
                                        path: '/auth/reset-pin-code/'
                                    }
                                ].map((item, i) => {
                                    return (
                                        <Box
                                            sx={{
                                                boxShadow: 2,
                                                p: 2,
                                                borderRadius: 1,
                                                mb: 3
                                            }}>
                                            <Link to={ item.path } className='link'>
                                                    
                                                <Typography variant='h6'>
                                                    { item.label }
                                                </Typography>
                                                <Typography variant='caption'>
                                                    { item.desc }
                                                </Typography>

                                            </Link>

                                            <br />
                                        </Box>
                                    )
                                })
                            }

                        </Box>
                    </>
                } />

                <Route path='/addresses' element={ <Addresses {...props} />} />
                
            </Routes>

        </Box>
    )
}
