import { Alert, Typography } from '@mui/material'

export type sponsorshipTransactionType = 'buy_sell_sponsored' | 'inscription_sponsored' | 'kyc_completed' | 'withdrawal'

interface myProps {
    transactionType: sponsorshipTransactionType,
    date: string
}

export default function SponsorshipTransactionTypeBadge(props: myProps) {

    const transactionType = props.transactionType;

    if(transactionType === 'buy_sell_sponsored') return (
        <Alert severity='info' sx={{fontSize: 'small'}}>
            <b>Commission Transaction Filleul.e : </b> Vous avez reçu cette commission car un filleul a effectué une transaction.
            <br />
            <Typography variant='caption'>{props.date}</Typography>
        </Alert>
    )

    if(transactionType === 'inscription_sponsored') return (
        <Alert severity='success' sx={{fontSize: 'small'}}>
            <b>Prime Invitation : </b> Vous avez reçu cette commission car un filleul a vérifié son compte avec succès.
            <br />
            <Typography variant='caption'>{props.date}</Typography>
        </Alert>
    )

    if(transactionType === 'kyc_completed') return (
        <Alert severity='success' sx={{fontSize: 'small'}}>
            <b>Prime Inscription : </b> Vous avez reçu ce bonus car vous avez vérifié votre compte avec succès.
            <br />
            <Typography variant='caption'>{props.date}</Typography>
        </Alert>
    )

    if(transactionType === 'withdrawal') return (
        <Alert severity='error' sx={{fontSize: 'small'}}>
            <b>Retrait commissions : </b> Vous avez été débité pour un retrait.
            <br />
            <Typography variant='caption'>{props.date}</Typography>
        </Alert>
    )

    return <></>
}
