import { useEffect } from 'react'
import { basicProps } from './utils/interfaces'
import { toast } from 'react-toastify';
import axiosApi from './functions/axiosApi';
import QueryString from 'qs';
import { Box, Button, Typography } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function WebPushSubscription(props: basicProps) {

    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    useEffect(() => {
        
        if (basicDatas.isLoggedIn) askPushPermission()

    }, [basicDatas.isLoggedIn])
    

    const askPushPermission = async () => {

        if(!('Notification' in window) || !('serviceWorker' in navigator) || (Notification.permission !== 'default')) return;
    
        basicDatas.dialogManager({
            dialogTitle: "",
            dialogText: (
                <Box sx={{
                    textAlign: 'center'
                }}>

                    <NotificationsNoneIcon
                        color='info'
                        sx={{
                            margin: 'auto',
                            fontSize: 70,
                            mb: 2
                        }} />

                    <Typography variant='h5' sx={{mb: 3, fontWeight: 'bold'}}>
                        Activer les notifications 
                    </Typography>

                    <Typography
                        sx={{mt: 5}}
                    >
                        <b>{ authUser.firstName }</b>, Activez les notifications pour être informé en temps réel de toute action concernant votre compte { basicDatas.appName }.

                    </Typography>

                </Box>
            ),
            dialogAgreeBtnColor: 'primary',
            dialogAgreeBtnText: 'Activer maintenant',
            dalogCloseBtnText: <Button color='error'>Plus tard</Button>
        }, async (response) => {
          
            if(response === 'ok') {
        
                const pushPermission = await Notification.requestPermission();
        
                if(pushPermission === 'granted') {
                registerServiceWorker();
                }
            
            }
        
            else {
                toast.warn("Domage! Si c'était une erreur, veuillez actualiser la page.");
            }
    
        }) 
        
    }
    
    const registerServiceWorker = async () => {

        const registration = await navigator.serviceWorker.register("/sw.js");
        let subscription = await registration.pushManager.getSubscription();

        if (!subscription) {
            subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: process.env.REACT_APP_PUSH_PUBLIC_VAPID,
            });
        }
      
        await savePushSubscription(subscription);
    }

    const savePushSubscription = async (subscription) => {
        subscription = subscription.toJSON();
        axiosApi.post('notificaions/save-push-subscription.php', QueryString.stringify({ subscription }))
    }

    return <></>
}
