import React from 'react'
import { basicProps } from '../../../../../utils/interfaces'
import { Link, Route, Routes } from 'react-router-dom'
import MailList from './MailList'
import WriteMail from './WriteMail'
import SendMail from './SendMail'

export default function Emails(props: basicProps) {
    return (
        <div>
            
            <Routes>

                <Route path='/' element={
                    <div style={{textAlign:'center'}}>

                    <br /><br />

                    <Link to="/ctr/communication/news-letter/emails/write-mail/" className='link' style={{fontSize:'18px'}}>Rédiger un mail</Link>
                    <br /><br />

                    <Link to="/ctr/communication/news-letter/emails/send-mail/" className='link' style={{fontSize:'18px'}}>Envoi mails</Link>
                    <br /><br />

                    <Link to="/ctr/communication/news-letter/emails/mail-list/" className='link' style={{fontSize:'18px'}}>Liste d'adresses mail</Link>
                    <br /><br />
              
                  </div>
                } />

                <Route path='/write-mail/*' element={<WriteMail {...props} />} />
                <Route path='/send-mail/*' element={<SendMail {...props} />} />
                <Route path='/mail-list/*' element={<MailList {...props} />} />

            </Routes>

        </div>
    )
}
