import { basicProps } from '../../../../utils/interfaces'
import { Avatar, Box, Typography } from '@mui/material'
import { Route, Routes } from 'react-router-dom';
import CampaignList from './CampaignList';
import ItemCampaign from './ItemCampaign';
import { isPC, publicPath } from '../../../../functions/basicFunctions';

export default function PrizePools(props: basicProps) {

    return (
        <>

            <Routes>
                <Route path='/' element={
                    <Box
                        sx={{
                            pt: 5,
                            maxWidth: 600,
                            margin: 'auto'
                        }}
                    >

                        <Avatar
                            src={publicPath + 'images/prize_pool_banner.png'}
                            sx={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: 3,
                                mb: 5
                            }}
                        />
                        
                        <Typography 
                            variant={ isPC ? 'h3' : 'h4'}
                            className='goldman'
                            color='primary'
                            sx={{
                                mb: 3,
                                fontWeight: 'bold',
                                letterSpacing: isPC ? 10 : 7,
                                textAlign: 'center'
                            }}
                        >
                            CAGNOTTES
                        </Typography>

                        <Typography
                            variant={isPC ? 'h5' : 'h6'}
                            className='Montserrat'
                            sx={{
                                mb: 10,
                                fontWeight: 'bold',
                                letterSpacing: 0.5,
                                textAlign: 'center'
                            }}
                        >
                            Effectuez quelques tâches simples et recevez votre part de la cagnotte.
                        </Typography>

                        <CampaignList {...props} />

                    </Box>
                } />

                <Route path='/:id' element={<ItemCampaign {...props}/>} />
            </Routes>
        </>
    )
}
