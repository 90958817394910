import React, { ReactElement } from 'react'
import { basicProps } from '../utils/interfaces'

interface infoPopupProps extends basicProps {
    children: any,
    text: ReactElement,
    title: string
}

export default function InfoPopup(props: infoPopupProps) {

    const basicDatas = props.basicDatas;

    const openPopup = () => {
        
        basicDatas.dialogManager({
            dialogTitle: props.title,
            dialogText: props.text,
            dialogAgreeBtnColor: 'primary',
            dialogAgreeBtnText: "J'ai compris",
            dalogCloseBtnText: ' '
          }, () => {})

    }

    return (
        <span onClick={(e) => {e.stopPropagation(); openPopup()}}>
            {props.children}
        </span>
    )
}
