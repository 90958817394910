import { Avatar, Button, DialogActions, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

interface componentProps {
  message: string | undefined
}
export default function UserIsNotConnectedMessage(props: componentProps) {

  return (
    <Paper
      elevation={5}
      sx={{
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '450px',
        p: 3
      }}
        >
          <Avatar sx={{ m: 2 }} />

          <Typography component="h1" variant="h5" sx={{mb:2}}>
            S'identifier
          </Typography>

          <div>

            {
              props.message 
              ? props.message 
              : <span> "Le service demandé requiert une authentication. <br /> Veuillez vous identifier pour continuer"</span>
            }
            
          </div>


          <DialogActions sx={{mt:3}}>

            <Link to="/auth/login/" className='link'>
              <Button variant='outlined' 
                      color='info'>
                Se connecter
              </Button>
            </Link>
            
            <Link to="/auth/signup/" className='link'>
              <Button variant='outlined' 
                      color='info'
                      sx={{ml:3}}>
                Créer un compte
              </Button>
            </Link>

          </DialogActions>

    </Paper>
  )
}
