import { useEffect, useState} from 'react'
import { basicProps, User } from '../../utils/interfaces';
import { MetaTags } from 'react-meta-tags';
import UserIsNotConnectedMessage from '../../components/UserIsNotConnectedMessage';
import { Link, Route, Routes } from 'react-router-dom';
import Referrals from './Referrals';
import { Alert, AlertTitle, Avatar, AvatarGroup, Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { copyClipboard, isPC, publicPath } from '../../functions/basicFunctions';
import axiosApi from '../../functions/axiosApi';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CommissionsRecap from './CommissionsRecap';
import Withdraw from './Withdraw';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const boxStyle = {
    width: isPC ? 275 : 'auto',
    height: 'auto',
    boxShadow: 2,
    p: 2,
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto'
}

export default function Sponsorships(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [linkCopied, setLinkCopied] = useState(false);
    const [sponsorshipInfos, setSponsorshipInfos] = useState({
        totalReferrals: 0,
        latestReferrals: [],
        commissions: {
            allTimeBalance: 0,
            availableBalance: 0
        }
    });

    useEffect(() => {

        axiosApi.post("sponsorships/check-old-system-balance.php")
        .then(() => {
            //console.log(response.data)

            axiosApi.post("sponsorships/get-sponsorships-basic-datas.php")
            .then(response => {
                setSponsorshipInfos(response.data.sponsorshipInfos)
            })
        })

    }, [])    


    if(!basicDatas.isLoggedIn) return (
        <div className='text-center' style={{maxWidth:'500px', margin: 'auto', marginTop:'60px'}}>
            <MetaTags id="sponsorships">
                <title>Parrainages</title>
                <meta name="description" content={basicDatas.appName + " - Parrainages"} />
                <meta property="og:title" content={basicDatas.appName + " - Parrainages"} />
            </MetaTags>
            <UserIsNotConnectedMessage message="Pour accéder à cette page, veuillez vous identifier." />
        </div>
    )

    return (

        <div>

            <MetaTags id="create-order">
                <title>CRYPTO-FIAT | Mon tableau de parrainage</title>
                <meta name="description" content="CRYPTO-FIAT | Mon tableau de parrainage" />
                <meta property="og:title" content="CRYPTO-FIAT | Mon tableau de parrainage" />
            </MetaTags>

            <Routes>

                <Route path='/' element={
                    <div style={{maxWidth: '825px', margin:'auto', marginTop:'50px'}}>

                        <Typography 
                            variant='h4' 
                            component='h3' 
                            className='text-center Montserrat'
                            fontWeight={800}
                        >
                            Mon tableau de parrainage
                        </Typography>

                        <br /><br />

                        <img 
                            src={ publicPath + 'images/sponsorship_page_banner.png'} 
                            width="100%" 
                            alt='Bonus banner' 
                            style={{
                                borderRadius: '3px'
                            }}
                        />

                        <Typography 
                            sx={{
                                mb: 3, 
                                mt: 5,
                                bgcolor: '#F7F7F7', 
                                p:2,
                                textAlign: 'left',
                                borderRadius: 1
                            }}
                        >
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                            >

                                <Typography fontWeight='bold' sx={{fontSize: 13}}>
                                    { "https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id }
                                </Typography>

                                <Typography>

                                    <Tooltip title="Cliquez pour copier le lien">

                                        {
                                            !linkCopied 
                                            ?   <ContentCopyIcon 
                                                    sx={{cursor: 'pointer'}} 
                                                    onClick={() => { copyClipboard("https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id, "Le lien a été copié !"); setLinkCopied(true)}}
                                                />
                                            :   <CheckCircleIcon 
                                                    className="text-success" 
                                                    sx={{cursor: 'pointer'}} 
                                                    onClick={() => { copyClipboard("https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id, "Le lien a été copié !"); setLinkCopied(true)}}
                                                />
                                        }

                                    </Tooltip>

                                    <Tooltip title="Partager via WhatsApp">
                                        <a 
                                            href={"https://wa.me/?text=Bonjour! Utilisez mon lien pour rejoindre CRYPTO-FIAT et commencez à acheter et vendre vos cryptos en toute simplicité : https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id }
                                            target='_blank'
                                        >
                                            <WhatsAppIcon sx={{color:'#128C7E', ml: 3, cursor: 'pointer'}} />
                                        </a>
                                    </Tooltip>

                                </Typography>

                            </Stack>
                        </Typography>

                        <Alert severity="info" sx={{marginTop:'40px', marginBottom:'30px', textAlign:'left'}}>
                            <AlertTitle style={{fontWeight:'bold'}}>Comment fonctionne le parrainage ?</AlertTitle>
                            Lorsque vous partagez votre lien d'invitation et que vos amis s'inscrivent et effectuent des transactions sur { basicDatas.appName }, vous recevez des commissions à chaque transaction effectuée par vos amis. Plus vous invitez d'amis, plus vous gagnez de commissions. Partagez votre lien et commencez à gagner dès aujourd'hui !
                            <br /> <br />
                            <Link to='/sponsorships/commissions/' className='link text-primary'>
                                En savoir plus
                            </Link>
                        </Alert>

                        <Stack direction={isPC ? 'row' : 'column'}
                                spacing={isPC ? 3 : 4}
                                sx={{mt:7}}>

                            <Box sx={boxStyle}>

                                <Stack direction='row' justifyContent='center'>

                                    <AvatarGroup max={3} total={sponsorshipInfos.totalReferrals} sx={{mb:3}}>

                                        {
                                            sponsorshipInfos.totalReferrals === 0
                                            ? <Avatar alt="" src="" />
                                            : sponsorshipInfos.latestReferrals.map((referral: User) => {
                                                return (
                                                    <Avatar src={referral.avatarUrl} alt={referral.lastName + " " + referral.firstName} key={referral.user_id} />
                                                )
                                            })
                                        }
                            
                                    </AvatarGroup>
                                </Stack>

                                <Typography className='text-muted' sx={{mb:3}}>
                                    {
                                        sponsorshipInfos.totalReferrals === 0 
                                        ? "Aucun.e filleul.e, Commencez à partager votre lien"
                                        : "Ils se sont inscrits avec mon lien d'invitation"
                                    }
                                    
                                </Typography>

                                {
                                    sponsorshipInfos.totalReferrals === 0

                                    ?   <Button variant='contained' 
                                                fullWidth 
                                                color='inherit'
                                                onClick={() => {copyClipboard("https://cryptofiat-ex.com/auth/signup/?s=" + basicDatas.authUser.user_id, "Le lien d'invitation a été copié dans le presse-paier."); setLinkCopied(true)}}
                                                startIcon={
                                                    !linkCopied 
                                                    ? <ContentCopyIcon sx={{fontSize:'small'}} />
                                                    : <CheckCircleIcon className="text-success" sx={{fontSize:'small'}} />
                                                }>
                                            Copier mon lien
                                        </Button>

                                    :   <Link to="/sponsorships/referrals/" className='link'>
                                            <Button variant='contained' fullWidth color='inherit'>
                                                Afficher
                                            </Button>
                                        </Link>
                                }

                                
                            </Box>

                            <Box sx={boxStyle}>

                                <Stack direction='row' justifyContent='center'>
                                    <Avatar className='text-success' sx={{backgroundColor:'#E8F3EE'}}>
                                        <AttachMoneyIcon />
                                    </Avatar>
                                </Stack>

                                <Typography variant='h6' sx={{mt:2, mb:2}}>
                                    Totales commissions
                                </Typography>

                                <Typography variant='h5' sx={{mb:3}}>
                                    { sponsorshipInfos.commissions.allTimeBalance + " FCFA" }
                                </Typography> 

                                <Link to="/sponsorships/commissions/" className='link'> 
                                    <Button variant='contained' 
                                            fullWidth 
                                            color='inherit'
                                            >
                                        Comprendre
                                    </Button>
                                </Link> 

                            </Box>

                            <Box sx={boxStyle}>

                                <Stack direction='row' justifyContent='center'>
                                    <Avatar className='text-success' sx={{backgroundColor:'#E8F3EE'}}>
                                        <AccountBalanceWalletIcon />
                                    </Avatar>
                                </Stack>

                                <Typography variant='h6' sx={{mt:2, mb:2}}>
                                    Solde actuel
                                </Typography>

                                <Typography variant='h5' sx={{mb:3}}>
                                    { sponsorshipInfos.commissions.availableBalance + " FCFA" }
                                </Typography>

                                <Link to="/sponsorships/withdraw/" className='link'> 
                                    <Button variant='contained' 
                                            fullWidth 
                                            color='inherit'
                                            >
                                        Retraits
                                    </Button>
                                </Link> 

                            </Box>

                        </Stack>

                    </div>
                } />

                <Route path='/referrals' element={<Referrals basicDatas={basicDatas} />} />
                <Route path='/commissions' element={<CommissionsRecap basicDatas={basicDatas} />} />
                <Route path='/withdraw' element={<Withdraw basicDatas={basicDatas} />} />

            </Routes>
        </div>

    )
}
