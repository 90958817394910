import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomeHelp from '../HomeHelp'
import BuyWithMtnCmGuide from './BuyWithMtnCmGuide'
import BuyWithOMCmGuide from './BuyWithOMCmGuide'
import BuyWithMtnCgGuide from './BuyWithMtnCgGuide'
import BuyWithAirtelCgGuide from './BuyWithAirtelCgGuide'
import BuyWithMoovTdGuide from './BuyWithMoovTdGuide'
import BuyWithAirtelTdGuide from './BuyWithAirtelTdGuide'
import BuyWithMoovGaGuide from './BuyWithMoovGaGuide'
import BuyWithAirtelGaGuide from './BuyWithAirtelGaGuide'

export default function TradeHelp() {
    return (
        <div>
            
            <Routes>
                <Route path='/' element={<HomeHelp/>} />
                <Route path='/buy-with-mtn-cm-guide' element={<BuyWithMtnCmGuide />} />
                <Route path='/buy-with-om-cm-guide' element={<BuyWithOMCmGuide />} />
                <Route path='/buy-with-mtn-cg-guide' element={<BuyWithMtnCgGuide />} />
                <Route path='/buy-with-airtel-cg-guide' element={<BuyWithAirtelCgGuide />} />
                <Route path='/buy-with-moov-td-guide' element={<BuyWithMoovTdGuide />} />
                <Route path='/buy-with-airtel-td-guide' element={<BuyWithAirtelTdGuide />} />
                <Route path='/buy-with-moov-ga-guide' element={<BuyWithMoovGaGuide />} />
                <Route path='/buy-with-airtel-Ga-guide' element={<BuyWithAirtelGaGuide />} />
            </Routes>
        </div>
    )
}
