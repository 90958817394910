import React from 'react'
import { basicProps } from '../../../utils/interfaces'
import { Link, Route, Routes } from 'react-router-dom'
import InvestPlans from './InvestPlans'

export default function Liquidity(props: basicProps) {
    return (
        <div>
            
            <Routes>

                <Route path='/' element={
                    <>
                        <div style={{textAlign:'center'}}>

                            <br /><br />

                            <Link to="/ctr/liquidity/invest-plans/" className='link' style={{fontSize:'18px'}}>Plans</Link>
                            <br /><br />

                        </div>
                    </>
                } />

                <Route path='/invest-plans/*' element={<InvestPlans {...props} />} />
                
            </Routes>

        </div>
    )
}
