import { useState, useEffect } from 'react'
import { User, basicProps, genderType, kycLevelType } from '../../../utils/interfaces'
import { LoadingButton } from '@mui/lab';
import { 
    Typography, Box, ToggleButtonGroup, ToggleButton, Button,
    Badge, TextField, List, ListItem, ListItemAvatar, Avatar,
    ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';

interface kycLevelCountType {
    vip0: number,
    vip00: number,
    vip1: number
}

export interface kycDemandType {
    id: number,
    userInfos: User,
    vip_level: kycLevelType,
    documentType: 'CNI' | 'PASSPORT' | 'PERMIS_CONDUIRE',
    documentID: string,
    expirationDate: string,
    date: string,
    lastUpdate: string,
    frontCard: string,
    backsideCard: string,
    frontDriverLicence: string,
    backsideDiverLicence: string,
    passportImg: string,
    selfie: string,
    country: string,
    ipCountry?: string,
    birthday: string,
    gender: genderType,
    message?: string
}

export default function WaitingDemands(props: basicProps) {

    const [demands, setDemands] = useState<kycDemandType[]>([]);
    const [kycLevelCount, setKycLevelCount] = useState<kycLevelCountType>();
    const [level, setLevel] = useState('vip00');
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);
    const [queryValue, setQueryValue] = useState('');

    useEffect(() => {
        getDemands()
    }, [level, queryValue])

    const getDemands = () => {

        setIsLoading(true);

        axiosApi.post("ctr/kyc/get-demands.php", QueryString.stringify({level, offset, limit:5, queryValue}))
        .then(response => {

            setIsLoading(false);

            if(response.data.status === 'success'){
                
                const newDemandsArray = demands.concat(response.data.demands);
                setDemands(newDemandsArray);
                setKycLevelCount(response.data.kycLevelCount);
                setOffset(offset + 5);
                
                if(response.data.demands.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <Box
            sx={{
                maxWidth: 400,
                margin: 'auto'
            }}>

            <Typography 
                    variant='h5'
                    sx={{
                        textAlign: 'center',
                        mt: 3,
                        mb: 5
                    }}>
                
                Demandes KYC 

            </Typography>

            <div className="text-center">

                <ToggleButtonGroup
                    color="primary"
                    value={level}
                    exclusive
                    onChange={
                        (e, newLevel) => {
                            setLevel(newLevel);
                            setDemands([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    sx={{
                        maxWidth: '100%',
                        overflow: 'auto'
                    }}
                    aria-label="order-status"
                    >

                    <ToggleButton value="vip00" sx={{minWidth: 150}}>
                        <Badge max={9999999999999} badgeContent={kycLevelCount ? kycLevelCount.vip00 : 0} color="primary">
                            VIP00
                        </Badge>
                    </ToggleButton>

                    <ToggleButton value="vip1" sx={{minWidth: 200}}>
                        <Badge max={9999999999999} badgeContent={kycLevelCount ? kycLevelCount.vip1 : 0} color="primary">
                            VIP1
                        </Badge>
                    </ToggleButton>

                </ToggleButtonGroup>

                <br /> <br />

                <TextField 
                    id="outlined-basic"
                    type='search' 
                    label="Rechercher un utilisateur : ID" 
                    variant="outlined"
                    placeholder="Entrez ID utilisateur"
                    value={queryValue}
                    onChange={
                        (e) => {
                            setQueryValue(e.target.value);
                            setDemands([]);
                            setOffset(0);
                            setEnded(false)
                        }
                    }
                    />

            </div>

            <List 
                sx={{ 
                    width: '100%', 
                    bgcolor: 'background.paper',
                    mt: 3
                }}>

                {
                    demands.map(itemDemand => {

                        const userInfos = itemDemand.userInfos;

                        return (
                            <ListItem 
                                    key={userInfos.user_id}
                                    alignItems="flex-start">

                                <ListItemAvatar>
                                    <Avatar alt={ userInfos.lastName + " " + userInfos.firstName } 
                                            src={ userInfos.avatarUrl } />
                                </ListItemAvatar>

                                <ListItemText
                                        primary={ userInfos.lastName + " " + userInfos.firstName }
                                        secondary={
                                    <>
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            Statut : <b>{ itemDemand.vip_level }</b>
                                        </Typography>
 
                                        <Link to={'/ctr/kyc/customers/' + userInfos.user_id + '/'} className='link'>
                                            <Button>
                                                Accéder
                                            </Button>
                                        </Link>
                                    </>
                                }
                                />
                            </ListItem>
                        )
                    })
                }
                
            </List>

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getDemands()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
