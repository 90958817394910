import React from 'react'
import { basicProps } from '../../utils/interfaces'
import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import DashboardHome from './DashboardHome';
import PhoneNumbers from './phone-numbers/PhoneNumbers';
import TransactionVolumes from './transaction-volumes/TransactionVolumes';

export default function ProDashboard(props: basicProps) {
    
    return (
        <Box>
            
            <Routes>
                <Route path='/' element={<DashboardHome {...props} />}></Route>
                <Route path='/phone-numbers' element={<PhoneNumbers {...props} />} />
                <Route path='/transaction-volumes' element={<TransactionVolumes {...props} />} />
            </Routes>
        </Box>
    )
}
