import React from 'react'
import { Link } from 'react-router-dom'
import { basicProps } from '../../utils/interfaces'

export default function CtrHome(props: basicProps) {
  return (
    <div style={{textAlign:'center'}}>

      <br /><br />

      <Link to="/ctr/orders/" className='link' style={{fontSize:'18px'}}>Ordres</Link>
      <br /><br />

      <Link to="/ctr/users/" className='link' style={{fontSize:'18px'}}>Utilisateurs</Link>
      <br /><br />

      <Link to="/ctr/crypto-list/" className='link' style={{fontSize:'18px'}}>Liste des devises</Link>
      <br /><br />

      <Link to="/ctr/payment-methods/" className='link' style={{fontSize:'18px'}}>Moyens de paiement</Link>
      <br /><br />
      
      <Link to="/ctr/networks/" className='link' style={{fontSize:'18px'}}>Les réseaux blockchains</Link>
      <br /><br />

      <Link to="/ctr/team/" className='link' style={{fontSize:'18px'}}>Gestionnaire de l'équipe</Link>
      <br /><br />

      <Link to="/ctr/communication/" className='link' style={{fontSize:'18px'}}>Communication</Link>
      <br /><br />

      <Link to="/ctr/sponsorships/" className='link' style={{fontSize:'18px'}}>Parrainages</Link>
      <br /><br />

      <Link to="/ctr/stats/" className='link' style={{fontSize:'18px'}}>Statistiques</Link>
      <br /><br />

      <Link to="/ctr/finances/" className='link' style={{fontSize:'18px'}}>Finances</Link>
      <br /><br />

      <Link to="/ctr/liquidity/" className='link' style={{fontSize:'18px'}}>Liquidité</Link>
      <br /><br />

      <Link to="/ctr/airdrops/" className='link' style={{fontSize:'18px'}}>Airdrops</Link>
      <br /><br />

      <Link to="/ctr/kyc/" className='link' style={{fontSize:'18px'}}>KYC</Link>
      <br /><br />

    </div>
  )
}
