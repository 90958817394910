import React, { useEffect, useState } from 'react'
import { basicProps } from '../../utils/interfaces';
import { Typography, Alert, AlertTitle, Box, TextField, Button, Chip } from '@mui/material';
import { cftTokenInfo } from '../../functions/basicFunctions';
import axiosApi from '../../functions/axiosApi';
import { HashLink } from 'react-router-hash-link';

export default function ClaimAirdrop(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [airdropData, setAirdropData] = useState<any>()

    useEffect(() => {
        getAirdropStatus();
    }, [])

    const getAirdropStatus = () => {
        axiosApi.post('airdrops/airdrop-status.php')
        .then(response => {
            if(response.data.status === 'success'){
                setAirdropData(response.data.airdropData);
            }
        })
    }
    

    const submitAdress = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let data = new FormData(e.currentTarget);
        
        data.append("loaderText", "Envoi de l'adresse en cours...");
    
        axiosApi.post('airdrops/claim-cft-tokens.php', data)
        .then(res => {
            if(res.data.status === 'success') {
                getAirdropStatus();
            }
        })
    }

    return (
        <div style={{paddingTop:'50px', maxWidth:'600px', margin:'auto'}}>
            
            <Typography variant='h5' className='text-center' sx={{mb: 5}}>
                Réclamer 1000 { cftTokenInfo.symbol + " tokens" }
            </Typography>

            {
                (airdropData && (airdropData.status === 'waiting_payment')) && 
                <Box component='div'>
                    <Alert severity="info" sx={{mb:2}}>
                        <AlertTitle sx={{fontWeight:'bold'}}>En attente de paiement</AlertTitle>
                        Vous avez soumis votre adresse BEP20 (Binance Smart Chain).
                        <br />
                        Veuillez attendre que l'administrateur vérifie les informations et transfère vos 1000 { cftTokenInfo.symbol } dans votre portefeuille dont l'adresse est :
                        <b>{ " " + airdropData.bscAddress }</b>
                        <br />
                        Si vous avez fait une erreur sur l'adresse de réception, veuillez {" "}
                        <HashLink to="/about#contact-us">
                            Contacter le support
                        </HashLink>.
                    </Alert>
                </Box>
            }

            {
                (airdropData && (airdropData.status === 'completed')) && 
                <Box component='div'>
                    <Alert severity="success" sx={{mb:2}}>
                        <AlertTitle sx={{fontWeight:'bold'}}>{"1000 " + cftTokenInfo.symbol } réclamés avec succès</AlertTitle>
                        L'administrateur a indiqué avoir transféré vos 1000 { cftTokenInfo.symbol } dans votre portefeuille à l'adresse: <b>{ " " + airdropData.bscAddress }</b> sur le réseau BEP20 (Binance Smart Chain)
                        <br />
                        Si vous avez un soucis, veuillez {" "}
                        <HashLink to="/about#contact-us">
                            Contacter le support
                        </HashLink>.

                        <br /><br />

                        Vérifiez sur la blockchain : <br />

                        <a href={"https://bscscan.com/tx/" + airdropData.transactionHash} className='link' target='_blank'>
                            <Chip
                                label={airdropData.transactionHash}
                                variant="outlined"
                                sx={{fontWeight:'bold', fontSize:'15px', marginTop:'10px', cursor:'pointer'}}
                            />
                        </a>

                    </Alert>
                </Box>
            }

            {
                (airdropData && (airdropData.status === 'canceled_by_admin')) && 
                <Box component='div'>
                    <Alert severity="error" sx={{mb:2}}>
                        <AlertTitle sx={{fontWeight:'bold'}}>Paiement refusé</AlertTitle>
                        L'administrateur a annulé votre demande. Après vérification, votre compte n'était pas éligible pour réclamer cet airdrop.
                        <br />
                        Pour tout rcours, veuillez {" "}
                        <HashLink to="/about#contact-us">
                            Contacter le support
                        </HashLink>.
                    </Alert>
                </Box>
            }

            {
                !airdropData &&

                <Box component='div'>
                    <Alert severity="info" sx={{mb:2}}>
                        <AlertTitle sx={{fontWeight:'bold'}}>Airdrop terminé !</AlertTitle>
                        Les réclamations pour cet airdrop ne sont plus possible après le 06 janvier 2023. <br />

                        Vous avez raté cet airdrop ? Abonnez-vous au <a href="https://t.me/cryptofiatex">canal Telegram</a> pour être au courant des annonces pour les prochains airdrops.
                    </Alert>
                </Box>
            }

        </div>
    )
}
