import { useState } from 'react'
import { 
    Box, Typography, Button, Tooltip, Avatar
} from '@mui/material';
import { User, basicProps, internalTransferType } from '../../utils/interfaces';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { cf_pay_info, copyClipboard, readabilityNumber } from '../../functions/basicFunctions';
import InfoPopup from '../InfoPopup';
import InfoIcon from '@mui/icons-material/Info';

interface myProps extends basicProps {
    transaction: internalTransferType,
    currentUser: User
}

export default function ViewTransfer(props: myProps) {

    const basicDatas = props.basicDatas;

    const transaction = props.transaction;
    const sender = transaction.sender;
    const receiver = transaction.receiver;
    const currentUser = props.currentUser;
    const iamSender = currentUser.user_id === sender.user_id;

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            
            <Typography
                variant='caption'
                onClick={() => setOpen(true)}
                >
                <span 
                    style={{
                        backgroundColor: 'rgba(100,100,100, 0.1)',
                        padding: '5px',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        display: 'inline-block'
                    }}
                >
                    Détails
                </span>
            </Typography>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{maxWidth: '550px', margin: 'auto'}}
            >
                <DialogTitle id="scroll-dialog-title">Détails transaction</DialogTitle>
                
                <DialogContent dividers={true}>

                    <Typography>
                        <Tooltip title="Cliquez pour copier ID transaction">
                            <span style={{cursor:'pointer', fontWeight: 'bold'}} onClick={() => copyClipboard(transaction.transactionID, "L'ID transaction a été copié !")}>
                                {"ID : " + transaction.transactionID}
                            </span>
                        </Tooltip>
                    </Typography>

                    <Typography
                        sx={{
                            mt: 1
                        }}>
                        Montant : <b>{ readabilityNumber(parseFloat((transaction.amount).toFixed(5))) + " " + transaction.asset.symbol }</b>
                    </Typography>


                    <Typography
                        sx={{
                            mt: 1
                        }}>
                        Frais : <b>{ readabilityNumber(parseFloat((iamSender ? transaction.senderFees : transaction.receiverFees).toFixed(5))) + " " + transaction.asset.symbol }</b>
                        
                        <InfoPopup basicDatas={basicDatas}
                                    title={"Frais de transactions " + cf_pay_info.name}
                                    text={
                                        <div>
                                            { cf_pay_info.feeReason }
                                        </div>
                                    }>
                            <Typography   
                                    variant='caption'
                                    sx={{
                                        ml: 1,
                                        fontStyle: 'oblique',
                                        cursor: 'pointer',
                                        mb: 2
                                    }}> 

                                        <InfoIcon sx={{mr: 0.5, fontSize:'16px'}} color='info' />
                                        Pourquoi ces frais ?
                            </Typography>

                        </InfoPopup>
                        
                    </Typography>
                    

                    <Typography>
                        Référence : <b>{ transaction.message }</b>
                    </Typography>

                    <Typography
                        variant='caption'
                        sx={{
                            mt: 1,
                            mb: 2
                        }}>
                        Date : <b>{ transaction.date }</b>
                    </Typography>

                    <Box
                        sx={{
                            boxShadow: 2,
                            p: 2,
                            mb: 3,
                            mt: 3,
                            borderRadius: 1,
                            textAlign: 'center'
                        }}>

                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mb: 2
                            }}>
                            <Avatar 
                                src={sender.avatarUrl} 
                                alt={sender.firstName + ' ' + sender.lastName}
                                sx={{
                                    boxShadow: 1
                                }} />
                        </Typography>

                        <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'var(--site-color)',
                                    mb: 1
                                }}>
                            Compte Émetteur
                        </Typography>

                        <Box>

                            <Typography sx={{mb: 2}}>
                                { sender.firstName + ' ' + sender.lastName }
                            </Typography>

                            <Typography>
                                <Tooltip title="Cliquez pour copier ID utilisateur">
                                    <b style={{cursor:'pointer'}} onClick={() => copyClipboard(sender.user_id, "L'ID utilisateur a été copié !")}>
                                        {" " + sender.user_id }
                                    </b>
                                </Tooltip>
                            </Typography>

                        </Box>
                    </Box>

                    <Box
                        sx={{
                            boxShadow: 2,
                            p: 2,
                            borderRadius: 1,
                            textAlign: 'center'
                        }}>

                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mb: 2
                            }}>
                            <Avatar 
                                src={receiver.avatarUrl} 
                                alt={receiver.firstName + ' ' + receiver.lastName}
                                sx={{
                                    boxShadow: 1
                                }} />
                        </Typography>

                        <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'var(--site-color)',
                                    mb: 1
                                }}>
                            Compte Bénéficiaire
                        </Typography>

                        <Box>

                            <Typography sx={{mb: 2}}> 
                                { receiver.firstName + ' ' + receiver.lastName }
                            </Typography>

                            <Typography>
                                <Tooltip title="Cliquez pour copier ID utilisateur">
                                    <b style={{cursor:'pointer'}} onClick={() => copyClipboard(receiver.user_id, "L'ID utilisateur a été copié !")}>
                                        { ' ' + receiver.user_id }
                                    </b>
                                </Tooltip>
                            </Typography>
                        </Box>

                    </Box>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}
