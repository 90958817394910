import { useState, useEffect} from 'react'
import { basicProps, prizePoolCampaignType } from '../../../../utils/interfaces'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import PrizePoolStatusBadge from '../../../../components/PrizePoolStatusBadge';
import Text from '../../../../components/Text';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import TelegramIcon from '@mui/icons-material/Telegram';
import { isPC } from '../../../../functions/basicFunctions';

export default function CampaignList(props: basicProps) {

    const navigate = useNavigate();

    const [campaigns, setCampaigns] = useState<prizePoolCampaignType[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);

    useEffect(() => {
        getCampaigns()
    }, [])


    const getCampaigns = () => {

        setIsLoading(true);

        axiosApi.post("airdrops/prize-pools/get-campaigns.php", QueryString.stringify({offset, limit: 3}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newArray = campaigns.concat(response.data.campaigns);
                setCampaigns(newArray);
                setOffset(offset + 3);

                if(response.data.campaigns.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <>

            {
                campaigns.length === 0 &&

                <Typography textAlign='center'>
                    Aucune cagnotte n'est en cours pour le moment. Veuillez vous abonner au canal Telegram pour être tenu informé de la prochaine campagne.

                    <br />

                    <a 
                        href='https://t.me/cryptofiatex' 
                        target='_blank'
                    >
                        <Button
                            variant='outlined'
                            className='Montserrat'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2
                            }}
                            endIcon={<TelegramIcon/>}
                        >
                            S'abonner au canal
                        </Button>
                    </a>
                </Typography>
            }

            {
                campaigns.map((itemCampaign) => {

                    const campaignPath = '/rewards-center/airdrops/prize-pools/' + itemCampaign.id + '/';

                    return (
                        <Stack
                            direction='row'
                            spacing={3}
                            sx={{
                                mb: 5,
                            }}
                        >

                            <Avatar
                                src={itemCampaign.img}
                                onClick={() => {navigate(campaignPath)}}
                                sx={{
                                    width: isPC ? 200 : 140,
                                    height: isPC ? 130 : 100,
                                    borderRadius: 3,
                                    cursor: 'pointer'
                                }}
                            />

                            <Typography sx={{width: '100%'}}>

                                <Typography 
                                    className='Montserrat' 
                                    fontWeight='bold'
                                    sx={{cursor: 'pointer', fontSize: isPC ? 'inherit' : 14}}
                                    onClick={() => navigate(campaignPath)}
                                >
                                    {itemCampaign.title + " "}
                                </Typography>

                                <Typography sx={{mt: 1}}>
                                    <PrizePoolStatusBadge status={itemCampaign.status} />
                                </Typography>

                                <Typography sx={{mt: 2}}>
                                    <Link to={campaignPath}>
                                        <Button
                                            variant='outlined'
                                            fullWidth
                                            className='Montserrat'
                                            sx={{
                                                p: isPC ? 1 : 0.5,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            { itemCampaign.status === 'progress' ? 'PARTICIPER' : 'Accéder'}
                                        </Button>
                                    </Link>
                                </Typography>
                        

                            </Typography>
                        </Stack>
                    )
                })
            }

            <br />
    
            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getCampaigns()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </>
    )
}
