import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { bilan } from './Bilans'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isPC, readabilityNumber } from '../../../../../functions/basicFunctions';

interface myProps {
    bilan: bilan,
    removeBilan: (bilan: bilan) => void
}

export default function ItemBilan(props: myProps) {

    const bilan = props.bilan;

    const [open, setOpen] = useState(false)

    return (
        <Accordion>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography fontWeight='bold'>
                    { bilan.period + ' ( ' + readabilityNumber(bilan.totalValue) + ' )' }
                </Typography>
            </AccordionSummary>

            <AccordionDetails>

                <Stack 
                    direction='row'
                    justifyContent='space-between'
                    sx={{
                        p: 2,
                        border: '1px solid #ddd'
                    }}
                >

                    <Typography fontWeight='bold'>
                        Compte
                    </Typography>

                    <Typography fontWeight='bold'>
                        Valeur estimée
                    </Typography>

                </Stack>

                {
                    bilan.balances.map((itemBalance, i) => {
                        return (
                            <Stack 
                                key={i}
                                direction='row'
                                justifyContent='space-between'
                                sx={{
                                    p: 2,
                                    border: '1px solid #ddd'
                                }}
                            >

                                <Typography>
                                    { itemBalance.fundLocation }
                                </Typography>

                                <Typography>
                                    { readabilityNumber(itemBalance.estimatedValue) + ' xaf'}
                                </Typography>

                            </Stack>
                        )
                    })
                }

                <Stack 
                    direction='row'
                    justifyContent='space-between'
                    sx={{
                        p: 2,
                        border: '1px solid #ddd'
                    }}
                >

                    <Typography fontWeight='bold'>
                        Total
                    </Typography>

                    <Typography fontWeight='bold' color='green'>
                        { readabilityNumber(bilan.totalValue) + ' xaf' }
                    </Typography>

                </Stack>

                <br />

                <div  
                    onClick={() => setOpen(true)} 
                    className='cursor-pointer'
                    style={{
                        textAlign: 'right'
                    }}
                >
                    <Button>
                        Dépenses
                    </Button>
                </div>

                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    sx={{maxWidth: '550px', margin: 'auto'}}
                >
                    <DialogTitle id="scroll-dialog-title" fontWeight='bold'>
                        Dépenses
                        <Typography color='error'>
                            { bilan.expenses.total } xaf
                        </Typography>
                    </DialogTitle>
                    
                    <DialogContent dividers={true} sx={{minWidth: 200}}>

                        {
                            bilan.expenses.items.map((itemExpense, i) => {
                                return (
                                    <Stack
                                        key={i}
                                        direction='row'
                                        justifyContent='space-between'
                                        sx={{
                                            mb: 2,
                                            pb: 2,
                                            borderBottom: '1px solid #eee'
                                        }}
                                    >
                                        <Typography>
                                            { itemExpense.expenseName }
                                            <br />
                                            <Typography variant='caption'>
                                                { itemExpense.date }
                                            </Typography>
                                        </Typography>

                                        <Typography sx={{minWidth: isPC ? 100 : 30}}></Typography>

                                        <Typography>
                                            { readabilityNumber(itemExpense.amount) } xaf
                                        </Typography>
                                    </Stack>
                                )
                            })
                        }

                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            sx={{
                                mt: 5
                            }}
                        >
                            <Typography fontWeight='bold'>
                                Total
                            </Typography>

                            <Typography color='error' fontWeight='bold'>
                                { bilan.expenses.total } xaf
                            </Typography>
                        </Stack>

                    </DialogContent>


                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Fermer</Button>
                    </DialogActions>
                </Dialog>

                <Button
                    color='error'
                    onClick={() => props.removeBilan(bilan)}
                    sx={{
                        mt: 2
                    }}
                >
                    Supprimer le bilan
                </Button>
                
            </AccordionDetails>

        </Accordion>
    )
}
