import { basicProps } from '../../../utils/interfaces'
import { Box } from '@mui/material'
import { Link, Route, Routes } from 'react-router-dom'
import WaitingDemands from './WaitingDemands'
import ItemDemand from './ItemDemand'
import AccountVerification from './AccountVerification'
import VerifyWalletAddresses from './wallet-addresses/VerifyWalletAddresses'

export default function Kyc(props: basicProps) {
    return (
        <Box>

            <Routes>
                <Route path='/' element={(
                    <div style={{textAlign:'center'}}>

                        <br /><br />

                        <Link to="/ctr/kyc/wallet-addresses/" className='link' style={{fontSize:'18px'}}>Vérification d'adresses</Link>
                        <br /><br />
                
                        <Link to="/ctr/kyc/customers/" className='link' style={{fontSize:'18px'}}>Vérification de comptes</Link>
                        <br /><br />
                
                    </div>
                )} />
                <Route path='/customers/*' element={<AccountVerification {...props} />} />
                <Route path='/wallet-addresses/*' element={<VerifyWalletAddresses {...props} />} />
            </Routes>

        </Box>
    )
}
