import { Avatar, Stack, Tooltip } from '@mui/material';
import Copyright from './Copyright';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from 'react-router-dom';
import { basicProps } from '../utils/interfaces';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function Footer(props: basicProps) {

  const basicDatas = props.basicDatas;

  return (
    <footer className='footer text-center'
            style={{
              marginTop: '70px'
            }}>

      <Stack justifyContent='center' 
              direction="row" 
              spacing={3}
              sx={{
                mb: 2
              }} >

        <Tooltip title="Message sur WhatsApp" placement='top'>
          <a href='https://wa.me/+237650839431' target='_blank'>
            <WhatsAppIcon sx={{color:'#128C7E'}} />
          </a>
        </Tooltip>

        <Tooltip title="Nous suivre sur Telegram" placement='top'>
          <a href='https://t.me/cryptofiatex' target='_blank'>
            <TelegramIcon />
          </a>
        </Tooltip>

        <Tooltip title="Nous suivre sur X" placement='top'>
          <a href='https://twitter.com/cryptofiatex' target='_blank'>

            <Avatar
              src="https://seeklogo.com/images/T/twitter-x-logo-0339F999CF-seeklogo.com.png?v=638258077460000000" 
              sx={{
                width: 23,
                height: 23,
                mt: 0.2
              }}
              />
          </a>
        </Tooltip>

        <Tooltip title="Nous suivre sur YouTube" placement='top'>
          <a href='https://www.youtube.com/@cryptofiat' target='_blank'>
            <YouTubeIcon color='error' sx={{width: 30, height: 30}} />
          </a>
        </Tooltip>
        
        <Tooltip title="Message par mail" placement='top'>
          <a href='mailto:contact@cryptofiat-ex.com' target='_blank'>
            <MailOutlineIcon sx={{color:'#D32F2F'}} />
          </a>
        </Tooltip>

      </Stack>

      <Link to="/about/" className="small text-muted" style={{display: 'block', marginBottom: '10px'}}>
        À propos de nous
      </Link>

      <Link to="/legal-notices/" className="small text-muted" style={{display: 'block', marginBottom: '10px'}}>
        Mentions légales
      </Link>

      <Link to="/terms/cgu/" className="small text-muted" style={{display: 'block', marginBottom: '10px'}}>
        Conditions (CGU)
      </Link>

      <Link to="/terms/privacy/" className="small text-muted" style={{display: 'block', marginBottom: '10px'}}>
        Confidentialité
      </Link>

      <Link to="/help/" className="small text-muted">
        Aide
      </Link>

      <br /> <br />
      <Copyright basicDatas={basicDatas} />
    </footer>
  )
}
