import { useState, useEffect } from 'react'
import { Avatar, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, TextareaAutosize, Typography } from '@mui/material';
import { basicProps, networkType, paymentMethodType } from '../../../utils/interfaces';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import { cf_pay_info, cftTokenInfo, readClipboard } from '../../../functions/basicFunctions';
import { MuiTelInput } from 'mui-tel-input';
import ImagesSelector from '../../../components/ImageSelector';
import InfoPopup from '../../../components/InfoPopup';
import InfoIcon from '@mui/icons-material/Info';

interface myProps extends basicProps {
    setOpen: (a: boolean) => void,
    getAddresses: () => void
}

export default function AddAddress(props: myProps) {

    const basicDatas = props.basicDatas;

    const [system, setSystem] = useState<'blockchain' | 'mobile-money'>();
    const [networks, setNetworks] = useState<networkType[]>([]);
    const [network, setNetwork] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<paymentMethodType | any>();

    const [paymentMethods, setpaymentMethods] = useState<paymentMethodType[]>([]);
    const [selectedNetwork, setSelectedNetwork] = useState<networkType | undefined>();
    const [blockchainAddress, setBlockchainAddress] = useState('');
    const [images, setImages] = useState<{img_url: string, img_data: string, img_id: number}[]>([]);
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
        getNetworks();
        getPaymentMethods();
    }, [])

    const handleNetworkChange = (network_symbol: string) => {

        const findNetworkInfos = networks.filter((item) => item.symbol === network_symbol);        

        if(findNetworkInfos.length !== 0) {
            setSelectedNetwork(findNetworkInfos[0])
        }

        setNetwork(network_symbol);

    }

    const handlePaymentMethodChange = (e: SelectChangeEvent) => {

        const matchedPaymentMethod = paymentMethods.filter((item) => item.symbol === e.target.value);
        if(matchedPaymentMethod.length !== 0){

            const matchedMethod = matchedPaymentMethod[0];
            setSelectedPaymentMethod(matchedMethod);
            setPaymentMethod(matchedMethod.symbol)

        }

    }

    const readAdressClipboard = () => {

        if(network === '') {
            toast.info("Veuillez d'abord sélectionner le réseau de transfert.");
            return;
        }

        readClipboard((clipboardContent) => {
            if(clipboardContent && typeof clipboardContent === 'string') setBlockchainAddress(clipboardContent)
        })
    }
    

    const getNetworks = () => {
        axiosApi.post('ctr/networks/get-networks.php')
        .then(response => {
            if(response.data.status === 'success'){
                setNetworks(response.data.networks);
            }
        })
    }

    const getPaymentMethods = () => {
        axiosApi.post('ctr/payment-methods/get-payment-methods.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setpaymentMethods(response.data.paymentMethods);
            }
        })
    }

    const saveAddress = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if((system === 'blockchain') && (images.length == 0)) {
            toast.error("Veuillez ajouter une capture de l'adresse à partir de votre portefeuille crypto.");
            return;
        }

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Traitement en cours...");

        if(images.length !== 0) {
            data.append('image', images[0].img_data);
        } 

        axiosApi.post("settings/saved_addresses/add.php", data)
        .then(response => {
            if(response.data.status === 'success') {
                props.setOpen(false);
                props.getAddresses();
            }
        })
    }

    return (
        <Box
            component='form'
            onSubmit={saveAddress}
            sx={{
                maxWidth: 400
            }}
            >

            <FormControl fullWidth sx={{mb: 2, minWidth: 200}}>

                <InputLabel id="system_label">Type d'adresse</InputLabel>

                <Select
                    labelId="system_label"
                    name='system'
                    label="Fournisseur prix"
                    fullWidth
                    margin="dense"
                    required
                    value={system}
                    onChange={(e) => setSystem(e.target.value as 'blockchain' | 'mobile-money')}
                    >

                    {
                        ['blockchain', 'mobile-money'].map((type, index) => {

                            return (
                                <MenuItem key={ index } value={ type }>
                                    { type === 'blockchain' ? "Blockchain" : 'Mobile money' }
                                </MenuItem>
                            )

                        })
                    }

                </Select>        

            </FormControl>

            {
                system === 'blockchain' &&  
                
                <>
                
                    <FormControl fullWidth sx={{mb: 2}}> 

                        <InputLabel id="select_network_label">Réseau</InputLabel>
        
                        <Select
                            labelId="select_network_label"
                            id="demo-simple-select"
                            name='network'
                            label="Réseau de transfert"
                            fullWidth
                            margin="dense"
                            required
                            onChange={(e) => handleNetworkChange(e.target.value + '')}
                            >
        
                            {
        
                                networks.map((itemNetwork, index) => {

                                    return (
                                        
                                        <MenuItem   
                                            key={index} 
                                            value={itemNetwork.symbol}
                                            >
            
                                            { itemNetwork.symbol + " (" + itemNetwork.name + ")" } 
            
                                        </MenuItem>
                                    )
                                })
                            }
        
                        </Select>
        
                    </FormControl>

                    {
                        selectedNetwork &&

                        <>

                            <Typography sx={{mb: 1}}>
                                <label htmlFor="address" className="small">
                                    Adresse {selectedNetwork?.symbol} 
                                </label> 

                                {
                                    blockchainAddress.length === 0 &&

                                    <Button color='info' 
                                            style={{float: 'right'}}
                                            onClick={readAdressClipboard} >
                                        Coller
                                    </Button>
                                }

                            </Typography>

                            <Typography variant='caption' sx={{color: 'red'}}>
                                Enregistrez uniquement l'adresse de votre propre compte/portefeuille. N'enregistrez ni l'adresse provenant d'une plateforme d'investissement en ligne ou d'une autre personne sinon votre compte sera restreint. 
                            </Typography>

                            <TextareaAutosize
                                aria-label="Adresse"
                                name='blockchainAddress'
                                id="blockchainAddress"
                                minRows={3}
                                required
                                value={blockchainAddress}
                                onChange={(e) => setBlockchainAddress(e.target.value)}
                                placeholder='Adresse'
                                style={{ 
                                    width:'100%', 
                                    padding:'10px', 
                                    borderRadius:'5px', 
                                    border: "1px solid #bbb"
                                }}
                            />

                            {
                                selectedNetwork.memo && 
                                <>

                                    <Typography variant='caption' sx={{color: 'red'}}>
                                        Certaines plateformes exigent un MEMO pour les dépôts via le réseau { selectedNetwork.symbol }
                                    </Typography> 

                                    <TextField
                                        type='text'
                                        name='memo'
                                        placeholder={selectedNetwork.memo}
                                        label="Memo (facultatif)"
                                        fullWidth
                                        sx={{mb: 2}}
                                    />
                                </>
                            }

                            
                            <TextField
                                type='text'
                                name='walletName'
                                placeholder={'Ex : ' + selectedNetwork.symbol + ' Trust Wallet'}
                                label="Associer un nom"
                                fullWidth
                                required
                                sx={{mt: 2, mb: 2}}
                            />


                            <div style={{display:'block', marginBottom:'7px'}}>
                                <Typography variant='caption'>
                                    Ajoutez une capture d'écran de cette adresse à partir de votre portefeuille crypto {selectedNetwork.symbol}.
                                </Typography>
                            </div>

                            <div className='proof-form-images'>
                                <ImagesSelector images={images} max={1} setImages={(items: any) => {setImages(items)}} />
                            </div>

                            <br />

                            <InfoPopup basicDatas={basicDatas}
                                title={"Pourquoi la vérification d'adresse est essentielle chez " + basicDatas.appName + "?"}
                                text={
                                    <div>
                                        <b>Sécurité accrue :</b> Nous vérifions les adresses de portefeuilles avant les transactions pour prévenir les détournements de fonds causés par des malwares qui remplacent les adresses copiées.
                                        <br /><br />
                                        <b>Conformité obligatoire :</b> Il est impératif que l'adresse de portefeuille vous appartienne personnellement pour prévenir les fraudes et respecter nos règles de sécurité.
                                        <br /><br />
                                        Ces mesures, bien que nouvelles, sont essentielles pour sécuriser vos actifs et empêcher les pertes. Nous vous remercions de votre compréhension et sommes à votre disposition pour toute assistance nécessaire.
                                        <br /><br />
                                        <a href="https://medium.com/@cryptofiat1022/pourquoi-lenregistrement-d-adresse-est-désormais-indispensable-sur-cryptofiat-229f502dcadf" target='_blank'>En savoir plus</a>
                                    </div>
                                }>

                                <Typography   
                                    variant='caption'
                                    className='small text-muted'
                                    sx={{
                                        fontStyle: 'oblique',
                                        cursor: 'pointer'
                                    }}
                                > 
                                    <InfoIcon sx={{mr:0.5, fontSize:'16px'}} color='info' />
                                    Pourquoi {basicDatas.appName} doit vérifier mon adresse ?
                                </Typography>
                            </InfoPopup>
                        </>
                    }

                </>

            }

            {
                system === 'mobile-money' &&

                <>

                    <FormControl fullWidth> 

                        <InputLabel id="select_network_label">Opérateur mobile money</InputLabel>
        
                        <Select
                            labelId="select_network_label"
                            id="demo-simple-select"
                            name='network'
                            label="Opérateur mobile money"
                            fullWidth
                            margin="dense"
                            required
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                            >
        
                            {
        
                                paymentMethods.map((itemPaymentMethod, index) => {

                                    return (
                                        
                                        <MenuItem   
                                            key={index} 
                                            value={itemPaymentMethod.symbol}
                                            disabled={(itemPaymentMethod.symbol === cf_pay_info.symbol) || (itemPaymentMethod.symbol === cftTokenInfo.symbol)}
                                            >
                                            
                                            <Avatar src={itemPaymentMethod.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                            { itemPaymentMethod.symbol + " (" + itemPaymentMethod.name + ")" } 
            
                                        </MenuItem>
                                    )
                                })
                            }
        
                        </Select>
                        
        
                    </FormControl>

                    {
                        selectedPaymentMethod &&
                        <>

                            <MuiTelInput 
                                label={"Numéro " + selectedPaymentMethod.name}
                                margin="normal"
                                id="phoneNumber"
                                value={phoneNumber} 
                                onChange={(value) => setPhoneNumber(value)}
                                name="phoneNumber"
                                autoComplete="phoneNumber"
                                placeholder={"Exemple : +237 " + selectedPaymentMethod.address}
                                fullWidth
                                required 
                                sx={{mt: 3}}
                            />

                            <TextField
                                id="phoneOwnerName"
                                label={"Nom associé à votre compte " + selectedPaymentMethod.name}
                                name="phoneOwnerName"
                                autoComplete="phoneOwnerName"
                                placeholder={"Exemple : " + selectedPaymentMethod.accountOwnerName}
                                fullWidth
                                required
                                sx={{mt:2}}
                                /> 

                            <TextField
                                type='text'
                                name='walletName'
                                placeholder={'Ex : Mon compte ' + selectedPaymentMethod.name}
                                label="Associer un nom"
                                fullWidth
                                required
                                sx={{mt: 2}}
                            />

                        </>
                    }

                </>

            }

            <Button
                type='submit'
                variant='contained'
                fullWidth
                sx={{mt: 3}}>
                Enregistrer
            </Button>
        </Box>
    )
}
