import { orderStatusType } from '../utils/interfaces';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface OrderStatusLabelProps {
    orderStatus: orderStatusType
}

export default function OrderStatusLabel(props: OrderStatusLabelProps) {

    const orderStatus = props.orderStatus;
    var orderStatusLabel = <span>Inconnu</span>;

    switch (orderStatus) {
        case 'waiting_payment':
            orderStatusLabel =  <span>
                                    <AccessTimeIcon fontSize='small' sx={{mr:0.5}} /> 
                                    <span style={{verticalAlign:'middle', fontWeight:'bold'}}>En attente de paiement</span>
                                </span>
            break;
        case 'marked_paid':
            orderStatusLabel =  <span>
                                    <FactCheckIcon fontSize='small' sx={{mr:0.5}} color='info' /> 
                                    <span style={{verticalAlign:'middle', fontWeight:'bold', color:'#2196F3'}}>Traitement en cours</span>
                                </span>
            break;
        case 'completed':
            orderStatusLabel =  <span>
                                    <CheckCircleIcon fontSize='small' sx={{mr:0.5}} color='success'/> 
                                    <span className='text-success' style={{verticalAlign:'middle', fontWeight:'bold'}}>Transaction terminée</span>
                                </span>
            break;
        case 'canceled_by_user':
            orderStatusLabel =  <span>
                                    <CancelIcon fontSize='small' sx={{mr:0.5}} color='error'/> 
                                    <span className='text-danger' style={{verticalAlign:'middle', fontWeight:'bold'}}>Annulé par l'utilisateur</span>
                                </span>
            break;

        case 'canceled_by_admin':
                orderStatusLabel =  <span>
                                        <CancelIcon fontSize='small' sx={{mr:0.5}} color='error'/> 
                                        <span className='text-danger' style={{verticalAlign:'middle', fontWeight:'bold'}}>Annulé par un validateur</span>
                                    </span>
                break;
        case 'completed':
            orderStatusLabel =  <span>
                                    <CancelIcon fontSize='small' sx={{mr:0.5}} color='error'/> 
                                    <span className='text-danger' style={{verticalAlign:'middle', fontWeight:'bold'}}>Ordre supprimé</span>
                                </span>
            break;
    
        default:
            orderStatusLabel =  <span>
                                    <AccessTimeIcon fontSize='small' sx={{mr:0.5}} /> 
                                    <span style={{verticalAlign:'middle', fontWeight:'bold'}}>En attente de paiement</span>
                                </span>
            break;
    }

    return orderStatusLabel;

}
