import { Box, Button, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import axiosApi from '../../../../functions/axiosApi';
import { useParams } from 'react-router-dom';

export default function EditFundLocation() {

    let {id} = useParams();
    let split = id?.split('-');
    const _id = split ? split[0] : 0;
    const _name = split ? split[1] : '';

    const [name, setName] = useState(_name)

    const edit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('loaderText', 'Ajout en cours...');
        data.append('id', id as string);

        axiosApi.post('ctr/stats/compta/funds-location/edit.php', data)
    }

    return (
        <div
            style={{
                maxWidth: 400,
                margin: 'auto'
            }}
        >

            <br />
            
            <Typography variant='h5' textAlign='center' sx={{mb: 5}}>
                Modifier
            </Typography>

            <Box
                component='form'
                onSubmit={edit}
            >

                <TextField
                    name='name' 
                    placeholder='Nom'
                    label='Nom'
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    fullWidth
                    sx={{
                        mb: 2
                    }}
                />

                <TextField
                    type='password'
                    name='sw' 
                    label='sw'
                    fullWidth
                    sx={{
                        mb: 3
                    }}
                />

                <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                >
                    Modifier
                </Button>
            </Box>

            
        </div>
    )
}
