import React from 'react'
import { basicProps } from '../../utils/interfaces'
import { Link, Route, Routes } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InvestPlans from './InvestPlans';
import ItemInvestPlan from './ItemInvestPlan';
import Payments from './Payments';
import { isPC, publicPath } from '../../functions/basicFunctions';

export default function LiquidityProvider(props: basicProps) {

    const basicDatas = props.basicDatas;

    return (
        <div>
            
            <Routes>
                <Route path='/' element={

                    <Box
                        sx={{
                            maxWidth: 700,
                            margin: 'auto',
                            pt: 5
                        }}
                    >

                        <img 
                            src={publicPath + "images/liquidity_banner.png"} 
                            alt="CRYPTO-FIAT PAY" 
                            width='100%'
                            style={{
                                borderRadius: '30px'
                            }}
                        />

                        <Typography
                            textAlign='center'
                            variant='h4'
                            fontWeight={1000}
                            className='Montserrat'
                            sx={{mt: 7, mb: 7, color: '#222222'}}
                        >
                            Fournisseur de liquidité
                        </Typography>
                    
                    
                        <Box
                            sx={{
                                bgcolor: '#F7F7F7',
                                borderRadius: 5
                            }}
                        >  

                            <Box
                                sx={{
                                    p: isPC ? 3 : 1,
                                    mt: 5
                                }}
                            >

                                <Box
                                    sx={{
                                        bgcolor: 'white',
                                        p: isPC ? 3 : 1,
                                        mb: 5,
                                        borderRadius: 3
                                    }}
                                >

                                    <Typography 
                                        variant='h6'
                                        fontWeight={800}
                                        sx={{mb: 2, color: '#222222'}}
                                        className='Montserrat'
                                    >
                                        Pourquoi fournir de la liquidité à { basicDatas.appName } ?
                                    </Typography>

                                    <Typography>
                                        Chez { basicDatas.appName }, nous nous efforçons d'offrir une expérience de transaction fluide et rapide à nos utilisateurs. Pour cela, nous avons besoin de disposer de fonds suffisants en cryptomonnaies et en fiat. 
                                        <Typography sx={{mb: 1}}></Typography>
                                        En fournissant de la liquidité, vous nous aidez à maintenir une réserve de fonds adéquate, ce qui permet de traiter les transactions plus rapidement et d'améliorer l'expérience utilisateur.  
                                    </Typography>

                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'white',
                                        p: isPC ? 3 : 1,
                                        mb: 5,
                                        borderRadius: 3
                                    }}
                                >

                                    <Typography 
                                        variant='h6'
                                        fontWeight={800} 
                                        className='Montserrat'
                                        sx={{mb: 2, color: '#222222'}}
                                    >
                                        Quels sont les avantages pour vous ?
                                    </Typography>

                                    <Typography>

                                        <ul>
                                            <li style={{marginBottom: 20}}>
                                                En tant que fournisseur de liquidité, vous bénéficiez d'un taux d'intérêt attractif de 10% sur une période de 10 mois. 
                                                <Typography sx={{mb: 1}}></Typography>
                                                Par exemple, si vous investissez <b>1'000'000 FCFA</b>, vous recevrez <b>110'000 FCFA</b> chaque mois pendant <b>10 mois</b>, soit un total de <b>1'100'000 FCFA</b>. 
                                            </li>

                                            <li>
                                                Vous bénéficiez automatiquement d'une remise sur vos transactions car vous êtes considéré comme un.e client.e privilégié.e de CRYPTO-FIAT.
                                                <div style={{marginBottom: 5}}></div>
                                                Le pourcentage de remise est déterminé par CRYPTO-FIAT en fonction de votre activité antérieure sur la plateforme et du montant de liquidité que vous fournissez.
                                                <div style={{marginBottom: 5}}></div>
                                                Plus votre participation est importante, plus vos avantages augmentent.
                                            </li>
                                        </ul>
                                         
                                    </Typography>

                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'white',
                                        p: isPC ? 3 : 1,
                                        mb: 5,
                                        borderRadius: 3
                                    }}
                                >

                                    <Typography 
                                        fontWeight={800} 
                                        variant='h6'
                                        className='Montserrat'
                                        sx={{mb: 2, color: '#222222'}}
                                    >
                                        Comment fournir de la liquidité ?
                                    </Typography>

                                    <Typography>
                                        Pour activer un plan d'investissement, veuillez nous contacter pour obtenir plus de détails sur les étapes à suivre et les conditions requises.  
                                    </Typography>
                                    
                                    <a 
                                        href={"https://wa.me/+237650839431/?text=Bonjour, je souhaites devenir fournisseur de liquidité à CRYPTO-FIAT, comment débuter?."} 
                                        target='_blank'
                                        className='link'
                                    >
                                        <Button
                                            variant='contained'
                                            startIcon={<WhatsAppIcon />}
                                            sx={{
                                                bgcolor: '#128C7E',
                                                mt: 2
                                            }}
                                        >
                                            +237 650 839 431
                                        </Button>
                                    </a>

                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'white',
                                        p: isPC ? 3 : 1,
                                        borderRadius: 3
                                    }}
                                >

                                    <Typography 
                                        fontWeight={800}
                                        variant='h6'
                                        className='Montserrat' 
                                        sx={{mb: 2, color: '#222222'}}
                                    >
                                        Comment visualiser vos investissements ?
                                    </Typography>

                                    <Typography>
                                        Accédez à votre espace de fournisseur de liquidité pour afficher tous les détails de vos investissements et suivre vos gains.
                                        <Typography sx={{mb: 1}}></Typography>
                                        Pour toute question ou assistance, n'hésitez pas à nous contacter.
                                        <Typography sx={{mb: 1}}></Typography>
                                        Merci de votre confiance et de votre soutien à CRYPTO-FIAT.   
                                    </Typography>
                                    
                                    <Link 
                                        to="/liquidity-provider/invest-plans/" 
                                        className='link'
                                    >
                                        <Button
                                            variant='contained'
                                            sx={{
                                                mt: 2,
                                                textTransform: 'none'
                                            }}
                                        >
                                            Mes plans d'investissment
                                        </Button>
                                    </Link>

                                </Box>

                            </Box>

                        </Box>

                    </Box>
                } />

                <Route path='/invest-plans/' 
                    element={<InvestPlans {...props} />}
                />

                <Route path='/invest-plans/:investPlanID/' 
                    element={<ItemInvestPlan {...props} />}
                />

                <Route path='/invest-plans/:investPlanID/payments' 
                    element={<Payments {...props} />}
                />
            </Routes>
        </div>
    )
}
