import { useState, useEffect } from 'react'
import { basicProps, prizePoolCampaignType } from '../../../../utils/interfaces'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import Text from '../../../../components/Text';
import { useNavigate } from 'react-router-dom';
import PrizePoolStatusBadge from '../../../../components/PrizePoolStatusBadge';

export default function Campaigns(props: basicProps) {

    const navigate = useNavigate();

    const [campaigns, setCampaigns] = useState<prizePoolCampaignType[]>([]);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);

    useEffect(() => {
        getCampaigns()
    }, [])


    const getCampaigns = () => {

        setIsLoading(true);

        axiosApi.post("ctr/airdrops/prize-pools/get-campaigns.php", QueryString.stringify({offset, limit: 3}))
        .then(response => {

            setIsLoading(false);
            
            if(response.data.status === 'success'){
                const newArray = campaigns.concat(response.data.campaigns);
                setCampaigns(newArray);
                setOffset(offset + 3);

                if(response.data.campaigns.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 600,
                margin: 'auto'
            }}
        >
            
            <Typography
                textAlign='center'
                className='Montserrat'
                variant='h5'
                fontWeight='bold'
                sx={{
                    mb: 5
                }}
            >
                Campagnes
            </Typography>

            {
                campaigns.map((itemCampaign) => {
                    return (
                        <Stack
                            direction='row'
                            spacing={3}
                            sx={{
                                mb: 5
                            }}
                        >

                            <Avatar
                                src={itemCampaign.img}
                                onClick={() => navigate("/ctr/airdrops/prize-pools/campaigns/" + itemCampaign.id + '/')}
                                sx={{
                                    width: 200,
                                    height: 130,
                                    borderRadius: 3,
                                    cursor: 'pointer'
                                }}
                            />

                            <Typography>

                                <Typography sx={{fontSize: 'small'}}>
                                    {itemCampaign.name + '  '}
                                    <PrizePoolStatusBadge status={itemCampaign.status} />
                                </Typography>

                                <Typography className='Montserrat' fontWeight='bold'>
                                    {itemCampaign.title}
                                </Typography>

                                <Typography sx={{opacity: 0.8}}>
                                    <Text text={itemCampaign.description} maxLength={50} basicDatas={props.basicDatas} />
                                </Typography>

                            </Typography>
                        </Stack>
                    )
                })
            }

            <br />
    
            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getCampaigns()}>
                            Afficher plus
                        </Button>
                }

            </div>
        </Box>
    )
}
