import { orderType } from '../../../utils/interfaces';
import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import CancelIcon from '@mui/icons-material/Cancel';

interface ShowOrderStausProps {
    order: orderType,
    style?: any
}

export default function ShowOrderStaus(props: ShowOrderStausProps) {

    const order = props.order;
    const orderID = order.basic.orderID;
    const orderStatus = order.basic.status;

    if ((orderStatus === 'canceled_by_user') || (orderStatus === 'canceled_by_admin')) return (
        <Alert severity="error" icon={ <CancelIcon fontSize='small' color='error'/> } sx={{textAlign:'left'}} {...props}>
            <AlertTitle sx={{fontWeight: 'bold'}}>Ordre annulé</AlertTitle>
            {orderStatus === 'canceled_by_user' ?  "L'ordre a été annulé par l'utilisateur" : "L'ordre a été annulé par un validateur"}
        </Alert>
    )

    if (orderStatus === 'marked_paid') return (
        <Alert severity="info" sx={{textAlign:'left'}} {...props}>
            <AlertTitle sx={{fontWeight: 'bold'}}>Marqué payé</AlertTitle>
            Vous avez confirmé avoir réglé le paiement. <br />
            Veuillez patienter pendant que les validateurs traitent votre commande.
        </Alert>
    )

    if (orderStatus === 'completed') return (

        <Alert severity="success" sx={{textAlign:'left'}} {...props}>

            <AlertTitle sx={{fontWeight: 'bold'}}>Ordre validé</AlertTitle>
            
            Les validateurs ont indiqué avoir transféré vos fonds. 
            <br />
            Veuillez vérifier le solde de votre portefeuille.

            <Box sx={{mt:2}}>
                <HashLink to={"/orders/" + orderID + "/#payment-proofs"} className='link'>
                    <Button variant='contained' color='success'>
                        Vérifier les preuves
                    </Button>
                </HashLink>
            </Box>

        </Alert>
    )

    return <></>
}
