import React, {useEffect, useState, useRef, ReactNode} from 'react';
import '../css/main/carousel.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';


/**
 * Ce composant est un carousel. 
 * il se nomme Fcacousel pour FINSMAD Carousel 
 * Fcacousel de façon à ne pas entrer en conflit avec une éventuelle librairie.
 */

interface CarouselProps {
    items: ReactNode[], // Contient les éléments à faire défiler
    height?: string; // (500px par défaut) C'est la hauteur en Pixels du carousel
    items_per_page?: number, // (1 par défaut) C'est le nombre d'éléments à afficher par page
    disableNavIcons?: boolean, // Si elle vaut true, la navigation d'en bas ne s'affiche pas
    disableFullScreenIcon?: boolean, // Si elle vaut true, l'icône "Passer en plein écran" ne s'affiche pas
    duration?: number; // C'est la durée d'affichage d'un élément avant de passer au suivant. si elle vaut 0, alors le slide nécessite de cliquer sur le bouton next
    allowFullScreen?: boolean; // Si elle est true, le carousel passe en plein écran lorqu'on clique sur agrandir
    isFullScreenHandler?(isFullScreen: boolean) : void; // Cette fonction permet au composant qui consomme le carousel de savoir lorsque le carousel passe en full screen et en mode normal
}


export default function Carousel(
    {
        items, 
        height = "500px",
        items_per_page = 1, 
        disableNavIcons = false,
        disableFullScreenIcon = false,
        duration = 0,
        allowFullScreen = true,
        isFullScreenHandler
    } : CarouselProps
) {

    const thisCarousel = useRef<HTMLElement>(null) as React.MutableRefObject<HTMLDivElement>;
    const itemsContainer = useRef<HTMLElement>(null) as React.MutableRefObject<HTMLDivElement>;
    const leftBtn = useRef<HTMLElement>(null) as React.MutableRefObject<HTMLDivElement>;
    const rightBtn = useRef<HTMLElement>(null) as React.MutableRefObject<HTMLDivElement>;

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentPageId, setCurrentPageId] = useState(1);
    const [carouselWidth, setCarouselWidth] = useState(0);
    const [items_container_width, set_items_container_width] = useState(0);
    const [elementWidth, setElementWidth] = useState(items_per_page);
    const [pages, setPages] = useState([1]);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);

    useEffect(() => {
        isFullScreenHandler && isFullScreenHandler(isFullScreen);
    }, [isFullScreen])

    useEffect(() => { 

        window.onresize = () => {
            get_carousel_width();
        }

        get_carousel_width(); 

        if(rightBtn && rightBtn.current){
        
            if(pages.length <= 1){
                rightBtn.current.style.display = 'none';
            }

            else {
                rightBtn.current.style.display = 'flex';
            }
        }
        
    })

    useEffect(() => {

        if(carouselWidth !== 0) {

            var num_pages = items.length / items_per_page;
            num_pages = Math.ceil(num_pages);

            var pagesArray = [];
            for (let i = 1; i < num_pages + 1; i++) {
                pagesArray.push(i as never);
            }

            setPages(pagesArray)
        }

    }, [items_container_width, carouselWidth, items.length, items_per_page])

    useEffect(() => {
      
        let interval: NodeJS.Timer;

        if(duration !== 0){
            interval = setInterval(() => {
                
                if(rightBtn && rightBtn.current) {
                    const clickEvent = new Event('click', {bubbles:true, cancelable:false});
                    rightBtn.current.dispatchEvent(clickEvent)
                }

            }, duration);

            if(itemsContainer && itemsContainer.current){

                itemsContainer.current.addEventListener('mouseover', () => {
                    clearInterval(interval);
                })

                itemsContainer.current.addEventListener('mouseout', () => {
                    interval = setInterval(() => {
                
                        if(rightBtn && rightBtn.current) {
                            const clickEvent = new Event('click', {bubbles:true, cancelable:false});
                            rightBtn.current.dispatchEvent(clickEvent)
                        }
        
                    }, duration);
                  
                })
               
            }
        }

        return () => {
            clearInterval(interval);
        }

    }, [duration, itemsContainer, rightBtn])
    
    
    useEffect(() => {
      
        if(itemsContainer && itemsContainer.current){
            itemsContainer.current.addEventListener('touchstart', (startEvent) => {
                setTouchStartX(startEvent.changedTouches[0].clientX)
            }, false)

            itemsContainer.current.addEventListener('touchend', (endEvent) => {

                setTouchEndX(endEvent.changedTouches[0].clientX);

            }, false)
        }

    }, [itemsContainer, rightBtn, leftBtn])

    useEffect(() => {

        if(rightBtn && rightBtn.current && leftBtn && leftBtn.current){

            const clickEvent = new Event('click', {bubbles:true, cancelable:false});
      
            if(touchStartX > touchEndX){
                rightBtn.current.dispatchEvent(clickEvent)
            }

            else if(touchStartX < touchEndX) {
                leftBtn.current.dispatchEvent(clickEvent)
            }
        }

    }, [touchEndX]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => { // réinitialiser si la liste d'items change
        goNext(1);
        setCurrentPageId(1);
    }, [items])  // eslint-disable-line react-hooks/exhaustive-deps
    

    let changePage = ((page_id: number) => {

        setCurrentPageId(page_id);
        goNext(page_id);
        
    })

    let get_carousel_width = () => {

        if(thisCarousel && thisCarousel.current){
            setCarouselWidth(thisCarousel.current.clientWidth)
        }

        calc_items_container_width();

    }

    let calc_items_container_width = () => {

        var _element_width = carouselWidth / items_per_page;
        var max_width = _element_width * items.length

        var width = max_width;

        set_items_container_width(width);
        setElementWidth(_element_width);
    }

    let page_number_btn_dimension = (page_id: number) => {

        var ecart = page_id - currentPageId;
        var dimension = ecart > 0 ? 30 - ecart * 4 : 30 + ecart * 4;
        
        return dimension;
    }

    let display_page_number_btn = (page_id: number) => {
        var ecart = page_id - currentPageId;
        ecart = ecart > 0 ? ecart : -ecart;

        if(window.screen.width >= 750) {
            return ((ecart <= 2) || (page_id === 1) || (page_id === pages.length)) ? 'flex' : 'none';
        }

        return ((ecart <= 1) || (page_id === 1) || (page_id === pages.length)) ? 'flex' : 'none';
 
    }

    let goPrev = () => {
        
        if(!itemsContainer || !itemsContainer.current) return;

        var percentage = 0;

        if(currentPageId === 1) return;

        if(currentPageId === 2){
            percentage = 0
        }

        else {
            percentage = (currentPageId - 2) * 100;
        }


        if((percentage === 0) && (leftBtn && leftBtn.current)){
            leftBtn.current.style.display = 'none';
        }


        if(percentage === 0){
            setCurrentPageId(1);
        }

        else if(currentPageId >= 2){
            setCurrentPageId(currentPageId - 1);
        }

        itemsContainer.current.style.marginLeft = - percentage + "%";

    }

    let goNext = (page_id: number | undefined = undefined) => {
        
        if(!itemsContainer || !itemsContainer.current) return;

        var percentage = 0;

        if(page_id !== undefined){

            if(page_id === 1){
                percentage = 0;
            }

            else {
                percentage = (page_id - 1) * 100;
            }
        }

        else {

            percentage = currentPageId * 100;  

        }

        if(percentage === pages.length * 100 - 100) {
            
            if(items_per_page !== 1) {

                var items_restant = items.length - items_per_page * currentPageId;
                var percentage_restant = (items_per_page - items_restant) * (100 / items_per_page);

                percentage = currentPageId * 100 - percentage_restant;
            }
            
        }

        else if(percentage === pages.length * 100){
            percentage = 0;
        }

        if((percentage === 0) && (leftBtn && leftBtn.current)){
            leftBtn.current.style.display = 'none';
        }

        else if(leftBtn && leftBtn.current) {
            leftBtn.current.style.display = 'flex';
        } 

        if(page_id === undefined){

            if(percentage === 0){
                setCurrentPageId(1);
            }

            else if(currentPageId < pages.length){
                setCurrentPageId(currentPageId + 1);
            }

            else {
                setCurrentPageId(pages.length);
            }
        }


        itemsContainer.current.style.marginLeft = - percentage + "%";

    }

    let fullScreen = () => {

        if(!thisCarousel || !thisCarousel.current) return;

        if(!thisCarousel.current.classList.value.match('is_full_screen')){

            thisCarousel.current.classList.add('is_full_screen');
            setIsFullScreen(true);
            thisCarousel.current.style.height = '100%';
            document.body.style.overflow = 'hidden';


            if(allowFullScreen && !document.fullscreenElement){
                thisCarousel.current.requestFullscreen();
                thisCarousel.current.style.backgroundColor = "#fff";
            }
            
        }

        else {

            thisCarousel.current.classList.remove('is_full_screen');
            setIsFullScreen(false);
            thisCarousel.current.style.height = height;
            document.body.style.overflow = 'auto';
            thisCarousel.current.style.backgroundColor = "";

            if(allowFullScreen && document.fullscreenElement) {
                document.exitFullscreen();  
            }
        }

        get_carousel_width();
        
    }

    return (
        <div    className='f-carousel' ref={thisCarousel}
                style={{
                    height: height ? height : '500px'
                }}>

            <div className='f-carousel-container'>

                {
                    (!disableFullScreenIcon || isFullScreen) &&
                    <div className='f-c-full-screen-btn shadow' title={isFullScreen ? 'Cliquez pour réduire' : 'Cliquez pour agrandir'} onClick={() => fullScreen()}>
                        <i className={isFullScreen ? "fa fa-search-minus" : "fa fa-search-plus"}></i>
                        {
                            isFullScreen 
                            ?   <CloseIcon fontSize='small' />
                            :   <OpenInFullIcon fontSize='small' />
                        }
                    </div>
                }

                <div    className="f-c-items shadow" 
                        style={{
                            height: (disableNavIcons && !isFullScreen) ? '100%' : '88%'
                        }}>

                    <div ref={leftBtn} className='f-c-direction-nav-btn f-c-left-btn shadow' title='Cliquez pour rentrer' onClick={() => goPrev()}>
                        <ArrowBackIosNewIcon fontSize='small' />
                    </div>

                    <div    className="f-c-items-container"
                            ref={itemsContainer}
                            style={{
                                width: items_container_width + 'px'
                            }}>

                        {
                            items.map((element, element_index) => {
                                return (
                                    <div    className={element_index === currentPageId ? 'f-c-element active' : 'f-c-element'} 
                                            id={"f-c-item-" + element_index} 
                                            key={element_index}
                                            onClick={() => fullScreen()}
                                            draggable="true"
                                            style={{
                                                width: elementWidth + 'px'
                                            }}
                                            >
                                        {element}
                                    </div>
                                )
                            })
                        }

                    </div>

                    <div ref={rightBtn} className="f-c-direction-nav-btn f-c-right-btn shadow" title='Cliquez pour avancer' onClick={() => goNext()}>
                        <ArrowForwardIosIcon fontSize='small' />
                    </div>

                </div>

                {
                    (!disableNavIcons || isFullScreen) &&

                    <div className='f-c-numerotations'>

                        {
                            pages.map((page_id) => {

                                return <span    className={page_id === currentPageId ? 'f-c-element_id_btn active shadow' : 'f-c-element_id_btn shadow'} 
                                                onClick={() => changePage(page_id)} title="Cliquez pour afficher l'élément correspondant"
                                                key={page_id}
                                                style={{
                                                    width: page_id === currentPageId ? '30px' : page_number_btn_dimension(page_id) + 'px',
                                                    height: page_id === currentPageId ? '30px' : page_number_btn_dimension(page_id) + 'px',
                                                    minWidth: (page_id === 1) || (page_id === pages.length) ? '20px' : '0px',
                                                    minHeight: (page_id === 1) || (page_id === pages.length) ? '20px' : '0px',
                                                    display: display_page_number_btn(page_id),
                                                    color: isFullScreen ? '#eee' : '#333'
                                                }}>
                                            {page_id}
                                        </span>
                            })
                        }
                    </div>
                }

            </div>

        </div>
    )
}