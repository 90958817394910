import { useState, useEffect } from 'react'
import { basicProps, prizePoolCampaignType } from '../../../../utils/interfaces'
import { Avatar, Box, LinearProgress, Typography } from '@mui/material'
import axiosApi from '../../../../functions/axiosApi';
import { useNavigate, useParams } from 'react-router-dom';
import QueryString from 'qs';
import TaskViews from './task-views/TaskViews';
import PrizePoolStatusBadge from '../../../../components/PrizePoolStatusBadge';
import ClaimRewards from './ClaimRewards';
import { isPC } from '../../../../functions/basicFunctions';

export default function ItemCampaign(props: basicProps) {

    const {id} = useParams();

    const [campaignData, setCampaignData] = useState<prizePoolCampaignType>();

    useEffect(() => {
        
        axiosApi.post("ctr/airdrops/prize-pools/get-campaign-data.php", QueryString.stringify({id}))
        .then(response => {

            if(response.data.status === 'success') {
                setCampaignData(response.data.campaignData)
            }
        })

    }, [id])


    if(!campaignData) return (
        <Box sx={{pt: 5, textAlign: 'center'}}>
            <LinearProgress 
                color='info' 
                sx={{
                    mb: 1, 
                    maxWidth: 500, 
                    margin: 'auto',
                    display: 'block'
                }} 
            />
        </Box>
    )


    return (
        <Box
            sx={{
                pt: 5,
                maxWidth: 600,
                margin: 'auto'
            }}
        >
            
            <Typography 
                variant={isPC ? 'h4' : 'h5'}
                className='Montserrat'
                sx={{
                    mb: 3,
                    fontWeight: 'bold',
                    letterSpacing: 0.5,
                    textAlign: 'center'
                }}
            >
                { campaignData.title } <br />
                <PrizePoolStatusBadge status={campaignData.status} />
            </Typography>

            <Avatar
                src={ campaignData.img } 
                sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 5,
                    mb: 5
                }}
            />

            <div 
                dangerouslySetInnerHTML={{__html: campaignData.description}}
                style={{opacity: 0.8}} 
            />

            {
                campaignData.status === 'progress' &&
                <TaskViews campaignData={campaignData} {...props} />
            }

            {
                campaignData.status === 'completed' &&
                <ClaimRewards campaignData={campaignData} {...props} />
            }


        </Box>
    )
}
