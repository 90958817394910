import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import QueryString from 'qs';
import { Alert, AlertTitle, Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { basicProps, investPlanPaymentType, investPlanType } from '../../utils/interfaces';
import axiosApi from '../../functions/axiosApi';
import { cf_pay_info, readabilityNumber } from '../../functions/basicFunctions';
import PaymentProofs from './PaymentProofs';

export default function Payments(props: basicProps) {

    const { investPlanID } =  useParams();
    const basicDatas = props.basicDatas;
    const authUser = basicDatas.authUser;

    const [investPlanData, setInvestPlanData] = useState<investPlanType>();
    const [payments, setPayments] = useState<investPlanPaymentType[]>([]);
    const [numPayments, setNumPayments] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [ended, setEnded] = useState(false);


    useEffect(() => {
        getPayments();
    }, [])

    useEffect(() => {
        
        axiosApi.post('liquidity/get_plan_info.php', QueryString.stringify({investPlanID}))
        .then(response => {

            if(response.data.status === 'success') {
                setInvestPlanData(response.data.investPlanData)
            }
        })

    }, [investPlanID])

    const getPayments = () => {

        setIsLoading(true);

        axiosApi.post("liquidity/get_payments.php", QueryString.stringify({
            investPlanID,
            offset, 
            limit: 3
        }))
        .then(response => {

            if(response.data.status === 'success'){
                const newDemandsArray = payments.concat(response.data.payments);
                setPayments(newDemandsArray);
                setNumPayments(response.data.numPayments);
                setIsLoading(false);
                setOffset(offset + 3);

                if(response.data.payments.length === 0){
                    setEnded(true)
                }
            }
        }).catch(() => {
            setIsLoading(false);
        })
    }

    if(!investPlanData) return (
        <Box>
            <Typography textAlign='center' sx={{mt: 3}}>
                Chargement...
            </Typography>
        </Box>
    )

    return (
        <Box
            sx={{
                pt: 3,
                maxWidth: 500,
                margin: 'auto'
            }}
        >
            
            <Typography 
                variant='h5'
                textAlign='center'
                sx={{
                    mt: 2,
                    mb: 5
                }}
            >
                Paiements plan investissement <br />
                { investPlanID } 
            </Typography>

            {
                numPayments === 0
                ?

                <Alert severity="info" sx={{textAlign:'left'}} {...props}>
                    <AlertTitle sx={{fontWeight: 'bold'}}>Aucun paiement reçu</AlertTitle>
                    Vous n'avez pas encore reçu de paiement pour ce plan d'investissement. Lorsqu'ils y aura des paiements, vous les verrez ici.
                </Alert>

                :

                <Alert severity="info" sx={{textAlign:'left'}} {...props}>
                    <AlertTitle sx={{fontWeight: 'bold'}}>Vous avez reçu { numPayments } paiements</AlertTitle>
                    Vous avez reçu { numPayments } paiements pour ce plan d'investissement pour un montant total de <b>{ readabilityNumber(investPlanData.totalPayments) + ' XAF'}</b>.
                </Alert>
            }

            <br /><br />

            
            {
                payments.map((itemPayment, i) => {
                    return (
                        <Typography 
                            key={i}
                            sx={{
                                mb: 5,
                                borderBottom: '1px solid #eee'
                            }}
                        >

                            <Typography
                                fontWeight='bold'
                                variant='h5'
                                sx={{mb: 1}}
                            >
                                { itemPayment.day + '-' + itemPayment.month + '-' + itemPayment.year} 
                            </Typography>

                            <Typography sx={{mb: 1}}>
                                Montant : <b>{ readabilityNumber(itemPayment.amount) + ' XAF' }</b>
                            </Typography>

                            <Typography 
                                sx={{
                                    mt: 2,
                                    p: 2,
                                    bgcolor: 'rgba(250,250,250, 1)',
                                    borderRadius: 2
                                }}
                            >

                                <table>
                                    <td>
                                        <Avatar 
                                            src={itemPayment.paymentMethod.logo} 
                                            sx={{
                                                width: 25,
                                                height: 25,
                                                mr: 1
                                            }}
                                        />
                                    </td>
                                    <td style={{verticalAlign: 'middle'}}>
                                        <Typography variant='caption'>
                                            { itemPayment.paymentMethod.symbol + " (" + itemPayment.paymentMethod.name + ")" }
                                        </Typography>
                                    </td>
                                </table>

                                <Typography variant='caption'>
                                    { itemPayment.paymentMethod.symbol === cf_pay_info.symbol ?  authUser.user_id : itemPayment.paymentMethodNumber}
                                </Typography>

                                <br />

                                <Typography variant='caption'>
                                    { itemPayment.paymentMethod.symbol === cf_pay_info.symbol ?  authUser.lastName + ' ' + authUser.firstName : itemPayment.accountOwnerName}
                                </Typography>

                            </Typography>

                            <PaymentProofs paymentInfo={itemPayment} basicDatas={basicDatas} />
                            
                        </Typography>
                    )
                })
            }

            <div className="text-center">

                {
                    isloading

                    ? 
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Chrargement
                        </LoadingButton>
                    :
                        !ended &&
                        <Button variant='contained' color='inherit' onClick={() => getPayments()}>
                            Afficher plus
                        </Button>
                }

            </div>

        </Box>
    )
}
