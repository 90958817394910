import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom';
import axiosApi from '../../../functions/axiosApi';
import { currencyType } from '../../../utils/interfaces';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ItemCurrency from './ItemCurrency';

interface props {
    basicDatas: any
}

export default function CurrenciesList(props: props) {

    const basicDatas = props.basicDatas;

    const [currencies, setCurrencies] = useState([]);
    const [networks, setNetworks] = useState([])

    useEffect(() => {
        getCurrencies();
        getNetworks();
    }, [])

    const getCurrencies = () => {
        axiosApi.post('ctr/currencies/get-currencies.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setCurrencies(response.data.currencies);
            }
        })
    }

    const getNetworks = () => {
        axiosApi.post('ctr/networks/get-networks.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setNetworks(response.data.networks);
            }
        })
    }
    

    const addCurrency = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        let data = new FormData(e.currentTarget);
        
        data.append('loaderText', 'Ajout de la devise en cours...');

        axiosApi.post('ctr/currencies/add-currency.php', data)
        .then(response => {
            if(response.data.status === 'success'){
                const form_add_currency = document.getElementById('form_add_currency') as HTMLFormElement
                if(form_add_currency) form_add_currency.reset();
                getCurrencies();
            }
        })

    }

    if(currencies.length === 0) return (
        <Box>
            <Typography
                    sx={{
                        mt: 3,
                        textAlign: 'center'
                    }}>
                Chargement...
            </Typography>
        </Box>
    )

    return (
        <div>

            <Routes>
                <Route path='/' element={

                    <div style={{
                        paddingTop: '30px'
                    }}>

                        <Typography component="h1" variant="h5" className='text-center'>
                            Liste des devises
                        </Typography>

                        <Box
                            component="form"
                            id="form_add_currency"
                            sx={{
                                '& .MuiTextField-root': { width: '25ch' },
                                mt: 4,
                                mb: 6,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={addCurrency}
                            >

                            <TextField
                                required
                                name='name'
                                id="outlined-required"
                                label="Nom devise"
                                />

                            <TextField
                                required
                                name="symbol"
                                id="outlined-disabled"
                                label="Symbole devise"
                                />

                            <TextField
                                required
                                name='logo'
                                id="outlined-required"
                                label="Lien logo (CoinMarketCap)"
                                />

                            <TextField
                                type='password'
                                label="sw"
                                name='sw'
                            />

                            <Button type='submit' variant='contained' sx={{p:1.9}}>
                                Ajouter
                            </Button>

                        </Box>

                        <List sx={{ width: '100%', mt:3 }}>

                            {
                                currencies.map((currency: currencyType, i) => {
                                    return (
                                        <ListItem   secondaryAction={

                                                        <Link to={"/ctr/crypto-list/" + currency.symbol + "/"}>
                                                            <Button color='info'>
                                                                <EditIcon />
                                                            </Button>
                                                        </Link>
                                                    }
                                                    sx={{bgcolor: 'ButtonFace', mb:2}}
                                                    key={i}>
                    
                                            <ListItemAvatar>
                                                <Avatar src={currency.logo} />
                                            </ListItemAvatar>
                                            <ListItemText primary={currency.symbol} secondary={currency.name} />
                                            <ListItemText primary={currency.purchasePrice ? currency.purchasePrice : "---"} secondary="Prix achat" />
                                            <ListItemText primary={currency.salePrice ? currency.salePrice : "---"} secondary="Prix vente" />
                                        
                                        </ListItem>
                                    )
                                })
                            }
            
                        </List>

                    </div>

                } />

                <Route path='/:currencySymbol' element={
                    <ItemCurrency basicDatas={basicDatas} networks={networks} />
                } />
            </Routes>

            

        </div>
    )
}
