import { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material'
import { basicProps, prizePoolCampaignType } from '../../../../../utils/interfaces'
import { Link } from 'react-router-dom';
import TaskStatusBadge from './TaskStatusBadge';
import axiosApi from '../../../../../functions/axiosApi';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import QueryString from 'qs';
import { isPC } from '../../../../../functions/basicFunctions';

interface myProps extends basicProps {
    campaignData: prizePoolCampaignType
}

export default function InviteFriendsTask(props: myProps) {

    const basicDatas = props.basicDatas;
    const campaignData = props.campaignData;

    const [inviteFriendsStatus, setInviteFriendsStatus] = useState('not_completed');
    const [statusLoaded, setStatusLoaded] = useState(false);
    let taskIsCompleted = inviteFriendsStatus === 'completed';

    useEffect(() => {
        
        axiosApi.post("airdrops/prize-pools/check-invite-friends-tasks-status.php", QueryString.stringify({campaignID: campaignData.id}))
        .then(response => {

            setStatusLoaded(true);

            if(response.data.status === 'success') {
                setInviteFriendsStatus(response.data.inviteFriendsStatus)
            }
        })
    }, [])
    

    return (
        <Box
            sx={{
                p: 2,
                mb: 5,
                bgcolor: '#F7F7F7',
                borderLeft: taskIsCompleted ? '5px solid green' : 'none',
                borderRadius: 3
            }}
        >

            <Stack
                direction='row'
                spacing={isPC ? 3 : 1.5}
            >

                <GroupAddIcon
                    color='primary'
                    sx={{
                        width: isPC ? 50 : 40,
                        height: isPC ? 50 : 40
                    }}
                />
                <Typography>

                    {
                        !statusLoaded &&
                        <Typography sx={{mb: 1.5}}>
                            <LinearProgress 
                                color='info' 
                                sx={{  
                                    margin: 'auto',
                                    display: 'block'
                                }} 
                            />
                        </Typography>
                    }

                    <TaskStatusBadge 
                        status={taskIsCompleted ? 'completed' : 'not_completed'} 
                        sx={{mb: 1}}
                    />

                    <Typography variant='h6' fontWeight='bold' className='Montserrat'>
                        Inviter des amis
                    </Typography>
                    <Typography sx={{opacity: 0.8}}>
                        Invitez vos amis à rejoindre { basicDatas.appName }

                        <Typography sx={{mb: 1}} />
                        <Typography variant='caption'>
                            Invitez vos amis à rejoindre { basicDatas.appName } et incitez les à vérifier leurs comptes.
                            <Typography sx={{mb: 1}} />
                            {!taskIsCompleted && <b>Pour que la tâche soit complétée, au moins un(e) filleul(e) invité(e) pendant la période en cours de cette cagnotte doit valider son KYC.</b>} <b>Plus vous invitez des amis, plus vos chances augmentent.</b>
                        </Typography>
                    </Typography>


                    <Link to={'/sponsorships/'}>
                        <Button
                            variant='outlined'
                            className='Montserrat'
                            sx={{
                                fontWeight: 'bold',
                                mt: 2
                            }}
                        >
                            {taskIsCompleted ? "Inviter plus d'amis" : 'Inviter des amis'}
                        </Button>
                    </Link>

                    
                </Typography>
            </Stack>
        </Box>
    )
}
