import { useState, useEffect } from 'react'
import { 
    Chip, Dialog, DialogTitle, DialogContent, Typography, ListItem,
    ListItemAvatar, Avatar, List, ListItemText, Divider, DialogActions,
    Button, TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';
import { currencyType } from '../../utils/interfaces';
import { cftTokenInfo, isPC } from '../../functions/basicFunctions';

interface myProps {
    title: string,
    selectedCrypto: currencyType | undefined,
    setSelectedCrypto: (a: currencyType) => void
}

export default function SearchImput(props: myProps) {

    const selectedCrypto = props.selectedCrypto;

    const [queryValue, setQueryValue] = useState('');
    const [currencyList, setCurrencyList] = useState<currencyType[]>([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        
        if(open) {
            searchCurrencies()
        }
    
    }, [open, queryValue])
    

    const handleClose = () => {
        setOpen(false);
    };

    const searchCurrencies = () => {

        setLoading(true);

        axiosApi.post("ctr/currencies/search-currencies.php", QueryString.stringify({query: queryValue}))
        .then(response => {

            setLoading(false)

            if(response.data.status === 'success') {
                setCurrencyList(response.data.currencies)
            }
        })
    }

    return (
        <>

            {
                selectedCrypto

                ?   <Chip
                        label={<> { selectedCrypto.symbol } <ExpandMoreIcon/> </>}
                        icon={
                            <Avatar 
                                alt={selectedCrypto.symbol} 
                                src={selectedCrypto.logo} 
                                sx={{width: 20, height: 20}}
                            />
                        }
                        onClick={() => setOpen(true)}
                        sx={{
                            borderRadius: 1
                        }}
                    />

                :
                    <Chip
                        label={<>Choisir crypto <ExpandMoreIcon/> </>}
                        onClick={() => setOpen(true)}
                        sx={{
                            borderRadius: 1
                        }}
                    />
            }
            

            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                    maxWidth: 600, 
                    margin: 'auto'
                }}
            >
                <DialogTitle id="scroll-dialog-title">{ props.title }</DialogTitle>
                
                <DialogContent 
                    dividers={true}
                    sx={{
                        height: isPC ? 400 : '80vh',
                        minWidth: isPC ? 350 : 100
                    }}
                >

                    <TextField 
                        id="outlined-basic"
                        type='search' 
                        label="Rechercher une devise" 
                        variant="outlined"
                        placeholder="Exemple : BTC"
                        value={queryValue}
                        fullWidth
                        sx={{mt:3}}
                        onChange={
                            (e) => {
                                setQueryValue(e.target.value);
                                setCurrencyList([]);
                            }
                        }
                    />

                    <br /><br />

                    {
                        ((currencyList.length === 0) && !loading) &&
                        <Typography variant='caption'>
                            Aucune dedive trouvée !
                        </Typography>
                    } 

                    {
                        loading &&
                        <Typography sx={{textAlign: 'center'}}>
                            <Typography variant='caption'>
                                Recherche en cours...
                            </Typography>
                        </Typography>
                    }

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', margin:'auto' }}>
                            
                        {
                            currencyList.map((currency: currencyType, currencyIndex) => {

                                let allowedForSwap = currency.allowedForSwap == 1;

                                return (

                                    <div key={ currencyIndex }>

                                        <ListItem 
                                            alignItems="flex-start" 
                                            onClick={() => { 
                                                if(allowedForSwap) {
                                                    props.setSelectedCrypto(currency);
                                                    setOpen(false)
                                                } 
                                                
                                            }}
                                            sx={{
                                                cursor: allowedForSwap ? 'pointer' : 'inherit',
                                                opacity: allowedForSwap ? 1 : 0.5
                                            }}
                                        >

                                            <ListItemAvatar sx={{cursor:'pointer'}}>
                                                <Avatar 
                                                    alt={currency.symbol} 
                                                    src={currency.logo} 
                                                    sx={{width: 30, height: 30}}
                                                />
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={ currency.symbol }
                                                secondary={
                                                    <>

                                                        <Typography
                                                            sx={{ display: 'block' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            { currency.name }
                                                        </Typography>

                                                        {
                                                            (!allowedForSwap) &&
                                                            <Chip label="SWAP non disponible" 
                                                                        sx={{
                                                                            backgroundColor: "#FDEDED",
                                                                            color: "#E5593C",
                                                                            fontSize: '11px',
                                                                            cursor: 'pointer',
                                                                            mt: 1
                                                                        }} 
                                                                        variant="filled"
                                                                        size='small' />
                                                        }

                                                    </>
                                                }
                                            />
                                        </ListItem>


                                        <Divider variant="inset" component="li" />

                                    </div>
                                )
                            })
                        }   

                    </List>


                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
