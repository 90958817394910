import { Box, Typography } from '@mui/material'
import { useState, useEffect} from 'react'
import axiosApi from '../../../../functions/axiosApi'
import { Link } from 'react-router-dom'

interface UsersStatsType {
    numUsers: number,
    numWhoMakeTransaction: number
}

export default function UsersStats() {

    const [UsersStats, setUsersStats] = useState<UsersStatsType>()

    useEffect(() => {
        getUsersStats()
    }, [])
    

    const getUsersStats = () => {
        axiosApi.post("ctr/stats/users-stats/users-stats.php")
        .then(response => {

            if(response.data.status === 'success'){
                setUsersStats(response.data.UsersStats);
            }
        })
    }

    return (
        <div style={{maxWidth: '700px', margin:'auto', paddingTop:'30px'}}>
            
            <Typography variant='h5' className='text-center'>
                Statistiques utilisateurs
            </Typography>

            <Box component={'div'}
                sx={{
                    p: 2, 
                    mt: 5
                }}>

                <Typography sx={{
                    boxShadow: 2,
                    p: 2,
                    mb: 3
                }}>
                    Nombre total utilisateurs
                    <Typography variant='h5' sx={{fontWeight: 'bold', mt: 1}}>
                        { UsersStats?.numUsers ? UsersStats.numUsers + " " : " ... "}
                        <Link to={'/ctr/users/'} style={{fontSize: 'small'}}>
                            Afficher
                        </Link>
                    </Typography>
                </Typography> 

                <Typography sx={{
                    boxShadow: 2,
                    p: 2,
                    mb: 3
                }}>
                    Nombre utilisateurs ayant effectué au moins une transaction
                    <Typography variant='h5' sx={{fontWeight: 'bold', mt: 1}}>
                        { UsersStats?.numWhoMakeTransaction ? UsersStats.numWhoMakeTransaction : " ..."}
                    </Typography>
                </Typography>  

            </Box>

        </div>
    )
}
