import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';
import { currencyType } from '../../utils/interfaces';
import { Avatar, Box, Chip, Skeleton, Typography } from '@mui/material';
import { isPC } from '../../functions/basicFunctions';

interface myProps {
    handleChangeCryptoCurrency: (currency: currencyType | null) => void,
    action: string,
    selectedCrypto: currencyType | null | undefined;
    trendingCurrencies: currencyType[] | undefined;
    setActiveStep: (value: number) => void
}

export default function SelectCrypto(props: myProps) {

    const selectedCrypto = props.selectedCrypto;
    const trendingCurrencies = props.trendingCurrencies;

    const [open, setOpen] = useState(false);
    const [currencyList, setCurrencyList] = useState<currencyType[]>([]);
    const [queryValue, setQueryValue] = useState(selectedCrypto ? selectedCrypto.symbol  + ' (' + selectedCrypto.name + ')' : '');
    const loading = open && (currencyList.length === 0);
    
    useEffect(() => {
        selectedCrypto && setQueryValue(selectedCrypto.symbol  + ' (' + selectedCrypto.name + ')')
    }, [])
    
    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            searchCurrencies();

        if (active) {
            setCurrencyList([...currencyList]);
        }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setCurrencyList([]);
        }
    }, [open]);

    const searchCurrencies = () => {

        axiosApi.post("ctr/currencies/search-currencies.php", QueryString.stringify({query: queryValue}))
        .then(response => {

            if(response.data.status === 'success') {
                setCurrencyList(response.data.currencies)
            }
        })
    }

    const currencyIsDisabled = (currency: currencyType) => {
        return !(currency.allowed_networks &&   
                                    
            (   ((props.action === 'buy') && (currency.allowed_networks?.filter(allowed_network => allowed_network.allowForBuy).length >= 1)) 
                ||
                ((props.action === 'sell') && (currency.allowed_networks?.filter(allowed_network => allowed_network.allowForSell).length >= 1))
            )
        )
    }

    const handleQueryValueChange = (e: any) => {
        setQueryValue(e.target.value);
        searchCurrencies();
    }

    return (

        <Box>

            <Autocomplete
                id="select-crypto-input"
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                isOptionEqualToValue={(currency, value) => currency.symbol === value.symbol}
                getOptionLabel={(currency) => currency.symbol  + ' (' + currency.name + ')'}
                getOptionDisabled={(currency) => currencyIsDisabled(currency)}
                filterOptions={(currency) => currency}
                options={currencyList}
                loading={loading}
                clearOnBlur
                fullWidth
                sx={{
                    mb: 2.5
                }}

                renderOption={(params, currency) => {
                    
                    return (
                        <Typography 
                            { ...params } 
                            >

                            <Typography 
                                    onClick={() => !currencyIsDisabled(currency) && props.handleChangeCryptoCurrency(currency) }
                                    sx={{
                                        width: '100%'
                                    }}
                                    
                                    >
                                <Avatar src={currency.logo} sx={{width:'30px', height:'30px', display:'inline-block', marginRight:'10px', verticalAlign:'middle'}} />
                                { currency.symbol + ' (' + currency.name + ')'}

                                {
                                    currencyIsDisabled(currency) &&

                                    <Chip label="Indisponible" 
                                        sx={{
                                            backgroundColor: "#FDEDED",
                                            color: "#E5593C",
                                            ml: 2,
                                            fontSize: '11px',
                                            cursor: 'pointer'
                                        }} 
                                        variant="filled"
                                        size='small' />
                                }
                                
                            </Typography>

                        </Typography>
                    )
                }}

                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Chercher une crypto"
                    placeholder='Ex: BTC'
                    name='cryptoCurrency'
                    value={queryValue}
                    onChange={handleQueryValueChange}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <>
                            {   loading ? <CircularProgress color="inherit" size={20} /> : null }
                            <span onClick={() => props.handleChangeCryptoCurrency(null)}>{   params.InputProps.endAdornment  }</span>
                        </>
                        ),
                    }}
                    />
                )}

            />

            <Typography
                
                sx={{
                    fontWeight: 'bold',
                    opacity: 0.7,
                    mb: 2,
                    mt: 2
                }}
                >
                Populaires et tendances
            </Typography>

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: isPC ? 'minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)' : 'repeat(2, 1fr)',
                    gap: 2
                }}>

                {
                    (!trendingCurrencies || (trendingCurrencies.length === 0)) &&
                    [1,2,3,4,5,6].map(i => {
                        return (
                            <Skeleton 
                                animation='wave'
                                variant='rectangular'
                                key={i}
                                height={30}
                                sx={{borderRadius: 1}}
                            />
                        )
                    })
                    
                }

                {
                    trendingCurrencies && 
                    trendingCurrencies.map(trendingCurrency => {

                        return (
                            <Chip 
                                key={trendingCurrency.id}
                                label={
                                    <span>
                                        <Avatar 
                                            src={trendingCurrency.logo}
                                            sx={{
                                                display: 'inline-block',
                                                mr: 1,
                                                width: '20px',
                                                height: '20px',
                                                verticalAlign: 'middle'
                                            }} />

                                        <span style={{verticalAlign: 'middle'}}>
                                            {trendingCurrency.symbol}
                                        </span>
                                        
                                    </span>
                                } 
                                sx={{
                                    backgroundColor: "rgba(240,240,240, 0.5)",
                                    borderRadius: 1,
                                    p: 2,
                                    cursor: 'pointer'
                                }} 
                                variant="filled" 
                                size="small"
                                disabled={currencyIsDisabled(trendingCurrency)}
                                onClick={() => {

                                    if(!currencyIsDisabled(trendingCurrency)) {
                                        props.handleChangeCryptoCurrency(trendingCurrency);
                                        if(selectedCrypto?.symbol === trendingCurrency.symbol) {
                                            props.setActiveStep(1)
                                        }
                                    }
                                }}
                                 />
                        )
                    })
                }

            </Box>

        </Box>
    );
}
