import { Box, Button, TextField, Typography } from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import { basicProps } from '../../utils/interfaces'
import axiosApi from '../../functions/axiosApi';
import QueryString from 'qs';

export default function Test(props: basicProps) {


    const onReady = () => {
        
        const data = {
            loaderText: "Traitement..."
        }
        
        axiosApi.post("tests/test.php", QueryString.stringify(data))
        .then(response => {
            if(response.data.status === 'success') {

                if(response.data.redirect_url) {
                    window.open(response.data.redirect_url)
                }
            }
        })
    };

    const checkStatus = () => {
        const data = {
            loaderText: "Traitement..."
        }
        
        axiosApi.post("tests/get-status.php", QueryString.stringify(data))
        .then(response => {
            if(response.data.status === 'success') {

                console.log(response.data)
            }
        })
    }

    const getImg = () => {
        const data = {
            loaderText: "Traitement..."
        }
        
        axiosApi.post("tests/get-img.php", QueryString.stringify(data))
        .then(response => {
            if(response.data.status === 'success') {

                console.log(response.data.imageData)
            }
        })
    }
    

    return (
        <div>

            <br /><br /><br />


            <Box    
                sx={{
                    boxShadow: 2,
                    p: 3
                }}
            >


                <Button 
                    type='submit'
                    variant='contained' 
                    fullWidth
                    onClick={onReady}
                >
                    Send
                </Button>

                <br /><br />

                <Button 
                    type='submit'
                    variant='contained' 
                    fullWidth
                    onClick={checkStatus}
                >
                    Vérifier statut
                </Button>

                <br /><br />

                <Button 
                    type='submit'
                    variant='contained' 
                    fullWidth
                    onClick={getImg}
                >
                    Télécharger image
                </Button>


                <img alt="img" src="https://test-gateway.zignsec.com/folders/2386eb8a-bf2c-40a4-8aa6-1ec353ecefd5/media/2ec58e5c-2667-4b09-a9fb-93987ff8070f" />

            </Box>


        </div>
    )
}
