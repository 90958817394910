import { useEffect } from 'react';
import { Box, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import QueryString from 'qs';
import axiosApi from '../../../functions/axiosApi';
import { basicProps, orderType } from '../../../utils/interfaces'
import BuyInstructions from './BuyInstructions';
import SellInstructions from './SellInstructions';
import { MetaTags } from 'react-meta-tags';
import ExternalBuyInstructions from './ExternalBuyInstructions';
import ExternalSellInstructions from './ExternalSellInstructions';
import BuyWithCftInstructions from './BuyWithCftInstructions';
import SellWithCftInstructions from './SellWithCftInstructions';
import { cftTokenInfo, copyClipboard } from '../../../functions/basicFunctions';
import SellCftInstructions from './SellCftInstructions';
import ExternalSaleCftInstructions from './ExternalSaleCftInstructions';
import BuyWithCFPAY from './BuyWithCFPAY';
import SaleWithCFPAY from './SaleWithCFPAY';
import CheckIcon from '@mui/icons-material/Check';
import SwapOrder from './SwapOrder';
import PaidIcon from '@mui/icons-material/Paid';

interface reglementProps extends basicProps {
    orderData: orderType,
    setOrderData: (order:orderType) => void
}

export default function Reglement(props: reglementProps) {

    const basicDatas = props.basicDatas;
    const orderData = props.orderData;
    const orderBasicInfos = orderData.basic;
    const cryptoCurrency = orderData.cryptoCurrency;
    const paymentMethod = orderData.paymentMethod;

    useEffect(() => {
        
        if(orderBasicInfos.cftBonus && (parseFloat(orderBasicInfos.cftBonus + '') > 0) && ((orderBasicInfos.status === 'waiting_payment') || (orderBasicInfos.status === 'marked_paid'))) {
            
            basicDatas.dialogManager({
                dialogTitle: '',
                dialogText: (
                    <Box sx={{
                        textAlign: 'center'
                    }}>

                        <PaidIcon
                            color='info'
                            sx={{
                                margin: 'auto',
                                fontSize: 70,
                                mb: 2
                            }} />

                        <Typography variant='h5' sx={{mb: 3, fontWeight: 'bold'}}>
                            { orderBasicInfos.cftBonus + ' CFT ' } 
                            <Chip color='info' label='Bonus' size='small' />
                        </Typography>

                        <Typography
                            sx={{mt: 5}}
                        >
                            Cette transaction vous donnera droit à un bonus de <b style={{color: 'var(--site-color)'}}>{ orderBasicInfos.cftBonus + ' CFT' }</b>. <br />

                            <Typography sx={{mt: 4}}>
                                <Typography variant='caption'>
                                    Vous obtenez ce bonus car vous bénéficiez des remises appliquées par { basicDatas.appName } aux utilisateurs réguliers et/ou ayant des volumes de transactions importants.
                                </Typography>
                            </Typography>

                        </Typography>

                    </Box>
                ),
                dialogAgreeBtnColor: 'info',
                dalogCloseBtnText: ' ',
                dialogAgreeBtnText: "D'accord merci"
              }, () => {})
        }

    }, [])


    const copyPhoneNumber = (phoneNumber: string): void => {
        
        let splitPhoneNumber = phoneNumber.split(' ');
        splitPhoneNumber.shift();
        const phoneNumberWithoutCode = splitPhoneNumber.join('');

        copyClipboard(phoneNumberWithoutCode, "Le numéro a été copié !");
    }

    const confirmPayment = () => {
        
        basicDatas.dialogManager({
            dialogTitle: 'Confirmation de paiement (' + orderData.basic.orderID + ')',
            dialogText: (
                <Typography> 
                    En marquant votre ordre comme PAYÉ, vous déclarez avoir :

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                        <ListItem>
                            <ListItemIcon>
                                <CheckIcon color='info' />
                            </ListItemIcon>

                            <ListItemText primary={`Vérifié le récapitulatif de votre ordre et êtes sûr de n'avoir commis aucune faute (surtout sur l'adresse de réception).`} />
                        
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <CheckIcon color='info' />
                            </ListItemIcon>

                            <ListItemText primary={`Effectué le paiement de l'ordre faute de quoi l'ordre sera automatiquement annulé par les validateurs.`} />
                        
                        </ListItem>
                    </List>

                    <Typography variant='caption'>
                        Gardez à l'esprit que { basicDatas.appName } n'a ni le droit, ni le possibilité de valider votre ordre si vous ne l'avez pas marqué comme PEYÉ. Mais si le delai de paiement est dépassé, l'ordre sera annulé.<br />
                        Vous ne pourrez plus annuler votre ordre s'il est déjà marqué comme PAYÉ.
                    </Typography>

                </Typography>
            ),
            dialogAgreeBtnColor: 'info',
            dialogAgreeBtnText: "Oui je confirme avoir payé"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    orderID: orderData.basic.orderID,
                    loaderText: "Confirmation en cours..."
                }
               
                axiosApi.post('orders/confirm-payment.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        const orderDataUpdated = response.data.orderData;
                        props.setOrderData(orderDataUpdated);
                    }
                })
            }
      
        })

    }

    const cancelOrder = () => {

        basicDatas.dialogManager({
            dialogTitle: "Annuler l'ordre (" + orderData.basic.orderID + ")",
            dialogText: "Voulez-vous vraiment annuler cet ordre? n'annulez pas si vous avez déjà effectué le paiement. Contactez plutôt le support car cette action est irréversible.",
            dialogAgreeBtnColor: 'warning',
            dialogAgreeBtnText: "Oui annuler"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    orderID: orderData.basic.orderID,
                    loaderText: "Annulation en cours..."
                }
               
                axiosApi.post('orders/cancel-order.php', QueryString.stringify(data))
                .then(response => {
                    if(response.data.status === 'success'){
                        const orderDataUpdated = response.data.orderData;
                        props.setOrderData(orderDataUpdated);
                    }
                })
            }
      
        })
        
    }


    const Meta = () => {
        return (
            <MetaTags id="order-infos">
                <title>{orderData ? "Règlement de l'ordre N° " + orderData.basic.orderID : "Règlement de l'ordre"}</title>
                <meta name="description" content={orderData ? "Règlement de l'ordre N° " + orderData.basic.orderID : basicDatas.appName + " - Règlement de l'ordre"} />
                <meta property="og:title" content={orderData ? "Règlement de l'ordre N° " + orderData.basic.orderID : basicDatas.appName + " - Règlement de l'ordre"} />
            </MetaTags>
        )
    }

    if(!orderData) return (
        <div className='text-center'>
            <Meta />
            Chargement...
        </div>
    )


    if(orderBasicInfos.action === 'swap') return (
        <div>
            <Meta />

            <SwapOrder
                confirmPayment={confirmPayment} 
                cancelOrder={cancelOrder} {...props}
            />
        </div>
    )


    return (
        <div>

            <Meta />

            {

                ((cryptoCurrency.symbol === cftTokenInfo.symbol) && (orderData.basic.action === 'sell'))

                ?
                
                    paymentMethod.availableInCameroon == 1
                    
                    ?   <SellCftInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props}/>
                    :   <ExternalSaleCftInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props}/>

                :

                    paymentMethod.symbol === 'CF_PAY' 

                    ?
                        orderData.basic.action === 'buy'
                        ?   <BuyWithCFPAY cancelOrder={cancelOrder} {...props} />
                        :   <SaleWithCFPAY cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props} />
                    :

                        (paymentMethod.symbol === cftTokenInfo.symbol) 
                        
                        ?   orderData.basic.action === 'buy' 

                            ?   <BuyWithCftInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} {...props} /> 
                            :   <SellWithCftInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} {...props} /> 

                        :
                            orderData.basic.action === 'buy' 

                            ?   paymentMethod.availableInCameroon == 1
                                ?   <BuyInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} {...props} /> 
                                :   <ExternalBuyInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} {...props} />
                            
                            :   paymentMethod.availableInCameroon == 1
                            
                                ?   <SellInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props} />
                                :   <ExternalSellInstructions confirmPayment={confirmPayment} cancelOrder={cancelOrder} copyPhoneNumber={copyPhoneNumber} {...props} />
            }
        </div>
    )
}
