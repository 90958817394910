import { ReactNode, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, Typography } from '@mui/material';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { kycDemandType } from './VerifyWalletAddresses';


const waNumber = '+237 650 83 94 31';

export const attachedMessages: {title: string, message: string, icon: ReactNode}[] = [
    {
        title: "L'adresse n'appartient pas à l'utilisateur",
        message: `
            Cher utilisateur,
            <br/><br/>
            Nous avons noté que l'adresse de portefeuille soumise ne semble pas être associée à un portefeuille personnel dont vous détenez le contrôle exclusif. Pour garantir la sécurité de vos fonds et respecter nos protocoles de conformité, il est essentiel que l'adresse fournie soit celle d'un portefeuille sur lequel vous avez une autorité complète.
            <br/><br/>
            Veuillez soumettre une adresse de portefeuille privé, géré uniquement par vous, pour continuer en toute sécurité.
            <br/><br/>
            Nous apprécions votre compréhension et coopération.
            <br/><br/>
            Si vous avez besoin d'aide, veuillez contacter notre service client via WhatsApp au ${waNumber}
        `,
        icon: <HighlightOffIcon color='error' />
    },

    {
        title: "Capture d'écran invalide",
        message: `
            Cher utilisateur,
            <br/><br/>
            La capture d'écran que vous avez fournie ne nous permet pas de confirmer votre adresse de portefeuille. 
            <br/>
            Veuillez réessayer en fournissant une capture d'écran de l'adresse à partir de votre portefeuille crypto. Assurez-vous que l'adresse est clairement visible et que l'image n'est pas rognée. 
            <br/><br/>
            Si vous avez besoin d'aide, veuillez contacter notre service client via WhatsApp au ${waNumber}
        `,
        icon: <HighlightOffIcon color='error' />
    },

    {
        title: "Mauvais réseau",
        message: `
            Cher utilisateur,
            <br/><br/>
            Nous avons remarqué que l'adresse que vous avez tenté d'enregistrer est incompatible avec le réseau que vous avez sélectionné. 
            <br/>
            Veuillez réessayer en sélectionnant le bon réseau. 
            <br/><br/>
            Si vous avez besoin d'aide, veuillez contacter notre service client via WhatsApp au ${waNumber}
        `,
        icon: <HighlightOffIcon color='error' />
    },

    {
        title: "Adresse invalide",
        message: `
            Cher utilisateur,
            <br/><br/>
            L'adresse que vous avez fournie est invalide. 
            <br/><br/>
            Si vous avez besoin d'aide, veuillez contacter notre service client via WhatsApp au ${waNumber}
        `,
        icon: <HighlightOffIcon color='error' />
    }
]

interface myProps {
    confirmAddress: (demand: kycDemandType, decision: 'deny' | 'allow') => void,
    demand: kycDemandType,
    message: string,
    setMessage: (message: string) => void 
}

export default function RemoveAddress(props: myProps) {

    const [open, setOpen] = useState(false)
    const [openMessages, setOpenMessages] = useState(false)

    if(!open) return (
        <Button 
            fullWidth
            variant='contained'
            color='error'
            onClick={() => setOpen(true)}
        >
            Refuser l'adresse
        </Button>
    )
    return (

        <Dialog open={open} onClose={() => setOpen(false)}>

            <DialogTitle>Retirer l'adresse</DialogTitle>

            <DialogContent sx={{minWidth: 300}}>

                <Button
                    startIcon={<StickyNote2Icon/>}
                    onClick={() => setOpenMessages(true)}
                    sx={{
                        mb: 2
                    }}
                >
                    Auto message
                </Button>
                
                <TextareaAutosize minRows={3} 
                                    style={{width:'100%'}}
                                    id={"textarea-message" + Math.random()}
                                    value={props.message}
                                    onChange={(e) => props.setMessage(e.target.value)}
                                    placeholder="Message" />

                <br /><br />

                <Button
                    fullWidth
                    color='error'
                    variant='contained'
                    onClick={() => props.confirmAddress(props.demand, 'deny')}
                >
                    Supprimer l'adresse
                </Button>
                
                <Dialog open={openMessages} onClose={() => setOpenMessages(false)}>

                    <DialogTitle>Messages automatiques</DialogTitle>

                    <DialogContent sx={{minWidth: 300}}>
                        
                        {
                            attachedMessages.map((itemMessage, i) => {

                                return (
                                    <Box
                                        key={i}
                                    >

                                        <Box
                                            onClick={() => {props.setMessage(itemMessage.message); setOpenMessages(false)}}
                                            sx={{
                                                pb: 3,
                                                mb: 3,
                                                boxShadow: 2,
                                                p: 2,
                                                borderRadius: 1,
                                                cursor: 'pointer'
                                            }}>
                                            
                                            <Typography>
                                                { itemMessage.icon } { itemMessage.title}
                                            </Typography>

                                            <Typography color="GrayText" fontSize='small'>
                                                { itemMessage.message }
                                            </Typography>

                                        </Box>
                                    </Box>
                                )

                                return <></>
                            })
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => setOpenMessages(false)}>Fermer</Button>
                    </DialogActions>

                </Dialog>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => setOpen(false)}>Fermer</Button>
            </DialogActions>

        </Dialog>
    )
}
