import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input';
import { useState } from 'react'
import axiosApi from '../../../../functions/axiosApi';
import { cftPartnerType } from '../../../../utils/interfaces';

interface myProps {
    partner: cftPartnerType,
    sw: string
}

export default function PartnerPhoneNumbers(props: myProps) {

    const partner = props.partner;

    const [open, setOpen] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState('')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addNumber = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        data.append('sw', props.sw);
        data.append('userID', partner.user_id);

        axiosApi.post("ctr/team-manager/cft-partners/add-phone-number.php", data)
        .then(response => {

            if(response.data.status === 'success') {
                console.log('coco')
            }
        })
    }

    return (
        <Box
            sx={{
                p: 2,
                mb: 3,
                boxShadow: 1,
                borderRadius: 1
            }}
        >

            <Typography
                    sx={{
                        fontWeight: 'bold'
                    }}>
                Numéros de téléphone
            </Typography>

            <Typography
                onClick={() => setOpen(true)}
                sx={{
                    color: 'var(--site-color)',
                    textDecorationLine: 'underline',
                    cursor: 'pointer'
                }}
            >
                Afficher
            </Typography>

            <Dialog open={open} onClose={handleClose}>

                <DialogTitle>Numéros téléphone</DialogTitle>

                <DialogContent sx={{minWidth: 300}}>

                    <Box
                        component='form'
                        onSubmit={addNumber}
                    >
                    
                        <MuiTelInput
                            margin="normal"
                            fullWidth
                            value={newPhoneNumber}
                            onChange={(value: string) => setNewPhoneNumber(value)}
                            id="newPhoneNumber"
                            label="Téléphone"
                            name="newPhoneNumber"
                            autoComplete="phone"
                            sx={{mb: 2}}
                        />

                        <Typography sx={{textAlign: 'right'}}>
                            <Button
                                variant='contained'
                                type='submit'
                            >
                                Ajouter
                            </Button>
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>

            </Dialog>
            
        </Box>
    )
}
