import { basicProps } from '../../utils/interfaces';
import { Typography, Box, Grid, Avatar, Button, Stack } from '@mui/material';
import { isPC, publicPath } from '../../functions/basicFunctions';
import { MetaTags } from 'react-meta-tags';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TelegramIcon from '@mui/icons-material/Telegram';

export default function About(props: basicProps) {

    const basicDatas = props.basicDatas;

    return (
        <div style={{
            maxWidth: '800px',
            margin: 'auto'
        }}>

            <MetaTags id="about">
                <title>A propos</title>
                <meta name="description" content={basicDatas.appName + " - A propos"} />
                <meta property="og:title" content={basicDatas.appName + " - A propos"} />
            </MetaTags>

            <div className="text-center"
                    style={{
                        marginTop: '0px',
                        marginBottom: '50px'
                    }}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>

                    <img 
                        src={isPC ? publicPath + "images/about_us.png" : publicPath + "images/home_banner.png"} 
                        alt="CRYPTO-FIAT"
                        style={{
                            width: '100%',
                            borderBottomLeftRadius: isPC ? '0px' : '5px',
                            borderBottomRightRadius: isPC ? '0px' : '5px'
                        }} />

                </div>

            </div>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={6}>
                    <Grid item xs={isPC ? 6 : 12} sx={{mb: isPC ? 4 : 0}}>
                        <Typography variant='h5' sx={{mb:2, fontWeight: isPC ? 'inherit' : 'bold'}}>
                            Qui sommes-nous ?
                        </Typography>

                        <div>
                            {basicDatas.appName + " "} est une plateforme dont l'objectif est de permettre aux résidents d'Afrique d'échanger facilement, rapidement et en toute sécurité leurs cryptomonnaies contre des monnaies locales, et vice versa. 
                            <br />
                            Nous nous engageons à offrir à nos utilisateurs les taux de change les plus compétitifs.
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{mb:4}} className="pc-app-el">
                        <div>
                            <Avatar src={publicPath + "/images/team.png"} 
                                    alt="Idriss Wagoum Takuété"
                                    style={{
                                        width: isPC ? '100%' : '200px',
                                        height: isPC ? '210px' : '200px',
                                        borderRadius: '0px'
                                    }} />
                        </div>
                    </Grid>


                    <Grid item xs={6} sx={{mb:4}} className='pc-app-el'>
                        <div>
                            <Avatar src={publicPath + "/images/idriswag.png"} 
                                alt="Idriss Wagoum Takuété"
                                style={{
                                    width: isPC ? '300px' : '200px',
                                    height: isPC ? '300px' : '200px',
                                    borderRadius: '0px'
                                }} />
                        </div>
                    </Grid>
                    <Grid item xs={isPC ? 6 : 12} sx={{mb: isPC ? 4 : 0}}>
                        
                        <Typography variant='h5' sx={{mb:2, fontWeight: isPC ? 'inherit' : 'bold'}}>
                            Le créateur
                        </Typography>

                        <div>
                            La plateforme a été créée et est maintenue sous la supervision d'<a href="https://www.idriswag.com" className='link text-primary' target='_blank'>Idriss Wagoum Takuété</a>, un jeune Camerounais qui a étudié l'informatique en Algérie, où il est tombé amoureux de la cryptographie. 
                            <br /> <br />
                            Aujourd'hui, il est l'un des plus grands passionnés de cryptographie au monde. 
                            <br /><br />
                            Pour plus d'informations, veuillez visiter son site personnel à l'adresse suivante : <a href="https://www.idriswag.com" className='link text-primary' target='_blank'>idriswag.com</a>.
                        </div>

                    </Grid>

                    <Grid item xs={12} sx={{mb:4}} className="mobile-app-el">
                        <div>
                            <Avatar src={publicPath + "/images/idriswag.png"} 
                                alt="IDRISS WAGOUM TAKUÉTÉ"
                                style={{
                                    width: isPC ? '300px' : '100%',
                                    height: isPC ? '300px' : '100vw',
                                    borderRadius: '0px'
                                }} />
                        </div>
                    </Grid>


                    <Grid item xs={isPC ? 7 : 12} id="contact-us">

                        <Typography variant='h5' sx={{mb:2, fontWeight: isPC ? 'inherit' : 'bold'}}>
                            Vous avez des questions pour nous ?
                        </Typography>

                        <div>

                            Contactez-nous pour toute information

                            <br />

                            <Typography variant='h6' sx={{mt:3, mb:4}}>
                                Tel : +237 6 50 83 94 31 <br />
                                Email : contact@cryptofiat-ex.com
                            </Typography>

                            <a href='https://wa.me/+237650839431' 
                                target='_blank' 
                                className='link' >

                                <Button variant='contained' 
                                        startIcon={<WhatsAppIcon />}
                                        fullWidth
                                        sx={{
                                            backgroundColor: '#128C7E'
                                        }}>
                                    WhatsApp
                                </Button>

                            </a>

                            <Stack justifyContent='space-between' 
                                    direction={isPC ? "row" : "column"} 
                                    spacing={3}
                                    sx={{mt:3}} >

                                <a href='https://t.me/cryptofiatex' 
                                    target='_blank' 
                                    className='link' >

                                    <Button variant='contained' 
                                            startIcon={<TelegramIcon />}
                                            color="info"
                                            fullWidth>
                                        Telegram
                                    </Button>

                                </a>

                                <a href='https://twitter.com/cryptofiatex' 
                                    target='_blank'
                                    className='link'>
                                        
                                    <Button variant='contained' 
                                            fullWidth
                                            color='inherit'
                                            startIcon={
                                                <Avatar
                                                    src="https://seeklogo.com/images/T/twitter-x-logo-0339F999CF-seeklogo.com.png?v=638258077460000000" 
                                                    sx={{
                                                        width: 23,
                                                        height: 23,
                                                        mt: 0.2
                                                    }}
                                                />
                                            }>
                                        X
                                    </Button>
                                </a>

                                <a href='mailto:contact@cryptofiat-ex.com' className='link'>
                                    <Button variant='contained' 
                                            startIcon={<MailOutlineIcon />}
                                            fullWidth
                                            sx={{
                                                backgroundColor: '#D32F2F'
                                            }}>
                                        Email
                                    </Button>
                                </a>
                            </Stack>
                        </div>
                    </Grid>
                    <Grid item xs={5} className='pc-app-el'>
                        <div>
                            <Avatar src={publicPath + "/images/phone-call.png"} 
                                    alt="Idriss Wagoum Takuété"
                                    style={{
                                        width: isPC ? '300px' : '200px',
                                        height: isPC ? '300px' : '200px',
                                        borderRadius: '0px'
                                    }} />
                        </div>
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}
