import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { banner, basicProps } from '../../../../utils/interfaces'
import axiosApi from '../../../../functions/axiosApi';
import QueryString from 'qs';

interface myProps extends basicProps {
    banner: banner
}

export default function ItemBanner(props: myProps) {

    const basicDatas = props.basicDatas;
    const banner = props.banner;
    const [openForm, setOpenForm] = useState(false);

    const saveChanges = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let data = new FormData(e.currentTarget)
        data.append('id', banner.id + '');

        axiosApi.post("ctr/communication/banners/set-banner.php", data);
    }

    const removeBanner = () => {

        basicDatas.dialogManager({
            dialogTitle: "Supprimer la bannière",
            dialogText: "Souhaitez-vous vraiment supprimer cette bannière?",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Supprimer"
          }, (response: string) => {
            
            if(response === 'ok') {

                const data = {
                    id: banner.id,
                    loaderText: "Suppression en cours..."
                }

                axiosApi.post("ctr/communication/banners/remove-banner.php", QueryString.stringify(data))
            }
      
        })

    }

    return (
        <Box
            component='form'
            onSubmit={saveChanges}
            sx={{
                mb: 5,
                boxShadow: 2,
                borderRadius: 1,
                p: 2
            }}
        >

            <Box
                sx={{
                    height: 250,
                    mb: 2,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    borderRadius: 1,
                    backgroundImage: 'url("' + banner.img + '")'
                }}
            ></Box>

            <Button
                onClick={() => setOpenForm(!openForm)}
                sx={{
                    textTransform: 'none',
                    mb: 2
                }}
            >
                Toggle Form
            </Button>

            <Button
                onClick={removeBanner}
                color='error'
                sx={{
                    textTransform: 'none',
                    mb: 2
                }}
            >
                Supprimer
            </Button>

            {
                openForm &&

                <>
                    <TextField
                        name='buttonTitle' 
                        defaultValue={banner.buttonTitle}
                        margin='dense'
                        fullWidth
                        label='buttonTitle'
                        sx={{mb:2}}
                    />

                    <FormControl 
                            fullWidth 
                            sx={{mb: 3}}
                        >

                            <InputLabel id="select_place_label">Place d'affichage</InputLabel>
                        
                            <Select
                                labelId="select_place_label"
                                name='type'
                                label="Place d'affichage"
                                fullWidth
                                defaultValue={banner.type}
                                margin="dense"
                                required
                            >

                                {
                                    [
                                        'home_mobile_top_carousel',
                                        'home_pc_top_bg'
                                    ].map((type, index) => {

                                        return (
                                            <MenuItem key={ index } value={ type }>
                                                { type }
                                            </MenuItem>
                                        )

                                    })
                                }
                
                            </Select>        

                        </FormControl>

                    <FormControl 
                        fullWidth 
                        sx={{mb: 2}}
                    >

                        <InputLabel id="select_priority_label">Priorité d'affichage</InputLabel>
                    
                        <Select
                            labelId="select_priority_label"
                            name='priority'
                            defaultValue={ banner.priority }
                            label="Priorité d'affichage"
                            fullWidth
                            margin="dense"
                            required
                        >

                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value, index) => {

                                    return (
                                        <MenuItem key={ index } value={ value }>
                                            { value }
                                        </MenuItem>
                                    )

                                })
                            }
            
                        </Select>        

                    </FormControl>

                    <FormControl 
                            fullWidth 
                            sx={{mb: 2}}
                        >

                            <InputLabel id="select_open_tap_label">Ouvir nouvel onglet</InputLabel>
                        
                            <Select
                                labelId="select_open_tap_label"
                                name='openNewTab'
                                defaultValue={ banner.openNewTab }
                                label="Ouvir nouvel onglet"
                                fullWidth
                                margin="dense"
                                required
                            >

                                {
                                    [0, 1].map((value, index) => {

                                        return (
                                            <MenuItem key={ index } value={ value }>
                                                { value }
                                            </MenuItem>
                                        )

                                    })
                                }
                
                            </Select>        

                        </FormControl>

                    <TextField
                        name='redirectUrl' 
                        defaultValue={banner.redirectUrl}
                        margin='dense'
                        fullWidth
                        label='redirectUrl'
                        sx={{mb:2}}
                    />
                    

                    <TextField
                        name='sw' 
                        type='password'
                        margin='dense'
                        fullWidth
                        label='sx'
                        sx={{mb:3}}
                    />

                    <Button
                        type='submit'
                        variant='contained'
                    >
                        Enregistrer
                    </Button>
                </>
            }
            
        </Box>
    )
}
