import React, { useEffect, useState} from 'react'
import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import axiosApi from '../../../functions/axiosApi';
import QueryString from 'qs';
import { basicProps, paymentMethodType } from '../../../utils/interfaces';
import { Link, Route, Routes } from 'react-router-dom';
import MobileMoneyPro from './MobileMoneyPro';
import EditIcon from '@mui/icons-material/Edit';
import ItemPaymentMethod from './ItemPaymentMethod';

export default function PaymentMethods(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [paymentMethods, setpaymentMethods] = useState([]);
    const [sw, setSw] = useState('')

    useEffect(() => {
        getPaymentMethods();
    }, [])

    const getPaymentMethods = () => {
        axiosApi.post('ctr/payment-methods/get-payment-methods.php', QueryString.stringify({qte:'all'}))
        .then(response => {
            if(response.data.status === 'success'){
                setpaymentMethods(response.data.paymentMethods);
            }
        })
    }
    

    const addPaymentMethod = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        let data = new FormData(e.currentTarget);
        data.append('sw', sw)
        
        data.append('loaderText', 'Ajout de la devise en cours...');

        axiosApi.post('ctr/payment-methods/add-payment-method.php', data)
        .then(response => {
            if(response.data.status === 'success'){
                const form_paymentMethod = document.getElementById('form_paymentMethod') as HTMLFormElement
                if(form_paymentMethod) form_paymentMethod.reset();
                getPaymentMethods();
            }
        })

    }

    return (
        <div>

            <Routes>

                <Route path='/' element={

                    <Box
                        sx={{
                            mt: 4
                        }}>
                        
                        <Typography component="h1" variant="h5" className='text-center'>
                            MOYENS DE PAIEMENT LOCAUX
                        </Typography>
            
                        <br />
            
                        <MobileMoneyPro basicDatas={basicDatas} sw={sw} />
            
                        <Box
                            component="form"
                            id="form_paymentMethod"
                            sx={{
                                '& .MuiTextField-root': { width: '25ch' },
                                mt: 4,
                                mb: 6,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={addPaymentMethod}
                            >
            
                            <TextField
                                required
                                name='name'
                                id="outlined-required"
                                label="Nom du moyen"
                                />
            
                            <TextField
                                required
                                name="symbol"
                                id="outlined-disabled"
                                label="Symbole"
                                />
            
                            <TextField
                                required
                                name='address'
                                id="outlined-required"
                                label="Adresse"
                                />
            
                            <TextField
                                required
                                name="accountOwnerName"
                                id="outlined-disabled"
                                label="Nom attaché au compte"
                                />
            
                            <TextField
                                required
                                name="logo"
                                id="outlined-disabled"
                                label="Lien du logo"
                                />
            
                            <Button type='submit' variant='contained' sx={{p:1.9}}>
                                Ajouter
                            </Button>
            
                        </Box>

                        <TextField
                            type='password'
                            label="sw"
                            name='sw'
                            value={sw}
                            onChange={(e) => setSw(e.target.value)}
                        />
            
                        <List sx={{ width: '100%', mt:3 }}>
            
                            {
                                paymentMethods.map((paymentMethod: paymentMethodType, index) => {
                                    return (
                                        <ListItem   
                                                key={index}
                                                secondaryAction={
                                                    <>
                                                        <Link to={"/ctr/payment-methods/" + paymentMethod.symbol + '/'}>
                                                            <Button color='info'>
                                                                <EditIcon />
                                                            </Button>
                                                        </Link>
                                                    </>
                                                }
                                                sx={{bgcolor: 'ButtonFace', mb:2}} >
                                            
                                            <ListItemAvatar>
                                                <Avatar src={paymentMethod.logo} />
                                            </ListItemAvatar>
                                            <ListItemText primary={paymentMethod.symbol} secondary={paymentMethod.name} />
                                            <ListItemText primary={paymentMethod.address} secondary="Numéro du compte" />
                                            <ListItemText primary={paymentMethod.accountOwnerName} secondary="Nom attaché au compte" />
                                        </ListItem>
                                    )
                                })
                            } 
            
                        </List>
            
                    </Box>
                } />

                <Route path='/:paymentMethodID' element={<ItemPaymentMethod basicDatas={basicDatas} paymentMethods={paymentMethods} /> } /> 

            </Routes>

        </div>
    )

}
