import { Route, Routes } from 'react-router-dom'
import Login from './Login'
import ResetPassword from './ResetPassword'
import SignUp from './SignUp'
import ResetPinCode from './ResetPinCode'

interface AuthProps {
    basicDatas: any
}

export default function Auth(props: AuthProps) {

    return (
        <div>  
            <Routes>
                <Route path='/login' element={<Login {...props} />} />
                <Route path='/signup' element={<SignUp {...props} />} />
                <Route path='/reset-password' element={<ResetPassword {...props} />} />
                <Route path='/reset-pin-code' element={<ResetPinCode {...props} />} />
            </Routes>
        </div>
    )
}
