import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import '../css/main/images-selector.css';
import useForceUpdate from '../hooks/useForceUpdate';
import CloseIcon from '@mui/icons-material/Close';

export default function ImagesSelector(props) {

    var images = props.images ? props.images : [];
    let setImages = props.setImages; 
    let maxImages = props.max ? props.max : 9;
    const forceUpdate = useForceUpdate();
    
    const InputImage = (props) => {
  
        const [url, setUrl] = useState(props.image.img_url);  
        const [dragOpen, setDragOpen] = useState(false);
        
        let onChangeFile = (event, currentImage) => {

            if (event.target.files && event.target.files.length > 0) {

                let files = Object.values(event.target.files)

                files.forEach(file => {
                
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.addEventListener('load', () => {
                        
                        let img_url = reader.result;
                        let img_data = img_url.replace(/^data:image\/(png|jpg|jpeg|wav|web|gif);base64,/, "");
                        setUrl(img_url)

                        let new_images = [];

                        images.forEach(image => {
                            new_images.push({
                                img_id: image.img_id,
                                img_url: image.img_id === currentImage.img_id ? img_url : image.img_url,
                                img_data: image.img_id === currentImage.img_id ? img_data : image.img_data,
                                extension: image.img_id === currentImage.img_id ? file.name.split('.').pop().toLowerCase() : image.extension,
                            })
                        })

                        setImages(new_images);
                        forceUpdate()
                    
                    });

                })           
            }
        }


        let removeImg = (e) => {
            e.preventDefault();

            var new_images = [];
            var c = 1;

            images.forEach(image => {

                if(image.img_id !== props.image.img_id){
                    image.img_id = c;
                    new_images.push(image);
                    c++
                }

            })

            setImages(new_images);
            
        }

        const startDrag = (e) => {
            e.target.classList.add('is-dragged');
            e.dataTransfer.setData('text', JSON.stringify(props.image));
            setDragOpen(true);
        }

        const dragEnter = (e) => {
            e.target.classList.add('is-hovered');
            setDragOpen(true);
        }

        const dragLeave = (e) => {
            e.target.classList.remove('is-hovered');
            setDragOpen(false)
        }

        const dragEnd = (e) => {
            e.preventDefault();
            e.target.classList.remove('is-dragged');
            setDragOpen(false);
        }

        const drop = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragOpen(false);

            let getDataImg = JSON.parse(e.dataTransfer.getData('text'));

            if(getDataImg.img_id === props.image.img_id) {
                e.target.classList.remove('is-dragged');
            }

            else {

                let newImgs = images.filter((image) => image.img_id !== getDataImg.img_id);
                let _newImgs = [];
                let i = 0;

                newImgs.forEach((image) => {

                    i++;

                    if(image.img_id === props.image.img_id){

                        if(getDataImg.img_id > props.image.img_id){

                            image = {...getDataImg}
                            image.img_id = i
                            _newImgs.push(image)

                            i++
                            image = {...props.image}
                            image.img_id = i
                            _newImgs.push(image)
                        }

                        else {
                            
                            image = {...props.image}
                            image.img_id = i
                            _newImgs.push(image)

                            i++
                            image = {...getDataImg}
                            image.img_id = i
                            _newImgs.push(image)
                        }
                    }   

                    else {
                        image.img_id = i
                        _newImgs.push(image)
                    }
                        

                })

                setImages(_newImgs)
            }

            
        }

        return (
            <label className="images-selector-item bg_img completed shadow"
                    id={'images-selector-item' + props.image.img_id}
                    draggable={window.screen.width > 700 ? true : false}
                    onDragStart={startDrag}
                    onDragEnd={dragEnd} 
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={drop}
                    element-id={props.image.imd_id}
                    style={{
                        backgroundImage: "url(" + url + ")",
                        display: 'flex'
                    }}>

                <CloseIcon className='delete-img-icon shadow' onClick={removeImg} title="Retirer l'image" />
                
                {
                    dragOpen &&
                    <span className='image-number'>
                        {props.image.img_id}
                    </span>
                }

                <input type="file" 
                        className='hide'
                        onChange={(e) => onChangeFile(e, props.image)} 
                        accept='image/*' />
            </label>
        )
    }

    const onSelectFiles = (event) => {

        if (event.target.files && event.target.files.length > 0) {

            let files = Object.values(event.target.files)
            let img_id = images.length + 1;

            files.forEach(file => {
              
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.addEventListener('load', () => {

                    if(img_id <= maxImages) {
                    
                        let img_url = reader.result;
                        let img_data = img_url.replace(/^data:image\/(png|jpg|jpeg|wav|web|gif);base64,/, "");
                        let newImgs = images;
                        newImgs.push({
                            img_id: img_id,
                            img_url,
                            extension: file.name.split('.').pop().toLowerCase(),
                            img_data
                        })
                        props.setImages(newImgs)

                        img_id++
                        forceUpdate()
                    }
                
                });
                

            })           
        }
    };

    return (
        <>

            {
                images && images.map((image, index) => {
                    return (
                        <InputImage image={image} key={index} />
                    )
                })
            } 

            {
                (images.length < maxImages) &&

                <label className='images-selector-item bg_img'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                    
                    <AddIcon />

                    <input type="file" 
                            className='hide'
                            onChange={onSelectFiles} 
                            accept='image/*'
                            multiple />
                </label>
            }

        </>
    )

}
