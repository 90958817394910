import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import axiosApi from '../../../../functions/axiosApi'
import { basicProps } from '../../../../utils/interfaces';
import QueryString from 'qs';
import { readabilityNumber } from '../../../../functions/basicFunctions';

export default function Expenses(props: basicProps) {

    const basicDatas = props.basicDatas;

    const [openForm, setOpenForm] = useState(false);
    const [expenses, setExpenses] = useState<{id: number, expenseName: string, amount: number}[]>([]);
    const [total, setTotal] = useState(0);
    const [sw, setSw] = useState('');

    useEffect(() => {
        getExpenses();
    }, [])

    const getExpenses = () => {

        axiosApi.post('ctr/stats/compta/expenses/get.php')
        .then(response => {

            if(response.data.status === 'success') {
                setExpenses(response.data.expenses);
                setTotal(response.data.total);
            }
        })
    }
    

    const saveExpense = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const form = e.currentTarget;

        const data = new FormData(e.currentTarget);
        data.append('loaderText', "Ajout en cours...");
        data.append('sw', sw);

        axiosApi.post("ctr/stats/compta/expenses/add.php", data)
        .then(response => {
            if(response.data.status === 'success') {
                form.reset();
                getExpenses();
            }
        })
    }

    const removeExpense = (id) => {

        basicDatas.dialogManager({
            dialogTitle: "Retirer une dépense",
            dialogText: "Souhaitez-vous confirmer la suppression de cette dépense ?",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Oui retirer"
          }, (response: string) => {
            
            if(response === 'ok') {

                axiosApi.post("ctr/stats/compta/expenses/delete.php", QueryString.stringify({id, sw}))
                .then(response => {
                    if(response.data.status === 'success') {
                        getExpenses();
                    }
                })
            }
        })
    }

    const purge = () => {

        basicDatas.dialogManager({
            dialogTitle: "Purger les dépense",
            dialogText: "Souhaitez-vous confirmer la suppression de toutes les dépenses ?",
            dialogAgreeBtnColor: 'error',
            dialogAgreeBtnText: "Oui supprimer"
          }, (response: string) => {
            
            if(response === 'ok') {

                axiosApi.post("ctr/stats/compta/expenses/purge.php", QueryString.stringify({sw}))
                .then(response => {
                    if(response.data.status === 'success') {
                        getExpenses();
                    }
                })
            }
        })
    }

    return (
        <div
            style={{
                maxWidth: 600,
                margin: 'auto'
            }}
        >

            <br />
            
            <Typography variant='h5' textAlign='center'>
                Dépenses période en cours
            </Typography>

            <Typography textAlign='center' fontWeight='bold'>
                ( { readabilityNumber(total) } )
            </Typography>

            <br /><br />

            <Stack
                direction='row'
                justifyContent='space-between'
            >
        
                <Button color='primary' sx={{textTransform: 'none'}} onClick={() => setOpenForm(!openForm)}>
                    { openForm ? 'Fermer' : 'Ajouter'}
                </Button>

                <Button color='error' onClick={purge}>
                    Purger
                </Button>

            </Stack>

            <br />

            {
                openForm &&
            
                <Box
                    component='form'
                    onSubmit={saveExpense}
                >
                    <TextField 
                        type='text'
                        name='expenseName'
                        label="Dépense"
                        placeholder='Nom dépense'
                        margin='dense'
                        fullWidth
                        required
                        sx={{
                            mb: 2
                        }}
                    />

                    <TextField 
                        type='number'
                        name='amount'
                        label="Montant"
                        placeholder='Montant dépense'
                        margin='dense'
                        fullWidth
                        required
                        sx={{
                            mb: 3
                        }}
                    />

                    <Button
                        type='submit'
                        variant='contained'
                    >
                        Enregistrer
                    </Button>
                </Box>

            }

            <br /><br />

            <Box>

                {
                    expenses.map((itemExpense) => {
                        return (
                            <Stack 
                                key={itemExpense.id}
                                direction='row'
                                justifyContent='space-between'
                                sx={{
                                    mb: 3,
                                    pb: 3,
                                    borderBottom: '1px solid #eee'
                                }}
                            >
                                <Typography>
                                    {itemExpense.expenseName}
                                </Typography>

                                <Typography></Typography>

                                <Typography>
                                    { readabilityNumber(itemExpense.amount) }
                                </Typography>

                                <Typography>
                                    <Button
                                        color='error'
                                        onClick={() => removeExpense(itemExpense.id)}
                                    >
                                        Del
                                    </Button>
                                </Typography>
                            </Stack>
                        )
                    })
                }
            </Box>

            <input type="password" value={sw} onChange={(e) => setSw(e.currentTarget.value) } />

        </div>
    )
}
